/* eslint-disable jsx-a11y/img-redundant-alt */
/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import * as React from 'react';
import { useCaseActivityPreviewImageStyles } from './styles';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFetchFileByUuid } from 'hooks/CaseDataHooks';

interface ICaseActivityPreviewImageProps {
  caseDataUuid: string;
}

export const CaseActivityPreviewImage: React.FC<
  ICaseActivityPreviewImageProps
> = ({ caseDataUuid }) => {
  const { casePreviewImage } = useCaseActivityPreviewImageStyles();
  const { data: imageData } = useFetchFileByUuid(caseDataUuid);

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <CydSimpleFormModal
        size="max"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        css={css``}
      >
        <img src={imageData} alt="attached image" />
      </CydSimpleFormModal>

      <img
        css={css`
          :hover {
            cursor: pointer;
          }
        `}
        role="button"
        alt="attached image preview"
        src={imageData}
        className={casePreviewImage}
        onClick={() => setIsOpen(true)}
      />
    </>
  );
};
