/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Group } from 'interface/Group.interface';
import { createAction } from '@reduxjs/toolkit';

interface ICreate {
  name: string;
  description: string;
  category: string;
  authSource: string;
}

// Groups Actions Function
export const createFetchGroups = createAction('groups/fetch');

export const createGroup = createAction<ICreate>('groups/create');

export const createDeleteGroup = createAction<Group>('groups/delete');
