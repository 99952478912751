/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { fetchCaseForms } from 'states/caseForms/actions';
import {
  caseFormsSelector,
  caseFormStatusSelector
} from 'states/caseForms/selectors';
import { useOrgs } from './OrgHooks';
import { useAtcPlaybookActions } from './PlaybookActionsHook';
import { usePlaybooks } from './PlaybooksHooks';
import { useAutoEffect } from './ReduxHooks';
import { useAllUsers } from './UserHooks';
import { Base64Decode, capitaliseEachWord } from 'utils/StringUtils';
import { CaseFormFieldType } from 'interface/CaseForm.interface';

export const useCaseForms = () => {
  return useAutoEffect({
    selector: caseFormsSelector,
    hasLoadedSelector: caseFormStatusSelector,
    ac: fetchCaseForms
  });
};

const keysToClean = [
  'contact_name',
  'contact_email',
  'contact_phone',
  'asset_name',
  'asset_description',
  'asset_organisation',
  'asset_location',
  'asset_ip_addresses',
  'asset_infrastructure_types'
];

export const useCaseFormJSON = (formData) => {
  const { data: forms } = useCaseForms();
  const { data: playbooks } = usePlaybooks();
  const { data: users } = useAllUsers();
  const { data: playbookActions } = useAtcPlaybookActions();
  const { data: orgs } = useOrgs();

  const formUuid = formData.uuid;
  if (!formUuid) {
    return '';
  }

  const formBeingUsed = forms.find(({ uuid }) => uuid === formUuid);
  const formName = formBeingUsed?.name || formUuid;

  const formFields: any = formBeingUsed?.fields
    ? JSON.parse(Base64Decode(formBeingUsed.fields))
    : [];

  const translateUuidsToText = (key: CaseFormFieldType, value) => {
    switch (key) {
      case CaseFormFieldType.USER:
        return users.find(({ uuid }) => uuid === value)?.displayName;
      case CaseFormFieldType.ORG:
        return orgs.find(({ uuid }) => uuid === value)?.name;
      case CaseFormFieldType.PLAYBOOK:
        return playbooks.find((el) => el.atc && el.atc.uuid === value)?.atc
          ?.name;
      case CaseFormFieldType.ACTION:
        return playbookActions.find((el) => el.atc && el.atc.uuid === value)
          ?.atc?.name;
      default:
        return value;
    }
  };

  let humanData = Object.entries(formData.fields).map(([key, value]) => {
    let mappedField = formFields.find(({ name }) => name === key);
    let newKey;
    //temp hack to be remediated in RM-1165
    if (keysToClean.includes(key)) {
      newKey = capitaliseEachWord(key.replace(/_/g, ' '));
    } else {
      newKey = mappedField?.displayName;
    }
    const newValue = translateUuidsToText(key as CaseFormFieldType, value);
    return [newKey || key, newValue || value];
  });

  humanData.unshift(['__formName__', formName]);

  let data = Object.entries(formData.fields).reduce(
    (acc, [key, value]) => {
      return { ...acc, fields: { ...acc.fields, [key]: value } };
    },
    { fields: {}, uuid: formUuid }
  );

  return JSON.stringify({
    humanData,
    data
  });
};
