/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParentCases } from 'hooks/deprecated_CaseHooks';
import { CydCaseViewCaseGroupSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewCaseGroupSection/CydCaseViewCaseGroupSection';
import { useCaseGroupsMemberOf } from 'hooks/CaseGroupHooks';
import {
  createAddMemberToCase,
  createRemoveMemberFromCase
} from 'states/cases/actions';

interface CydCaseViewParentSectionProps {
  caseUuid: string;
  isEditable: boolean;
}

export const CydCaseViewParentSection = ({
  caseUuid,
  isEditable = false
}: CydCaseViewParentSectionProps) => {
  const dispatch = useDispatch();
  const { data: groupsMemberOf } = useCaseGroupsMemberOf(caseUuid);
  const { data: parentCases } = useParentCases(groupsMemberOf);

  const handleAddCase = (groupUuid) => {
    dispatch(
      createAddMemberToCase({
        caseUuid: groupUuid,
        memberUuid: caseUuid
      })
    );
  };

  const handleRemoveCase = (groupUuid) => {
    dispatch(
      createRemoveMemberFromCase({
        caseUuid: groupUuid,
        memberUuid: caseUuid
      })
    );
  };

  return (
    <CydCaseViewCaseGroupSection
      data-testid="cyd-parent-cases-section"
      cases={parentCases}
      caseUuid={caseUuid}
      emptyListMessage="This case is not a member of any case group"
      isEditable={isEditable}
      onAddCase={handleAddCase}
      onRemoveCase={handleRemoveCase}
    />
  );
};
