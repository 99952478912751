/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResource } from 'interface/Resource.interface';
export interface IResourceState {
  resourceList: IResource[];
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: IResourceState = {
  resourceList: [],
  isLoading: false,
  hasLoaded: false
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    fetchResourcesListSuccess: (
      state,
      { payload }: PayloadAction<IResource[]>
    ) => {
      state.resourceList = payload;
      state.hasLoaded = true;
      state.isLoading = false;
    }
  }
});

export const {
  reducer: resources,
  actions: { fetchResourcesListSuccess }
} = resourcesSlice;
