/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  dialogBox: {
    backgroundColor: palette.background.default,
    '& h2': {
      fontSize: 20,
      textAlign: 'center'
    },
    '& button': {
      margin: '15px 0 0 auto',
      display: 'block'
    }
  },

  svgStyles: {
    '& g text': {
      fill: palette.text.primary,
      fontSize: 16
    },
    '& .state--next': {
      strokeWidth: 2,
      stroke: palette.text.primary,
      strokeDasharray: 4
    },
    '& g circle': {
      fill: palette.text.primary,
      r: 12,
      stroke: palette.primary.main,
      strokeWidth: 0,

      '&.state--current': {
        fill: palette.primary.main,
        r: 13
      },
      '&.state--start': {
        strokeWidth: 3
      },
      '&.state--end': {
        stroke: palette.success.main,
        strokeWidth: 3
      }
    }
  },

  keyText: {
    marginLeft: spacing(1)
  }
}));
