/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { Acl } from 'interface/Acl.interface';
import React from 'react';

export type CydAclSelectorProps = {
  availableAcls: Array<Acl>;
  /**
   * @deprecated - use selectedAclUuid instead
   */
  selectedAcl?: Acl | null;
  selectedAclUuid?: string | null;
  defaultSelectedAclUuid?: string | null;
  label?: string;
  name?: string;
  onChange?: (acl: Acl, aclUuid: string) => void;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
};

export const CydAclSelector = (props: CydAclSelectorProps) => {
  const {
    required,
    availableAcls,
    label = 'Select ACL',
    disabled,
    name,
    onChange,
    selectedAclUuid,
    defaultSelectedAclUuid,
    autoFocus
  } = props;

  return (
    <CydSimpleSelect
      autoFocus={autoFocus}
      required={required}
      availableOptions={availableAcls}
      selectedValueString={selectedAclUuid}
      defaultSelectedString={defaultSelectedAclUuid}
      label={label}
      disabled={disabled}
      onChange={(acl, uuid) => {
        if (onChange) {
          onChange(acl, uuid);
        }
      }}
      name={name}
      generateOptionLabel={(v) => v.description}
      generateValueString={(v) => v.uuid}
    />
  );
};
