/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { AtcPlaybook } from 'interface/Playbook.interface';
import { Typography, Chip, TextField, css } from '@mui/material';
import { usePlaybooksPageSinglePlaybookInfoStyle } from './styles';
import * as moment from 'moment-timezone';
import { CaseTag } from 'interface/CaseTag.interface';
import { useTimezoneLegacy } from 'hooks/AuthHooks';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import {
  createExportPlaybook,
  createDuplicatePlaybook
} from 'states/playbooks/actions';
import { dateFormatLocale } from 'utils/DateUtils';
import { useCaseTagsFiltered } from 'hooks/CaseTagsHooks';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydBaseModal } from 'components/_dialogs/CydBaseModal/CydBaseModal';

/** @jsxImportSource @emotion/react */

interface IPlaybooksPageSinglePlaybookInfoProps {
  playbook: AtcPlaybook;
  caseTags: CaseTag[];
  onAddTagToPlaybook: (caseTag: CaseTag) => void;
  onRemoveTagOnPlaybook: (caseTag: CaseTag) => void;
  onDeletePlaybook: () => void;
}

export const PlaybooksPageSinglePlaybookInfo: React.FC<
  IPlaybooksPageSinglePlaybookInfoProps
> = ({
  playbook,
  caseTags,
  onAddTagToPlaybook,
  onRemoveTagOnPlaybook,
  onDeletePlaybook
}) => {
  const [selectedCaseTag, setSelectedCaseTag] = React.useState<
    CaseTag | undefined
  >(undefined);

  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const { modified, created, tags } = playbook;
  const timeZone = useTimezoneLegacy();
  const [newTag, setNewTag] = React.useState('');
  const [clearInput, setClearInput] = React.useState('');
  const dispatch = useDispatch();
  const { data: tagListFiltered } = useCaseTagsFiltered();
  var existingTags = playbook ? playbook.tags : [];
  const tagsNotExistList =
    tagListFiltered &&
    tagListFiltered.filter(
      (tag) => !existingTags?.find((t) => t === tag.value)
    );

  const handleDeleteConfirmation = () => {
    onDeletePlaybook();
    setDeleteDialog(false);
  };

  const handleTagsChange = React.useCallback(
    (event, values) => {
      values ? setSelectedCaseTag(values) : setSelectedCaseTag(undefined);
    },
    [setSelectedCaseTag]
  );

  const handleAddTagToPlaybook = () => {
    if (!selectedCaseTag) {
      return;
    }
    onAddTagToPlaybook(selectedCaseTag);
    setSelectedCaseTag(undefined);
    setClearInput(`${selectedCaseTag.value}`);
  };

  const handleRemoveTagFromPlaybook = (caseTag: CaseTag) => {
    if (!caseTag) {
      return;
    }
    onRemoveTagOnPlaybook(caseTag);
  };

  const {
    timeStampSection,
    tagsControl,
    tagChip,
    tagChipContainer,
    playbookActionContainer,
    tagChipLabel,
    playbookActionDuplicateContainer
  } = usePlaybooksPageSinglePlaybookInfoStyle();

  const handleInputChange = (event: any) => {
    event.target.value &&
      event.target.value !== '' &&
      setNewTag(event.target.value);
  };

  const handleExportPlaybook = (uuid: string) => {
    uuid && dispatch(createExportPlaybook(uuid));
  };

  const handleDuplicatePlaybook = (uuid: string) => {
    uuid && dispatch(createDuplicatePlaybook(uuid));
  };

  const handleKeyEnter = (event: any) => {
    if (event.key === 'Enter') {
      const findTag = caseTags.find((el) => el.value === newTag);
      if (findTag) {
        onAddTagToPlaybook(findTag);
      } else if (selectedCaseTag) {
        handleAddTagToPlaybook();
      }
    }
  };

  return (
    <div>
      <div className={timeStampSection}>
        <Typography gutterBottom>
          Last updated: {moment.tz(modified, timeZone).format(dateFormatLocale)}
        </Typography>
        <Typography>
          Created on: {moment.tz(created, timeZone).format(dateFormatLocale)}
        </Typography>
      </div>
      <div>
        <Typography>Tags</Typography>
        <div className={tagChipContainer}>
          {tags &&
            caseTags &&
            caseTags
              .filter((tagObj) => tags.indexOf(tagObj.value) !== -1)
              .map((tagObj) => (
                <Chip
                  key={tagObj.value}
                  className={tagChip}
                  classes={{ label: tagChipLabel }}
                  color="primary"
                  label={tagObj.value}
                  onDelete={() => handleRemoveTagFromPlaybook(tagObj)}
                />
              ))}
        </div>
        <div
          css={(theme) => `
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(2)};
          `}
        >
          <div className={tagsControl}>
            <div
              css={(theme) => `
                display: flex;
                gap: ${theme.spacing(2)}; 
              `}
            >
              <Autocomplete
                key={clearInput}
                placeholder="Add new tag"
                onChange={handleTagsChange}
                onKeyDown={(e) => handleKeyEnter(e)}
                options={tagsNotExistList}
                getOptionLabel={(option) => option.value}
                style={{ minWidth: 170 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add new tag"
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                  />
                )}
              />
              <CydButton variant="outlined" onClick={handleAddTagToPlaybook}>
                Add tag
              </CydButton>
            </div>
          </div>

          <div
            className={playbookActionContainer}
            css={(theme) => `
              gap: ${theme.spacing(2)};
            `}
          >
            <CydButton
              onClick={() => handleExportPlaybook(playbook.uuid || '')}
              variant="outlined"
            >
              Export Playbook
            </CydButton>
            <CydButton
              onClick={() => handleDuplicatePlaybook(playbook.uuid || '')}
              variant="outlined"
            >
              Duplicate Playbook
            </CydButton>
          </div>
          <div
            className={playbookActionDuplicateContainer}
            css={css`
              padding: 0;
            `}
          >
            <CydButton variant="outlined" onClick={() => setDeleteDialog(true)}>
              Delete Playbook
            </CydButton>
          </div>
        </div>
        {deleteDialog && (
          <CydBaseModal
            title="Are you sure?"
            isOpen
            onClose={() => setDeleteDialog(false)}
          >
            <>
              <div>
                <Typography
                  variant="body1"
                  gutterBottom
                  css={(theme) => `
                margin-bottom: ${theme.spacing(4)}
                `}
                >
                  Do you want to delete this playbook?
                </Typography>
              </div>
              <CydButtonStack justifyContent="flex-end">
                <CydButton
                  variant="text"
                  onClick={() => setDeleteDialog(false)}
                >
                  Cancel
                </CydButton>
                <CydButton onClick={handleDeleteConfirmation}>
                  Continue
                </CydButton>
              </CydButtonStack>
            </>
          </CydBaseModal>
        )}
      </div>
    </div>
  );
};
