import { useDefaultLayoutBannerStyles } from './style';
import { useSystemPropValue } from 'hooks/SysPropsHooks';
import { SYSTEM_PROPERTY } from '../../../constants';
import { isAuthenticated } from 'utils/TokenManager';

function Inner() {
  const textColor = useSystemPropValue(
    SYSTEM_PROPERTY.CLASSIFICATION_TEXT_RGB,
    '#121212'
  );
  const bgColor = useSystemPropValue(
    SYSTEM_PROPERTY.CLASSIFICATION_BG_RGB,
    '#fff'
  );
  const text = useSystemPropValue(SYSTEM_PROPERTY.CLASSIFICATION_TEXT);

  const { container } = useDefaultLayoutBannerStyles({ textColor, bgColor });

  return <>{text && <div className={container}>{text}</div>}</>;
}

export const DefaultLayoutBanner = () => {
  if (isAuthenticated()) {
    return <Inner />;
  }
  return null;
};
