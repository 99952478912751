/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { BaseCustomNodeProps, REACT_FLOW_NODE_WIDTH } from './BaseCustomNode';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { innerBorder, outerBorder } from './_commonBorderStyles';
// referencing BaseCustomNodeProps directly is a temporary shortcut for now.
// ulimately all of these compoennts should use the BaseNode directly

const CIRCLE_SIZE = 60;

export const BaseCircleNode = (
  props: BaseCustomNodeProps & { label: string }
) => {
  const { data } = props;
  return (
    <div
      css={(theme) => `
        ${outerBorder(theme, data)}
        padding: ${theme.spacing(4)}; 
        width: ${CIRCLE_SIZE}px;
        height: ${CIRCLE_SIZE}px;
        border-radius: 50%;
        position: relative;
        margin-left: ${(REACT_FLOW_NODE_WIDTH - CIRCLE_SIZE) / 2}px;
      `}
    >
      <div
        css={(theme) => `
          ${innerBorder(theme, data)}
          position: absolute;
          inset: -2px;
          border-radius: 50%;
        `}
      ></div>

      <Typography
        color="text.primary"
        textAlign="center"
        css={css`
          position: absolute;
          left: 0;
          right: 0;
        `}
      >
        {props.label}
      </Typography>
      {props.children}
    </div>
  );
};
