import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import React, { PropsWithChildren } from 'react';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';

/** @jsxImportSource @emotion/react */

export const DefaultContainer = <T extends {}>(props: PropsWithChildren<T>) => {
  return <>{props.children}</>;
};

export const DefaultFallback = <T extends {}>(props: PropsWithChildren<T>) => {
  const { isToggleEnabled } = useFeatureToggles();
  const isDebugMode = isToggleEnabled('REACT_APP_DEBUG_MODE');

  if (isDebugMode) {
    return (
      <div
        css={(theme) => `
    color: ${theme.palette.error.main}; 
    border: ${theme.palette.error.main}

    width: 50px; 
    height: 50px; 
  `}
      >
        This component is in error
      </div>
    );
  }

  return <>{props.children}</>;
};

export const withErrorBoundary =
  <T extends {}>(
    Component: React.ComponentType<T>,
    Container: React.ComponentType<
      React.PropsWithChildren<T>
    > = DefaultContainer,
    FallBack: React.ComponentType<T> = DefaultFallback
  ) =>
  (props: T) => {
    return (
      <ErrorBoundary
        ContainerComponent={Container}
        /* @ts-ignore - IDE isn't showing an error, not sure what is happening here - maybe something to do with the css injection? */
        FallbackComponent={() => <FallBack {...props} />}
      >
        {/* @ts-ignore - IDE isn't showing an error, not sure what is happening here - maybe something to do with the css injection? */}

        <Component {...props} />
      </ErrorBoundary>
    );
  };
