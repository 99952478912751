/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { PropsWithChildren } from 'react';

import {
  GenericNodeWithPossibleStatus,
  REACT_FLOW_NODE_MAX_HEIGHT,
  REACT_FLOW_NODE_WIDTH
} from './BaseCustomNode';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydIcon, CydIconTypes } from 'components/_foundation/CydIcon/CydIcon';
import { Typography } from '@mui/material';
import { innerBorder, outerBorder } from './_commonBorderStyles';
export type BaseDiamondNodeProps = PropsWithChildren<{
  data: GenericNodeWithPossibleStatus;
  nodeType: 'parallel' | 'if-condition' | 'while-condition' | 'switch';
  className?: string;
  label?: string;
}>;

// nb. this values are important.
// utils/autoLayout uses them to determine the positioning of the nodes.
// Our strategy (for now) is that all nodes are always the same layout size
// If we want to improve on this, we will need to do some kind of detecting dynamic sizing type thing.

const IconMap: Record<BaseDiamondNodeProps['nodeType'], CydIconTypes> = {
  'if-condition': 'close',
  parallel: 'add',
  'while-condition': 'timer',
  switch: 'snowFlake'
};

const DIAMOND_CONTAINER_WIDTH = REACT_FLOW_NODE_MAX_HEIGHT;
const DIAMOND_HEIGHT = Math.sqrt(2 * (DIAMOND_CONTAINER_WIDTH / 2) ** 2);

export const BaseDiamondNode = (props: BaseDiamondNodeProps) => {
  const { children, data, nodeType, label } = props;

  return (
    <>
      {/* Container for the diamond, everything else to sit in to sit in */}
      <div
        css={(theme) => `
          display: block;
          margin: 0 auto;
          height: ${DIAMOND_CONTAINER_WIDTH}px;
          width: ${DIAMOND_CONTAINER_WIDTH}px;
          margin-left: ${
            (REACT_FLOW_NODE_WIDTH - DIAMOND_CONTAINER_WIDTH) / 2
          }px;
          position: relative;
          border: solid 1px transparent;
        `}
      >
        {/* Diamond */}
        <div
          css={(theme) => `
          ${outerBorder(theme, data)}
          transform: rotate(-45deg); 
          width: ${DIAMOND_HEIGHT}px;
          height: ${DIAMOND_HEIGHT}px; 
          margin: ${(DIAMOND_CONTAINER_WIDTH - DIAMOND_HEIGHT) / 2}px;
      `}
        >
          <div
            css={(theme) => `
             ${innerBorder(theme, data)}
              position: absolute;
              inset: -2px;
            `}
          ></div>
        </div>

        <CydIcon
          icon={IconMap[nodeType]}
          css={(theme) => `
          position: absolute; 
          left: 50%;
          transform: translate(-50%, -50%);
          height: 60%; 
          width: 60%; 
          top: 50%;
          color: ${theme.other.border}
        `}
        />

        {label && (
          <Typography
            variant="body2"
            color="text.primary"
            textAlign="center"
            css={css`
              top: -10px;
              left: ${DIAMOND_HEIGHT + 30}px;
              text-align: left;
              position: absolute;
              display: flex;
              align-items: center;
              height: ${DIAMOND_CONTAINER_WIDTH}px;
              width: ${REACT_FLOW_NODE_WIDTH - DIAMOND_CONTAINER_WIDTH}px;
            `}
          >
            <span>{label}</span>
          </Typography>
        )}
        {children}
      </div>
    </>
  );
};
