/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeEvery } from 'redux-saga/effects';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { fetchSamlLoginUrlSuccess } from './slice';
import { createFetchSamlLoginUrl } from './actions';
import { apiFetchSamlLoginUrl } from 'services/ReportServices';

function* fetchSamlLoginUrlSaga({ payload: location }: { payload: string }) {
  try {
    const { json } = yield apiFetchSamlLoginUrl(location);
    yield put(fetchSamlLoginUrlSuccess(json.url));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_SAML_LOGIN_URL_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchSamlLoginUrl() {
  yield takeEvery(createFetchSamlLoginUrl, fetchSamlLoginUrlSaga);
}

export default [watchFetchSamlLoginUrl()];
