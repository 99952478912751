/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLeading } from 'redux-saga/effects';
import { fetchAllResources } from './actions';
import { fetchResourcesListSuccess } from './slice';
import { addNotification } from 'states/notifications/slice';
import { ERROR_MESSAGE } from './errors';
import { apiFetchResourceList } from 'services/ReportServices';

export function* performFetchResourceList() {
  try {
    const { json } = yield apiFetchResourceList();
    yield put(fetchResourcesListSuccess(json));
  } catch (ex) {
    yield put(addNotification(ERROR_MESSAGE.FETCH_RESOURCE_LIST_ERROR));
  }
}

/* Watchers */
function* watchFetchResourceList() {
  yield takeLeading(fetchAllResources, performFetchResourceList);
}

export default [watchFetchResourceList()];
