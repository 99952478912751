/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  FETCH_ACL_GROUPS_ERROR: {
    code: 'FETCH_ACL_GROUPS_ERROR',
    message: 'Error occurred while fetching ACL group'
  }
};

export { ERROR_MESSAGE };
