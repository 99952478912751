/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useState } from 'react';
import { css } from '@emotion/react';
import {
  CydSimpleFormModal,
  CydSimpleFormModalProps
} from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { ICaseMeta } from 'interface/Case.interface';
import { useMetas } from 'hooks/MetaHooks';
import {
  CydMetadataForm,
  CydMetadataFormItem
} from 'components/_caseView/CydMetadataForm/CydMetadataForm';
import { MODAL_SIZE_WIDTH_MAP } from 'components/_dialogs/CydBaseModal/CydBaseModal';

/** @jsxImportSource @emotion/react */

type CydCaseMetadataModalProps = Omit<CydSimpleFormModalProps, 'children'> & {
  metadata: { [key: string]: ICaseMeta };
  onSave: (selectedMetadataItems: { [key: string]: string }) => void;
};

export const CydCaseMetadataModal = ({
  metadata,
  onClose,
  onSave,
  ...props
}: CydCaseMetadataModalProps) => {
  const initialMetadataTypeAndValues: Array<CydMetadataFormItem> = Object.keys(
    metadata
  ).map((key) => ({
    metadataType: metadata[key].name,
    value: metadata[key].value
  }));
  const [selectedMetadataTypeAndValues, setSelectedMetadataTypeAndValues] =
    useState<Array<CydMetadataFormItem>>(initialMetadataTypeAndValues);
  const { data: metas } = useMetas();
  const title = 'Metadata';

  const availableMetadataTypes = metas.map(({ name }) => name);

  const handleCancel = (ev) => {
    setSelectedMetadataTypeAndValues(initialMetadataTypeAndValues);
    onClose && onClose(ev);
  };

  const handleSubmit = (ev) => {
    // prevent default so that the page will not be refreshed after form submission
    ev.preventDefault();
    const newMetadata = selectedMetadataTypeAndValues
      // Backend return failure if metadata type field is empty
      // filter out metadata fields that are empty
      .filter((metadata) => metadata.metadataType || metadata.value)
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.metadataType]: curr.value
        }),
        {}
      );
    onSave && onSave(newMetadata);
  };

  return (
    <CydSimpleFormModal
      {...props}
      onClose={handleCancel}
      title={title}
      css={css`
        width: ${MODAL_SIZE_WIDTH_MAP.xxlarge};
      `}
    >
      <form onSubmit={handleSubmit}>
        <CydMetadataForm
          title={title}
          availableMetadataTypes={availableMetadataTypes}
          selectedMetadataTypeAndValues={selectedMetadataTypeAndValues}
          onChange={(newSelectedItems) => {
            setSelectedMetadataTypeAndValues(newSelectedItems);
          }}
        />
        <CydButtonStack justifyContent="flex-end">
          <CydButton variant="text" onClick={handleCancel}>
            Cancel
          </CydButton>
          <CydButton type="submit">Save</CydButton>
        </CydButtonStack>
      </form>
    </CydSimpleFormModal>
  );
};
