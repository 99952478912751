/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  CydApi_Actuator,
  CydApi_Connector,
  CydApi_ConnectorOperationType,
  CydApi_ConnectorType,
  CydApi_CreateConnectorRequest,
  CydApi_EventTrigger,
  CydApi_EventTriggerRequestFields,
  CydApi_UpdateConnectorRequest
} from 'services/ConnectorsService';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { CYDARM_BASE_API } from '../constants';
import { imperativeAddNotification } from 'states/imperitiveAddNotif';
import { extraIsApiServices, handleRtkqError } from 'utils/rtkqUtils';
import { connectorOperationTypeService } from 'services/openapi/ConnectorOperationTypeService';
import { eventTriggerService } from 'services/openapi/EventTriggerService';

export const rtkqConnectorsApi = createApi({
  reducerPath: 'rtk-connectors',
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),

  tagTypes: [
    'connector',
    'connector-type',
    'connector-operation-type',
    'actuator',
    'event-trigger'
  ],
  endpoints: (builder) => {
    return {
      updateConnector: builder.mutation<
        unknown,
        {
          config: CydApi_UpdateConnectorRequest;
          connectorUuid: string;
        }
      >({
        invalidatesTags: ['connector'],

        queryFn: async (arg, api, extra, base) => {
          const apiService = extraIsApiServices(api.extra);

          try {
            const result = await apiService.apiUpdateConnector(
              arg.config,
              arg.connectorUuid
            );

            imperativeAddNotification({
              message: 'Updated connector.'
            });
            return {
              data: result.json
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'Failed to update connector.'
            });

            return await handleRtkqError(err);
          }
        }
      }),

      createConnector: builder.mutation<
        unknown,
        {
          connector: CydApi_CreateConnectorRequest;
        }
      >({
        invalidatesTags: ['connector'],
        queryFn: async (arg, api) => {
          const apiService = extraIsApiServices(api.extra);

          try {
            const result = await apiService.apiCreateConnector(arg.connector);

            imperativeAddNotification({
              message: 'Created connector.'
            });
            return {
              data: result.json
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'Failed to create connector.'
            });
            return await handleRtkqError(err);
          }
        }
      }),

      deleteConnector: builder.mutation<
        unknown,
        {
          connectorUuid: string;
        }
      >({
        invalidatesTags: ['connector'],

        queryFn: async (arg, api) => {
          try {
            const apiService = extraIsApiServices(api.extra);

            const result = await apiService.apiDeleteConnector(
              arg.connectorUuid
            );

            imperativeAddNotification({ message: 'Connector deleted.' });
            return {
              data: result.json
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'Failed to delete Connector.'
            });

            return await handleRtkqError(err);
          }
        }
      }),

      getAllConnectors: builder.query<Array<CydApi_Connector>, {}>({
        providesTags: ['connector'],
        queryFn: async function (arg, api, extra) {
          try {
            const apiService = extraIsApiServices(api.extra);
            const result = await apiService.apiGetConnectors();

            return {
              data: result.json.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                return 1;
              })
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),
      getAllActuators: builder.query<Array<CydApi_Actuator>, {}>({
        providesTags: ['actuator'],

        queryFn: async function (arg, api) {
          try {
            const apiService = extraIsApiServices(api.extra);

            const result = await apiService.apiGetActuators();
            return {
              data: result.json
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),
      getAllConnectorTypes: builder.query<Array<CydApi_ConnectorType>, {}>({
        providesTags: ['connector-type'],

        queryFn: async function (arg, api) {
          try {
            const apiService = extraIsApiServices(api.extra);

            const result = await apiService.apiGetConnectorTypes();
            return {
              data: result.json
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),
      getAllConnectionOperationTypes: builder.query<
        Array<CydApi_ConnectorOperationType>,
        {}
      >({
        providesTags: ['connector-operation-type'],

        queryFn: async function () {
          try {
            const result = await connectorOperationTypeService.get({
              pageNumber: 0,
              // TODO: This is a hack to get all the operation types
              pageSize: 1000
            });
            return {
              data: result.data!
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),
      getConnectorOperationTypeByUuid: builder.query<
        CydApi_ConnectorOperationType,
        { uuid: string }
      >({
        providesTags: ['connector-operation-type'],
        queryFn: async function ({ uuid }) {
          if (!uuid) {
            throw new Error('uuid is not defined');
          }
          try {
            const result = await connectorOperationTypeService.getByUuid(uuid);

            return {
              data: result.data!
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),

      getAllEventTriggers: builder.query<Array<CydApi_EventTrigger>, {}>({
        providesTags: ['event-trigger'],

        queryFn: async function () {
          try {
            const response = await eventTriggerService.get({
              pageNumber: 0,
              // TODO: This is a hack to get all event triggers
              pageSize: 1000
            });
            return {
              data: response.data!
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),

      deleteEventTrigger: builder.mutation<unknown, { uuid: string }>({
        invalidatesTags: ['event-trigger'],
        queryFn: async ({ uuid }, api) => {
          try {
            const result = await eventTriggerService.delete(uuid);

            imperativeAddNotification({
              message: 'Deleted Triggered Automation.'
            });
            return {
              data: result
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'Failed to delete Triggered Automation.'
            });
            return await handleRtkqError(err);
          }
        }
      }),

      createEventTrigger: builder.mutation<
        unknown,
        CydApi_EventTriggerRequestFields
      >({
        invalidatesTags: ['event-trigger'],
        queryFn: async (eventTriggerFields) => {
          try {
            const result = await eventTriggerService.create(eventTriggerFields);
            imperativeAddNotification({
              message: 'Created Triggered Automation.'
            });
            return {
              data: result.data
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'Failed to create Triggered Automation.'
            });
            return await handleRtkqError(err);
          }
        }
      }),

      updateEventTrigger: builder.mutation<
        unknown,
        { uuid: string; fields: CydApi_EventTriggerRequestFields }
      >({
        invalidatesTags: ['event-trigger'],
        queryFn: async ({ uuid, fields }) => {
          try {
            const result = await eventTriggerService.update(uuid, fields);
            imperativeAddNotification({
              message: 'Updated Triggered Automation.'
            });
            return {
              data: result
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'Failed to update Triggered Automation.'
            });
            return await handleRtkqError(err);
          }
        }
      })
    };
  }
});

export function useActuators() {
  return rtkqConnectorsApi.endpoints.getAllActuators.useQuery({});
}

export function useConnectors() {
  return rtkqConnectorsApi.endpoints.getAllConnectors.useQuery({});
}

export function useConnectorTypes() {
  return rtkqConnectorsApi.endpoints.getAllConnectorTypes.useQuery({});
}

export function useConnectorOperationTypes() {
  return rtkqConnectorsApi.endpoints.getAllConnectionOperationTypes.useQuery(
    {}
  );
}

export function useConnectorOperationTypeByUuid(uuid: string) {
  return rtkqConnectorsApi.endpoints?.getConnectorOperationTypeByUuid.useQuery({
    uuid
  });
}

export function useUpdateConnector() {
  return rtkqConnectorsApi.endpoints.updateConnector.useMutation();
}

export function useDeleteConnector() {
  return rtkqConnectorsApi.endpoints.deleteConnector.useMutation();
}

export function useCreateConnector() {
  return rtkqConnectorsApi.endpoints.createConnector.useMutation();
}

// Event Triggers
export function useEventTriggers() {
  return rtkqConnectorsApi.endpoints.getAllEventTriggers.useQuery({});
}

export function useCreateEventTrigger() {
  return rtkqConnectorsApi.endpoints.createEventTrigger.useMutation();
}

export function useUpdateEventTrigger() {
  return rtkqConnectorsApi.endpoints.updateEventTrigger.useMutation();
}

export function useDeleteEventTrigger() {
  return rtkqConnectorsApi.endpoints.deleteEventTrigger.useMutation();
}
