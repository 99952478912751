/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonApiResourceId
 */
export interface JsonApiResourceId {
    /**
     * 
     * @type {string}
     * @memberof JsonApiResourceId
     */
    type?: string;
    /**
     * The UUID of the related object
     * @type {string}
     * @memberof JsonApiResourceId
     */
    id?: string;
    /**
     * The local UUID of the related object, for creating new objects
     * @type {string}
     * @memberof JsonApiResourceId
     */
    lid?: string;
}

/**
 * Check if a given object implements the JsonApiResourceId interface.
 */
export function instanceOfJsonApiResourceId(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonApiResourceIdFromJSON(json: any): JsonApiResourceId {
    return JsonApiResourceIdFromJSONTyped(json, false);
}

export function JsonApiResourceIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiResourceId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lid': !exists(json, 'lid') ? undefined : json['lid'],
    };
}

export function JsonApiResourceIdToJSON(value?: JsonApiResourceId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'lid': value.lid,
    };
}

