/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { ICreateCaseDataRequest } from 'interface/CaseData.interface';
import { cyFetchAuthenticated, CyFetchResponse } from 'utils/CydarmFetch';
import { Base64Decode, batchUuidStrings } from 'utils/StringUtils';
import { apiFetchCaseDatasetTz } from 'services/DataService';

export type ActionInstanceComment = {
  dataStubUuid: string;
  acl: string;
  creatoruuid: string;
  created: string;
  content: string;

  permissions: {
    deletable: boolean;
    editable: boolean;
    manageable: boolean;
    readable: boolean;
  };
};

export async function apiGetCommentsOnActionInstance(
  actionInstanceUuid: string
): Promise<Array<ActionInstanceComment>> {
  const result = (await cyFetchAuthenticated(
    `/action-instance/${actionInstanceUuid}/data`,
    {
      method: 'GET'
    }
  )) as CyFetchResponse<Array<any>>;

  if (result.json.length === 0) {
    return [];
  }

  // We need to batch uuids to make sure it doesn't exceed the max url length
  const uuidStrings = batchUuidStrings(result.json.map((v) => v.uuid));

  const allProms = uuidStrings.map((v) => {
    return apiFetchCaseDatasetTz(v, '');
  });

  const allResults = await Promise.all(allProms);

  const joinedResult = allResults.flatMap((v) => v.json);

  const dataStubMap = result.json.reduce((acc, cur) => {
    acc[cur.uuid] = cur;
    return acc;
  }, {});

  return joinedResult.map((v) => {
    const dataStub = dataStubMap[v.dataStubUuid];
    return {
      dataStubUuid: dataStub.uuid,
      acl: dataStub.acl,
      created: dataStub.created,
      creatoruuid: dataStub.creatoruuid,
      content: Base64Decode(v.bytedata),

      permissions: {
        deletable: v.deletable,
        editable: v.editable,
        manageable: v.manageable,
        readable: v.readable
      }
    };
  });
}

export type CydApi_CreateActionInstanceCommentRequest = ICreateCaseDataRequest;

export function apiCreateCommentOnActionInstance(
  actionInstanceUuid: string,
  comment: ICreateCaseDataRequest
): Promise<CyFetchResponse<{}>> {
  const data = {
    ...comment,
    acl_uuid: comment.acl?.uuid
  };
  return cyFetchAuthenticated(`/action-instance/${actionInstanceUuid}/data`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
}
