/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSelector } from 'reselect';
import { IStore } from 'states/store.interface';

export const orgListSelector = ({ orgs: { list } }: IStore) => list;

export const singleOrgSelector = createSelector(
  orgListSelector,
  (_, uuid) => uuid,
  (orgs, orgUuid) => orgs.find((org) => org.uuid === orgUuid)
);

export const singleOrgByNameSelector = createSelector(
  orgListSelector,
  (_, orgName) => orgName,
  (orgs, orgName) => orgs.find((org) => org.name === orgName)
);

export const multiOrgSelector = createSelector(
  orgListSelector,
  (orgs) => orgs.length > 1
);

export const orgStatusSelector = ({ orgs: { isLoading, hasLoaded } }: IStore) =>
  isLoading || hasLoaded;

export const orgHasLoadedSelector = ({ orgs: { hasLoaded } }: IStore) =>
  hasLoaded;
