/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { CydTextField } from 'components/_formElements/CydTextField';
import moment from 'moment';
import {
  PlaybookAction,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { useDispatch } from 'react-redux';
import { createUpdateAtcPlaybookAction } from 'states/playbookActions/actions';
import { useStyles } from './style';
import { useCurrentDataAcls } from 'hooks/AclHooks';
import { AclSelect } from 'components/fields/AclSelect';
import { Acl } from 'interface/Acl.interface';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydBaseModal } from 'components/_dialogs/CydBaseModal/CydBaseModal';
import { useParams } from 'react-router';
import { createFetchPlaybookByUuid } from 'states/playbooks/actions';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

/** @jsxImportSource @emotion/react */

interface IPlaybookActionsPopoverProps {
  onClose: () => void;
  playbookAction: PlaybookActionInstance; // Surely this should be PlaybookAction?
}

// no longer a popover. is modal content.
export const PlayBookActionsPopover: React.FC<IPlaybookActionsPopoverProps> = ({
  onClose,
  playbookAction
}) => {
  const { data: aclList } = useCurrentDataAcls();
  const params = useParams<{ playbookUuid: string }>();
  const divStyle = useStyles();
  const dispatch = useDispatch();
  const existsAcl =
    aclList &&
    aclList.find((el) => playbookAction && el.uuid === playbookAction.acl);

  const [newPlaybookActionsName, setNewPlaybookActionsName] =
    React.useState('');
  const [newPlaybookActionsDesc, setNewPlaybookActionsDesc] =
    React.useState('');
  const [newPlaybookAcl, setNewPlaybookAcl] = React.useState<Acl | undefined>(
    existsAcl
  );

  const handleNameChange = React.useCallback(
    (event: any, defaultValue: string) => {
      event.target.value === ''
        ? setNewPlaybookActionsName(defaultValue)
        : setNewPlaybookActionsName(event.target.value);
    },
    [setNewPlaybookActionsName]
  );

  const handleAclChange = React.useCallback(
    (event: any) => {
      event.target.value && setNewPlaybookAcl(event.target.value);
    },
    [setNewPlaybookAcl]
  );

  const handleOnClose = React.useCallback(() => {
    setNewPlaybookAcl(undefined);
    onClose();
  }, [setNewPlaybookAcl, onClose]);

  const handleSubmitAction = (data: PlaybookAction) => {
    const editNameValue =
      newPlaybookActionsName === '' ? data.name : newPlaybookActionsName;
    const editDescValue =
      newPlaybookActionsDesc === '' ? data.description : newPlaybookActionsDesc;

    const resolveAction = params.playbookUuid
      ? createFetchPlaybookByUuid(params.playbookUuid)
      : undefined;

    data.uuid &&
      dispatch(
        createUpdateAtcPlaybookAction({
          uuid: data.uuid,
          playbookAction: {
            ...data,
            name: editNameValue,
            description: editDescValue,
            modified: moment().format(),
            acl: newPlaybookAcl && newPlaybookAcl.uuid
          },
          resolveAction
        })
      );
    setNewPlaybookActionsName('');
    setNewPlaybookActionsDesc('');
    onClose();
  };

  return (
    <CydBaseModal
      isOpen
      size="xlarge"
      title="Edit action"
      onClose={handleOnClose}
    >
      <form
        onSubmit={(ev) => {
          // This fixed Cypress Test by preventing page refresh on form submission
          ev.preventDefault();
          handleSubmitAction(playbookAction);
        }}
      >
        {playbookAction && (
          <div className={divStyle.root}>
            <CydTextField
              label="Action name"
              data-testid="edit-action-name"
              defaultValue={playbookAction.name}
              onChange={(value, e) => {
                handleNameChange(e, playbookAction.name || '');
              }}
            />
          </div>
        )}
        {playbookAction && (
          <div className={divStyle.root}>
            <p
              className={divStyle.root}
              css={(theme) => `
              color: ${theme.palette.grey[400]}
              `}
            >
              Action Description
            </p>
            <CydEnhancedMarkdownEditor
              name="description"
              label="Description"
              onChange={(description) => {
                setNewPlaybookActionsDesc(description);
              }}
              initialValue={playbookAction.description}
              height="260px"
              autoFocus={false}
            />
          </div>
        )}
        {playbookAction && (
          <div className={divStyle.root}>
            <AclSelect
              acls={aclList}
              onChange={(e) => handleAclChange(e)}
              value={newPlaybookAcl ? newPlaybookAcl : existsAcl}
            />
          </div>
        )}

        <CydButtonStack justifyContent="flex-end">
          <CydButton variant="text" onClick={handleOnClose}>
            Cancel
          </CydButton>
          <CydButton type="submit" variant="contained">
            Save
          </CydButton>
        </CydButtonStack>
      </form>
    </CydBaseModal>
  );
};
