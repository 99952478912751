/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface INotificationPayload {
  message: string;
  expiryTime?: number;
}

export interface INotification {
  message: string;
  id: string;
  timestamp: number;
  expiryTime?: number;
}

export interface INotificationStoreState {
  notifications: INotification[];
  notificationDrawerOpen: boolean;
}

const initialState: INotificationStoreState = {
  notifications: [],
  notificationDrawerOpen: false
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: {
      prepare: (payload: INotificationPayload) => {
        return {
          payload: {
            ...payload,
            timestamp: Date.now(),
            id: uuidv4()
          }
        };
      },
      reducer: (state, { payload }: PayloadAction<INotification>) => {
        state.notifications.push(payload);
      }
    },
    removeNotification: (state, { payload }: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        ({ id }) => id !== payload
      );
    },
    openNotificationDrawer: (state) => {
      state.notificationDrawerOpen = true;
    },
    closeNotificationDrawer: (state) => {
      state.notificationDrawerOpen = false;
    }
  }
});

export const {
  reducer: notificationStore,
  actions: {
    addNotification,
    removeNotification,
    openNotificationDrawer,
    closeNotificationDrawer
  }
} = notificationSlice;
