/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import moment from 'moment';
import { DefaultSelect } from 'components/fields/DefaultSelect';
import { Grid, Typography } from '@mui/material';
import { object, mixed, string } from 'yup';
import { DateTimePicker } from 'components/_formElements/DatePickers';

export interface ICaseDataReportFormData {
  startDate?: string;
  endDate?: string;
  reportFormat: string;
  errorMessage?: string;
}

interface ICaseDataReportFormProps {
  onSubmit: (data: ICaseDataReportFormData) => void;
  handleDateChange: (data) => void;
  formRef?: React.RefObject<any>;
  timelineLink: boolean;
  timelineStartDate: string;
  timelineEndDate: string;
}

const initialValues = {
  startDate: Date(),
  endDate: Date(),
  reportFormat: 'docx'
};

const formatOptions = [
  {
    value: 'docx',
    label: 'Microsoft Word'
  }
];

const validationSchema = object({
  startDate: mixed().required('Please select a start date'),
  endDate: mixed().required('Please select an end date'),
  reportFormat: string().required('Please select a format')
});

const handlerTimeFormat = 'YYYY-MM-DDTHH:mm';

export const CaseDataReportForm: React.FC<ICaseDataReportFormProps> = ({
  formRef,
  onSubmit,
  handleDateChange,
  timelineLink,
  timelineStartDate,
  timelineEndDate
}) => {
  const handleChangeDate = (
    handleChange: FormikProps<ICaseDataReportFormData>['handleChange'],
    values,
    name,
    date
  ) => {
    const handler = handleChange(name);
    !timelineLink && handler && handler(date);
    if (timelineLink) {
      const parsedDate = new Date(date);
      if (name === 'startDate') {
        handleDateChange(([, endDate]) => [parsedDate, endDate]);
      }
      if (name === 'endDate') {
        handleDateChange(([startDate]) => [startDate, parsedDate]);
      }
    }
  };

  const validationEndDate = (values: ICaseDataReportFormData) => {
    const errors: { errorMessage?: string | null } = {};

    errors.errorMessage = moment(values.startDate).isSameOrBefore(
      moment(values.endDate)
    )
      ? null
      : 'End date must be after start date';

    if (
      moment(values.endDate).format(handlerTimeFormat) ===
      moment(values.startDate).format(handlerTimeFormat)
    ) {
      errors.errorMessage = 'End date and start date should not be the same';
    }

    if (errors.errorMessage === null) {
      return;
    }
    return errors;
  };

  const renderForm = ({
    handleChange,
    values,
    errors
  }: FormikProps<ICaseDataReportFormData>) => {
    values.startDate = (() => {
      // If date component linked to timeline graph, substract 15 minutes from start date
      if (timelineLink) {
        return moment(timelineStartDate).subtract(15, 'minutes').toString();
      }
      // date component is not linked, return value chosen by user
      else {
        return values.startDate;
      }
    })();
    values.endDate = (() => {
      if (timelineLink) {
        return moment(timelineEndDate).add(15, 'minutes').toString();
      } else {
        return values.endDate;
      }
    })();
    const formattedStartDate = values.startDate
      ? moment(values.startDate).format(handlerTimeFormat)
      : '';
    const formattedEndDate = values.endDate
      ? moment(values.endDate).format(handlerTimeFormat)
      : '';
    return (
      <Form>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography>Format</Typography>
            <DefaultSelect
              name="reportFormat"
              value={values.reportFormat}
              onChange={handleChange}
              options={formatOptions}
              placeholder="Select format"
              disabledPlaceholder
            />
          </Grid>
          <Grid item xs>
            <Typography>Selected Start Date</Typography>
            <DateTimePicker
              type="datetime-local"
              value={formattedStartDate}
              disabled={timelineLink}
              onChange={(e) =>
                handleChangeDate(
                  handleChange,
                  values,
                  'startDate',
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs>
            <Typography>Selected End Date</Typography>
            <DateTimePicker
              type="datetime-local"
              value={formattedEndDate}
              disabled={timelineLink}
              onChange={(e) =>
                handleChangeDate(
                  handleChange,
                  values,
                  'endDate',
                  e.target.value
                )
              }
            />
            {errors.errorMessage && (
              <div style={{ color: 'red', fontSize: 15 }}>
                {errors.errorMessage}
              </div>
            )}
          </Grid>
        </Grid>
      </Form>
    );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      validate={validationEndDate}
    >
      {renderForm}
    </Formik>
  );
};
