import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import { usersWithDisplayNameSelector } from 'states/users/selectors';
import { User } from 'interface/User.interface';
import config from 'config';

const caseDataSelector = ({ data }: IStore) => data.cases;

export const multiCaseActivitySelector = createSelector(
  caseDataSelector,
  usersWithDisplayNameSelector,
  (_, caseUuids) => caseUuids,
  (caseData, users, caseUuids) =>
    caseUuids.reduce(
      (acc, cur) => [
        ...acc,
        ...((caseData[cur] &&
          caseData[cur].map((data) => ({
            ...data,
            creator: users.find(({ uuid }) => uuid === data.creatoruuid)
          }))) ||
          [])
      ],
      []
    )
);

export const dataSignificancesSelector = ({
  data: { dataSignificances }
}: IStore) => dataSignificances;

export const filteredDataSignificancesSelector = createSelector(
  dataSignificancesSelector,
  (dataSigs) => dataSigs.filter((s) => s.selectable)
);

export const defaultDataSignificanceSelector = createSelector(
  dataSignificancesSelector,
  (dataSignificances) =>
    dataSignificances.find(
      ({ precedence }) => precedence === config.DEFAULT_DATA_SIGNIFICANCE
    )
);

export const dataSignificancesStatusSelector = ({
  data: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;

export const caseContributorsSelector = (caseData: Array<any>) =>
  createSelector(usersWithDisplayNameSelector, (users) => {
    return caseData
      .reduce(
        (uniqueCreatorUuids: string[], { creatoruuid }) =>
          creatoruuid && uniqueCreatorUuids.includes(creatoruuid)
            ? uniqueCreatorUuids
            : [...uniqueCreatorUuids, creatoruuid],
        []
      )
      .map((creatorUuid) => users.find(({ uuid }) => uuid === creatorUuid))
      .filter((contributor) => !!contributor) as User[];
  });
