import { useEffect, useRef } from 'react';

// See: https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

const EMPTY_OBJECT = {};
//https://usehooks-ts.com/react-hook/use-interval
/**
 *
 * @param callback
 * @param delay
 * @param jitterMs - to prevent API calls being made _exactly_ every 5 seconds or whatever, you can add some jitter to the interval to randomise it
 */
export function useInterval(
  callback: () => void,
  delay: number | null,
  options: {
    jitterMs?: number;
    onlyPollIfWindowActive?: boolean;
  } = EMPTY_OBJECT
) {
  const { jitterMs, onlyPollIfWindowActive } = options;
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const shouldPollRef = useRef(true);

  useEffect(() => {
    if (onlyPollIfWindowActive) {
      // If they open in a new tab, we don't want the polling to occur
      shouldPollRef.current = document.visibilityState === 'visible';

      const visibilityHandler = () => {
        shouldPollRef.current = document.visibilityState === 'visible';
      };

      window.addEventListener('visibilitychange', visibilityHandler);
      return () => {
        window.removeEventListener('visibilitychange', visibilityHandler);
      };
    }

    return () => {};
  }, [onlyPollIfWindowActive, shouldPollRef]);

  // Set up the interval.
  useEffect(() => {
    // Call it immediately
    savedCallback.current();

    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => {
      if (shouldPollRef.current) {
        if (jitterMs) {
          setTimeout(
            () => {
              savedCallback.current();
            },
            Math.floor(Math.random() * jitterMs)
          );
        } else {
          savedCallback.current();
        }
      }
    }, delay);

    return () => clearInterval(id);
  }, [delay, jitterMs]);
}
