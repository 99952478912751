/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import React from 'react';
import {
  CydUserDisplay,
  CydUserDisplayProps
} from 'components/_objectDisplay/CydUserDisplay/CydUserDisplay';
import { useSingleUser } from 'hooks/UserHooks';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { CydObjectNotFound } from 'components/_objectDisplay/CydObjectNotFound/CydObjectNotFound';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CydFragment } from 'components/_utils/CydFragment';

type CydUserDisplayByUsernameProps = {
  userId: string;
} & Omit<CydUserDisplayProps, 'user'>;

export const CydUserDisplayById = withErrorBoundary(
  (props: CydUserDisplayByUsernameProps) => {
    const { userId, ...rest } = props;

    if (!userId) {
      return null;
    }

    const { data, isLoading, hasLoaded } = useSingleUser(userId);

    if (isLoading || !hasLoaded) {
      return <LoadingSpinner />;
    }

    if (!data) {
      throw new Error(`No user found for userId: ${userId}`);
    }
    return <CydUserDisplay user={data} {...rest} />;
  },
  CydFragment,
  (props) => <CydObjectNotFound objectId={props.userId} />
);
