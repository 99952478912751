/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import TableCell, { TableCellProps } from '@mui/material/TableCell';
import clsx from 'clsx';
import * as React from 'react';
import { useDefaultTableStyles } from './style';
import { TableSortLabel } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface IDefaultTableHeaderCellProps extends TableCellProps {
  onSortColumn?: (columnKey: string) => void;
  onFilter?: (columnKey: string, columnRef: React.RefObject<any>) => void;
  columnKey?: string;
  orderBy?: string;
  order?: string;
}

export const DefaultTableHeaderCell: React.FC<IDefaultTableHeaderCellProps> =
  React.memo(
    ({
      children,
      onSortColumn,
      columnKey,
      orderBy,
      order,
      onFilter,
      ...rest
    }) => {
      const {
        tableHeader,
        headerWithFilter,
        headerFilterIcon,
        headerSortIcon
      } = useDefaultTableStyles();

      const columnRef = React.useRef<any>();

      const handleSortLabelClick = React.useCallback(() => {
        if (!onSortColumn || !columnKey) {
          return;
        }

        onSortColumn(columnKey);
      }, [onSortColumn, columnKey]);

      const handleRequestFilter = React.useCallback(
        (event) => {
          if (!onFilter || !columnKey) {
            return;
          }

          event.stopPropagation();

          onFilter(columnKey, columnRef);
        },
        [onFilter, columnKey, columnRef]
      );

      const headerContent = onFilter ? (
        <div className={headerWithFilter}>
          <FilterListIcon
            className={headerFilterIcon}
            onClick={handleRequestFilter}
          />
          {children}
        </div>
      ) : (
        children
      );

      return (
        <TableCell
          {...rest}
          className={clsx(rest.className, tableHeader)}
          ref={columnRef}
        >
          {onSortColumn ? (
            <TableSortLabel
              active={orderBy === columnKey && !!order}
              direction={(order as any) || 'asc'}
              onClick={handleSortLabelClick}
              classes={{ icon: headerSortIcon }}
              IconComponent={ArrowDropDownIcon}
            >
              {headerContent}
            </TableSortLabel>
          ) : (
            headerContent
          )}
        </TableCell>
      );
    }
  );
