/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonApiLinks
 */
export interface JsonApiLinks {
    /**
     * Link to this resource
     * @type {string}
     * @memberof JsonApiLinks
     */
    self?: string;
    /**
     * Link to first page of resources, if this is a list
     * @type {string}
     * @memberof JsonApiLinks
     */
    first?: string;
    /**
     * Link to last page of resources, if this is a list
     * @type {string}
     * @memberof JsonApiLinks
     */
    last?: string;
    /**
     * Link to previous page of resources, if this is a list
     * @type {string}
     * @memberof JsonApiLinks
     */
    prev?: string;
    /**
     * Link to next page of resources, if this is a list
     * @type {string}
     * @memberof JsonApiLinks
     */
    next?: string;
}

/**
 * Check if a given object implements the JsonApiLinks interface.
 */
export function instanceOfJsonApiLinks(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonApiLinksFromJSON(json: any): JsonApiLinks {
    return JsonApiLinksFromJSONTyped(json, false);
}

export function JsonApiLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiLinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': !exists(json, 'self') ? undefined : json['self'],
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'prev': !exists(json, 'prev') ? undefined : json['prev'],
        'next': !exists(json, 'next') ? undefined : json['next'],
    };
}

export function JsonApiLinksToJSON(value?: JsonApiLinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'self': value.self,
        'first': value.first,
        'last': value.last,
        'prev': value.prev,
        'next': value.next,
    };
}

