/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { IUserProperties } from 'interface/User.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchAllUserProperty } from './actions';

export interface IUserPropsStoreState {
  isLoading: boolean;
  hasLoaded: boolean;
  userProps: IUserProperties[];
  errors?: string;
}

const initialState: IUserPropsStoreState = {
  userProps: [],
  isLoading: false,
  hasLoaded: false,
  errors: undefined
};

const userPropsSlice = createSlice({
  name: 'userProps',
  initialState,
  reducers: {
    fetchAllUserPropertySuccess: (
      state,
      { payload }: PayloadAction<IUserProperties[]>
    ) => ({
      ...state,
      userProps: payload,
      isLoading: false,
      hasLoaded: true,
      errors: undefined
    }),

    fetchAllUserPropertyFailed: (
      state,
      { payload }: PayloadAction<string>
    ) => ({ ...state, isLoading: false, errors: payload }),

    addUserPropertySuccess: (
      state,
      { payload }: PayloadAction<IUserProperties>
    ) => {
      state.userProps.push(payload);
    },
    createUpdateUserProperty: (
      state,
      { payload }: PayloadAction<IUserProperties>
    ) => {
      state.userProps = state.userProps.map((el) => {
        if (el.name === payload.name) {
          return payload;
        }
        return el;
      });
    },
    clearUserProperty: () => initialState
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchAllUserProperty, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: userPropsStore,
  actions: {
    fetchAllUserPropertySuccess,
    fetchAllUserPropertyFailed,
    addUserPropertySuccess,
    createUpdateUserProperty
  }
} = userPropsSlice;
