/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  transitionParent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacing(6),
    maxWidth: '100%',

    '& .fade-enter': {
      opacity: 0
    },
    '& .fade-enter-active': {
      opacity: 1,
      transition: 'opacity 500ms ease-in'
    },
    '& .fade-exit': {
      opacity: 1
    },
    '& .fade-exit-active': {
      opacity: 0,
      transition: 'opacity 500ms ease-in'
    }
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'left',
    color: palette.text.primary,
    marginBottom: 15
  },
  centered: {
    margin: '0 auto'
  },
  filterRow: {
    position: 'sticky',
    top: 74,
    width: '100%',
    zIndex: 10,
    padding: spacing(1, 0),
    background: alpha(palette.background.default, 0.5),
    backdropFilter: 'blur(20px) saturate(180%)'
  },
  contentWrapper: {
    padding: '16px 0'
  }
}));
