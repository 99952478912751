/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from 'hooks/AuthHooks';
import { useDataAcls } from 'hooks/AclHooks';
import {
  readCaseDataFile,
  convertStringtoMarkdownTable
} from 'utils/CaseDataUtils';
import { createAddCaseData } from 'states/data/actions';
import config from 'config.js';
import { Base64Encode } from 'utils/StringUtils';
import { useUploadFile } from 'hooks/CaseDataHooks';
import { Dropzone } from 'components/Dropzone/Dropzone';
import { Case } from 'interface/Case.interface';

interface IActivityViewDropzoneProps {
  className?: string;
  cydCase: Case;
  active: boolean;
}

export const ActivityViewDropzone: FC<IActivityViewDropzoneProps> = ({
  children,
  cydCase,
  className,
  active
}) => {
  const dispatch = useDispatch();
  const { data: currentUser } = useCurrentUser();
  const { data: storeAcl } = useDataAcls();
  const commentAcl =
    storeAcl.filter((el) => el.uuid === cydCase.acl) || config.DEFAULT_ACL;
  const currentUserUuid = (currentUser && currentUser.uuid) || '';
  const [uploadFile] = useUploadFile();

  const handleFileChange = useCallback(
    async (files: any, fileName?: string) => {
      if (!active) {
        return;
      }
      const fileData: File[] = files || [];
      const caseDataFile =
        fileData && (await readCaseDataFile(fileData, fileName));

      const ourFileData = caseDataFile.fileData;
      caseDataFile.fileData = [];

      ourFileData.forEach((file) => {
        if (!cydCase) {
          throw new Error('No caseData exists');
        }
        uploadFile({
          caseUuid: cydCase.uuid,
          data: {
            acl_uuid: cydCase.acl,
            data: file.data,
            fileLastMod: file.fileLastMod,
            mimeType: file.mimeType,
            significance: 'Comment',
            fileName: file.fileName
          }
        });
      });

      if (caseDataFile.stixData.length > 0) {
        const action = createAddCaseData({
          caseData: {
            data: Base64Encode(''),
            acl: commentAcl[0],
            significance: 'Comment',
            userUuid: currentUserUuid,
            caseUuid: cydCase.uuid,
            mimeType: 'text/plain',
            ...caseDataFile
          }
        });
        cydCase.uuid && dispatch(action);
      }
    },
    [active, cydCase, uploadFile, commentAcl, currentUserUuid, dispatch]
  );

  const handlePaste = (e) => {
    if (!active) {
      return;
    }
    const cbFiles = e.clipboardData.files;
    const cbTypes = e.clipboardData.types;
    if (cbFiles.length < 1) {
      return;
    } else if (cbTypes.includes('text/rtf')) {
      const cbItems = e.clipboardData.items;
      for (const element of cbItems) {
        const thisItem = element;
        const cbItemCallback = (data: string) => {
          const convertedString =
            data && convertStringtoMarkdownTable(data.trimEnd());
          convertedString && handleInputMarkdownTable(convertedString);
        };
        if (thisItem.type === 'text/plain') {
          thisItem.getAsString(cbItemCallback);
        }
      }
      return;
    }
    return handleFileChange(cbFiles, 'clipboardImage.png');
  };

  const handleInputMarkdownTable = useCallback(
    (inputString: string) => {
      const action = createAddCaseData({
        caseData: {
          data: Base64Encode(inputString),
          acl: commentAcl[0],
          significance: 'Comment',
          userUuid: currentUserUuid,
          caseUuid: cydCase.uuid,
          mimeType: 'text/plain'
        }
      });
      cydCase.uuid && dispatch(action);
    },
    [dispatch, cydCase.uuid, commentAcl, currentUserUuid]
  );

  return (
    <Dropzone
      active={active}
      className={className}
      onDrop={(file) => {
        handleFileChange(file);
      }}
      onPasteCapture={handlePaste}
    >
      {children}
    </Dropzone>
  );
};

export default ActivityViewDropzone;
