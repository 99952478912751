/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';
import { customScrollbar } from 'utils/StyleUtils';

export const useCaseViewPageActivitiesStyles = makeStyles(
  ({ spacing, shape: { borderRadius }, palette }) => ({
    activitiesWrapper: {
      maxHeight: '100%',
      overflowY: 'auto',
      boxSizing: 'border-box',
      padding: spacing(3),
      transition: 'transform 0.2s ease',
      paddingBottom: ({ shouldShowActivityDrawer, height }: any) =>
        shouldShowActivityDrawer ? height : spacing(3),
      ...customScrollbar(borderRadius)
    }
  })
);

export const useCaseViewPageNewActivityDrawerStyles = makeStyles(
  ({ spacing, shape: { borderRadius }, palette }) => ({
    drawer: ({ fullscreen, isOpened }: any) => ({
      padding: spacing(3),
      width: fullscreen
        ? `calc(100% - ${spacing(6)})`
        : `calc(100% - ${spacing(55)})`,
      left: fullscreen ? spacing(3) : spacing(50),
      backgroundColor: palette.background.paper,
      position: 'fixed',
      bottom: spacing(3),
      background: palette.background.paper,
      boxShadow: '0 -5px 10px rgba(0,0,0,0.5)',
      transform: 'translateY(0%)',
      transition: 'transform 0.3s ease',
      willChange: 'transform',
      borderRadius,
      ...(!isOpened && {
        visibility: 'hidden',
        transform: 'translateY(100%)'
      }),
      ...(fullscreen && {
        height: `calc(100% - ${spacing(6)})`,
        zIndex: 1101,
        '& .editor-toolbar.fullscreen': {
          background: 'none',
          '&::before, &::after': {
            display: 'none'
          }
        },
        '& > form, & .MuiGrid-container, & #simplemde-editor-1-wrapper': {
          height: '100%'
        },
        '& #simplemde-editor-1-wrapper': {
          position: 'relative'
        },
        '& .CodeMirror.CodeMirror-fullscreen': {
          position: 'absolute !important' as any,
          width: '100%',
          backgroundColor: palette.background.default,
          border: 'none',
          borderRadius: '10px !important',
          '&.CodeMirror-sided': {
            borderRadius: '10px 0 0 10px !important'
          }
        }
      })
    })
  })
);
