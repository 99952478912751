/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Global, css } from '@emotion/react';
import { customScrollbarCss } from 'utils/StyleUtils';

/** @jsxImportSource @emotion/react */

export const STYLE = {
  GUTTER: '8px'
};

//todo remove this, just declare these directly in the theme
export const COLOR = {
  BLACK: '#121212',
  WHITE: '#fff',
  BLUE_LITE: '#8FA5CC',

  PRIMARY: '#1976D2',
  PRIMARY_LITE: '#42A5F5',
  SECONDARY: '#669EFF',
  SECONDARY_LITE: '#4281ee'
};

export const GlobalStyles = () => (
  <Global
    styles={(theme) => css`
      html {
        box-sizing: border-box;
      }
      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }
      html,
      body,
      #root {
        height: 100%;
      }
      body,
      .docs-story {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        background-color: ${theme.palette.background.default};
        color: ${theme.palette.text.secondary};
      }

      select.form-control,
      textarea.form-control,
      input.form-control {
        font-size: 14px;
      }
      input[type='file'] {
        width: 100%;
      }

      a.plain-anchor:any-link {
        color: inherit;
        cursor: inherit;
        text-decoration: inherit;
      }

      a {
        color: ${theme.palette.text.secondary};
      }

      /* Clearfix */
      .clearfix:before,
      .clearfix:after {
        content: ' '; /* 1 */
        display: table; /* 2 */
      }

      * {
        ${customScrollbarCss};
      }

      .clearfix:after {
        clear: both;
      }
    `}
  />
);
