/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useMemo } from 'react';
import { SelectProps, Select, MenuItem } from '@mui/material';
import { usePlaybooks } from 'hooks/PlaybooksHooks';

export const PlaybookSelect2: React.FC<SelectProps> = ({ value, ...props }) => {
  const { data: playbooks } = usePlaybooks();

  const _value = useMemo(() => value || '', [value]);

  return (
    <Select value={_value} {...props}>
      <MenuItem value="">Select playbook</MenuItem>
      {playbooks.map(
        (playbook) =>
          playbook.atc && (
            <MenuItem value={playbook.atc.uuid} key={playbook.atc.uuid}>
              {playbook.atc.name}
            </MenuItem>
          )
      )}
    </Select>
  );
};
