/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { POSSIBLE_FEATURE_FLAGS } from 'featureFlags';
import React, { useCallback, useState } from 'react';
import { createNoop } from 'utils/Misc';
import { useSysProps } from './SysPropsHooks';
import { SystemPropertyInterface } from 'interface/SystemProperty.interface';

type FeatureTogglesHook = {
  isToggleEnabled: (str: POSSIBLE_FEATURE_FLAGS) => boolean;
  setToggle: (feature: POSSIBLE_FEATURE_FLAGS, state: boolean) => void;
};

const FeatureTogglesHookContext = React.createContext<FeatureTogglesHook>({
  isToggleEnabled: createNoop('FeatureFlags isToggleEnabled', false),
  setToggle: createNoop('FeatureFlags setToggle')
});

export const FeatureTogglesHookProvider = (
  props: React.PropsWithChildren<FeatureTogglesHook>
) => {
  const { children, ...rest } = props;
  return (
    <FeatureTogglesHookContext.Provider value={rest}>
      {children}
    </FeatureTogglesHookContext.Provider>
  );
};

export const useFeatureToggles = () => {
  return React.useContext(FeatureTogglesHookContext);
};

const SYSPROP_TO_FEATUREFLAG_MAP: Partial<
  Record<POSSIBLE_FEATURE_FLAGS, string>
> = {
  REACT_APP_USE_CACAO_PLAYBOOKS: 'NON_LINEAR_PLAYBOOKS',
  REACT_APP_USE_NEW_CASE_LIST: 'USE_PAGINATED_CASE_LIST',
  REACT_APP_USE_LAZY_LOADING_CASE_SELECTOR: 'USE_LAZY_LOADING_CASE_SELECTOR'
};

export function determineIsToggledEnabled(
  toggleName: string,
  envVars: Record<string, string | undefined>,
  sysProps: Array<SystemPropertyInterface>,
  localStorageGet: (str: string) => string | null,
  localState: Record<string, boolean>
): boolean {
  const localValue = localState[toggleName];
  if (localValue !== undefined) {
    return localValue;
  }

  const localStorageValue = localStorageGet(toggleName);
  if (localStorageValue !== null) {
    return localStorageValue === 'true';
  }

  const sysPropValue = sysProps.find((v) => {
    return v.name === SYSPROP_TO_FEATUREFLAG_MAP[toggleName];
  });

  if (sysPropValue) {
    return sysPropValue.value === 'true';
  }

  const envVarValue = envVars[toggleName];
  if (envVarValue) {
    return envVarValue === 'true';
  }

  return false;
}

export const ProductionFeatureTogglesHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const [toggles, setToggles] = useState({});

  const setFeature = useCallback(
    (key: POSSIBLE_FEATURE_FLAGS, value: unknown) => {
      setToggles({
        ...toggles,
        [key]: value
      });
    },
    [toggles]
  );

  const { data, isLoading } = useSysProps();

  const getIsToggleEnabled = useCallback(
    (str: POSSIBLE_FEATURE_FLAGS) => {
      //Order of priority
      // State value (ie. debug pane)
      // Local storage
      // Sys pop
      // Environment variable

      if (isLoading) {
        throw new Error(
          'Feature Toggles not ready, sys props are still loading'
        );
      }

      return determineIsToggledEnabled(
        str,
        process.env,
        data,
        localStorage.getItem.bind(localStorage),
        toggles
      );
    },
    [toggles, data, isLoading]
  );

  return (
    <FeatureTogglesHookProvider
      isToggleEnabled={getIsToggleEnabled}
      setToggle={setFeature}
    >
      {props.children}
    </FeatureTogglesHookProvider>
  );
};

/**
 * For testing purposes only
 *
 * You can pass in the state of various toggles
 * @param props
 * @returns
 */
export const TestingFeatureTogglesHookProvider = (
  props: React.PropsWithChildren<{
    toggles: Partial<Record<POSSIBLE_FEATURE_FLAGS, boolean>>;
  }>
) => {
  const { toggles } = props;

  return (
    <FeatureTogglesHookProvider
      setToggle={createNoop('FeatureFlags')}
      isToggleEnabled={(toggle) => {
        const foundValue = toggles[toggle];
        if (foundValue !== undefined) {
          return foundValue;
        }
        return false;
      }}
    >
      {props.children}
    </FeatureTogglesHookProvider>
  );
};
