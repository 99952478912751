/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Edge, Node } from 'reactflow';
import ELK from 'elkjs/lib/elk.bundled.js';
import {
  REACT_FLOW_NODE_WIDTH,
  REACT_FLOW_NODE_MAX_HEIGHT,
  VERTICAL_SPACING_VALUE
} from '../CustomNodes/BaseCustomNode';
import { ElkNode } from 'elkjs';
import { GenericNode } from '../type';
import { cacaoNodeOrderingUtils } from 'components/_playbooks/CacaoFlowchart/utils/cacaoNodeOrderingUtils';
const elk = new ELK();

export async function positionElements(
  nodes: Array<Omit<Node<GenericNode>, 'position'>>,
  edges: Array<Edge>
): Promise<Array<Node<GenericNode>>> {
  // PLEASE DO NOT DELETE THESE COMMENTS

  // SEE THE OPTIONS HERE
  //https://www.eclipse.org/elk/reference.html

  // USE THIS TOOL TO HELP DEBUG
  //https://rtsys.informatik.uni-kiel.de/elklive/elkgraph.html

  //THIS IS A GOOD SAMPLE GRAPH
  //   node myGraph {
  //     nodeLabels.placement: "H_LEFT V_TOP OUTSIDE"
  //     elk.layered.nodePlacement.strategy: BASIC
  //     elk.direction: DOWN
  //     elk.layered.nodePlacement.favorStraightEdges: false

  //     node n0
  //     node n1
  //     node n2
  //     node n3
  //     node n4
  //     edge n0->n1
  //     edge n1->n2
  //     edge n1->n3
  //     edge n3->n4
  //     edge n2->n4
  //
  // }

  const orderedNodes = cacaoNodeOrderingUtils.getNodeOrders(nodes);
  // TODO: this will need to be removed once `getNodeOrders` function support while and switch condition
  const missingNodes = nodes.filter(
    (v) => orderedNodes.find((on) => on.id === v.id) === undefined
  );

  const graph = {
    id: 'doesnotmatter',
    // Available Options: https://eclipse.dev/elk/reference/algorithms/org-eclipse-elk-layered.html
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      // 'elk.layered.nodePlacement.favorStraightEdges': 'false',
      'elk.layered.crossingMinimization.forceNodeModelOrder': 'true',
      // 'elk.layered.crossingMinimization.strategy': 'INTERACTIVE',
      'elk.layered.spacing.baseValue': `${
        REACT_FLOW_NODE_MAX_HEIGHT + VERTICAL_SPACING_VALUE
      }`
    },
    children: [...orderedNodes, ...missingNodes].map((v, i) => {
      return {
        id: v.id,
        height: 50,
        label: v.data.name,
        width: REACT_FLOW_NODE_WIDTH
      };
    }),
    edges: edges.map((v) => {
      return {
        id: v.id,
        sources: [v.source],
        targets: [v.target],
        label: v.label
      };
    })
  } as ElkNode;

  const result = await elk.layout(graph);

  const resultMap = new Map<string, ElkNode>();
  result.children?.forEach((v) => {
    resultMap.set(v.id, v);
  });

  return nodes.map((v) => {
    const elkNode = resultMap.get(v.id);

    if (!elkNode) {
      throw new Error(`Something went wrong, node with ${v.id} was not found`);
    }

    return {
      ...v,
      position: {
        x: elkNode.x as number,
        y: elkNode.y as number
      }
    };
  });
}
