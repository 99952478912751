/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { TextFieldProps } from '@mui/material/TextField';
import { CydTextField } from 'components/_formElements/CydTextField';
import { Field, FieldProps, getIn } from 'formik';
import * as React from 'react';

export const FormikTextField: React.FunctionComponent<TextFieldProps> = ({
  name,
  error,
  helperText,
  ...rest
}) => {
  const renderField = ({
    field,
    form: { errors, touched, values }
  }: FieldProps) => {
    const fieldError = name && getIn(errors, name);
    const fieldTouched = name && getIn(touched, name);

    return (
      <CydTextField
        {...field}
        {...rest}
        onChange={(value, event) => {
          field.onChange(event);
        }}
        name={name}
        helperText={helperText}
        errorText={fieldError && fieldTouched && fieldError}
      />
    );
  };

  return <Field name={name}>{renderField}</Field>;
};
