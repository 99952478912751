/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Chip } from '@mui/material';
import { CydAclModal } from 'components/_dialogs/CydAclModal/CydAclModal';
import { Acl } from 'interface/Acl.interface';
import { useState } from 'react';
import Link from '@mui/material/Link';

/** @jsxImportSource @emotion/react */

export type CydAclDisplayProps = {
  acl: Acl;
  variant?: 'default' | 'small-clickable' | 'default-clickable';

  // Legacy functionality, and should be removed ASAP
  filename?: string;
  significance?: string;
};

export const CydAclDisplay = (props: CydAclDisplayProps) => {
  const { acl, variant = 'default', ...rest } = props;

  const [isModalOpened, setIsModalOpened] = useState(false);

  if (variant === 'default') {
    return <>{acl.description}</>;
  }

  if (variant === 'small-clickable') {
    return (
      <>
        <Chip
          css={(theme) => `
      font-size: 10px;
      margin-left: ${theme.spacing(4)};
    `}
          label="ACL"
          variant="outlined"
          size="small"
          onClick={() => {
            setIsModalOpened(true);
          }}
        />

        <CydAclModal
          isOpen={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          acl={acl}
          {...rest}
        />
      </>
    );
  }

  if (variant === 'default-clickable') {
    return (
      <>
        <Link
          aria-label="View ACL"
          css={(theme) => `cursor: pointer;
        margin-right: ${theme.spacing(4)};
        display: block;`}
          underline="none"
          onClick={() => {
            setIsModalOpened(true);
          }}
        >
          {acl.description}
        </Link>

        <CydAclModal
          isOpen={isModalOpened}
          onClose={() => setIsModalOpened(false)}
          acl={acl}
          {...rest}
        />
      </>
    );
  }
  throw Error('cases exhausted');
};
