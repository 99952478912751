/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { all, delay, fork, take } from 'redux-saga/effects';
import * as TokenManager from '../utils/TokenManager';
import aclSagas from './acl/sagas';
import aclGroupSagas from './aclGroup/sagas';
import assetsSagas from './assets/sagas';
import caseCacheSagas from './caseCache/sagas';
import caseDataSagas from './caseData/sagas';
import currentUserSagas from './auth/sagas';
import usersSagas from './users/sagas';
import groupsSagas from './groups/sagas';
import groupMemberSagas from './groupMembers/sagas';
import dataSagas from './data/sagas';
import quickSearchSagas from './quickSearch/sagas';
import IoCQuickSearchSagas from './IoCQuickSearch/sagas';
import playbooksSagas from './playbooks/sagas';
import playbookActionsSagas from './playbookActions/sagas';
import caseTagsSagas from './caseTags/sagas';
import orgSagas from './orgs/sagas';
import reportSagas from './reports/sagas';
import casePlaybooks from './casePlaybooks/sagas';
import casePlaybookActions from './caseActions/sagas';
import caseStates from './caseStates/sagas';
import cases from './cases/sagas';
import syspropSagas from './sysprops/sagas';
import samlSagas from './saml/sagas';
import watcherSagas from './watchers/sagas';
import caseMetasSagas from './caseMetas/sagas';
import userActionsSagas from './userActions/sagas';
import severitySagas from './caseSeverity/sagas';
import watchingCasesSagas from './watchingCases/sagas';
import caseGroupsSagas from './caseGroups/sagas';
import mttxSagas from './metrics/sagas';
import stixSagas from './caseStix/sagas';
import userPropsSagas from './userProps/sagas';
import caseFormSagas from './caseForms/sagas';
import contacts from './contacts/sagas';
import resource from './resource/sagas';

export default function* rootSaga() {
  yield all([
    ...aclSagas,
    ...aclGroupSagas,
    ...assetsSagas,
    ...caseCacheSagas,
    ...caseDataSagas,
    ...caseTagsSagas,
    ...casePlaybooks,
    ...casePlaybookActions,
    ...currentUserSagas,
    ...usersSagas,
    ...groupsSagas,
    ...groupMemberSagas,
    ...dataSagas,
    ...quickSearchSagas,
    ...IoCQuickSearchSagas,
    ...playbooksSagas,
    ...playbookActionsSagas,
    ...orgSagas,
    ...reportSagas,
    ...caseStates,
    ...cases,
    ...syspropSagas,
    ...samlSagas,
    ...watcherSagas,
    ...caseMetasSagas,
    ...userActionsSagas,
    ...severitySagas,
    ...watchingCasesSagas,
    ...caseGroupsSagas,
    ...mttxSagas,
    ...stixSagas,
    ...userPropsSagas,
    ...caseFormSagas,
    ...contacts,
    ...resource
  ]);
}

let oldToken;

export function* takeEveryThrottledPerKey(pattern, saga, selector, timeout) {
  const set = new Set();

  function* timer(id) {
    yield delay(timeout);
    set.delete(id);
  }

  while (true) {
    // We don't want the throttling to occur if the user has since logged out
    const currentToken = TokenManager.getAccessToken();
    if (currentToken !== oldToken || !currentToken) {
      set.clear();
      oldToken = currentToken;
    }

    const action = yield take(pattern);
    const id = selector(action);

    const throttled = set.has(id);
    if (throttled) {
      // Do nothing, action throttled
    } else {
      set.add(id);
      // Expire items after timeout
      yield fork(timer, id);
      yield fork(saga, action);
    }
  }
}
