/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as React from 'react';
import { Acl } from 'interface/Acl.interface';
import { DialogProps } from '@mui/material/Dialog';
import { Dialog, Grid, Typography } from '@mui/material';
import { AclSelect } from 'components/fields/AclSelect';
import { AclDialogRow } from 'components/AclDialog/AclDialogRow';
import { useAclDialogStyles } from './styles';
import { EncryptionKeySelect } from 'components/fields/EncryptionKeySelect';
import { DataSignificanceSelect } from 'components/fields/DataSignificanceSelect/DataSignificanceSelect';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { AclAciRow } from 'components/AclAciRow';
import { aclPermissions, transformAclAciGroup } from 'utils/AclUtils';
import { useAcls } from 'hooks/AclHooks';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

interface IAclDialogProps extends Omit<DialogProps, 'onClose'> {
  acl: Acl;
  dataSignificances: DataSignificance[];
  disabled: boolean;
  encryptionkey?: string | undefined;
  filename?: string;
  open: boolean;
  significance?: string;
  onClose: () => void;
}

const AcldDialogWrapper = (props) =>
  props.open ? <AclDialog {...props} /> : <></>;

const AclDialog: React.FC<IAclDialogProps> = ({
  acl,
  dataSignificances,
  disabled,
  open,
  encryptionkey,
  filename,
  significance,
  onClose
}) => {
  const aciGroup = transformAclAciGroup(acl);
  const { data: aclList } = useAcls();
  const { container, title, aciRowContainer } = useAclDialogStyles();

  // Hardcoded for now
  const keyOptions = [
    { label: 'No key selected', value: 'No key selected' },
    { label: 'key1', value: 'key1' },
    { label: 'key2', value: 'key2' },
    { label: 'key3', value: 'key3' }
  ];

  const renderEncryptionDropdown = (keys) => {
    return (
      <EncryptionKeySelect
        options={keys}
        value={'No key selected'}
        disabled={disabled}
      ></EncryptionKeySelect>
    );
  };

  const renderSignificancesDropdown = () => {
    const currentSig = dataSignificances.find(
      ({ name }) => name === significance
    );
    const defaultValue = dataSignificances.find(
      ({ name }) => name === 'Comment'
    );
    return (
      <DataSignificanceSelect
        dataSignificances={dataSignificances}
        value={currentSig || defaultValue}
        disabled={disabled}
      />
    );
  };

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: container }}>
      <Grid container spacing={1} className={title}>
        <Grid item xs={3}>
          <Typography>Access Control</Typography>
        </Grid>
        <Grid item xs={9}>
          <AclSelect acls={aclList} value={acl} disabled={disabled}></AclSelect>
        </Grid>
      </Grid>
      {filename && (
        <AclDialogRow
          title="Encryption"
          value={renderEncryptionDropdown(keyOptions)}
        ></AclDialogRow>
      )}
      {significance && (
        <AclDialogRow
          title="Type"
          value={renderSignificancesDropdown()}
        ></AclDialogRow>
      )}
      <div className={aciRowContainer}>
        {aclPermissions.map((permission) => (
          <AclAciRow
            key={permission}
            title={permission}
            acis={aciGroup[permission]}
          />
        ))}
      </div>
      <CydButtonStack justifyContent="flex-end">
        <CydButton onClick={onClose}>Close</CydButton>
      </CydButtonStack>
    </Dialog>
  );
};

export default React.memo(
  AcldDialogWrapper,
  (prevProps, nextProps) => prevProps.open === nextProps.open
);
