import { SYSTEM_PROPERTY } from '../constants';
import { useCallback, useEffect } from 'react';
import { useSystemPropValue } from './SysPropsHooks';
import config from '../config';
import { IAutoEffectReturn } from './ReduxHooks';

export const useThrottledInterval = (fn, interval, deps: any = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoFn = useCallback(fn, deps);
  const sysInterval = useSystemPropValue(
    SYSTEM_PROPERTY.DATA_POLL_TIME,
    interval
  );
  useEffect(() => {
    const fnWrapper = () => {
      if (document.hidden) {
        return;
      }
      memoFn();
    };
    const intervalFn = setInterval(fnWrapper, sysInterval);
    return () => clearInterval(intervalFn);
  }, [memoFn, sysInterval]);
};

interface IAutoFetch {
  <TSelector = unknown>(
    data: IAutoEffectReturn<TSelector>,
    interval: 'THREAD' | 'LIST' | 'DASHBOARD_CHART'
  ): IAutoEffectReturn<TSelector>;
}

export const useAutoFetchEffect: IAutoFetch = (
  { fetchData, ...rest },
  interval
) => {
  let intervalTime = 10000;
  switch (interval) {
    case 'THREAD':
      intervalTime = config.THREAD_ITEMS_REFRESH_INTERVAL;
      break;
    case 'LIST':
      intervalTime = config.CASE_LIST_REFRESH_INTERVAL;
      break;
    case 'DASHBOARD_CHART':
      intervalTime = config.DASHBOARD_CHART_REFRESH_INTERVAL;
      break;
  }
  useThrottledInterval(() => fetchData(), intervalTime);
  return {
    fetchData,
    ...rest
  };
};
