/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Chip } from '@mui/material';
import { GenericRunningInstanceStepStatus } from 'components/_playbooks/CacaoPlaybookRunningInstance';
import React from 'react';
import { capitaliseString } from 'utils/StringUtils';

/** @jsxImportSource @emotion/react */

export type CydStatusChipProps = {
  status: GenericRunningInstanceStepStatus['status'];
};

export const CydPlaybookStatusChip = (props: CydStatusChipProps) => {
  const { status } = props;

  return (
    <Chip
      label={capitaliseString(status)}
      css={(theme) => `
      padding: 3px;
      color: ${theme.other.playbookStatusPalette[status]['_400']}; 
      background-color: ${theme.other.playbookStatusPalette[status]['_400']}08; 
    `}
    />
  );
};
