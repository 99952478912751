/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Tooltip, Typography } from '@mui/material';

import { Handle, Position } from 'reactflow';
import { ManualOrAutomaticIcon } from '../ManualOrAutomaticIcon/ManualOrAutomaticIcon';
import { CydPlaybookStatusChip } from '../StatusChip/CydPlaybookStatusChip';
import { determineManualOrAutomatic } from '../utils/generalNodeStatusUtils';
import { BaseCustomNode, BaseCustomNodeProps } from './BaseCustomNode';

/** @jsxImportSource @emotion/react */
import { CydInfoIcon } from 'components/_dataDisplay/CydInfoIcon/CydInfoIcon';
import { CydUserDisplayById } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplayById';
import { parseNodeName } from './customNodeUtils';

export const SingleNode = (props: BaseCustomNodeProps) => {
  const { data } = props;
  const nodeType = determineManualOrAutomatic(data);

  return (
    <BaseCustomNode data={data}>
      <div
        css={(theme) => `
          display: flex;
          flex-flow: row nowrap;
        `}
      >
        <Tooltip placement="top" title={parseNodeName(data, data.playbookType)}>
          <Typography
            variant="body1"
            color="text.primary"
            gutterBottom
            css={(theme) => `
              flex: 1 1 auto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `}
          >
            {parseNodeName(data, data.playbookType)}
          </Typography>
        </Tooltip>

        <ManualOrAutomaticIcon type={nodeType} />
      </div>

      {data.nodeStatus && (
        <div
          css={(theme) => `
          display: flex; 
          align-items: center; 
          gap: ${theme.spacing(1)};

        `}
        >
          <CydPlaybookStatusChip status={data.nodeStatus.status} />
          {data.nodeStatus.assignee && (
            <CydUserDisplayById
              variant="avatar"
              userId={data.nodeStatus.assignee}
            />
          )}
          {/* Spacer */}
          <div
            css={(theme) => `
              flex: 1 0 auto;
            `}
          ></div>

          {data.nodeStatus.tags.length > 0 && (
            <CydInfoIcon icon="tag" text={`${data.nodeStatus.tags.length}`} />
          )}
        </div>
      )}
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </BaseCustomNode>
  );
};
