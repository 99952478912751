/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest } from 'redux-saga/effects';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { createReportFailed, loadingReportSuccess } from './slice';
import {
  createSummaryReport,
  createAnalystReport,
  createMTTXReport,
  createHeatMapReport,
  createUserReport,
  createGroupReport,
  ICreateReportWithResolve
} from './actions';
import { Report } from 'interface/Report.interface';
import {
  apiCreateAnalystReport,
  apiCreateGroupReport,
  apiCreateHeatMapReport,
  apiCreateMTTXReport,
  apiCreateReport,
  apiCreateUserReport
} from 'services/ReportServices';

function* performDownloadReport(response, name) {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  const blob = yield response.blob();
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = name;
  // attempt to retrieve filename
  var fileName = '';
  const contentDisp: string | null = response.headers.get(
    'Content-Disposition'
  );
  if (contentDisp != null) {
    const parts: string[] = contentDisp.split('=');
    if (parts.length > 1) {
      fileName = parts[1];
      anchor.download = parts[1];
    }
  }
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  fileName
    ? yield put(
        addNotification({
          message: `Report ${fileName} has been created`
        })
      )
    : yield put(
        addNotification({
          message: `Report has been created`
        })
      );
}

export function* performCreateReport({
  payload
}: {
  payload: ICreateReportWithResolve;
}) {
  try {
    const displayPreviewFunc = payload.displayPreview;
    const response: Response = yield apiCreateReport(payload.previewType, {
      orgUuidList: payload.orgList,
      startTime: Date.parse(payload.startTime),
      endTime: Date.parse(payload.endTime),
      timezone: payload.timezone,
      format: payload.format
    });
    if (displayPreviewFunc) {
      response.blob().then(function (blob) {
        const blobType = blob.type;
        blob.text().then(function (text) {
          displayPreviewFunc(text, blobType);
        });
      });
    } else {
      yield performDownloadReport(response, 'summary_report.pptx');
    }
    yield put(loadingReportSuccess());
  } catch (ex) {
    yield put(createReportFailed(ERROR_MESSAGE.CREATE_REPORT_ERROR));
    yield put(
      addNotification({ message: ERROR_MESSAGE.CREATE_REPORT_ERROR.message })
    );
  }
}

export function* performCreateAnalystReport({
  payload: report
}: {
  payload: Report;
}) {
  try {
    const response: Response = yield apiCreateAnalystReport({
      org: report.org,
      startTime: Date.parse(report.startTime).toString(),
      endTime: Date.parse(report.endTime).toString(),
      format: report.format,
      userNames: report.userNames,
      tags: report.tags,
      states: report.states
    });
    yield performDownloadReport(response, 'analyst_report.csv');
  } catch (ex) {
    yield put(createReportFailed(ERROR_MESSAGE.CREATE_REPORT_ERROR));
    yield put(
      addNotification({ message: ERROR_MESSAGE.CREATE_REPORT_ERROR.message })
    );
  }
}

export function* performCreateMTTXReport({
  payload: report
}: {
  payload: ICreateReportWithResolve;
}) {
  try {
    const displayPreviewFunc = report.displayPreview;
    const response: Response = yield apiCreateMTTXReport(report.previewType, {
      org: report.org,
      startTime: Date.parse(report.startTime),
      endTime: Date.parse(report.endTime),
      format: report.format,
      userNames: report.userNames,
      includeTags: report.includeTags,
      excludeTags: report.excludeTags,
      severities: report.severities
    });

    if (displayPreviewFunc) {
      response.blob().then(function (blob) {
        const blobType = blob.type;
        blob.text().then(function (text) {
          displayPreviewFunc(text, blobType);
        });
      });
    } else {
      yield performDownloadReport(response, 'mttx_report.csv');
    }
    yield put(loadingReportSuccess());
  } catch (ex) {
    yield put(createReportFailed(ERROR_MESSAGE.CREATE_REPORT_ERROR));
    yield put(
      addNotification({ message: ERROR_MESSAGE.CREATE_REPORT_ERROR.message })
    );
  }
}

export function* performCreateHeatMapReport({
  payload: report
}: {
  payload: ICreateReportWithResolve;
}) {
  try {
    const displayPreviewFunc = report.displayPreview;
    const response: Response = yield apiCreateHeatMapReport(
      report.previewType,
      {
        org: report.org,
        startTime: Date.parse(report.startTime).toString(),
        endTime: Date.parse(report.endTime).toString(),
        format: report.format,
        xTagPrefixes: report.xTagPrefixes,
        yTagPrefixes: report.yTagPrefixes,
        timezone: report.timezone
      }
    );
    if (displayPreviewFunc) {
      response.blob().then(function (blob) {
        const blobType = blob.type;
        blob.text().then(function (text) {
          displayPreviewFunc(text, blobType);
        });
      });
    } else {
      yield performDownloadReport(response, 'heatmap_report.csv');
    }
    yield put(loadingReportSuccess());
  } catch (ex) {
    yield put(createReportFailed(ERROR_MESSAGE.CREATE_REPORT_ERROR));
    yield put(
      addNotification({ message: ERROR_MESSAGE.CREATE_REPORT_ERROR.message })
    );
  }
}

export function* performCreateUserReport() {
  try {
    const response: Response = yield apiCreateUserReport();
    yield performDownloadReport(response, 'user_report.csv');
  } catch (ex) {
    yield put(createReportFailed(ERROR_MESSAGE.CREATE_USER_REPORT_ERROR));
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CREATE_USER_REPORT_ERROR.message
      })
    );
  }
}

export function* performCreateGroupReport() {
  try {
    const response: Response = yield apiCreateGroupReport();
    yield performDownloadReport(response, 'group_report.csv');
  } catch (ex) {
    yield put(createReportFailed(ERROR_MESSAGE.CREATE_GROUP_REPORT_ERROR));
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CREATE_GROUP_REPORT_ERROR.message
      })
    );
  }
}

function* watchCreateReport() {
  yield takeLatest(createSummaryReport, performCreateReport);
}

function* watchCreateAnalystReport() {
  yield takeLatest(createAnalystReport, performCreateAnalystReport);
}

function* watchCreateMTTXReport() {
  yield takeLatest(createMTTXReport, performCreateMTTXReport);
}

function* watchCreateHeatMapReport() {
  yield takeLatest(createHeatMapReport, performCreateHeatMapReport);
}

function* watchCreateUserReport() {
  yield takeLatest(createUserReport, performCreateUserReport);
}

function* watchCreateGroupReport() {
  yield takeLatest(createGroupReport, performCreateGroupReport);
}

export default [
  watchCreateReport(),
  watchCreateUserReport(),
  watchCreateMTTXReport(),
  watchCreateAnalystReport(),
  watchCreateGroupReport(),
  watchCreateHeatMapReport()
];
