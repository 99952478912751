/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as moment from 'moment-timezone';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { useTimezone2 } from 'hooks/AuthHooks';
import { dateFormatInYearAnd24Hour } from 'utils/DateUtils';

type CydTimeDisplayProps = {
  time: string;
  className?: string;
};

export const CydTimeDisplay = withErrorBoundary(
  ({ time, className }: CydTimeDisplayProps) => {
    if (time === '') {
      throw new Error('time property cannot be an empty string');
    }
    const { timezone } = useTimezone2();
    return (
      <span className={className}>
        {time
          ? moment.tz(time, timezone).format(dateFormatInYearAnd24Hour)
          : ''}
      </span>
    );
  },
  (props) => {
    return (
      <span data-testid="cyd-time-display" className={props.className}>
        {props.children}
      </span>
    );
  },

  // What we're doing here is if no timezone is found, we just render as a default string
  (props) => {
    return <span>{props.time}</span>;
  }
);
