/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchCaseStates } from './actions';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { CaseState } from 'interface/CaseState.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICaseStatesStoreState {
  list: CaseState[];
  isLoading: boolean;
  hasLoaded: boolean;
  error: ErrorPayload | null;
}

const initialState: ICaseStatesStoreState = {
  list: [],
  isLoading: false,
  hasLoaded: false,
  error: null
};

const caseStatesSlice = createSlice({
  name: 'caseStates',
  initialState,
  reducers: {
    fetchCaseStatesSuccess: (
      state,
      { payload }: PayloadAction<CaseState[]>
    ) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.list = payload;
    },

    fetchCaseStatesFailed: (
      state,
      { payload }: PayloadAction<ErrorPayload>
    ) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.error = payload;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchCaseStates, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: caseStates,
  actions: { fetchCaseStatesSuccess, fetchCaseStatesFailed }
} = caseStatesSlice;
