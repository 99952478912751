/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ICaseMeta } from 'interface/Case.interface';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydCaseDetailsPanelSection } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanelSection';
import { CydMetadataDisplay } from 'components/_objectDisplay/CydMetadataDisplay/CydMetadataDisplay';
import { CydCaseMetadataModal } from 'components/_caseView/CydCaseMetadataModal/CydCaseMetadataModal';
import { createUpdateCaseMeta } from 'states/cases/actions';
import { transformMetadataToUpdatePayload } from 'utils/MetadataUtils';

type CydCaseViewMetadataProps = {
  caseUuid: string;
  metadata?: { [key: string]: ICaseMeta } | null;
  isEditable?: boolean;
};

const emptyObject = {};

export const CydCaseViewMetadataSection = ({
  caseUuid,
  metadata = emptyObject,
  isEditable = false
}: CydCaseViewMetadataProps) => {
  const metaDataToUse = metadata || emptyObject;

  const dispatch = useDispatch();
  const [isOpenMetadataModal, setIsOpenMetadataModal] = useState(false);
  const metadataKeys = Object.keys(metaDataToUse);
  const hasMetadata = metadataKeys.length > 0;

  const handleEdit = () => {
    setIsOpenMetadataModal(true);
  };

  const handleSave = (newData) => {
    dispatch(
      createUpdateCaseMeta({
        caseUuid,
        caseMeta: transformMetadataToUpdatePayload(metaDataToUse, newData),
        newData: newData
      })
    );
    setIsOpenMetadataModal(false);
  };
  const metadataLength = Object.values(metaDataToUse).filter(
    (a) => a.uuid
  ).length;
  return (
    <CydCaseDetailsPanelSection
      title="Metadata"
      data-testid={`metadata-length-${metadataLength}`}
      actions={
        isEditable &&
        (hasMetadata ? (
          <CydButton variant="text" startIcon="edit" onClick={handleEdit}>
            Edit
          </CydButton>
        ) : (
          <CydButton variant="text" startIcon="add" onClick={handleEdit}>
            Add
          </CydButton>
        ))
      }
    >
      <CydMetadataDisplay metadata={metaDataToUse} />
      <CydCaseMetadataModal
        isOpen={isOpenMetadataModal}
        onClose={() => setIsOpenMetadataModal(false)}
        metadata={metaDataToUse}
        onSave={handleSave}
      />
    </CydCaseDetailsPanelSection>
  );
};
