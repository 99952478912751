/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Alert } from '@mui/material';
import React from 'react';

export type CydFormErrorMessageProps = {
  message: string;
  className?: string;
};

export const CydFormErrorMessage = (props: CydFormErrorMessageProps) => {
  const { message, className } = props;

  return (
    <Alert className={className} severity="error">
      {message}
    </Alert>
  );
};
