/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
export type CydAppBarSearchProps = {
  onSearch: (searchString: string) => void;
  className?: string;
};

export const CydAppBarSearch = (props: CydAppBarSearchProps) => {
  const { onSearch, className } = props;
  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        const searchString = e.target[0].value;
        onSearch(searchString);
      }}
    >
      {/* Note that we aren't using CydTextField here, because this search bar is a bit of an exception  */}
      <TextField
        name="search"
        variant="outlined"
        placeholder="Search for cases"
        inputProps={{
          'aria-label': 'Search for cases'
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </form>
  );
};
