/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Tooltip } from '@mui/material';
import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';

export type CydAclDiffersShieldProps = {
  aclDiffers: boolean;
};

export const CydAclDiffersShield = (props: CydAclDiffersShieldProps) => {
  const { aclDiffers } = props;

  const [showIcon, setShowIcon] = React.useState(aclDiffers);

  // Commented out for now.
  // Basically investigating some debouncing on showing the icon
  // For when you are doing a batch update of case activity, and you don't want to show the user
  // A flash of all the icons appearing

  React.useEffect(() => {
    let timeoutId;
    if (aclDiffers) {
      timeoutId = setTimeout(() => {
        setShowIcon(true);
      }, 5000);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setShowIcon(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [aclDiffers]);

  return (
    <>
      {showIcon && (
        <Tooltip title="ACL Differs from case default">
          <span
            css={css`
              vertical-align: middle;
            `}
          >
            <CydIcon
              icon="badgeExclaim"
              css={(theme) => `
                    height: 16px;
                    width: 16px;
                  `}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
};
