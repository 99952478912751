/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createFetchContacts } from 'states/contacts/actions';
import {
  contactOrgsSelector,
  contactsSelector,
  contactListNameSelector,
  contactsHasLoadedSelector,
  contactsStatusSelector
} from 'states/contacts/selectors';
import { useAutoEffect } from './ReduxHooks';

export const useContacts = () => {
  return useAutoEffect({
    selector: contactsSelector,
    ac: createFetchContacts,
    hasLoadedSelector: contactsHasLoadedSelector,
    statusSelector: contactsStatusSelector
  });
};

export const useOrgContacts = () => {
  return useAutoEffect({
    selector: contactOrgsSelector,
    ac: createFetchContacts,
    statusSelector: contactsStatusSelector
  });
};

export const useContactListName = () => {
  return useAutoEffect({
    selector: contactListNameSelector,
    ac: createFetchContacts,
    statusSelector: contactsStatusSelector
  });
};
