/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';

export type CydNoDataToDisplayProps = {
  className?: string;
  title?: string;
  body?: string;
  additionalContent?: React.ReactNode;
};

export const CydNoDataToDisplay = (props: CydNoDataToDisplayProps) => {
  const {
    title = 'No data to display',
    body = 'Try checking your selected criteria and filters',
    additionalContent = null
  } = props;

  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      `}
    >
      <CydIcon
        icon="dashboard"
        css={(theme) => `
        width: ${theme.spacing(12)}; 
        height: ${theme.spacing(12)}; 
      `}
      />
      <Typography variant="h6" color="text.primary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {body}
      </Typography>
      {additionalContent}
    </div>
  );
};
