/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchContacts } from './actions';
import {
  Contact,
  IContactResponse,
  IContactResponseData,
  IContactStoreState,
  UpdateContactSuccessPayload
} from 'interface/Contacts.interface';
import { splitStringToArray } from 'utils/StringUtils';

const initialState: IContactStoreState = {
  results: [],
  isLoading: false,
  hasLoaded: false
};

const contactsSlice = createSlice({
  name: 'contactsSlice',
  initialState,
  reducers: {
    updateContactSuccess: (
      state,
      {
        payload: updatedContactPayload
      }: PayloadAction<UpdateContactSuccessPayload>
    ) => {
      state.results = state.results.map((contact: Contact) => {
        if (contact.uuid === updatedContactPayload.uuid) {
          return {
            ...contact,
            data: {
              ...contact.data,
              name: updatedContactPayload.name,
              email: splitStringToArray(updatedContactPayload.email),
              phone: splitStringToArray(updatedContactPayload.phone),
              organisation: updatedContactPayload.organisation
            },
            acl: updatedContactPayload.acl
          };
        }
        return contact;
      });
    },
    fetchContactsSuccess: (
      state,
      { payload }: PayloadAction<IContactResponse[]>
    ) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.results = payload.map((contactResponse: IContactResponse) => {
        const {
          contact_type: contactType,
          phone,
          email,
          organisation,
          name,
          deprecated,
          org_uuid: orgUuid
        }: IContactResponseData = contactResponse.data;

        return {
          ...contactResponse,
          data: {
            contactType,
            phone: phone || [],
            email: email || [],
            organisation,
            deprecated,
            name,
            orgUuid
          }
        };
      });
    },
    fetchContactsFailed: (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.results = [];
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchContacts, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: contacts,
  actions: { updateContactSuccess, fetchContactsSuccess, fetchContactsFailed }
} = contactsSlice;
