/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { AppBar, Toolbar } from '@mui/material';
import { CydarmRoute } from 'interface/CydarmRoute';
import React, { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import cydarmWhiteLogo from 'assets/images/cydarm-logo-white.svg';
import { CydAppBarNavLinks } from './CydAppBarNavLinks/CydAppBarNavLinks';
import { CydAppBarSearch } from './CydAppBarSearch/CydAppBarSearch';
import { CydAppBarTimezoneSelect } from './CydAppBarTimeZoneSelect/CydAppBarTimeZoneSelect';

import {
  useSignout,
  useTimezone2,
  useTimezoneListAvailable
} from 'hooks/AuthHooks';
import { useSystemPropValue } from 'hooks/SysPropsHooks';
import { SYSTEM_PROPERTY } from '../../../constants';
import { CydUserAvatar } from './CydUserAvatar/CydUserAvatar';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type CydAppBarProps = {
  className?: string;
};

export const CydAppBar = (props: CydAppBarProps) => {
  const { className } = props;
  const allTimezones = useTimezoneListAvailable();

  const { timezone: selectedTimezone, updateTimezone } = useTimezone2();

  const signout = useSignout();
  const version = useSystemPropValue(SYSTEM_PROPERTY.VERSION, '');

  const navigate = useNavigate();

  // This seems dangerous
  const handleSearch = useCallback(
    (searchString) => {
      if (!searchString) {
        return;
      }
      const decodeSearchText = encodeURIComponent(searchString);
      navigate(`/search/${decodeSearchText}`);
    },
    [navigate]
  );

  return (
    <AppBar
      className={className}
      position="static"
      color="default"
      elevation={1}
      css={(theme) => `
        background-color: ${theme.palette.background.default};
      `}
    >
      <Toolbar
        disableGutters
        css={(theme) => `
          gap: ${theme.spacing(6)}; 
          padding: 0 ${theme.spacing(4)};
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
        `}
      >
        <NavLink
          to={CydarmRoute.DASHBOARD}
          css={css`
            justify-self: flex-start;
          `}
        >
          <img
            css={css`
              height: 32px;
              vertical-align: middle;
            `}
            src={cydarmWhiteLogo}
            alt="Cydarm"
          />
        </NavLink>

        <CydAppBarNavLinks
          css={css`
            flex: 1 0 auto;

            justify-self: flex-start;
          `}
        />

        <CydAppBarTimezoneSelect
          allTimezones={allTimezones}
          selectedTimezone={selectedTimezone}
          updateTimezone={updateTimezone}
          css={css`
            justify-self: flex-start;
          `}
        />

        <CydAppBarSearch
          onSearch={handleSearch}
          css={css`
            justify-self: flex-start;
          `}
        />

        <CydUserAvatar
          onSignoutClick={signout}
          version={version}
          css={css`
            justify-self: flex-end;
          `}
        />
      </Toolbar>
    </AppBar>
  );
};
