import { QueryClient } from '@tanstack/react-query';

//Due to something weird with import resolution, this thing needs to be in its own module, so that states/cases/sagas.ts can import it.
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});
