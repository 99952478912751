/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useCaseActivityStyles = makeStyles<
  Theme,
  { color?: string; highlighted?: boolean; partial?: boolean }
>(({ spacing, shape, palette: { background, primary } }) => ({
  container: ({ partial, color, highlighted }) => ({
    backgroundColor: highlighted ? background.paper : background.default,
    marginBottom: spacing(1),
    padding: spacing(1, 2),
    borderLeft: `3px solid ${color}`,
    borderRadius: shape.borderRadius,
    transition: 'background-color 0.3s ease',
    ...(partial && { paddingBottom: 0 })
  }),

  containerMinimal: {
    padding: spacing(1, 2),
    borderLeft: ({ color }) => `3px solid ${color}`,
    marginBottom: spacing(1),
    borderRadius: shape.borderRadius,

    '& $containerHeading': {
      justifyContent: 'center'
    },

    '& $containerHeadingLeft': {
      display: 'block'
    }
  },

  containerHeading: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  containerHeadingLeft: {
    display: 'flex',
    flex: '1 1 auto'
  },
  containerHeadingRight: {
    position: 'absolute',
    right: '40px'
  },
  actionsContainerActive: {
    opacity: 1
  },
  infoIcon: {
    marginLeft: spacing(3)
  },
  button: {
    minWidth: 'auto'
  },
  childrenContainer: {
    paddingLeft: spacing(3)
  },
  headingText: {
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center'
  },
  linkText: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  jsonTable: {
    padding: spacing(1),
    color: primary.light,
    verticalAlign: 'top',
    width: 'min-content',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    border: `1px solid rgba(192,192,255,0.2) !important`,
    '& table': {
      backgroundColor: background.default,
      width: '100%',
      '& tr': {
        backgroundColor: background.paper,
        width: 'min-content',
        '& :hover': {
          backgroundColor: background.default,
          color: primary.main
        },
        '& td:first-child': {
          minWidth: '120px !important',
          width: '120px !important'
        }
      },
      '& :hover': {
        backgroundColor: background.default,
        color: primary.main
      },
      '& td:first-child': {
        fontWeight: 'bold'
      },
      '& td:other-child': {
        fontWeight: 'normal'
      },
      '& td:only-child': {
        fontWeight: 'normal'
      }
    }
  }
}));

export const useCaseActivityPreviewImageStyles = makeStyles(() => ({
  casePreviewImage: {
    maxWidth: '90%'
  }
}));

export const codeBlockStyle = makeStyles(({ palette }) => ({
  codeBlock: {
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      backgroundColor: palette.background.default,
      borderRadius: '5px',
      padding: '10px 20px'
    },
    '& code': {
      fontFamily: 'Courier New, Courier, monospace',
      color: palette.text.primary,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    wordBreak: 'break-word'
  }
}));

export const useIframeStyles = makeStyles(({ palette }) => ({
  iframeStyles: {
    width: '100%',
    border: 'none',
    borderRadius: '6px',
    height: '500px',
    overflow: 'auto',
    background: palette.common.white
  }
}));
