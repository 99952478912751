/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState, useEffect, useRef, useCallback } from 'react';
import {
  useCaseContributors,
  useDataSignificancesRedux
} from 'hooks/CaseDataHooks';
import { CaseViewPageCaseDataDrawer } from './CaseViewPageCaseDataDrawer';
import {
  useCommentDrawerState,
  useDrawerHeightStyles,
  useFormDataState
} from 'hooks/CaseActivitiesUIHooks';
import CaseActivitiesWrapper from './CaseActivitiesWrapper';
import ActivityView from 'components/ActivityView';
import { CydCaseViewActivityFilter } from 'components/_caseView/CydCaseViewActivityFilter/CydCaseViewActivityFilter';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { CydRightInfoPanel } from 'components/_layout/CydRightInfoPanel/CydRightInfoPanel';
import { CydCaseDetailsPanel } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanel';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { CydCaseViewActivitiesThread } from 'components/_caseView/CydCaseViewActivitiesThread/CydCaseViewActivitiesThread';
import {
  ActivityItem,
  ActivityItemOpenState
} from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';
import { useCaseActivityWithFilter } from 'hooks/CaseActivityHooks';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { useCaseViewPageContext } from '../CaseViewPage';
import { useParams } from 'react-router';
import { useCurrentUser } from 'hooks/AuthHooks';
import { useDebounce } from 'hooks/DebounceHooks';
import { CaseActivityUtils } from 'utils/CaseActivityUtils';

const getScrollContainer = (): HTMLElement => {
  const scrollContainer = document.getElementById('scroll-container');
  if (!scrollContainer) {
    throw new Error('No scroll container exists');
  }
  return scrollContainer;
};

const getDrawerHeight = () => {
  const drawer = document.getElementById('add-activity-item-panel');
  if (!drawer) {
    throw new Error('No Add Activity Item Panel exists');
  }
  return drawer.clientHeight;
};

export function CaseViewPageActivities() {
  const caseUuid = useParams()['caseUuid'];

  if (!caseUuid) {
    throw new Error('No caseUuid in URL params');
  }

  const { data: currentUser } = useCurrentUser();
  const { filter, setFilter, cydCase } = useCaseViewPageContext();
  const { isToggleEnabled } = useFeatureToggles();
  const { data: contributors } = useCaseContributors(cydCase.uuid);
  const formDataState = useFormDataState(cydCase);
  const [, activitiesWrapper] = useDrawerHeightStyles(formDataState[0]);
  const [isDrawerOpen, setIsDrawerOpen] = useCommentDrawerState();
  const prevDrawerHeight = useRef(0);
  const [isLastActivityVisible, setIsLastActivityVisible] = useState(false);
  const [selectedActivityElement, setSelectedActivityElement] =
    useState<HTMLElement>();
  const { data: dataSignificances } = useDataSignificancesRedux();
  const [expandAllState, setExpandAllState] = useState(
    ActivityItemOpenState.Partial as ActivityItemOpenState
  );

  const prevCaseActivitiesRef = useRef<Array<ActivityItem>>([]);

  const caseActivityQuery = useCaseActivityWithFilter({
    caseUuid: cydCase.uuid,
    filterSettings: filter,
    lastUpdated: cydCase.modified
  });

  const { data: caseActivities } = caseActivityQuery;

  // using debounce to avoid scrolling on updates and scrolling on clicking reply/edit buttons at the same time
  // Scrolling reply/edit buttons should take precedence over auto-scrolling
  const scrollContainerTo = useDebounce(
    (top: number, method: 'scroll' | 'scrollBy' = 'scroll') => {
      const scrollContainer = getScrollContainer();
      scrollContainer?.[method]({
        left: 0,
        top,
        // AC 10: https://cydarm.atlassian.net/wiki/spaces/FEAT/pages/1803747329/Case+Thread+Behaviour
        behavior: 'smooth'
      });
    },
    [],
    50
  );

  const handleResize = useDebounce(
    () => {
      // only need to update the scroll container height if the drawer is open
      // if scroll container is not opened, the height will be automatically adjusted by the native browser behavior
      if (isDrawerOpen) {
        updateScrollContainerHeight(false);
      }
    },
    [isDrawerOpen],
    50
  );

  const resetScrollContainerHeight = useCallback(() => {
    const scrollContainer = getScrollContainer();
    scrollContainer.style.height = '';
    prevDrawerHeight.current = 0;
    // clear selectedActivityElement when closing the drawer
    setSelectedActivityElement(undefined);
  }, []);

  // scroll to a html element. It is used when users click on reply / edit buttons
  const scrollToElement = useCallback(
    (element: HTMLElement, position: 'bottom' | 'top' = 'bottom') => {
      const scrollContainer = getScrollContainer();
      const scrollContainerHeight = scrollContainer.clientHeight;
      const activityItemElHeight = element.clientHeight;
      const visibleScrollContainerHeight =
        scrollContainerHeight - (filterPanelRef.current?.clientHeight ?? 0);

      if (position === 'bottom') {
        // calculate the offset of the bottom of the activity item relative to the bottom of the scroll container
        // this is the make sure the top of the activity item always visible in the visible scroll container on auto-scrolling
        // if the activity item is taller than the visible scroll container, the top of the activity item should be touching the top of the scroll container
        // if the activity item is smaller than the visible scroll container, the bottom of the activity item should be touching the bottom of the scroll container
        const activityItemElDisplayOffsetBottom = Math.min(
          activityItemElHeight,
          visibleScrollContainerHeight
        );

        const activityItemElOffsetBottom =
          element.offsetTop -
          scrollContainerHeight +
          activityItemElDisplayOffsetBottom;

        // auto scrolling on reply / edit button click
        scrollContainerTo(activityItemElOffsetBottom);
      } else {
        const activityItemElOffsetTop =
          element.offsetTop -
          scrollContainerHeight +
          visibleScrollContainerHeight;

        // auto scrolling on reply / edit button click
        scrollContainerTo(activityItemElOffsetTop);
      }
    },
    [scrollContainerTo]
  );

  const updateScrollContainerHeight = useCallback(
    (enableAutoScroll: boolean = true) => {
      // setTimeout to ensure the drawer is open before we calculate the height
      const scrollContainer = getScrollContainer();
      const scrollContainerParent = scrollContainer.parentNode as HTMLElement;
      const drawerHeight = getDrawerHeight();

      const SCROLL_PANEL_BOTTOM_MARGIN = 16;
      scrollContainer.style.height = `${
        scrollContainerParent.clientHeight -
        drawerHeight -
        SCROLL_PANEL_BOTTOM_MARGIN
      }px`;

      if (enableAutoScroll) {
        // when clicking edit/reply buttons, the selectedActivityElement should be set
        if (selectedActivityElement) {
          // AC13: https://cydarm.atlassian.net/wiki/spaces/FEAT/pages/1803747329/Case+Thread+Behaviour
          scrollToElement(selectedActivityElement);
        }
        // only update the scroll point if drawer height has changed
        else if (prevDrawerHeight.current !== drawerHeight) {
          // auto scroll when opening/closing drawer
          scrollContainerTo(
            drawerHeight - prevDrawerHeight.current,
            'scrollBy'
          );
        }
      }
      prevDrawerHeight.current = drawerHeight;
    },
    [scrollContainerTo, scrollToElement, selectedActivityElement]
  );

  useEffect(() => {
    // AC1. First Load - https://cydarm.atlassian.net/wiki/spaces/FEAT/pages/1803747329/Case+Thread+Behaviour
    if (
      CaseActivityUtils.shouldScrollToBottom({
        isFirstLoad: prevCaseActivitiesRef.current.length === 0,
        isDrawerOpen
      })
    ) {
      const scrollContainer = getScrollContainer();

      // auto scroll when the activity thread grows
      scrollContainerTo(scrollContainer.scrollHeight);
    }
    prevCaseActivitiesRef.current = caseActivities ?? [];
  }, [isDrawerOpen, caseActivities, scrollContainerTo]);

  // auto scroll on opening the drawer
  useEffect(() => {
    if (isDrawerOpen) {
      updateScrollContainerHeight();
    } else {
      resetScrollContainerHeight();
    }
  }, [resetScrollContainerHeight, isDrawerOpen, updateScrollContainerHeight]);

  // when the window is resized, update the scroll container height
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      // comment out resetScrollContainerHeight() as throwing error when logging out from the Case Details page
      // scroll container will be removed when the component is unmounted
      // so no need to reset the height
      // resetScrollContainerHeight();
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, resetScrollContainerHeight]);

  const filterPanelRef = useRef<HTMLDivElement>(null);

  return (
    <div
      css={css`
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        height: auto;
      `}
    >
      <CydRightInfoPanel>
        <CydCaseDetailsPanel cydarmCase={cydCase} />
      </CydRightInfoPanel>

      <div
        ref={filterPanelRef}
        css={(theme) => `
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          gap: ${theme.spacing(3)};

          position: sticky;
          top: -${theme.spacing(4)};
          margin-top: -${theme.spacing(4)};
          padding: ${theme.spacing(2)} 0;
          background-color: ${theme.palette.background.default};
          z-index:2;
        `}
      >
        <CydCaseViewActivityFilter
          availableUsers={contributors}
          availableSignificanceLevels={dataSignificances}
          onChange={(data) => {
            setFilter(data);
          }}
          filterSettings={filter}
        />

        <CydButton
          variant="text"
          fullWidth={false}
          onClick={() => {
            switch (expandAllState) {
              case ActivityItemOpenState.Closed: {
                setExpandAllState(ActivityItemOpenState.Partial);
                return;
              }
              case ActivityItemOpenState.Partial: {
                setExpandAllState(ActivityItemOpenState.Open);
                return;
              }
              case ActivityItemOpenState.Open: {
                setExpandAllState(ActivityItemOpenState.Closed);
                return;
              }
            }
          }}
        >
          {
            {
              [ActivityItemOpenState.Closed]: 'Default view',
              [ActivityItemOpenState.Partial]: 'Expand all',
              [ActivityItemOpenState.Open]: 'Collapse all'
            }[expandAllState]
          }
        </CydButton>

        <CydButton
          startIcon="add"
          onClick={() => setIsDrawerOpen(true)}
          css={css`
            flex: 0 0 auto;
          `}
        >
          Add Item
        </CydButton>
      </div>

      <ActivityView //responsible for dropzone and key commands, be aware that this component has the role 'button'
        cydCase={cydCase}
        css={css`
          flex: 1 0 auto;
          height: 100%;
        `}
      >
        {isToggleEnabled('REACT_APP_USE_NEW_ACTIVITY_THREAD') ? (
          <>
            {caseActivityQuery.isLoading && <LoadingSpinner />}

            {caseActivityQuery.data && (
              <CydCaseViewActivitiesThread
                caseData={cydCase}
                activities={caseActivityQuery.data}
                expandAllToggleState={expandAllState}
                // when clicking edit/reply buttons
                onChange={(activityItemEl) => {
                  // set the selectedActivityElement so that the updateScrollContainerHeight can scroll to it instead of scrolling base on the height of the scroll container
                  setSelectedActivityElement(activityItemEl);
                  setIsDrawerOpen(true);
                }}
                onLastRowVisible={(isVisible) => {
                  setIsLastActivityVisible(isVisible);
                }}
                onNewItemMounted={(activityItem, activityItemEl) => {
                  if (!currentUser) {
                    return;
                  }
                  // AC3 - on reply - https://cydarm.atlassian.net/wiki/spaces/FEAT/pages/1803747329/Case+Thread+Behaviour
                  if (
                    CaseActivityUtils.shouldScrollToElement({
                      isDrawerOpen,
                      activityItem,
                      currentUserUuid: currentUser.uuid,
                      isRootLevelActivityItem: !!caseActivities?.find(
                        (c) => c.uuid === activityItem.uuid
                      ),
                      isRootLatestActivityItemVisible: isLastActivityVisible
                    })
                  ) {
                    scrollToElement(activityItemEl, 'top');
                  }
                }}
              />
            )}
          </>
        ) : (
          <CaseActivitiesWrapper
            styles={activitiesWrapper}
            massToggle={expandAllState === ActivityItemOpenState.Open}
            activities={caseActivityQuery.data}
            caseAcl={cydCase.acl}
            isInitializeMassToggle={false} // set comment to be half open by default
          />
        )}
      </ActivityView>

      <CaseViewPageCaseDataDrawer
        caseDetails={cydCase}
        formDataState={formDataState}
        onTabChange={() => {
          // Form tab is very short, this is to ensure the height of the scroll container is always touching the top of the drawer
          // wait for the tab changed to be reflected in the DOM before updating the scroll container height
          setTimeout(() => {
            updateScrollContainerHeight();
          }, 10);
        }}
      />
    </div>
  );
}
