/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  createAddCaseToCaseCacheAction,
  createDeleteCaseFromCaseCacheAction,
  createFetchCaseCaseAction
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { caseCacheFetchResolved } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  apiAddCaseToCaseCache,
  apiDeleteCaseFromCaseCache,
  apiFetchCaseCache
} from 'services/CaseCacheService';

function* fetchCaseCache() {
  try {
    const { json } = yield apiFetchCaseCache();
    yield put(caseCacheFetchResolved(json));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CASE_CACHE_FETCH_ERROR.message
      })
    );
  }
}

function* addCaseToCaseCache({ payload: uuid }: PayloadAction<string>) {
  const newCaseRequest = { caseUuid: uuid };

  try {
    yield apiAddCaseToCaseCache(newCaseRequest);
    yield put(createFetchCaseCaseAction());
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CASE_CACHE_ADD_ERROR.message
      })
    );
  }
}

function* deleteCaseFromCaseCache({ payload: uuid }: PayloadAction<string>) {
  try {
    yield apiDeleteCaseFromCaseCache(uuid);
    yield put(createFetchCaseCaseAction());
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CASE_CACHE_DELETE_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchCaseGroup() {
  yield takeLatest(createFetchCaseCaseAction, fetchCaseCache);
}

function* watchAddCaseToCaseGroup() {
  yield takeEvery(createAddCaseToCaseCacheAction, addCaseToCaseCache);
}

function* watchDeleteCaseFromCaseGroup() {
  yield takeEvery(createDeleteCaseFromCaseCacheAction, deleteCaseFromCaseCache);
}

export default [
  watchFetchCaseGroup(),
  watchAddCaseToCaseGroup(),
  watchDeleteCaseFromCaseGroup()
];
