/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CydApi_BaseCase } from 'services';
import { debounce } from '@mui/material/utils';
import { rtkqCasesApi } from 'hooks/CaseHooksRq';
import { PaginatedLoadMoreFunctionResult } from 'components/_dataDisplay/CydDataTable/CydDataTable';

/** @jsxImportSource @emotion/react */

export type CydCaseSelectorProps = {
  /**
   * For controlled usage
   *
   * Note that this value is the case LOCATOR
   * The reason is, because when you have an item selected, it needs to show in the text field, and the locator is more readable than the uuid
   */
  selectedCaseLocator?: string;

  /**
   * For uncontrolled usage
   *
   * Note that this value is the case LOCATOR
   * The reason is, because when you have an item selected, it needs to show in the text field, and the locator is more readable than the uuid
   */
  defaultSelectedCaseLocator?: string;

  onChange?: (
    caseLocator: string | null,
    cydarmCase: CydApi_BaseCase | null
  ) => void;

  label?: string;

  /**
   * If not provided a default will be used
   * nb. This load more function MUST BE RESPONSIBLE FOR RETURNING ALL PREVIOUS RESULTS TOO
   * ie. if you are fetching page 3, the function passed in must also be returning the results of page 0, 1, 2
   * The component is not keeping track of previous searches.
   * @returns
   */
  loadMoreFunction?: (
    searchString: string,
    pagination: {
      pageSize: number;
      pageNumber: number;
    },
    excludeList?: Array<string>
  ) => Promise<SearchResult>;

  disabled?: boolean;
  name?: string;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;

  /**
   * An array of case UUIDS to exclude from the results (ie. the items that already selected/case you are already on)
   * Note the mismatch between that the _values_ provided by this component are case locators
   */
  excludeList?: Array<string>;
};

export type SearchResult = {
  data: Array<CydApi_BaseCase>;
  paginationInfo: {
    searchString: string;
    totalResults: number;
    pageSize: number;
    pageNumber: number;
  };
};

function useDefaultLoadMoreFunction() {
  const [query] = rtkqCasesApi.endpoints.getCasesFiltered.useLazyQuery();

  return async (
    searchString: string,
    pagination: {
      pageSize: number;
      pageNumber: number;
    },
    excludeArray?: Array<string>
  ): Promise<SearchResult> => {
    // We need to actually return all pages 0 to current page num,
    // These should already be cached.
    const allProms = new Array(pagination.pageNumber + 1)
      .fill(true)
      .map((v, i) => {
        return query(
          {
            filterOptions: {
              filter: {
                text: searchString
              },
              page: {
                number: i,
                size: pagination.pageSize
              }
            }
          },
          true
        );
      });

    const allResults = await Promise.all(allProms);
    if (allResults.every((v) => v.isSuccess)) {
      const allResultsTyped = allResults as Array<{
        data: PaginatedLoadMoreFunctionResult<CydApi_BaseCase>;
      }>;

      const lastResult = allResultsTyped[allResults.length - 1];

      return {
        data: allResultsTyped
          .flatMap((v) => v.data.data)
          .filter((v) => {
            if (excludeArray) {
              return !excludeArray.includes(v.uuid);
            }
            return true;
          }),
        paginationInfo: {
          totalResults: lastResult.data.paginationInfo.totalNumItems,
          pageNumber: lastResult.data.paginationInfo.pageNum,
          pageSize: lastResult.data.paginationInfo.numPerPage,
          searchString: searchString
        }
      };
    }
    throw new Error('Unknown error');
  };
}

function LoadMoreElement(
  props: React.HTMLAttributes<HTMLLIElement> & {
    onScrollIntoView: () => void;
    isLoading: boolean;
    paginationInfo: {
      totalResults: number;
      pageNumber: number;
      pageSize: number;
      searchString: string;
    };
  }
) {
  const { onScrollIntoView, isLoading, paginationInfo } = props;

  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref.current) {
      const ulNode = ref.current?.parentNode?.parentNode;
      if (!ulNode) {
        throw new Error('No ul node found');
      }
      let options = {
        root: ulNode as HTMLUListElement,
        rootMargin: '20px',
        threshold: 0.5
      };

      let observer = new IntersectionObserver((e) => {
        if (e[e.length - 1]?.isIntersecting) {
          onScrollIntoView();
        }
      }, options);
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li
      className="MuiAutocomplete-option"
      css={(theme) => `
    color: ${theme.palette.text.secondary}
  `}
    >
      {isLoading ? (
        <span> Loading more... </span>
      ) : (
        <span ref={ref}>
          Load more (currently showing{' '}
          {(paginationInfo.pageNumber + 1) * paginationInfo.pageSize} of{' '}
          {paginationInfo.totalResults}){' '}
        </span>
      )}
    </li>
  );
}

const PAGE_SIZE = 20;
const TYPING_DEBOUNCE_MS = 400;

export const CydCaseSelector = (props: CydCaseSelectorProps) => {
  const {
    selectedCaseLocator,
    defaultSelectedCaseLocator,
    onChange,
    label,
    disabled,
    name,
    className,
    autoFocus,
    required,
    loadMoreFunction,
    excludeList
  } = props;

  const defaultLoadMoreFunction = useDefaultLoadMoreFunction();

  const [lastSearchResult, setLastSearchResult] = useState<SearchResult>({
    data: [],
    paginationInfo: {
      searchString: '',
      totalResults: 0,
      pageNumber: 0,
      pageSize: PAGE_SIZE
    }
  });

  const [valuesArray, valuesMap] = useMemo((): [
    valuesArray: Array<string>,
    valuesMap: Record<string, CydApi_BaseCase>
  ] => {
    const newOptionsMap = lastSearchResult.data.reduce((acc, cur) => {
      acc[cur.locator] = cur;
      return acc;
    }, {});

    const optionsValueArray = Object.keys(newOptionsMap);

    if (
      lastSearchResult.paginationInfo.totalResults >
      (lastSearchResult.paginationInfo.pageNumber + 1) *
        lastSearchResult.paginationInfo.pageSize
    ) {
      optionsValueArray.push('load-more');
    }

    return [optionsValueArray, newOptionsMap];
  }, [lastSearchResult]);

  const [isLoading, setIsLoading] = useState(false);

  const loadMoreFunctionToUse = loadMoreFunction ?? defaultLoadMoreFunction;
  const debouncedFetchData = React.useMemo(
    () =>
      debounce(
        (
          request: { str: string; pageNumber: number },
          callback: (results: SearchResult) => void
        ) => {
          const result = loadMoreFunctionToUse(
            request.str,
            {
              pageNumber: request.pageNumber,
              pageSize: PAGE_SIZE
            },
            excludeList
          );
          return result.then(callback);
        },
        TYPING_DEBOUNCE_MS
      ),
    [excludeList, loadMoreFunctionToUse]
  );

  const handleLoad = (str: string, pageNumber: number) => {
    setIsLoading(true);

    // We use the debounced loaded more if it's due to the user typing text
    if (pageNumber === 0) {
      debouncedFetchData(
        {
          str,
          pageNumber
        },
        (result) => {
          setIsLoading(false);
          setLastSearchResult(result);
        }
      );
    }
    // But otherwise take every for the 'load more'
    else {
      loadMoreFunctionToUse(
        str,
        {
          pageNumber: pageNumber,
          pageSize: PAGE_SIZE
        },
        excludeList
      ).then((v) => {
        setIsLoading(false);
        setLastSearchResult(v);
      });
    }
  };

  return (
    <>
      <Autocomplete
        className={className}
        disabled={disabled}
        options={valuesArray}
        value={selectedCaseLocator}
        defaultValue={defaultSelectedCaseLocator}
        filterOptions={(v) => v}
        loading={isLoading}
        renderOption={(props, value) => {
          if (value === 'load-more') {
            return (
              <LoadMoreElement
                {...props}
                /**
                 * The key is important
                 * We want this element to remount every time the page changes
                 */
                key={`${lastSearchResult.paginationInfo.pageNumber}-${isLoading}`}
                isLoading={isLoading}
                paginationInfo={lastSearchResult.paginationInfo}
                onScrollIntoView={() => {
                  handleLoad(
                    lastSearchResult.paginationInfo.searchString,
                    lastSearchResult.paginationInfo.pageNumber + 1
                  );
                }}
              />
            );
          } else {
            return (
              <li {...props}>
                <span
                  css={(theme) => `
            border: solid 1px ${theme.palette.text}; 
            border-radius: 50%; 
            font-size: 10px; 
            margin-right: 1em;
        `}
                >
                  {valuesMap[value].locator}
                </span>
                <span>{valuesMap[value].description}</span>
              </li>
            );
          }
        }}
        onOpen={() => {
          handleLoad('', 0);
        }}
        onChange={(event, stringValue, reason, details) => {
          if (stringValue) {
            onChange?.(stringValue, valuesMap[stringValue]);
          } else {
            onChange?.(null, null);
          }
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              required={required}
              label={label}
              autoFocus={autoFocus}
              name={name}
              onInput={(e) => {
                //@ts-ignore
                handleLoad(e.target.value, 0);
              }}
            />
          </>
        )}
      />
    </>
  );
};
