/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { TableRowProps } from '@mui/material/TableRow';
import { FC, forwardRef, memo } from 'react';
import TableRow from '@mui/material/TableRow';

/** @jsxImportSource @emotion/react */

export const DefaultTableRow: FC<TableRowProps> = memo(
  forwardRef(({ ...props }, ref) => {
    return (
      <TableRow
        css={(theme) => `
          cursor: pointer;
          transition: background-color 0.15s ease;
          &:hover {
            background-color: ${theme.palette.background.default};
          }
          & a:any-link {
            color: inherit;
            text-decoration: inherit;
          }
       `}
        ref={ref}
        {...props}
      />
    );
  })
);
