/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  useAttachFilesToWikiPage,
  useCreateWikiPage,
  useDeleteFileFromWikiPage,
  useDeleteWikiPage,
  useEditWikiPage,
  useWikiPage
} from 'hooks/WikiHooks';
import { NotFoundPage } from 'pages/NotFoundPage';
import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { WikiPageHeader } from './WikiPageHeader/WikiPageHeader';
import { WikiPageContent } from './WikiPageContent/WikiPageContent';
import { useCurrentDataAcls, useDefaultAclForOrg } from 'hooks/AclHooks';
import { List } from '@mui/material';
import { WikiPageStubResource } from 'generatedApi';
import { JsonApiDataItem } from 'interface/JsonApi';
import {
  CydApi_WikiPageObject,
  CydApi_WikiPageObjectRelationships
} from 'services';
import { ConfirmDialog } from 'components/ConfirmDialog';
import Dropzone from 'components/Dropzone/Dropzone';
import { readCaseDataFile } from 'utils/CaseDataUtils';
import { WikiAttachmentListItem } from 'pages/WikiPage/WikiAttachmentListItem';

export type WikiPageDataProps = {};

export const WikiPageData = (props: WikiPageDataProps) => {
  const params = useParams<{ wikiUuid?: string }>();
  const wikiUuid = params.wikiUuid;
  const dropzoneRef = useRef<any>();

  const [deleteAttachmentId, setDeleteAttachmentId] = React.useState<string>();
  const createWikiPageMutation = useCreateWikiPage();
  const attachFilesToWikiPageMutation = useAttachFilesToWikiPage();
  const deleteFileToWikiPageMutation = useDeleteFileFromWikiPage();
  const editWikiPageMutation = useEditWikiPage();
  const deleteWikiPageMutation = useDeleteWikiPage();
  const wikiPageResult = useWikiPage(wikiUuid);
  const availableAclsResult = useCurrentDataAcls();
  const defaultAcl = useDefaultAclForOrg();

  if (
    wikiPageResult.isError &&
    (wikiPageResult.error as Error).message !== '404'
  ) {
    throw wikiPageResult.error;
  }

  const is404 =
    wikiPageResult.isError && (wikiPageResult.error as Error).message === '404';

  const wikiPageResultData = wikiPageResult.data;
  // determine which of the children is an attachment
  const attachmentStubs: Array<WikiPageStubResource> = [];
  if (wikiPageResultData?.included) {
    wikiPageResultData.included.forEach((v) => {
      if (
        v['type'] === 'wiki-page' &&
        typeof v['id'] === 'string' &&
        v['attributes']?.['mime_type'] !== 'text/markdown'
      ) {
        attachmentStubs.push(v);
      }
    });
  }

  const wikiItem = wikiPageResultData?.data as JsonApiDataItem<
    CydApi_WikiPageObject,
    CydApi_WikiPageObjectRelationships
  >;

  const handleAttachFileUpload = useCallback(
    async (files) => {
      const attachmentData = await readCaseDataFile(files);
      attachFilesToWikiPageMutation.mutate({
        attachments: attachmentData.fileData.map((attachment) => ({
          content: attachment.data,
          file_name: attachment.fileName,
          mime_type: attachment.mimeType,
          parent_uuid: wikiItem.id
        })),
        wikiUuid: wikiItem.id
      });
    },
    [wikiItem, attachFilesToWikiPageMutation]
  );
  return (
    <div>
      {is404 && (
        <NotFoundPage
          isAuthenticated
          css={css`
            margin-top: 100px;
          `}
        />
      )}
      {wikiPageResultData && (
        <>
          <Dropzone
            ref={dropzoneRef}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            `}
            onDrop={handleAttachFileUpload}
            active
          />
          <WikiPageHeader
            wikiItem={wikiItem}
            onDeleteWikiItem={(item) =>
              deleteWikiPageMutation.mutate({ wikiUuid: item.id })
            }
            onCreateChildWikiItem={(item) =>
              createWikiPageMutation.mutate(item)
            }
            onAttachClick={() => {
              if (dropzoneRef.current) {
                dropzoneRef.current.open();
              }
            }}
            onEditWikiItem={(oldItem, newData) => {
              editWikiPageMutation.mutate({
                wikiUuid: oldItem.id,
                newData
              });
            }}
            availableAcls={availableAclsResult.data}
            defaultAcl={defaultAcl.data}
          />
          <WikiPageContent wikiItem={wikiItem} />
          <List>
            {attachmentStubs.map((v) => (
              <WikiAttachmentListItem
                key={v.id}
                attachment={v}
                onDeleteClick={() => setDeleteAttachmentId(v.id)}
              />
            ))}
          </List>
        </>
      )}
      {deleteAttachmentId && (
        <ConfirmDialog
          open
          title="Do you want to delete this file?"
          onClose={() => setDeleteAttachmentId(undefined)}
          onConfirm={() => {
            deleteFileToWikiPageMutation.mutate({
              attachmentId: deleteAttachmentId,
              wikiUuid: wikiUuid as string
            });
            setDeleteAttachmentId(undefined);
          }}
        />
      )}
    </div>
  );
};
