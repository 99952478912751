/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Grid from '@mui/material/Grid';
import { Typography, Tooltip, Link } from '@mui/material';
import { css } from '@emotion/react';
import { ICaseMeta } from 'interface/Case.interface';

/** @jsxImportSource @emotion/react */

type CydMetadataDisplayProps = {
  metadata: { [key: string]: ICaseMeta };
};

export const CydMetadataDisplay = ({
  metadata = {}
}: CydMetadataDisplayProps) => {
  const metadataKeys = Object.keys(metadata);

  const tableCellStyle = css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `;
  const hasMetadata = metadataKeys.length > 0;

  function isUrlValid(str) {
    try {
      const newUrl = new URL(str);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }
  const itemValue = (uri: string) => {
    if (isUrlValid(uri)) {
      return (
        <Link
          aria-label={uri}
          css={(theme) => `cursor: pointer;
        margin-right: ${theme.spacing(4)};
        display: block;`}
          underline="none"
          target="_blank"
          href={uri}
        >
          {uri}
        </Link>
      );
    }
    return uri;
  };
  if (!hasMetadata) {
    return (
      <Typography variant="body2" color="text.secondary">
        No metadata added
      </Typography>
    );
  }
  return (
    <div
      css={css`
        max-height: 100px;
        overflow-y: auto;
      `}
    >
      {metadataKeys.map((metaKey) => {
        const meta: ICaseMeta = metadata[metaKey];
        return (
          <Grid container key={meta.name} spacing={2}>
            <Grid item xs={6}>
              <Tooltip disableFocusListener title={meta.name}>
                <Typography css={tableCellStyle}>{meta.name}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip disableFocusListener title={meta.value}>
                <Typography css={tableCellStyle}>
                  {itemValue(meta.value)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
