/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { AbstractOpenApiService } from 'services/openapi/AbstractOpenApiService';
import { CydApi_ConnectorOperationType } from 'services/ConnectorsService';
import { ConnectorOperationTypeResource } from 'generatedApi/models/ConnectorOperationTypeResource';
import { Configuration } from 'generatedApi/runtime';
import { ConnectorOperationTypeApi } from 'generatedApi/apis/ConnectorOperationTypeApi';

class ConnectorOperationTypeService extends AbstractOpenApiService<
  CydApi_ConnectorOperationType,
  ConnectorOperationTypeResource
> {
  constructor() {
    super('connectorOperationType');
  }

  protected createApi(config: Configuration): ConnectorOperationTypeApi {
    return new ConnectorOperationTypeApi(config);
  }

  public transformFieldsToOpenApiResource(
    data: CydApi_ConnectorOperationType
  ): ConnectorOperationTypeResource {
    return {
      id: data.id,
      attributes: {
        name: data.name,
        display_name: data.display_name
      }
    };
  }

  public transformOpenApiResourceToFields(
    resource: ConnectorOperationTypeResource
  ): CydApi_ConnectorOperationType {
    const attributes = resource.attributes;
    return {
      id: resource.id,
      name: attributes?.name,
      display_name: attributes?.display_name
    } as CydApi_ConnectorOperationType;
  }
}

export const connectorOperationTypeService =
  new ConnectorOperationTypeService();
