/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { createSelector } from 'reselect';
import { IStore } from 'states/store.interface';
import { ContactType } from 'interface/Contacts.interface';

export const contactsSelector = ({ contacts: { results } }: IStore) => {
  return [...results].sort((a, b) => {
    return a.data.name > b.data.name ? 1 : -1;
  });
};

export const contactsHasLoadedSelector = ({
  contacts: { hasLoaded }
}: IStore) => hasLoaded;

export const contactsStatusSelector = ({
  contacts: { hasLoaded, isLoading }
}: IStore) => hasLoaded || isLoading;

export const contactOrgsSelector = createSelector(
  contactsSelector,
  (contacts) =>
    contacts.filter(({ data }) => data.contactType === ContactType.ORGANIZATION)
);

export const contactListNameSelector = ({ contacts: { results } }: IStore) =>
  results.map((el) => el.data.name);
