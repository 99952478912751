/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { syntaxHighlightingMap } from 'utils/CaseDataUtils';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import {
  determineLanguageFromMimeTypeOrFileName,
  useDisplayAsJsonTable,
  useDisplayAsJsonYaml
} from 'hooks/CaseContentHooks';
import { dump } from 'js-yaml';
import { CaseActivityUtils } from 'utils/CaseActivityUtils';
import { CydFormErrorMessage } from 'components/_messages/CydFormErrorMessage/CydFormErrorMessage';

/** @jsxImportSource @emotion/react */

export const CaseActivityContentSyntaxHighlighter = ({ caseActivity }) => {
  const { htmlString, content, mimetype, filename } = caseActivity;
  const displayJsonAsYaml = useDisplayAsJsonYaml(content);
  const displayJsonAsTable = useDisplayAsJsonTable(content);

  if (CaseActivityUtils.isMalformedJSON(caseActivity)) {
    return (
      <CydFormErrorMessage
        css={(theme) => `
          margin-bottom: ${theme.spacing(4)};
        `}
        message="Malformed JSON"
      />
    );
  }

  let language = determineLanguageFromMimeTypeOrFileName(mimetype, filename);
  let parsedContent = content;
  if (displayJsonAsYaml && language === 'application/json') {
    parsedContent = dump(JSON.parse(content));
    language = 'application/yaml';
  }
  // always pretty print json
  if (language === 'application/json') {
    parsedContent = JSON.stringify(JSON.parse(content), null, 2);
  }
  return !htmlString &&
    language &&
    !(language === 'application/json' && displayJsonAsTable) ? (
    <SyntaxHighlighter
      language={language && syntaxHighlightingMap[language]}
      style={coldarkDark}
    >
      {`${parsedContent}`}
    </SyntaxHighlighter>
  ) : null;
};
