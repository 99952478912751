/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { jsx } from '@emotion/react';
import { tableBorderBottom } from 'components/DefaultTable/style';
import { PropsWithChildren } from 'react';

/** @jsxImportSource @emotion/react */

export const DefaultTableCell = ({
  children,
  noWrap,
  className,
  as,
  onClick
}: PropsWithChildren<{
  noWrap?: boolean;
  className?: string;
  as?: string;
  onClick?: React.MouseEventHandler<HTMLTableCellElement>;
}>) => {
  return jsx(as || 'td', {
    css: (theme) => `
      font-size: 15px;
      position: relative;
      color: ${theme.palette.text.secondary};
      padding: 8px;
      border-bottom: ${tableBorderBottom(theme)};
      word-break: ${noWrap ? 'normal' : 'break-word'};
      white-space: ${noWrap ? 'nowrap' : 'inherit'};
      overflow-wrap: ${noWrap ? 'normal' : 'anywhere'};
    `,
    className,
    children,
    onClick
  });
};
