/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useCallback, useMemo } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  createFetchDataSignificances,
  createFetchCaseData
} from 'states/data/actions';
import { createUpdateData, createAddCaseData } from 'states/data/actions';
import { createAddStixData } from 'states/caseStix/actions';
import { useCurrentUser } from './AuthHooks';
import { readCaseDataFile } from 'utils/CaseDataUtils';
import { StixBuilder } from 'utils/StixBuilder';
import { Base64Encode } from 'utils/StringUtils';
import {
  dataSignificancesSelector,
  multiCaseActivitySelector,
  dataSignificancesStatusSelector,
  filteredDataSignificancesSelector,
  defaultDataSignificanceSelector,
  caseContributorsSelector
} from 'states/data/selectors';

import { useAutoEffect } from './ReduxHooks';
import { useAllUsers } from './UserHooks';
import { CYDARM_BASE_API, MIMETYPES } from '../constants';
import { createUpdateCase } from 'states/cases/actions';
import { Case } from 'interface/Case.interface';
import {
  BaseQueryApi,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/dist/query/react';
import {
  CydApi_StixDataUploadBody,
  apiAddCaseData,
  apiAddCaseStixPath,
  apiUploadFileToCase,
  apiFetchDetailedDataset
} from 'services';
import {
  rtkqCaseActivityApi,
  useGetAllCaseActivity
} from './CaseActivityHooks';
import { extraIsApiServices, handleRtkqError } from 'utils/rtkqUtils';
import { imperativeAddNotification } from 'states/imperitiveAddNotif';
import { ICreateCaseDataRequest } from 'interface/CaseData.interface';
import { CaseDataTopLevel } from 'generatedApi/models/CaseDataTopLevel';
import { CyFetchResponse } from 'utils/CydarmFetch';
/**
 * This is an important pattern to avoid circular dependencies anywhere we interact with store directly
 * See:
 *
 * https://github.com/reduxjs/redux-toolkit/issues/1540
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 *
 *
 * @param store
 */
let store;
export function provideStore(_store) {
  store = _store;
}

export const useCaseMemberOfMemberData = (caseUuids: string[]) => {
  return useAutoEffect({
    selector: multiCaseActivitySelector,
    ac: createFetchCaseData,
    selectorData: caseUuids,
    spreadDispatch: true,
    actionData: caseUuids.map((uuid) => ({ uuid }))
  });
};

const commonDataSigAutoEffect = {
  ac: createFetchDataSignificances,
  equality: shallowEqual,
  statusSelector: dataSignificancesStatusSelector,
  isLoadingSelector: (store) => store.data.isLoading,
  hasLoadedSelector: (store) => store.data.hasLoaded
};

export const useDataSignificancesRedux = () => {
  return useAutoEffect({
    selector: dataSignificancesSelector,
    ...commonDataSigAutoEffect
  });
};

export const useFilteredDataSignificances = () => {
  return useAutoEffect({
    selector: filteredDataSignificancesSelector,
    ...commonDataSigAutoEffect
  });
};

export const useDefaultDataSignificance = () => {
  return useAutoEffect({
    selector: defaultDataSignificanceSelector,
    ...commonDataSigAutoEffect
  });
};

export const useCaseContributors = (caseUuid: string) => {
  useAllUsers();

  // RTKQ call - so we can reuse cache data from rtkq
  const {
    data: caseActivities,
    isLoading,
    isError,
    isSuccess
  } = useGetAllCaseActivity({ caseUuid });

  const data = useSelector((state) => {
    // combining rtkq data with existing redux data (from saga)
    return caseContributorsSelector(caseActivities?.case_data ?? [])(
      state,
      caseUuid
    );
  });
  return { data, isLoading, isError, isSuccess };
};

export const useCaseDataFormHandler = (
  caseUuid: string,
  action: string,
  manipulatingCaseUuid?: string,
  callbackFn?: () => void
) => {
  const { data: currentUser } = useCurrentUser();
  const dispatch = useDispatch();

  const caseDataStore = store.getState().caseData;
  const currentCaseDataUuids = useMemo(
    () => (caseDataStore.caseData && caseDataStore.caseData[caseUuid]) || [],
    [caseDataStore.caseData, caseUuid]
  );

  return useCallback(
    async (data, stixOnly?: boolean) => {
      if (!currentUser) {
        return;
      }

      let caseDataFiles = data.files ? await readCaseDataFile(data.files) : {};

      if (stixOnly && 'stixData' in caseDataFiles) {
        caseDataFiles = {
          stixData: caseDataFiles.stixData,
          fileData: []
        };
      }

      const actionToUse = data.type || action;

      switch (actionToUse) {
        case 'stix':
          const caseStixData: string = StixBuilder.buildData(data);
          dispatch(
            createAddStixData({
              stixData: {
                bundleAcl: data.acl!.description,
                caseUuid: caseUuid,
                data: Base64Encode(caseStixData),
                mimeType: data.mimeType,
                significance: data.significance!.name,
                userUuid: currentUser.uuid
              },
              currentCaseDataUuids
            })
          );
          break;
        case 'edit':
          dispatch(
            createUpdateData({
              dataStubUuid: manipulatingCaseUuid!,
              data: {
                bytedata: Base64Encode(data.mde),
                acl: data.acl!.uuid,
                significance: data.significance!.name
              }
            })
          );
          break;
        case 'reply':
          dispatch(
            createAddCaseData({
              caseData: {
                data: Base64Encode(data.mde),
                acl: data.acl,
                significance: data.significance!.name,
                userUuid: currentUser.uuid,
                caseUuid,
                mimeType: 'text/plain',
                ...caseDataFiles
              },
              currentCaseDataUuids,
              parentCaseDataStubUuid: manipulatingCaseUuid
            })
          );
          break;
        case 'forms':
          dispatch(
            createAddCaseData({
              caseData: {
                data: Base64Encode(data.data),
                acl: data.acl,
                significance: data.significance?.name,
                userUuid: currentUser.uuid,
                caseUuid,
                mimeType: MIMETYPES.JSON_FORM
              }
            })
          );
          break;
        default:
          dispatch(
            createAddCaseData({
              caseData: {
                data: Base64Encode(data.mde),
                acl: data.acl!,
                significance: data.significance && data.significance.name,
                userUuid: currentUser.uuid,
                caseUuid,
                mimeType: 'text/plain',
                ...caseDataFiles
              }
            })
          );
          break;
      }

      if (callbackFn) {
        callbackFn();
      }
    },
    [
      action,
      manipulatingCaseUuid,
      caseUuid,
      currentCaseDataUuids,
      callbackFn,
      currentUser,
      dispatch
    ]
  );
};

export const useUpdateCase = (cydCase?: Case) => {
  const dispatch = useDispatch();
  return (caseData: Partial<Case>, justificationReason?: string) => {
    if (!cydCase) {
      throw new Error('Can not update a case because none was given');
    }

    dispatch(
      createUpdateCase({
        caseUuid: cydCase.uuid,
        caseData: caseData,
        justificationText: justificationReason,
        successNotification: {
          message: `Updated case ${cydCase.locator}`
        }
      })
    );
  };
};

export const rtkqCaseDataApi = createApi({
  reducerPath: 'rtk-case-data',
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),
  endpoints: (builder) => {
    return {
      fetchFile: builder.query<
        string,
        {
          caseDataUuid: string;
        }
      >({
        queryFn: async (args, api, extraOptions) => {
          const { caseDataUuid } = args;
          if (!caseDataUuid) {
            throw new Error('No caseDataUuid to call function with');
          }
          const fileData: Response =
            await apiFetchDetailedDataset(caseDataUuid);

          const base64Image: string = await new Promise(async (resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(await fileData.blob());
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
          });
          return { data: base64Image };
        }
      }),

      uploadFile: builder.mutation<
        unknown,
        {
          caseUuid: string;
          parentUuid?: string;
          data: {
            acl_uuid: string;
            data: string;
            fileLastMod: number;
            fileName: string;
            mimeType: string;
            significance: string;
          };
        }
      >({
        queryFn: async (args, api) => {
          try {
            const result = await apiUploadFileToCase({
              caseUuid: args.caseUuid,
              parentUuid: args.parentUuid ?? null,
              data: args.data,
              fileName: args.data.fileName
            });

            //imperatively reset the cache on the other slice
            api.dispatch(
              rtkqCaseActivityApi.endpoints.invalidateCaseActivity.initiate({
                caseUuid: args.caseUuid
              })
            );

            return {
              data: result
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),

      addBulkIoc: builder.mutation<
        CaseDataTopLevel,
        {
          payload: ICreateCaseDataRequest;
        }
      >({
        queryFn: async (args, api: BaseQueryApi) => {
          const apiService = extraIsApiServices(api.extra);

          return apiService
            .apiBulkIocUpload(args.payload)
            .then((result: CyFetchResponse<CaseDataTopLevel>) => {
              return {
                data: result.json
              };
            })
            .catch(async (reject: Response) => {
              reject.json().then((json) => {
                let errorText = '';
                json.errors?.forEach((error) => {
                  errorText += error.title;
                  errorText += '\n';
                });
                if (args.payload.setErrorText) {
                  args.payload.setErrorText(errorText);
                }
              });
              return await handleRtkqError(reject);
            });
        }
      }),

      addStix: builder.mutation<
        {
          uuid: string;
        },
        {
          caseUuid: string;
          parentUuid?: string;
          data: CydApi_StixDataUploadBody;
        }
      >({
        queryFn: async (args, api) => {
          try {
            const result = await apiAddCaseStixPath(
              args.caseUuid,
              args.data,
              args.parentUuid
            );

            //imperatively reset the cache on the other slice
            api.dispatch(
              rtkqCaseActivityApi.endpoints.invalidateCaseActivity.initiate({
                caseUuid: args.caseUuid
              })
            );

            imperativeAddNotification({ message: 'Uploaded STIX file' });
            return {
              data: {
                uuid: result.json.uuid
              }
            };
          } catch (err) {
            imperativeAddNotification({
              message: 'STIX file upload has failed'
            });
            return await handleRtkqError(err);
          }
        }
      }),

      addComment: builder.mutation<
        { uuid: string },
        {
          caseUuid: string;
          parentUuid?: string;
          data: {
            acl_uuid: string;
            data: string;
            significance: string;
            mimeType: 'text/plain';
          };
        }
      >({
        queryFn: async (args, api) => {
          try {
            const result = await apiAddCaseData(
              args.caseUuid,
              args.data,
              args.parentUuid
            );

            //imperatively reset the cache on the other slice
            api.dispatch(
              rtkqCaseActivityApi.endpoints.invalidateCaseActivity.initiate({
                caseUuid: args.caseUuid
              })
            );

            return {
              data: result.json
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      })
    };
  }
});

export function useFetchFileByUuid(caseDataUuid: string) {
  return rtkqCaseDataApi.endpoints.fetchFile.useQuery({ caseDataUuid });
}

export function useUploadFile() {
  return rtkqCaseDataApi.endpoints.uploadFile.useMutation();
}

export function useAddCommentRtkq() {
  return rtkqCaseDataApi.endpoints.addComment.useMutation();
}

export function useAddStixRtkq() {
  return rtkqCaseDataApi.endpoints.addStix.useMutation();
}

export function useBulkIocUpload() {
  return rtkqCaseDataApi.endpoints.addBulkIoc.useMutation();
}
