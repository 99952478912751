/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  useCommentDrawerState,
  useCommentModeState,
  useManipulatingCaseItemUuidState
} from 'hooks/CaseActivitiesUIHooks';
import { ICaseData } from 'interface/CaseData.interface';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createDeleteData, createDownloadData } from 'states/data/actions';
import {
  ActivityItem,
  ActivityItemOpenState,
  CydCaseViewActivityItem
} from '../CydCaseViewActivityItem/CydCaseViewActivityItem';

/** @jsxImportSource @emotion/react */

export type CydCaseViewActivitiesThreadProps = {
  activities: Array<ActivityItem>;

  caseData: ICaseData;

  expandAllToggleState: ActivityItemOpenState;

  onChange: (activityItemEl: HTMLDivElement) => void;

  onLastRowVisible: (isVisible: boolean) => void;

  onNewItemMounted: (
    activityItem: ActivityItem,
    activityItemEl: HTMLDivElement
  ) => void;
};

function getStubsToDelete(caseActivity: ActivityItem): Array<string> {
  const childrenStubs =
    caseActivity.children?.flatMap((v) => getStubsToDelete(v)) || [];

  return [...childrenStubs, caseActivity.dataStubUuid];
}

export const CydCaseViewActivitiesThread = (
  props: CydCaseViewActivitiesThreadProps
) => {
  const {
    activities,
    expandAllToggleState,
    caseData,
    onChange,
    onLastRowVisible,
    onNewItemMounted
  } = props;

  const [, setMode] = useCommentModeState();
  const [, setCaseUuid] = useManipulatingCaseItemUuidState();
  const [, setIsOpen] = useCommentDrawerState();

  const dispatch = useDispatch();

  // ALL OF THIS JUST COPIED FROM CaseActivityActions.tsx

  const handleDelete = (caseActivity: ActivityItem) => {
    const stubs = getStubsToDelete(caseActivity);

    stubs.forEach((v) => {
      dispatch(
        createDeleteData({
          dataStubUuid: v,
          caseUuid: caseActivity.caseuuid
        })
      );
    });
  };

  const handleDownload = (caseActivity: ActivityItem) => {
    dispatch(createDownloadData(caseActivity));
  };

  const handleChangeClick = React.useCallback(
    ({
      caseUuid,
      mode,
      activityItemContentEl
    }: {
      caseUuid: string;
      mode: 'edit' | 'reply';
      activityItemContentEl: HTMLDivElement;
    }) => {
      setMode(mode);
      setCaseUuid(caseUuid);
      setIsOpen(true);
      onChange(activityItemContentEl);
    },
    [onChange, setCaseUuid, setIsOpen, setMode]
  );

  const handleEdit = React.useCallback(
    (caseActivity: ActivityItem, activityItemContentEl: HTMLDivElement) => {
      handleChangeClick({
        mode: 'edit',
        caseUuid: caseActivity.uuid,
        activityItemContentEl
      });
    },
    [handleChangeClick]
  );

  const handleReply = React.useCallback(
    (caseActivity: ActivityItem, activityItemContentEl: HTMLDivElement) => {
      handleChangeClick({
        mode: 'reply',
        caseUuid: caseActivity.dataStubUuid,
        activityItemContentEl
      });
    },
    [handleChangeClick]
  );

  return (
    <div css={(theme) => `padding-bottom: ${theme.spacing(8)};`}>
      {activities.map((v, index) => {
        return (
          <CydCaseViewActivityItem
            openStateFromParent={expandAllToggleState}
            activityItem={v}
            key={v.uuid}
            caseAcl={caseData.acl as string}
            onDeleteClick={handleDelete}
            onDownloadClick={handleDownload}
            onReplyClick={handleReply}
            onEditClick={handleEdit}
            onVisible={(isVisible) => {
              // return isVisible value for the last row
              if (index === activities.length - 1) {
                onLastRowVisible(isVisible);
              }
            }}
            onNewItemMounted={(activityItemEl) =>
              onNewItemMounted(v, activityItemEl)
            }
          />
        );
      })}
    </div>
  );
};
