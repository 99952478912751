/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, call, takeLatest } from 'redux-saga/effects';
import {
  createAddActionToPlaybook,
  createAddTagToPlaybook,
  createPlaybook,
  createPlaybookByFile,
  createDeletePlaybook,
  createDuplicatePlaybook,
  createExportPlaybook,
  createFetchPlaybookByUuid,
  createFetchPlaybooks,
  ICreatePlaybook,
  createMovePlaybookAction,
  createRemoveActionFromPlaybook,
  createRemoveTagFromPlaybook,
  createUpdatePlaybook
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { AtcPlaybook } from 'interface/Playbook.interface';
import { addNotification } from 'states/notifications/slice';
import { history } from 'utils/HistoryUtils';
import {
  deletePlaybookFailed,
  deletePlaybookSuccess,
  fetchPlaybookByUuidSuccess,
  fetchPlaybooksFailed,
  fetchPlaybooksSuccess
} from './slice';
import {
  consolidateUuids,
  convertStepToBackendCompatableStep,
  removeStepTypePrefixes,
  uniquifyCacaoJson
} from 'utils/PlaybookUtils';
import { CydarmRoute } from 'interface/CydarmRoute';
import {
  apiAddActionToPlaybook,
  apiAddTagToPlaybook,
  apiCreatePlaybook,
  apiCreatePlaybookByFile,
  apiDeletePlaybook,
  apiDuplicatePlaybook,
  apiExportPlaybook,
  apiFetchPlaybookByUuid,
  apiFetchPlaybooks,
  apiInsertActionToPlaybook,
  apiRemoveActionFromPlaybook,
  apiRemoveTagFromPlaybook,
  apiUpdatePlaybook,
  apiCreatePlaybookAction
} from 'services/PlaybookServices';

export function* performFetchPlaybooks() {
  try {
    const { json } = yield apiFetchPlaybooks();
    yield put(fetchPlaybooksSuccess(json));
  } catch (ex) {
    yield put(fetchPlaybooksFailed(ERROR_MESSAGE.FETCH_PLAYBOOKS_ERROR));
  }
}

export function* performFetchPlaybookByUuid({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    const { json } = yield apiFetchPlaybookByUuid(uuid);
    yield put(fetchPlaybookByUuidSuccess(json));
  } catch (ex) {
    //TODO.. something?
  }
}

export function* performCreatePlaybook(action) {
  const { payload }: { payload: ICreatePlaybook } = action;
  try {
    const { resolve, ...rest } = payload;

    if (payload.atc) {
      const { json: createdPlaybookResponse } = yield apiCreatePlaybook(rest);
      yield put(createFetchPlaybookByUuid(createdPlaybookResponse.uuid));
      yield put(createFetchPlaybooks());

      if (resolve) {
        resolve(createdPlaybookResponse);
      }

      yield put(
        addNotification({
          message: `Playbook was created successfully`
        })
      );
    } else if (payload.cacao) {
      // There is a backend rule that all action names need to be unique
      // So we need to uniquify the names before we send them to the backend
      const uniqueJson = uniquifyCacaoJson(payload.cacao);

      // save the keys and steps before we submit the actions for creation
      const oldKeys = Object.keys(uniqueJson.workflow);
      const oldSteps = Object.values(uniqueJson.workflow);

      // First create the actions
      const allProms = oldSteps.map((v) => {
        const newAction = {
          cacao: {
            ...v,
            acl: payload.cacao?.acl
          }
        };

        return apiCreatePlaybookAction(newAction);
      });

      const result = yield Promise.all(allProms);

      // Now create a playbook
      const playbookToSend = consolidateUuids(
        uniqueJson,
        oldKeys,
        result.map((v) => v.json)
      );

      const { json: createdPlaybookResponse } = yield apiCreatePlaybook({
        cacao: playbookToSend
      });

      // Now add the actions to the playbook
      const allProms2 = result.map((v, i) => {
        const newSteps = Object.values(playbookToSend.workflow);

        return apiAddActionToPlaybook(
          createdPlaybookResponse.uuid,
          v.json.uuid,
          {
            cacao: convertStepToBackendCompatableStep(
              removeStepTypePrefixes(newSteps[i])
            )
          }
        );
      });

      yield Promise.all(allProms2);

      yield put(createFetchPlaybookByUuid(createdPlaybookResponse.uuid));
      yield put(createFetchPlaybooks());

      if (resolve) {
        resolve(createdPlaybookResponse);
      }

      yield put(
        addNotification({
          message: `Playbook was created successfully`
        })
      );
    }
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CREATE_PLAYBOOK_ERROR.message
      })
    );
  }
}

export function* performCreatePlaybookByFile(action) {
  const {
    payload: { fileList },
    resolve
  }: { payload: { fileList }; resolve } = action;
  try {
    yield apiCreatePlaybookByFile(fileList);
    yield put(createFetchPlaybooks());
    resolve
      ? yield put(addNotification({ message: resolve }))
      : yield put(addNotification({ message: `Successfully uploaded file` }));
  } catch (ex) {
    const responseCode: Number = ex.status;
    switch (responseCode) {
      case 409:
        yield put(
          addNotification({
            message: ERROR_MESSAGE.UPLOAD_PLAYBOOK_CONFLICT_ERROR.message
          })
        );
        break;
      case 404:
        yield put(
          addNotification({
            message: ERROR_MESSAGE.UPLOAD_PLAYBOOK_NOCONTENT_ERROR.message
          })
        );
        break;
      case 400:
        yield put(
          addNotification({
            message:
              `${ERROR_MESSAGE.UPLOAD_PLAYBOOK_FILE_ERROR.message} ${ex.message}` ||
              ERROR_MESSAGE.UPLOAD_PLAYBOOK_FILE_ERROR.message,
            expiryTime: 10000
          })
        );
        break;
      default:
        yield put(
          addNotification({
            message: ERROR_MESSAGE.CREATE_PLAYBOOK_ERROR.message
          })
        );
    }
  }
}

export function* performExportPlaybook({ payload: uuid }: { payload: string }) {
  try {
    const response: Response = yield apiExportPlaybook(uuid);
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    const blob = yield response.blob();
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = 'playbook.yaml';
    // attempt to retrieve filename
    const contentDisp: string | null = response.headers.get(
      'Content-Disposition'
    );
    if (contentDisp != null) {
      const parts: string[] = contentDisp.split('=');
      if (parts.length > 1) {
        anchor.download = unescape(parts[1]);
      }
    }
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    yield put(
      addNotification({
        message: `Playbook ${anchor.download} has been successfully exported`
      })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.EXPORT_PLAYBOOK_ERROR.message })
    );
  }
}

export function* performUpdatePlaybook(action) {
  const {
    payload: { playbook, uuid }
  }: { payload: { uuid: string; playbook: AtcPlaybook } } = action;
  try {
    yield apiUpdatePlaybook(uuid, {
      uuid,
      name: playbook.name,
      description: playbook.description,
      acl: playbook.acl.description
    });
    yield put(
      addNotification({ message: `Playbook ${playbook.name} has been saved` })
    );
    yield put(createFetchPlaybookByUuid(uuid));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.UPDATE_PLAYBOOK_ERROR.message })
    );
  }
}

export function* performDeletePlaybook({
  payload: { uuid, onSuccess }
}: {
  payload: { uuid: string; onSuccess: () => void };
}) {
  try {
    yield apiDeletePlaybook(uuid);
    yield put(deletePlaybookSuccess(uuid));
    onSuccess();
  } catch (ex) {
    yield put(deletePlaybookFailed(ERROR_MESSAGE.DELETE_PLAYBOOK_ERROR));
  }
}

export function* performAddActionToPlaybook(action) {
  const {
    payload: { playbookUuid, playbookActionUuid, PlaybookActionType }
  } = action;
  try {
    yield apiAddActionToPlaybook(
      playbookUuid,
      playbookActionUuid,
      PlaybookActionType
    );
    yield put(createFetchPlaybookByUuid(playbookUuid));
    yield put(
      addNotification({ message: 'Action added to playbook successfully' })
    );
  } catch (ex) {
    yield put(addNotification(ERROR_MESSAGE.ADD_ACTION_TO_PLAYBOOK_ERROR));
  }
}

export function* performRemoveActionFromPlaybook(action) {
  const {
    payload: {
      playbookUuid,
      playbookActionUuid,
      position,

      /**
       * The purpose of this is that the deduping functionality in CydarmFetch is causing issues when reordering, so we're providing optout
       */
      requireRefetch = true
    }
  } = action;
  try {
    yield apiRemoveActionFromPlaybook(
      playbookUuid,
      playbookActionUuid,
      position
    );

    if (requireRefetch) {
      yield put(createFetchPlaybookByUuid(playbookUuid));
    }
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.REMOVE_ACTION_FROM_PLAYBOOK_ERROR.message
      })
    );
  }
}

export function* performInsertActionToPlaybook(action) {
  const {
    payload: { playbookUuid, playbookActionUuid, position }
  } = action;
  try {
    yield apiInsertActionToPlaybook(playbookUuid, playbookActionUuid, position);
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.ADD_ACTION_TO_PLAYBOOK_ERROR.message
      })
    );
  }
}

export function* performMovePlaybookAction(action) {
  const {
    payload: { playbookUuid, playbookActionUuid, currentPosition, newPosition }
  } = action;
  try {
    yield call(performRemoveActionFromPlaybook, {
      payload: {
        playbookUuid,
        playbookActionUuid,
        position: currentPosition,
        requireRefetch: false
      }
    });

    yield call(performInsertActionToPlaybook, {
      payload: {
        playbookUuid,
        playbookActionUuid,
        position: newPosition
      }
    });
    yield put(createFetchPlaybookByUuid(playbookUuid));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.UPDATE_POSIITION_PLAYBOOK_ERROR.message
      })
    );
  }
}

export function* performAddTagToPlaybook(action) {
  const {
    payload: { playbookUuid, caseTagUuid }
  } = action;
  try {
    yield apiAddTagToPlaybook(playbookUuid, caseTagUuid);
    yield put(createFetchPlaybookByUuid(playbookUuid));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.ADD_TAG_TO_PLAYBOOK_ERROR.message
      })
    );
  }
}

export function* performRemoveTagFromPlaybook(action) {
  const {
    payload: { playbookUuid, caseTagUuid }
  } = action;
  try {
    yield apiRemoveTagFromPlaybook(playbookUuid, caseTagUuid);
    yield put(createFetchPlaybookByUuid(playbookUuid));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.REMOVE_TAG_FROM_PLAYBOOK_ERROR.message
      })
    );
  }
}

export function* performDuplicatePlaybook({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    const {
      json
    }: {
      json: AtcPlaybook;
    } = yield apiDuplicatePlaybook(uuid);
    yield put(createFetchPlaybooks());

    history.push(`${CydarmRoute.PLAYBOOKS}/${json.uuid}`);
    history.go(0);
    yield put(addNotification({ message: 'Successfully duplicate playbook' }));
  } catch (ex) {
    yield put(addNotification(ERROR_MESSAGE.DUPLICATE_PLAYBOOK_ERROR));
  }
}

/* Watchers */
function* watchFetchPlaybooks() {
  yield takeLatest(createFetchPlaybooks, performFetchPlaybooks);
}

function* watchFetchPlaybookByUuid() {
  yield takeLatest(createFetchPlaybookByUuid, performFetchPlaybookByUuid);
}

function* watchCreatePlaybook() {
  yield takeLatest(createPlaybook, performCreatePlaybook);
}

function* watchCreatePlaybookByFile() {
  yield takeLatest(createPlaybookByFile, performCreatePlaybookByFile);
}

function* watchExportPlaybook() {
  yield takeLatest(createExportPlaybook, performExportPlaybook);
}

function* watchDuplicatePlaybook() {
  yield takeLatest(createDuplicatePlaybook, performDuplicatePlaybook);
}

function* watchUpdatePlaybook() {
  yield takeLatest(createUpdatePlaybook, performUpdatePlaybook);
}

function* watchDeletePlaybook() {
  yield takeLatest(createDeletePlaybook, performDeletePlaybook);
}

function* watchAddActionToPlaybook() {
  yield takeLatest(createAddActionToPlaybook, performAddActionToPlaybook);
}

function* watchRemoveActionFromPlaybook() {
  yield takeLatest(
    createRemoveActionFromPlaybook,
    performRemoveActionFromPlaybook
  );
}

function* watchAddTagToPlaybook() {
  yield takeLatest(createAddTagToPlaybook, performAddTagToPlaybook);
}

function* watchRemoveTagFromPlaybook() {
  yield takeLatest(createRemoveTagFromPlaybook, performRemoveTagFromPlaybook);
}

function* watchMovePlaybookAction() {
  yield takeLatest(createMovePlaybookAction, performMovePlaybookAction);
}

export default [
  watchFetchPlaybooks(),
  watchFetchPlaybookByUuid(),
  watchCreatePlaybook(),
  watchCreatePlaybookByFile(),
  watchUpdatePlaybook(),
  watchExportPlaybook(),
  watchDeletePlaybook(),
  watchAddActionToPlaybook(),
  watchRemoveActionFromPlaybook(),
  watchAddTagToPlaybook(),
  watchRemoveTagFromPlaybook(),
  watchMovePlaybookAction(),
  watchDuplicatePlaybook()
];
