/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

export enum CydarmPage {
  HOME = 'HOME',
  AUTH = 'AUTH',
  DASHBOARD = 'DASHBOARD',
  PLAYBOOKS = 'PLAYBOOKS',
  PLAYBOOKS_ACTIONS = 'PLAYBOOK_ACTIONS',
  SETTINGS_ASSETS = 'SETTINGS_ASSETS',
  NOT_FOUND_PAGE = 'NOT_FOUND_PAGE'
}

export enum CydarmRoute {
  HOME = '/',
  AUTH = '/auth',
  DASHBOARD = '/launch',
  CHARTS = '/charts',
  CASE_LIST = '/caselist',
  CASE_VIEW = '/caseview/:caseUuid',
  QUICK_SEARCH = '/search/:query',
  SETTINGS = '/settings',
  SETTINGS_PROFILE = '/settings/profile',
  SETTINGS_USER_DETAIL = '/settings/users/:userUuid',
  SETTINGS_USERS = '/settings/users',
  SETTINGS_GROUPS = '/settings/groups',
  SETTINGS_GROUP_DETAIL = '/settings/groups/:groupUuid',
  SETTINGS_ORGS = '/settings/orgs',
  SETTINGS_ORG_DETAIL = '/settings/orgs/:orgUuid',
  SETTINGS_CONTACTS = '/settings/contacts',
  SETTINGS_ASSETS = '/settings/assets',
  SETTINGS_FORMS = '/settings/forms',
  SETTINGS_TAGS = '/settings/tags',
  SETTINGS_ACLS = '/settings/acls',
  SETTINGS_METADATA = '/settings/meta',
  SETTINGS_USER_PROPERTIES = '/settings/user-properties',
  SETTINGS_SYSTEM_PROPERTIES = '/settings/system-properties',
  SETTINGS_CONNECTORS = '/settings/connectors',
  SETTINGS_TRIGGERED_AUTOMATIONS = '/settings/triggered-automations',
  REVIEW = '/review',
  MANAGE_DASHBOARD = '/manager-dashboard',
  PLAYBOOKS = '/playbooks',
  PLAYBOOKS_ACTIONS = '/playbooks/actions',
  REPORT = '/report',
  GRAPH = '/graph',
  WIKI = '/wiki',
  WIKI_UUID = '/wiki/:wikiUuid'
}
