/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Typography, TextField } from '@mui/material';

import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';

/** @jsxImportSource @emotion/react */

type JustificationModalProps = {
  onSubmit: (text: string) => void;
  onClose: () => void;
};

export const JustificationModal = (props: JustificationModalProps) => {
  const { onSubmit, onClose } = props;

  return (
    <CydSimpleFormModal
      isOpen={true}
      onClose={onClose}
      onFormSubmit={(e) => {
        e.preventDefault();
        onSubmit(e.target['reason'].value);
      }}
    >
      <>
        <Typography variant="h5">Reason for status change</Typography>
        <TextField
          onKeyDown={(e) => {
            // Allow submit on cmd/ctrl enter
            if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
              // @ts-ignore Yes it exists
              onSubmit(e.target.value);
            }
          }}
          variant="outlined"
          multiline
          name="reason"
          fullWidth
          rows={4}
          autoFocus
        />

        <CydButtonStack justifyContent="flex-end">
          <CydButton variant="contained" type="submit">
            Submit
          </CydButton>
        </CydButtonStack>
      </>
    </CydSimpleFormModal>
  );
};
