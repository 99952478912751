import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    margin: 5,
    padding: 2,
    fontSize: 14
  }
});

export const editActionPopoverStyles = makeStyles({
  actionPopover: {
    width: 'fit-content',
    maxWidth: '500px'
  }
});
