/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  CydSearchableMultiSelect,
  CydSearchableMultiSelectProps
} from 'components/_formElements/CydSearchableMultiSelect/CydSearchableMultiSelect';
import { CaseTag } from 'interface/Tags.interface';
import React from 'react';

export type CydTagMultiSelectorProps = {
  availableTags: Array<CaseTag>;
  /**
   * @deprecated - use selectedValues or defaultValues instead
   */
  selectedTags?: Array<CaseTag>;
  selectedValues?: Array<string>;
  defaultValues?: Array<string>;
  onChange?: (
    selectedTags: Array<CaseTag>,
    newValueStrings: Array<string>
  ) => void;
  label?: string;
  name?: string;
  hotKey?: string;
  size?: CydSearchableMultiSelectProps<unknown>['size'];
  className?: string;
  dontShowClearButton?: boolean;
  unfocusOnEscape?: boolean;
};

export const CydTagMultiSelector = (props: CydTagMultiSelectorProps) => {
  const {
    availableTags,
    selectedValues,
    defaultValues,
    name,
    onChange,
    label = 'Select tags',
    hotKey,
    className,
    size,
    dontShowClearButton,
    unfocusOnEscape
  } = props;

  return (
    <CydSearchableMultiSelect
      dontShowClearButton={dontShowClearButton}
      size={size}
      name={name}
      unfocusOnEscape={unfocusOnEscape}
      className={className}
      availableOptions={availableTags}
      onChange={onChange}
      label={label}
      selectedOptionStrings={selectedValues}
      defaultSelectedOptionStrings={defaultValues}
      generateOptionLabel={(v) => v.value}
      hotKey={hotKey}
      generateValueString={(v) => v.uuid}
    />
  );
};
