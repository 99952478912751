/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { LinearProgress } from '@mui/material';
import { useInterval } from 'hooks/useInterval';
import React, { useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type CydFileProgressProps = {
  fileName: string;

  progressRef: React.MutableRefObject<number | 'error' | 'success'>;
};

export const CydFileProgress = (props: CydFileProgressProps) => {
  const { fileName, progressRef } = props;
  const [progress, setProgress] = useState(0 as number | 'error' | 'success');

  // The notistack doesn't have the ability to respond to content updates
  // So we update it via a ref
  // https://github.com/iamhosseindhv/notistack/issues/234
  useInterval(() => {
    setProgress(progressRef.current);
  }, 1000);

  return (
    <div
      css={css`
        height: 70px;

        p {
          margin: 0.5em;
        }
      `}
    >
      <p>Uploading {fileName}...</p>
      <div
        css={css`
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;
          gap: 1em;
        `}
      >
        {progress === 'success' && (
          <>
            <p>File uploaded successfully.</p>
            <CheckCircleRoundedIcon color="success" fontSize="large" />
          </>
        )}
        {progress === 'error' && (
          <>
            <p>Upload failed.</p>
            <ReportProblemRoundedIcon color="error" fontSize="large" />
          </>
        )}
      </div>
      {typeof progress === 'number' && (
        <LinearProgress
          value={progress}
          variant="determinate"
          css={css`
            margin: 0.5em;
          `}
        />
      )}
    </div>
  );
};
