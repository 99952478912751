/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { FC, memo, useMemo } from 'react';
import { useOrgs } from 'hooks/OrgHooks';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { Org } from 'interface/Org.interface';

interface IOrgSelect2 extends SelectProps {
  dataValue?: keyof Org;
}

export const OrgSelect2: FC<IOrgSelect2> = memo(
  ({ value, dataValue = 'uuid', ...props }) => {
    const { data: orgs } = useOrgs();

    const _value = useMemo(() => value || '', [value]);

    return (
      <Select value={_value} {...props} data-testid="org-select">
        <MenuItem value="">Select Organization</MenuItem>
        {orgs.map((org) => (
          <MenuItem value={org[dataValue]} key={org.uuid}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    );
  }
);
