/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useEffect, useState, forwardRef } from 'react';
import { DefaultLayout } from 'components/layouts/DefaultLayout';
import { useCases } from 'hooks/deprecated_CaseHooks';
import ReviewCaseItem from 'components/ReviewCaseItem/ReviewCaseItem';
import { Grid, Typography } from '@mui/material';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { css } from '@emotion/react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useStyles } from './styles';
import { TimeFormattedCase } from 'components/ReviewCaseItem/interfaces';
import { useAutoFetchEffect } from 'hooks/IntervalHooks';
import { ScrollView } from 'components/ScrollView';
import { SkeletonListWrapper } from 'components/SkeletonLoading/SkeletonListWrapper';
import {
  CydDateRangePicker,
  CydDateRangePickerProps
} from 'components/_formElements/CydDateRangePicker/CydDateRangePicker';
import { CydNoDataToDisplay } from 'components/_placeholders/CydNoDataToDisplay/CydNoDataToDisplay';
/** @jsxImportSource @emotion/react */

/** @jsxImportSource @emotion/react */

const ReviewPage = () => {
  const { data: cases, hasLoaded } = useAutoFetchEffect(useCases(), 'LIST');

  const [filteredCases, setFilteredCases] = useState<TimeFormattedCase[]>([]);

  const { transitionParent, heading, centered } = useStyles();

  const [dateRange, setDateRange] = useState([
    null,
    null
  ] as CydDateRangePickerProps['initialValue']);

  useEffect(() => {
    //If dates aren't there, exit early
    if (!dateRange[0] || !dateRange[1]) {
      return;
    }

    const dateFromUnix = new Date(dateRange[0]).valueOf();
    const dateToUnix = new Date(dateRange[1]).valueOf();

    setFilteredCases(
      cases
        .map((caseData) => ({
          ...caseData,
          createdUnix: new Date(caseData.created).getTime()
        }))
        .filter((caseData) => {
          return (
            caseData.createdUnix >= dateFromUnix &&
            caseData.createdUnix <= dateToUnix
          );
        })
        .sort((a, b) => a.createdUnix - b.createdUnix)
    );
  }, [dateRange, cases]);

  return (
    <DefaultLayout>
      <Grid container item xs={12} className={centered}>
        <Grid container item xs={6}>
          <Typography className={heading} variant="h1">
            Review Cases
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid container item xs={6}>
            <CydDateRangePicker
              initialValue={dateRange}
              makeInitialValueOneMonthAgoToToday
              onComplete={(from, to) => {
                setDateRange([from, to]);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {filteredCases.length === 0 && hasLoaded && (
            <CydNoDataToDisplay
              css={(theme) => `
          margin-top: ${theme.spacing(32)}; 

        `}
            />
          )}
        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <TransitionGroup className={transitionParent}>
            <ScrollView
              skeletonWrapper={
                <SkeletonListWrapper
                  hasLoaded={hasLoaded}
                  skeleton={SkeletonLoad}
                />
              }
            >
              {filteredCases.map((caseData) => (
                <TransitionableReviewItem key={caseData.uuid} {...caseData} />
              ))}
            </ScrollView>
          </TransitionGroup>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

const TransitionableReviewItem = (props) => (
  <CSSTransition timeout={500} classNames="fade">
    <ReviewCaseItem {...props} />
  </CSSTransition>
);

const SkeletonLoad = forwardRef<HTMLDivElement, SkeletonProps>((props, ref) => (
  <Skeleton
    css={css`
      border-radius: 8px;
      width: 100%;
      min-width: 800px;
      height: 163px;
      margin-bottom: 8px;
    `}
    variant="rectangular"
    ref={ref}
  />
));
export default ReviewPage;
