import { usefulStringMatch } from 'utils/StringUtils';
import { PaginatedLoadMoreFunction, RowData, Column } from './CydDataTable';
import { defaultSortFunction } from 'utils/ArrayUtils';

/**
 * This is the implementation of the PaginatedLoadMoreFunction for if pagination mode is sync; ie. all the data is already available
 */
export const createSyncFilterAndSort =
  <T extends RowData>(
    rowsAvailable: Array<T>,
    columns: Array<Column<T>>
  ): PaginatedLoadMoreFunction<T> =>
  async (options) => {
    const {
      pageNum: pageNumber,
      numPerPage: rowsPerPage,
      sortConfig,
      filterConfig
    } = options;

    let newRows: Array<T>;

    if (
      filterConfig &&
      filterConfig.filterKey &&
      filterConfig.filterText.length > 0
    ) {
      newRows = [
        ...rowsAvailable.filter((v) => {
          const rowValue = v[filterConfig.filterKey as keyof T];
          if (typeof rowValue !== 'string') {
            console.warn(
              `Row data with columnFilter key ${
                filterConfig.filterKey
              } do not have type string, they have type: ${typeof rowValue}`
            );
          }

          return usefulStringMatch(
            v[filterConfig.filterKey as keyof T] as string,
            filterConfig.filterText
          );
        })
      ];
    } else {
      newRows = [...rowsAvailable];
    }

    if (sortConfig) {
      // note about debouncing
      // Obviously we don't want this effect to be firing too often (eg as the user
      // types in to the filter textfield)
      // We'll debounce how the textfield updates state, rather than any debouncing logic here

      const sortColumn = columns.find((v) => v.key === sortConfig.sortKey);
      const sortFn = sortColumn?.sortFn;

      if (!sortColumn || !sortFn) {
        throw new Error(
          `Could not find a sortColumn for sortKey: ${sortConfig.sortKey}`
        );
      }

      newRows.sort((a, b) => {
        const sortFunctionToUse =
          sortFn === true ? defaultSortFunction : sortFn;
        const sortResult = sortFunctionToUse(
          a[sortConfig.sortKey],
          b[sortConfig.sortKey]
        );
        // Invert if sort direction is desc
        return sortResult * (sortConfig.sortDirectionIsAsc ? -1 : 1);
      });
    }

    const sortedRows = newRows;

    //FILTERING

    let rowsToReturn: Array<T>;
    if (rowsPerPage === -1) {
      rowsToReturn = sortedRows;
    } else {
      rowsToReturn = sortedRows.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      );
    }

    return {
      data: rowsToReturn,
      paginationInfo: {
        pageNum: pageNumber,
        numPerPage: rowsPerPage,
        totalNumItems: rowsAvailable.length
      }
    };
  };
