/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useEffect, useState } from 'react';

/** @jsxImportSource @emotion/react */
import { CydAppBar } from '../CydAppBar/CydAppBar';
import { CydBasicPanel } from 'components/_panels/CydBasicPanel/CydBasicPanel';
import { DefaultLayoutBanner } from 'components/layouts/DefaultLayout/DefaultLayoutBanner';
import { useRef } from 'react';
import { ClassNames } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { css } from '@emotion/react';
import { debounce } from 'hooks/DebounceHooks';

export type CydPageLayoutFrameProps = {
  isAuthenticated: boolean;
};

/**
 * The purpose of this component is to provide a consistent layout area amongst pages.
 *
 * ie. this component is responsible for positioning headers, footers, sidebars, action areas etc.
 *
 * This frame assumes an authenticated user
 *
 *
 * This is based on my investigation here: https://github.com/dwjohnston/react-layout-approaches
 *
 *
 *
 *
 *
 * Re: Multiple sticky elements - I can't find a nice way to do it, without doing a fixed offset!
 * https://stackoverflow.com/questions/54689034/pure-css-multiple-stacked-position-sticky
 * https://stackoverflow.com/questions/56356423/stack-sticky-element-after-other-sticky-element-with-dynamic-height?noredirect=1&lq=1
 *
 *
 * @param props
 * @returns
 */
export const CydPageLayoutFrame = (
  props: React.PropsWithChildren<CydPageLayoutFrameProps>
) => {
  const { children, isAuthenticated } = props;
  const panelRef = useRef(null);
  const [subNavHeight, setSubNavHeight] = useState(0);

  // This is to observe the sub-nav bar height, as a reference to determine the off set for right hand panel (to stop it from floating)
  useEffect(() => {
    if (!panelRef.current) {
      throw new Error('Cannot observe the size of sub nav');
    }

    const resizeObserver = new ResizeObserver((entries) => {
      // prevent rapid state updates that lead to the loop "ResizeObserver loop completed with undelivered notifications."
      entries.forEach((entry) => {
        // prevent re-rendering if the height is the same
        if (entry.contentRect.height === subNavHeight) {
          return;
        }
        debounce(() => {
          setSubNavHeight(entry.contentRect.height);
        }, 100)();
      });
    });

    resizeObserver.observe(panelRef.current);

    return () => resizeObserver.disconnect();
  }, [panelRef, subNavHeight]);

  // Yeah, definitely a lot of CSS starts looking unreadable.
  return (
    //Outer most div, gives it background color
    <div
      css={(theme) => `
        background-color: ${theme.palette.background.default}; 
      `}
    >
      <div
        css={(theme) => `
                  position: sticky; 
                  top: 0; 
                  display: flex; 
                  justify-content: center; 
                  align-items: stretch; 
                  height: ${theme.other.appBarHeight}; 
                  background-color: ${theme.palette.background.default}; 
                  z-index: 100; 
`}
      >
        {isAuthenticated && <CydAppBar />}
      </div>
      <div
        ref={panelRef}
        id="class-banner"
        css={(theme) => `
          position: sticky; 
          top:  ${theme.other.appBarHeight};
          background-color: ${theme.palette.background.default};
          z-index: 100;
          &:empty {
            display: none;
          }
        `}
      >
        <DefaultLayoutBanner />
        <div
          id="sub-nav"
          css={(theme) => `
          &:empty {
            display: none;
          }
          position: sticky; 
          top:0;

          z-index: 99; 
          background-color: ${theme.palette.background.default};
          border-bottom: solid 2px ${theme.other.primaryTransparent}; 

          //slim the horizontal margins as per: https://cydarm.atlassian.net/browse/RM-2605
          padding: ${theme.spacing(2)} ${theme.spacing(4)} 0; 
        `}
        ></div>
      </div>

      <div
        css={css`
          display: flex;
          flex-flow: row nowrap;
        `}
      >
        <div
          data-testid="left-side-bar"
          id="left-side-bar"
          css={(theme) => `
          &:empty {
            display: none;
          }
          //https://stackoverflow.com/questions/44446671/my-position-sticky-element-isnt-sticky-when-using-flexbox
          align-self: flex-start; 
          flex: 0 0 420px; 
          min-height: calc(100vh - ${theme.other.appBarHeight} - ${subNavHeight}px);      
          position: sticky; 
          top: calc(${theme.other.appBarHeight} + ${subNavHeight}px);
          

          border-right: solid 2px ${theme.other.primaryTransparent}; 
          padding: ${theme.spacing(4)};
          overflow: auto; 
          max-height: calc(100vh - ${
            theme.other.appBarHeight
          } - ${subNavHeight}px - ${theme.spacing(8)} );
        `}
        ></div>

        {/* The purpose of this div is occupy its parent grid area, also scroll bars */}
        <div
          id="scroll-container"
          //https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
          css={(theme) => {
            return `
            flex: 1 1 auto;
            position: relative; 
            padding: ${theme.spacing(4)};
            min-height: 0; 

            // If the right hand panel is empty
            // Change the position
            // Thanks :has selector!
            &:has(+:empty){
              .notistack-SnackbarContainer {
                right: 12px;
              }
            }
            height: calc(100vh - ${theme.other.appBarHeight} - ${subNavHeight}px);
            overflow-y: auto;

            // This is a special class to assign to any content that you want to fill the remaining height of the screen
            // I figure better doing this than some thing where you pass cascading flex boxes down the tree.
            .layout-frame-main-content-100 {
              height: calc(100vh - ${
                theme.other.appBarHeight
              } - ${subNavHeight}px - ${theme.spacing(16)});
            }
          `;
          }}
        >
          {/* We put the Notistack provider here, so that it will account for whether the right hand panel is displaying or not  */}
          <ClassNames>
            {({ css, cx, theme }) => (
              <SnackbarProvider
                classes={{
                  containerAnchorOriginBottomRight: css`
                    // Note the extra class because otherwise you get specificity ordering problems
                    &.notistack-SnackbarContainer {
                      position: fixed;
                      right: 440px;
                    }
                  `,
                  root: css`
                    .notistack-MuiContent-default {
                      background-color: ${theme.palette.background.paper};
                      border-radius: 0.5em;
                    }
                  `
                }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom'
                }}
              >
                {children}
              </SnackbarProvider>
            )}
          </ClassNames>
        </div>

        {/* Right hand panel */}
        <div
          id="right-info-panel"
          css={(theme) => `
            flex: 0 0 348px;
            height: calc(100vh - ${theme.other.appBarHeight} - ${subNavHeight}px);
            overflow: auto;
            display: none;
            
            // &:has(div > div) {
            //   display: block;
            // }
          `}
        >
          <CydBasicPanel
            id="right-info-panel-content"
            showBorder
            css={() => `
              align-self: flex-start;
            `}
          />
        </div>
      </div>
    </div>
  );
};
