/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useActivitiesTimelineFormZoomStyles = makeStyles(
  ({ spacing }) => ({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: spacing(2)
    },
    label: {
      marginRight: spacing(),
      fontSize: 16
    }
  })
);

export const useActivitiesTimelineFormStyles = makeStyles(({ spacing }) => ({
  dataSignificanceLabelWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  dataSignificanceSvg: {
    width: 10,
    height: 10,
    marginRight: 10
  },
  panWrapper: {
    marginTop: spacing(2)
  }
}));
