/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { ICaseSeverity } from 'interface/CaseSeverity.interface';
import React from 'react';
import { determineContrastForHex } from 'utils/ColourUtils';
import { convertLegacySeverityColor } from 'utils/SeverityColorHacks';
/** @jsxImportSource @emotion/react */

export type CydSeveritySelectorProps = {
  availableSeverities: Array<ICaseSeverity>;
  /**
   * @deprecated - use selectedSeverityUuid instead
   */
  selectedSeverity?: null | ICaseSeverity;
  selectedSeverityUuid?: string | null;
  defaultSelectedSeverityUuid?: string | null;
  className?: string;
  name?: string;
  label?: string;
  variant?: 'default' | 'color-small';
  onChange?: (newSeverity: ICaseSeverity, newsSeverityValue: string) => void;
  disabled?: boolean;
};

export const CydSeveritySelector = (props: CydSeveritySelectorProps) => {
  const {
    availableSeverities,
    selectedSeverityUuid,
    selectedSeverity,
    defaultSelectedSeverityUuid,
    onChange,
    name,
    label = 'Select severity',
    variant = 'default',
    disabled = false
  } = props;

  return (
    <>
      <CydSimpleSelect
        css={(theme) => {
          const severityColor = selectedSeverity?.color;

          const convertedSeverityColor =
            convertLegacySeverityColor(severityColor) ||
            theme.palette.text.primary;

          // If the severity color has bad contrast, just make it white
          const colorToUse =
            determineContrastForHex(
              theme.palette.background.default,
              convertedSeverityColor
            ) > 3 // Magic number here - this is the contrast threshold
              ? convertedSeverityColor
              : theme.palette.text.primary;

          return variant === 'color-small'
            ? `
      & .MuiOutlinedInput-root {
          background-color: ${colorToUse}15;
          color: ${colorToUse};
          font-size: 16px;

          &.MuiInputBase-sizeSmall {
            border-radius: 16px;
          }
         }

      & .MuiSvgIcon-root {
        color: ${colorToUse};
      }

      & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${colorToUse}40;
        border-width: 0.75px;
      }

      & .MuiOutlinedInput-notchedOutline {
        border-color: ${colorToUse}40;
      }
      }
      `
            : '';
        }}
        disabled={disabled}
        label={label}
        name={name}
        size={variant === 'default' ? 'medium' : 'small'}
        availableOptions={availableSeverities}
        defaultSelectedString={defaultSelectedSeverityUuid}
        selectedValueString={selectedSeverityUuid}
        selectedOption={selectedSeverity}
        onChange={onChange}
        generateOptionLabel={(v) => {
          return v.name;
        }}
      />
    </>
  );
};
