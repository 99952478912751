/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';

interface IWatchCase {
  caseUuid: string;
  aclUuid: string;
  userUuid: string;
}

export const createWatchCase = createAction<IWatchCase>('watchingCases/watch');
export const createUnWatchCase = createAction<string>('watchingCases/unwatch');
export const createFetchWatchingCases = createAction<string>(
  'watchingCases/fetch'
);
