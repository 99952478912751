/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useEffect, useMemo, useState } from 'react';
import { useDataSignificancesRedux } from 'hooks/CaseDataHooks';
import { generateColor } from 'utils/ColourUtils';

export const useHSLColour = (
  dataSignificanceId,
  saturation = 100,
  luminosity = 50
) => {
  const [hslColor, setHslColor] = useState('');
  const { data: dataSigs } = useDataSignificancesRedux();
  useEffect(() => {
    setHslColor(
      generateColor(dataSigs.length, dataSignificanceId, saturation, luminosity)
    );
  }, [dataSignificanceId, dataSigs.length, saturation, luminosity]);

  return hslColor;
};

export const useHSLColourMap = (
  saturation = 100,
  luminosity = 50
): [
  stringMap: Record<string, string>,
  precedenceMap: Record<number, string>
] => {
  const { data: dataSigs } = useDataSignificancesRedux();

  return useMemo(() => {
    const stringMap = {} as Record<string, string>;
    const precedenceMap = {} as Record<number, string>;

    dataSigs.forEach((v) => {
      const color = generateColor(
        dataSigs.length,
        v.precedence,
        saturation,
        luminosity
      );

      stringMap[v.name] = color;
      precedenceMap[v.precedence!] = color;
    });

    return [stringMap, precedenceMap];
  }, [dataSigs, luminosity, saturation]);
};
