export function createNoop<T>(id: string, returnValue?: T) {
  return (): T => {
    return returnValue as T;
  };
}

export function createUninitialisedFunction(): () => any {
  return function () {
    throw new Error('Uninitialised!');
  };
}
