/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
// Actions
import { CaseTag } from 'interface/Tags.interface';
import { createAction } from '@reduxjs/toolkit';

interface ICreate {
  value: string;
  description: string;
  acl: string;
  resolve?: (a: CaseTag) => void;
}
// Case Tag Actions
export const createFetchCaseTags = createAction('caseTags/fetch');

export const createCaseTag = createAction<ICreate>('caseTags/create');

export const createDeleteCaseTag = createAction<string>('caseTags/delete');

export const createUpdateCaseTag = createAction<CaseTag>('caseTags/update');
