/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConnectorOperationTypeListTopLevel,
  ConnectorOperationTypeRequestTopLevel,
  ConnectorOperationTypeTopLevel,
  ConnectorOperationTypeUpdateRequestTopLevel,
} from '../models/index';
import {
    ConnectorOperationTypeListTopLevelFromJSON,
    ConnectorOperationTypeListTopLevelToJSON,
    ConnectorOperationTypeRequestTopLevelFromJSON,
    ConnectorOperationTypeRequestTopLevelToJSON,
    ConnectorOperationTypeTopLevelFromJSON,
    ConnectorOperationTypeTopLevelToJSON,
    ConnectorOperationTypeUpdateRequestTopLevelFromJSON,
    ConnectorOperationTypeUpdateRequestTopLevelToJSON,
} from '../models/index';

export interface ConnectorOperationTypeGetRequest {
    pageNumber: number;
    pageSize: number;
}

export interface ConnectorOperationTypePostRequest {
    connectorOperationTypeRequestTopLevel?: ConnectorOperationTypeRequestTopLevel;
}

export interface ConnectorOperationTypeUuidDeleteRequest {
    uuid: string;
}

export interface ConnectorOperationTypeUuidGetRequest {
    uuid: string;
}

export interface ConnectorOperationTypeUuidPutRequest {
    uuid: string;
    connectorOperationTypeUpdateRequestTopLevel?: ConnectorOperationTypeUpdateRequestTopLevel;
}

/**
 * 
 */
export class ConnectorOperationTypeApi extends runtime.BaseAPI {

    /**
     * Get all connector operation types
     */
    async connectorOperationTypeGetRaw(requestParameters: ConnectorOperationTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectorOperationTypeListTopLevel>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling connectorOperationTypeGet.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling connectorOperationTypeGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['page[number]'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page[size]'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/connector-operation-type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorOperationTypeListTopLevelFromJSON(jsonValue));
    }

    /**
     * Get all connector operation types
     */
    async connectorOperationTypeGet(requestParameters: ConnectorOperationTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectorOperationTypeListTopLevel> {
        const response = await this.connectorOperationTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new connector operation type
     */
    async connectorOperationTypePostRaw(requestParameters: ConnectorOperationTypePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectorOperationTypeTopLevel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        const response = await this.request({
            path: `/connector-operation-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorOperationTypeRequestTopLevelToJSON(requestParameters.connectorOperationTypeRequestTopLevel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorOperationTypeTopLevelFromJSON(jsonValue));
    }

    /**
     * Create a new connector operation type
     */
    async connectorOperationTypePost(requestParameters: ConnectorOperationTypePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectorOperationTypeTopLevel> {
        const response = await this.connectorOperationTypePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing connector operation type
     */
    async connectorOperationTypeUuidDeleteRaw(requestParameters: ConnectorOperationTypeUuidDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorOperationTypeUuidDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/connector-operation-type/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing connector operation type
     */
    async connectorOperationTypeUuidDelete(requestParameters: ConnectorOperationTypeUuidDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.connectorOperationTypeUuidDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get the specified connector operation type
     */
    async connectorOperationTypeUuidGetRaw(requestParameters: ConnectorOperationTypeUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectorOperationTypeTopLevel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorOperationTypeUuidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/connector-operation-type/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorOperationTypeTopLevelFromJSON(jsonValue));
    }

    /**
     * Get the specified connector operation type
     */
    async connectorOperationTypeUuidGet(requestParameters: ConnectorOperationTypeUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectorOperationTypeTopLevel> {
        const response = await this.connectorOperationTypeUuidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing connector operation type
     */
    async connectorOperationTypeUuidPutRaw(requestParameters: ConnectorOperationTypeUuidPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectorOperationTypeTopLevel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling connectorOperationTypeUuidPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        const response = await this.request({
            path: `/connector-operation-type/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectorOperationTypeUpdateRequestTopLevelToJSON(requestParameters.connectorOperationTypeUpdateRequestTopLevel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorOperationTypeTopLevelFromJSON(jsonValue));
    }

    /**
     * Update an existing connector operation type
     */
    async connectorOperationTypeUuidPut(requestParameters: ConnectorOperationTypeUuidPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectorOperationTypeTopLevel> {
        const response = await this.connectorOperationTypeUuidPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
