/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IAclFormData, IAclAci, Acl } from 'interface/Acl.interface';

export const aclPermissions = [
  'manage',
  'create',
  'read',
  'update',
  'delete',
  'find'
] as const;

export const transformAclFormDataToAci = (aclFormData: IAclFormData) =>
  aclPermissions.reduce(
    (aci, permission) => [
      ...aci,
      ...(aclFormData[permission]
        ? (aclFormData[permission] || []).map(({ name, uuid }) => ({
            permission: { uuid: '', label: permission },
            group: { name, uuid }
          }))
        : [])
    ],
    []
  );

export const mapGroupsToFormattedAci = (aciData, groups) =>
  aclPermissions.reduce(
    (aci, permission) => [
      ...aci,
      ...(aciData[permission]
        ? aciData[permission].map((name) => ({
            permission: { uuid: '', label: permission },
            group: groups.find((group) => group.name === name)
          }))
        : [])
    ],
    []
  );

export const transformAclAciGroup = ({
  aci
}: Acl): { [index: string]: IAclAci[] } =>
  aci &&
  aci.reduce(
    (acc, singleAci) => ({
      ...acc,
      [singleAci.permission.label]: [
        ...(acc[singleAci.permission.label] || []),
        singleAci
      ]
    }),
    {}
  );

export const sortAclAcis = (aci: IAclAci[]) => {
  return aci.sort((data1, data2) =>
    data1.group.name > data2.group.name ? 1 : -1
  );
};
