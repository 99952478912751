/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { User } from 'interface/User.interface';
import { createFetchCaseWatchers } from './actions';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IWatcher {
  [index: string]: User[];
}
export interface IWatchersStoreState {
  isFetching: boolean;
  hasLoaded: boolean;
  watchers?: { [index: string]: User[] };
  errors?: ErrorPayload;
}

const initialState: IWatchersStoreState = {
  watchers: {},
  isFetching: false,
  hasLoaded: false,
  errors: undefined
};

const watcherSlice = createSlice({
  name: 'watchers',
  initialState,
  reducers: {
    fetchCaseWatchersFailed: (
      state,
      { payload }: PayloadAction<ErrorPayload>
    ) => ({ ...state, isFetching: false, errors: payload }),

    fetchCaseWatchersSuccess: (state, { payload }: PayloadAction<IWatcher>) => {
      state.isFetching = false;
      state.hasLoaded = true;
      state.errors = undefined;
      state.watchers = { ...state.watchers, ...payload };
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchCaseWatchers, (state) => ({
      ...state,
      isFetching: true,
      errors: undefined
    }))
});

export const {
  reducer: watcherStore,
  actions: { fetchCaseWatchersFailed, fetchCaseWatchersSuccess }
} = watcherSlice;
