import * as CydarmFetch from 'utils/CydarmFetch';

// groupMembers
export async function apiFetchGroupMembers(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/group/${uuid}/user`, {
    method: 'GET'
  });
}

export async function apiFetchRecursiveGroupMembers(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(
    `/group/${uuid}/user?recursive=true`,
    {
      method: 'GET'
    }
  );
}

export async function apiAddGroupMember(groupUuid, memberUuid) {
  return CydarmFetch.cyFetchAuthenticated(
    `/group/${groupUuid}/user/${memberUuid}`,
    {
      method: 'POST'
    }
  );
}

export async function apiRemoveGroupMember(groupUuid, memberUuid) {
  return CydarmFetch.cyFetchAuthenticated(
    `/group/${groupUuid}/user/${memberUuid}`,
    {
      method: 'DELETE'
    }
  );
}

export async function apiFetchChildGroups(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/group/${uuid}/group`, {
    method: 'GET'
  });
}

export async function apiAddGroupChild(groupUuid, childGroupUuid) {
  return CydarmFetch.cyFetchAuthenticated(
    `/group/${groupUuid}/group/${childGroupUuid}`,
    {
      method: 'POST'
    }
  );
}

export async function apiRemoveGroupChild(groupUuid, childGroupUuid) {
  return CydarmFetch.cyFetchAuthenticated(
    `/group/${groupUuid}/group/${childGroupUuid}`,
    {
      method: 'DELETE'
    }
  );
}

// groups
export async function apiFetchGroups() {
  return CydarmFetch.cyFetchAuthenticated('/group', {
    method: 'GET'
  });
}

export async function apiCreateGroup(groupData: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/group', {
    method: 'POST',
    body: JSON.stringify(groupData)
  });
}

export async function apiDeleteGroup(groupUuid) {
  return CydarmFetch.cyFetchAuthenticated(`/group/${groupUuid}`, {
    method: 'DELETE'
  });
}
