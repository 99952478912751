import { AclsSettingsPage } from 'pages/AclsSettingsPage';
import { AssetSettingsPage } from 'pages/AssetSettingsPage';
import { ContactsPage } from 'pages/ContactsPage';
import { FormSettingsPage } from 'pages/FormSettingsPage';
import { GroupsSettingsPage } from 'pages/GroupsSettingsPage';
import { MetaSettingsPage } from 'pages/MetaSettingsPage';
import { OrganisationSettingsPage } from 'pages/OrganisationSettingsPage';
import { SingleGroupSettingsPage } from 'pages/SingleGroupSettingsPage';
import { SingleOrganisationSettingsPage } from 'pages/SingleOrganisationSettingsPage';
import { SystemPropertiesPage } from 'pages/SystemPropertiesPage';
import { TagsSettingsPage } from 'pages/TagsSettingsPage';
import { UserManagementPage } from 'pages/UserManagementPage';
import { UserPropertiesPage } from 'pages/UserPropertiesPage';
import { UserSettingsPage } from 'pages/UserSettingsPage';
import { FC, LazyExoticComponent } from 'react';
import { CydarmRoute } from 'interface/CydarmRoute';
import { POSSIBLE_FEATURE_FLAGS } from 'featureFlags';
import { ConnectorSettingsPage } from 'pages/ConnectorSettingsPage';
import { TriggeredAutomationsSettingsPage } from 'pages/TriggeredAutomationsSettingsPage';

interface ISettingsPageRoutesObject {
  url: string;
  label?: string;
  // Should reconsider if it's worth lazy loading components.
  // Since we're an app it's more acceptable to have a large initial load,
  // but less acceptable to have lots of mini-loads during actions such as navigation.
  // And lazy loading accomplishes the opposite
  component: LazyExoticComponent<FC>;

  featureFlag?: POSSIBLE_FEATURE_FLAGS;
}

//if provided a label it will appear in the sidebar
export const SettingsPageRoutes: ISettingsPageRoutesObject[] = [
  {
    url: CydarmRoute.SETTINGS_PROFILE,
    label: 'My Profile',
    component: UserSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_USER_DETAIL,
    component: UserSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_USERS,
    label: 'Users',
    component: UserManagementPage
  },
  {
    url: CydarmRoute.SETTINGS_GROUPS,
    label: 'Groups',
    component: GroupsSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_GROUP_DETAIL,
    component: SingleGroupSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_ORGS,
    label: 'Organizations',
    component: OrganisationSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_ORG_DETAIL,
    component: SingleOrganisationSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_CONTACTS,
    label: 'Contacts',
    component: ContactsPage
  },
  {
    url: CydarmRoute.SETTINGS_ASSETS,
    label: 'Assets',
    component: AssetSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_FORMS,
    label: 'Forms',
    component: FormSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_TAGS,
    label: 'Tags',
    component: TagsSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_ACLS,
    label: 'ACLs',
    component: AclsSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_METADATA,
    label: 'Metadata',
    component: MetaSettingsPage
  },
  {
    url: CydarmRoute.SETTINGS_USER_PROPERTIES,
    label: 'User Properties',
    component: UserPropertiesPage
  },
  {
    url: CydarmRoute.SETTINGS_SYSTEM_PROPERTIES,
    label: 'System Properties',
    component: SystemPropertiesPage
  },
  {
    url: CydarmRoute.SETTINGS_CONNECTORS,
    label: 'Connectors',
    component: ConnectorSettingsPage,
    featureFlag: 'REACT_APP_USE_CONNECTOR_SETTINGS_PAGE'
  },
  {
    url: CydarmRoute.SETTINGS_TRIGGERED_AUTOMATIONS,
    label: 'Triggered Automations',
    component: TriggeredAutomationsSettingsPage,
    featureFlag: 'REACT_APP_USE_CONNECTOR_SETTINGS_PAGE'
  }
];
