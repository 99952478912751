/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { memo } from 'react';
import StackedInfo from 'components/StackedInfo/StackedInfo';
import Chip from '@mui/material/Chip';
import { css } from '@emotion/react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useSeverityStates } from 'hooks/CaseSeverityHooks';
import { CydUserDisplayByUsername } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplayByUsername';
import { CydTimeDisplay } from 'components/_objectDisplay/CydTimeDisplay/CydTimeDisplay';

/** @jsxImportSource @emotion/react */

interface ItemContentsProps {
  currentTags: string[];
  currentAcl: any;
  severity: number | null;
  assignee?: string;
  created: string;
  modified: string;
  status: string;
}

const ItemContents: React.FC<ItemContentsProps> = memo(
  ({
    currentTags,
    status,
    severity,
    assignee,
    currentAcl,
    created,
    modified
  }) => {
    const { data: allSeverity } = useSeverityStates();

    const selectedSeverity =
      allSeverity && allSeverity.find((el) => el.precedence === severity);

    const selectedSeverityName =
      (selectedSeverity && selectedSeverity.name) || undefined;

    const createdFormatted = <CydTimeDisplay time={created} />;
    const modifiedFormatted = <CydTimeDisplay time={modified} />;

    const info = [
      { header: 'Status', info: status },
      { header: 'Severity', info: selectedSeverityName },
      {
        header: 'Assignee',
        info: <CydUserDisplayByUsername username={assignee} />
      },
      {
        header: 'ACL',
        info: currentAcl.description
      },
      { header: 'Created', info: createdFormatted },
      { header: 'Modified', info: modifiedFormatted }
    ];

    return (
      <AccordionDetails
        css={css`
          flex-wrap: wrap;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 1 100%;
            margin: spacing(1, 0, 4, 0);
          `}
        >
          {info.map((details) => (
            <StackedInfo
              key={details.header}
              header={details.header}
              info={details.info}
            />
          ))}
        </div>

        <div
          css={(theme) => `
            width: 100%;
            margin-bottom: -10px;
            & .MuiChip-root {
              color: ${theme.palette.background.default};
              background-color: ${theme.palette.text.primary};
            }
            & .MuiChip-root:not(:last-of-type) {
              margin-right: 10px;
            }
        `}
        >
          {currentTags.map((tag) => (
            <Chip
              css={(theme) => `
                margin-top: ${theme.spacing(1)};
              `}
              label={tag}
              key={tag}
            />
          ))}
        </div>
      </AccordionDetails>
    );
  }
);

export default ItemContents;
