/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import moment from 'moment';
import { dateFormatLocale } from './DateUtils';

export const formatString = (dataIn) => {
  dataIn = dataIn.replace(/-|_/g, ' ');
  dataIn = dataIn.split(' ');
  if (dataIn.length > 0) {
    for (let i = 0, x = dataIn.length; i < x; i++) {
      dataIn[i] = dataIn[i][0].toUpperCase() + dataIn[i].substr(1);
    }
  }

  return dataIn.join(' ');
};
export const isValidJSON = (dataIn) => {
  try {
    JSON.parse(dataIn);
  } catch (e) {
    return false;
  }
  return true;
};

export const dataExists = (dataIn) => {
  if (dataIn instanceof Array) {
    return dataIn.some(
      ({ property }) => property === undefined || property === null
    );
  } else if (dataIn === undefined || dataIn === null || dataIn === '') {
    return false;
  }
  return true;
};

export const defaultSTIX2_1 = (dataIn) => {
  const json = JSON.parse(dataIn);
  const objects = json.objects;
  const observedData = json.objects[getIndex(objects, 'observed-data')];
  const optionalProperties = objects.find(
    (o) => o.id === observedData.object_refs[0]
  );
  const relationship = objects.find(
    (o) => o.type === 'relationship' && o.target_ref === observedData.id
  );
  const indicator = objects.find(
    (o) => o.type === 'indicator' && o.id === relationship.source_ref
  );
  const object_refs = observedData.object_refs;
  const network_trafficIndex = getIndex(objects, 'network-traffic');
  const network_traffic = objects[network_trafficIndex].type;
  const searchTerm = optionalProperties.type;
  const title = '### STIX 2.1 Fragment: ' + formatString(searchTerm) + '\n';
  const searchKeys = [
    'first_observed',
    'last_observed',
    'number_observed',
    'confidence'
  ];
  let markdown = title;
  markdown += getSearchKey(observedData, searchKeys, false);
  if (network_traffic === 'network-traffic' && dataExists(object_refs)) {
    markdown += networkTraffic(objects, object_refs);
    return markdown;
  } else {
    markdown += grabSTIXIoC(
      observedData,
      optionalProperties,
      indicator,
      searchTerm
    );
  }
  // default case
  return markdown;
};

const grabSTIXIoC = (
  commonProperties,
  optionalProperties,
  indicator,
  searchTerm
) => {
  let markdown = '';
  if (searchTerm) {
    markdown +=
      '\n' +
      genericParser(
        commonProperties,
        optionalProperties,
        indicator,
        searchTerm
      );
  }
  return markdown;
};

const getSearchKey = (dataIn, searchKeys, backquote) => {
  let markdown = '';
  let bq = '';
  if (backquote) {
    bq = '`';
  }
  for (let i = 0; i < Object.keys(dataIn).length; i++) {
    for (let j = 0; j < Object.keys(searchKeys).length; j++) {
      if (Object.keys(dataIn)[i] === searchKeys[j]) {
        if (searchKeys[j] === 'payload_bin') {
          markdown +=
            '\n**' +
            formatString(searchKeys[j]) +
            '**:  ***Download STIX bundle for binary data.*** \n';
        } else {
          markdown +=
            '\n **' +
            formatString(searchKeys[j]) +
            '**: ' +
            bq +
            formatValue(Object.values(dataIn)[i]) +
            bq +
            '\n';
        }
      }
    }
  }
  return markdown;
};

const formatValue = (dataIn) => {
  if (typeof dataIn === 'number') {
    return dataIn;
  }
  if (moment(dataIn, moment.ISO_8601).isValid()) {
    const date = moment(dataIn).format(dateFormatLocale);
    return date;
  }
  return dataIn;
};

export const getIndex = (objects, searchTerm) => {
  let index = 0;
  objects.forEach((object) => {
    if (searchTerm === 'optionalProperties' && objects.length >= 1) {
      index = objects.lastIndexOf(object);
    } else if (searchTerm === 'network-traffic') {
      index = objects.indexOf(object);
    } else if (object.type === 'network-traffic' || 'observed-data') {
      Object.keys(object).forEach(function (k) {
        if (k === searchTerm) {
          index = objects.indexOf(object);
        }
      });
    } else if (searchTerm === 'type') {
      index = objects.lastIndexOf(object);
    }
  });
  return index;
};

const networkTraffic = (objects, objectRefs) => {
  let markdown = '';
  const src_refIndex = getIndex(objects, 'src_ref');
  const dst_refIndex = getIndex(objects, 'dst_ref');
  const dst_portIndex = getIndex(objects, 'dst_port');
  const protocolsIndex = getIndex(objects, 'protocols');
  const protocols = objects[protocolsIndex].protocols;

  if (objects[dst_portIndex].dst_port) {
    markdown +=
      '\n**Destination Port:** ' + objects[dst_refIndex].dst_port + '\n';
  }
  if (protocols) {
    markdown += '\n**Protocols:**\n';
    protocols.forEach((protocol) => {
      markdown += '\n* ' + protocol.toUpperCase() + '\n';
    });
  }
  for (let i = 1; i < objectRefs.length; i++) {
    if (objects[i].id === objects[src_refIndex].src_ref) {
      markdown += '\n\n **Source** \n';
      if (objects[i].type === 'domain-name') {
        markdown += '\n * **Domain Name**: ' + objects[i].value + ' \n ';
      } else if (objects[i].type === 'ipv4-addr') {
        markdown += '\n * **IPV4 Address**: ' + objects[i].value + ' \n ';
      }
    }
    if (objects[i].id === objects[dst_refIndex].dst_ref) {
      markdown += '\n\n **Destination** \n';
      if (objects[i].type === 'domain-name') {
        markdown += '\n * **Domain Name**: ' + objects[i].value + ' \n ';
      } else if (objects[i].type === 'ipv4-addr') {
        markdown += '\n * **IPV4 Address**: ' + objects[i].value + ' \n ';
      }
    }
  }
  return markdown;
};

const genericParser = (
  commonProperties,
  optionalProperties,
  indicator,
  searchTerm: string
) => {
  let markdown = '';
  switch (searchTerm) {
    case 'artifact': {
      markdown += getIndicatorTypes(indicator);
      markdown += getSearchKey(optionalProperties, ['mime_type'], false);
      markdown += getSearchKey(optionalProperties, ['payload_bin'], true);

      return markdown;
    }
    case 'Autonomous System':
      return '';
    case 'Directory':
      return '';
    case 'domain-name': {
      markdown += getIndicatorTypes(indicator);
      markdown += getDefangedSetting(optionalProperties, false);
      markdown += getSearchKey(optionalProperties, ['value'], true);
      return markdown;
    }
    case 'email-addr': {
      markdown += getIndicatorTypes(indicator);
      markdown += getDefangedSetting(optionalProperties, false);
      markdown += getSearchKey(
        optionalProperties,
        ['value', 'display_name'],
        true
      );

      return markdown;
    }
    case 'Email Message':
      return '';
    case 'file': {
      if (dataExists(optionalProperties.hashes)) {
        for (let [key, value] of Object.entries(optionalProperties.hashes)) {
          markdown += '\n **' + key + ':** \n\n * `' + value + '`\n\n';
        }
      }
      markdown += getSearchKey(optionalProperties, ['name'], true);
      markdown += getSearchKey(
        optionalProperties,
        ['created', 'modified', 'accessed', 'mime_type', 'name_enc'],
        false
      );
      markdown += getIndicatorTypes(indicator);

      return markdown;
    }
    case 'ipv4-addr': {
      markdown += getIndicatorTypes(indicator);
      markdown += getDefangedSetting(optionalProperties, false);
      markdown += getSearchKey(optionalProperties, ['value'], true);
      return markdown;
    }
    case 'IPV6 Address':
      return '';
    case 'MAC Address':
      return '';
    case 'Mutex':
      return '';
    case 'Process':
      return '';
    case 'Software':
      return '';
    case 'url': {
      markdown += getIndicatorTypes(indicator);
      markdown += getDefangedSetting(optionalProperties, false);
      markdown += getSearchKey(optionalProperties, ['value'], true);
      return markdown;
    }
    case 'User Account':
      return '';
    case 'Windows Registry Key':
      return '';
    case 'X.509 Certificate':
      return '';
    default: {
      return '';
    }
  }
};

const getDefangedSetting = (dataIn, backquote) => {
  let markdown = getSearchKey(dataIn, ['is_defanged'], false);
  if (markdown === '') {
    markdown = getSearchKey(dataIn, ['defanged'], false);
  }
  return markdown;
};

const getIndicatorTypes = (indicator) => {
  let markdown = '';
  if (dataExists(indicator)) {
    markdown += '\n **Indicator Types:**';
    indicator.indicator_types.forEach((label) => {
      markdown += '\n * ' + label + '\n\n';
    });
  }
  return markdown;
};
