/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Zoom from 'd3-zoom';
import * as d3Axis from 'd3-axis';
import { css } from '@emotion/react';
import { useHSLColourMap } from 'hooks/HSLColourHooks';
import { parseActivityTimelineData } from 'utils/ActivityTimelineUtils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TimelineCaptionsWrapper } from './TimelineCaptionsWrapper';
import { useDispatch } from 'react-redux';
import { openTimelineCaption } from 'states/activityTimeline/slice';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { ICaseStix } from 'interface/Case.interface';

/** @jsxImportSource @emotion/react */

export type ActivitiesTimelineProps = {
  activities: any[];
  dataSignificancesToShow: DataSignificance[];
  handleDateChange: (dates: [string, string]) => void;
  stixData: ICaseStix[];
  domain: [string, string];
  caseAcl: string;
};

export const ActivitiesTimeline = (props: ActivitiesTimelineProps) => {
  const {
    activities,
    dataSignificancesToShow,
    handleDateChange,
    stixData,
    domain: [startDate, endDate],
    caseAcl
  } = props;

  const precedenceList = useMemo(() => {
    return dataSignificancesToShow.map(({ precedence }) => precedence);
  }, [dataSignificancesToShow]);

  const dispatch = useDispatch();
  const colorMap = useHSLColourMap(85, 58);
  const svgRef = useRef<any>();
  const dataContainer = useRef({ activities, stixData });
  const lastEventTransform = useRef();
  const lastDomain = useRef<Date[]>();
  const zoomToDomainFunction = useRef<any>();
  const [svgWidth, setSvgWidth] = useState(0);

  useEffect(() => {
    let newDomain = [startDate, endDate];
    if (
      `${newDomain}` !== `${lastDomain.current}` &&
      zoomToDomainFunction.current
    ) {
      zoomToDomainFunction.current(newDomain);
    }
  }, [startDate, endDate, lastDomain, zoomToDomainFunction]);

  useEffect(() => {
    dataContainer.current = { activities, stixData };
  }, [activities, stixData]);

  useEffect(() => {
    const { activities, stixData } = dataContainer.current;
    let { parsedData, minX, maxX, scaleExtent } = parseActivityTimelineData(
      activities,
      stixData
    );

    const margin = {
      top: 10,
      right: 0,
      bottom: 40,
      left: 0
    };

    const svg = d3.select(svgRef.current);

    const getNewXScale = (event, xScale) => {
      const t = event?.transform || lastEventTransform.current;
      if (!t) {
        return [undefined, undefined];
      }
      return [t.rescaleX(xScale), t];
    };

    function draw(): any {
      const width = +svg.attr('width');
      const height = +svg.attr('height') - margin.bottom;
      svg.html('');
      // create a clipping region
      svg
        .append('defs')
        .append('clipPath')
        .attr('id', 'clip')
        .append('rect')
        .attr('width', width)
        .attr('height', height);

      // create scale objects
      const xScale = d3Scale
        .scaleTime()
        .domain([minX, maxX])
        .rangeRound([0, width]);
      const yScale = d3Scale
        .scaleLinear()
        .domain([0, Math.max(...precedenceList) + 1]) // max precedence + jitter
        .range([height, 0]);
      // create axis objects
      const xAxis = d3Axis.axisBottom(xScale).ticks(width / 100);
      // Draw Axis
      const gX = svg
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top + height})`)
        .call(xAxis);

      // Draw Datapoints
      const points_g = svg
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)
        .attr('clip-path', 'url(#clip)')
        .classed('points_g', true);

      const points = points_g
        .selectAll('circle')
        .data(parsedData)
        .enter()
        .append('circle')
        .attr('cx', (d) => xScale(d.created))
        .attr('cy', (d) => yScale(d.positionY))
        .attr('r', 5)
        .attr('fill', (d) => colorMap[1][d.sigprecedence])
        .attr('class', (d) => `dataSig-${d.sigprecedence}`);
      points.on('click', function (event, data) {
        const elements = points.nodes();
        const index = elements.indexOf(event.currentTarget);
        const node = elements[index];
        dispatch(openTimelineCaption({ data, node }));
      });

      const zoomed = (event) => {
        const [new_xScale, t] = getNewXScale(event, xScale);
        if (!new_xScale) {
          return;
        }
        gX.call(xAxis.scale(new_xScale));
        points.data(parsedData).attr('cx', (d) => new_xScale(d.created));
        lastEventTransform.current = t;
      };

      const zoomEnd = (event) => {
        const [new_xScale] = getNewXScale(event, xScale);
        if (!new_xScale) {
          return;
        }
        const { parsedData: reparsedData } = parseActivityTimelineData(
          activities,
          stixData,
          new_xScale.domain()
        );
        parsedData = reparsedData;
        points
          .data(parsedData)
          .transition()
          .attr('cy', (d) => yScale(d.positionY));

        lastDomain.current = new_xScale.domain();
        handleDateChange(new_xScale.domain());
      };

      // Pan and zoom
      const zoom = d3Zoom
        .zoom()
        .scaleExtent(scaleExtent)
        .translateExtent([
          [-Infinity, 0],
          [Infinity, height]
        ])
        .extent([
          [0, 0],
          [width, height]
        ])
        .on('zoom', zoomed)
        .on('end', zoomEnd);

      svg
        .append('rect')
        .attr('width', width)
        .attr('height', height)
        .style('fill', 'none')
        .style('pointer-events', 'all')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)
        .lower();
      svg.call(zoom);

      zoomed(null);
      zoomEnd(null);

      handleDateChange([
        xScale.domain()[0].toString(),
        xScale.domain()[1].toString()
      ]);
      lastDomain.current = xScale.domain();
      zoomToDomainFunction.current = (domain) => {
        svg
          .transition()
          .call(
            zoom.transform,
            d3Zoom.zoomIdentity
              .scale(width / (xScale(domain[1]) - xScale(domain[0])))
              .translate(-xScale(domain[0]), 0)
          );
      };
    }

    const updateWindow = () => {
      let { width, height } = svg.node().getBoundingClientRect();
      svg.attr('width', width);
      svg.attr('height', height);
      setSvgWidth(width);
      draw();
    };

    updateWindow();

    d3.select(window).on('resize.updatesvg', updateWindow);
  }, [
    activities.length,
    stixData.length,
    colorMap,
    handleDateChange,
    dispatch,
    precedenceList
  ]);

  return (
    <div
      css={css`
        display: inline-block;
        position: relative;
        width: 100%;
        height: 100%;
        vertical-align: top;

        ${precedenceList?.map(
          (significance) => css`
            & .dataSig-${significance} {
              opacity: 1;
            }
          `
        )}

        & circle {
          opacity: 0;
          transition: opacity 0.3s ease;
          cursor: pointer;
        }
      `}
    >
      <TimelineCaptionsWrapper svgWidth={svgWidth} caseAcl={caseAcl} />
      <svg
        className="cyd-case-view-timeline"
        css={(theme) => {
          const { palette } = theme;
          return `
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              & .domain {
                stroke: ${palette.text.primary};
              }
              & .tick {
                line {
                  stroke: ${palette.text.primary};
                }
                text {
                  fill: ${palette.text.primary};
                }
              }
              & circle.selected {
                fill: ${palette.text.primary};
              }
            `;
        }}
        ref={svgRef}
      />
    </div>
  );
};
