/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { TextField } from '@mui/material';
import { CydDateTimePicker } from 'components/_formElements/CydDateTimePicker/CydDateTimePicker';
import { DatePicker } from 'components/_formElements/DatePickers';

export const DatetimeFields = ({ type, onDateChange, ...props }) => {
  switch (type) {
    case 'date':
      return <DatePicker {...props} />;
    case 'datetime':
      return (
        <CydDateTimePicker
          initialValue={null}
          onComplete={onDateChange}
          makeInitialTimeBeCurrentTime={true}
          {...props}
        />
      );
    case 'time':
      return <TextField type="time" {...props}></TextField>;
    default:
      return null;
  }
};
