export interface IContactStoreState {
  results: Contact[];
  isLoading: boolean;
  hasLoaded: boolean;
}

export type Contact = {
  acl: string;
  data: ContactData;
  deletable: boolean;
  editable: boolean;
  manageable: boolean;
  readable: boolean;
  uuid: string;
};

export type ContactData = {
  contactType: ContactType;
  name: string;
  deprecated: boolean;
  email: string[];
  organisation?: string;
  phone: string[];
  orgUuid?: string;
};

export interface IContactResponse {
  acl: string;
  data: IContactResponseData;
  deletable: boolean;
  editable: boolean;
  manageable: boolean;
  readable: boolean;
  uuid: string;
}

export interface UpdateContactSuccessPayload extends IContactForm {
  uuid: string;
}

export interface IContactResponseData {
  contact_type: ContactType;
  name: string;
  deprecated: boolean;
  email?: string[];
  organisation?: string;
  phone?: string[];
  org_uuid?: string;
}

export interface IContactForm {
  contactType: ContactType;
  organisation?: string;
  orgUuid?: string;
  name: string;
  phone: string;
  email: string;
  acl: string;
}

export interface IContactCreate {
  phone: string[];
  email: string[];
}

export interface IContactUpdate extends IContactForm {
  uuid: string;
  resolve?: () => void;
}

export interface IContactBulkCreate {
  file: File;
  aclName: string;
}

export interface IUpdateContactAction {
  type: string;
  payload: IContactUpdate;
}

export enum ContactType {
  ORGANIZATION = 'organization',
  INDIVIDUAL = 'individual'
}
