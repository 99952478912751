/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IAssetForm } from 'interface/Assets.interface';
import {
  put,
  takeEvery,
  takeLatest,
  throttle,
  select
} from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import {
  fetchAssets,
  fetchAssetsFailed,
  fetchAssetsSuccess,
  updateAssetSuccess
} from './slice';
import {
  createAssetAction,
  createBulkAssetsAction,
  createDeleteAssetAction,
  createUpdateAssetAction
} from './actions';
import { splitStringToArray } from 'utils/StringUtils';
import { currentUserSelector } from 'states/auth/selectors';
import {
  apiCreateAsset,
  apiCreateBulkAssets,
  apiDeleteAsset,
  apiFetchAssets,
  apiUpdateAsset
} from 'services/AssetsService';

function* performFetchAssets(action) {
  try {
    const { json: results } = yield apiFetchAssets();
    yield put(fetchAssetsSuccess(results));
  } catch (ex) {
    yield put(fetchAssetsFailed());
    yield put(addNotification({ message: 'Unable to fetch assets' }));
  }
}

function* performCreateAsset({ payload }: { payload: IAssetForm }) {
  try {
    yield apiCreateAsset({
      ...payload,
      infrastructureTypes: splitStringToArray(payload.infrastructureTypes),
      ipAddresses: splitStringToArray(payload.ipAddresses)
    });
    yield put(
      addNotification({
        message: `Asset name ${payload.name} has been created`
      })
    );
    yield put(fetchAssets());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to create new asset' }));
  }
}

function* performUpdateAsset({ payload }) {
  const currentUser = yield select(currentUserSelector);

  const { resolve, ...assetData } = payload;

  try {
    if (!currentUser?.uuid) {
      return;
    }

    const updateAssetData = {
      ...assetData,
      infrastructureTypes: splitStringToArray(payload.infrastructureTypes),
      ipAddresses: splitStringToArray(assetData.ipAddresses),
      userUuid: currentUser?.uuid
    };

    yield apiUpdateAsset(updateAssetData);

    yield put(
      addNotification({
        message: `Asset ${assetData.name} has been updated`
      })
    );
    yield put(updateAssetSuccess(assetData));
    resolve && resolve();
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to update asset: ${assetData.name}`
      })
    );
  }
}

function* performDeleteAsset(action) {
  const { payload: uuid } = action;

  try {
    yield apiDeleteAsset(uuid);
    yield put(addNotification({ message: `Asset ${uuid} has been deleted` }));
    yield put(fetchAssets());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to delete asset' }));
  }
}

function* performCreateBulkAssets(action) {
  const { file, aclName } = action.payload;
  const formData = new FormData();
  formData.append('upload', file);
  formData.append('aclName', aclName);

  try {
    yield apiCreateBulkAssets(formData);
    yield put(addNotification({ message: 'Successfully uploaded assets' }));
    yield put(fetchAssets());
  } catch (ex) {
    if (ex.status === 400) {
      const data = yield ex.json() || [];
      const notificationText =
        data?.failedRequests
          ?.map((el) => el.error || '')
          .join(', ')
          .trim() || 'Failed to upload CSV';
      yield put(addNotification({ message: notificationText }));
    } else {
      yield put(addNotification({ message: 'Failed to upload CSV' }));
    }
  }
}

/* Watchers */
function* watchAssetsSagas() {
  yield throttle(10, fetchAssets, performFetchAssets);
  yield takeEvery(createAssetAction, performCreateAsset);
  yield takeEvery(createUpdateAssetAction, performUpdateAsset);
  yield takeLatest(createDeleteAssetAction, performDeleteAsset);
  yield takeLatest(createBulkAssetsAction, performCreateBulkAssets);
}

export default [watchAssetsSagas()];
