/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import {
  determineLanguageFromMimeTypeOrFileName,
  useDisplayAsJsonTable
} from 'hooks/CaseContentHooks';
import React, { ReactElement } from 'react';
import { useCaseActivityStyles } from '../styles';
// import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

export const CaseActivityContentJsonTable = ({ caseActivity }) => {
  const { htmlString, content, mimetype, filename } = caseActivity;

  const { jsonTable } = useCaseActivityStyles({});
  // const jsonTable = coldarkDark;

  // convery an array to a MUI table, with one row per element
  const convertArrayToTable = (arr: Array<any>): React.ReactElement => {
    let rows: ReactElement[] = [];
    arr.forEach((value, index) => {
      if (typeof value === 'object') {
        rows.push(
          <TableRow className={jsonTable} key={index}>
            <TableCell className={jsonTable}>
              {convertObjectToTable(value, false)}
            </TableCell>
          </TableRow>
        );
      } else if (Array.isArray(value)) {
        rows.push(
          <TableRow className={jsonTable} key={index}>
            <TableCell className={jsonTable}>
              {convertArrayToTable(value)}
            </TableCell>
          </TableRow>
        );
      } else {
        rows.push(
          <TableRow className={jsonTable} key={index}>
            <TableCell className={jsonTable}>{value}</TableCell>
          </TableRow>
        );
      }
    });
    return (
      <Table className={jsonTable}>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };

  // convert an object to a MUI table, with one row per key-value pair
  const convertObjectToTable = (
    obj: object,
    topLevel: boolean
  ): React.ReactElement => {
    let rows: ReactElement[] = [];
    Object.entries(obj).forEach(([key, value]) => {
      if (value && Array.isArray(value)) {
        rows.push(
          <TableRow className={jsonTable} key={key}>
            <TableCell className={jsonTable}>{key}</TableCell>
            <TableCell className={jsonTable}>
              {convertArrayToTable(value)}
            </TableCell>
          </TableRow>
        );
      } else if (value && typeof value === 'object') {
        rows.push(
          <TableRow className={jsonTable} key={key}>
            <TableCell className={jsonTable}>{key}</TableCell>
            <TableCell className={jsonTable}>
              {convertObjectToTable(value, false)}
            </TableCell>
          </TableRow>
        );
      } else {
        rows.push(
          <TableRow className={jsonTable} key={key}>
            <TableCell className={jsonTable}>{key}</TableCell>
            <TableCell className={jsonTable}>{String(value)}</TableCell>
          </TableRow>
        );
      }
    });
    return (
      <Table className={jsonTable}>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };

  const language = determineLanguageFromMimeTypeOrFileName(mimetype, filename);
  const displayJsonAsTable = useDisplayAsJsonTable(content);

  return language === 'application/json' &&
    !htmlString &&
    displayJsonAsTable ? (
    <TableContainer
      className={jsonTable}
      component={Paper}
      style={{ maxWidth: '100%' }}
    >
      {convertObjectToTable(JSON.parse(content), true)}
    </TableContainer>
  ) : null;
};
