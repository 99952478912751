/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Avatar } from '@mui/material';
import { User } from 'interface/User.interface';
import { getUserInitials } from 'utils/UserUtils';

/** @jsxImportSource @emotion/react */

export type CydUserDisplayProps = {
  user: User;

  variant?: 'default' | 'avatar' | 'bold';
  className?: string;
};

export const CydUserDisplay = (props: CydUserDisplayProps) => {
  const { variant = 'default', user, className } = props;

  if (!user) {
    throw new Error('No user');
  }

  const { displayName } = user;

  if (!displayName) {
    throw new Error('No display name found');
  }

  if (variant === 'avatar') {
    return (
      <Avatar
        className={className}
        css={(theme) => `
        background-color: ${theme.other.colorAvatar}; 
        color: ${theme.palette.text.primary}; 
        width: 24px;  
        height: 24px;
        font-size:12px;
`}
      >
        {getUserInitials(user)}
      </Avatar>
    );
  }

  // This variant might actually be clickable, etc
  if (variant === 'bold') {
    return (
      <div
        className={className}
        css={(theme) => `
      font-weight: bold; 
    `}
      >
        {displayName}
      </div>
    );
  }
  return <div className={className}>{displayName}</div>;
};
