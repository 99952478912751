/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

//this file should probably be refactored/split out, it's 'yuge
import * as CydarmFetch from 'utils/CydarmFetch';
import {
  put,
  takeEvery,
  takeLatest,
  all,
  takeLeading,
  call
} from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import {
  inlineImageWhitelist,
  STIX_MIMETYPE2_0,
  STIX_MIMETYPE2_1,
  displayableMimeTypes,
  HTML_MIMETYPE,
  CYDARM_FORM_MIMETYPE,
  displayableSyntaxHighlighting,
  displayableSyntaxHighlightingExtension,
  convertToMarkdown
} from 'utils/CaseDataUtils';
import {
  ICreateCaseDataRequest,
  IDetailedCaseData,
  ICaseDataResponse
} from 'interface/CaseData.interface';
import { Base64Decode, escapeBracketsForDefanged } from 'utils/StringUtils';
import { ERROR_MESSAGE } from './errors';
import { takeEveryThrottledPerKey } from 'states/sagas';
import {
  assignDatasetToCase,
  assignDataToCase,
  removeDataFromCase
} from 'states/caseData/slice';
import { addDatastubsToCase } from 'states/cases/slice';
import {
  createUpdateData,
  createDeleteData,
  createFetchDataSignificances,
  createFetchCaseData,
  createAddCaseData,
  createFetchCaseDataStubs,
  createDownloadData
} from './actions';
import {
  addDatasetToStore,
  fetchDataSignificancesSuccess,
  removeDataFromStore
} from './slice';
import { LOCAL_STORAGE } from '../../constants';
import {
  apiFetchCaseActivityDataStubs,
  apiFetchCaseDatasetTz,
  apiFetchDetailedDataset,
  apiAddCaseData,
  apiAddCaseStixPath,
  apiDeleteData,
  apiFetchDataSignificances,
  apiUpdateData
} from 'services/DataService';
import { invalidateCaseActivity } from 'states/cases/sagas';
import { clone } from 'utils/ObjectUtils';
import { Acl } from 'interface/Acl.interface';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { downloadFileFromUrl } from 'utils/FileUtils';

export function* performUpdateData(action) {
  const {
    payload: { dataStubUuid, data }
  } = action;
  try {
    const { json: caseData } = yield apiUpdateData(dataStubUuid, data);
    yield put(addNotification({ message: 'Updated case data successfully' }));

    yield call(invalidateCaseActivity, caseData.caseuuid);
  } catch (ex) {
    yield put(
      addNotification({ message: 'Error occurred updating case data' })
    );
  }
}

export function* performDeleteData(action) {
  const {
    payload: { dataStubUuid, caseUuid }
  } = action;
  try {
    yield apiDeleteData(dataStubUuid);
    yield put(removeDataFromStore({ dataStubUuid, caseUuid }));
    yield put(removeDataFromCase({ dataUuid: dataStubUuid, caseUuid }));
    yield put(addNotification({ message: 'Deleted case data successfully' }));
    yield call(invalidateCaseActivity, caseUuid);
  } catch (ex) {
    yield put(
      addNotification({ message: 'Error occurred deleting case data' })
    );
  }
}

export function* performFetchDataSignificances() {
  try {
    const { json: dataSignificances }: { json: DataSignificance[] } =
      yield apiFetchDataSignificances();
    dataSignificances.reverse();
    yield put(fetchDataSignificancesSuccess(dataSignificances));
  } catch (ex) {
    yield put(
      addNotification({ message: 'Error occurred fetching data significances' })
    );
  }
}

async function fetchDetailedDataset(
  caseData: IDetailedCaseData
): Promise<IDetailedCaseData> {
  const caseDataCopy: IDetailedCaseData = clone(caseData);
  const fileExtension: string | undefined = caseDataCopy.filename
    ? caseDataCopy.filename.slice(
        Math.max(0, caseDataCopy.filename.lastIndexOf('.')) || Infinity
      )
    : '';
  if (caseDataCopy.mimetype) {
    // is this a displayable mime type or does it have syntax highlighting?
    if (
      displayableMimeTypes.has(caseDataCopy.mimetype) ||
      displayableSyntaxHighlightingExtension.has(fileExtension)
    ) {
      if (inlineImageWhitelist.has(caseDataCopy.mimetype)) {
        // can be displayed as an inline image
        const fileData: Response = await apiFetchDetailedDataset(
          caseDataCopy.uuid
        );

        //convert to base64 so we can store in redux
        const base64Image: string = await new Promise(async (resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(await fileData.blob());
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
        });

        caseDataCopy.previewImage = base64Image;
      } else if (
        caseDataCopy.mimetype === STIX_MIMETYPE2_0 ||
        caseDataCopy.mimetype === STIX_MIMETYPE2_1
      ) {
        const convertedMarkdownStix = convertToMarkdown(
          caseDataCopy.bytedata && Base64Decode(caseDataCopy.bytedata),
          caseDataCopy.mimetype
        );
        if (convertedMarkdownStix === 'Invalid JSON') {
          // using backend render stix as FE unable to parse as markdown
          caseDataCopy.content =
            (caseDataCopy.bytedata && Base64Decode(caseDataCopy.bytedata)) ||
            '';
        } else {
          // using frontend render if backend unable to render
          caseDataCopy.content = convertedMarkdownStix;
        }
      } else if (caseDataCopy.mimetype === HTML_MIMETYPE) {
        // render as HTML
        const fileData = await apiFetchDetailedDataset(caseDataCopy.uuid);
        let blob = await fileData.blob();
        caseDataCopy.htmlString = await blob.text();
      } else if (
        displayableSyntaxHighlighting.has(caseDataCopy.mimetype) ||
        displayableSyntaxHighlightingExtension.has(fileExtension)
      ) {
        // render using syntax highlighting
        const fileData = await apiFetchDetailedDataset(caseDataCopy.uuid);
        let blob = await fileData.blob();
        caseDataCopy.content = await blob.text();
      } else if (caseDataCopy.mimetype === CYDARM_FORM_MIMETYPE) {
        caseDataCopy.content = caseDataCopy.bytedata
          ? Base64Decode(caseDataCopy.bytedata)
          : '';
      } else {
        // standard comment, usually text/plain
        caseDataCopy.mimetype =
          caseDataCopy.mimetype || 'application/octet-stream';
        caseDataCopy.content =
          caseDataCopy.bytedata &&
          escapeBracketsForDefanged(Base64Decode(caseDataCopy.bytedata));
      }
    }
  } else {
    // not displayable or syntax highlightable - just a regular file download
    // this is a hack to make sure these files get displayed
    caseDataCopy.content = caseDataCopy.filename
      ? caseDataCopy.filename
      : 'Filename could not be displayed.';
  }
  return caseDataCopy;
}

function* fetchCaseDataset(action) {
  const {
    payload: { uuid: caseUuid, currentCaseDataUuids = [], timeZone }
  } = action;
  const selectedTz = timeZone
    ? timeZone
    : localStorage.getItem(LOCAL_STORAGE.TIME_ZONE) || '';
  const encodedSelectedTz = encodeURIComponent(selectedTz);
  try {
    // fetch data stubs for all data items
    let result = yield apiFetchCaseActivityDataStubs(caseUuid);

    if (currentCaseDataUuids) {
      // TO DO: Implement not loading data we already have?
    }

    const caseDataArray = result.json.case_data;

    // perform a bulk fetch for items that are displayable
    const uuidList = [...caseDataArray]
      .filter((item) => displayableMimeTypes.has(item.mimetype))
      .map((item) => item.uuid)
      .join(',');

    if (uuidList === '') {
      return;
    }

    let { json: fetchData } = yield apiFetchCaseDatasetTz(
      uuidList,
      encodedSelectedTz
    );
    // replace caseData with detailedData from bulk fetch, whenever possible, also for stix data as BE is doing rendering
    const fullList = caseDataArray.map((caseData) => {
      let detailedData = fetchData.filter((currentData) => {
        if (caseData.uuid === currentData.dataStubUuid) {
          if (
            caseData.mimetype === STIX_MIMETYPE2_0 ||
            caseData.mimetype === STIX_MIMETYPE2_1
          ) {
            caseData.bytedata = currentData.bytedata;
          }
          return true;
        }
        return false;
      });

      if (detailedData.length) {
        // merge results of detailed data from the bulk fetch
        for (let [key, value] of Object.entries(detailedData[0])) {
          if (!Object.keys(caseData).includes(key)) {
            caseData[key] = value;
          }
        }
      } else {
        caseData['dataStubUuid'] = caseData.uuid;
      }
      return caseData;
    });

    const returnData = yield all(
      fullList.map((caseData) => fetchDetailedDataset(caseData))
    );

    yield put(
      addDatasetToStore({
        dataset: returnData,
        caseUuid: caseUuid
      })
    );
    yield put(
      assignDatasetToCase({
        caseUuid,
        dataUuids: caseDataArray.map(({ uuid }) => uuid)
      })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_CASE_DATA_ERROR.message })
    );
  }
}

function* performAddCaseDataStubs(action) {
  const {
    payload: { caseData, currentCaseDataUuids = [] }
  } = action;

  try {
    let result = yield apiFetchCaseActivityDataStubs(caseData.uuid);

    const caseDataArray = result.json.case_data;
    const caseDataArray2 = caseDataArray.filter(
      ({ uuid }) => !currentCaseDataUuids.includes(uuid)
    );
    yield put(
      addDatastubsToCase({ case: caseData, dataStubs: caseDataArray2 })
    );
  } catch (ex) {}
}

function* performAddCaseData(action) {
  const {
    payload: { parentCaseDataStubUuid, caseData }
  } = action;
  const { stixData, fileData, ...rest } = caseData as ICreateCaseDataRequest;
  let parentDataItem: ICaseDataResponse = {
    uuid: parentCaseDataStubUuid ? parentCaseDataStubUuid : ''
  };
  const hasFiles = fileData && fileData.length;
  const hasStix = stixData && stixData.length;
  const hasText = rest.data !== '';
  //if multiple files and text, text is parent. If one file and text, file is parent.
  if (hasFiles || hasStix) {
    if (fileData!.length + stixData!.length > 1 && hasText) {
      yield addComment(true);
      if (hasFiles) {
        yield addFile();
      }
      if (hasStix) {
        yield addStix();
      }
    } else {
      if (hasFiles) {
        yield addFile(hasText);
      }
      if (hasStix) {
        yield addStix(hasText);
      }
      if (hasText) {
        yield addComment();
      }
    }
  } else if (hasText) {
    yield addComment();
  }

  yield call(invalidateCaseActivity, caseData.caseUuid);

  function* addFile(makeParent = false) {
    try {
      yield all(
        fileData!.map(function* (dataFile) {
          const { json: newCaseData } = yield apiAddCaseData(
            caseData.caseUuid,
            {
              ...dataFile,
              significance: caseData.significance,
              acl: caseData.acl
            },
            parentDataItem.uuid
          );
          yield put(
            assignDataToCase({
              caseUuid: caseData.caseUuid,
              dataUuid: newCaseData.uuid
            })
          );
          if (makeParent) {
            parentDataItem = newCaseData;
          }
        })
      );
      yield put(
        addNotification({
          message: `Uploaded ${fileData!.length} ${
            fileData!.length === 1 ? 'file' : 'files'
          }`
        })
      );
    } catch (ex) {
      yield put(addNotification({ message: 'File upload has failed' }));
      return;
    }
  }

  function* addStix(makeParent = false) {
    try {
      let bundleAcl: Acl;
      if (rest.acl === undefined) {
        throw new Error('ACL is undefined');
      } else {
        bundleAcl = rest.acl;
      }
      yield all(
        stixData!.map(function* (dataFile) {
          const { json: newCaseData } = yield apiAddCaseStixPath(
            caseData.caseUuid,
            // TODO: need better typing here - use of this directive is very dodgy!
            //@ts-expect-error
            {
              ...dataFile,
              significance: rest.significance,
              bundleAcl: bundleAcl
            }
          );
          yield put(
            assignDataToCase({
              caseUuid: caseData.caseUuid,
              dataUuid: newCaseData.uuid
            })
          );
          if (makeParent) {
            parentDataItem = newCaseData;
          }
        })
      );
      yield put(addNotification({ message: 'Uploaded STIX file' }));
    } catch (ex) {
      yield put(addNotification({ message: 'STIX file upload has failed' }));
    }
  }

  function* addComment(makeParent = false) {
    try {
      if (rest.acl_uuid === undefined && rest.acl !== undefined) {
        rest.acl_uuid = rest.acl.uuid;
      }
      const { json: newCaseData } = yield apiAddCaseData(
        caseData.caseUuid,
        //@ts-expect-error - TODO: fix typing issues and remove this directive
        rest,
        parentDataItem.uuid
      );
      if (makeParent) {
        parentDataItem = newCaseData;
      }
      yield put(
        assignDataToCase({
          caseUuid: caseData.caseUuid,
          dataUuid: newCaseData.uuid
        })
      );

      yield put(addNotification({ message: 'Added comment' }));
    } catch (ex) {
      console.error(ex);
      yield put(addNotification({ message: 'Add comment has failed' }));
    }
  }
}

function* performDownloadCaseData(action) {
  const { dataStubUuid, filename = 'stix_data' } = action.payload;

  try {
    const fileData = yield CydarmFetch.cyFetchFileAuthenticated(
      `/data/${dataStubUuid}/file`
    );
    const blob = yield fileData.blob();
    const url = window.URL.createObjectURL(blob);

    downloadFileFromUrl(url, filename);
  } catch (ex) {
    yield put(addNotification({ message: 'Error occurred downloading file' }));
  }
}

function* watchUpdateData() {
  yield takeEvery(createUpdateData, performUpdateData);
}

function* watchDeleteData() {
  yield takeEvery(createDeleteData, performDeleteData);
}

function* watchFetchSignificanceData() {
  yield takeLeading(
    createFetchDataSignificances,
    performFetchDataSignificances
  );
}

function* watchFetchCaseData() {
  yield takeEveryThrottledPerKey(
    createFetchCaseData,
    fetchCaseDataset,
    ({ payload: { uuid: caseUuid, timezone } }) => ({ caseUuid, timezone }),
    100
  );
}

function* watchAddCaseData() {
  yield takeLatest(createAddCaseData, performAddCaseData);
}

function* watchAddCaseDataStubs() {
  yield takeEvery(createFetchCaseDataStubs, performAddCaseDataStubs);
}

function* watchDownloadData() {
  yield takeLatest(createDownloadData, performDownloadCaseData);
}

export default [
  watchUpdateData(),
  watchDeleteData(),
  watchFetchSignificanceData(),
  watchFetchCaseData(),
  watchAddCaseData(),
  watchAddCaseDataStubs(),
  watchDownloadData()
];
