import { useQuery } from '@tanstack/react-query';
import { useCurrentUserPrimaryOrg } from 'hooks/AuthHooks';
import { apiGetOrgPropsForOrg, OrgProp } from 'services/OrgPropsService';
import { LoadingData } from 'types/LoadingData';

export type ORG_PROP_TYPES =
  | 'CASE_DEFAULTS_ACL'
  | 'CASE_DATA_DEFAULTS_ACL'
  | 'USER_MANAGEMENT_ACL'
  | 'ORG_MANAGEMENT_ACL';

export function useOrgProps(orgUuid: string | null) {
  return useQuery({
    queryFn: () => {
      if (!orgUuid) {
        throw new Error('No org uuid to call function with');
      }
      return apiGetOrgPropsForOrg(orgUuid);
    },
    enabled: !!orgUuid,
    queryKey: ['org-prop', orgUuid]
  });
}

export function useOrgProp(
  orgUuid: string | null,
  propType: ORG_PROP_TYPES
): LoadingData<OrgProp | null> {
  const orgProps = useOrgProps(orgUuid);

  if (!orgUuid) {
    return {
      isLoading: false,
      hasLoaded: true,
      data: null
    };
  }

  if (orgProps.isLoading) {
    return {
      isLoading: true,
      hasLoaded: false,
      data: null
    };
  } else {
    const foundProp = orgProps.data?.json.find((v) => v.name === propType);

    if (!foundProp) {
      throw new Error(`No org prop found for propType: '${propType}'`);
    }

    return {
      isLoading: false,
      hasLoaded: true,
      data: foundProp
    };
  }
}

export function usePrimaryOrgProp(propType: ORG_PROP_TYPES) {
  const primaryOrg = useCurrentUserPrimaryOrg();
  return useOrgProp(primaryOrg.data || null, propType);
}
