/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  FETCH_ORGS_ERROR: {
    code: 'FETCH_ORGS_ERROR',
    message: 'Error occurred while fetching orgs'
  },
  FETCH_ORG_BY_UUID_ERROR: {
    code: 'FETCH_ORG_BY_UUID_ERROR',
    message: 'Error occurred while fetching org with uuid'
  },
  CREATE_ORG_ERROR: {
    code: 'CREATE_ORG_ERROR',
    message: 'Error occurred while creating org'
  },
  UPDATE_ORG_ERROR: {
    code: 'UPDATE_ORG_ERROR',
    message: 'Error occurred while updating org'
  },
  DELETE_ORG_ERROR: {
    code: 'DELETE_ORG_ERROR',
    message: 'Error occurred while deleting org'
  }
};

export { ERROR_MESSAGE };
