/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { PropsWithChildren } from 'react';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from '../type';
import { innerBorder, outerBorder } from './_commonBorderStyles';

export type GenericNodeWithPossibleStatus = GenericNode & {
  nodeStatus?: GenericPlaybookResolutionInfo['nodeStatuses'][string];
  isSelected: boolean; //nb. we don't use React-Flow's selection mechanism
  playbookType: GenericPlaybook['playbookType'];
};

/** @jsxImportSource @emotion/react */
export type BaseCustomNodeProps = PropsWithChildren<{
  data: GenericNodeWithPossibleStatus;
  className?: string;
}>;

// nb. this values are important.
// utils/autoLayout uses them to determine the positioning of the nodes.
// Our strategy (for now) is that all nodes are always the same layout size
// If we want to improve on this, we will need to do some kind of detecting dynamic sizing type thing.
export const REACT_FLOW_NODE_WIDTH = 350;
export const REACT_FLOW_NODE_MAX_HEIGHT = 91;
export const VERTICAL_SPACING_VALUE = 53;

export const BaseCustomNode = (props: BaseCustomNodeProps) => {
  const { className, children, data } = props;

  return (
    <div
      data-testid="generic-playbook-node"
      css={(theme) => {
        return `
          width: ${REACT_FLOW_NODE_WIDTH}px; 
          height: ${REACT_FLOW_NODE_MAX_HEIGHT}px; 
          ${outerBorder(theme, data)}
          border-radius: ${theme.other.panelBorderRadius}; 
  `;
      }}
      className={className}
    >
      {/* This inner div is an overlay to show the selection status */}
      <div
        css={(theme) => `
        ${innerBorder(theme, data)}

        position: absolute; 
        top: 0px; 
        bottom: 0px; 
        right: 0px; 
        left: 0px; 
        border-radius: ${theme.other.panelBorderRadius}; 
        display: flex; 
        flex-flow: column nowrap; 
        justify-content: center; 

        padding: ${theme.spacing(4)}; 

      `}
      >
        {children}
      </div>
    </div>
  );
};
