/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { getTimezoneOffset } from 'date-fns-tz';
import { Store } from '@reduxjs/toolkit';

/**
 * This is an important pattern to avoid circular dependencies anywhere we interact with store directly
 * See:
 *
 * https://github.com/reduxjs/redux-toolkit/issues/1540
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 *
 *
 * @param store
 */
let store: Store;
export function provideStore(_store: Store) {
  store = _store;
}

const isValidDate = (d: any): boolean => !isNaN(d.getTime());
export const RFC3339_UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const dateFormatInHour = 'HH:mm:ss';
export const dateFormatInHourAndYear = 'HH:mm:ss DD/MM/YYYY';
export const dateFormatInYearAnd24Hour = 'YYYY-MM-DD HH:mm';
export const dateFormatRfc3339Local = 'YYYY-MM-DDTHH:mm:ssZ';
export const dateFormatLocale = 'LLL';
const periods = [
  {
    label: 'Year',
    seconds: 31536000
  },
  {
    label: 'Month',
    seconds: 2628000
  },
  {
    label: 'Day',
    seconds: 86400
  },
  {
    label: 'Hour',
    seconds: 3600
  },
  {
    label: 'Minute',
    seconds: 60
  },
  {
    label: 'Second',
    seconds: 1 //1 second in a second!?
  }
];

export const formatDate = (dateString: string | null): string | undefined => {
  if (!dateString) {
    return;
  }

  const dateMillis = Date.parse(dateString);
  const date = new Date(dateMillis);
  if (!isValidDate(date)) {
    return;
  }
  let timeZone = store.getState().auth.timeZone;
  // let timeZone = '';

  if (timeZone === 'Australia/ACT') {
    timeZone = 'Australia/Sydney';
  } else if (timeZone === 'Antarctica/McMurdo') {
    timeZone = 'Pacific/Auckland';
  }

  return date.toLocaleString(
    navigator.language,
    timeZone ? { timeZone } : undefined
  );
};

export const secondsToString = (seconds) => {
  let secondsLeft = seconds;
  if (seconds < 1) {
    return 'Immediate';
  }

  return periods
    .map((period) => {
      let count = 0;
      if (secondsLeft >= period.seconds) {
        count = Math.floor(secondsLeft / period.seconds);
        secondsLeft = secondsLeft % period.seconds;
      }
      return { count: count, label: period.label };
    })
    .filter(({ count }) => count)
    .filter((o, index) => index < 2)
    .map(({ count, label }) => `${count} ${count > 1 ? `${label}s` : label}`)
    .join(', ');
};

export const isValidTimeZone = (tz) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw new Error('Time zones are not available in this environment');
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (ex) {
    return false;
  }
};

export function isValidJsDate(d: Date) {
  //@ts-ignore
  return d instanceof Date && !isNaN(d);
}

//https://github.com/marnusw/date-fns-tz/issues/194
//I've had to reverse the +/- operators, I'm confused as to why, but tests work.
export function fixedUtcToZonedTime(date: Date | number, timeZone: string) {
  const offset = getTimezoneOffset(timeZone, date);

  return new Date(date.valueOf() - offset);
}

export function fixedZonedTimeToUtc(date: Date | number, timeZone: string) {
  const offset = getTimezoneOffset(timeZone, date);

  return new Date(date.valueOf() + offset);
}

export function getStartOfDayInTimeZone(
  date: {
    date: number;
    monthIndex: number;
    fullYear: number;
  },
  tz?: string
): string {
  if (tz) {
    const utcDate = new Date(
      Date.UTC(date.fullYear, date.monthIndex, date.date, 0, 0)
    );

    const tzDate = fixedUtcToZonedTime(utcDate, tz);

    const rString = tzDate.toISOString();
    return rString;
  } else {
    const jsDate = new Date(date.fullYear, date.monthIndex, date.date, 0, 0);
    return jsDate.toISOString();
  }
}

export function getEndOfDayInTimeZone(
  date: {
    day: number;
    monthIndex: number;
    fullYear: number;
  },
  tz?: string
): string {
  if (tz) {
    const utcDate = new Date(
      Date.UTC(date.fullYear, date.monthIndex, date.day, 23, 59, 59, 999)
    );
    const tzDate = fixedUtcToZonedTime(utcDate, tz);

    return tzDate.toISOString();
  } else {
    const jsDate = new Date(
      date.fullYear,
      date.monthIndex,
      date.day,
      23,
      59,
      59,
      999
    );
    return jsDate.toISOString();
  }
}

// Take a combination of a ISO Date string and timezone, convert it to locale time.
export function convertTimezonedDateToLocaleDate(
  date: string,
  tz: string
): string {
  const newTime = fixedZonedTimeToUtc(new Date(date), tz);
  return newTime.toISOString();
}

export function sortDates(a: string, b: string) {
  //https://stackoverflow.com/questions/12192491/sort-array-by-iso-8601-date
  return a < b ? -1 : a > b ? 1 : 0;
}
