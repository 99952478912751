/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Org } from 'interface/Org.interface';
import { createAction } from '@reduxjs/toolkit';

interface IUpdate {
  org: Org;
  uuid: string;
}

// Orgs Actions Function
export const createFetchOrgs = createAction('orgs/fetch');
export const createFetchOrgByUuid = createAction<string>('orgs/fetchByUuid');
export const createOrg = createAction<string>('orgs/create');
export const createUpdateOrg = createAction<IUpdate>('orgs/update');
export const createDeleteOrg = createAction<string>('orgs/delete');
