/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const usePlaybookActionFormStyles = makeStyles(({ spacing }) => ({
  fieldWrapper: {
    marginBottom: spacing(2)
  }
}));

export const createPlaybookActionField = makeStyles(() => ({
  inputRoot: {
    '&&': { padding: '2px 10px 2px ' }
  }
}));
