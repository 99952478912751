/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createFetchCaseSeverity } from './actions';
import { ICaseSeverity } from 'interface/CaseSeverity.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISeverityStoreState {
  list: ICaseSeverity[];
  maxCaseSeverityLevel: number;
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: ISeverityStoreState = {
  list: [],
  maxCaseSeverityLevel: 0,
  isLoading: false,
  hasLoaded: false
};

const severitySlice = createSlice({
  name: 'severity',
  initialState,
  reducers: {
    fetchCaseSeverityFailure: (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
    },
    fetchCaseSeveritySuccess: (
      state,
      { payload }: PayloadAction<ICaseSeverity[]>
    ) => {
      state.list = payload.sort((a: ICaseSeverity, b: ICaseSeverity) =>
        a.precedence! > b.precedence! ? 1 : -1
      );
      state.isLoading = false;
      state.hasLoaded = true;
      state.maxCaseSeverityLevel = Math.max(
        ...payload.map(({ precedence }) => precedence || 0)
      );
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchCaseSeverity, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: severity,
  actions: { fetchCaseSeveritySuccess, fetchCaseSeverityFailure }
} = severitySlice;

// export const severity = (
//   state = initialState,
//   { type, payload }: { type: string; payload: ISeverityStorePayload }
// ) => {
//   switch (type) {
//     case FETCH_CASE_SEVERITY:
//       return { ...state, isLoading: true };
//     case FETCH_CASE_SEVERITY_SUCCESS:
//       return {
//         ...state,
//         list: payload.list,
//         isLoading: false,
//         hasLoaded: true,
//         maxCaseSeverityLevel: payload.list.reduce(
//           (acc, severity) =>
//             severity.precedence && severity.precedence > acc
//               ? severity.precedence
//               : acc,
//           0
//         )
//       };
//     default:
//       return state;
//   }
// };
