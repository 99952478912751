/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useCaseViewPageStyles = makeStyles(({ shadows, palette }) => ({
  wrapper: {
    padding: 0
  },
  container: {
    display: 'flex'
  },
  content: {
    flex: 'auto',
    minWidth: 'calc(100% - 385px)'
  },
  tabsBarContainer: {
    position: 'relative',
    backgroundColor: palette.background.paper,
    boxShadow: shadows[1],
    zIndex: 1,
    minHeight: 40
  },
  tabContentContainer: {
    boxSizing: 'border-box'
  }
}));

export const useCaseViewPageSidebarStyles = makeStyles(
  ({
    spacing,
    shadows,
    palette: { text, background, divider },
    shape: { borderRadius }
  }) => ({
    sidebarInfo: {},
    sidebarInfoIcon: {},
    sideBar: {},
    sidebarClosedState: {
      width: 24
    },
    sidebarActionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    sidebarList: {
      paddingTop: 0,
      paddingBottom: 0
    },
    sidebarListItem: {
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5)
    },
    sidebarListCaseDescription: {
      fontSize: 14,
      color: text.primary
    },
    sidebarMetaName: {
      width: '30%',
      paddingRight: spacing(2),
      textAlign: 'left',
      fontSize: 14
    },
    sidebarMetaWrapper: {
      display: 'flex',
      marginBottom: spacing(2),
      textAlign: 'left',
      cursor: 'pointer',
      border: '0px solid'
    },
    sidebarMetaValue: {
      width: '45%',
      right: spacing(2),
      top: spacing(2),
      borderRadius: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      display: 'inline',
      padding: '12px 12px',
      border: '1px solid'
    },
    caseListIcon: {
      display: 'flex',
      paddingRight: spacing(1),
      fontSize: '30px'
    },
    sidebarMetaEditButton: {
      position: 'absolute',
      right: spacing(2),
      top: spacing(2)
    },
    sidebarMetaEditOpenTabButton: {
      position: 'inherit',
      right: spacing(2),
      top: spacing(2)
    },
    tagChip: {
      fontSize: 11,
      marginRight: spacing(2),
      justifyContent: 'space-between',
      maxWidth: '100%',
      marginBottom: spacing()
    },
    tagChipLabel: {
      paddingLeft: 12,
      paddingRight: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    caseTagAutoSelect: {
      paddingBottom: spacing(2),
      paddingTop: spacing(2)
    },
    sideBarContainer: {
      paddingTop: '5px',
      padding: spacing(3),
      borderBottom: `3px solid ${divider}`,
      position: 'relative'
    },
    sideBarCollapseButton: {
      maxWidth: '4px',
      minWidth: '0px',
      verticalAlign: 'top'
    },
    sideBarTagInput: {
      minWidth: '300px'
    }
  })
);

export const useAddPlaybookPopoverStyles = makeStyles(({ spacing }) => ({
  container: {
    'div&': {
      width: 300,
      marginLeft: -16
    }
  }
}));

export const useCaseViewPageSidebarPlaybookStyles = makeStyles(
  ({ spacing }) => ({
    sidebarPlaybook: {
      marginBottom: spacing(2)
    },
    sidebarPlaybookHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing()
    }
  })
);

export const sideBarTagsAutocomplete = makeStyles(({ palette }) => ({
  input: {
    flexDirection: 'row',
    flex: '0 1 100%'
  },
  inputRoot: {
    backgroundColor: palette.background.paper,
    '&&': { minWidth: '150px' },
    '& .MuiAutocomplete-endAdornment ': {
      top: 'calc(100% - 35px)',
      position: 'absolute'
    }
  }
}));
