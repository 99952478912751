/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Handle, Position } from 'reactflow';
import { BaseCustomNodeProps } from './BaseCustomNode';

import { BaseDiamondNode } from './BaseDiamondNode';
import { parseNodeName } from './customNodeUtils';

export const IfConditionNode = (props: BaseCustomNodeProps) => {
  // Problem with this solution is that we've lost any selection styling, etc that was on BaseCustomNode.
  // Anyway, lets cross that bridge when we get there
  return (
    <BaseDiamondNode
      {...props}
      nodeType="if-condition"
      label={parseNodeName(props.data, props.data.playbookType)}
    >
      <Handle type="target" position={Position.Top} />
      <Handle id="left" type="source" position={Position.Left} />
      <Handle id="bottom" type="source" position={Position.Bottom} />
      <Handle id="right" type="source" position={Position.Right} />
    </BaseDiamondNode>
  );
};
