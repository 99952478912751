/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  DefaultSelect,
  IDefaultSelectProps
} from 'components/fields/DefaultSelect';
import { User } from 'interface/User.interface';

export interface IUserSelectProps extends Omit<IDefaultSelectProps, 'options'> {
  users: User[];
}

const mapUserToOptions = (users: User[]) =>
  users.map((user) => ({
    label: user.displayName,
    value: user
  }));

export const UserSelect: React.FunctionComponent<IUserSelectProps> = ({
  users,
  value,
  multiple,
  placeholder,
  onChange,
  ...props
}) => {
  const userOptions = React.useMemo(() => mapUserToOptions(users), [users]);

  const _value =
    value &&
    (multiple
      ? users.filter(({ uuid }) =>
          (value as User[]).some((selectedUser) => selectedUser.uuid === uuid)
        )
      : users.find(({ uuid }) => uuid === (value as User).uuid));

  const emptyValue = React.useMemo(() => (multiple ? [] : ''), [multiple]);

  const changeHandler = React.useCallback(
    (event, child) => {
      if (!onChange) {
        return;
      }

      if (!multiple) {
        onChange(event, child);
        return;
      }

      const value = event.target.value;
      const isEmptySelected = multiple && value.some((val: any) => val === '');
      event.target.value = isEmptySelected ? [''] : value;

      onChange(event, child);
    },
    [multiple, onChange]
  );

  const renderValue = React.useCallback(
    (selected) => {
      if (selected.length === 0) {
        return <span>{placeholder}</span>;
      }

      return selected.map(({ displayName }: User) => displayName).join(', ');
    },
    [placeholder]
  );

  return (
    <DefaultSelect
      multiple={multiple}
      options={userOptions}
      value={_value || emptyValue}
      placeholder={placeholder}
      onChange={changeHandler}
      renderValue={multiple ? renderValue : undefined}
      {...props}
    />
  );
};
