import React from 'react';
import { createNoop } from 'utils/Misc';
import { useDataSignificancesRedux } from './CaseDataHooks';

type DataSignificances = {
  getDataSignificances: typeof useDataSignificancesRedux;
};

const DataSignificancesContext = React.createContext<DataSignificances>({
  getDataSignificances: createNoop('Data Signficances', {
    data: [],
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  })
});

export const DataSignificancesProvider = (
  props: React.PropsWithChildren<DataSignificances>
) => {
  const { children, ...rest } = props;
  return (
    <DataSignificancesContext.Provider value={rest}>
      {children}
    </DataSignificancesContext.Provider>
  );
};

export const useDataSignificancesHook = () => {
  return React.useContext(DataSignificancesContext);
};

export const ProductionDataSignificancesHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <DataSignificancesProvider getDataSignificances={useDataSignificancesRedux}>
      {props.children}
    </DataSignificancesProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */

const DEFAULT_SIGNIFICANCES = [
  {
    uuid: 'a4433df7-8d30-4e84-8799-291d59e17033',
    name: 'Comment',
    selectable: true,
    precedence: 1
  },
  {
    uuid: '82f3d9d1-e20e-438c-bd41-819830900e1f',
    name: 'Assignee Change',
    selectable: false,
    precedence: 2
  },
  {
    uuid: '4c3b9d29-9a2f-4265-989b-ffe814118820',
    name: 'Significant Finding',
    selectable: true,
    precedence: 3
  },
  {
    uuid: '504d9bea-c51b-4692-baef-f70f28251456',
    name: 'Significant Decision',
    selectable: true,
    precedence: 4
  },
  {
    uuid: '8e462637-c6ec-4815-a106-f8b6e13ee7cf',
    name: 'Significant Action',
    selectable: true,
    precedence: 5
  },
  {
    uuid: 'c44e6a4c-f160-4191-a158-7d5196e0ad31',
    name: 'Severity Change',
    selectable: false,
    precedence: 6
  },
  {
    uuid: '12173453-2f7a-45a0-be73-75c81239c239',
    name: 'Disclosure Import',
    selectable: true,
    precedence: 7
  },
  {
    uuid: '25e9e3d7-fdbc-4ffb-8621-46f0b99a907e',
    name: 'Disclosure Export',
    selectable: true,
    precedence: 8
  },
  {
    uuid: '389ef016-7b2b-45b8-a335-5ea42f41aafb',
    name: 'Status Change',
    selectable: false,
    precedence: 9
  }
];
export const InMemoryDataSignificancesHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <DataSignificancesProvider
      getDataSignificances={() => ({
        data: DEFAULT_SIGNIFICANCES,
        isLoading: false,
        hasLoaded: true,
        fetchData: () => {}
      })}
    >
      {props.children}
    </DataSignificancesProvider>
  );
};
