/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydTextField } from 'components/_formElements/CydTextField';
import { GenericPlaybookStatusUpdate } from 'components/_playbooks/CacaoPlaybookRunningInstance';
import React from 'react';
import { GenericVariableInfo } from '../../type';

export type OutputVariablesSettingFormProps = {
  onSubmitVariables: (data: GenericPlaybookStatusUpdate) => void;
  resolvedOutArgs: Array<{
    value: string | null;
    info: GenericVariableInfo;
  }>;
  closeModalFn: () => void;
};

export const OutputVariablesSettingForm = (
  props: OutputVariablesSettingFormProps
) => {
  const {
    resolvedOutArgs,
    onSubmitVariables: onUpdateNode,
    closeModalFn
  } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const payload = resolvedOutArgs.reduce(
          (acc, cur) => {
            return {
              ...acc,

              [cur.info.key]: e.target[cur.info.key].value
            };
          },
          {} as Record<string, string>
        );

        onUpdateNode({
          variable_bindings: payload
        });

        closeModalFn();
      }}
    >
      {resolvedOutArgs.map((v, i) => {
        return (
          <CydTextField
            key={v.info.key}
            autoFocus={i === 0}
            required
            name={v.info.key}
            label={v.info.name}
            defaultValue={v.value}
          />
        );
      })}
      <CydButtonStack justifyContent={'flex-end'}>
        <CydButton type="submit">Submit</CydButton>
      </CydButtonStack>
    </form>
  );
};
