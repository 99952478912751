/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  createFetchPlaybooks,
  createFetchPlaybookByUuid
} from 'states/playbooks/actions';
import {
  playbookListSelector,
  singleCacaoPlaybookSelector,
  playbookAtcListSelector,
  playbookCacaoListSelector,
  singleAtcPlaybookSelector
} from 'states/playbooks/selector';
import { useAutoEffect } from './ReduxHooks';

export const usePlaybooks = () => {
  return useAutoEffect({
    selector: playbookListSelector,
    ac: createFetchPlaybooks,
    isLoadingSelector: (state) => state.playbooks.isLoading || false,
    hasLoadedSelector: (state) => state.playbooks.hasLoaded,
    statusSelector: (state) => {
      const result = !!(state.playbooks.hasLoaded || state.playbooks.isLoading);
      return result;
    }
  });
};

export const useAtcPlaybooks = () => {
  return useAutoEffect({
    selector: playbookAtcListSelector,
    ac: createFetchPlaybooks,
    isLoadingSelector: (state) => state.playbooks.isLoading || false,
    hasLoadedSelector: (state) => state.playbooks.hasLoaded,
    statusSelector: (state) => {
      const result = !!(state.playbooks.hasLoaded || state.playbooks.isLoading);
      return result;
    }
  });
};

export const useCacaoPlaybooks = () => {
  return useAutoEffect({
    selector: playbookCacaoListSelector,
    ac: createFetchPlaybooks,
    isLoadingSelector: (state) => state.playbooks.isLoading || false,
    hasLoadedSelector: (state) => state.playbooks.hasLoaded,
    statusSelector: (state) => {
      const result = !!(state.playbooks.hasLoaded || state.playbooks.isLoading);
      return result;
    }
  });
};

export const useSingleAtcPlaybook = (
  playbookUuid: string,
  shouldFetch: boolean = true
) => {
  return useAutoEffect({
    selector: singleAtcPlaybookSelector,
    ac: shouldFetch ? createFetchPlaybookByUuid : undefined,
    actionData: playbookUuid
  });
};

export const useSingleCacaoPlaybook = (playbookUuid: string) => {
  return useAutoEffect({
    selector: singleCacaoPlaybookSelector,
    ac: createFetchPlaybookByUuid,
    actionData: playbookUuid
  });
};
