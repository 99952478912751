/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';

/** @jsxImportSource @emotion/react */
import { CydSimpleTable } from 'components/_dataDisplay/CydSimpleTable/CydSimpleTable';
import { getVariablesAsRowsAndColumns } from '../../utils/generalNodeStatusUtils';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from '../../type';

export type InputArgsDisplayProps = {
  node: GenericNode;
  playbook: GenericPlaybook;
  resolutionInfo: GenericPlaybookResolutionInfo;
};

export const InputArgsDisplay = (props: InputArgsDisplayProps) => {
  const { node, playbook, resolutionInfo } = props;

  if (node.nodeType.type !== 'action') {
    // Only 'action' and 'start' steps have input args
    return null;
  }
  const rowsAndColumns = getVariablesAsRowsAndColumns(
    playbook.variablesMap,
    resolutionInfo.resolvedVariables,
    node.nodeType.data.inArgIds || [],
    true
  );

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Input Variables
      </Typography>

      {node.nodeType.data.inArgIds?.length === 0 && (
        <>(No input variables for this step)</>
      )}
      <CydSimpleTable
        columns={rowsAndColumns.columns}
        rows={rowsAndColumns.rows}
        uniquenessId="label"
      />
    </div>
  );
};
