/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { CydarmRoute } from 'interface/CydarmRoute';

export const caseViewPageChildPages = [
  //Make sure this first, otherwise it will break things.
  {
    url: '',
    label: 'Activity'
  },
  {
    url: CydarmRoute.PLAYBOOKS,
    label: 'Playbooks'
  },
  {
    url: CydarmRoute.REPORT,
    label: 'Reports'
  },
  { url: CydarmRoute.GRAPH, label: 'Graph' }
];

export const caseViewPageChildPagesNew = [
  //Make sure this first, otherwise it will break things.
  {
    url: '',
    label: 'Activity'
  },
  {
    url: CydarmRoute.PLAYBOOKS,
    label: 'Playbooks'
  },
  {
    url: CydarmRoute.REPORT,
    label: 'Reports'
  },
  { url: CydarmRoute.GRAPH, label: 'Graph' }
];
