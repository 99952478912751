/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  ICaseDataStixDataForm,
  ICaseDataStixDataType,
  ICaseDataStixIdContributingPropertiesArtifact,
  ICaseDataStixIdContributingPropertiesFile
} from 'interface/CaseData.interface';
import canonicalize from 'canonicalize';
import Mustache from 'mustache';
import raw from 'raw.macro';
import moment from 'moment';
import { v4 as uuid, v5 as uuidv5 } from 'uuid';
import { ObjectUtils } from 'utils/ObjectUtils';
import { RFC3339_UTC_DATE_FORMAT } from 'utils/DateUtils';
import { confidenceRating } from './CaseDataUtils';

const artifactStixTemplate = raw('./templates/stix/artifact-stix.mustache');
const fileStixTemplate = raw('./templates/stix/file-stix.mustache');
const valueTypeStixTemplate = raw('./templates/stix/value-type-stix.mustache');
const valueTypeEmailAddrStixTemplate = raw(
  './templates/stix/value-type-email-addr-stix.mustache'
);

// This is a namespace for Stix Cyber Observable Objects
// Please refer to https://docs.oasis-open.org/cti/stix/v2.1/os/stix-v2.1-os.html#_64yvzeku5a5c
const STIX21_CYBER_OBS_NAMESPACE = '00abedb4-aa42-466c-9c01-fed23315a9b7';

export class StixBuilder {
  private static generateUuidv5(data: object) {
    const cleanedUpData = ObjectUtils.removeNullAndUndefinedFields(data);
    return uuidv5(
      canonicalize(cleanedUpData) as string,
      STIX21_CYBER_OBS_NAMESPACE
    );
  }

  public static getScoTypePatternField(scoType: ICaseDataStixDataType) {
    switch (scoType) {
      case 'email-addr':
        return 'email-message:from_ref.value';
      default:
        return `${scoType}:value`;
    }
  }

  public static generateScoUuidForValueBaseType(value: string) {
    if (!value) {
      throw new Error('"value" must be provided');
    }
    return this.generateUuidv5({ value });
  }

  public static generateScoUuidForFile(
    idContributingProperties?: ICaseDataStixIdContributingPropertiesFile
  ) {
    if (idContributingProperties) {
      const { hashes, name, extensions, parent_directory_ref } =
        idContributingProperties;

      // Currently we only have hashes and name fields.
      // Based on the Stix 2.1 spec, we can have extensions and parent_directory_ref
      if (hashes || name || extensions || parent_directory_ref) {
        return this.generateUuidv5(idContributingProperties);
      }
    }
    return uuid();
  }

  public static generateScoUuidForArtifact(
    idContributingProperties?: ICaseDataStixIdContributingPropertiesArtifact
  ) {
    if (idContributingProperties) {
      const { hashes, payload_bin } = idContributingProperties;
      // const hashes = this.getHashes(idContributingProperties.hashes);
      if (hashes || payload_bin) {
        return this.generateUuidv5(idContributingProperties);
      }
    }
    // use v4 if no data
    return uuid();
  }

  public static getTemplateByDataType = (dataType: ICaseDataStixDataType) => {
    if (this.isValueType(dataType)) {
      if (dataType === 'email-addr') {
        return valueTypeEmailAddrStixTemplate;
      }
      return valueTypeStixTemplate;
    } else if (dataType === 'artifact') {
      return artifactStixTemplate;
    } else if (dataType === 'file') {
      return fileStixTemplate;
    }
    throw new Error(`dataType "${dataType}" is not supported`);
  };

  private static isValueType(dataType: ICaseDataStixDataType): boolean {
    return (
      dataType === 'url' ||
      dataType === 'email-addr' ||
      dataType === 'ipv4-addr' ||
      dataType === 'domain-name'
    );
  }

  public static buildData(input: ICaseDataStixDataForm) {
    const {
      isIoc,
      dataValue,
      dataType,
      firstObserved,
      mimeType,
      confidence,
      file,
      name,
      displayName,
      description
    } = input;
    const bundleUuid = uuid();
    const objectDataUuid = uuid();
    const indicatorUuid = uuid();
    const sightingUuid = uuid();
    // Stix 2.1 date format must be RFC3339 with utc timezone
    const createdDate = moment().utc().format(RFC3339_UTC_DATE_FORMAT);
    const firstObservedDate = moment(firstObserved)
      .utc()
      .format(RFC3339_UTC_DATE_FORMAT);

    const template = this.getTemplateByDataType(dataType);

    return Mustache.render(template, {
      bundle_uuid: bundleUuid, // v4
      sighting_uuid: sightingUuid, // v4
      creation_timestamp: createdDate,
      modified_timestamp: createdDate,
      sighting_timestamp: firstObservedDate,
      indicator_uuid: indicatorUuid, // v4
      confidence: confidence || confidenceRating[0].value,
      observed_data_uuid: objectDataUuid, // v5
      isIoc,
      ioc_name: name,
      ioc_description: description,

      /* Artifact Specific - Start */
      ...(dataType === 'artifact'
        ? {
            mime_type: mimeType,
            artifact_sco_uuid_v5: this.generateScoUuidForArtifact({
              payload_bin: dataValue
            }),
            artifact_bin: dataValue
          }
        : null),
      /* Artifact Specific - End */

      /* File Specific - Start */
      ...(dataType === 'file'
        ? {
            file_name: dataValue,
            mime_type: mimeType,
            hash_type: file.hashType,
            hash_value: file.hash,
            file_sco_uuid_v5: this.generateScoUuidForFile({
              name,
              hashes: {
                [file.hashType]: file.hash
              }
            })
          }
        : null),
      /* File Specific - End */

      /* Value type Specific - Start */
      ...(this.isValueType(dataType)
        ? {
            sco_type: dataType,
            sco_type_pattern_field: this.getScoTypePatternField(dataType),
            sco_value: dataValue,
            sco_uuid_v5: this.generateScoUuidForValueBaseType(dataValue)
          }
        : null),
      /* Value type Specific - End */

      /* Value type Email Addr Specific - Start */
      ...(dataType === 'email-addr'
        ? {
            sco_display_name: displayName,
            sco_email_message_uuid: uuid() // use v4 since we don't have email message content
          }
        : null)
      /* Value type Email Addr Specific - End */
    });
  }
}
