/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { DataSignificance } from 'interface/DataSignificance.interface';
import { Chip } from '@mui/material';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useHSLColourMap } from 'hooks/HSLColourHooks';
import { grey } from '@mui/material/colors';

export type CydSignificanceDisplayProps = {
  significance: string;
};

const DEFAULT_COLOR = grey[500];
export const useColorForSignificance = (
  significance: DataSignificance | string
): string => {
  const [stringMap, precedenceMap] = useHSLColourMap();

  if (typeof significance === 'string') {
    const color = stringMap[significance];
    return color ?? DEFAULT_COLOR;
  } else {
    if (!significance.precedence) {
      return DEFAULT_COLOR;
    }
    const color = precedenceMap[significance.precedence];
    return color;
  }
};

function addAlphaValueToHslString(inputString: string): string {
  const alpha = 0.05;

  const outputString = inputString.replace(')', `,${alpha})`);
  return outputString;
}

export const CydSignificanceDisplay = (props: CydSignificanceDisplayProps) => {
  const { significance } = props;

  const color = useColorForSignificance(significance);

  return (
    <Chip
      clickable={false}
      label={significance}
      size="small"
      css={css`
        background-color: ${addAlphaValueToHslString(color)};
        color: ${color};
      `}
    />
  );
};

export function CydSignificanceDot(props: { significance: string }) {
  const color = useColorForSignificance(props.significance);
  return (
    <div
      css={css`
        width: 12px;
        height: 12px;
        background-color: ${color};
        border-radius: 50%;

        position: relative;
        left: 1px;
      `}
    />
  );
}
