/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useParams } from 'react-router-dom';
import { useCaseStix } from 'hooks/CaseStixHooks';
/** @jsxImportSource @emotion/react */
import { StixVis } from 'components/_graphVisualisation/StixVix/StixVis';
import { CydNoDataToDisplay } from 'components/_placeholders/CydNoDataToDisplay/CydNoDataToDisplay';

export const CaseViewPageStix: React.FC<{}> = () => {
  const params = useParams();
  const caseUuid = params['caseUuid'];

  if (!caseUuid) {
    throw new Error('No caseUuid in URL params');
  }
  const { data: caseStixData } = useCaseStix(caseUuid);

  return (
    <>
      {caseStixData ? (
        <StixVis caseStixData={caseStixData} />
      ) : (
        <CydNoDataToDisplay
          title="No graph to display"
          body="Try adding stix data to the case first"
          css={(theme) => `
          display: flex;
           justify-content: center;
           align-items: center;
           flex-direction: column;
           height: 100%;
           width: 100%;

        `}
        />
      )}
    </>
  );
};
