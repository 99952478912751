/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { FormObject } from 'states/caseForms/slice';

export interface ICaseFormField {
  name: string;
  type: CaseFormFieldType | '';
  charWidth?: number;
  possibleValues?: Array<string>;
  displayName: string;
}

export type ICaseForm = {
  name: string;
  acl: string;
  uuid: string;
  fields: Array<ICaseFormField>;
  precedence: number;
};

export interface ICaseFormRequestData {
  name: string;
  acl: string;
  fields: Array<ICaseFormField>;
  precedence?: number;
  uuid?: string;
}

export interface ICaseFormAction {
  type: string;
  payload: { caseForm: ICaseFormRequestData };
}
export interface ICaseFormObject {
  type: string;
  payload: { caseForm: FormObject };
}

export enum CaseFormFieldType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DATE_TIME = 'datetime',
  TIME = 'time',
  BOOLEAN = 'boolean',
  ENUM = 'enum',
  USER = 'user',
  ORG = 'org',
  PLAYBOOK = 'playbook',
  ACTION = 'action',
  ASSET = 'asset',
  CONTACT = 'contact'
}
