/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  fetchCurrentUserInfo,
  fetchTotpConfig,
  initialiseTotpConfig,
  removeTotpConfig,
  signIn,
  signOut,
  updateTimezone,
  validateTotpConfig
} from './actions';
import * as TokenManager from 'utils/TokenManager';
import { AUTH_SOURCE } from 'containers/AuthSource';
import { addNotification } from 'states/notifications/slice';
import { ERROR_MESSAGE } from './errors';
import {
  fetchCurrentUserInfoSuccess,
  signInFailed,
  signInSuccess,
  updateTimezoneSuccess,
  fetchTotpConfigSuccess,
  fetchTotpConfigFailed
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  apiDeleteTotp,
  apiFetchCurrentUserInfo,
  apiFetchTotpConfig,
  apiInitialiseTotp,
  apiSignout,
  apiValidateTotp
} from 'services/AuthService';

function* fetchCurrentUserInfoSaga() {
  const sessionUuid: string = TokenManager.getSessionUuid();

  if (!sessionUuid) {
    return;
  }

  try {
    const { json } = yield apiFetchCurrentUserInfo(sessionUuid);

    yield put(fetchCurrentUserInfoSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_CURRENT_USER_INFO_ERROR.message
      })
    );
  }
}

function* performSignOut() {
  const sessionUuid: string = TokenManager.getSessionUuid();
  try {
    yield apiSignout(sessionUuid);
  } catch (ex) {
    console.error(ex);
  }
  TokenManager.clearAccessToken();
  yield put({ type: 'SIGN_OUT_SUCCESS' });
}

function* performSignIn(action) {
  const {
    payload: { username, password, multifactor, authSource }
  } = action;
  try {
    if (authSource === AUTH_SOURCE.INTERNAL.value) {
      let loginPass = password;
      if (!!multifactor) {
        loginPass = loginPass + multifactor;
      }
      yield TokenManager.internalLogin(username, loginPass);
    } else if (authSource === AUTH_SOURCE.CERTIFICATE.value) {
      yield put(
        addNotification({
          message: 'Certificate login has not been implemented yet.'
        })
      );
    } else {
      // Really probably should handle this a better way.
      // Throw an error?
      console.error('Unknown auth source: ' + authSource);
    }
    yield put(signInSuccess());
  } catch (e) {
    yield put(addNotification({ message: 'Login failed, please try again' }));
    yield put(signInFailed());
  }
}

export function* performUpdateTimezone({
  payload: timeZone
}: PayloadAction<string>) {
  try {
    yield put(updateTimezoneSuccess(timeZone));
  } catch (e) {
    yield put(addNotification({ message: 'Failed to update timezone' }));
  }
}

function* performFetchTotpConfig({ payload: userUuid }) {
  try {
    const { json } = yield apiFetchTotpConfig(userUuid);
    yield put(fetchTotpConfigSuccess(json));
  } catch (ex) {
    if (ex.status !== 403) {
      yield put(
        addNotification({
          message: ERROR_MESSAGE.FETCH_TOTP_ERROR.message
        })
      );
    }
    yield put(fetchTotpConfigFailed());
  }
}

function* performInitialiseTotp({ payload }) {
  const { userUuid, resolve } = payload;
  try {
    const { json } = yield apiInitialiseTotp(userUuid);
    yield put(initialiseTotpConfig(json));
    resolve();
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.INITIALISE_TOTP_ERROR.message
      })
    );
  }
}

function* performValidateTotp({ payload }) {
  const { userUuid, totpCode } = payload;
  try {
    yield apiValidateTotp(userUuid, totpCode);
    yield put(
      addNotification({ message: 'Successfully validated 2FA for user.' })
    );
    yield put(fetchTotpConfig(userUuid));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.VALIDATE_TOTP_ERROR.message
      })
    );
  }
}

function* performDeleteTotp({ payload }) {
  const { userUuid } = payload;
  try {
    yield apiDeleteTotp(userUuid);
    yield put(fetchTotpConfig(userUuid));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.REMOVE_TOTP_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchCurrentUserInfo() {
  yield takeLeading(fetchCurrentUserInfo, fetchCurrentUserInfoSaga);
}

function* watchSignOut() {
  yield takeLatest(signOut, performSignOut);
}

function* watchSignIn() {
  yield takeLatest(signIn, performSignIn);
}

function* watchUpdateTimezone() {
  yield takeLatest(updateTimezone, performUpdateTimezone);
}

function* watchFetchTotpConfig() {
  yield takeLeading(fetchTotpConfig, performFetchTotpConfig);
}

function* watchInitialiseTotpConfig() {
  yield takeLeading(initialiseTotpConfig, performInitialiseTotp);
}

function* watchValidateTotpConfig() {
  yield takeLeading(validateTotpConfig, performValidateTotp);
}

function* watchRemoveTotpConfig() {
  yield takeLatest(removeTotpConfig, performDeleteTotp);
}

export default [
  watchFetchCurrentUserInfo(),
  watchSignOut(),
  watchSignIn(),
  watchUpdateTimezone(),
  watchFetchTotpConfig(),
  watchInitialiseTotpConfig(),
  watchValidateTotpConfig(),
  watchRemoveTotpConfig()
];
