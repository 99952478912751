/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { keyframes, css } from '@emotion/react';
import { darken } from '@mui/material/styles';
import {
  hiddenToastPlacement,
  toastPlacement
} from 'components/SnackbarsContainer/styles';
import { PropsWithChildren } from 'react';

/** @jsxImportSource @emotion/react */

const notificationTimer = keyframes`
  from {transform: translateY(0%)}
  to {transform: translateY(100%)}
`;

const slideIn = (number, prevNumber) => {
  let fromY = toastPlacement(prevNumber);
  let toY = toastPlacement(number);
  let fromOpacity = 1;
  let toOpacity = 1;
  let fromScale = `scale(1)`;
  let toScale = 'scale(1)';

  if (number === 3) {
    toOpacity = 0.8;
    toScale = 'scale(0.95)';
    toY = hiddenToastPlacement(3);
  }

  if (prevNumber === 3 || (prevNumber === undefined && number === 3)) {
    fromOpacity = 0.8;
    fromScale = 'scale(0.95)';
    fromY = hiddenToastPlacement(3);
  } else if (prevNumber === undefined) {
    fromOpacity = 0;
    fromY = '100%';
  }

  return keyframes`
    from {transform: translate(-50%, ${fromY}) ${fromScale}; opacity: ${fromOpacity}}
    to {transform: translate(-50%, ${toY}) ${toScale}; opacity: ${toOpacity}}
  `;
};

type ToastNotificationProps = {
  number: number;
  prevNumber?: number;
  expiry: number;
};

export const ToastNotification = ({
  number,
  prevNumber,
  expiry,
  children
}: PropsWithChildren<ToastNotificationProps>) => (
  <div
    data-testid="cyd-toast-notif"
    css={(theme) => css`
      position: fixed;
      background: ${darken(theme.palette.text.primary, 0.4)};
      color: ${theme.palette.background.paper};
      left: 50%;
      bottom: 0;
      border-radius: 8px;
      box-shadow: ${theme.shadows[1]};
      padding: 4px 12px 4px 24px;
      overflow: hidden;
      animation: ${slideIn(number, prevNumber)} 0.3s linear forwards;
      transform: translate(-50%, -${number * 70 + 20}px);
      z-index: ${number === 3 ? 1 : 2};

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: ${theme.palette.text.primary};
        transform: translateY(0px);
        animation: ${notificationTimer} ${expiry}ms linear forwards;
        z-index: -1;
      }
    `}
  >
    {children}
  </div>
);
