/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLeading } from 'redux-saga/effects';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { fetchCaseStatesFailed, fetchCaseStatesSuccess } from './slice';
import { createFetchCaseStates } from './actions';
import { apiPerformFetchCaseStates } from 'services/CaseServices';

export function* performFetchCaseStates() {
  try {
    const { json } = yield apiPerformFetchCaseStates();
    yield put(fetchCaseStatesSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_CASE_STATES_ERROR.message
      })
    );
    yield put(fetchCaseStatesFailed(ERROR_MESSAGE.FETCH_CASE_STATES_ERROR));
  }
}

/* Watchers */
function* watchFetchCaseStates() {
  yield takeLeading(createFetchCaseStates, performFetchCaseStates);
}

export default [watchFetchCaseStates()];
