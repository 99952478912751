/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { FormControl, FormGroup, FormLabel } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import { useDefaultCheckboxStyle } from './style';

interface IDefaultCheckboxOption {
  value: any;
  label: string;
}

export interface IDefaultCheckboxProps extends CheckboxProps {
  label?: string;
  options: IDefaultCheckboxOption[];
  value?: any[] | boolean;
  onChange?: (event: any) => void;
  row?: boolean;
  error?: boolean;
  renderOptionLabel?: (option: IDefaultCheckboxOption) => React.ReactNode;
}

export const DefaultCheckbox: React.FC<IDefaultCheckboxProps> = ({
  label,
  value = [],
  options,
  onChange,
  name,
  row,
  error,
  renderOptionLabel,
  ...props
}) => {
  const handleChange = (_value: any) => (event) => {
    if (!onChange) {
      return;
    }

    if (Array.isArray(value)) {
      const newValue = value.includes(_value)
        ? value.filter((val) => val !== _value)
        : [...value, _value];

      event.target = { value: newValue, name };

      onChange(event);
    }

    if (typeof value === 'boolean') {
      event.target = { value: !value, name };

      onChange(event);
    }
  };

  const {
    label: labelStyle,
    focused,
    checkBox,
    checkBoxChecked
  } = useDefaultCheckboxStyle();

  return (
    <FormControl error={error}>
      {label && (
        <FormLabel classes={{ root: labelStyle, focused }} component="legend">
          {label}
        </FormLabel>
      )}
      <FormGroup row={row}>
        {options.map(({ label: _label, value: _value }) => (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange(_value)}
                checked={
                  Array.isArray(value)
                    ? value.includes(_value)
                    : value === _value
                }
                color="default"
                classes={{
                  root: checkBox,
                  checked: checkBoxChecked
                }}
                {...props}
              />
            }
            key={_label}
            label={
              renderOptionLabel
                ? renderOptionLabel({ label: _label, value: _value })
                : _label
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
