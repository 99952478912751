/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { TextField } from '@mui/material';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useTimezone2 } from 'hooks/AuthHooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { convertTimezonedDateToLocaleDate } from 'utils/DateUtils';

/** @jsxImportSource @emotion/react */
export type CydDateTimePickerProps = {
  initialValue: string | null;
  onComplete: (newDate: string | null) => void;
  required?: boolean;
  label?: string;
  className?: string;
  name?: string;
  makeInitialTimeBeCurrentTime?: boolean;
};

/**
 *
 * - wont respond to initialValue (except on mount)
 *
 * @param props
 * @returns
 */
export const CydDateTimePicker = (props: CydDateTimePickerProps) => {
  const {
    initialValue,
    onComplete,
    required,
    label,
    className,
    name,
    makeInitialTimeBeCurrentTime = false
  } = props;
  const { timezone } = useTimezone2();

  const [tempValue, setTempValue] = useState(null as null | string);

  // Set the initial value
  useEffect(() => {
    if (initialValue) {
      const str = convertTimezonedDateToLocaleDate(initialValue, timezone);
      setTempValue(str.slice(0, str.length - 1)); // -1 = Just trim the Z
    } else if (makeInitialTimeBeCurrentTime) {
      const str = convertTimezonedDateToLocaleDate(
        new Date(Date.now()).toISOString(),
        timezone
      );
      setTempValue(str.slice(0, str.length - 5)); // -5 = trim milliseconds and Z
    } else {
      setTempValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We don't want handleChange to fire everytime onComplete changes
  const onCompleteRef = useRef(onComplete);
  onCompleteRef.current = onComplete;

  // Handle change is responsible for converting the value from the local time, to a timezone adjusted time
  const handleChange = useCallback(
    (newValue: string | null) => {
      if (!newValue) {
        onCompleteRef.current(newValue);
        return;
      }

      const jsDate = new Date(newValue);

      // Invalid date, do nothing
      if (isNaN(jsDate.valueOf())) {
        return;
      }

      const timezoneAdjustedDate = zonedTimeToUtc(jsDate, timezone);
      onCompleteRef.current(timezoneAdjustedDate.toISOString());
    },
    [onCompleteRef, timezone]
  );

  // When tempValue changes, fire the handle change
  useEffect(() => {
    handleChange(tempValue);
  }, [tempValue, handleChange]);

  return (
    <>
      <TextField
        css={(theme) => `

 
          input[type="datetime-local"]::-webkit-calendar-picker-indicator {

            filter: invert(${theme.palette.mode === 'dark' ? 1 : 0});
           }
        `}
        inputProps={
          {
            // You can do validation etc according to the docs here:
            //https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#additional_attributes
          }
        }
        type="datetime-local"
        required={required}
        className={className}
        fullWidth
        name={name}
        autoComplete="off"
        label={label}
        value={tempValue}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          //https://stackoverflow.com/questions/43747521/mobile-safari-10-3-1-datetime-local-enter-a-valid-value-error
          setTempValue(e.target.value.slice(0, 16));
        }}
      />
    </>
  );
};
