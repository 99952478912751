/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  put,
  takeEvery,
  debounce,
  takeLatest,
  select
} from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import {
  createFetchContacts,
  createContact,
  createBulkContacts,
  createDeleteContact,
  createUpdateContact
} from './actions';
import {
  fetchContactsFailed,
  fetchContactsSuccess,
  updateContactSuccess
} from './slice';
import {
  apiCreateBulkContacts,
  apiPerformCreateContact,
  apiPerformDeleteContact,
  apiPerformFetchContacts,
  apiPerformUpdateContact
} from 'services/ContactsService';
import { IUpdateContactAction } from 'interface/Contacts.interface';
import { splitStringToArray } from 'utils/StringUtils';
import { currentUserSelector } from 'states/auth/selectors';

function* performFetchContacts(action) {
  try {
    const { json: results } = yield apiPerformFetchContacts();
    yield put(fetchContactsSuccess(results));
  } catch (ex) {
    yield put(fetchContactsFailed());
    yield put(addNotification({ message: 'Unable to fetch contacts' }));
  }
}

function* performCreateContact(action) {
  const {
    payload: { name, contactType, phone, email, organisation, acl }
  } = action;

  try {
    yield apiPerformCreateContact({
      name,
      contactType,
      phone,
      email,
      organisation,
      acl
    });
    yield put(addNotification({ message: `Contact ${name} has been created` }));
    yield put(createFetchContacts());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to create new contact' }));
  }
}

function* performUpdateContact(action: IUpdateContactAction) {
  const { payload } = action;
  const currentUser = yield select(currentUserSelector);
  const { resolve, ...contactData } = payload;

  try {
    yield apiPerformUpdateContact(contactData.uuid, {
      ...contactData,
      email: splitStringToArray(contactData.email),
      phone: splitStringToArray(contactData.phone),
      userUuid: currentUser?.uuid
    });
    yield put(
      addNotification({
        message: `Contact ${contactData.name} has been updated`
      })
    );
    yield put(updateContactSuccess(contactData));
    resolve && resolve();
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to update contact: ${contactData.name}`
      })
    );
  }
}

function* performDeleteContact(action) {
  const { payload: uuid } = action;
  try {
    yield apiPerformDeleteContact(uuid);
    yield put(addNotification({ message: `Contact ${uuid} has been deleted` }));
    yield put(createFetchContacts());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to delete contact' }));
  }
}

function* performCreateBulkContacts(action) {
  const { file, aclName } = action.payload;
  const formData = new FormData();
  formData.append('upload', file);
  formData.append('aclName', aclName);

  try {
    yield apiCreateBulkContacts(formData);
    yield put(addNotification({ message: 'Successfully Uploaded Contacts' }));
    yield put(createFetchContacts());
  } catch (ex) {
    if (ex.status === 400) {
      const data = yield ex.json() || [];
      const notificationText =
        data?.failedRequests
          ?.map((el) => el.error || '')
          .join(', ')
          .trim() || 'Failed to upload CSV';
      yield put(addNotification({ message: notificationText }));
    } else {
      yield put(addNotification({ message: 'Failed to upload CSV' }));
    }
  }
}

/* Watchers */
function* watchFetchContacts() {
  yield debounce(10, createFetchContacts, performFetchContacts);
  yield takeEvery(createContact, performCreateContact);
  yield takeEvery(createUpdateContact, performUpdateContact);
  yield takeEvery(createDeleteContact, performDeleteContact);
  yield takeLatest(createBulkContacts, performCreateBulkContacts);
}

export default [watchFetchContacts()];
