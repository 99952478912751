/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  createWatchCase,
  createUnWatchCase
} from 'states/watchingCases/actions';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useCurrentUser } from './AuthHooks';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import moment from 'moment';
import { useCaseOpenStatesList } from './deprecated_CaseHooks';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CYDARM_BASE_API } from '../constants';
import {
  CydApi_BaseCase,
  apiFetchWatchingCases,
  apiUnwatchCase,
  apiWatchCase
} from 'services';
import { rtkqUsersApi } from './UserHooks';
export const rtkqWatchingApi = createApi({
  reducerPath: 'rtk-watching',
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),

  tagTypes: ['watch-list'],
  endpoints: (builder) => {
    return {
      getFullWatchList: builder.query<
        Array<CydApi_BaseCase>,
        { userUuid: string }
      >({
        queryFn: async (args) => {
          const result = await apiFetchWatchingCases(args.userUuid);
          return {
            data: result.json
          };
        },
        providesTags: [
          {
            type: 'watch-list',
            id: 'LIST'
          }
        ]
      }),

      watchCase: builder.mutation<
        null,
        { caseUuid: string; userUuid: string; aclUuid: string }
      >({
        queryFn: async (args) => {
          await apiWatchCase(args.caseUuid, {
            case_uuid: args.caseUuid,
            user_uuid: args.userUuid,
            acl_uuid: args.aclUuid
          });

          return {
            data: null
          };
        },
        invalidatesTags: [
          {
            type: 'watch-list',
            id: 'LIST'
          }
        ]
      }),

      unwatchCase: builder.mutation<null, { caseUuid: string }>({
        queryFn: async (args) => {
          await apiUnwatchCase(args.caseUuid);
          return {
            data: null
          };
        },
        invalidatesTags: [
          {
            type: 'watch-list',
            id: 'LIST'
          }
        ]
      })
    };
  }
});

export function useIsWatched(caseUuid: string) {
  const currentUserResult = rtkqUsersApi.endpoints.getCurrentUser.useQuery({});
  const queryResult = rtkqWatchingApi.endpoints.getFullWatchList.useQuery(
    currentUserResult.data
      ? { userUuid: currentUserResult.data.uuid }
      : skipToken
  );

  if (queryResult.data?.find((v) => v.uuid === caseUuid)) {
    return true;
  }

  return false;
}

export function useWatchCaseMutation() {
  const currentUserResult = rtkqUsersApi.endpoints.getCurrentUser.useQuery({});

  const [mutation] = rtkqWatchingApi.endpoints.watchCase.useMutation();

  return useCallback(
    (caseUuid: string, aclUuid: string) => {
      if (!currentUserResult.data) {
        throw new Error('No user available');
      }
      mutation({ caseUuid, userUuid: currentUserResult.data.uuid, aclUuid });
    },
    [currentUserResult.data, mutation]
  );
}

export function useUnwatchCaseMutation() {
  const [mutation] = rtkqWatchingApi.endpoints.unwatchCase.useMutation();

  return useCallback(
    (caseUuid: string) => {
      mutation({ caseUuid });
    },
    [mutation]
  );
}

export const useWatchingCases = () => {
  const currentUser = useCurrentUser();
  return rtkqWatchingApi.endpoints.getFullWatchList.useQuery(
    currentUser.data ? { userUuid: currentUser.data.uuid } : skipToken
  );
};

export const useUserWatchingCases = () => {
  const { data: statuses } = useCaseOpenStatesList();
  const openStatesLabels = statuses.map((v) => v.name);
  const currentUser = useCurrentUser();

  const result = rtkqWatchingApi.endpoints.getFullWatchList.useQuery(
    currentUser.data ? { userUuid: currentUser?.data?.uuid } : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );

  return {
    ...result,
    data: (result.data ?? [])
      .filter(({ status }) => {
        // Logic per: https://cydarm.atlassian.net/browse/RM-2729
        return openStatesLabels.includes(status);
      })
      .sort((caseA, caseB) =>
        moment(caseA.modified).isBefore(moment(caseB.modified)) ? 1 : -1
      )
  };
};

export const useWatchCaseHandlers = (): [
  (caseUuid: string, aclUuid: string, userUuid: string) => void,
  (caseUuid: string) => void
] => {
  const dispatch = useDispatch();

  const watchCaseFn = useCallback(
    (caseUuid: string, aclUuid: string, userUuid: string) => {
      dispatch(createWatchCase({ caseUuid, aclUuid, userUuid }));
    },
    [dispatch]
  );

  const unWatchCaseFn = useCallback(
    (caseUuid: string) => {
      dispatch(createUnWatchCase(caseUuid));
    },
    [dispatch]
  );

  return [watchCaseFn, unWatchCaseFn];
};
