/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useMemo } from 'react';
import {
  FormControl,
  InputBaseProps,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useUniqueId } from 'hooks/useUniqueId';

export type CydMultSelectProps<T> = {
  onChange?: (newOption: Array<T>, newValueStrings: Array<string>) => void;
  availableOptions: Array<T>;
  /**
   * @deprecated - use selectedValues or defaultValues instead
   */
  selectedOptions?: Array<T>;
  /**
   * For controlled component
   */
  selectedValues?: Array<string>;
  /**
   * For uncontrolled component
   */
  defaultValues?: Array<string>;
  generateOptionLabel: (value: T) => string;
  label: string;
  className?: string;
  fullWidth?: boolean;

  size?: InputBaseProps['size'];

  generateValue?: (v: T) => string;
  name?: string;
};

/**
 * This thing is essentially copied pasted from CydSimpleSelect
 *
 * It makes the typing easier to just do one version for singles, and one version for multies
 */

export const CydMultiSelect = <T,>(props: CydMultSelectProps<T>) => {
  const {
    selectedOptions,
    selectedValues,
    defaultValues,
    onChange,
    availableOptions,
    generateOptionLabel,
    label,
    className = '',
    fullWidth = true,
    size,
    generateValue,
    name
  } = props;

  const generateValueToUse = generateValue || generateOptionLabel;

  const valuesLookup = useMemo(() => {
    const map = new Map<string, T>();
    availableOptions.forEach((v) => {
      map.set(generateValueToUse(v), v);
    });
    return map;
  }, [availableOptions, generateValueToUse]);

  // I don't like this
  // But it seems like MUI insists you must attach labels via an ID, rather than wrapping:
  // https://v4.mui.com/components/selects/#accessibility
  // https://github.com/mui/material-ui/issues/16494
  const generatedId = useUniqueId(`multi-select-${label}`);

  return (
    <FormControl
      fullWidth={fullWidth}
      css={css`
        min-width: 100px;
      `}
      className={`${className}`}
    >
      {/* @ts-ignore - until we can get this merged: https://github.com/cydarm/cydarm-frontend/pull/1167 */}
      <InputLabel id={generatedId} size={size}>
        {label}
      </InputLabel>
      <Select
        name={name}
        size={size}
        labelId={generatedId}
        multiple
        label={label}
        value={selectedValues ?? selectedOptions?.map(generateValueToUse)}
        defaultValue={defaultValues}
        onChange={(e) => {
          const valueKeys = e.target.value as string[];

          const values = valueKeys.map((v) => {
            const value = valuesLookup.get(v) as T;

            if (!value) {
              console.warn("Selected value doesn't exist in the valuesLookup", {
                valueKey: valueKeys
              });
            }
            return value;
          });

          onChange?.(values, values.map(generateValueToUse));
        }}
      >
        {availableOptions.map((v) => {
          const optionLabel = generateOptionLabel(v);
          const value = generateValueToUse(v);

          return (
            <MenuItem value={value} key={value}>
              {optionLabel}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
