/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydSimpleTable } from 'components/_dataDisplay/CydSimpleTable/CydSimpleTable';
import React, { useMemo } from 'react';
import { GenericPlaybook, GenericPlaybookResolutionInfo } from '../../type';
import { getVariablesAsRowsAndColumns } from '../../utils/generalNodeStatusUtils';

export type PlaybooksArgsDisplayProps = {
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;
};

export const PlaybooksArgsDisplay = (props: PlaybooksArgsDisplayProps) => {
  const { playbookResolutionInfo, playbook } = props;

  const rowsAndColumns = useMemo(() => {
    const rowsAndColumns = getVariablesAsRowsAndColumns(
      playbook.variablesMap,
      playbookResolutionInfo.resolvedVariables,
      Object.keys(playbook.variablesMap),
      true,
      '(not set)'
    );

    return rowsAndColumns;
  }, [playbookResolutionInfo, playbook]);

  return (
    <div>
      <CydSimpleTable
        rows={rowsAndColumns.rows}
        columns={rowsAndColumns.columns}
        uniquenessId={'label'}
      />
    </div>
  );
};
