/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Button, Fade, Paper, Popper } from '@mui/material';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import React, { useEffect, useRef, useState } from 'react';
import { sendToClipboard } from 'utils/ClipboardUtils';

/** @jsxImportSource @emotion/react */

export type CydCopyClickerProps = React.PropsWithChildren<{
  stringToCopy: string;

  // If narrow target, only clicking the icon will cause the copy behaviour
  narrowTarget?: boolean;
  className?: string;
  buttonLabel?: string;
}>;

export const CydCopyClicker = (props: CydCopyClickerProps) => {
  const {
    stringToCopy,
    children,
    narrowTarget,
    className,
    buttonLabel = 'Copy text'
  } = props;

  /**
   * This component contains a bunch of functionality related to the copy-on-click stuff, have the little message appear etc.
   * Probably we can abstract that stuff to its own component.
   * But I haven't don't that for now, in interests of being practical for this current task.
   */

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      timeoutId = setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isOpen]);

  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Popper
        open={isOpen}
        anchorEl={ref.current}
        placement="right"
        css={(theme) => `
              z-index: 10000;
            `}
      >
        <Fade in={isOpen}>
          <Paper
            elevation={1}
            css={(theme) => `
              padding: ${theme.spacing(1)}; 
              border-radius: ${theme.spacing(1)};
              background-color: ${theme.palette.background.default}; 
              font-size: 10px; 
            `}
          >
            Copied!
          </Paper>
        </Fade>
      </Popper>

      <div className={className}>
        {/* In narrowTarget mode, put the text outside of the button */}
        {narrowTarget && children}
        <Button
          ref={ref}
          disableElevation
          disableRipple
          aria-label={buttonLabel}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            sendToClipboard(stringToCopy);
            setIsOpen(true);
          }}
          css={(theme) => `
 
         &:hover {
           background-color: unset; 
         }
        
         color: ${theme.palette.text.secondary}; 
 
   `}
        >
          {!narrowTarget && children}
          <CydIcon
            icon="copy"
            css={(theme) => `
       width: 12px; 
       height: 12px; 
       margin-left: 2px; 
       
     `}
          />
        </Button>
      </div>
    </>
  );
};
