/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { LinearProgress, Typography } from '@mui/material';
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CacaoBadge } from 'components/_playbooks/CacaoBadge';

// nb. note the typings aren't strictly a playbook instance, because we want this to support standalone actions too.
// Possibly we will rework this in the future.
export type CydPlaybookInstanceDisplayProps = {
  title: string;
  actionStatuses: Array<PlaybookActionInstance>;
  className?: string;
  isCacao?: boolean;
};

export const CydPlaybookInstanceDisplay = (
  props: CydPlaybookInstanceDisplayProps
) => {
  const { title, actionStatuses, className, isCacao } = props;

  return (
    <div
      className={className}
      css={(theme) => `
      margin-bottom: ${theme.spacing(4)}; 
    `}
    >
      <div
        css={(theme) => `
          display: flex;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(1)}; 
        `}
      >
        <Typography
          variant="body2"
          color="text.primary"
          gutterBottom
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1 1 auto;
            width: 200px; //https://stackoverflow.com/questions/17779293/css-text-overflow-ellipsis-not-working
          `}
        >
          {title}
        </Typography>
        {isCacao && <CacaoBadge />}

        <Typography
          variant="caption"
          color="text.secondary"
          align="right"
          gutterBottom
          css={css`
            white-space: nowrap;
          `}
        >
          <span
            css={css`
              margin-left: 1em;
            `}
          >
            {actionStatuses.filter(({ status }) => status === 'success').length}{' '}
            / {actionStatuses.length}
          </span>
        </Typography>
      </div>

      <LinearProgress
        css={(theme) => `
        
        `}
        color="primary"
        variant="determinate"
        value={
          (actionStatuses.filter(({ status }) => status === 'success').length /
            actionStatuses.length) *
          100
        }
      />
    </div>
  );
};
