/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { ICaseMeta } from 'interface/Case.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICaseMetasStoreState {
  metas: ICaseMeta[];
}

const initialState: ICaseMetasStoreState = {
  metas: []
};

const caseMetasSlice = createSlice({
  name: 'caseMetas',
  initialState,
  reducers: {
    fetchMetasSuccess: (state, { payload }: PayloadAction<ICaseMeta[]>) => {
      state.metas = payload;
    },
    createMetasSuccess: (state, { payload }: PayloadAction<ICaseMeta>) => {},
    removeMetasSuccess: (state, { payload }: PayloadAction<ICaseMeta>) => {
      state.metas = state.metas.filter((el) => el.uuid !== payload.uuid);
    }
  }
});

export const {
  reducer: caseMetasStore,
  actions: { fetchMetasSuccess, createMetasSuccess, removeMetasSuccess }
} = caseMetasSlice;
