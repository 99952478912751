import * as CydarmFetch from 'utils/CydarmFetch';

// watcher
export async function apiFetchCaseWatchers(caseUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/watch`);
}

// watchingCases
export async function apiWatchCase(caseUuid: string, caseData: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/watch`, {
    method: 'POST',
    body: JSON.stringify(caseData)
  });
}

export async function apiUnwatchCase(caseUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/watch`, {
    method: 'DELETE'
  });
}
export type CydApi_BaseCase = {
  uuid: string;
  locator: string;
  description: string;
  created: string;
  modified: string;
  org: string;
  assignee: string;
  status: string;
  metadata: null;
  members: unknown[];
  severity: null;
  severityName: string;
  tags: Array<string> | null;
  minSlaSeconds: null;
  minSlaName: string;
  closed: string;
  totalCompletedActionsInAllPlaybooks: number;
  totalActionsInAllPlaybooks: number;
  acl: string;
  readable: boolean;
  editable: boolean;
  manageable: boolean;
  deletable: boolean;
  updateAcls: null;
};

export async function apiFetchWatchingCases(
  userUuid: string
): Promise<CydarmFetch.CyFetchResponse<Array<CydApi_BaseCase>>> {
  return CydarmFetch.cyFetchAuthenticated(`/user/${userUuid}/watch`, {
    method: 'GET'
  });
}
