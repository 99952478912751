/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Box, Tab, Tabs } from '@mui/material';
import { useUniqueId } from 'hooks/useUniqueId';
import React, { useEffect, useState } from 'react';
import { CydTabChip } from './CydTabChip';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydPanelHr } from 'components/_panels/CydPanelHr/CydPanelHr';

export type CydTabPanelsProps = {
  variant?: 'default' | 'chip' | 'side-panel';

  /**
   * This is for accessiblity purposes
   */
  label: string;

  tabs: Array<{
    label: string;
    /**
     * Use this if your label will keep changing, (eg. the label is 'Comments (4)' and that number will change)
     */
    tabKey?: string;
    content: React.ReactNode;
    disabled?: boolean;
  }>;

  initialSelectedTab?: string;

  /**
   * For just for convenience we can remove the tabbing ability and then it behaves just like a box
   * showing the selected tab
   */
  hideTabs?: boolean;

  /**
   * Optionally, if you need to do anything (like reseting forms) when changing tabs.
   * _You do not need to use this to control tab state!_
   */
  onTabChange?: (newTab: string) => void;
};

export type TabPanelProps = {
  children?: React.ReactNode;
  currentValue: string;
  id: string;
  value: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, currentValue, value, id } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== currentValue}
      id={`${id}-tab-panel-${value}`}
      aria-labelledby={`${id}-tab-${value}`}
      css={(theme) => `
      padding-top: ${theme.spacing(4)};
      height: 100%;
    `}
    >
      {value === currentValue && <Box height="100%">{children}</Box>}
    </div>
  );
}

export const CydTabPanels = (props: CydTabPanelsProps) => {
  const {
    variant = 'default',
    tabs,
    label,
    initialSelectedTab = 'Note',
    hideTabs = false,
    onTabChange
  } = props;

  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);

  useEffect(() => {
    setSelectedTab(initialSelectedTab);
  }, [initialSelectedTab]);

  const id = useUniqueId(`tabs-${label}`);

  return (
    <Box position={'relative'} height={'100%'}>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={(e, value) => {
            setSelectedTab(value);

            if (onTabChange) {
              onTabChange(value);
            }
          }}
          css={css`
            ${hideTabs && 'display: none; '}

            ${variant === 'chip' &&
            `

            min-height: auto;
            .MuiTabs-indicator {
               display: none;
            }
            `}
          `}
        >
          {tabs.map((v) => {
            return (
              <Tab
                disabled={v.disabled}
                label={v.label}
                key={v.label}
                value={v.tabKey || v.label}
                id={`${id}-tab-${v.label}`}
                aria-controls={`${id}-tab-panel-${v.label}`}
                css={(theme) => {
                  if (variant === 'side-panel') {
                    return `
                    max-width: 100%; 
                  font-size: ${theme.typography.body1.fontSize}; 
                  flex: 1 0 auto; 
                `;
                  }

                  return ``;
                }}
                //https://github.com/mui/material-ui/issues/31194
                //@ts-ignore
                component={
                  variant === 'chip'
                    ? (props) => {
                        const { className, ...rest } = props; // Pop the class name off
                        return (
                          <CydTabChip
                            label={props.children}
                            isSelected={props['aria-selected']}
                            {...rest}
                          />
                        );
                      }
                    : undefined
                }
              />
            );
          })}
        </Tabs>
      </Box>

      {variant === 'side-panel' && <CydPanelHr disableMargins />}
      {tabs.map((v) => {
        return (
          <TabPanel
            key={v.tabKey || v.label}
            currentValue={selectedTab}
            value={v.tabKey || v.label}
            id={id}
          >
            {v.content}
          </TabPanel>
        );
      })}
    </Box>
  );
};
