/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  useAllNotifications,
  useNotificationDrawerState
} from 'hooks/NotificationHooks';
import {
  memo,
  FC,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo
} from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch } from 'react-redux';
import { openNotificationDrawer } from 'states/notifications/slice';
import { SnackbarsSeeAllButton } from 'components/SnackbarsContainer/SnackbarsSeeAllButton';
import { ToastNotification } from 'components/SnackbarsContainer/ToastNotification';
import { isAuthenticated } from 'utils/TokenManager';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useLocation } from 'react-router';
export interface ISnackbarsContainerProps {
  expiry?: number;
}

export const ToastWrapper: FC<any> = memo(
  ({ number, onClose, expiry, children }) => {
    const prevNumberRef = useRef();
    useEffect(() => {
      prevNumberRef.current = number;
      setTimeout(() => onClose(), expiry);
    });

    return (
      <ToastNotification
        expiry={expiry}
        number={number}
        prevNumber={prevNumberRef.current}
      >
        {children}
      </ToastNotification>
    );
  },
  (prevProps, nextProps) =>
    prevProps.id === nextProps.id && prevProps.number === nextProps.number
);

export const SnackbarsContainer: React.FC<ISnackbarsContainerProps> = ({
  expiry = 3000 // default expiry is 3 seconds
}) => {
  const notifications = useAllNotifications();
  const [closedIDs, setClosedIDs] = useState<string[]>([]);
  const dispatch = useDispatch();
  const showDrawer = useNotificationDrawerState();

  const handleClose = useCallback(
    (id) => () => {
      setClosedIDs((prevIDs) => [...prevIDs, id]);
    },
    []
  );

  const handleOpenNotificationDrawer = () => {
    dispatch(openNotificationDrawer());
  };

  const notificationsToShow = useMemo(
    () =>
      notifications
        .filter(({ id }) => id && !closedIDs.includes(id))
        .slice(0, 4),
    [notifications, closedIDs]
  );

  //if showing notification drawer, add them to the closed list since we can see them on the side
  useEffect(() => {
    if (showDrawer) {
      notificationsToShow.map(({ id = '' }) =>
        setClosedIDs((prevIDs) => [...prevIDs, id])
      );
    }
  }, [showDrawer, notificationsToShow, setClosedIDs]);

  const location = useLocation();
  // Hide the notifs when the user logs out. We don't want them seeing a bunch of toasts
  const isAuth = isAuthenticated() || location.pathname.startsWith('/auth');
  if (!showDrawer) {
    return (
      <div
        css={css`
          display: ${isAuth ? 'initial' : 'none'};
        `}
      >
        {notificationsToShow.map((notification, index) => (
          <ToastWrapper
            number={index}
            id={notification.id}
            key={notification.id}
            onClose={handleClose(notification.id)}
            expiry={expiry}
          >
            {notification.message}
            <IconButton
              onClick={handleClose(notification.id)}
              key="close"
              aria-label="Close"
              color="inherit"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </ToastWrapper>
        ))}
        <SnackbarsSeeAllButton
          amountOfNotifications={notificationsToShow.length}
          onClick={handleOpenNotificationDrawer}
        >
          See All Notifications <NotificationsNoneIcon />
        </SnackbarsSeeAllButton>
      </div>
    );
  } else {
    return null;
  }
};
