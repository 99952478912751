import { cyFetchAuthenticated, CyFetchResponse } from 'utils/CydarmFetch';

export type OrgProp = {
  acl: string;
  encryptionKey: null;
  name: string;
  orgUuid: string;
  uuid: string;
  value: string;
};

export async function apiGetOrgPropsForOrg(
  orgUuid: string
): Promise<CyFetchResponse<Array<OrgProp>>> {
  const result = await cyFetchAuthenticated(`/org-prop/${orgUuid}`);

  return {
    ...result,
    json: result.json.map((v) => {
      return {
        ...v,
        value: atob(v.value)
      };
    })
  };
}
