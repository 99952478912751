import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

/**
 * Use this generate unique IDs. You often need these for labels with MUI.
 * @param prefix
 * @returns
 */
export function useUniqueId(prefix: string): string {
  return useMemo(() => {
    return `${prefix.split(' ').join('.')}-${uuid()}`;
  }, [prefix]);
}

//See
// https://v4.mui.com/components/selects/#accessibility
// https://github.com/mui/material-ui/issues/16494
