/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Card, CardProps } from '@mui/material';
import React from 'react';

/** @jsxImportSource @emotion/react */

export type CydBasicPanelProps = {
  className?: string;
  showBorder?: boolean;
  id?: string;
  elevation?: CardProps['elevation'];
};

export const CydBasicPanel = (
  props: React.PropsWithChildren<CydBasicPanelProps>
) => {
  const { children, className, showBorder = false, ...rest } = props;

  return (
    <Card
      elevation={0}
      className={className}
      css={(theme) => `
        padding: ${theme.spacing(6)}; 
        border: solid 1px ${showBorder ? theme.other.border : 'transparent'};
        border-radius: ${theme.other.panelBorderRadius}; 
        
        margin: ${theme.spacing(3)}; 
      `}
      {...rest}
    >
      {children}
    </Card>
  );
};
