/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  FETCH_PLAYBOOK_ACTIONS_ERROR: {
    code: 'FETCH_PLAYBOOK_ACTIONS_ERROR',
    message: 'Error occurred while fetching playbook actions'
  },
  FETCH_PLAYBOOK_ACTION_BY_UUID_ERROR: {
    code: 'FETCH_PLAYBOOK_ACTION_BY_UUID_ERROR',
    message: 'Error occurred while fetching playbook action with uuid'
  },
  CREATE_PLAYBOOK_ACTION_ERROR: {
    code: 'CREATE_PLAYBOOK_ACTION_ERROR',
    message: 'Error occurred while creating playbook action'
  },
  CREATE_PLAYBOOK_ACTION_TAG_ERROR: {
    code: 'CREATE_PLAYBOOK_ACTION_TAG_ERROR',
    message: 'Error occurred while creating playbook action tag'
  },
  UPDATE_PLAYBOOK_ACTION_ERROR: {
    code: 'UPDATE_PLAYBOOK_ACTION_ERROR',
    message: 'Error occurred while updating playbook action'
  },
  DELETE_PLAYBOOK_ACTION_ERROR: {
    code: 'DELETE_PLAYBOOK_ACTION_ERROR',
    message: 'Error occurred while deleting playbook action'
  },
  DELETE_PLAYBOOK_ACTION_TAG_ERROR: {
    code: 'DELETE_PLAYBOOK_ACTION_TAG_ERROR',
    message: 'Error occurred while deleting playbook action tag'
  }
};

export { ERROR_MESSAGE };
