/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  OasisCacaoPlaybook,
  Variable
} from 'components/_playbooks/CacaoPlaybook';
import {
  CacaoPlaybookRunningInstance,
  GenericRunningInstanceStepStatus
} from 'components/_playbooks/CacaoPlaybookRunningInstance';
import { CydApi_CasePlaybookInstance } from 'interface/Playbook.interface';
import { Node, Edge } from 'reactflow';
import { parseNodeName } from '../CustomNodes/customNodeUtils';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo,
  GenericVariableInfo
} from '../type';
import { positionElements } from './autoLayout';

export type FlowChartData = {
  nodes: Array<Node<GenericNode>>;
  edges: Array<Edge>;
};

type CacaoVariableMap = Record<
  string,
  {
    data: unknown;
    variableInfo: Variable;
  }
>;

export function attachStatusData(
  cacaoPlaybook: OasisCacaoPlaybook,
  cacaoStatuses?: CacaoPlaybookRunningInstance
): GenericPlaybookResolutionInfo {
  if (!cacaoPlaybook.workflow) {
    throw new Error('We expect a workflow to exist');
  }

  const stepEntries = Object.entries(cacaoPlaybook.workflow);

  const statusMap = cacaoStatuses
    ? cacaoStatuses.action_statuses.reduce(
        (acc, cur) => {
          return {
            ...acc,
            [cur.stepId]: cur
          };
        },
        {} as Record<string, GenericRunningInstanceStepStatus>
      )
    : null;

  /**
   * If this is a running instance playbook, get the resolved variables
   */

  let resolvedVariablesMap: CacaoVariableMap;

  //First, go over the playbook and get all the variable info
  resolvedVariablesMap = cacaoPlaybook.playbook_variables
    ? Object.entries(cacaoPlaybook.playbook_variables).reduce((acc, cur) => {
        const [key, value] = cur;
        return {
          ...acc,
          [key]: {
            data: undefined,
            variableInfo: value
          }
        };
      }, {} as CacaoVariableMap)
    : ({} as CacaoVariableMap);

  //nb. in the current example, we are not using step variables, so I haven't put the logic in.
  // Second, go over the action statuses to put the resolved data in.
  if (cacaoStatuses) {
    Object.entries(cacaoStatuses.variableBindings).forEach((v) => {
      const [key, value] = v;

      if (!resolvedVariablesMap[key]) {
        throw new Error(
          `Error constructing resolvedVariablesMap - no object found at key '${key} to assign data into`
        ); // Or just warn and ignore.
      }
      resolvedVariablesMap[key].data = value;
    });

    cacaoStatuses.action_statuses.forEach((actionStatus) => {
      if (!actionStatus.producedData) {
        return;
      }
      const dataEntries = Object.entries(actionStatus.producedData);

      dataEntries.forEach((v) => {
        const [key, value] = v;

        if (!resolvedVariablesMap[key]) {
          throw new Error(
            `Error constructing resolvedVariablesMap - no object found at key '${key} to assign data into`
          ); // Or just warn and ignore.
        }
        resolvedVariablesMap[key].data = value;
      });
    });
  }

  /**
   * Resolve other referenced Cacao data
   *
   * - Target
   */

  // const resolvedTargets =
  //   cacaoPlaybook.targets || ({} as Record<string, Target>);

  // TODO we'll come back to this when we're doing cacao.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const nodes = stepEntries.reduce((acc, v) => {
    const [stepKey] = v;

    let nodeStatus: null | GenericRunningInstanceStepStatus;

    if (statusMap) {
      const foundStatus = statusMap[stepKey];

      if (!foundStatus) {
        throw new Error(`No status found for step with id ${stepKey}`);
      }

      nodeStatus = foundStatus;
    } else {
      nodeStatus = null;
    }

    return {
      ...acc,
      [stepKey]: nodeStatus
    };
  }, {});

  const ourStatusMap: GenericPlaybookResolutionInfo = {
    topLevelPlaybookStatus: {
      tags: [] //todo this needs to be populated some how
    },

    nodeStatuses: (cacaoStatuses?.action_statuses || []).reduce((acc, cur) => {
      return {
        ...acc,
        [cur.stepId]: {
          status: cur.status,
          nodeId: cur.stepId,
          assignee: cur.assignee,
          tags: cur.tags
        }
      };
    }, {}),

    resolvedVariables: Object.entries(resolvedVariablesMap).reduce(
      (acc, cur) => {
        const [key, value] = cur;

        const valueToUse =
          value.data !== undefined ? { value: value.data } : null;
        return {
          ...acc,
          [key]: valueToUse
        };
      },
      {}
    )
  };
  return ourStatusMap;
}

export function generateEdges(nodes: Array<GenericNode>): Array<Edge> {
  const edges = [] as Array<Edge>;

  nodes.forEach((node) => {
    switch (node.nodeType.type) {
      case 'start':
      case 'action':
      case 'playbook-action': {
        const targetId = node.nodeType.nextNode;

        if (targetId) {
          edges.push({
            arrowHeadType: 'arrowclosed',
            id: `${node.nodeId}-${targetId}`,
            source: node.nodeId,
            target: targetId,
            type: 'smooth'
          } as Edge);
        }
        break;
      }
      case 'parallel': {
        const { onParallel, onCompletion } = node.nodeType.nextNode;

        if (!Array.isArray(onParallel)) {
          throw new Error('parallel node expects an array of nextNode');
        }

        onParallel.forEach((parallelId) => {
          edges.push({
            arrowHeadType: 'arrowclosed',
            id: `${node.nodeId}-${parallelId}`,
            source: node.nodeId,
            target: parallelId,
            type: 'smooth',
            sourceHandle: 'bottom'
          } as Edge);
        });
        if (onCompletion) {
          edges.push({
            arrowHeadType: 'arrowclosed',
            id: `${node.nodeId}-${onCompletion}`,
            source: node.nodeId,
            target: onCompletion,
            label: 'completion',
            type: 'smooth',
            sourceHandle: 'right'
          } as Edge);
        }
        break;
      }
      case 'if-condition':
        {
          const { onTrue, onFalse, onCompletion, onSuccess, onFailure } =
            node.nodeType.nextNode;
          if (onTrue) {
            edges.push({
              arrowHeadType: 'arrowclosed',
              id: `${node.nodeId}-${onTrue}`,
              source: node.nodeId,
              target: onTrue,
              label: 'true',
              type: 'smooth',
              sourceHandle: 'bottom'
            } as Edge);
          }
          if (onFalse) {
            edges.push({
              arrowHeadType: 'arrowclosed',
              id: `${node.nodeId}-${onFalse}`,
              source: node.nodeId,
              target: onFalse,
              label: 'false',
              type: 'smooth',
              sourceHandle: 'bottom'
            } as Edge);
          }
          if (onCompletion) {
            edges.push({
              arrowHeadType: 'arrowclosed',
              id: `${node.nodeId}-${onCompletion}`,
              source: node.nodeId,
              target: onCompletion,
              label: 'completion',
              type: 'smooth',
              sourceHandle: 'right'
            } as Edge);
          }
          if (onSuccess) {
            edges.push({
              arrowHeadType: 'arrowclosed',
              id: `${node.nodeId}-${onSuccess}`,
              source: node.nodeId,
              target: onSuccess,
              label: 'success',
              type: 'smooth',
              sourceHandle: 'right'
            } as Edge);
          }
          if (onFailure) {
            edges.push({
              arrowHeadType: 'arrowclosed',
              id: `${node.nodeId}-${onFailure}`,
              source: node.nodeId,
              target: onFailure,
              label: 'failure',
              type: 'smooth',
              sourceHandle: 'left'
            } as Edge);
          }
        }
        break;
      case 'while-condition':
        {
          const { onTrue, onCompletion } = node.nodeType.nextNode;
          edges.push({
            arrowHeadType: 'arrowclosed',
            id: `${node.nodeId}-${onTrue}`,
            source: node.nodeId,
            target: onTrue,
            label: 'true',
            type: 'smooth'
          } as Edge);
          edges.push({
            arrowHeadType: 'arrowclosed',
            id: `${node.nodeId}-${onCompletion}`,
            source: node.nodeId,
            target: onCompletion,
            label: 'false',
            type: 'smooth'
          } as Edge);
        }
        break;
      case 'switch-condition':
        const switchCases = node.nodeType.nextNode as Record<string, string>;
        for (let currentCase in switchCases) {
          edges.push({
            arrowHeadType: 'arrowclosed',
            id: `${node.nodeId}-${switchCases[currentCase]}`,
            source: node.nodeId,
            target: switchCases[currentCase],
            label: 'true',
            type: 'smooth'
          } as Edge);
        }
        break;
      case 'end':
        break;
    }
  });

  return edges;
}

/**
 *
 * The signature on this function might look complicated, but it's pretty straight foward.
 *
 * cacaoStatuses is an optional parameter
 * If it is provided, then the return type of the function includes the cacaoStatus property on each of the nodes.
 *
 * @param cacaoPlaybook
 * @param cacaoStatuses
 * @returns
 */
// export async function cacaoToFlowchart<
//   TStatuses extends CacaoPlaybookRunningInstance | undefined
// >(cacaoPlaybook: Playbook, cacaoStatuses?: TStatuses): Promise<FlowChartData> {
//   const nodes = attachStatusData(cacaoPlaybook, cacaoStatuses);
//   const edges = generateEdges(nodes);
//   const nodesWithPositions = await positionElements(nodes, edges);

//   return {
//     nodes: nodesWithPositions,
//     edges
//   };
// }

export async function attachPositions(
  nodes: Array<GenericNode>,
  mode?: GenericPlaybook['playbookType']
): Promise<FlowChartData> {
  const edges = generateEdges(nodes);

  const reactFlowNodes = nodes.map((v) => {
    const ariaLabel = parseNodeName(v, mode || 'atc');

    return {
      id: v.nodeId,
      ariaLabel,
      data: v,
      type: v.nodeType.type
    };
  });

  const nodesWithPositions = await positionElements(reactFlowNodes, edges);

  return {
    nodes: nodesWithPositions,
    edges
  };
}

export function convertCacaoPlaybookToGenericPlaybook(
  playbook: OasisCacaoPlaybook
): GenericPlaybook {
  const nodes: Array<GenericNode> = Object.entries(playbook.workflow || {}).map(
    ([key, value]) => {
      let nodeType: GenericNode['nodeType'];

      // Common step validation - according to 4.1 in https://docs.oasis-open.org/cacao/security-playbooks/v2.0/cs01/security-playbooks-v2.0-cs01.html#_Toc152256478
      const onCompletion = value.on_completion as any as string;
      const onSuccess = value.on_success as any as string;
      const onFailure = value.on_failure as any as string;

      if (onCompletion && (onSuccess || onFailure)) {
        throw new Error(
          `Node ${key} has both on_completion and on_success/on_failure properties. Only one of these properties should be defined.`
        );
      }

      //nb. we dont' have all the types for Cacao yet, so I haven't implemented the logic
      switch (value.type) {
        case 'start': {
          //For now, at least, we will assume that on_completion always exists
          if (!value.on_completion) {
            throw new Error(
              `Node ${key} did not have an on_completion property`
            );
          }

          nodeType = {
            type: value.type,
            nextNode: value.on_completion,
            data: {
              // The start variables are any marked 'external'
              outArgIds: Object.entries(playbook.playbook_variables ?? {})
                .filter((v) => {
                  const [, value] = v;

                  return value.external;
                })
                .map((v) => {
                  const [key] = v;
                  return key;
                })
            }
          };

          break;
        }
        case 'action': {
          //For now, at least, we will assume that on_completion always exists
          if (!value.on_completion) {
            throw new Error(
              `Node ${key} did not have an on_completion property`
            );
          }
          // validate input/output args
          if (value.in_args != null && !Array.isArray(value.in_args)) {
            throw new Error(`'in_args' must be an array`);
          }
          if (value.out_args != null && !Array.isArray(value.out_args)) {
            throw new Error(
              `'out_args' must be an array: ${typeof value.out_args}`
            );
          }

          nodeType = {
            type: value.type,
            nextNode: value.on_completion,
            data: {
              inArgIds: value.in_args ?? ([] as Array<string>),
              outArgIds: value.out_args ?? ([] as Array<string>)
            }
          };

          break;
        }
        case 'playbook-action': {
          //For now, at least, we will assume that on_completion always exists
          if (!value.on_completion) {
            throw new Error(
              `Node ${key} did not have an on_completion property`
            );
          }

          nodeType = {
            type: value.type,
            nextNode: value.on_completion
          };

          break;
        }
        case 'if-condition': {
          if (!value.on_true) {
            throw new Error(`Node ${key} did not have an on_true property`);
          }
          if (Array.isArray(value.on_true)) {
            throw new Error(`'on_true' may not be an array`);
          }
          if (value.on_false) {
            if (Array.isArray(value.on_false)) {
              throw new Error(`'on_false' may not be an array`);
            }
          }
          const onTrue = value.on_true as string;
          const onFalse = value.on_false as any as string;

          if (!(typeof value.condition === 'string')) {
            throw new Error(`'condition' must be a string`);
          }

          nodeType = {
            type: value.type,
            nextNode: {
              onTrue,
              onFalse,
              onCompletion,
              onSuccess,
              onFailure
            },
            data: {
              condition: value.condition
            }
          };

          break;
        }

        case 'while-condition': {
          if (!value.on_true) {
            throw new Error(`Node ${key} did not have an on_true property`);
          }
          if (value.on_true != null && Array.isArray(value.on_true)) {
            throw new Error(`'on_true' may not be an array`);
          }
          if (
            value.on_completion != null &&
            Array.isArray(value.on_completion)
          ) {
            throw new Error(`'on_completion' may not be an array`);
          }
          const onTrue = value.on_true as any as string;
          const onCompletion = value.on_completion as any as string;

          if (!(typeof value.condition === 'string')) {
            throw new Error(`'condition' must be a string`);
          }

          nodeType = {
            type: value.type,
            nextNode: {
              onTrue,
              onCompletion
            },
            data: {
              condition: value.condition
            }
          };

          break;
        }

        case 'switch-condition': {
          if (typeof value.switch !== 'string') {
            throw new Error(`'condition' must be a string`);
          }

          nodeType = {
            type: value.type,
            nextNode: value.cases ?? ({} as Record<string, string>),
            data: {
              switch: value.switch
            }
          };

          break;
        }

        case 'parallel': {
          if (!value.next_steps) {
            throw new Error(
              `Node ${key} did not have the 'next_steps' property`
            );
          }

          if (value.nextNode != null && !Array.isArray(value.nextNode)) {
            throw new Error(`'nextNode' must be an array`);
          }

          nodeType = {
            type: value.type,
            nextNode: {
              onParallel: value.next_steps ?? ([] as Array<string>),
              onCompletion: value.on_completion
            }
          };

          break;
        }

        case 'end': {
          nodeType = {
            type: value.type,
            nextNode: null
          };

          break;
        }

        default: {
          throw new Error('Node type was not assigned');
        }
      }

      const node: GenericNode = {
        nodeId: key,
        name: value.name || '(unnamed node)',
        nodeType: nodeType,

        description: value.description,
        oldData: {}
      };

      return node;
    }
  );

  const variableInfo = Object.entries(playbook.playbook_variables || {}).reduce(
    (acc, cur) => {
      const [key, value] = cur;
      return {
        ...acc,
        [key]: {
          name: value.description || key,
          type: value.type,
          key: key
        }
      };
    },
    {} as Record<string, GenericVariableInfo>
  );

  return {
    id: playbook.id,
    playbookType: 'cacao',
    nodes,
    title: playbook.name,
    description: playbook.description || '',
    acl: 'TODO ADD ACL', // A cacao playbook is not going to be enough it seems,
    tags: [],
    modified: '',
    created: '',
    variablesMap: variableInfo
  };
}

export function convertCacaoPlaybookAndGenerateStatusData(
  playbook: OasisCacaoPlaybook,
  statusData: CacaoPlaybookRunningInstance
): {
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;
} {
  const playbookResolutionInfo = attachStatusData(playbook, statusData);
  const genericPlaybook = convertCacaoPlaybookToGenericPlaybook(playbook);

  return {
    playbook: genericPlaybook,
    playbookResolutionInfo
  };
}

export function convertCacaoToGenericPlaybookAndPlaybookResolution(
  cacaoPlaybookInstance: CydApi_CasePlaybookInstance,
  playBookTemplate: OasisCacaoPlaybook
): {
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;
} {
  /**
   * The raw action statuses omit the `action--` prefix, so we need to look that up.
   */
  const stepIdMap = Object.keys(playBookTemplate.workflow).reduce(
    (acc, cur) => {
      const shortKey = cur.split('--')[1];
      return {
        ...acc,
        [shortKey]: cur
      };
    },
    {} as Record<string, string>
  );

  const result = convertCacaoPlaybookAndGenerateStatusData(
    playBookTemplate as any,
    {
      playbookUuid: cacaoPlaybookInstance.playbookUuid,
      variableBindings: cacaoPlaybookInstance.variable_bindings,
      action_statuses: cacaoPlaybookInstance.action_statuses.map((v) => {
        return {
          status: v.status as GenericRunningInstanceStepStatus['status'],
          stepId: stepIdMap[v.actionUuid as string],
          producedData: v.variable_bindings,
          assignee: v.assigneeUuid || null,
          tags: [], // note leaving this empty, lets unpick this later
          position: v.position
        };
      })
    }
  );

  cacaoPlaybookInstance.action_statuses.forEach((v) => {
    const foundNode = result.playbook.nodes.find((w) => {
      return w.nodeId.endsWith(v.actionUuid as string);
    });

    if (foundNode) {
      foundNode.oldData = v;
    }
  });

  return result;
}
