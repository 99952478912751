/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as CydarmFetch from 'utils/CydarmFetch';

export async function fetchAllAcls() {
  return CydarmFetch.cyFetchAuthenticated('/acl', {
    method: 'GET'
  });
}

export async function fetchAclByUuid(uuid: string) {
  const result = CydarmFetch.cyFetchAuthenticated(`/acl/${uuid}`, {
    method: 'GET'
  });

  return result;
}

//TODO - better typing here
export async function createAcl(acl: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/acl', {
    method: 'POST',
    body: JSON.stringify(acl)
  });
}

export async function deleteAcl(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/acl/${uuid}`, {
    method: 'DELETE'
  });
}

export async function fetchAclGroups() {
  return CydarmFetch.cyFetchAuthenticated('/group', {
    method: 'GET'
  });
}
