/**
 * This is an interface that matches the parts of the  browser DataTransfer (https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer) class that we need
 * JSDOM hasn't implemented this class, so we can't use it tests
 * See: https://github.com/jsdom/jsdom/issues/1568
 */
export type OurDataTransfer = {
  types: Readonly<Array<string>>;
  getData: (type: string) => string;
};

/**
 * Takes a PasteEvents .clipboardData and convert it to a GFM formatted table string, or null if the data is not table data
 * @param clipboardData
 */
export function convertClipboardDataToGfmTable(
  clipboardData: OurDataTransfer | null
): string | null {
  if (clipboardData === null) {
    return null;
  }
  const data = clipboardData.getData('text/html');

  // We'll wrap this in a big try catch, so if something does go wrong,
  // They'll just get the text/plain rendition
  try {
    // We'll parse the table that is included in the paste event
    // And use that construct a GFM table
    const domParser = new DOMParser();
    const elements = domParser.parseFromString(data, 'text/html');

    const tables = elements.getElementsByTagName('table');
    if (tables.length !== 1) {
      throw new Error('Expected exactly one table');
    }

    const rows = elements.getElementsByTagName('tr');

    let finalGfmString = '';
    for (let i = 0; i < rows.length; i++) {
      const row = rows.item(i);
      if (!row) {
        continue;
      }

      // look for header cells
      const thCells: HTMLCollectionOf<HTMLTableCellElement> =
        row.getElementsByTagName('th');
      const tdCells: HTMLCollectionOf<HTMLTableCellElement> =
        row.getElementsByTagName('td');
      if (!thCells && !tdCells) {
        throw new Error('No cells found');
      }
      if (thCells.length > 0 && tdCells.length > 0) {
        throw new Error('Found both th and td cells');
      }
      const cellTexts = [] as Array<string>;
      // iterate over th cells
      for (let j = 0; j < thCells?.length; j++) {
        const cell = thCells.item(j);
        const cellText = cell?.textContent ?? '';
        cellTexts.push(cellText.trim());
      }
      // iterate over td cells
      for (let j = 0; j < tdCells?.length; j++) {
        const cell = tdCells.item(j);
        const cellText = cell?.textContent ?? '';
        cellTexts.push(cellText.trim());
      }
      // join cell text into row text
      const rowText = `|${cellTexts.join('|')}|\n`;
      finalGfmString += rowText;
      // insert the header line seperator that GFM wants
      if (i === 0) {
        finalGfmString += `|${new Array(cellTexts.length)
          .fill('---')
          .join('|')}|\n`;
      }
    }

    return finalGfmString;
  } catch (err) {
    return clipboardData.getData('text/plain');
  }
}
