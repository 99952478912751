import { Store } from '@reduxjs/toolkit';

import { provideStore as provideStoreCydarmFetch } from '../utils/CydarmFetch';
import { provideStore as provideStoreAddNotif } from '../states/imperitiveAddNotif';
import { provideStore as provideStoreDateUtils } from '../utils/DateUtils';
import { provideStore as provideStoreCaseDataHooks } from '../hooks/CaseDataHooks';
import { provideStore as provideStoreCaseDataSaga } from '../states/cases/sagas';

/**
 * This is an important pattern to avoid circular dependencies anywhere we interact with store directly
 * See:
 *
 * https://github.com/reduxjs/redux-toolkit/issues/1540
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 *
 *
 * @param store
 */
export function provideStore(store: Store) {
  provideStoreCydarmFetch(store);
  provideStoreAddNotif(store);
  provideStoreDateUtils(store);
  provideStoreCaseDataHooks(store);
  provideStoreCaseDataSaga(store);
}
