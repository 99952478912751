/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataAcls } from 'hooks/AclHooks';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { CydCaseDetailsPanelSection } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanelSection';
import { CaseInput } from 'interface/Case.interface';
import { createUpdateCase } from 'states/cases/actions';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { Checkbox, Typography } from '@mui/material';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydAclDisplayById } from 'components/_objectDisplay/CydAclDisplay/CydAclDisplayById';
import { Acl } from 'interface/Acl.interface';

/** @jsxImportSource @emotion/react */

interface CaseViewPageSidebarAclProps {
  aclUuid: string;
  caseUuid: string;
  locator: string;
  disabled?: boolean;
}

type ChangeAclProps = {
  initialAclUuid: string;
  onSubmit: (payload: { acl: string; updateAcls: boolean }) => void;
  onClose: () => void;
  availableAcls: Array<Acl>;
  disabled?: boolean;
};

const ChangeAclDialog = ({
  initialAclUuid,
  onSubmit,
  onClose,
  availableAcls,
  disabled
}: ChangeAclProps) => {
  const [isBulkUpdate, setIsBulkUpdate] = useState<boolean>(false);
  const [selectedAclUuid, setSelectedAclUuid] = useState(initialAclUuid);

  return (
    <CydSimpleFormModal
      isOpen={true}
      title="Update ACL"
      onClose={onClose}
      size="default"
      onFormSubmit={(e) => {
        e.preventDefault();
        onSubmit({ acl: selectedAclUuid, updateAcls: isBulkUpdate });
      }}
    >
      <>
        <CydAclSelector
          autoFocus
          availableAcls={availableAcls}
          selectedAclUuid={selectedAclUuid}
          onChange={(acl, aclUuid) => setSelectedAclUuid(aclUuid)}
          disabled={disabled}
        />

        <div
          css={(theme) => `
display: block; 
  margin: ${theme.spacing(1)} auto;
`}
        >
          <label>
            <Checkbox
              name="apply-acl-to-all-case-data"
              checked={isBulkUpdate}
              onChange={() => {
                setIsBulkUpdate((prevState) => !prevState);
              }}
            />
            <Typography variant="body2" color="text.secondary" display="inline">
              Apply this ACL to all case data
            </Typography>
          </label>
        </div>
        <CydButtonStack justifyContent="flex-end">
          <CydButton variant="contained" type="submit">
            Submit
          </CydButton>
        </CydButtonStack>
      </>
    </CydSimpleFormModal>
  );
};

export const CydCaseViewAclSection = ({
  aclUuid,
  locator,
  caseUuid,
  disabled = false
}: CaseViewPageSidebarAclProps) => {
  const dispatch = useDispatch();
  const { data: aclsData } = useDataAcls();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleUpdateCase = useCallback(
    (data: CaseInput) => {
      dispatch(
        createUpdateCase({
          caseUuid,
          caseData: { locator, ...data }
        })
      );

      setIsOpen(false);
    },
    [dispatch, caseUuid, locator]
  );

  return (
    <CydCaseDetailsPanelSection title={<>ACL</>}>
      <CydAclDisplayById aclId={aclUuid} variant="default-clickable" />
      <CydButton
        variant="outlined"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      >
        Change
      </CydButton>

      {isOpen && (
        <ChangeAclDialog
          availableAcls={aclsData}
          initialAclUuid={aclUuid}
          onClose={() => setIsOpen(false)}
          onSubmit={handleUpdateCase}
          disabled={disabled}
        />
      )}
    </CydCaseDetailsPanelSection>
  );
};
