import * as CydarmFetch from 'utils/CydarmFetch';

// resource
export function apiFetchResourceList() {
  return CydarmFetch.cyFetchAuthenticated('/resource');
}

// saml
export async function apiFetchSamlLoginUrl(location: string) {
  return CydarmFetch.cyFetch(`/auth/saml?redirect=${location}`, {
    method: 'GET'
  });
}

// report
export function apiCreateReport(payloadHeader: unknown, payloadBody: unknown) {
  return CydarmFetch.cyFetchFileAuthenticated('/report/summary', {
    headers: payloadHeader
      ? new Headers({ Accept: `${payloadHeader}` })
      : undefined,
    method: 'POST',
    body: JSON.stringify(payloadBody)
  });
}

export function apiCreateMTTXReport(
  payloadHeader: unknown,
  payloadBody: unknown
) {
  return CydarmFetch.cyFetchFileAuthenticated('/report/mttx', {
    headers: payloadHeader
      ? new Headers({ Accept: `${payloadHeader}` })
      : undefined,
    method: 'POST',
    body: JSON.stringify(payloadBody)
  });
}

export function apiCreateHeatMapReport(
  payloadHeader: unknown,
  payloadBody: unknown
) {
  return CydarmFetch.cyFetchFileAuthenticated('/report/heatmap', {
    headers: payloadHeader
      ? new Headers({ Accept: `${payloadHeader}` })
      : undefined,
    method: 'POST',
    body: JSON.stringify(payloadBody)
  });
}

export function apiCreateAnalystReport(report: unknown) {
  return CydarmFetch.cyFetchFileAuthenticated('/report/analyst', {
    method: 'POST',
    body: JSON.stringify(report)
  });
}

export function apiCreateUserReport() {
  return CydarmFetch.cyFetchFileAuthenticated('/report/user', {
    method: 'POST'
  });
}
export function apiCreateGroupReport() {
  return CydarmFetch.cyFetchFileAuthenticated('/report/group', {
    method: 'POST'
  });
}
