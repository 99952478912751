/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';
import { customScrollbar } from 'utils/StyleUtils';

export const useCaseViewPageReportStyles = makeStyles(
  ({ spacing, shape: { borderRadius }, palette }) => ({
    reportWrapper: {
      padding: spacing(2),
      overflowY: 'auto',
      maxHeight: '100%',
      ...customScrollbar(borderRadius)
    },
    formWrapper: {
      marginTop: spacing(4),
      flexWrap: 'wrap'
    },
    actionsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: spacing(4)
    },
    container: {
      padding: spacing(3),
      borderBottom: `3px solid ${palette.divider}`,
      position: 'relative'
    },
    chipDiv: {
      margin: 3,
      alignItems: 'center'
    },
    linkButton: {
      alignItems: 'center',
      justifyItems: 'center',
      top: 30
    },
    autocomplete: {
      padding: 5,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      margin: 0
    },
    csvDownloadButton: {
      textDecoration: 'none'
    }
  })
);
