import { IStore } from 'states/store.interface';

export const caseTagsSelector = ({ caseTags: { list } }: IStore) => list;

export const caseTagsFilteredSelector = ({ caseTags: { list } }: IStore) =>
  list.filter((t) => t.deprecated !== true);

export const caseTagsHasLoadedSelector = ({
  caseTags: { hasLoaded }
}: IStore) => hasLoaded;

export const caseTagStatusSelector = (store) => {
  const status = store.caseTags.isLoading || store.caseTags.hasLoaded;
  return status;
};
