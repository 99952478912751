/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createAction } from '@reduxjs/toolkit';
import { IAssetBulkCreate, IAssetForm } from 'interface/Assets.interface';

export const createAssetAction = createAction<IAssetForm>('assets/create');
export const createUpdateAssetAction =
  createAction<IAssetForm>('assets/update');
export const createBulkAssetsAction =
  createAction<IAssetBulkCreate>('assets/create/bulk');
export const createDeleteAssetAction = createAction<string>('assets/delete');
