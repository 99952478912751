import * as CydarmFetch from 'utils/CydarmFetch';

// playbookAction
export async function apiFetchPlaybookActions() {
  return CydarmFetch.cyFetchAuthenticated('/playbook-action');
}

export async function apiFetchPlaybookActionByUuid(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook-action/${uuid}`);
}

export async function apiCreatePlaybookAction(playbookAction: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/playbook-action', {
    method: 'POST',
    body: JSON.stringify(playbookAction)
  });
}
export async function apiUpdateAtcPlaybookAction(
  uuid: string,
  playbookAction: unknown
) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook-action/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(playbookAction)
  });
}

export async function apiDeletePlaybookAction(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook-action/${uuid}`, {
    method: 'DELETE'
  });
}

export async function apiCreatePlaybookActionTag(
  actionUuid: string,
  tagUuid: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook-action/${actionUuid}/case-tag/${tagUuid}`,
    {
      method: 'POST'
    }
  );
}
export async function apiDeletePlaybookActionTag(
  actionUuid: string,
  tagUuid: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook-action/${actionUuid}/case-tag/${tagUuid}`,
    {
      method: 'DELETE'
    }
  );
}

// playbooks
export async function apiFetchPlaybooks() {
  return CydarmFetch.cyFetchAuthenticated('/playbook');
}

export async function apiFetchPlaybookByUuid(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook/${uuid}`);
}

export async function apiCreatePlaybook(action: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/playbook', {
    method: 'POST',
    body: JSON.stringify(action)
  });
}

export async function apiCreatePlaybookByFile(fileList: any) {
  return CydarmFetch.cyFetchAuthenticated('/playbook/file/upload', {
    method: 'POST',
    body: fileList
  });
}

export async function apiExportPlaybook(uuid: string) {
  return CydarmFetch.cyFetchFileAuthenticated(`/playbook/file/${uuid}`, {
    method: 'GET'
  });
}

export async function apiUpdatePlaybook(uuid: string, playbook: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(playbook)
  });
}

export async function apiDeletePlaybook(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook/${uuid}`, {
    method: 'DELETE'
  });
}

export async function apiAddActionToPlaybook(
  playbookUuid: string,
  playbookActionUuid: string,
  playbookActionType
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook/${playbookUuid}/playbook-action/${playbookActionUuid}`,
    { method: 'POST', body: JSON.stringify(playbookActionType) }
  );
}

export async function apiRemoveActionFromPlaybook(
  playbookUuid: string,
  playbookActionUuid: string,
  position
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook/${playbookUuid}/playbook-action/${playbookActionUuid}?position=${position}`,
    { method: 'DELETE' }
  );
}

export async function apiInsertActionToPlaybook(
  playbookUuid: string,
  playbookActionUuid: string,
  position
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook/${playbookUuid}/playbook-action/${playbookActionUuid}?position=${position}`,
    {
      method: 'POST',
      body: JSON.stringify({
        atc: {
          precedence: position
        }
      })
    }
  );
}

export async function apiAddTagToPlaybook(
  playbookUuid: string,
  caseTagUuid: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook/${playbookUuid}/case-tag/${caseTagUuid}`,
    { method: 'POST' }
  );
}

export async function apiRemoveTagFromPlaybook(
  playbookUuid: string,
  caseTagUuid: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/playbook/${playbookUuid}/case-tag/${caseTagUuid}`,
    { method: 'DELETE' }
  );
}

export async function apiDuplicatePlaybook(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/playbook/${uuid}`, {
    method: 'POST'
  });
}
