/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { USER_PROPERTY } from '../constants';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  createAddNewUserProperty,
  createUpdateUserProperty
} from 'states/userProps/actions';
import { uniqueArray } from 'utils/ArrayUtils';
import { isValidTimeZone } from 'utils/DateUtils';
import { useCurrentUser } from './AuthHooks';
import { useUserPropNameExists, useUserPropValue } from './UserPropsHooks';

export function useUserFavouriteTimezones(): {
  userFavouriteTimezones: any[];
  addFavouriteTimezone: (timeZoneValue: string) => void;
  removeFavouriteTimezone: (timeZoneValue: string) => void;
} {
  const dispatch = useDispatch();

  const { data: currentUser } = useCurrentUser();
  if (!currentUser) {
    console.warn('unable to locate user in current session');
  }

  const isUserPropFavTimezoneListExists = useUserPropNameExists(
    currentUser?.uuid,
    USER_PROPERTY.FAVOURITE_TIMEZONE_LIST
  );
  const userPropTimezoneList = useUserPropValue(
    currentUser?.uuid || '',
    USER_PROPERTY.FAVOURITE_TIMEZONE_LIST,
    []
  );

  const filteredFavouriteTimezoneListArray = [...userPropTimezoneList].filter(
    (el) => isValidTimeZone(el)
  );

  const handleUpdateUserPropTimezoneList = useCallback(
    (timeZoneValue) => {
      if (!timeZoneValue || !isValidTimeZone(timeZoneValue)) {
        return;
      }
      if (!currentUser) {
        return;
      }
      if (isUserPropFavTimezoneListExists) {
        dispatch(
          createUpdateUserProperty({
            userUuid: currentUser.uuid,
            propName: USER_PROPERTY.FAVOURITE_TIMEZONE_LIST,
            propValue: uniqueArray([
              ...userPropTimezoneList,
              timeZoneValue
            ]).filter((el) => isValidTimeZone(el))
          })
        );
      } else {
        dispatch(
          createAddNewUserProperty({
            userUuid: currentUser.uuid,
            propName: USER_PROPERTY.FAVOURITE_TIMEZONE_LIST,
            propValue: [timeZoneValue]
          })
        );
      }
    },
    [
      userPropTimezoneList,
      currentUser,
      dispatch,
      isUserPropFavTimezoneListExists
    ]
  );

  const handleRemoveFromTimezoneList = useCallback(
    (timeZone: string) => {
      if (!timeZone || !isValidTimeZone(timeZone)) {
        return;
      }
      if (!currentUser) {
        return;
      }
      if (isUserPropFavTimezoneListExists) {
        dispatch(
          createUpdateUserProperty({
            userUuid: currentUser.uuid,
            propName: USER_PROPERTY.FAVOURITE_TIMEZONE_LIST,
            propValue: [...userPropTimezoneList].filter((el) => el !== timeZone)
          })
        );
      }
    },
    [
      currentUser,
      dispatch,
      userPropTimezoneList,
      isUserPropFavTimezoneListExists
    ]
  );

  return {
    userFavouriteTimezones: filteredFavouriteTimezoneListArray,
    addFavouriteTimezone: handleUpdateUserPropTimezoneList,
    removeFavouriteTimezone: handleRemoveFromTimezoneList
  };
}
