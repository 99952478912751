/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

// import and pass a mui icon (for example `import { mdiSpellcheck } from '@mdi/js`)';
// and convert it to an svg html tag with this function
export function iconToSvg(icon: string): string {
  const svgNamespace = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(svgNamespace, 'svg');
  svg.setAttribute('width', '14');
  svg.setAttribute('height', '14');
  svg.setAttribute('viewBox', '0 0 24 24');
  const path = document.createElementNS(svgNamespace, 'path');
  path.setAttribute('d', icon);
  svg.appendChild(path);

  return svg.outerHTML;
}
