/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventTriggerResourceAttributes
 */
export interface EventTriggerResourceAttributes {
    /**
     * The name of the trigger
     * @type {string}
     * @memberof EventTriggerResourceAttributes
     */
    name?: string;
    /**
     * The description of the trigger
     * @type {string}
     * @memberof EventTriggerResourceAttributes
     */
    description?: string;
    /**
     * Whether the trigger is active
     * @type {boolean}
     * @memberof EventTriggerResourceAttributes
     */
    active?: boolean;
    /**
     * The type of event to use to launch the trigger
     * @type {string}
     * @memberof EventTriggerResourceAttributes
     */
    event_type?: string;
    /**
     * The condition defining when the event should be triggered
     * @type {string}
     * @memberof EventTriggerResourceAttributes
     */
    condition?: string;
    /**
     * The arguments to the operation
     * @type {object}
     * @memberof EventTriggerResourceAttributes
     */
    operation_arguments?: object;
}

/**
 * Check if a given object implements the EventTriggerResourceAttributes interface.
 */
export function instanceOfEventTriggerResourceAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventTriggerResourceAttributesFromJSON(json: any): EventTriggerResourceAttributes {
    return EventTriggerResourceAttributesFromJSONTyped(json, false);
}

export function EventTriggerResourceAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTriggerResourceAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'event_type': !exists(json, 'event_type') ? undefined : json['event_type'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
        'operation_arguments': !exists(json, 'operation_arguments') ? undefined : json['operation_arguments'],
    };
}

export function EventTriggerResourceAttributesToJSON(value?: EventTriggerResourceAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'active': value.active,
        'event_type': value.event_type,
        'condition': value.condition,
        'operation_arguments': value.operation_arguments,
    };
}

