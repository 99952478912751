/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { CaseInput, Case } from 'interface/Case.interface';
import { INotificationPayload } from 'states/notifications/slice';
import { createAction } from '@reduxjs/toolkit';

interface ICreateCase {
  caseData: CaseInput;
  resolve?: () => void;
  navigate?: boolean;
}

interface IUpdateCase {
  caseUuid: string;
  caseData: CaseInput;
  successNotification?: INotificationPayload;
  justificationText?: string;
}

interface IUpdateCaseMeta {
  caseUuid: string;
  caseMeta: { [index: string]: any[] | string[] };
  newData: { [index: string]: any[] | string[] };
}

interface IAddOrRemoveMember {
  caseUuid: string;
  memberUuid: string;
  resolve?: (memberUuid: string) => void;
}

interface ITag {
  tagValue: string;
  callback?: () => void;
}
interface IAddTagToCase extends ITag {
  caseUuid: string;
}

export interface IAddTagToCases extends ITag {
  caseUuids: string[];

  onResolve?: () => void;
}

export interface IAssignUser {
  caseUuids: string[];
  username: string;

  onResolve?: () => void;
}

interface IFetchByLocator {
  locator: string;
  resolve: (caseData: Case) => void;
}

export type BatchAssignStatusRequest = {
  caseUuid: Array<Case>;
  caseStatus: string;
  onResolve?: () => void;
};

export const createFetchCases = createAction('cases/fetch');

export const createFetchCaseByUuid = createAction<string>('cases/fetchByUuid');

export const createFetchCaseAndMembersByUuid = createAction<string>(
  'cases/fetchWithMembersByUuid'
);

export const createFetchCaseByLocator = createAction<IFetchByLocator>(
  'cases/fetchByLocator'
);

export const createCase = createAction(
  'cases/create',
  (payload: ICreateCase) => {
    const { caseData, resolve, navigate = true } = payload;
    return {
      payload: {
        caseData,
        resolve,
        navigate
      }
    };
  }
);

export const createBatchUpdateCaseStatus =
  createAction<BatchAssignStatusRequest>('cases/batchUpdateStatus');

export const createUpdateCase = createAction<IUpdateCase>('cases/update');

export const createUpdateCaseMeta =
  createAction<IUpdateCaseMeta>('cases/updateMeta');

/**
 * @deprecated - no longer used
 */
export const createAddMemberToCase =
  createAction<IAddOrRemoveMember>('cases/addMember');

/**
 * @deprecated - no longer used
 */
export const createRemoveMemberFromCase =
  createAction<IAddOrRemoveMember>('cases/removeMember');

export const createAddTagToCase =
  createAction<IAddTagToCase>('cases/addTagToCase');

export const createAddTagToCases = createAction<IAddTagToCases>(
  'cases/addTagToCases'
);

export const createAssignUserToCases =
  createAction<IAssignUser>('cases/assignUser');

export const createRemoveTagFromCase =
  createAction<IAddTagToCase>('cases/removeTag');

export const createFetchCaseSlas = createAction<string>('cases/fetchSlas');
