/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import { SYSTEM_PROPERTY, DISPLAY_NAME } from '../../constants';
import { User } from 'interface/User.interface';

const { MACROS, FALLBACK_FORMAT } = DISPLAY_NAME;

const getGroups = ({ groups: { list = [] } }: IStore) => list;

export const usersSelector = ({ users: { list = [] } }: IStore) => list;

const getCategory = ({ sysprops: { properties } }: IStore) => {
  let sysprop =
    properties.find(
      ({ name }) => name === SYSTEM_PROPERTY.USER_ROLE_CATEGORY
    ) || {};
  return sysprop.value || '';
};

const getNameFormat = ({ sysprops: { properties } }: IStore) => {
  let sysprop =
    properties.find(({ name }) => name === SYSTEM_PROPERTY.NAME_FORMAT) || {};
  return sysprop.value || FALLBACK_FORMAT;
};

const usersWithDisplayName = (
  groups,
  category,
  nameFormat,
  users: User[],
  { filterNonHuman = false, filterDeleted = false } = {
    filterNonHuman: false,
    filterDeleted: false
  }
): User[] => {
  let filteredUsers = users.filter((user) => {
    if (filterNonHuman && !user.human) {
      return false;
    }
    if (filterDeleted && user.deleted) {
      return false;
    }
    return true;
  });
  if (!groups) {
    return filteredUsers;
  }

  //translate the uuid's to full groups
  let usersWithGroups = filteredUsers.map((user) => {
    if (!user.groupUuids) {
      return { ...user, belongsTo: [] };
    }
    return {
      ...user,
      belongsTo: user.groupUuids
        .map((groupUuid) => groups.find(({ uuid }) => uuid === groupUuid))
        .filter((group) => !!group)
    };
  });

  //map, filter, and reduce our way over the users till we have a display name
  return usersWithGroups.map((user) => {
    let userCopy = {
      ...user,
      userRole: user.belongsTo
        .filter((group) => group.category_name === category)
        .map(({ name }) => name)
        .toString(),
      userOrg: user.belongsTo
        .filter((group) => group.category_name === 'organisation')
        .map(({ name }) => name)
        .toString()
    };
    return {
      ...userCopy,
      displayName: MACROS.reduce(
        (acc, macro) => acc.replace(macro[0], userCopy[macro[1]] || '-'),
        nameFormat
      )
    };
  });
};

export const usersWithDisplayNameSelector = createSelector(
  [getGroups, getCategory, getNameFormat, usersSelector, (_, props) => props],
  usersWithDisplayName
);

export const singleUserSelector = createSelector(
  usersWithDisplayNameSelector,
  (_, userUuid) => userUuid,
  (users, userUuid) => {
    return users.find(({ uuid }) => uuid === userUuid);
  }
);

export const singleUserSelectorByUsername = createSelector(
  usersWithDisplayNameSelector,
  (_, username) => username,
  (users, username) => {
    return users.find((user) => user.username === username);
  }
);

export const usersBelongingToOrgSelector = createSelector(
  usersWithDisplayNameSelector,
  (_, orgName) => orgName,
  (users, orgName) =>
    users.filter(({ belongsTo }) => {
      if (!belongsTo) {
        return true;
      }
      const usersOrg = belongsTo
        .filter((group) => group.category_name === 'organisation')
        .map(({ name }) => name);
      return usersOrg.includes(orgName);
    })
);

export const usersStatusSelector = ({
  users: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;

export const disabledUsersSelector = ({ users: { list = [] } }: IStore) =>
  list.filter((el) => el.deleted);

export const usersHasLoadedSelector = ({ users: { hasLoaded } }: IStore) =>
  hasLoaded;

export const usersIsLoadingSelector = ({ users: { isLoading } }: IStore) =>
  isLoading;
