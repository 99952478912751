/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  put,
  takeLatest,
  takeEvery,
  takeLeading,
  call
} from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { ERROR_MESSAGE } from './errors';
import {
  addSystemProperty,
  fetchAllSystemProperty,
  fetchSystemProperty,
  removeSystemProperty,
  updateSystemProperty
} from './actions';
import {
  fetchAllSystemPropSuccess,
  fetchSystemPropertyFailed,
  fetchSystemPropertySuccess,
  removeSystemPropertySuccess
} from './slice';

import {
  createSysProp,
  deleteSysProp,
  fetchAllSysProp,
  fetchSysProp,
  updateSysProp
} from 'services/SystemPropertyService';
import {
  DraftSystemPropertyInterface,
  SystemPropertyInterface
} from 'interface/SystemProperty.interface';

export function* performAddSystemProperty(action: {
  payload: DraftSystemPropertyInterface | Array<DraftSystemPropertyInterface>;
}) {
  const { payload: settingsData } = action;

  try {
    if (Array.isArray(settingsData)) {
      yield call(async () => {
        // nb. There is no recovery if some of these fail!
        // You will have some resources created, and others not.
        return Promise.all(settingsData.map((v) => createSysProp(v)));
      });
    } else {
      yield createSysProp(settingsData);
    }

    yield put(fetchAllSystemProperty());
    yield put(
      addNotification({ message: 'New system property successfully created' })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.CREATE_SYSPROP_ERROR.message })
    );
  }
}

export function* performUpdateSystemProperty(action: {
  payload: SystemPropertyInterface;
}) {
  const { payload: settingData } = action;

  try {
    if (Array.isArray(settingData)) {
      yield call(async () => {
        // nb. There is no recovery if some of these fail!
        // You will have some resources updated, and others not.
        return Promise.all(settingData.map((v) => updateSysProp(v)));
      });
    } else {
      yield updateSysProp(settingData);
    }
    yield put(fetchAllSystemProperty());
    yield put(
      addNotification({ message: 'System property successfully updated' })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.UPDATE_SYSPROP_ERROR.message })
    );
  }
}

export function* performRemoveSystemProperty(action: {
  payload: string | string[];
}) {
  const uuid = action.payload;

  try {
    if (Array.isArray(uuid)) {
      yield call(async () => {
        return Promise.all(uuid.map((v) => deleteSysProp(v)));
      });
    } else {
      yield deleteSysProp(uuid);
    }

    yield put(removeSystemPropertySuccess(uuid));
    yield put(
      addNotification({ message: 'System property successfully removed' })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.DELETE_SYSPROP_ERROR.message })
    );
  }
}

export function* performFetchAllSystemProperty() {
  try {
    const sysProps = yield fetchAllSysProp();
    yield put(fetchAllSystemPropSuccess(sysProps));
  } catch (ex) {
    yield put(fetchSystemPropertyFailed());
  }
}

export function* performFetchSystemProperty({
  payload: name
}: {
  payload: string;
}) {
  try {
    const json = yield fetchSysProp(name);
    yield put(fetchSystemPropertySuccess(json));
  } catch (ex) {
    yield put(fetchSystemPropertyFailed());
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_SYSPROP_ERROR.message })
    );
  }
}

/* Watchers */
function* watchFetchSystemProperty() {
  yield takeEvery(fetchSystemProperty, performFetchSystemProperty);
}

function* watchAddSystemProperty() {
  //@ts-ignore
  yield takeLatest(addSystemProperty, performAddSystemProperty);
}

function* watchUpdateSystemProperty() {
  //@ts-ignore
  yield takeLatest(updateSystemProperty, performUpdateSystemProperty);
}

function* watchRemoveSystemProperty() {
  yield takeLatest(removeSystemProperty, performRemoveSystemProperty);
}

function* watchFetchAllSystemProperty() {
  yield takeLeading(fetchAllSystemProperty, performFetchAllSystemProperty);
}

export default [
  watchFetchSystemProperty(),
  watchAddSystemProperty(),
  watchUpdateSystemProperty(),
  watchFetchAllSystemProperty(),
  watchRemoveSystemProperty()
];
