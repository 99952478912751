/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { CircularProgress } from '@mui/material';
import * as styles from './styles';

export const LoadingScreen = () => {
  const { container } = styles.loadingScreen();

  return (
    <div className={container}>
      <CircularProgress />
    </div>
  );
};
