/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventTriggerResourceAttributes } from './EventTriggerResourceAttributes';
import {
    EventTriggerResourceAttributesFromJSON,
    EventTriggerResourceAttributesFromJSONTyped,
    EventTriggerResourceAttributesToJSON,
} from './EventTriggerResourceAttributes';
import type { EventTriggerResourceRelationships } from './EventTriggerResourceRelationships';
import {
    EventTriggerResourceRelationshipsFromJSON,
    EventTriggerResourceRelationshipsFromJSONTyped,
    EventTriggerResourceRelationshipsToJSON,
} from './EventTriggerResourceRelationships';
import type { ObjectMetadataSet } from './ObjectMetadataSet';
import {
    ObjectMetadataSetFromJSON,
    ObjectMetadataSetFromJSONTyped,
    ObjectMetadataSetToJSON,
} from './ObjectMetadataSet';

/**
 * 
 * @export
 * @interface EventTriggerResource
 */
export interface EventTriggerResource {
    /**
     * 
     * @type {string}
     * @memberof EventTriggerResource
     */
    type?: string;
    /**
     * The UUID of the trigger
     * @type {string}
     * @memberof EventTriggerResource
     */
    id?: string;
    /**
     * 
     * @type {EventTriggerResourceAttributes}
     * @memberof EventTriggerResource
     */
    attributes?: EventTriggerResourceAttributes;
    /**
     * 
     * @type {EventTriggerResourceRelationships}
     * @memberof EventTriggerResource
     */
    relationships?: EventTriggerResourceRelationships | null;
    /**
     * 
     * @type {ObjectMetadataSet}
     * @memberof EventTriggerResource
     */
    meta?: ObjectMetadataSet | null;
}

/**
 * Check if a given object implements the EventTriggerResource interface.
 */
export function instanceOfEventTriggerResource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventTriggerResourceFromJSON(json: any): EventTriggerResource {
    return EventTriggerResourceFromJSONTyped(json, false);
}

export function EventTriggerResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTriggerResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'attributes': !exists(json, 'attributes') ? undefined : EventTriggerResourceAttributesFromJSON(json['attributes']),
        'relationships': !exists(json, 'relationships') ? undefined : EventTriggerResourceRelationshipsFromJSON(json['relationships']),
        'meta': !exists(json, 'meta') ? undefined : ObjectMetadataSetFromJSON(json['meta']),
    };
}

export function EventTriggerResourceToJSON(value?: EventTriggerResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'attributes': EventTriggerResourceAttributesToJSON(value.attributes),
        'relationships': EventTriggerResourceRelationshipsToJSON(value.relationships),
        'meta': ObjectMetadataSetToJSON(value.meta),
    };
}

