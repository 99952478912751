/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { ICaseStix } from 'interface/Case.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchStix } from './actions';

export interface ICaseStixStoreState {
  isFetching: boolean;
  stix: ICaseStix[];
  errors?: ErrorPayload;
}

const initialState: ICaseStixStoreState = {
  stix: [],
  isFetching: false,
  errors: undefined
};

const caseStixSlice = createSlice({
  name: 'caseStix',
  initialState,
  reducers: {
    fetchStixSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.errors = undefined;
      state.stix = payload.objects;
    },

    fetchStixFailed: (state, { payload }: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.errors = payload;
    },

    clearStixData: () => initialState
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchStix, (state) => {
      state.isFetching = true;
      state.errors = undefined;
    })
});

export const {
  reducer: caseStixStore,
  actions: { fetchStixSuccess, fetchStixFailed, clearStixData }
} = caseStixSlice;
