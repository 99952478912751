/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Form, Formik } from 'formik';
import { FormikTextField } from 'components/FormikTextField';
import { string, object } from 'yup';
import {
  usePlaybookActionFormStyles,
  createPlaybookActionField
} from './styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useAtcPlaybookActions } from 'hooks/PlaybookActionsHook';
import { DefaultSelect } from 'components/fields/DefaultSelect';
import { useCurrentDataAcls, useDefaultAclForOrg } from 'hooks/AclHooks';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

export interface IPlaybookActionData {
  description: string;
}

interface IPlaybookActionFormProps {
  onSubmit: (playbookActionData: IPlaybookActionData) => void;
  formRef: React.RefObject<any>;
}

const validationSchema = object({
  name: string().required('Please enter a name'),
  description: string().required('Please enter a description')
});

export const PlaybookCreateActionForm: React.FC<IPlaybookActionFormProps> = ({
  onSubmit,
  formRef
}) => {
  const { data: storeAction } = useAtcPlaybookActions();
  const playbookActionLabel =
    storeAction &&
    storeAction
      .filter((playbook) => playbook.atc != null)
      .map((el) => el.atc?.name);
  const [actionDesc, setActionDesc] = React.useState('');

  const handleSelectedActionDescription = (selectedAction: string) => {
    return (
      storeAction && storeAction.find((el) => el.atc?.name === selectedAction)
    );
  };

  const { fieldWrapper } = usePlaybookActionFormStyles();
  const classes = createPlaybookActionField();

  const { data: aclList } = useCurrentDataAcls();

  const aclOptions = aclList.map((el) => {
    return {
      label: el.description,
      value: el.uuid
    };
  });

  const { data: defaultAcl } = useDefaultAclForOrg();

  const renderForm = ({ handleChange, values, setFieldValue, errors }) => {
    values.acl = values.acl ? values.acl : defaultAcl ? defaultAcl.uuid : '';

    return (
      <Form>
        <div className={fieldWrapper}>
          <Autocomplete
            classes={classes}
            onInputChange={(e, value, reason) => {
              if (reason === 'reset') {
                const selectedAction = handleSelectedActionDescription(value);
                values.description = selectedAction
                  ? selectedAction.atc?.description
                  : values.description;
                setActionDesc(values.description);
              } else {
                setActionDesc(values.description);
              }
              values.name = value;
            }}
            freeSolo={true}
            clearOnEscape={true}
            options={playbookActionLabel}
            renderInput={(params) => (
              <FormikTextField
                {...params}
                variant="outlined"
                data-testid="input-action-name"
                placeholder={'Action name'}
                name={'name'}
                value={actionDesc}
                autoFocus
              />
            )}
          />
        </div>
        <div className={fieldWrapper}>
          <CydEnhancedMarkdownEditor
            name="description"
            label="Description"
            initialValue={values.description}
            onChange={(description) => {
              setFieldValue('description', description);
            }}
            height="260px"
            autoFocus={false}
            error={errors.description}
          />
        </div>
        <DefaultSelect
          options={aclOptions}
          name={'acl'}
          value={values.acl}
          onChange={handleChange}
          placeholder="Select ACL"
          placeholderSelection={false}
        />
      </Form>
    );
  };

  const initialValues = {
    name: '',
    description: ''
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {renderForm}
    </Formik>
  );
};
