/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { useEffect, useMemo, useRef, useState } from 'react';

const chunkArray = (array: [], chunkSize: number) => {
  let newArray: any = [];
  for (var i = 0; i < array.length; i += chunkSize) {
    newArray.push(array.slice(i, i + chunkSize));
  }
  return newArray;
};

//amount of items to load each chunk
const chunkSize = 40;
//pixels before reaching the last element to begin rendering more
const bufferDistance = 500;

export const ScrollView = ({
  children,
  skeletonWrapper
}: {
  children: any;
  skeletonWrapper?: any;
}) => {
  const lastItemRef = useRef<any>();
  const [toShow, setToShow] = useState(1);
  const childrenChunks = useMemo(
    () => chunkArray(children, chunkSize),
    [children]
  );

  const childrenToShow = childrenChunks.slice(0, toShow).flat();

  useEffect(() => {
    const incrementToShow = () => {
      setToShow((prevState) => Math.min(prevState + 1, childrenChunks.length));
    };

    const handleScroll = () => {
      if (
        lastItemRef?.current?.offsetTop <
        window.scrollY + window.innerHeight + bufferDistance
      ) {
        incrementToShow();
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastItemRef, childrenChunks.length]);

  const childrenToRender = childrenToShow.map((child, i) => {
    let isLast = i === childrenToShow.length - 1;
    return (
      <child.type
        {...child.props}
        key={child.key}
        innerRef={isLast ? lastItemRef : null}
        mycustomattribute="something"
      />
    );
  });

  if (skeletonWrapper) {
    return (
      <skeletonWrapper.type {...skeletonWrapper.props}>
        {childrenToRender}
      </skeletonWrapper.type>
    );
  } else {
    return childrenToRender;
  }
};
