/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const CREATE_USER_ACCESS: string = 'cydarm-create-user-access';

/**
 * Takes an create user access token and writes it to session storage
 * @param token
 */
export function setCreateUserAccessToken(access: string): void {
  localStorage.setItem(CREATE_USER_ACCESS, access);
}

/**
 * Returns the current create access token from local storage.
 * If there is no access token, returns an empty string
 */
export function getCreateUserAccessToken(): string {
  return localStorage.getItem(CREATE_USER_ACCESS) || '';
}
