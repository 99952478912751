/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { FC } from 'react';
import Routes from './Routes';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider
} from '@mui/material/styles';

import 'typeface-roboto';
import { SnackbarsContainer } from 'components/SnackbarsContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSessionHook } from 'hooks/AuthHooks';
import { useCustomTheme } from 'theme/Theme';
import { GlobalStyles } from 'theme/globalStyles';
import { NotificationDrawer } from 'components/NotificationDrawer';
import { Alert } from 'components/Alert';

import { checkEnvVarsExist } from 'featureFlags';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { StylesProvider } from '@mui/styles';
import { CydLocalizationProvider } from 'providers/CydLocalizationProvider/CydLocalizationProvider';
import { CydHookProvider } from 'providers/CydHookProvider/CydHookProvider';
import { BrowserRouter } from 'react-router-dom';
import { setupConsoleOverrides } from 'consoleOverrides';

if (
  process.env.REACT_APP_USE_MSW === 'true' &&
  process.env.NODE_ENV === 'development'
) {
  import('./browserWorker').then(({ worker }) => {
    worker.start();
  });
}

if (process.env.NODE_ENV === 'development') {
  setupConsoleOverrides();
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

checkEnvVarsExist();

const App: FC = () => {
  useSessionHook();
  const theme = useCustomTheme();

  return (
    <DndProvider backend={HTML5Backend}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <StylesProvider>
            <ScopedCssBaseline />
            <GlobalStyles />
            <CydLocalizationProvider>
              {/* Important: BrowserRouter needs to be outside of the HookProvider, so that the hooks can access routing hooks */}
              <BrowserRouter>
                {/* Error boundary should be inside browser router, so that anything that is caught by the error boundary, the router still responds to */}
                <ErrorBoundary>
                  <CydHookProvider>
                    <Routes />
                    <SnackbarsContainer />
                    <NotificationDrawer />
                    <Alert />
                  </CydHookProvider>
                </ErrorBoundary>
              </BrowserRouter>
            </CydLocalizationProvider>
          </StylesProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </DndProvider>
  );
};

export default App;
