import { DataSignificance } from 'interface/DataSignificance.interface';
import { User } from 'interface/User.interface';

import { CaseState } from 'interface/CaseState.interface';
import { Group } from 'interface/Group.interface';
import { CaseTag } from 'interface/Tags.interface';
import { Acl } from 'interface/Acl.interface';
import { ICaseSeverity } from 'interface/CaseSeverity.interface';
import { ICaseMeta } from 'interface/Case.interface';
import { Org } from 'interface/Org.interface';
import { Case } from 'interface/Case.interface';
import { PlaybookAction } from 'interface/Playbook.interface';

export const statuses: Array<CaseState> = [
  {
    name: 'Foo',
    description: 'Foo description',
    start: true,
    end: true,
    next: []
  },
  {
    name: 'Bar',
    description: 'BAR description',
    start: true,
    end: true,
    next: []
  },
  {
    name: 'I am a very long status',
    description: 'BAR description',
    start: true,
    end: true,
    next: []
  }
];

export const severities: ICaseSeverity[] = [
  { precedence: 5, name: 'Low', color: '#00ff00' },
  { precedence: 4, name: 'Medium', color: '#ffff00' },
  { precedence: 3, name: 'High', color: '#ffa500' },
  { precedence: 2, name: 'Severe', color: '#ff0000' },
  { precedence: 1, name: 'Emergency', color: '#000000' }
];

export const acls: Array<Acl> = [
  {
    uuid: '6ac6fe3f-4eff-45ce-895c-1f418ee52e2c',
    description: 'user management',
    forData: true,
    deprecated: false,
    aci: [
      {
        uuid: 'acbc56ad-f9b3-4a4d-a4ca-a60de4c845fb',
        permission: {
          uuid: 'e0e8da26-e95c-44c8-a75d-f93ce31a717a',
          label: 'delete'
        },
        group: {
          uuid: '3211a9ee-eba0-49b4-a848-dd1e3bb5b732',
          name: 'user manager',
          //@ts-ignore this is what comes from the server
          description: '',
          category_uuid: '',
          category_name: 'internal',
          category_mode: 'exclusive',
          acl: null,
          created: '0001-01-01T00:00:00Z',
          public_key: null
        }
      }
    ]
  },

  {
    uuid: '7d5bed0e-3f4f-437f-b723-8b74afb26b74',
    description: 'Cydarm case data defaults read-only',
    forData: true,
    deprecated: false,
    aci: [
      {
        uuid: 'ac42fec9-7214-4ce1-9369-be17ba2dbcc5',
        permission: {
          uuid: '946625f3-e878-43df-8b7b-ebacc5df041a',
          label: 'read'
        },
        group: {
          uuid: 'e2dded70-13d6-4cfc-8f95-2e2a4b78f0f4',
          name: 'marketing',
          //@ts-ignore this is what comes from the server

          description: '',
          category_uuid: '',
          category_name: 'org role',
          category_mode: 'inclusive',
          acl: null,
          created: '0001-01-01T00:00:00Z',
          public_key: null
        }
      }
    ]
  }
];

export const tags: Array<CaseTag & { value: string }> = [
  //@ts-ignore this is what comes from API
  {
    uuid: '20008315-f697-4ccc-956c-00c83892e152',
    value: 'mitre-attack:t1211-exploitation_for_defense_evasion',
    description: '',
    acl: 'b5310b28-1fc0-4dc7-87df-8c368adbc763-1',
    deprecated: false
  },
  //@ts-ignore this is what comes from API
  {
    uuid: '461a7163-3bc0-4abe-aa44-a3119954798a',
    value: 'mitre-attack:t1006-file_system_logical_offsets',
    description: '',
    acl: 'b5310b28-1fc0-4dc7-87df-8c368adbc763-2',
    deprecated: false
  },
  //@ts-ignore this is what comes from API
  {
    uuid: 'ta1',
    value: 'simple-tag',
    description: '',
    acl: 'b5310b28-1fc0-4dc7-87df-8c368adbc763-3',
    deprecated: false
  }
];

export const groups: Array<Group> = [
  {
    uuid: 'f0456cd4-fc1e-40dc-b2e1-8a16332383ca',
    name: 'personal-djohnston@cydarm.com',
    description: 'Personal group for user djohnston@cydarm.com',
    //@ts-ignore this is what comes from API
    category_uuid: '',
    category_name: 'personal',
    category_mode: 'inclusive',
    acl: 'd45362d2-ab33-4b47-8454-b371126fb94e',
    created: '2022-02-07T05:03:35.107251Z',
    public_key: null
  },
  {
    uuid: 'dea19e3c-212b-4524-8eb1-e2451327dadb',
    name: 'TLP:WHITE',
    description: 'Disclosure is not limited.',
    //@ts-ignore this is what comes from API
    category_uuid: '',
    category_name: 'TLP',
    category_mode: 'exclusive',
    acl: '6ac6fe3f-4eff-45ce-895c-1f418ee52e2c',
    created: '2020-03-25T12:30:32.269698Z',
    public_key: null
  }
];

export const users: Array<User> = [
  {
    displayName: 'Display Name 1',
    //@ts-ignore this is what comes from API
    Id: 0,
    uuid: '571782f7-63d3-46d2-b291-1c7fdc76b670',
    username: 'admin-role@cydarm',
    human: false,
    email: 'admin124@cydarm.com',
    phone: '+61431094783',
    givenName: 'Admin Privilege',
    familyName: 'The',
    created: '2021-09-29T06:46:35.405632Z',
    authSourceId: 'internal',
    authSourceHandle: 'admin-role@cydarm',
    aclUuid: 'd45362d2-ab33-4b47-8454-b371126fb94e',
    groupUuid: '0f06cd9f-8080-4f02-97b3-17fc161eccda',
    groupUuids: [
      '0f06cd9f-8080-4f02-97b3-17fc161eccda',
      '330effd5-fa7c-496a-ae85-66e13523b736',
      '9facd6ef-6e89-4383-8300-579595773449',
      '5d9b03af-b554-4e40-9553-8c434d68cf90',
      'b1237275-9668-479e-b07d-6e3a2d37ef44',
      '3211a9ee-eba0-49b4-a848-dd1e3bb5b732',
      'd0e386fa-2b46-43f7-97b5-d5a18ac9eb9e',
      'afaa5a0d-bd2d-4024-b792-ca96bfaeada0',
      'e33b6173-ddc8-4b6c-b8a4-06c28c76e114',
      '267533e7-4319-488c-a6cb-c8fbdd4d69dc'
    ],
    org: '614b91d6-4659-4f13-9f75-bb518af8c32e',
    deleted: false
  },
  {
    displayName: 'Display Name 2',
    //@ts-ignore this is what comes from API
    Id: 0,
    uuid: 'ec55bdf0-2b3c-4f69-a146-caec3355ae60',
    username: 'ab',
    human: true,
    email: 'a@b.co',
    phone: '0',
    givenName: 'A',
    familyName: 'B',
    created: '2020-06-21T06:45:08.395229Z',
    authSourceId: 'internal',
    authSourceHandle: 'ab',
    aclUuid: '5d0008bb-fa90-485f-8d40-fc9dcbadd8e3',
    groupUuid: '87fac42c-ab8b-4e47-bfaa-a696348ee0f5',
    groupUuids: [
      '87fac42c-ab8b-4e47-bfaa-a696348ee0f5',
      '330effd5-fa7c-496a-ae85-66e13523b736',
      '9facd6ef-6e89-4383-8300-579595773449',
      'e2dded70-13d6-4cfc-8f95-2e2a4b78f0f4',
      'e33b6173-ddc8-4b6c-b8a4-06c28c76e114'
    ],
    org: '614b91d6-4659-4f13-9f75-bb518af8c32e',
    deleted: false
  }
];

export const testSignificanceLevels: DataSignificance[] = [
  {
    uuid: 'c938bdad-e3e6-464f-9f38-31dad8581929',
    name: 'Disclosure Export',
    selectable: true,
    precedence: 8
  },
  {
    uuid: '74d1f94e-2a20-40e5-8c0f-38b072351dc8',
    name: 'Disclosure Import',
    selectable: true,
    precedence: 7
  },
  {
    uuid: 'ee070ef1-8971-4a47-9e91-3b3d1e7de15b',
    name: 'Significant Action',
    selectable: true,
    precedence: 5
  },
  {
    uuid: 'f39c4b82-dd01-49b3-9f86-67635ba65f05',
    name: 'Significant Decision',
    selectable: true,
    precedence: 4
  },
  {
    uuid: '68a67c5f-3163-4f11-9d42-ddb0da4c2ba3',
    name: 'Significant Finding',
    selectable: true,
    precedence: 3
  },
  {
    uuid: 'bd55ba0c-6ce5-436f-aefd-c83ab22c06ac',
    name: 'Comment',
    selectable: true,
    precedence: 1
  }
];

export const orgs: Array<Org> = [
  {
    uuid: 'dc78f96c-827c-459f-9676-febf5d66adeb',
    name: 'Customer 1',
    groupUuid: '5d9b03af-b554-4e40-9553-8c434d68cf90',
    acl: '055a3c93-541c-4ebd-bedf-127506528e86'
  },
  {
    uuid: '614b91d6-4659-4f13-9f75-bb518af8c32e',
    name: 'Cydarm',
    groupUuid: '9facd6ef-6e89-4383-8300-579595773449',
    acl: '2b2eef27-4928-44b0-b04d-7d2594a6a0da'
  }
];

export const metadata: { [key: string]: ICaseMeta } = {
  'Diamond Model - Infrastructure': {
    uuid: '52ccdc67-d38b-46c0-bf1b-9167fc7fd41c',
    name: 'Diamond Model - Infrastructure',
    value: 'meta value 1',
    integer: 22,
    acl: 'be39aed6-228b-4f4a-b6e6-346003ec15a4'
  },
  'GuardDuty Finding': {
    uuid: '78481ba8-3969-407a-90b8-616455d2aadf',
    name: 'GuardDuty Finding',
    value: 'meta value 2',
    integer: 27,
    acl: 'be39aed6-228b-4f4a-b6e6-346003ec15a4'
  },
  'GuardDuty Finding ID': {
    uuid: 'ef76e8ce-31ee-4a2d-85c0-81ce2a56c8a7',
    name: 'GuardDuty Finding ID',
    value: 'http://www.google.com',
    integer: 27,
    acl: 'be39aed6-228b-4f4a-b6e6-346003ec15a4'
  },
  'Phishing Clickers': {
    uuid: 'a38f2a8f-e4c1-4f67-b5eb-a1ab6966179d',
    name: 'Phishing Clickers',
    value: 'www.google.com',
    integer: 27,
    acl: 'be39aed6-228b-4f4a-b6e6-346003ec15a4'
  }
};
export const cases: Array<Case> = [
  {
    uuid: '782b1db8-480c-412e-b7e2-a364d38c0e26',
    locator: '4GPLVE',
    description: 'AUTO_QA a7f9b1bd-ae99-4c61-a057-8713f2adc285 case title',
    created: '2022-08-18 15:46:03.61988+00:00',
    modified: '2022-08-18 15:46:41.737442+00:00',
    org: 'AutoQA',
    assignee: '',
    status: 'Alert',
    members: [],
    severity: null,
    //@ts-ignore
    severityName: '',
    tags: [],
    //@ts-ignore - this is what is in redux
    minSlaSeconds: null,
    minSlaName: '',
    closed: '',
    totalCompletedActionsInAllPlaybooks: 0,
    totalActionsInAllPlaybooks: 0,
    acl: 'e1139ca9-fe8b-4cf4-ad8b-f71ef1fbffe2',
    readable: true,
    editable: true,
    manageable: true,
    deletable: true,
    updateAcls: null
  },
  {
    uuid: '4e40aec3-387c-4b9e-be47-90b656fb3637',
    locator: 'JJTRAJ',
    description: 'AUTO_QA fe4958ba-216e-4ace-9b0e-c3ceedec5290 case title',
    created: '2022-08-18 15:47:17.64478+00:00',
    modified: '2022-08-18 15:47:17.64478+00:00',
    org: 'AutoQA',
    assignee: '',
    status: 'Alert',
    members: [],
    severity: null,
    //@ts-ignore
    severityName: '',
    tags: [],
    //@ts-ignore - this is what is in redux
    minSlaSeconds: null,
    minSlaName: '',
    closed: '',
    totalCompletedActionsInAllPlaybooks: 0,
    totalActionsInAllPlaybooks: 0,
    acl: 'e1139ca9-fe8b-4cf4-ad8b-f71ef1fbffe2',
    readable: true,
    editable: true,
    manageable: true,
    deletable: true,
    updateAcls: null
  }
];

export const atcPlaybooks = [
  {
    uuid: 'c0366dbf-8543-4b19-859c-9cdd192a981a',
    name: 'Playbook name',
    description: 'aaaaa',
    created: '2022-08-24T02:40:36.21723Z',
    modified: '2022-08-24T02:41:04.157808Z',
    deleted: false,
    acl: 'e84e9374-53e6-4cce-894e-51c9c571b936',
    tags: [],
    actions: []
  },
  {
    uuid: 'c0366dbf-8543-4b19-859c-9cdd192a981a',
    name: 'AAA Playbook',
    description: 'aaaaa',
    created: '2022-08-24T02:40:36.21723Z',
    modified: '2022-08-24T02:41:04.157808Z',
    deleted: false,
    acl: 'e84e9374-53e6-4cce-894e-51c9c571b936',
    tags: [],
    actions: []
  },
  {
    uuid: 'c0366dbf-8543-4b19-859c-9cdd192a981a',
    name: 'ZZZ Playbook',
    description: 'bbbb',
    created: '2022-08-24T02:40:36.21723Z',
    modified: '2022-08-24T02:41:04.157808Z',
    deleted: false,
    acl: 'e84e9374-53e6-4cce-894e-51c9c571b936',
    tags: [],
    actions: []
  }
];

//@ts-ignore
export const actions = [
  {
    uuid: '7c718470-8e71-4419-afd4-421fc8ff155f',
    name: 'Malware C2 Containment',
    description: 'Block the URL, IP address, or payload of the C2 traffic.',
    created: '2019-05-11T04:38:24.955812Z',
    modified: '2019-05-11T04:38:24.955812Z',
    acl: 'b62ccfcd-a385-4075-bfed-c5c1aac4d7fe',
    deleted: false,
    tags: ['mitre-attack:t1501-systemd_service', 'mitre-attack:t1100-web_shell']
  },
  {
    uuid: '440220b9-c264-4a4c-8de8-99057007646b',
    name: 'Malware Post-Incident Activity',
    description:
      'Generate and complete Post-Incident Report for relevant stakeholders.',
    created: '2019-05-11T04:38:24.955812Z',
    modified: '2019-05-11T04:38:24.955812Z',
    acl: 'b62ccfcd-a385-4075-bfed-c5c1aac4d7fe',
    tags: null
  },
  {
    uuid: '706ac973-4cb8-4ec0-8750-eda060eb92f2',
    name: 'Denial of Service Investigation',
    description:
      'Identify the source and type of the packets. Identity which systems are affected and their criticality.',
    created: '2019-05-11T04:38:24.955812Z',
    modified: '2019-05-11T04:38:24.955812Z',
    acl: 'b62ccfcd-a385-4075-bfed-c5c1aac4d7fe',
    tags: null
  }
] as Array<PlaybookAction>;

export const dataSignificances = [
  {
    uuid: 'a4433df7-8d30-4e84-8799-291d59e17033',
    name: 'Comment',
    selectable: true,
    precedence: 1
  },
  {
    uuid: '82f3d9d1-e20e-438c-bd41-819830900e1f',
    name: 'Assignee Change',
    selectable: false,
    precedence: 2
  },
  {
    uuid: '4c3b9d29-9a2f-4265-989b-ffe814118820',
    name: 'Significant Finding',
    selectable: true,
    precedence: 3
  },
  {
    uuid: '504d9bea-c51b-4692-baef-f70f28251456',
    name: 'Significant Decision',
    selectable: true,
    precedence: 4
  },
  {
    uuid: '8e462637-c6ec-4815-a106-f8b6e13ee7cf',
    name: 'Significant Action',
    selectable: true,
    precedence: 5
  },
  {
    uuid: 'c44e6a4c-f160-4191-a158-7d5196e0ad31',
    name: 'Severity Change',
    selectable: false,
    precedence: 6
  },
  {
    uuid: '12173453-2f7a-45a0-be73-75c81239c239',
    name: 'Disclosure Import',
    selectable: true,
    precedence: 7
  },
  {
    uuid: '25e9e3d7-fdbc-4ffb-8621-46f0b99a907e',
    name: 'Disclosure Export',
    selectable: true,
    precedence: 8
  },
  {
    uuid: '389ef016-7b2b-45b8-a335-5ea42f41aafb',
    name: 'Status Change',
    selectable: false,
    precedence: 9
  }
];

export const playbookActionAssignedToMe = [
  {
    Case: 'e278478d-53f5-4b02-88bc-3a736de9d0b5',
    Playbook: '9203478b-468e-4a50-b932-43aeee5b625c',
    PlaybookPrecedence: 1,
    actionUuid: '2411dfed-96de-479b-b704-388893d7408e',
    position: 1,
    actionName: 'action1 8023c50b-f99a-4c9f-8439-90880cedb177',
    actionDescription: 'action1 desc',
    assigneeUuid: '24ae80bc-4e2b-4134-a4bb-e6a9ae742bdf',
    assignedAt: '2023-09-03T23:55:41.736668Z',
    status: 'ready',
    statusAt: '',
    statusMsg: '',
    created: '2023-09-03T16:12:16.494159Z',
    variable_bindings: {},
    tags: null,
    case_playbook_uuid: 'a3345eaa-5623-4d28-8bd1-1a1cbe0628ee',
    actionInstanceUuid: '03b0cda8-8196-48ec-86db-5c455b57075a'
  },
  {
    Case: 'e278478d-53f5-4b02-88bc-3a736de9d0b5',
    Playbook: '9203478b-468e-4a50-b932-43aeee5b625c',
    PlaybookPrecedence: 1,
    actionUuid: '9d6666fa-5dde-4514-b06d-ce23546a7afc',
    position: 2,
    actionName: 'action2 8023c50b-f99a-4c9f-8439-90880cedb177',
    actionDescription: 'action2 desc',
    assigneeUuid: '24ae80bc-4e2b-4134-a4bb-e6a9ae742bdf',
    assignedAt: '2023-09-03T23:55:41.736668Z',
    status: 'ready',
    statusAt: '',
    statusMsg: '',
    created: '2023-09-03T16:12:16.494159Z',
    variable_bindings: {},
    tags: null,
    case_playbook_uuid: 'a3345eaa-5623-4d28-8bd1-1a1cbe0628ee',
    actionInstanceUuid: 'ae882abb-482c-4326-9176-d354a05e6922'
  },
  {
    Case: '05b09ff8-6ce8-4d4f-b5d1-8997866768ca',
    Playbook: '96308b97-f956-45a2-b8f6-c5b4c2c64e0c',
    PlaybookPrecedence: 1,
    actionUuid: '794f09af-12e2-4faf-9929-cc07185280ba',
    position: 1,
    actionName: 'a1 e53a8053-63ca-40f9-88ac-c93a820cf01f',
    actionDescription: 'a1 desc',
    assigneeUuid: '24ae80bc-4e2b-4134-a4bb-e6a9ae742bdf',
    assignedAt: '2023-09-03T23:55:41.736668Z',
    status: 'ready',
    statusAt: '',
    statusMsg: '',
    created: '2023-09-03T16:14:57.258825Z',
    variable_bindings: {},
    tags: null,
    case_playbook_uuid: 'a5d10165-9e16-4ff9-ad8d-eabbb1114708',
    actionInstanceUuid: '0763120b-6405-404c-8c8a-4b0e20e569c4'
  },
  {
    Case: '3c69a80e-5e25-4f08-908a-d157a6846265',
    Playbook: '0fd7bdcc-30d3-4372-9abc-b7d05494a6d5',
    PlaybookPrecedence: 1,
    actionUuid: 'c63d7054-0319-4e5d-befa-01fd43223cda',
    position: 1,
    actionName: 'RA_0001_identification_get_original_email',
    actionDescription:
      "Obtain original phishing email\n\nStage: identification \nhttps://www.lifewire.com/save-an-email-as-an-eml-file-in-gmail-1171956\nhttps://eml.tooutlook.com/ \n\nObtain original phishing email from on of the available/fastest options:\n- Email Team/Email server: if there is such option\n- Person who reported the attack (if it wasn't detected automatically or reported by victims)\n- Victims: if they were reporting the attack\nAsk for email in `.EML` format. Instructions: \n  1. Drug and drop email from Email client to Desktop\n  2. Send to IR specialists by <email>\n",
    assigneeUuid: '24ae80bc-4e2b-4134-a4bb-e6a9ae742bdf',
    assignedAt: '2023-09-03T23:55:41.736668Z',
    status: 'ready',
    statusAt: '',
    statusMsg: '',
    created: '2023-09-03T23:55:37.24947Z',
    variable_bindings: {},
    tags: null,
    case_playbook_uuid: 'e91c456a-32c6-4d39-816d-b48d1d5c9214',
    actionInstanceUuid: '2fc7b6d2-bda8-456c-af76-c7a438475c40'
  }
];
