/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useRegisterHotkeyListener } from 'hooks/HotkeyHooks';
import {
  useCommentDrawerState,
  useCommentModeState
} from 'hooks/CaseActivitiesUIHooks';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import ActivityViewDropzone from 'components/Dropzone/ActivityViewDropzone';
import { Case } from 'interface/Case.interface';

type ActivityViewProps = React.PropsWithChildren<{
  className?: string;
  cydCase: Case;
}>;

const ActivityView = (props: ActivityViewProps) => {
  const { cydCase, children, className } = props;
  const [isOpened, setIsOpened] = useCommentDrawerState();
  const [, setMode] = useCommentModeState();

  const toggleDrawer = (open, mode = 'comment') => {
    if (open === isOpened) {
      return;
    } //prevent drawer changing when one is open
    setIsOpened(open);
    setMode(mode);
  };

  useRegisterHotkeyListener('1', () => toggleDrawer(true, 'comment'));
  useRegisterHotkeyListener('2', () => toggleDrawer(true, 'stix'));
  useRegisterHotkeyListener('3', () => toggleDrawer(true, 'forms'));
  useRegisterHotkeyListener('Escape', () => toggleDrawer(false), false);

  return (
    <div
      data-testid="cyd-activityview"
      className={className}
      id="activity-view"
    >
      {/*
  Dropzone needs to be 100% height, so a drag onto a onto empty case thread is still nice and big
*/}
      <ActivityViewDropzone
        css={css`
          height: 100%;
          width: 100%;
          min-height: 400px;
        `}
        cydCase={cydCase}
        active={!isOpened}
      >
        {children}
      </ActivityViewDropzone>
    </div>
  );
};

export default ActivityView;
