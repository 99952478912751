/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { CaseViewPagePlaybooksAddPopup } from './CaseViewPagePlaybooksAddPopup';
import { usePopovers } from 'hooks/PopoverHooks';
import { useDispatch } from 'react-redux';
import { addPlaybookToCase } from 'states/casePlaybooks/actions';
import {
  AtcPlaybook,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { useCasePlaybooks } from 'hooks/CasePlaybooksHooks';
import { CasePlaybookList } from 'components/CasePlaybookList';
import { useStyles } from './styles';
import { PlaybooksPageNewActionPopover } from 'pages/PlaybooksPage/PlaybooksPagePlaybooks/PlaybooksPageNewActionPopover';
import { useAtcPlaybookActions } from 'hooks/PlaybookActionsHook';
import { createPlaybookAction } from 'states/playbookActions/actions';
import { createAddActionToCase } from 'states/caseActions/actions';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { useParams } from 'react-router';
import { useCaseViewPageContext } from '../CaseViewPage';

const caseViewPlaybookPopover = {
  ADD_PLAYBOOKS: 'ADD_PLAYBOOKS',
  ADD_ACTIONS: 'ADD_ACTIONS'
};

export function CaseViewPagePlaybooks() {
  const caseUuid = useParams()['caseUuid'];

  if (!caseUuid) {
    throw new Error('No caseUuid in URL params');
  }

  const { cydCase } = useCaseViewPageContext();
  const { data: casePlaybooks } = useCasePlaybooks(caseUuid);
  const { data: allPlaybookAction } = useAtcPlaybookActions();
  const dispatch = useDispatch();

  const { openPopover, bindPopoverProps, anchorRefs } = usePopovers({
    [caseViewPlaybookPopover.ADD_PLAYBOOKS]: undefined
  });

  const {
    openPopover: openActionPopover,
    closePopover: closeActionPopover,
    bindPopoverProps: bindActionPopover,
    anchorRefs: actionAnchorRefs
  } = usePopovers({
    [caseViewPlaybookPopover.ADD_ACTIONS]: undefined
  });

  const handleOpenPlaybookPopover = React.useCallback(() => {
    openPopover(caseViewPlaybookPopover.ADD_PLAYBOOKS);
  }, [openPopover]);

  const handleOpenActionsPopover = React.useCallback(() => {
    openActionPopover(caseViewPlaybookPopover.ADD_ACTIONS);
  }, [openActionPopover]);

  const handleAddPlaybookToCase = (playbook: AtcPlaybook) => {
    if (!playbook.uuid || !cydCase) {
      return;
    }
    dispatch(
      addPlaybookToCase({
        caseUuid: cydCase.uuid,
        playbookUuid: playbook.uuid
      })
    );
  };

  const handleAddActionToCase = (data: PlaybookActionInstance) => {
    const duplicateAction =
      allPlaybookAction &&
      allPlaybookAction.map((el) => el.atc?.name).indexOf(data.name) !== -1;

    if (duplicateAction) {
      const actionUuid = allPlaybookAction.find(
        (el) => el.atc?.name === data.name
      );
      actionUuid &&
        dispatch(
          createAddActionToCase({
            caseUuid,
            actionUuid: actionUuid.atc?.uuid ?? ''
          })
        );
    } else {
      dispatch(
        createPlaybookAction({
          playbookAction: { atc: data },
          resolve: ({ uuid }) => {
            if (!caseUuid || !uuid) {
              return;
            }

            dispatch(createAddActionToCase({ caseUuid, actionUuid: uuid }));
          }
        })
      );
    }
    closeActionPopover(caseViewPlaybookPopover.ADD_ACTIONS);
  };

  const { fullHeight } = useStyles();

  return (
    <div className={fullHeight}>
      <CydButtonStack justifyContent="flex-end">
        <CydButton
          variant="contained"
          onClick={handleOpenPlaybookPopover}
          ref={anchorRefs[caseViewPlaybookPopover.ADD_PLAYBOOKS]}
          startIcon="add"
        >
          Add a playbook
        </CydButton>
        <CydButton
          variant="contained"
          onClick={handleOpenActionsPopover}
          ref={actionAnchorRefs[caseViewPlaybookPopover.ADD_ACTIONS]}
          startIcon="add"
        >
          Add an action
        </CydButton>
      </CydButtonStack>

      {cydCase && (
        <CasePlaybookList case={cydCase} casePlaybooks={casePlaybooks} />
      )}
      <CaseViewPagePlaybooksAddPopup
        onSubmit={handleAddPlaybookToCase}
        {...bindPopoverProps(caseViewPlaybookPopover.ADD_PLAYBOOKS)}
      />
      <PlaybooksPageNewActionPopover
        onSubmit={handleAddActionToCase}
        {...bindActionPopover(caseViewPlaybookPopover.ADD_ACTIONS)}
      />
    </div>
  );
}
