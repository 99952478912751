/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import React from 'react';

import { useMemo } from 'react';
import { createFetchAclAction } from 'states/acl/actions';
import { Acl, AclPermissionLabel, IAclAci } from 'interface/Acl.interface';
import {
  sortedAclsSelector,
  sortedDataAclsSelector,
  aclsStatusSelector,
  dataDefaultAclSelector,
  aclByUuidSelector,
  sortedNonDeprecatedDataAclsSelector,
  sortedNonDeprecatedAclsSelector,
  aclIsLoadingSelector
} from 'states/acl/selectors';
import { useAutoEffect } from './ReduxHooks';
import { useSingleResource } from './ResourceHook';
import { useCurrentUser } from './AuthHooks';
import { ResourceName } from 'interface/Resource.interface';
import { ICaseData } from 'interface/CaseData.interface';
import { createNoop } from 'utils/Misc';
import { acls } from 'testData/justData/data';
import { ACLS } from '../constants';
import { ORG_PROP_TYPES, usePrimaryOrgProp } from 'hocs/OrgPropsHooks';
import { LoadingData } from 'types/LoadingData';
import { useSingleCaseRtkq } from './CaseHooksRq';

/**
 * @deprecated - this function returns all ACLs, included deleted and non-for-data acls, you probably want to use useCurrentDataAcls!
 * @returns
 */
export const useAcls = () =>
  useAutoEffect({
    selector: sortedAclsSelector,
    ac: createFetchAclAction,
    statusSelector: aclsStatusSelector,
    isLoadingSelector: aclIsLoadingSelector,
    hasLoadedSelector: (store) => store.acl.hasLoaded
  });

const commonAclAutoEffect = {
  ac: createFetchAclAction,
  statusSelector: aclsStatusSelector,
  isLoadingSelector: aclIsLoadingSelector,
  hasLoadedSelector: (store) => store.acl.hasLoaded
};

/**
 * @deprecated - this function returns all for-data ACLs, included deleted acls, you probably want to use useCurrentDataAcls!
 * @returns
 */
export const useDataAcls = () =>
  useAutoEffect({
    selector: sortedDataAclsSelector,
    ...commonAclAutoEffect
  });

/**
 * @deprecated - use useDefaultAcl2
 * @returns
 */
export const useDefaultAcl = (): null | Acl => {
  const { data: aclList } = useDataAcls();

  if (!aclList) {
    return null;
  }

  const defaultAcl =
    aclList && aclList.find((el) => el.description === ACLS.CASE_DATA_DEFAULT);
  return defaultAcl || null;
};

/**
 * This function gets the suggests the default ACL for a given context, by looking at the current user's primary org's org props.
 * @param context
 * @returns
 */
export function useDefaultAclForOrg(
  context: ORG_PROP_TYPES = 'CASE_DEFAULTS_ACL'
): LoadingData<Acl | null> {
  const defaultAclUuidResult = usePrimaryOrgProp(context);
  const aclResult = useAclByUuid(defaultAclUuidResult.data?.value);
  return aclResult;
}

/**
 * Gives you the ACL that is assigned to the case
 * @param caseUuid
 * @returns
 */
export const useCaseAcl = (caseUuid: string): LoadingData<Acl> => {
  const caseResult = useSingleCaseRtkq(caseUuid);
  const caseData = caseResult.data;

  const aclResult = useAclByUuid(caseData?.acl);

  if (caseResult.isLoading || aclResult.isLoading) {
    return {
      isLoading: true,
      hasLoaded: false,
      data: null
    };
  }

  //WARNING!
  // Behaviour here will actually be a little buggy/not meeting the above typing.
  // There is a flash where isLoading is false, but also hasLoaded is false, and and so no case/no case acl will be found.
  // I tried to unpick what was happening in the Case/ACl redux stuff, to no avail.
  // One more reason to move to React-Query.

  return {
    isLoading: false,
    hasLoaded: true,
    data: aclResult.data as Acl
  };
};

/**
 * Note that this function also returns non-for-data acls. Delete ACLs are not included.
 * @returns
 */
export const useCurrentAcls = () =>
  useAutoEffect({
    selector: sortedNonDeprecatedAclsSelector,
    ...commonAclAutoEffect
  });

/**
 * This is the ACL selector you likely want to use when populating ACL selectors. It returns just non-deleted for-data ACLs
 * @returns
 */
export const useCurrentDataAcls = () =>
  useAutoEffect({
    selector: sortedNonDeprecatedDataAclsSelector,
    ...commonAclAutoEffect
  });

export const useAclByUuid = (uuid?: string): LoadingData<Acl | null> => {
  const result = useAutoEffect({
    selector: aclByUuidSelector,
    selectorData: uuid,
    ...commonAclAutoEffect
  });
  // no uuid provided
  if (!uuid) {
    return {
      isLoading: false,
      hasLoaded: true,
      data: null
    };
  }
  if (!result.data) {
    // still loading
    return {
      isLoading: true,
      hasLoaded: false,
      data: null
    };
  } else {
    // finished loading
    return {
      data: result.data,
      isLoading: false,
      hasLoaded: true
    };
  }
};

export const useCheckHasPermissionByResource = (
  resourceName: ResourceName,
  permissionLabels: Array<AclPermissionLabel>
): boolean => {
  const { data: singleResource } = useSingleResource(resourceName);
  const { data: aclFound } = useAclByUuid(singleResource?.Acl || '');
  const { data: currentUser } = useCurrentUser();

  if (!currentUser) {
    return false;
  }

  return singleResource
    ? !!aclFound?.aci.find(
        (aci) =>
          currentUser?.groupUuids.includes(aci.group.uuid) &&
          permissionLabels.includes(aci.permission.label)
      )
    : false;
};

type ObjectsThatHaveAccessPermissions = ICaseData; // Extend this later with |
export function useCheckAccessPermissionsForObjects(): (
  object?: ObjectsThatHaveAccessPermissions
) => {
  canDelete: boolean;
  canUpdate: boolean;
  canManage: boolean;
} {
  return (object) => {
    return {
      canDelete: object?.deletable || false,
      canUpdate: object?.editable || false,
      canManage: object?.manageable || false
    };
  };
}

/**
 * @deprecated - This function returns all non-deleted non-for-data acls that end with 'case data defaults' (which have since been deleted). You probably want to use useCurrentDataAcls!
 * @returns
 */
export const useDefaultDataAcl = () =>
  useAutoEffect({
    selector: dataDefaultAclSelector,
    ...commonAclAutoEffect
  });

export const useAclAciGroup = (
  { aci }: Acl,
  nameFormat = false
): { [index: string]: IAclAci[] } =>
  useMemo(
    () =>
      aci &&
      aci.reduce(
        (acc, singleAci) => ({
          ...acc,
          [singleAci.permission.label]: [
            ...(acc[singleAci.permission.label] || []),
            nameFormat ? singleAci.group.name : singleAci
          ]
        }),
        {}
      ),
    [aci, nameFormat]
  );

type AclHook = {
  //nb. I haven't pulled all of these over.
  // Just testing that this works first. We can move the others over as we need.
  getAcls: typeof useAcls;
  getDataAcls: typeof useDataAcls;
  getAclByUuid: typeof useAclByUuid;
  getCurrentAcls: typeof useCurrentAcls;
};

const AclHookContext = React.createContext<AclHook>({
  getAcls: createNoop('Acls', {
    data: [],
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  }),

  getCurrentAcls: createNoop('Acls', {
    data: [],
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  }),
  getDataAcls: createNoop('Acls', {
    data: [],
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  }),
  getAclByUuid: createNoop('Acls', {
    data: acls[0],
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  })
});

export const AclHookProvider = (props: React.PropsWithChildren<AclHook>) => {
  const { children, ...rest } = props;
  return (
    <AclHookContext.Provider value={rest}>{children}</AclHookContext.Provider>
  );
};

export const useAclsHook = () => {
  return React.useContext(AclHookContext);
};

export const ProductionAclHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <AclHookProvider
      getCurrentAcls={useCurrentAcls}
      getAcls={useAcls}
      getDataAcls={useDataAcls}
      getAclByUuid={useAclByUuid}
    >
      {props.children}
    </AclHookProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */
export const InMemoryAclHookProvider = (
  props: React.PropsWithChildren<Partial<AclHook>>
) => {
  const { getAclByUuid, getAcls, getDataAcls, getCurrentAcls } = props;

  return (
    <AclHookProvider
      getCurrentAcls={
        getCurrentAcls ||
        (() => ({
          data: acls,
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      getAclByUuid={
        getAclByUuid ||
        (() => ({
          data: acls[0],
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      getAcls={
        getAcls ||
        (() => ({
          data: acls,
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      getDataAcls={
        getDataAcls ||
        (() => ({
          data: [],
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
    >
      {props.children}
    </AclHookProvider>
  );
};
