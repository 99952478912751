import * as CydarmFetch from 'utils/CydarmFetch';

export async function apiFetchCaseCache() {
  return CydarmFetch.cyFetchAuthenticated('/case-cache', {
    method: 'GET'
  });
}

export async function apiAddCaseToCaseCache(uuid: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/case-cache', {
    method: 'POST',
    body: JSON.stringify(uuid)
  });
}

export async function apiDeleteCaseFromCaseCache(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/case-cache/${uuid}`, {
    method: 'DELETE'
  });
}
