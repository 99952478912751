/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { CaseActivityContent } from './CaseActivityContent';
import { css } from '@emotion/react';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import Collapse from '@mui/material/Collapse';

/** @jsxImportSource @emotion/react */

export const CaseActivityContentWrapper = ({
  caseActivity,
  shouldShowFullView,
  setShouldShowFullView
}) => {
  const currentShowStatus =
    shouldShowFullView === 'expand' || shouldShowFullView === 'partial';
  const showPartialContent = shouldShowFullView === 'partial';

  return (
    <Collapse
      in={currentShowStatus}
      css={css`
        text-align: justify;
        max-height: ${showPartialContent ? '200px' : '100%'};
        overflow: hidden;
        padding-bottom: 0;
        position: relative;
      `}
    >
      <CaseActivityContent caseActivity={caseActivity} />
      {showPartialContent && (
        <div
          css={(theme) => `
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              0deg,
              ${theme.palette.background.default} 20%,
              rgba(0, 0, 0, 0) 100%
            );
            display: flex;
            justify-content: flex-end;
            flex: 0 1 100%;
            flex-direction: column;
          `}
        >
          <CydButton
            variant="text"
            onClick={() => {
              setShouldShowFullView('expand');
            }}
          >
            Show More
          </CydButton>
        </div>
      )}
    </Collapse>
  );
};
