/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useAtcPlaybookActions } from 'hooks/PlaybookActionsHook';
import { Select, SelectProps, MenuItem } from '@mui/material';
import { useMemo } from 'react';

export const PlaybookActionsSelect: React.FC<SelectProps> = ({
  value,
  ...props
}) => {
  const { data: actions } = useAtcPlaybookActions();

  const _value = useMemo(() => value || '', [value]);

  return (
    <Select value={_value} {...props}>
      <MenuItem value="">Select playbook action</MenuItem>
      {actions &&
        actions.forEach((action) => {
          action?.atc && (
            <MenuItem value={action.atc.uuid} key={action.atc.uuid}>
              {action.atc.name}
            </MenuItem>
          );
        })}
    </Select>
  );
};
