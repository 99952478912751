/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { ActivityItem } from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';
import { CaseActivityContentDownload } from './CaseActivityContentSlices/CaseActivityContentDownload';
import { CaseActivityContentHTML } from './CaseActivityContentSlices/CaseActivityContentHTML';
import { CaseActivityContentJsonTable } from './CaseActivityContentSlices/CaseActivityContentJsonTable';
import { CaseActivityContentMarkdown } from './CaseActivityContentSlices/CaseActivityContentMarkdown';
import { CaseActivityContentPreviewImage } from './CaseActivityContentSlices/CaseActivityContentPreviewImage';
import { CaseActivityContentSyntaxHighlighter } from './CaseActivityContentSlices/CaseActivityContentSyntaxHighlighter';

/** @jsxImportSource @emotion/react */

type CaseActivityContentProps = {
  caseActivity: ActivityItem;
};

export const CaseActivityContent = (props: CaseActivityContentProps) => {
  return (
    <div
      css={(theme) => `
      font-size: ${theme.typography.body1.fontSize}px;
      color: ${theme.palette.text.primary};
      margin-left: ${theme.spacing(2)};
      max-width: 1350px;

        & a {
          color: ${theme.palette.text.secondary};
        }
        & p {
          overflow-wrap: break-word;
          margin: 0;
          margin-bottom: 7px;
          & .spoiler-text {
            transition: background 0.2s ease, color 0.2s ease;
          }
          & .spoiler-text:not(:hover) {
            background-color: ${theme.palette.background.default};
            color: transparent;
          }
        }
        & table {
          border: none;
          border-spacing: 0;
          border-collapse: collapse;
          white-space: normal;
        }
        & td {
          border: ${theme.palette.text.secondary} 1px solid;
          padding: 5;
          margin: 2;
        }
        & th {
          border: ${theme.palette.text.secondary} 1px solid;
          padding: 5;
          margin: 2;
        }

        & pre {
          max-width: 1200px;
          overflow-x: auto;
        }

      `}
    >
      <CaseActivityContentDownload {...props} />
      <CaseActivityContentPreviewImage {...props} />
      <CaseActivityContentHTML {...props} />
      <CaseActivityContentMarkdown {...props} />
      <CaseActivityContentSyntaxHighlighter {...props} />
      <CaseActivityContentJsonTable {...props} />
    </div>
  );
};
