/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { useCaseAcl, useCurrentDataAcls } from 'hooks/AclHooks';
import { useCurrentUser } from 'hooks/AuthHooks';
import {
  useActionInstanceComments,
  useAddCommentToActionInstance
} from 'hooks/PlaybookActionsHook';
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import React, { useState } from 'react';
import { CommentsPanel } from './CommentsPanel';
import { Base64Encode } from 'utils/StringUtils';
import { Acl } from 'interface/Acl.interface';

export type ServicedCommentsPanelProps = {
  actionInstance: PlaybookActionInstance;
};

export const ServicedCommentsPanel = withErrorBoundary(
  (props: ServicedCommentsPanelProps) => {
    const { actionInstance } = props;

    const commentsQuery = useActionInstanceComments(
      actionInstance.actionInstanceUuid
    );
    const commentsMutation = useAddCommentToActionInstance(
      actionInstance.actionInstanceUuid,
      actionInstance.Case
    );
    const availableAclsResult = useCurrentDataAcls();
    const defaultAclResult = useCaseAcl(actionInstance.Case);
    const currentUser = useCurrentUser();

    const [selectedAcl, setSelectedAcl] = useState<Acl | null>(
      defaultAclResult.data || null
    );

    return (
      <div>
        {(commentsQuery.isLoading || currentUser.isLoading) && (
          <LoadingSpinner />
        )}
        {commentsQuery.data && (
          <CommentsPanel
            comments={commentsQuery.data}
            availableAcls={availableAclsResult.data}
            onChangeAcl={setSelectedAcl}
            selectedAcl={selectedAcl}
            onAddComment={(data) => {
              if (!selectedAcl) {
                throw new Error('No ACL selected');
              }

              if (!currentUser.data) {
                throw new Error('No current user');
              }

              commentsMutation.mutate({
                data: Base64Encode(data),
                acl: selectedAcl,
                mimeType: 'text/plain',
                caseUuid: actionInstance.Case,
                significance: 'Comment',
                userUuid: currentUser.data.uuid
              });
            }}
          />
        )}
      </div>
    );
  }
);
