/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { CydInfoIcon } from 'components/_dataDisplay/CydInfoIcon/CydInfoIcon';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydFileUploader } from 'components/_formElements/CydFileUploader/CydFileUploader';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { CydSignificanceSelector } from 'components/_objectSelectors/CydSignificanceSelector/CydSignificanceSelector';
import { Acl } from 'interface/Acl.interface';
import { DataSignificance } from 'interface/DataSignificance.interface';

/** @jsxImportSource @emotion/react */

export type BulkIocFormContentProps = {
  availableSignificances: Array<DataSignificance>;
  availableAcls: Array<Acl>;

  defaultSelectedSignificance: DataSignificance;
  defaultSelectedAcl: Acl;

  closeModalFn: () => void;

  onSubmit: (payload: {
    aclUuid: string;
    significanceName: string;
    file: File;
  }) => void;

  errorMessage?: string;
};

export const BulkIocFormContent = (props: BulkIocFormContentProps) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const {
    availableAcls,
    availableSignificances,
    defaultSelectedAcl,
    defaultSelectedSignificance,
    closeModalFn,
    onSubmit,
    errorMessage
  } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        // Very important.
        // A nuance of React is that events will propagate through portals
        // https://legacy.reactjs.org/docs/portals.html#event-bubbling-through-portals
        // Meaning that even though this form isn't inside the DataDrawer form on the DOM tree
        // The submission event here can still trigger it, unless we prevent propagation
        // See also: https://github.com/facebook/react/issues/11387
        e.stopPropagation();

        const formData = new FormData(e.currentTarget);
        const acl = formData.get('acl')?.toString();
        const significance = formData.get('significance')?.toString();

        if (!acl) {
          throw new Error('ACL not provided for form submission');
        }
        if (!significance) {
          throw new Error('Significance not provided for form submission');
        }

        if (files.length === 0) {
          throw new Error('File not provided for submission');
        }

        onSubmit({
          aclUuid: acl,
          significanceName: significance,
          file: files[0]
        });
      }}
    >
      <Typography>
        Upload a CSV to quickly add multiple Indicators to this case. CSV must
        be correctly formatted to be imported.{' '}
        <CydInfoIcon
          variant="tooltip"
          text="CSV must contain the columns: type, value, datetime, name, description"
        />
      </Typography>

      {/* TODO: Add download sample CSV if/when this is working */}
      {/* <Button variant="outlined" href="/something" download>
        Download sample CSV
      </Button> */}
      <CydFileUploader
        name="file"
        files={files}
        onFilesAdded={(newFiles: Array<File>) => {
          setFiles(newFiles);
        }}
        accept={{
          'text/csv': ['.csv']
        }}
        css={(theme) => `
          ${
            errorMessage ? `border-color: ${theme.palette.error.main};` : ''
          }           
        `}
      />
      <Typography
        gutterBottom
        variant="caption"
        color="error"
        display={'block'}
      >
        {errorMessage}
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <CydAclSelector
            availableAcls={availableAcls}
            name="acl"
            defaultSelectedAclUuid={defaultSelectedAcl.uuid}
          />
        </Grid>

        <Grid item xs={6}>
          <CydSignificanceSelector
            name="significance"
            availableSignificances={availableSignificances}
            defaultSelectedValue={defaultSelectedSignificance.name}
          />
        </Grid>
      </Grid>

      <CydButtonStack justifyContent={'flex-end'}>
        <CydButton variant="text" onClick={closeModalFn}>
          Cancel
        </CydButton>
        <CydButton variant="contained" type="submit">
          Add to case
        </CydButton>
      </CydButtonStack>
    </form>
  );
};
