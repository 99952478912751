/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchAclAction } from './actions';
import { Acl } from 'interface/Acl.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAclStoreState {
  acls: Acl[];
  hasLoaded: boolean;
  isLoading: boolean;
}

const initialState: IAclStoreState = {
  acls: [],
  hasLoaded: false,
  isLoading: false
};

const aclSlice = createSlice({
  name: 'acl',
  initialState,
  reducers: {
    fetchAclByUuidSuccess(state, { payload }: PayloadAction<Acl>) {
      state.acls = [
        ...state.acls.filter(({ uuid }) => payload.uuid !== uuid),
        payload
      ];
    },

    fetchAclSuccess(state, { payload }: PayloadAction<Acl[]>) {
      state.isLoading = false;
      state.hasLoaded = true;
      state.acls = payload;
    },

    deleteAclSuccess(state, { payload }: PayloadAction<string>) {
      state.acls = state.acls.filter(({ uuid }) => uuid !== payload);
    },

    createOrRetrieveAclSuccess(state, { payload }: PayloadAction<Acl>) {
      state.acls = [...state.acls, payload];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createFetchAclAction, (state, action) => {
      state.isLoading = true;
    });
  }
});

export const {
  reducer: acl,
  actions: {
    fetchAclByUuidSuccess,
    fetchAclSuccess,
    deleteAclSuccess,
    createOrRetrieveAclSuccess
  }
} = aclSlice;
