/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CydarmRoute } from 'interface/CydarmRoute';
import {
  JsonApiArrayResponse,
  JsonApiIndividualResponse
} from 'interface/JsonApi';
import { useServiceProvider } from 'providers/ServiceProvider';
import { useNavigate } from 'react-router';
import {
  CydApi_WikiPageObject,
  CydApi_WikiPageObjectRelationships,
  CydApi_WikiPageStub,
  CydApi_WikiPageStubRelationships,
  WikiAttachmentPayload,
  WikiPagePayload,
  WikiPayload
} from 'services/WikiService';
import { downloadFileFromBase64String } from 'utils/FileUtils';
import { imperativeAddNotification } from 'states/imperitiveAddNotif';

export function useWikiStubs() {
  const { apiGetWikiPages } = useServiceProvider();

  return useQuery({
    queryKey: ['wiki-page'],
    queryFn: async () => {
      const result = await apiGetWikiPages();

      return result.json;
    }
  });
}

export function useWikiPage(wikiUuid?: string) {
  const { apiGetWikiPage } = useServiceProvider();

  return useQuery({
    queryKey: ['wiki-page', wikiUuid],
    queryFn: async () => {
      if (!wikiUuid) {
        return null;
      }

      try {
        const result = await apiGetWikiPage(wikiUuid);
        return result.json;
      } catch (err: any) {
        // Both 400 and 404s are returned if page doesn't exist. See: https://cydarm.slack.com/archives/C033ZKMMAJ1/p1675988243342109
        if (err.status >= 400 && err.status < 500) {
          throw new Error('404');
        }
        throw err;
      }
    },

    retry: false,
    useErrorBoundary: false
  });
}

export function useDeleteFileFromWikiPage() {
  const { apiDeleteWikiPage } = useServiceProvider();
  const qc = useQueryClient();

  return useMutation({
    mutationKey: ['wiki-page'],
    mutationFn: async (payload: { attachmentId: string; wikiUuid: string }) => {
      const { attachmentId } = payload;
      const result = await apiDeleteWikiPage(attachmentId);
      return result.json;
    },
    onSuccess: (_, { wikiUuid }) => {
      // Invalidate the wiki page so that the new attachment is shown
      qc.invalidateQueries({
        queryKey: ['wiki-page', wikiUuid]
      });
    }
  });
}

export function useDownloadFileFromWikiPage() {
  const { apiGetWikiPage } = useServiceProvider();
  return useMutation({
    mutationKey: ['wiki-attachment'],
    mutationFn: async ({ attachmentId }: { attachmentId: string }) => {
      return await apiGetWikiPage(attachmentId);
    },
    onSuccess: (data) => {
      const attributes = data.json.data
        .attributes as unknown as WikiAttachmentPayload;

      downloadFileFromBase64String({
        filename: attributes.file_name,
        fileData: attributes.content
      });
    }
  });
}

export function useAttachFilesToWikiPage() {
  const { apiUploadFileToWiki } = useServiceProvider();
  const qc = useQueryClient();
  return useMutation({
    mutationKey: ['wiki-attachment'],
    mutationFn: async ({
      attachments
    }: {
      wikiUuid: string;
      attachments: Array<WikiAttachmentPayload>;
    }) => {
      await Promise.all(
        attachments.map(async (attachmentPayload) => {
          return await apiUploadFileToWiki(attachmentPayload);
        })
      );
      return;
    },
    onSuccess: (_, { wikiUuid }) => {
      // Invalidate the wiki page so that the new attachment is shown
      qc.invalidateQueries({
        queryKey: ['wiki-page', wikiUuid]
      });
    }
  });
}

export function useCreateWikiPage() {
  const { apiCreateWikiPage } = useServiceProvider();
  const navigate = useNavigate();
  const qc = useQueryClient();

  return useMutation({
    mutationKey: ['wiki-page'],
    mutationFn: async (payload: WikiPagePayload) => {
      const result = await apiCreateWikiPage(payload);
      return result.json;
    },
    onSuccess: (data) => {
      qc.setQueryData(['wiki-page', data.data.id], data);

      qc.setQueryData(
        ['wiki-page'],
        (
          oldData:
            | JsonApiArrayResponse<
                CydApi_WikiPageStub,
                CydApi_WikiPageStubRelationships
              >
            | undefined
        ) => {
          if (oldData) {
            return {
              ...oldData,
              data: [...oldData.data, data.data]
            };
          }

          return undefined;
        }
      );

      navigate(`${CydarmRoute.WIKI}/${data.data.id}`);
    }
  });
}

export function useDeleteWikiPage() {
  const { apiDeleteWikiPage } = useServiceProvider();
  const navigate = useNavigate();
  const qc = useQueryClient();

  return useMutation({
    mutationKey: ['wiki-page'],
    mutationFn: async (payload: { wikiUuid: string }) => {
      const { wikiUuid } = payload;
      const result = await apiDeleteWikiPage(wikiUuid);
      return result.json;
    },
    onSuccess: (data, variables) => {
      navigate(`${CydarmRoute.WIKI}`);
      qc.removeQueries({ queryKey: ['wiki-page', variables.wikiUuid] });

      qc.setQueryData(
        ['wiki-page'],
        (
          oldData:
            | JsonApiArrayResponse<
                CydApi_WikiPageStub,
                CydApi_WikiPageStubRelationships
              >
            | undefined
        ) => {
          if (!oldData) {
            return undefined;
          }

          return {
            ...oldData,
            data: oldData.data.filter((v) => v.id !== variables.wikiUuid)
          };
        }
      );
    },
    onError: async (err: any) => {
      const errorJson = await err.json();
      imperativeAddNotification({ message: errorJson.errors[0].title });
    }
  });
}

export function useEditWikiPage() {
  const { apiEditWikiPage } = useServiceProvider();
  const qc = useQueryClient();

  return useMutation({
    mutationKey: ['wiki-page'],
    mutationFn: async (payload: { wikiUuid: string; newData: WikiPayload }) => {
      const { wikiUuid, newData } = payload;

      const result = await apiEditWikiPage(wikiUuid, newData);
      return result.json;
    },
    onSuccess: (newData, variables) => {
      qc.setQueryData(
        ['wiki-page'],
        (
          oldData:
            | JsonApiArrayResponse<
                CydApi_WikiPageStub,
                CydApi_WikiPageStubRelationships
              >
            | undefined
        ) => {
          if (!oldData) {
            return undefined;
          }

          return {
            ...oldData,
            data: oldData.data.map((v) => {
              if (v.id !== variables.wikiUuid) {
                return v;
              } else {
                return newData.data;
              }
            })
          };
        }
      );

      qc.setQueryData(
        ['wiki-page', variables.wikiUuid],
        (
          oldData:
            | JsonApiIndividualResponse<
                CydApi_WikiPageObject,
                CydApi_WikiPageObjectRelationships
              >
            | undefined
        ) => {
          if (!oldData) {
            return newData;
          }
          return {
            ...newData,
            included: oldData.included
          };
        }
      );
    }
  });
}
