/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

export const titleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};

// If a key or value in the array is found in this object, we will replace it with a more
// human readable one
export const wordReplacer = {
  dstip: 'Destination IP',
  dstmac: 'Destination MAC',
  srcip: 'Source IP',
  srcmac: 'Source MAC',
  traffic: 'Traffic',
  observeddata: 'Observed Data',
  dst_byte_count: 'Destination Byte Count',
  dst_port: 'Destination Port',
  src_byte_count: 'Source Byte Count',
  src_port: 'Source Port',
  macaddr: 'MAC Address',
  ipv4addr: 'IPV4 Address',
  networktraffic: 'Network Traffic',
  udp: 'UDP',
  dns: 'DNS',
  tcp: 'TCP',
  first_observed: 'First Observed',
  last_observed: 'Last Observed',
  number_observed: 'Number Observed',
  identity_class: 'IdentityClass',
  contact_information: 'Contact Information',
  valid_from: 'Valid From',
  first_seen: 'First Seen',
  last_seen: 'Last Seen',
  relationship_type: 'Relationship Type',
  phase_name: 'Phase Name',
  source_ref: 'Source Reference',
  target_ref: 'Target Reference',
  src_ref: 'Source',
  dst_ref: 'Destination'
};
