/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { takeLeading, put } from 'redux-saga/effects';
import {
  createCaseForm,
  deleteCaseForm,
  fetchCaseForms,
  updateCaseForm
} from './actions';
import {
  createCaseFormSuccess,
  fetchCaseFormsSuccess,
  FormObject
} from './slice';
import { ICaseFormAction, ICaseFormObject } from 'interface/CaseForm.interface';
import { addNotification } from 'states/notifications/slice';
import {
  createForm,
  deleteForm,
  fetchAllForms,
  updateForm
} from 'services/FormService';

export const CASE_FORM_ENDPOINT: string = `/form`;

function* fetchCaseFormsSaga() {
  try {
    const caseForms = yield fetchAllForms();
    yield put(fetchCaseFormsSuccess(caseForms));
  } catch (error) {}
}

export function* performCreateCaseForm(action: ICaseFormAction) {
  const {
    payload: { caseForm: caseFormData }
  } = action;
  try {
    const submitCaseFormData = {
      ...caseFormData,
      fields: JSON.stringify(caseFormData?.fields || []),
      precedence: 1 // This is not used for now. Set it to 1
    };
    const caseForm = yield createForm(
      submitCaseFormData as unknown as Omit<FormObject, 'uuid'>
    );

    yield put(
      createCaseFormSuccess({
        ...submitCaseFormData,
        uuid: caseForm.uuid
      })
    );
    yield put(
      addNotification({
        message: `Case Form ${caseFormData?.name} has been created`
      })
    );
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to create new case form' }));
  }
}

export function* performUpdateCaseForm(action: ICaseFormAction) {
  const {
    payload: { caseForm: caseFormData }
  } = action;
  try {
    if (!caseFormData.uuid) {
      return;
    }
    const updateCaseFormData = {
      ...caseFormData,
      uuid: caseFormData.uuid,
      fields: JSON.stringify(caseFormData?.fields || [])
    };
    yield updateForm(updateCaseFormData);
    yield put(fetchCaseForms());
    yield put(
      addNotification({
        message: `Case Form ${caseFormData?.name} has been updated`
      })
    );
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to update case form' }));
  }
}

export function* performRemoveCaseForm(action: ICaseFormObject) {
  const {
    payload: { caseForm: caseFormData }
  } = action;
  try {
    yield deleteForm(caseFormData);
    yield put(
      addNotification({
        message: `Case Form ${caseFormData?.name} has been removed`
      })
    );
    yield put(fetchCaseForms());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to remove case form' }));
  }
}

/* Watchers */
function* watchFetchCaseForms() {
  yield takeLeading(fetchCaseForms, fetchCaseFormsSaga);
}

function* watchCreateCaseForm() {
  yield takeLeading(createCaseForm, performCreateCaseForm);
}

function* watchUpdateCaseForm() {
  yield takeLeading(updateCaseForm, performUpdateCaseForm);
}

function* watchDeleteCaseForm() {
  yield takeLeading(deleteCaseForm, performRemoveCaseForm);
}

export default [
  watchFetchCaseForms(),
  watchCreateCaseForm(),
  watchUpdateCaseForm(),
  watchDeleteCaseForm()
];
