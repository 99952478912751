/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useConfirmDialogStyles = makeStyles(({ spacing, palette }) => ({
  dialogWrapper: {
    backgroundColor: palette.background.paper
  },
  content: {
    padding: spacing(4),
    paddingTop: spacing(),
    color: palette.text.primary
  },
  header: {
    padding: spacing(4),
    paddingBottom: spacing(),
    color: palette.text.primary
  }
}));

export const dialogBackgroundProps = { style: { opacity: 0.5 } };
