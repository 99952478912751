/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import React from 'react';
import { determineManualOrAutomatic } from '../utils/generalNodeStatusUtils';

export type ManualOrAutomaticIconProps = {
  type: ReturnType<typeof determineManualOrAutomatic>;
  className?: string;
};

export const ManualOrAutomaticIcon = (props: ManualOrAutomaticIconProps) => {
  const { type, className } = props;

  if (type === 'not-applicable') {
    return null;
  }

  if (type === 'automatic') {
    return (
      <CydIcon
        icon="robot"
        label="This is an automated step"
        className={className}
      />
    );
  }

  if (type === 'manual') {
    return (
      <CydIcon
        icon="human"
        className={className}
        label="This is a manual step to be performed by humans"
      />
    );
  }

  console.warn(`Unknown icon type '${type}', returning null`);
  return null;
};
