/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IManagerDashboardStoreState {
  selectedPeriod: string;
  tags: string[];
  endState: string;
  showMean: boolean;
  showEndOfState: boolean;
}

const initialState: IManagerDashboardStoreState = {
  selectedPeriod: 'monthly',
  tags: [],
  endState: 'Closed',
  showMean: true,
  showEndOfState: false
};

const managerDashboardSlice = createSlice({
  name: 'managerDashboardStore',
  initialState,
  reducers: {
    updateTags: (state, { payload }: PayloadAction<string[]>) => {
      state.tags = payload;
    },

    updateSelectedPeriod: (state, { payload }: PayloadAction<string>) => {
      state.selectedPeriod = payload;
    },

    updateEndState: (state, { payload }: PayloadAction<string>) => {
      state.endState = payload;
    },

    switchShowEndState: (state) => {
      state.showEndOfState = !state.showEndOfState;
    },

    switchShowMean: (state) => {
      state.showMean = !state.showMean;
    }
  }
});

export const {
  reducer: managerDashboardStore,
  actions: {
    updateTags,
    updateSelectedPeriod,
    updateEndState,
    switchShowEndState,
    switchShowMean
  }
} = managerDashboardSlice;

// export const managerDashboardStore = (
//   state = initialState,
//   { type, payload }: { type: string; payload: IManagerDashboardPayload }
// ) => {
//   switch (type) {
//     case UPDATE_TAGS:
//       return {
//         ...state,
//         tags: payload.tags
//       };
//     case UPDATE_END_STATE:
//       return {
//         ...state,
//         endState: payload.endState
//       };
//     case UPDATE_SELECTED_PERIOD:
//       return {
//         ...state,
//         selectedPeriod: payload.selectedPeriod
//       };
//     case SWITCH_SHOW_END_STATE:
//       return {
//         ...state,
//         showEndOfState: !state.showEndOfState
//       };
//     case SWITCH_SHOW_MEAN:
//       return {
//         ...state,
//         showMean: !state.showMean
//       };
//     default:
//       return state;
//   }
// };
