/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchIoCQuickSearch } from 'states/IoCQuickSearch/actions';
import { iocQuickSearchResultsSelector } from 'states/IoCQuickSearch/selectors';
import { useAutoEffect } from './ReduxHooks';

export const useQuickSearchResults = (query: string) => {
  return useAutoEffect({
    selector: iocQuickSearchResultsSelector,
    ac: createFetchIoCQuickSearch,
    actionData: query
  });
};
