/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';
import { PlaybookAction } from 'interface/Playbook.interface';

import { v4 as uuid } from 'uuid';
import { AvailableStepStatuses } from 'components/_playbooks/CacaoFlowchart/type';

export const mapNamesToPlaybookActions = (
  caseActions,
  playbookActions
): PlaybookAction[] => {
  if (!caseActions || !caseActions.length || !playbookActions.length) {
    return [];
  }
  // TODO: augment the record here without replacing it
  caseActions.forEach((caseAction) => {
    const playbookAction = playbookActions.find(
      (action) => action.name === caseAction.actionName
    );
    if (playbookAction) {
      caseAction.description = playbookAction.description;
    }
  });

  return caseActions;
};

const topLevelKeysToAdjust = ['workflow_exception', 'workflow_start'];

const stepKeysToAdjust = [
  'on_completion',
  'on_success',
  'on_failure',
  'on_true',
  'on_false'
];
const keyArrayStepsToAdjust = ['next_steps'];

const recordStepsToAdjust = ['cases'];

// uniquifyCacaoJson takes a playbook, and generates new IDs for each step
export function uniquifyCacaoJson(
  playbook: OasisCacaoPlaybook,
  uuidGenerator: () => string = uuid
): OasisCacaoPlaybook {
  // Create a map of old uuids to new uuids
  const keyMap = {} as Record<string, string>;

  // Go through an generate new keys, and prefixed names
  const newWorkflow = Object.entries(playbook.workflow).reduce((acc, cur) => {
    const [key, value] = cur;

    const prefix = key.split('--')[0];

    const newUuid = uuidGenerator();

    const newKey = `${prefix}--${newUuid}`;

    keyMap[key] = newKey;

    acc[newKey] = {
      ...value,
      name: `${newKey} --- ${value.name || ''}`
    };

    return acc;
  }, {});

  // Now we also need to update references

  Object.entries(newWorkflow).forEach(
    (step: [string, OasisCacaoPlaybook['workflow'][string]]) => {
      const [stepId, stepObj] = step;

      // in the loop below we assume that the playbook has been validated and that the field types can be safely asserted
      for (const stepObjKey in stepObj) {
        if (stepKeysToAdjust.includes(stepObjKey)) {
          // for each of on_success, on_failure, etc, map the ID to the new workflow ID, then write this to the new workflow
          const stepIdToAdjust = stepObj[stepObjKey] as string;
          const newStepId = keyMap[stepIdToAdjust];
          newWorkflow[stepId][stepObjKey] = newStepId;
        } else if (keyArrayStepsToAdjust.includes(stepObjKey)) {
          // for next_steps, map the IDs to the new workflow IDs, then write these to the new workflow
          const stepIdsToAdjust = stepObj[stepObjKey] as Array<string>;
          const newStepIds = stepIdsToAdjust.map((v) => keyMap[v]);
          newWorkflow[stepId][stepObjKey] = newStepIds;
        } else if (recordStepsToAdjust.includes(stepObjKey)) {
          // for switch cases, map each ID to the new workflow IDs, then write to the new workflow
          const records = stepObj[stepObjKey] as Record<string, string>;
          if (records) {
            Object.entries(records).forEach(([caseKey, value]) => {
              const newStepId = keyMap[value];
              newWorkflow[stepId][stepObjKey][caseKey] = newStepId;
            });
          }
        }
      }
    }
  );

  const newPlaybook = { ...playbook };
  newPlaybook.workflow = newWorkflow;

  // map the top level keys to the new workflow IDs
  topLevelKeysToAdjust.forEach((topLevelKey) => {
    const stepUuidToAdjust = newPlaybook[topLevelKey] as string;
    if (stepUuidToAdjust) {
      newPlaybook[topLevelKey] = keyMap[stepUuidToAdjust];
    }
  });

  return newPlaybook;
}

// consolidateUuids takes a playbook, and a list of new step uuids, and returns a new playbook with the new uuids
export function consolidateUuids(
  playbook: OasisCacaoPlaybook,
  oldSteps: Array<string>,
  newUuids: Array<{ uuid: string; acl: string }>
): OasisCacaoPlaybook {
  if (oldSteps.length !== newUuids.length) {
    throw new Error('Old steps and new uuids should be the same length');
  }

  const keyMap = {} as Record<string, string>;

  oldSteps.forEach((oldKey, i) => {
    const prefix = oldKey.split('--')[0];
    const newKey = `${prefix}--${newUuids[i].uuid}`;
    keyMap[oldKey] = newKey;
  });

  const newWorkflow = {};

  oldSteps.forEach((oldKey) => {
    const newKey = keyMap[oldKey];
    newWorkflow[newKey] = playbook.workflow[oldKey];

    stepKeysToAdjust.forEach((stepKey) => {
      // assume validation has been done and type asserion is safe
      const stepUuidToAdjust = playbook.workflow[oldKey][stepKey] as string;

      if (stepUuidToAdjust) {
        const newStepUuid = keyMap[stepUuidToAdjust];

        newWorkflow[newKey][stepKey] = newStepUuid;
      }
    });

    keyArrayStepsToAdjust.forEach((stepKey) => {
      // assume validation has been done and type asserion is safe
      const uuidsToAdjust = playbook.workflow[oldKey][stepKey] as Array<string>;

      if (uuidsToAdjust) {
        const newUuids = uuidsToAdjust.map((v) => keyMap[v]);

        newWorkflow[newKey][stepKey] = newUuids;
      }
    });

    recordStepsToAdjust.forEach((stepKey) => {
      // assume validation has been done and type asserion is safe
      const recordsToAdjust = playbook.workflow[oldKey][stepKey] as Record<
        string,
        string
      >;
      if (recordsToAdjust) {
        Object.entries(recordsToAdjust).forEach(([key, value]) => {
          const newStepId = keyMap[value];
          newWorkflow[newKey][stepKey][key] = newStepId;
        });
      }
    });
  });

  const newPlaybook = { ...playbook };
  newPlaybook.workflow = newWorkflow;

  topLevelKeysToAdjust.forEach((topLevelKey) => {
    const stepUuidToAdjust = newPlaybook[topLevelKey] as string;
    if (stepUuidToAdjust) {
      newPlaybook[topLevelKey] = keyMap[stepUuidToAdjust];
    }
  });

  return newPlaybook;
}

// Convert the step we have defined, to something compatable with this structure
// https://github.com/cydarm/case-management/blob/develop/backend/model/model-playbook-cacao.go#L834
export function convertStepToBackendCompatableStep(data): unknown {
  return {
    ...data,
    workflow_start: data.type === 'start'
  };
}

export function removeStepTypePrefixes(data): unknown {
  return {
    ...data,
    on_completion: data.on_completion?.split('--')[1],
    on_true: data.on_true?.split('--')[1],
    on_false: data.on_false?.split('--')[1],
    next_steps: data.next_steps?.map((v) => v.split('--')[1])
  };
}

/* These completed statuses are based on PlaybookActionCompletedStatuses in <case-management>/common/constants/constants-playbook.go */
const PlaybookActionCompletedStatuses: Array<AvailableStepStatuses> = [
  'success',
  'failed',
  'exception'
];

export function isPlaybookActionCompletedStatus(
  status: AvailableStepStatuses
): boolean {
  return PlaybookActionCompletedStatuses.includes(status);
}

export class PlaybookUtils {
  public static attachRandomIdsAndNamesToPlaybookJson(
    playbookJson: OasisCacaoPlaybook,
    namePrefix: string,
    nameSuffix: string
  ): OasisCacaoPlaybook {
    let playbookStr = JSON.stringify(playbookJson);
    const workflow = playbookJson.workflow;

    // update workflow ids
    const stepIds = Object.keys(workflow);
    stepIds.forEach((stepId) => {
      const [prefix] = stepId.split('--');
      const newId = `${prefix}--${uuid()}`;
      playbookStr = playbookStr.replace(new RegExp(stepId, 'ig'), newId);
    });

    const returnPlaybook = JSON.parse(playbookStr);
    // update playbook id
    returnPlaybook.id = `playbook--${uuid()}`;

    // update playbook name
    returnPlaybook.name += ` ${nameSuffix}`;

    // update workflow step names
    Object.keys(returnPlaybook.workflow).forEach((workflowKey) => {
      returnPlaybook.workflow[workflowKey].name += ` ${nameSuffix}`;
      returnPlaybook.workflow[workflowKey].name = returnPlaybook.workflow[
        workflowKey
      ].name.replace(new RegExp(`^${namePrefix}-`), '');
    });
    return returnPlaybook;
  }
}
