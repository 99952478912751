/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest } from 'redux-saga/effects';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { fetchUserActionsFailed, fetchUserActionsSuccess } from './slice';
import { createFetchUserActions } from './actions';
import { apiFetchUserActions } from 'services/UsersServices';

export function* performFetchUserActions({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    const { json: userActions } = yield apiFetchUserActions(uuid);

    yield put(fetchUserActionsSuccess({ [uuid]: userActions }));
  } catch (ex) {
    yield put(fetchUserActionsFailed());
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_USER_ACTIONS_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchUserActions() {
  yield takeLatest(createFetchUserActions, performFetchUserActions);
}

export default [watchFetchUserActions()];
