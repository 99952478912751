/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useCallback, useState, createRef, useMemo } from 'react';

export const usePopovers = (popovers: { [index: string]: any }) => {
  const initialPopoversState = Object.keys(popovers).reduce(
    (initialState, popoverKey) => ({
      ...initialState,
      [popoverKey]: {
        ...popovers[popoverKey],
        isOpened: false
      }
    }),
    {}
  );

  const anchorRefs = useMemo(
    () =>
      Object.keys(popovers).reduce(
        (initialRefs, popoverKey) => ({
          ...initialRefs,
          [popoverKey]: createRef<any>()
        }),
        {}
      ),
    [popovers]
  );

  const [popoversState, setPopoversState] = useState(initialPopoversState);

  const openPopover = useCallback(
    (popoverKey, additionalProps?: any) => {
      const { anchorEl = undefined, ...rest } = additionalProps || {};

      setPopoversState({
        ...popoversState,
        [popoverKey]: {
          ...popoversState[popoverKey],
          isOpened: true,
          anchorEl: anchorEl
            ? anchorEl
            : anchorRefs[popoverKey] && anchorRefs[popoverKey].current,
          ...rest
        }
      });
    },
    [popoversState, anchorRefs]
  );

  const closePopover = useCallback(
    (popoverKey) => {
      setPopoversState({
        ...popoversState,
        [popoverKey]: {
          ...popovers[popoverKey],
          isOpened: false,
          anchorEl: undefined
        }
      });
    },
    [popovers, popoversState]
  );

  const bindPopoverProps = useCallback(
    (popoverKey) => ({
      ...popoversState[popoverKey],
      onClose: () => {
        closePopover(popoverKey);
      }
    }),
    [popoversState, closePopover]
  );

  return { openPopover, closePopover, bindPopoverProps, anchorRefs };
};
