/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { shallowEqual } from 'react-redux';
import { createFetchMetas } from 'states/caseMetas/actions';
import { caseMetasSelector } from 'states/caseMetas/selectors';
import { useAutoEffect } from './ReduxHooks';

export const useMetas = () => {
  return useAutoEffect({
    selector: caseMetasSelector,
    ac: createFetchMetas,
    equality: shallowEqual
  });
};
