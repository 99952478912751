/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { debounce, put, takeLatest } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { Base64Encode } from 'utils/StringUtils';
import {
  createAddNewUserProperty,
  createFetchAllUserProperty,
  IAddOrUpdateUserProp,
  IRemoveUserProp,
  createRemoveUserProperty,
  createUpdateUserProperty
} from './actions';
import {
  fetchAllUserPropertySuccess,
  fetchAllUserPropertyFailed
} from './slice';
import {
  apiCreateUserProps,
  apiFetchAllUserProps,
  apiRemoveUserProps,
  apiUpdateUserProps
} from 'services/UsersServices';

export function* performFetchAllUserProps({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    const { json: data } = yield apiFetchAllUserProps(uuid);
    yield put(fetchAllUserPropertySuccess(data));
  } catch (error) {
    yield put(fetchAllUserPropertyFailed(error.statusText));
  }
}

export function* performCreateUserProps({
  payload: { userUuid, propName, propValue }
}: {
  payload: IAddOrUpdateUserProp;
}) {
  try {
    const encodedString = Base64Encode(JSON.stringify({ key: propValue }));
    yield apiCreateUserProps(userUuid, propName, {
      name: propName,
      value: encodedString
    });
    yield put(createFetchAllUserProperty(userUuid));
    yield put(addNotification({ message: 'New setting successfully created' }));
  } catch (ex) {
    yield put(fetchAllUserPropertyFailed(ex.statusText));
    yield put(addNotification({ message: 'New setting creating failed' }));
  }
}

export function* performUpdateUserProps({
  payload: { userUuid, propName, propValue }
}: {
  payload: IAddOrUpdateUserProp;
}) {
  try {
    const encodedString = Base64Encode(JSON.stringify({ key: propValue }));
    yield apiUpdateUserProps(userUuid, propName, {
      name: propName,
      value: encodedString
    });
    yield put(createFetchAllUserProperty(userUuid));
    yield put(addNotification({ message: 'New setting successfully updated' }));
  } catch (ex) {
    yield put(fetchAllUserPropertyFailed(ex.statusText));
    yield put(addNotification({ message: 'New setting updating failed' }));
  }
}

export function* performRemoveUserProps({
  payload: { userUuid, propName }
}: {
  payload: IRemoveUserProp;
}) {
  try {
    yield apiRemoveUserProps(userUuid, propName);
    yield put(createFetchAllUserProperty(userUuid));
    yield put(addNotification({ message: 'Setting successfully removed' }));
  } catch (ex) {
    yield put(fetchAllUserPropertyFailed(ex.statusText));
    yield put(addNotification({ message: 'Setting fail to remove' }));
  }
}

function* watchFetchAllUserProps() {
  yield debounce(10, createFetchAllUserProperty, performFetchAllUserProps);
}

function* watchCreateUserProps() {
  yield takeLatest(createAddNewUserProperty, performCreateUserProps);
}

function* watchUpdateUserProps() {
  yield takeLatest(createUpdateUserProperty, performUpdateUserProps);
}

function* watchRemoveUserProps() {
  yield takeLatest(createRemoveUserProperty, performRemoveUserProps);
}

export default [
  watchFetchAllUserProps(),
  watchCreateUserProps(),
  watchRemoveUserProps(),
  watchUpdateUserProps()
];
