/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useSelector } from 'react-redux';
import { openCaptionsStoreState } from 'states/activityTimeline/selectors';
import { TimelineCaption } from './TimelineCaption';

export const TimelineCaptionsWrapper = ({ svgWidth, caseAcl }) => {
  const openCaptions = useSelector(openCaptionsStoreState);
  return (
    <>
      {openCaptions.map((caption) => (
        <TimelineCaption key={caption.id} {...{ caption, svgWidth, caseAcl }} />
      ))}
    </>
  );
};
