/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonApiErrorSource } from './JsonApiErrorSource';
import {
    JsonApiErrorSourceFromJSON,
    JsonApiErrorSourceFromJSONTyped,
    JsonApiErrorSourceToJSON,
} from './JsonApiErrorSource';

/**
 * 
 * @export
 * @interface JsonApiError
 */
export interface JsonApiError {
    /**
     * The UUID of the error
     * @type {string}
     * @memberof JsonApiError
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value
     * @type {string}
     * @memberof JsonApiError
     */
    status?: string;
    /**
     * An application-specific error code, expressed as a string value
     * @type {string}
     * @memberof JsonApiError
     */
    code?: string;
    /**
     * A short, human-readable summary of the problem
     * @type {string}
     * @memberof JsonApiError
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem
     * @type {string}
     * @memberof JsonApiError
     */
    detail?: string;
    /**
     * 
     * @type {JsonApiErrorSource}
     * @memberof JsonApiError
     */
    source?: JsonApiErrorSource | null;
    /**
     * Additional metadata fields
     * @type {object}
     * @memberof JsonApiError
     */
    meta?: object;
}

/**
 * Check if a given object implements the JsonApiError interface.
 */
export function instanceOfJsonApiError(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonApiErrorFromJSON(json: any): JsonApiError {
    return JsonApiErrorFromJSONTyped(json, false);
}

export function JsonApiErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
        'source': !exists(json, 'source') ? undefined : JsonApiErrorSourceFromJSON(json['source']),
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
    };
}

export function JsonApiErrorToJSON(value?: JsonApiError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'code': value.code,
        'title': value.title,
        'detail': value.detail,
        'source': JsonApiErrorSourceToJSON(value.source),
        'meta': value.meta,
    };
}

