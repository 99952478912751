/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { CaseTag } from 'interface/CaseTag.interface';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  caseTag?: CaseTag | null;
  isLoading?: boolean;
  error?: ErrorPayload | null;
  list: CaseTag[];
  hasLoaded: boolean;
} = {
  caseTag: null,
  list: [],
  isLoading: false,
  hasLoaded: false,
  error: null
};

const caseTagsSlice = createSlice({
  name: 'caseTags',
  initialState,
  reducers: {
    fetchCaseTagsSuccess: (state, { payload }: PayloadAction<CaseTag[]>) => {
      state.hasLoaded = true;
      state.isLoading = false;
      state.list = payload;
    },

    fetchCaseTagsFailed: (state, { payload }: PayloadAction<ErrorPayload>) => {
      state.error = payload;
    },

    deleteCaseTag: (state, { payload }: PayloadAction<string>) => {
      state.list = state.list.filter(({ uuid }) => uuid !== payload);
    },

    createCaseTagSuccess: (state, { payload }: PayloadAction<CaseTag>) => {
      state.list.push(payload);
    }
  }
});

export const {
  reducer: caseTags,
  actions: {
    fetchCaseTagsSuccess,
    fetchCaseTagsFailed,
    deleteCaseTag,
    createCaseTagSuccess
  }
} = caseTagsSlice;
