/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createDownloadCaseStixData,
  createAddStixData,
  createFetchStix
} from './actions';
import { addNotification } from 'states/notifications/slice';
import { assignDataToCase } from 'states/caseData/slice';
import { clearStixData, fetchStixSuccess } from './slice';
import { Case } from 'interface/Case.interface';
import {
  apiPerformAddStixData,
  apiPerformDownloadCaseStixData,
  apiPerformFetchStix
} from 'services/CaseServices';
import { invalidateCaseActivity } from 'states/cases/sagas';

export function* performFetchStix({ payload: caseUuid }: { payload: string }) {
  try {
    yield put(clearStixData());
    const { json: stix } = yield apiPerformFetchStix(caseUuid);
    yield put(fetchStixSuccess(stix));
  } catch (error) {
    yield put(
      addNotification({
        message: 'Failed to fetch stix data'
      })
    );
  }
}

function* performDownloadCaseStixData({
  payload: caseData
}: {
  payload: Case;
}) {
  const filename = `cydarm-case-indicators-${caseData.locator}.stix.json`;

  try {
    const { json: stix } = yield apiPerformDownloadCaseStixData(caseData.uuid);
    const json = JSON.stringify(stix);
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (ex) {
    yield put(
      addNotification({ message: 'Error occurred downloading STIX file' })
    );
  }
}

function* performAddStixData(action) {
  const {
    payload: { stixData }
  } = action;

  try {
    const { json: newCaseStixData } = yield apiPerformAddStixData(
      stixData.caseUuid,
      stixData
    );

    yield put(addNotification({ message: 'Added stix bundle successfully' }));

    yield put(
      assignDataToCase({
        caseUuid: stixData.caseUuid,
        dataUuid: newCaseStixData.uuid
      })
    );

    yield call(invalidateCaseActivity, stixData.caseUuid);
  } catch (ex) {
    yield put(addNotification({ message: 'Add stix bundle has failed' }));
  }
}

function* watchFetchStix() {
  yield takeLatest(createFetchStix, performFetchStix);
}
function* watchAddStixData() {
  yield takeLatest(createAddStixData, performAddStixData);
}

function* watchDownloadCaseSTIXdata() {
  yield takeLatest(createDownloadCaseStixData, performDownloadCaseStixData);
}

export default [
  watchFetchStix(),
  watchAddStixData(),
  watchDownloadCaseSTIXdata()
];
