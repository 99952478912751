import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import {
  usersSelector,
  usersWithDisplayNameSelector
} from 'states/users/selectors';
import {
  actionAssigneeGroupsSelector,
  caseAssigneeGroupsSelector
} from 'states/sysprops/selectors';
import { DefaultRootState } from 'react-redux';

export const childGroupsSelector = ({
  groupMembers: { childGroups }
}: IStore) => childGroups;

export const singleChildGroupSelector = createSelector(
  childGroupsSelector,
  (_, groupUuid) => groupUuid,
  (childGroups, groupUuid) => (childGroups && childGroups[groupUuid]) || []
);

export const groupMembersSelector = ({ groupMembers: { members } }: IStore) =>
  members;

export const singleGroupMemberSelector = createSelector(
  groupMembersSelector,
  (_, groupUuid) => groupUuid,
  (groupMembers, groupUuid) => groupMembers[groupUuid]
);

const groupMemeberUuidsSelector = createSelector(
  groupMembersSelector,
  (_, groupUuid) => groupUuid,
  (groupMembers, groupUuid) =>
    groupMembers[groupUuid] && groupMembers[groupUuid].map(({ uuid }) => uuid)
);

export const singleGroupNonMemberUsers = createSelector(
  groupMemeberUuidsSelector,
  usersSelector,
  (groupUuids = [], users) =>
    users.filter(({ uuid }) => !groupUuids.includes(uuid))
);

//yes, this exists in the /groups/selectors.tsx, but we must avoid a circular dependency else webpack will throw a hissy fit and NOT TELL YOU WHY
const groupsSelector = ({ groups: { list } }: IStore) => list || [];

export const deepGroupMembersSelector = ({
  groupMembers: { deepMembers }
}: IStore) => deepMembers;

export const deepGroupMembersStatusSelector = ({
  groupMembers: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;

export const singleDeepGroupMemberSelector = createSelector(
  deepGroupMembersSelector,
  (_, props) => props?.uuid,
  (groupMembers, groupUuid) => groupMembers && groupMembers[groupUuid]
);

export const groupNameToUuidSelector = createSelector(
  groupsSelector,
  (_, groupName) => groupName,
  (groups, groupName) =>
    groups
      .filter((o) => o.category_name === 'organisation')
      .find((o) => o.name === groupName)?.uuid
) as unknown as (state: DefaultRootState, groupName: string) => string; //OPERATION TYPES CLEAN UP

export const singleDeepGroupMembersWithDisplayNameSelector = createSelector(
  singleDeepGroupMemberSelector,
  usersWithDisplayNameSelector,
  (deepMembers, allUsers) => {
    const deepMemberUuids = deepMembers?.map((mem) => mem.uuid);
    return allUsers
      .filter((user) => deepMemberUuids?.includes(user.uuid))
      .filter(({ human }) => human);
  }
);

const filterAssigneesBySysProp = (
  deepMembers,
  groups,
  assigneeGroupNames,
  currentAssignee = ''
) => {
  //filter out deleted users EXCEPT for the current assignee, otherwise we wouldnt be able to see them on caseview page
  const filteredDeepMembers = deepMembers.filter(
    (user) => !user.deleted || user.username === currentAssignee
  );
  if (!assigneeGroupNames) {
    return filteredDeepMembers;
  }
  if (!groups) {
    return [];
  }
  const assigneeGroups = assigneeGroupNames
    .map((name) => groups.find((group) => group.name === name))
    .filter((group) => group)
    .map(({ uuid }) => uuid);

  return filteredDeepMembers.filter(
    (user) =>
      user.belongsTo &&
      user.belongsTo.filter(({ uuid }) => assigneeGroups.includes(uuid)).length
  );
};

export const deepCaseAssigneesSelector = createSelector(
  singleDeepGroupMembersWithDisplayNameSelector,
  groupsSelector,
  caseAssigneeGroupsSelector,
  (_, props) => props?.assignee,
  filterAssigneesBySysProp
);

export const deepActionAssigneesSelector = createSelector(
  singleDeepGroupMembersWithDisplayNameSelector,
  groupsSelector,
  actionAssigneeGroupsSelector,
  (_, props) => props?.assignee,
  filterAssigneesBySysProp
);
