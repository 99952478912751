/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { UserSelect } from 'components/fields/UserSelect';
import { useActionAssignees, useSingleUser } from 'hooks/UserHooks';
import { useDispatch } from 'react-redux';
import { updateCasePlaybookAction } from 'states/casePlaybooks/actions';
import { Grid } from '@mui/material';
import { useCasePlaybookListItemActionStyles } from '../styles';
import { createUpdateCaseAction } from 'states/caseActions/actions';

export const ActionItemAssignee = ({
  securityCase,
  casePlaybookUuid,
  action,
  belongsToPlaybook
}) => {
  const { assigneeUuid, actionUuid, status } = action;
  const { data: currentAssignee } = useSingleUser(assigneeUuid);
  const { data: users } = useActionAssignees(
    securityCase.org,
    currentAssignee?.username
  );
  const dispatch = useDispatch();
  const { assigneeSelect } = useCasePlaybookListItemActionStyles();

  const handleChange = (event) => {
    if (!actionUuid) {
      return;
    }
    const assigneeUuid = event.target.value.uuid;
    const caseUuid = securityCase.uuid;
    if (belongsToPlaybook) {
      dispatch(
        updateCasePlaybookAction({
          caseUuid,
          playbookUuid: casePlaybookUuid,

          actionInstance: {
            ...action,
            assigneeUuid,
            status
          }
        })
      );
    } else {
      dispatch(
        createUpdateCaseAction({
          caseUuid,
          actionInstance: {
            ...action,
            // https://cydarm.slack.com/archives/C033ZKMMAJ1/p1668466541367079
            assigneeUuid,
            status
          }
        })
      );
    }
  };

  return (
    <Grid item xs={2}>
      <UserSelect
        onChange={handleChange}
        value={currentAssignee}
        className={assigneeSelect}
        users={users}
        placeholder="Select assignee"
      />
    </Grid>
  );
};
