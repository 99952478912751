/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Chip, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useDataAcls } from 'hooks/AclHooks';
import { useEffect } from 'react';
import { useCaseViewPageReportStyles } from './styles';

export const GroupFilter = ({ caseData, setAclList, aclList }) => {
  const { autocomplete, chipDiv } = useCaseViewPageReportStyles();
  const { data: aclFullList } = useDataAcls();
  const currentAcl =
    caseData && aclFullList.find(({ uuid }) => uuid === caseData.acl);

  const currentAclReadList =
    currentAcl && currentAcl.aci.filter((el) => el.permission.label === 'read');

  const optionLabel = currentAclReadList
    ? currentAclReadList.map((el) => el.group.name)
    : [];

  const handleAddAcl = (aclLabel: Array<string>) => {
    aclLabel && setAclList(aclLabel);
  };

  const handleRemoveAcl = (aclLabel: string) => {
    aclList &&
      setAclList((prevState) => {
        return prevState.filter((el) => el !== aclLabel);
      });
  };

  useEffect(() => {
    const readList =
      currentAcl &&
      currentAcl.aci
        .filter((el) => el.permission.label === 'read')
        .map((el) => el.group.name);
    readList && setAclList(readList);
  }, [currentAcl, setAclList]);

  if (!aclList) {
    return null;
  }
  return (
    <Autocomplete
      className={autocomplete}
      multiple
      disableClearable={true}
      options={optionLabel}
      value={aclList}
      filterSelectedOptions
      onChange={(e, value) => handleAddAcl(value)}
      renderTags={(value) =>
        value.map((option) => (
          <Chip
            color="primary"
            label={option}
            onDelete={() => handleRemoveAcl(option)}
            key={option}
            className={chipDiv}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          label={'Group filter'}
          maxRows={1}
        />
      )}
    />
  );
};
