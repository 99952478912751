/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { Case, IDetailCase } from 'interface/Case.interface';
import { ICaseSeverity } from 'interface/CaseSeverity.interface';
import { CaseState } from 'interface/CaseState.interface';
import moment from 'moment';
import { partitionArray } from './ArrayUtils';

export const getAssignedCases = (cases: Case[], username: string) =>
  cases.filter(({ assignee }) => assignee === username);

const sortCasesFnCreator = (
  orderBy: keyof IDetailCase & string,
  order: 'asc' | 'desc'
) => {
  let [dir1, dir2] = order === 'asc' ? [1, -1] : [-1, 1];
  return (caseA: IDetailCase, caseB: IDetailCase) => {
    // See behaviour of the types here:
    // Just retrieve the value of caseA[orderBy] inside the switch branch for correct type inference
    //https://www.typescriptlang.org/play?#code/C4TwDgpgBAYg9nKBeKBvAUFLUCGAuKAZ2ACcBLAOwHMBuKTbAIwIoFcBbRiEu9AX3SD0oSLAQBpCCELIoAaylwAZmMQAyIqUq16gpawoBjYGTgUicEsHhwAFEoQEbAGnlSnEqYQCUaIdihDM2JcAEZZBzgAbQUQAF1eBiwyFVtY5CQUAHIcLN8MAID-QqwgihCcFEiYqQT6EqwkwoB6ZoBZOFZCaDgAN24oXpwSGQALbmhgRG7J8GguUZxe01YSeobWqAAVOcJDcjBgKAo4I8J2YaOIE9YqUagp4+GSOAB3B-GHuagHNZxQgB06xKZUIcAANhAAeC4FRbP9XDhvLwAgImikoGkpBlsow8gVsAI+EA

    switch (orderBy) {
      case 'created':
      case 'modified':
        return Date.parse(caseA[orderBy]) > Date.parse(caseB[orderBy])
          ? dir1
          : dir2;

      case 'minSlaSeconds': {
        const a = caseA[orderBy];
        const b = caseB[orderBy];

        // Null|| undefined checking is probably not necessary, but I don't want to change the type from minSlaSeconds?: number to minSlaSeconds: number|null just yet.
        if (a === undefined || a === null) {
          return 1;
        }
        if (a !== undefined && a !== null && (b === undefined || b === null)) {
          return -1;
        }
        return moment.duration(a).asMilliseconds() >
          moment.duration(b).asMilliseconds()
          ? dir1
          : dir2;
      }

      case 'rank':
      case 'severity':
      case 'playbookProgressPercent': {
        const a = caseA[orderBy];
        const b = caseB[orderBy];
        return (a || 0) > (b || 0) ? dir1 : dir2;
      }

      case 'locator':
      case 'assignee':
      case 'description':
      case 'status':
      case 'org': {
        const a = caseA[orderBy] || '';
        const b = caseB[orderBy] || '';
        return a > b ? dir1 : dir2;
      }
      default:
        return 0;
    }
  };
};

const searchCasesFnCreator = (searchQuery, searchOptions?: string[]) => {
  if (!searchQuery || !searchOptions || searchOptions.length <= 0) {
    return;
  }

  return ({ locator, tags, description }: Case) => {
    if (
      searchOptions.includes('locator') &&
      locator.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true;
    }

    if (
      searchOptions.includes('description') &&
      description.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return true;
    }

    if (
      searchOptions.includes('tags') &&
      tags &&
      tags.some((tag) => tag.includes(searchQuery))
    ) {
      return true;
    }

    return false;
  };
};

const filterCasesFnCreator = (filterData?: any) => {
  if (!filterData || Object.keys(filterData).length <= 0) {
    return;
  }

  return ({
    uuid,
    severity,
    isWatched,
    assignee,
    status,
    created,
    modified,
    members,
    tags,
    org
  }: Case) => {
    //TODO clean this up. This whole function just feels wrong. I feel like I'm sinning by adding to it
    let shouldInclude = true;

    if (filterData.caseUuids) {
      shouldInclude = filterData.caseUuids.has(uuid);
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.tags && filterData.tags.length > 0) {
      shouldInclude =
        shouldInclude &&
        tags &&
        filterData.tags.some(({ value }) => tags.includes(value));
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.type) {
      shouldInclude =
        shouldInclude &&
        ((filterData.type === 'Group' && members && members.length > 0) ||
          (filterData.type === 'Standalone' &&
            (!members || members.length <= 0)));
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.severity && filterData.severity.length) {
      shouldInclude =
        shouldInclude && filterData.severity.includes(String(severity));
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.isWatched) {
      shouldInclude =
        shouldInclude && filterData.isWatched === String(isWatched);
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.assignees && filterData.assignees.length) {
      shouldInclude =
        shouldInclude &&
        (filterData.assignees.includes('unassigned')
          ? assignee === ''
          : filterData.assignees.includes(assignee));
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.caseStates !== undefined) {
      shouldInclude = shouldInclude && filterData.caseStates.includes(status);
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.createdAt !== undefined) {
      shouldInclude =
        shouldInclude &&
        (!filterData.createdAt.from ||
          moment(filterData.createdAt.from).isSameOrBefore(
            moment(created),
            'days'
          )) &&
        (!filterData.createdAt.to ||
          moment(filterData.createdAt.to).isSameOrAfter(
            moment(created),
            'days'
          ));
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.lastModified !== undefined) {
      shouldInclude =
        shouldInclude &&
        (!filterData.lastModified.from ||
          moment(filterData.lastModified.from).isSameOrBefore(
            moment(modified),
            'days'
          )) &&
        (!filterData.lastModified.to ||
          moment(filterData.lastModified.to).isSameOrAfter(
            moment(modified),
            'days'
          ));
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    if (filterData.organisations !== undefined) {
      shouldInclude = shouldInclude && filterData.organisations.includes(org);
    }
    if (!shouldInclude) {
      return shouldInclude;
    }

    return shouldInclude;
  };
};

export const sortCases = <T extends Case>(
  cases: T[],
  orderBy: keyof Case = 'created',
  order: 'asc' | 'desc' = 'desc'
) => {
  const sortFunction = sortCasesFnCreator(orderBy, order);

  if (!sortFunction) {
    return cases;
  }

  return cases.sort(sortFunction);
};

export const searchCases = <T extends Case>(
  cases: T[],
  searchQuery: string,
  searchOptions?: string[]
) => {
  const searchFunction = searchCasesFnCreator(searchQuery, searchOptions);

  if (!searchFunction) {
    return cases;
  }

  return cases.filter(searchFunction);
};

export const filterCases = <T extends Case>(cases: T[], filterData?: any) => {
  const filterFunction = filterCasesFnCreator(filterData);

  if (!filterFunction) {
    return cases;
  }

  return cases.filter(filterFunction);
};

export const paginateCases = <T extends Case>(
  cases: T[],
  { page, perPage }: { page: number; perPage: number }
) => {
  const returnVal = cases.slice((page - 1) * perPage, page * perPage);
  return returnVal;
};

export const transformSingleCase = (
  securityCase: Case,
  watchingCases: string[]
) => ({
  ...securityCase,
  isWatched: watchingCases.includes(securityCase.uuid)
});

export function getOpenCases(cases: Case[]): Case[] {
  return cases.filter((v) => {
    return !Boolean(v.closed);
  });
}

// Todo, possibly change the return type
export function partitionCasesBySeverity(
  cases: Case[],
  severityList: ICaseSeverity[]
): Array<Array<Case>> {
  const indexMap = new Map<number | null, number>();

  severityList.forEach((v, i) => {
    indexMap.set(v.precedence, i);
  });

  return partitionArray(cases, severityList.length, (v) => {
    const result = indexMap.get(v.severity);
    if (result === undefined) {
      throw new Error(
        `Something has gone wrong, severity value : ${v.severity} was not found in the severityList`
      );
    }

    return result;
  });
}

export function partitionCasesByStatus(
  cases: Case[],
  stateList: CaseState[]
): Array<Array<Case>> {
  const indexMap = new Map<string, number>();

  stateList.forEach((v, i) => {
    indexMap.set(v.name, i);
  });

  return partitionArray(cases, stateList.length, (v) => {
    const result = indexMap.get(v.status);

    if (result === undefined) {
      console.warn(`state value : ${v.status} was not found in the stateList`);
      return null;
    }
    return result;
  });
}

export function partitionCasesBySeverityThenStatus(
  cases: Case[],
  severityList: ICaseSeverity[],
  stateList: CaseState[]
): Array<Array<Array<Case>>> {
  const groupedBySeverity = partitionCasesBySeverity(cases, severityList);

  const nowGroupedByStatus = groupedBySeverity.map((v) => {
    return partitionCasesByStatus(v, stateList);
  });

  return nowGroupedByStatus;
}
