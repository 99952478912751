/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Node } from 'reactflow';
import { GenericNode } from 'components/_playbooks/CacaoFlowchart/type';

enum NodePath {
  FAILURE = 'failure',
  FALSE = 'false',
  TRUE = 'true',
  SUCCESS = 'success',
  COMPLETE = 'complete'
}

// "failed" appears on the left side of "false"
// "false" appears on the left side of "true" and so on
const CONDITION_NODE_ORDERING = [
  NodePath.FAILURE,
  NodePath.FALSE,
  NodePath.TRUE,
  NodePath.SUCCESS,
  NodePath.COMPLETE
];

export class cacaoNodeOrderingUtils {
  public static findStartNodes(nodes: Array<Node<GenericNode>>) {
    return nodes.filter((node) => {
      return node.data.nodeType?.type === 'start';
    });
  }

  public static assignOrderToChildNodes(
    node: Node<GenericNode>,
    nodes: Array<Node<GenericNode>>,
    orderPrefix: string = `1`
  ): Array<Node<GenericNode> & { order: string }> {
    const nodeType = node?.data?.nodeType;
    const currentNodeType = nodeType?.type;
    const currentNodeWithOrder = {
      ...node,
      order: orderPrefix
    };
    switch (currentNodeType) {
      case 'start':
      case 'action':
      case 'playbook-action': {
        const nextNodeId = nodeType.nextNode;
        const nextNode = nodes.find((n) => n.id === nextNodeId);
        return [
          currentNodeWithOrder,
          ...this.assignOrderToChildNodes(nextNode!, nodes, `${orderPrefix}1`)
        ];
      }
      case 'if-condition': {
        const nextNodeIds = nodeType.nextNode;
        const falseNode = nodes.find((n) => n.id === nextNodeIds.onFalse);
        const trueNode = nodes.find((n) => n.id === nextNodeIds.onTrue);
        const successNode = nodes.find((n) => n.id === nextNodeIds.onSuccess);
        const failureNode = nodes.find((n) => n.id === nextNodeIds.onFailure);
        const completeNode = nodes.find(
          (n) => n.id === nextNodeIds.onCompletion
        );
        const falseOrderPrefix = `${orderPrefix}${
          CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.FALSE) + 1
        }`;
        const trueOrderPrefix = `${orderPrefix}${
          CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.TRUE) + 1
        }`;
        const successOrderPrefix = `${orderPrefix}${CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.SUCCESS) + 1}`;
        const failureOrderPrefix = `${orderPrefix}${CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.FAILURE) + 1}`;
        const completeOrderPrefix = `${orderPrefix}${CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.COMPLETE) + 1}`;
        return [
          currentNodeWithOrder,
          ...(failureNode
            ? this.assignOrderToChildNodes(
                failureNode!,
                nodes,
                failureOrderPrefix
              )
            : []),
          ...(falseNode
            ? this.assignOrderToChildNodes(falseNode!, nodes, falseOrderPrefix)
            : []),
          ...(trueNode
            ? this.assignOrderToChildNodes(trueNode!, nodes, trueOrderPrefix)
            : []),
          ...(successNode
            ? this.assignOrderToChildNodes(
                successNode!,
                nodes,
                successOrderPrefix
              )
            : []),
          ...(completeNode
            ? this.assignOrderToChildNodes(
                completeNode!,
                nodes,
                completeOrderPrefix
              )
            : [])
        ];
      }
      case 'while-condition': {
        const nextNodeIds = nodeType.nextNode;
        const trueNode = nodes.find((n) => n.id === nextNodeIds.onTrue);
        const completeNode = nodes.find(
          (n) => n.id === nextNodeIds.onCompletion
        );
        const trueOrderPrefix = `${orderPrefix}${
          CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.TRUE) + 1
        }`;
        const completeOrderPrefix = `${orderPrefix}${CONDITION_NODE_ORDERING.findIndex((n) => n === NodePath.COMPLETE) + 1}`;

        return [
          currentNodeWithOrder,
          ...(trueNode
            ? this.assignOrderToChildNodes(trueNode!, nodes, trueOrderPrefix)
            : []),

          ...(completeNode
            ? this.assignOrderToChildNodes(
                completeNode!,
                nodes,
                completeOrderPrefix
              )
            : [])
        ];
      }
      case 'parallel': {
        const parallelNodes = nodeType.nextNode.onParallel;
        const completionNodeId = nodeType.nextNode.onCompletion;
        const completionNode = completionNodeId
          ? nodes.find((n) => n.id === completionNodeId)
          : null;
        return [
          currentNodeWithOrder,
          ...parallelNodes
            .map((nextNodeId, index) => {
              const nextNode = nodes.find((n) => n.id === nextNodeId)!;
              return this.assignOrderToChildNodes(
                nextNode,
                nodes,
                `${orderPrefix}${index + 1}`
              );
            })
            .flat(),
          ...(completionNode
            ? this.assignOrderToChildNodes(
                completionNode,
                nodes,
                `${orderPrefix}${parallelNodes.length + 1}`
              )
            : [])
        ];
      }
      //      case 'switch-condition':
      case 'end': {
        return [currentNodeWithOrder];
      }
    }
    return [];
  }

  public static assignOrderToNodes(
    nodes: Array<Node<GenericNode>>
  ): Array<Node<GenericNode> & { order: string }> {
    const startNodes = this.findStartNodes(nodes);
    return startNodes
      .map((startNode) => this.assignOrderToChildNodes(startNode, nodes))
      .flat();
  }

  public static getNodeOrders(
    nodes: Array<Omit<Node<GenericNode>, 'position'>>
  ): Array<Omit<Node<GenericNode>, 'position'>> {
    return (
      this.assignOrderToNodes(nodes as Array<Node<GenericNode>>)
        .sort((a, b) => {
          if (a.order === undefined) {
            return -1;
          }
          if (b.order === undefined) {
            return 1;
          }
          return a.order > b.order ? 1 : -1;
        })
        // remove order field
        .map(({ order, ...rest }) => rest)
    );
  }
}
