import * as CydarmFetch from 'utils/CydarmFetch';

export async function apiFetchOrgs() {
  return CydarmFetch.cyFetchAuthenticated('/org');
}

export async function apiFetchOrgByUuid(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/org/${uuid}`);
}

export async function apiCreateOrg(name: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/org', {
    method: 'POST',
    body: JSON.stringify(name)
  });
}

export async function apiUpdateOrg(uuid: string, org: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/org/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(org)
  });
}

export async function apiDeleteOrg(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/org/${uuid}`, {
    method: 'DELETE'
  });
}
