/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';
import { useAddNotification } from './NotificationHooks';
import { useEffect } from 'react';
import {
  addPlaybookToCase,
  fetchCasePlaybooks
} from 'states/casePlaybooks/actions';
import {
  playbookStatusSelector,
  casePlaybooksSelector
} from 'states/casePlaybooks/selectors';
import { IAutoEffectReturn, useAutoEffect } from './ReduxHooks';
import {
  AtcPlaybook,
  CydApi_CasePlaybookInstance
} from 'interface/Playbook.interface';

export const useCasePlaybooks = (
  caseUuid: string
): IAutoEffectReturn<Array<CydApi_CasePlaybookInstance>> => {
  return useAutoEffect({
    selector: casePlaybooksSelector,
    ac: fetchCasePlaybooks,
    actionData: caseUuid
  });
};

export const useAddPlaybookToCaseEffect = () => {
  const { isAddedPlaybookToCaseSuccess, isAddingPlaybookToCaseError } =
    useSelector(playbookStatusSelector);
  const addNotification = useAddNotification();

  // Watch Case Success Effect
  useEffect(() => {
    if (!isAddedPlaybookToCaseSuccess) {
      return;
    }

    addNotification({ message: 'Added playbook to case successfully' });
  }, [isAddedPlaybookToCaseSuccess, addNotification]);

  // Watch Case Failed Effect
  useEffect(() => {
    if (!isAddingPlaybookToCaseError) {
      return;
    }

    addNotification({ message: 'Error occurred adding playbook to case' });
  }, [isAddingPlaybookToCaseError, addNotification]);
};

export const useRemoveCasePlaybookEffect = () => {
  const { isRemovedPlaybookFromCaseSuccess, isRemovingPlaybookFromCaseError } =
    useSelector(playbookStatusSelector);
  const addNotification = useAddNotification();

  // Watch Case Success Effect
  useEffect(() => {
    if (!isRemovedPlaybookFromCaseSuccess) {
      return;
    }

    addNotification({ message: 'Removed playbook successfully' });
  }, [isRemovedPlaybookFromCaseSuccess, addNotification]);

  // Watch Case Failed Effect
  useEffect(() => {
    if (!isRemovingPlaybookFromCaseError) {
      return;
    }

    addNotification({ message: 'Error occurred removing playbook' });
  }, [isRemovingPlaybookFromCaseError, addNotification]);
};

export const useAddPlaybookToCase = (
  caseUuid: string
): ((
  playbook: AtcPlaybook,
  callback?: (newPlaybookInstanceUuid: string) => void
) => void) => {
  const dispatch = useDispatch();
  return (playbook, callback) => {
    dispatch(
      addPlaybookToCase({
        caseUuid: caseUuid,
        playbookUuid: playbook.uuid as string,
        callback
      })
    );
  };
};
