import { PaginatedLoadMoreFunctionResult } from 'components/_dataDisplay/CydDataTable/CydDataTable';
import { CaseFilterParams } from 'hooks/CaseHooksRq';
import { Case } from 'interface/Case.interface';
import { ICaseSeverity } from 'interface/CaseSeverity.interface';
import * as CydarmFetch from 'utils/CydarmFetch';
import { CydApi_BaseCase } from './reports/WatchingCasesService';

//map case field names to sort names for filtering parameters
const sortFieldMap = new Map([
  ['org', 'org'],
  ['description', 'description'],
  ['severity', 'severity'],
  ['created', 'created'],
  ['modified', 'modified'],
  ['assignee', 'assignee'],
  ['status', 'status'],
  ['totalCompletedActionsInAllPlaybooks', 'completed_actions'],
  ['minSlaSeconds', 'min_sla_seconds'],
  ['minSlaName', 'min_sla_name']
]);

function convertCaseFilterParamsToQueryString(
  filterParams: CaseFilterParams
): string {
  const newObj = {
    'page[number]': `${filterParams.page.number}`,
    'page[size]': `${filterParams.page.size}`
  } as Record<string, string>;

  Object.entries(filterParams.filter).forEach((v) => {
    const [key, value] = v;

    if (value === undefined) {
      return;
    }

    if (Array.isArray(value)) {
      const updatedValues = value.map((item) => {
        if (item.includes(',')) {
          return `"${item}"`;
        }
        return item;
      });
      newObj[`filter[${key}]`] = updatedValues.join(',');
    } else {
      newObj[`filter[${key}]`] = value;
    }
  });

  if (filterParams.sort) {
    // check if we have a mapping for sort field name for a case property name
    newObj.sort =
      sortFieldMap.get(filterParams.sort) ??
      sortFieldMap.get(filterParams.sort) ??
      filterParams.sort;
  }

  if (filterParams.sort_dir) {
    newObj.sort_dir = filterParams.sort_dir;
  }
  return new URLSearchParams(newObj).toString();
}

export async function apiFetchCasesFiltered(filterParams: CaseFilterParams) {
  const queryParams = convertCaseFilterParamsToQueryString(filterParams);

  const result = await CydarmFetch.cyFetchAuthenticated(`/case?${queryParams}`);

  const parsedResult: PaginatedLoadMoreFunctionResult<CydApi_BaseCase> = {
    paginationInfo: {
      totalNumItems: result.json.meta?.totalResults ?? 0,
      pageNum: filterParams.page.number,
      numPerPage: filterParams.page.size
    },
    data: result.json.data
  };

  return parsedResult;
}

// caseData
export async function apiGenerateReport(caseUuid, data) {
  return CydarmFetch.cyFetchFileAuthenticated(`/report/case/${caseUuid}`, {
    method: 'POST',
    body: JSON.stringify({
      ...data
    })
  });
}

// caseGroups
export async function apiPerformFetchGroupsForCase(caseUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/member`, {
    method: 'GET'
  });
}

/**
 * This endpoint appears to return an inconsistent response from what JSON API expects
 * https://jsonapi.org/format/#fetching-resources-responses
 * Which is an array of 'JSON API' like objects, that have that attributes field, etc.
 * @param parentCaseUuid
 * @returns
 */
export async function apiFetchCasesByParentUuid(
  parentCaseUuid: string,
  pageSize: number,
  pageNumber: number
): Promise<
  CydarmFetch.CyFetchResponse<{
    data: Array<Case>;
    meta?: {
      totalResults: number;
    };
    links: unknown;
  }>
> {
  const urlParams = new URLSearchParams();
  urlParams.set('filter[parent_uuid]', parentCaseUuid);
  urlParams.set('page[size]', `${pageSize}`);
  urlParams.set('page[number]', `${pageNumber}`);

  return CydarmFetch.cyFetchAuthenticated(`/case?${urlParams.toString()}`, {
    method: 'GET'
  });
}

export async function apiFetchParentCasesByMemberuuid(memberCaseUuid: string) {
  throw new Error('Not implemented');
}

// caseMetas
export async function apiPerformFetchMetas() {
  return CydarmFetch.cyFetchAuthenticated('/meta');
}

export async function apiPerformCreateMeta(meta: any) {
  return CydarmFetch.cyFetchAuthenticated('/meta', {
    method: 'POST',
    body: JSON.stringify(meta)
  });
}

export async function apiPerformUpdateMeta(uuid: any, meta: any) {
  return CydarmFetch.cyFetchAuthenticated(`/meta/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify({ ...meta })
  });
}

export async function apiPerformDeleteMeta(uuid: any) {
  return CydarmFetch.cyFetchAuthenticated(`/meta/${uuid}`, {
    method: 'DELETE',
    body: JSON.stringify({ uuid })
  });
}

// cases
export async function apiPerformFetchCases(): Promise<
  CydarmFetch.CyFetchResponse<Array<CydApi_BaseCase>>
> {
  return CydarmFetch.cyFetchAuthenticated(`/case`);
}

export async function apiPerformCreateCase(caseData: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/case`, {
    method: 'POST',
    body: JSON.stringify(caseData)
  });
}

export async function apiPerformFetchCaseByUuid(
  caseUuid: string
): Promise<CydarmFetch.CyFetchResponse<CydApi_BaseCase>> {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}`);
} // Also used in fetchCaseAndMembers

export async function apiPerformFetchCaseByLocator(
  locator: string
): Promise<CydarmFetch.CyFetchResponse<CydApi_BaseCase>> {
  return CydarmFetch.cyFetchAuthenticated(`/case/locator/${locator}`);
}

export async function apiPerformUpdateCase(
  caseUuid: string,
  caseData: unknown
) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}`, {
    method: 'PUT',
    body: JSON.stringify(caseData)
  });
}

export async function apiPerformUpdateCaseMeta(
  caseUuid: string,
  caseMeta: unknown
) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/meta`, {
    method: 'POST',
    body: JSON.stringify(caseMeta)
  });
}

export async function apiPerformAddMemberToCase(
  caseUuid: string,
  memberUuid: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/case/${caseUuid}/member/${memberUuid}`,
    {
      method: 'POST'
    }
  );
}

export async function apiPerformRemoveMemberFromCase(
  caseUuid: string,
  memberUuid: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/case/${caseUuid}/member/${memberUuid}`,
    {
      method: 'DELETE'
    }
  );
}

export async function apiPerformAddTagToCase(
  caseUuid: string,
  tagValue: string
) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/tag`, {
    method: 'POST',
    body: JSON.stringify({ caseUuid, tagValue })
  });
}

export async function apiPerformRemoveTagFromCase(
  caseUuid: string,
  tagValue: string
) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/tag`, {
    method: 'DELETE',
    body: JSON.stringify({
      caseUuid,
      tagValue
    })
  });
}

export async function apiPerformFetchCaseSlas(caseUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/sla`, {
    method: 'GET'
  });
}

// caseSeverity
export async function apiPerformFetchCaseSeverity(): Promise<
  CydarmFetch.CyFetchResponse<Array<ICaseSeverity>>
> {
  return CydarmFetch.cyFetchAuthenticated(`/case-severity`);
}

// caseStates
export async function apiPerformFetchCaseStates() {
  return CydarmFetch.cyFetchAuthenticated('/case-status');
}

// caseStix
export async function apiPerformFetchStix(caseUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/stix`);
}

export async function apiPerformDownloadCaseStixData(caseData: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseData}/stix`);
}

export async function apiPerformAddStixData(
  stixDataUuid,
  stixData,
  parentCommentUuid?: string | null
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/case/${stixDataUuid}/stix${
      parentCommentUuid ? `/${parentCommentUuid}` : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify(stixData)
    }
  );
}

export type CydApi_CaseTag = {
  uuid: string;
  value: string;
  description: string;
  acl: string;
  deprecated: boolean;
};

// caseTags
export async function apiPerformFetchCaseTags(): Promise<
  CydarmFetch.CyFetchResponse<Array<CydApi_CaseTag>>
> {
  return CydarmFetch.cyFetchAuthenticated('/case-tag');
}

export async function apiPerformCreateCaseTags(caseTags) {
  return CydarmFetch.cyFetchAuthenticated('/case-tag', {
    method: 'POST',
    body: JSON.stringify({ ...caseTags })
  });
}

export async function apiPerformUpdateCaseTags(
  caseUuid,
  caseTags
): Promise<CydarmFetch.CyFetchResponse<CydApi_CaseTag>> {
  return CydarmFetch.cyFetchAuthenticated(`/case-tag/${caseUuid}`, {
    method: 'PUT',
    body: JSON.stringify({ ...caseTags })
  });
}

export async function apiPerformDeleteCaseTags(
  caseUuid: string
): Promise<CydarmFetch.CyFetchResponse<null>> {
  return CydarmFetch.cyFetchAuthenticated(`/case-tag/${caseUuid}`, {
    method: 'DELETE'
  });
}
