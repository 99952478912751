/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as TokenManager from './TokenManager';
import { signOut } from 'states/auth/actions';
import { pathOr } from './ObjectUtils';
import { CydarmFetchOption } from 'interface/CydarmFetch.interface';
import { CydarmRoute } from 'interface/CydarmRoute';
import { Store } from '@reduxjs/toolkit';

export type CyFetchResponse<T = unknown> = {
  json: T;
  response: Response;
};

export const endpoint: string = '/cydarm-api';
export const authzHeader: string = 'X-Cydarm-Authz';

export const CYDARM_FETCH_ERROR = {
  TOKEN_REQUIRED: 'CydarmFetch: a valid token is required'
};

/**
/**
 * This is an important pattern to avoid circular dependencies anywhere we interact with store directly
 * See:
 *
 * https://github.com/reduxjs/redux-toolkit/issues/1540
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 *
 *
 * @param store
 */

let store: Store;
export function provideStore(_store: Store) {
  store = _store;
}

/**
 * Wraps an API call with the default endpoint, using the ES6 `fetch` function
 * @param path API path you want to hit (eg. `/case/{caseid}`)
 * @param init JS ES6 `fetch` RequestInit object
 */
export function cyFetch(
  path: string,
  init: RequestInit,
  option?: CydarmFetchOption
) {
  return fetch(endpoint + path, init)
    .then((response: Response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response
        .text()
        .then((text) => (text ? JSON.parse(text) : {}))
        .then((json) => ({ response, json }));
    })
    .catch((errorResp: Response) => {
      console.error(`Error: when calling ${path}`, errorResp, init);
      const autoSignOut = pathOr(true, ['autoSignOut'], option);
      // I don't know what this logic is meant to be doing
      // But it causes an unneccesary call to be made if the 401 is because of bad username/password
      // So only do it if no token exists
      if (autoSignOut && errorResp.status === 401) {
        if (TokenManager.isAuthenticated()) {
          store.dispatch(signOut());
        }
      }
      return Promise.reject(errorResp);
    });
}

/**
 * Uses `cydarmFetch` API path wrapper and appends a token as an cydarm header
 * @param path API path you want to hit (eg. `/case/{caseid}`)
 * @param token Access token to append to Cydarm header
 * @param init JS ES6 `fetch` RequestInit object
 */
export function cyFetchWithBearerToken(
  path: string,
  token: string,
  init?: RequestInit,
  option?: CydarmFetchOption
) {
  if (!init) {
    init = {};
  }
  if (!token && path !== '/auth/session/') {
    window.location.replace(
      `${CydarmRoute.AUTH}?redirect=${window.location.pathname}${window.location.search}`
    );

    return Promise.reject(CYDARM_FETCH_ERROR.TOKEN_REQUIRED);
  }

  if (!init.headers) {
    init.headers = new Headers({ [authzHeader]: token });
  } else {
    (init.headers as Headers).set(authzHeader, token);
  }
  return cyFetch(path, init, option);
}

/**
 * Uses `cydarmFetch` API path wrapper and base64 encoded request
 * @param path API path you want to hit (eg. `/case/{caseid}`)
 * @param username Username to append to request body
 * @param password Password to append to request body
 * @param init JS ES6 `fetch` RequestInit object
 */
export function cyFetchWithRequestBodyAuth(
  path: string,
  username: string,
  password: string
): Promise<any> {
  const initReq: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      username: btoa(username),
      password: btoa(password)
    })
  };
  return cyFetch(path, initReq);
}

/**
 * The reason this is exported, is so that the test can reset it! Do not access directly, outside of the cyFetchAuthenticated function!
 */
export const existingRequestMap = new Map();

/**
 * Call the default endpoint with the stored session token
 * @param path API path you want to hit (eg. `/case/{caseid}`)
 * @param init JS ES6 `fetch` RequestInit object
 */

export function cyFetchAuthenticated(
  path: string,
  init?: RequestInit,
  option?: CydarmFetchOption
) {
  /**
   * Because multiple identical GET requests could fire at a single time, keep a record of the pending requests, and just return the promise of the existing request
   */

  // This only applies to GET requests
  if (!init || init.method === 'GET') {
    // Find any existing ones
    const existingPromise = existingRequestMap.get(path);
    // If they do exist, just return it
    if (existingPromise) {
      return existingPromise;
    }

    // If it doesn't exist
    else {
      // Create the request
      const promise = cyFetchWithBearerToken(
        path,
        TokenManager.getAccessToken(),
        init,
        option
      );

      // set it into the map
      existingRequestMap.set(path, promise);

      // When it resolves remove it from the map, after a delay
      promise.finally(() => {
        existingRequestMap.delete(path);
      });

      // Return the promise
      return promise;
    }
  }

  return cyFetchWithBearerToken(
    path,
    TokenManager.getAccessToken(),
    init,
    option
  );
}

/**
 * Call the default endpoint with the stored session token
 * @param path API path you want to hit (eg. `/case/{caseid}`)
 * @param init JS ES6 `fetch` RequestInit object
 */
export function cyFetchFileAuthenticated(path: string, init?: RequestInit) {
  if (!init) {
    init = {};
  }
  const token = TokenManager.getAccessToken();
  if (!token) {
    return Promise.reject(CYDARM_FETCH_ERROR.TOKEN_REQUIRED);
  }
  if (!init.headers) {
    init.headers = new Headers({ [authzHeader]: token });
  } else {
    (init.headers as Headers).forEach((value, key) =>
      (init?.headers as Headers).set(key, value)
    );
    (init.headers as Headers).set(authzHeader, token);
  }

  return fetch(endpoint + path, init)
    .then((response: Response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }
      return Promise.resolve(response);
      // return response.blob();
    })
    .catch((error) => {
      console.error(`Error: when calling ${path}`, error, init);
      throw new Error(error);
    });
}
