/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useSelector, shallowEqual } from 'react-redux';
import { useCallback } from 'react';
import { createFetchGroups } from 'states/groups/actions';
import { createGroupReport } from 'states/reports/actions';
import {
  createFetchGroupMembers,
  createRemoveGroupMember,
  createFetchGroupChildGroups
} from 'states/groupMembers/actions';
import {
  groupsSelector,
  singleGroupSelector,
  singleGroupChildrenSelector,
  singleGroupNonMemberGroupsSelector,
  currentGroupsSelector,
  groupUuidsSelector,
  groupsStatusSelector,
  groupsHasLoadedSelector,
  groupsIsLoadingSelector
} from 'states/groups/selectors';
import {
  singleGroupNonMemberUsers,
  groupMembersSelector,
  singleGroupMemberSelector,
  groupNameToUuidSelector
} from 'states/groupMembers/selectors';
import { useAutoEffect } from './ReduxHooks';
import { useAllUsers } from './UserHooks';

export const useGroups = () => {
  return useAutoEffect({
    selector: groupsSelector,
    ac: createFetchGroups,
    statusSelector: groupsStatusSelector,
    equality: shallowEqual
  });
};

export const useGroupUuids = () => {
  return useAutoEffect({
    selector: groupUuidsSelector,
    ac: createFetchGroups,
    statusSelector: groupsStatusSelector,
    equality: shallowEqual
  });
};

export const useGroupMembers = () => {
  let { data: groupUuids } = useGroupUuids();
  return useAutoEffect({
    selector: groupMembersSelector,
    ac: createFetchGroupMembers,
    actionData: groupUuids,
    spreadDispatch: true
  });
};

export const useSingleGroupMembers = (groupUuid: string) => {
  return useAutoEffect({
    selector: singleGroupMemberSelector,
    ac: createFetchGroupMembers,
    actionData: groupUuid,
    equality: shallowEqual
  });
};

export const useSingleGroupChildren = (groupUuid: string) => {
  useGroups();
  return useAutoEffect({
    selector: singleGroupChildrenSelector,
    ac: createFetchGroupChildGroups,
    actionData: groupUuid,
    equality: shallowEqual
  });
};

export const useSingleGroup = (groupUuid?: string) => {
  return useAutoEffect({
    selector: singleGroupSelector,
    statusSelector: groupsStatusSelector,
    ac: createFetchGroups,
    selectorData: groupUuid,
    isLoadingSelector: groupsIsLoadingSelector,
    hasLoadedSelector: groupsHasLoadedSelector
  });
};

export const useSingleGroupNonMemberUsers = (groupUuid: string) => {
  useAllUsers();
  return useAutoEffect({
    selector: singleGroupNonMemberUsers,
    ac: createFetchGroupMembers,
    actionData: groupUuid,
    equality: shallowEqual
  });
};

export const useSingleGroupNonMemberGroups = (groupUuid: string) => {
  useGroups();
  return useAutoEffect({
    selector: singleGroupNonMemberGroupsSelector,
    ac: createFetchGroupMembers,
    actionData: groupUuid
  });
};

export const useRemoveGroupMember = (dispatch) =>
  useCallback(
    (groupUuid: string, memberUuid: string) => {
      dispatch(createRemoveGroupMember({ groupUuid, memberUuid }));
    },
    [dispatch]
  );

export const useCurrentGroups = (userUuid: string) => {
  useGroups();
  useGroupMembers();
  return useSelector((state) => currentGroupsSelector(state, userUuid));
};

export const useGroupManagementGetGroupReport = (dispatch) =>
  useCallback(() => {
    dispatch(createGroupReport());
  }, [dispatch]);

export const useUuidFromGroupName = (org) =>
  useSelector((state) => groupNameToUuidSelector(state, org));
