/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CydIconTypes } from 'components/_foundation/CydIcon/CydIcon';
import { Checkbox, TablePagination, TablePaginationProps } from '@mui/material';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import { CydPopover } from 'components/_dialogs/CydPopover/CydPopover';
import { CydTextField } from 'components/_formElements/CydTextField';
import { defaultDisplayAValueAsString } from 'utils/StringUtils';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButtonModal } from 'components/_dialogs/CydButtonModal/CydButtonModal';
import { CydConfirmModal } from 'components/_dialogs/CydConfirmModal/CydConfirmModal';
import { usePaginationAndFiltering } from './usePaginationAndFiltering';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { DEFAULT_ROWS_PER_PAGE_SETTING } from '../../../constants';

export type PaginatedLoadMoreFunctionResult<T extends RowData> = {
  paginationInfo: {
    pageNum: number;
    numPerPage: number;
    totalNumItems: number;
  };
  data: Array<T>;
};

export type PaginatedLoadMoreFunctionInput<T extends RowData> = {
  pageNum: number;
  numPerPage: number;

  sortConfig: SortConfig<T> | null;
  filterConfig: FilterConfig<T> | null;
};

/**
 * CydDataTable is not responsible for caching the results of paginated/filtered queries.
 * It's the responsibility of the function that you pass in to do that for you.
 */
export type PaginatedLoadMoreFunction<T extends RowData> = (
  data: PaginatedLoadMoreFunctionInput<T>
) => Promise<PaginatedLoadMoreFunctionResult<T>>;

type PaginationInfo<T extends RowData> =
  | {
      paginationType: 'sync'; // 'sync' - All the data already exists, and we're just paging through it.
      // Future state is 'async' in which we query for the data as we change page

      rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
    }
  | {
      paginationType: 'async';
      loadingMoreFunction: PaginatedLoadMoreFunction<T>;
      rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
    };

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [
  10,
  25,
  50,
  { label: 'All', value: -1 }
];

export type RowData = Record<string, unknown>;

// Explanation of this type:
// https://github.com/dwjohnston/ts-tutorial-series/blob/master/lesson-8-advanced-mapped-types-and-discriminated-unions/ALTERNATIVE_EXAMPLE.md
export type Column<T extends RowData> = {
  [K in keyof T]: {
    key: K & string;
    label: ReactNode;
    customRender?: (value: T[K], rowData: T) => React.ReactNode;

    /**
     * SYNC PAGINATION ONLY!
     * If you provide this function the column label will be clickable and have a sort arrow
     * Implement this function as you would an standard Array.sort
     *
     * Or just provide `true` to use a default sorting function
     */
    sortFn?: ((a: T[K], b: T[K]) => number) | true;
  };
}[keyof T];

/**
 * November 2022 update:
 * I've implemented async pagination/filtering
 * Pulled a lot of the logic out to those two new files:
 *  - createSyncFilterAndSort.ts
 *  - usePaginationAndFiltering.tsx
 *
 * The sync mode now basically behaves the same way async will - it's just that if you provide paginationType='sync' (or null)
 * And the 'rows' data it creates the PaginatedLoadMoreFunction for you.
 *
 *
 *
 *
 * June 2022 update:
 *  This component is getting unweildly
 *  We should pull the bits that get mapped over, into memoised components
 *
 *
 *
 * Earlier notes:
 * I'm adopting a 'impelement it as you need it' strategy for this component
 *
 * If the component doesn't do what you need - please extend it to do what you need! (Unless that's going to be way too hard and silly)
 *
 * Possible things to change in the future:
 *
 * - Custom column label rendering
 * - Change position of the actions column
 * - ~~Pagination (major update)~~ Sync pagination done.

 * - Customise the batch actions area
 * - Filter functionality
 *  - Currently filter functionality is a direct simple text match. We could offer custom filter options in the popover.
 *  - Possible some 'advanced filtering' panel. Although perhaps that is something that should exist outside of the table +
 *
 *
 * Note about selection behaviour:
 * Treating this like an _uncontrolled component_ re: selection behaviour
 * That is - it is currently not possible to mount this table with preselected rows.
 *
 */

export type CydDataTableProps<T extends RowData> = {
  /**
   * The the key of your rows that can be used to determine uniqueness - eg 'id'
   * The value of this should be a string
   */
  rowUniquenessKey: keyof T & string;
  rows?: Array<T>;
  columns: Array<Column<T>>;

  /**
   * Use if you want to programmatically have something occur when you click a row
   * However, use getRowLink (below) if you want to do navigation
   * @param rowData
   * @returns
   */
  onRowClick?: (rowData: T) => void;
  /**
   * Makes the row clickable like a link
   */
  getRowLink?: (rowData: T) => string;

  rowActions?: {
    // Redundant nesting, because we may want to add additional configuration for the rowActions
    // Eg. which column should the row actions be in, custom label.
    actions: Array<{
      // an icon is usually just a string icon type, but if you need to customise how it renders, use the function method
      icon:
        | CydIconTypes
        | ((
            rowData: T,
            onClick: (
              data: T,
              e: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => void
          ) => ReactElement);

      label: string;

      /**
       * if onClick is provided, it will be called when you click the button
       */
      onClick?: (
        // rowData should be all you need, but incase you need it, we include the original event
        rowData: T,
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => void;

      /**
       * if ModalContent is provided, when you click the button a modal will display
       */
      renderModalContent?: (
        data: T,
        closeModalFn: () => void
      ) => React.ReactElement;

      /**
       * To be used with the onClick handler, if this property is used, a modal will be displayed before continuing.
       */
      onClickConfirmMessage?: {
        message: string | ((data: T) => string);
      };

      // Future extensibility:
      // - button should be enabled/disabled function
      // - something to handle async/loading actions
    }>;
  };

  batchActions?: {
    // See above note about redundant nesting
    actions: Array<{
      label: string;
      icon?: CydIconTypes;

      /*
       * Function to call if it is a 'click' variant batch action
       */
      onAction?: (data: Array<T>) => void;

      /*
        Content to render if it is a 'popover' variant batch action
      */
      renderPopoverContent?: (props: {
        closePopoverFn: () => void;
        data: Array<T>;
      }) => React.ReactElement;
    }>;
  };

  columnFilter?: {
    key: keyof T & string;
  };

  paginationInfo?: null | PaginationInfo<T>;

  // If you pass this ref in, you can call it to programatically refresh the table
  refreshRef?: React.MutableRefObject<() => void>;
  /**
   *
   * To be used to store the current number of rows per page
   *
   */
  onRowsPerPageChange?: (newRowsPerPage: number) => void;

  noRowsPerPage?: number;
};

const DEFAULT_RENDER_FUNCTION = defaultDisplayAValueAsString;

export type SortConfig<T extends RowData> = {
  sortKey: keyof T & string;
  sortDirectionIsAsc: boolean;
};

export type FilterConfig<T extends RowData> = {
  filterText: string;
  filterKey: (keyof T & string) | null;
};

const DEFAULT_PAGINATION_INFO: PaginationInfo<RowData> = {
  paginationType: 'sync'
};

function TableCellWithPossibleLink(
  props: React.PropsWithChildren<{ link?: string; className?: string }>
) {
  const { link, children, className } = props;
  return (
    <TableCell
      className={className}
      css={css`
        position: relative;

        a.rowlink {
          position: absolute;
          inset: 0;
        }

        white-space: pre-line;
      `}
    >
      {/* We put a link overlay on each cell. This allows the user to right-click -> open in new tab */}
      {link && <Link to={link} className="rowlink"></Link>}

      <>{children}</>
    </TableCell>
  );
}

export const CydDataTable = <T extends RowData>(
  props: CydDataTableProps<T>
) => {
  const {
    rows,
    columns,
    rowActions,
    batchActions,
    rowUniquenessKey,
    columnFilter,
    paginationInfo,
    onRowClick,
    getRowLink,
    refreshRef,
    onRowsPerPageChange,
    noRowsPerPage: propRowsPerPage
  } = props;

  const [localRowsPerPage, setLocalRowsPerPage] = useState(
    DEFAULT_ROWS_PER_PAGE_SETTING
  );

  const hasRowClick = !!(onRowClick || getRowLink);
  const paginationToUse =
    paginationInfo === undefined ? DEFAULT_PAGINATION_INFO : paginationInfo;

  const {
    rowsToDisplay,
    pageNumberInfo,
    handlePageChange,
    handleSortChange,
    handleFilterTextChange,
    filterConfig,
    sortConfig
  } = usePaginationAndFiltering({
    paginationInfo: paginationToUse,
    rows,
    columnFilter,
    columns,
    refreshRef,
    noRowsPerPage: propRowsPerPage ?? localRowsPerPage
  });

  useEffect(() => {
    rowsToDisplay.forEach((v) => {
      if (typeof v[rowUniquenessKey] !== 'string') {
        console.warn(
          `The rows unique identifier '${rowUniquenessKey}' was not of type string`,
          v
        );
      }
    });
  }, [rowsToDisplay, rowUniquenessKey]);

  const [selectedRowsIds, setSelectedRowsIds] = useState([] as Array<string>);

  const selectAllRowsCheckboxState = useMemo(() => {
    if (selectedRowsIds.length === rowsToDisplay.length) {
      return 'all-selected';
    }
    if (selectedRowsIds.length === 0) {
      return 'none-selected';
    }

    return 'partial-selected';
  }, [rowsToDisplay.length, selectedRowsIds.length]);

  const selectedRows = useMemo(
    () =>
      rowsToDisplay.filter((v) => {
        return selectedRowsIds.includes(v[rowUniquenessKey] as string);
      }),
    [rowsToDisplay, rowUniquenessKey, selectedRowsIds]
  );

  return (
    <TableContainer
      component={Paper}
      css={css`
        min-width: min-content;
        width: auto;
      `}
    >
      <Table>
        <TableHead>
          {paginationToUse && (
            <TableRow data-testid="table-row">
              {/* AREA FOR BATCH ACTION CONTROLS */}
              {batchActions && (
                <TableCell
                  align="right"
                  colSpan={columns.length - 2}
                  css={css`
                    height: 40px; // Height of the button
                    box-sizing: content-box;

                    button {
                      margin-top: 0;
                      margin-bottom: 0;
                    }
                  `}
                >
                  {selectedRows.length > 0 && (
                    <CydButtonStack
                      justifyContent="flex-start"
                      css={css`
                        margin: 0;
                      `}
                    >
                      {/* Why has typescript lost track here? */}
                      {batchActions.actions.map((action) => {
                        const { label, onAction, renderPopoverContent } =
                          action;

                        if (onAction && renderPopoverContent) {
                          console.warn(
                            "For a batch action we have both an onAction and renderPopoverContent handler - you probably don't wan't this!"
                          );
                        }

                        return (
                          <React.Fragment key={label}>
                            {onAction && (
                              <CydButton
                                variant="outlined"
                                onClick={() => {
                                  onAction(selectedRows);
                                }}
                              >
                                {action.label}
                              </CydButton>
                            )}

                            {renderPopoverContent && (
                              <>
                                <CydPopover
                                  buttonVariant="outlined"
                                  label={label}
                                  renderChildren={({ closePopoverFn }) => {
                                    return renderPopoverContent({
                                      data: selectedRows,
                                      closePopoverFn
                                    });
                                  }}
                                />
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </CydButtonStack>
                  )}
                </TableCell>
              )}

              <TablePagination
                data-testid="table-pagination"
                rowsPerPageOptions={
                  paginationToUse.rowsPerPageOptions ??
                  DEFAULT_ROWS_PER_PAGE_OPTIONS
                }
                colSpan={100} // All of them
                count={pageNumberInfo.totalNumItems ?? 0}
                rowsPerPage={pageNumberInfo.numPerPage}
                page={pageNumberInfo.pageNum}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={(e, newPageNumber) =>
                  handlePageChange(newPageNumber)
                }
                onRowsPerPageChange={(e) => {
                  // set backto page 0
                  handlePageChange(0);
                  const newRowsPerPage = parseInt(e.target.value);
                  setLocalRowsPerPage(newRowsPerPage);
                  onRowsPerPageChange && onRowsPerPageChange(newRowsPerPage);
                }}
              />
            </TableRow>
          )}

          {/* AREA FOR COLUMN LABELS */}
          <TableRow>
            {/*HEADER CELL FOR THE CHECKBOXES  */}
            {batchActions && (
              <TableCell>
                <Checkbox
                  css={css`
                    padding: 1em;
                    margin: -0.5em;
                  `}
                  inputProps={{
                    'aria-label': `select all ${selectAllRowsCheckboxState}`
                  }}
                  indeterminate={
                    selectAllRowsCheckboxState === 'partial-selected'
                  }
                  checked={selectAllRowsCheckboxState === 'all-selected'}
                  onClick={(e) => {
                    let isMounted = true;
                    if (selectAllRowsCheckboxState === 'all-selected') {
                      if (isMounted) {
                        setSelectedRowsIds([]);
                      }
                    } else {
                      if (isMounted) {
                        setSelectedRowsIds(
                          rowsToDisplay.map(
                            (v) => v[rowUniquenessKey] as string
                          )
                        );
                      }
                    }
                    return () => {
                      isMounted = false;
                    };
                  }}
                />
              </TableCell>
            )}

            {/* COLUMN HEADERS */}
            {columns.map((column) => {
              const { key, label, sortFn } = column;
              return (
                <TableCell key={key}>
                  {columnFilter && columnFilter.key === key && (
                    <>
                      <CydPopover
                        variant="icon-button"
                        icon="filter"
                        buttonSize="small"
                        onFormSubmit={(e) => {
                          e.preventDefault();
                        }}
                        label={`Filter by ${column.label}`}
                      >
                        <CydTextField
                          autoFocus
                          label={`Filter by ${column.label}`}
                          value={filterConfig.filterText}
                          onChange={handleFilterTextChange}
                        />
                      </CydPopover>
                    </>
                  )}

                  {sortFn ? (
                    <TableSortLabel
                      css={css`
                        padding-left: 0;
                      `}
                      active={sortConfig ? sortConfig.sortKey === key : false}
                      direction={
                        sortConfig && sortConfig.sortDirectionIsAsc
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => {
                        const newSortConfig =
                          sortConfig?.sortKey === key
                            ? {
                                sortKey: key,
                                sortDirectionIsAsc:
                                  !sortConfig.sortDirectionIsAsc
                              }
                            : {
                                sortKey: key,
                                sortDirectionIsAsc: false
                              };

                        handleSortChange(newSortConfig);
                      }}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    <>{label}</>
                  )}
                </TableCell>
              );
            })}

            {/* HEADER CELL FOR ROW ACTIONS */}
            {rowActions && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsToDisplay.map((row) => {
            const rowLink = getRowLink?.(row);
            return (
              <TableRow
                key={row[rowUniquenessKey] as string}
                hover={hasRowClick}
                onClick={
                  hasRowClick
                    ? (e) => {
                        // Important:
                        // Otherwise clicking buttons, checkboxes etc, also counts as a row click.
                        if (
                          //@ts-ignore
                          ['button', 'input'].includes(e.target.localName) ||
                          //@ts-ignore
                          e.target.classList.contains('no-click')
                        ) {
                          //do nothing
                        } else {
                          onRowClick?.(row);
                        }
                      }
                    : undefined
                }
                css={css`
                  ${hasRowClick &&
                  `:hover {
                  cursor: pointer; 
                }, 
                
 
                  .no-click {
                    cursor: default;
                  }
                `}
                `}
              >
                {/* CHECKBOX FOR ROW SELECTION */}
                {batchActions && (
                  <TableCell className="no-click">
                    <Checkbox
                      css={css`
                        padding: 1em;
                        margin: -0.5em;
                      `}
                      // Warning - possible bad performance here
                      checked={selectedRowsIds.includes(
                        row[rowUniquenessKey] as string
                      )}
                      onChange={(e) => {
                        let isMounted = true;
                        const id = row[rowUniquenessKey] as string;

                        if (e.target.checked) {
                          if (isMounted) {
                            setSelectedRowsIds((old) => [...old, id]);
                          }
                        } else {
                          if (isMounted) {
                            setSelectedRowsIds((old) =>
                              old.filter((v) => v !== id)
                            );
                          }
                        }
                        return () => {
                          isMounted = false;
                        };
                      }}
                    />
                  </TableCell>
                )}

                {/* THE DATA */}
                {columns.map((column) => {
                  const renderFn =
                    column.customRender || DEFAULT_RENDER_FUNCTION;

                  return (
                    <TableCellWithPossibleLink
                      key={`${column.key}-${row.id}`}
                      link={rowLink}
                    >
                      {renderFn(row[column.key], row)}
                    </TableCellWithPossibleLink>
                  );
                })}

                {/* ROW ACTION BUTTONS */}
                {rowActions && (
                  <TableCell className="no-click">
                    <CydButtonStack>
                      {rowActions.actions.map((action) => {
                        const {
                          onClick: actionOnClick,
                          renderModalContent,
                          onClickConfirmMessage,
                          icon,
                          label
                        } = action;

                        let messageToUse = undefined as string | undefined;

                        if (onClickConfirmMessage) {
                          if (
                            typeof onClickConfirmMessage.message == 'string'
                          ) {
                            messageToUse = onClickConfirmMessage.message;
                          } else {
                            messageToUse = onClickConfirmMessage.message(row);
                          }
                        }

                        if (actionOnClick && renderModalContent) {
                          console.warn(
                            'Received both onClick and ModalContent - onClick will take precedence!'
                          );
                        }

                        if (messageToUse && !actionOnClick) {
                          console.warn(
                            'Received onClickConfirmMessage, but no onClick, this may lead to unintended behaviour!'
                          );
                        }

                        // Icon Button Variant
                        if (typeof icon === 'string') {
                          if (actionOnClick) {
                            if (messageToUse) {
                              return (
                                <CydConfirmModal
                                  key={label}
                                  renderTrigger={(onClick) => (
                                    <CydIconButton
                                      key={action.label}
                                      icon={action.icon as CydIconTypes}
                                      onClick={(e) => {
                                        onClick(e);
                                      }}
                                      label={action.label}
                                    />
                                  )}
                                  message={messageToUse}
                                  onConfirm={(e) => actionOnClick(row, e)}
                                />
                              );
                            }

                            return (
                              <CydIconButton
                                key={action.label}
                                icon={action.icon as CydIconTypes}
                                onClick={(e) => {
                                  actionOnClick(row, e);
                                }}
                                label={action.label}
                              />
                            );
                          }

                          if (renderModalContent) {
                            //nb. propagation is already stopped in CydButtonModal
                            return (
                              <CydButtonModal
                                key={action.label}
                                variant="icon-button"
                                icon={icon as CydIconTypes}
                                label={label}
                                renderContent={(onClose) => {
                                  return renderModalContent(row, onClose);
                                }}
                              />
                            );
                          }
                        }
                        // Customised icon
                        else {
                          if (actionOnClick) {
                            if (messageToUse) {
                              return (
                                <CydConfirmModal
                                  key={label}
                                  renderTrigger={(onClick) => {
                                    const onClickHere = (data, e) => {
                                      onClick(data);
                                    };

                                    return (
                                      <React.Fragment key={action.label}>
                                        {icon(row, onClickHere)}
                                      </React.Fragment>
                                    );
                                  }}
                                  message={messageToUse}
                                  onConfirm={(e) => actionOnClick(row, e)}
                                />
                              );
                            }

                            return (
                              <React.Fragment key={action.label}>
                                {icon(row, actionOnClick)}
                              </React.Fragment>
                            );
                          }

                          if (renderModalContent) {
                            return (
                              <CydButtonModal
                                key={action.label}
                                label={label}
                                renderButton={(onClick) =>
                                  icon(row, (data, e) => {
                                    onClick(e);
                                  })
                                }
                                renderContent={(closeModal) => {
                                  return renderModalContent(row, closeModal);
                                }}
                              />
                            );
                          }
                        }

                        throw new Error(
                          'Found nothing to render, have you provided onClick or ModalContent?'
                        );
                      })}
                    </CydButtonStack>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
