/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { FlowBuilder } from 'components/_playbooks/CacaoFlowchart/FlowBuilder/FlowBuilder';
import { convertCacaoPlaybookToGenericPlaybook } from 'components/_playbooks/CacaoFlowchart/utils/cacaoToFlowchart';
import { withFlowchartErrorBoundary } from 'components/_playbooks/CacaoFlowchart/withFlowchartErrorBoundary';
import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createDeletePlaybook } from 'states/playbooks/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CydBaseModal } from 'components/_dialogs/CydBaseModal/CydBaseModal';
import { Typography } from '@mui/material';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { useNavigate } from 'react-router';
import { CydarmRoute } from 'interface/CydarmRoute';

export type PlaybooksPageSingleCacaoPlaybookProps = {
  cacaoPlaybook: OasisCacaoPlaybook;
};

export const PlaybooksPageSingleCacaoPlaybook = withFlowchartErrorBoundary(
  (props: PlaybooksPageSingleCacaoPlaybookProps) => {
    const { cacaoPlaybook } = props;
    const dispatch = useDispatch();
    const [deleteDialog, setDeleteDialog] = useState(false);

    const navigate = useNavigate();
    const handleDelete = () => {
      if (!cacaoPlaybook?.uuid) {
        return;
      }

      dispatch(
        createDeletePlaybook({
          uuid: cacaoPlaybook.uuid,
          onSuccess: () => {
            navigate(CydarmRoute.PLAYBOOKS);
          }
        })
      );
    };

    const handleDeleteConfirmation = () => {
      handleDelete();
      setDeleteDialog(false);
    };

    const genericPlaybook =
      convertCacaoPlaybookToGenericPlaybook(cacaoPlaybook);
    return (
      <div
        css={css`
          height: 100%;
          // This is to remove the padding from the scroll container if the scroll-container has this component
          #scroll-container:has(&) {
            padding: 0;
          }
        `}
      >
        <FlowBuilder
          onSavePlaybook={() => {}}
          playbook={genericPlaybook}
          onDeletePlaybook={() => setDeleteDialog(true)}
        />
        {deleteDialog && (
          <CydBaseModal
            title="Are you sure?"
            isOpen
            onClose={() => setDeleteDialog(false)}
          >
            <>
              <div>
                <Typography
                  variant="body1"
                  gutterBottom
                  css={(theme) => `
                margin-bottom: ${theme.spacing(4)}
                `}
                >
                  Do you want to delete this playbook?
                </Typography>
              </div>
              <CydButtonStack justifyContent="flex-end">
                <CydButton
                  variant="text"
                  onClick={() => setDeleteDialog(false)}
                >
                  Cancel
                </CydButton>
                <CydButton onClick={handleDeleteConfirmation}>
                  Continue
                </CydButton>
              </CydButtonStack>
            </>
          </CydBaseModal>
        )}
      </div>
    );
  }
);
