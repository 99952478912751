/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { ProductionAclHookProvider } from 'hooks/AclHooks';
import { ProductionTimezoneHookProvider, useIsSignedIn } from 'hooks/AuthHooks';
import { ProductionSeverityHookProvider } from 'hooks/CaseSeverityHooks';
import { ProductionDataSignificancesHookProvider } from 'hooks/DataSignificances';
import { ProductionDownloadHookProvider } from 'hooks/DownloadHooks';
import { ProductionFeatureTogglesHookProvider } from 'hooks/FeatureFlagHooks';
import { ProductionOrgHookProvider } from 'hooks/OrgHooks';
import { ProductionSysPropsHookProvider } from 'hooks/SysPropsHooks';
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './reactQueryQueryClient';
import { ProductionUserPropsHookProvider } from 'hooks/UserPropsHooks';

export type CydHookProviderProps = React.PropsWithChildren<{}>;

const ALL_HOOK_PROVIDERS = [
  ProductionSysPropsHookProvider,
  ProductionUserPropsHookProvider,
  ProductionFeatureTogglesHookProvider,
  ProductionTimezoneHookProvider,
  ProductionDataSignificancesHookProvider,
  ProductionDownloadHookProvider,
  ProductionOrgHookProvider,
  ProductionAclHookProvider,
  ProductionSeverityHookProvider
];

/**
 * This is our top level hook provider, composing all the other hook providers
 */
export const CydHookProvider = (props: CydHookProviderProps) => {
  const { children } = props;

  const signedIn = useIsSignedIn();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {/* Only instantiate the hook providers if they are logged in 
          Otherwise many of them get into an errored state
        */}

        {signedIn ? (
          <>
            {/* This pattern is a bit nicer to maintain that having to do the nesting ourselves */}

            {ALL_HOOK_PROVIDERS.reduceRight((acc, Cur) => {
              return <Cur>{acc}</Cur>;
            }, children)}
          </>
        ) : (
          children
        )}
      </QueryClientProvider>
    </>
  );
};
