/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { useParams } from 'react-router';
import {
  useSingleAtcPlaybook,
  useSingleCacaoPlaybook
} from 'hooks/PlaybooksHooks';

import { PlaybooksPageSingleAtcPlaybook } from './PlaybooksPageSingleAtcPlaybook';
import { PlaybooksPageSingleCacaoPlaybook } from './PlaybooksPageSingleCacaoPlaybook';

export const PlaybooksPageSinglePlaybook: React.FC<{}> = (props) => {
  const params = useParams<{ playbookUuid: string }>();
  const selectedAction = undefined;

  if (!params.playbookUuid) {
    throw new Error('No playbookUuid in URL params');
  }

  const { data: atcPlaybook } = useSingleAtcPlaybook(params.playbookUuid);
  const { data: cacaoPlaybook } = useSingleCacaoPlaybook(params.playbookUuid);

  if (atcPlaybook) {
    return (
      <PlaybooksPageSingleAtcPlaybook
        atcPlaybook={atcPlaybook}
        selectedAction={selectedAction}
      />
    );
  }

  if (cacaoPlaybook) {
    return <PlaybooksPageSingleCacaoPlaybook cacaoPlaybook={cacaoPlaybook} />;
  }

  return null;
};
