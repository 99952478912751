/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import React from 'react';
import { usePageStyle } from 'components/Page/style';

interface IPageToolbarProps {
  children: React.ReactNode;
}

export const PageContent: React.FC<IPageToolbarProps> = ({ children }) => {
  const { pageContent } = usePageStyle();

  return <div className={pageContent}>{children}</div>;
};
