import { useQuery } from '@tanstack/react-query';
import { useServiceProvider } from 'providers/ServiceProvider';
import { useMemo } from 'react';
import { useSystemPropValue } from './SysPropsHooks';
import { SYSTEM_PROPERTY } from '../constants';

export const useSlaString = (slas) => {
  const [seconds, isNegative] = useMemo(() => {
    if (!slas || !slas.length) {
      return [undefined, false];
    }
    let secs = Math.min(
      ...slas.map((sla) =>
        sla.timeRemaining.split(':').reduce((acc, time, i, arr) => {
          let accumulatedTime = 60 * Math.abs(acc) + +time;
          return sla.timeRemaining.charAt(0) === '-' && i === arr.length - 1
            ? -accumulatedTime
            : accumulatedTime;
        })
      )
    );
    const isNegative = secs < 0;
    return [Math.abs(secs), isNegative];
  }, [slas]);
  return useFormattedSeconds(seconds, isNegative);
};

export const useFormattedSeconds = (seconds, isNegative = false) =>
  useMemo(() => {
    if (!seconds) {
      return '';
    }
    let _isNegative = isNegative || seconds < 0;
    let _seconds = Math.abs(seconds);
    let hours = Math.floor(_seconds / 60 / 60);
    let mins = Math.floor(_seconds / 60) - hours * 60;
    let secs = _seconds % 60;
    let formattedTime = [hours, mins, secs]
      .map((time) => String(time).padStart(2, '0'))
      .join(':');
    return _isNegative ? `-${formattedTime}` : formattedTime;
  }, [seconds, isNegative]);

const EMPTY_ARRAY = [];

export function useSlas(caseUuid?: string) {
  const pollTime = parseInt(
    useSystemPropValue(SYSTEM_PROPERTY.DATA_POLL_TIME, '5000')
  );

  const { apiPerformFetchCaseSlas } = useServiceProvider();
  return useQuery({
    queryKey: ['slas', caseUuid],
    queryFn: async () => {
      if (!caseUuid) {
        throw new Error('No caseUuid to fetch SLAs with');
      }
      const result = await apiPerformFetchCaseSlas(caseUuid);
      return result.json.sort();
    },
    enabled: !!caseUuid,
    refetchInterval: pollTime,
    placeholderData: EMPTY_ARRAY
  });
}
