/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { CydarmPage } from 'interface/CydarmRoute';

type IDefaultLayoutProps = {
  page?: CydarmPage;
  className?: string;
  children: React.ReactNode;
};

//@DEPRECATED
// This component can probably just be removed.

export const DefaultLayout: React.FC<IDefaultLayoutProps> = ({
  page,
  children,
  className
}) => {
  return (
    <div className={className} data-testid={page}>
      {' '}
      {children}
    </div>
  );
};
