/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  addSystemProperty,
  fetchAllSystemProperty,
  removeSystemProperty,
  updateSystemProperty
} from 'states/sysprops/actions';
import {
  allSystemPropertiesSelector,
  sysPropsHasLoadedSelector,
  sysPropsIsLoadingSelector,
  sysPropsStatusSelector,
  systemPropValueSelector
} from 'states/sysprops/selectors';
import { useAutoEffect } from './ReduxHooks';
import deepEqual from 'react-fast-compare';
import { SYSTEM_PROPERTY } from '../constants';
import { INewSystemPropertiesData } from 'components/forms/SystemPropertiesForm';
import { useCallback } from 'react';
import { SystemPropertyInterface } from 'interface/SystemProperty.interface';
import { IStore } from 'states/store.interface';

export const useAllSystemProperties = () => {
  return useAutoEffect({
    selector: allSystemPropertiesSelector,
    ac: fetchAllSystemProperty,
    hasLoadedSelector: sysPropsHasLoadedSelector,
    isLoadingSelector: sysPropsIsLoadingSelector,
    statusSelector: sysPropsStatusSelector,
    equality: deepEqual
  });
};

export const useSystemPropValue = (propName, defaultVal: any = undefined) => {
  useAllSystemProperties();
  return useSelector(
    (
      state: IStore // OPERATION TYPES CLEAN UP - I added the assertion here to fix the type error. Really this type should be inferred.
    ) => systemPropValueSelector(state, propName, defaultVal)
  );
};

export const useJsonSyntaxStyle = () => {
  const { getSysProp } = useSysPropsHook();

  const syntaxStyle = getSysProp(SYSTEM_PROPERTY.JSON_SYNTAX_STYLE, 'INLINE');
  return syntaxStyle;
};

export function useSysProps(): {
  fetchAllSysProps: () => void;
  addSystemProp: (
    settingData: INewSystemPropertiesData | INewSystemPropertiesData[]
  ) => void;
  updateSystemProp: (
    settingData: INewSystemPropertiesData | INewSystemPropertiesData[]
  ) => void;
  removeSystemProp: (uuid: string | string[]) => void;
  data: Array<SystemPropertyInterface>;

  isLoading: boolean;
} {
  const dispatch = useDispatch();

  let { data } = useAllSystemProperties();

  const fetchAllSysProps = useCallback(() => {
    dispatch(fetchAllSystemProperty());
  }, [dispatch]);

  const isLoading = useSelector(sysPropsIsLoadingSelector);

  const updateSystemPropFn = useCallback(
    async (settingData: INewSystemPropertiesData) => {
      dispatch(updateSystemProperty(settingData));
    },
    [dispatch]
  );

  const useRemoveSysPropFn = useCallback(
    async (sysPropUuid: string) => {
      dispatch(removeSystemProperty(sysPropUuid));
    },
    [dispatch]
  );

  const useAddSysPropFn = useCallback(
    async (settingData: INewSystemPropertiesData) => {
      dispatch(addSystemProperty(settingData));
    },
    [dispatch]
  );

  return {
    data,
    addSystemProp: useAddSysPropFn,
    updateSystemProp: updateSystemPropFn,
    removeSystemProp: useRemoveSysPropFn,
    fetchAllSysProps,
    isLoading
  };
}

type SysPropsHook = {
  getSysProp: typeof useSystemPropValue;
};

const SysPropsHookContext = React.createContext<SysPropsHook>({
  getSysProp: () => {
    throw new Error('SysPropsContextNotInitialised!');
  }
});

export const SysPropsHookProvider = (
  props: React.PropsWithChildren<SysPropsHook>
) => {
  const { children, ...rest } = props;
  return (
    <SysPropsHookContext.Provider value={rest}>
      {children}
    </SysPropsHookContext.Provider>
  );
};

export const useSysPropsHook = () => {
  return React.useContext(SysPropsHookContext);
};

export const ProductionSysPropsHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <SysPropsHookProvider getSysProp={useSystemPropValue}>
      {props.children}
    </SysPropsHookProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */

const DEFAULT_SYS_PROPS = {};

export const InMemorySysPropsHookProvider = (
  props: React.PropsWithChildren<{
    getSysProp?: (key, defaultValue) => void;
  }>
) => {
  const { getSysProp } = props;
  return (
    <SysPropsHookProvider
      getSysProp={
        getSysProp ??
        ((key, defaultValue) => {
          return DEFAULT_SYS_PROPS[key] || defaultValue;
        })
      }
    >
      {props.children}
    </SysPropsHookProvider>
  );
};
