import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  datetime: {
    color: palette.text.disabled,
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: "''",
      height: '5px',
      width: '5px',
      borderRadius: '50%',
      backgroundColor: palette.text.disabled,
      display: 'block',
      marginRight: spacing(1)
    }
  },
  timeItem: {
    display: 'flex',
    alignItems: 'center',
    '& + span': {
      marginLeft: 10
    },
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 4
    }
  }
}));
