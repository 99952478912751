/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { IStore } from 'states/store.interface';

export const commentModeSelector = ({ caseThreadDrawer: { mode } }: IStore) =>
  mode;

export const commentDrawerSelector = ({
  caseThreadDrawer: { drawerIsOpen }
}: IStore) => drawerIsOpen;

export const manipulatingCaseItemSelector = ({
  caseThreadDrawer: { caseItemUuid }
}: IStore) => caseItemUuid;
