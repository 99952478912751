/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const usePlaybookActionsTableStyles = makeStyles(({ spacing }) => ({
  tableCellStyle: {
    wordBreak: 'break-word',
    maxWidth: '400px'
  },
  tagChip: {
    marginRight: spacing(1),
    marginBottom: spacing(1),
    justifyContent: 'space-between',
    fontSize: 11,
    maxWidth: '100%'
  },
  tagChipLabel: {
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: 11,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },
  tagChipContainer: {
    marginTop: spacing(2),
    overflow: 'hidden'
  }
}));
