/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useCallback, useState } from 'react';
import {
  CydButton,
  CydButtonProps
} from 'components/_formElements/CydButton/CydButton';
import {
  CydIconButton,
  CydIconButtonProps
} from 'components/_formElements/CydIconButton/CydIconButton';
import { CydIconTypes } from 'components/_foundation/CydIcon/CydIcon';

import {
  CydSimpleFormModal,
  CydSimpleFormModalProps
} from '../CydSimpleFormModal/CydSimpleFormModal';

export type CydButtonModalProps = {
  label: string;
  variant?: 'button' | 'icon-button';
  icon?: CydIconTypes;
  title?: string;
  size?: CydSimpleFormModalProps['size'];

  /**
   * If you need to customise the button, use this
   */
  ButtonProps?: CydButtonProps;

  /**
   * If you need to customise the icon button, use this
   */
  IconButtonProps?: CydIconButtonProps;

  /**
   * Use if you want full control of the button render
   */
  renderButton?: (onClick: (e) => void, label: string) => React.ReactElement;

  /**
   * Optional extra functionality to call when the modal closes, whether by escape, or by button click
   */
  onClose?: () => void;

  /**
   * The Modal content.
   * It takes an closeModalFn to programatically close the modal
   */
  renderContent: (closeModalFn: () => void) => React.ReactElement;
};

export const CydButtonModal = (props: CydButtonModalProps) => {
  const {
    label,
    variant = 'button',
    size,
    icon,
    renderContent,
    ButtonProps,
    IconButtonProps,
    title,
    renderButton,
    onClose
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const openModalFn = useCallback(
    (e) => {
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const closeModalFn = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [setIsOpen, onClose]);

  return (
    <>
      {renderButton ? (
        renderButton(openModalFn, label)
      ) : variant === 'button' ? (
        <CydButton {...ButtonProps} onClick={openModalFn}>
          {label}
        </CydButton>
      ) : (
        <CydIconButton
          icon={icon as CydIconTypes}
          onClick={openModalFn}
          {...IconButtonProps}
          label={label}
        />
      )}

      <CydSimpleFormModal
        size={size}
        isOpen={isOpen}
        onClose={closeModalFn}
        title={title}
      >
        {renderContent(closeModalFn)}
      </CydSimpleFormModal>
    </>
  );
};
