/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Handle, Position } from 'reactflow';
import { BaseCustomNodeProps } from './BaseCustomNode';
import { BaseDiamondNode } from './BaseDiamondNode';

export const SwitchConditionNode = (props: BaseCustomNodeProps) => {
  return (
    <BaseDiamondNode {...props} nodeType="switch">
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </BaseDiamondNode>
  );
};
