import { visit } from 'unist-util-visit';

export default function MarkdownSpoilerPlugin(this: any, options) {
  const transformer = (tree) => {
    const visitor = (node, index, parent) => {
      if (node?.value.startsWith('!>')) {
        node.value = node.value.slice(2);
        const spoiler = {
          type: 'spoiler',
          position: node.position,
          children: [node],
          data: {
            hName: 'span',
            hProperties: { className: 'spoiler-text' }
          }
        };
        parent.children[index] = spoiler;
      }
    };

    visit(tree, 'text', visitor);
  };

  return transformer;
}
