/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { CydIconTypes, CydIcon } from 'components/_foundation/CydIcon/CydIcon';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type CydButtonProps = Pick<
  ButtonProps,
  | 'className'
  | 'children'
  | 'variant'
  | 'disabled'
  | 'onClick'
  | 'onMouseDown'
  | 'type'
  | 'name'
  | 'href'
  | 'size'
  | 'id'
  | 'color'
  | 'fullWidth'
  | 'LinkComponent'
  | 'aria-label'
> & {
  component?: React.ElementType;

  startIcon?: CydIconTypes;
  endIcon?: CydIconTypes;
};

export const CydButton = React.forwardRef(
  (props: CydButtonProps, ref: React.RefObject<HTMLButtonElement>) => {
    const { startIcon, endIcon, variant = 'contained', ...rest } = props;

    return (
      <Button
        css={css`
          white-space: nowrap;
        `}
        ref={ref}
        {...rest}
        variant={variant}
        startIcon={startIcon && <CydIcon icon={startIcon} />}
        endIcon={endIcon && <CydIcon icon={endIcon} />}
      />
    );
  }
);
