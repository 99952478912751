/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Modal, ModalProps, Paper, Typography } from '@mui/material';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import React from 'react';

/** @jsxImportSource @emotion/react */

export type CydBaseModalProps = {
  size?: 'default' | 'large' | 'xlarge' | 'xxlarge' | 'max';
  isOpen: boolean;
  onClose?: (
    e: React.MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  children: ModalProps['children'];
  className?: string;
  title?: string;
  dontShowCloseButton?: boolean;
};

const sizesMapWidth: Record<CydBaseModalProps['size'] & string, string> = {
  default: '400px',
  large: '600px',
  xlarge: '800px',
  xxlarge: 'calc(100vw - 200px)',
  max: 'calc(100vw - 20px)'
};

export const MODAL_SIZE_WIDTH_MAP = sizesMapWidth;

const sizesMapMaxHeight: Record<CydBaseModalProps['size'] & string, string> = {
  default: '80%',
  large: '80%',
  xlarge: '80%',
  xxlarge: 'calc(100vh - 150px)',
  max: 'calc(100vh - 20px)'
};

const sizesMapMinHeight: Record<CydBaseModalProps['size'] & string, string> = {
  default: '200px',
  large: '200px',
  xlarge: '200px',
  xxlarge: 'calc(100vh - 150px)',
  max: 'calc(100vh - 20px)'
};

/**
 * The base modal is the lowest level Cydarm modal, it provides sizing, colors etc, as well as position of the close button
 * @param props
 * @returns
 */
export const CydBaseModal = (props: CydBaseModalProps) => {
  const {
    isOpen,
    onClose,
    children,
    className,
    size = 'default',
    title,
    dontShowCloseButton = false
  } = props;

  const minHeight = sizesMapMinHeight[size];
  const maxHeight = sizesMapMaxHeight[size];
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper
        elevation={1}
        className={className}
        css={(theme) => `
    
        width: ${sizesMapWidth[size]}; 
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: ${theme.spacing(8)};
        min-height:  ${minHeight};
        max-height: ${maxHeight};
        ${minHeight === maxHeight ? `height: ${minHeight};` : ''};
        overflow: auto;
      `}
      >
        <div
          css={(theme) => `
          position: sticky; // Combination of sticky and absolute to get the button not scroll away 
          // https://stackoverflow.com/questions/5209814/can-i-position-an-element-fixed-relative-to-parent
          inset: 0; 
        `}
        >
          {!dontShowCloseButton && (
            <CydIconButton
              css={(theme) => `
              position: absolute; 
              top: -${theme.spacing(
                8
              )}; // We're just getting it to sit in the margin
              right: -${theme.spacing(8)};
          `}
              icon="close"
              label="close"
              size="small"
              onClick={onClose}
            />
          )}
        </div>

        {title ? (
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        ) : (
          <div
            css={(theme) => `
          margin-bottom: ${theme.spacing(3)};
          `}
          ></div> //spacer
        )}

        {children}
      </Paper>
    </Modal>
  );
};
