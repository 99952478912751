/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { addCaseToWatchingList, removeCaseFromWatchingList } from './slice';
import {
  createWatchCase,
  createUnWatchCase,
  createFetchWatchingCases
} from './actions';
import { createFetchCaseWatchers } from 'states/watchers/actions';
import {
  apiFetchWatchingCases,
  apiUnwatchCase,
  apiWatchCase
} from 'services/reports/WatchingCasesService';
import { invalidateCaseActivity } from 'states/cases/sagas';

export function* performWatchCase(action) {
  const {
    payload: { caseUuid, aclUuid, userUuid }
  }: {
    payload: { caseUuid: string; aclUuid: string; userUuid: string };
  } = action;

  try {
    yield apiWatchCase(caseUuid, {
      acl_uuid: aclUuid,
      user_uuid: userUuid,
      case_uuid: caseUuid
    });

    yield put(addCaseToWatchingList([caseUuid]));

    yield put(createFetchCaseWatchers(caseUuid));

    yield call(invalidateCaseActivity, caseUuid);

    yield put(addNotification({ message: `Watched case successfully` }));
  } catch (error) {
    yield put(addNotification({ message: `Watch case has failed` }));
  }
}

export function* performUnWatchCase({
  payload: caseUuid
}: {
  payload: string;
}) {
  try {
    yield apiUnwatchCase(caseUuid);

    yield put(removeCaseFromWatchingList(caseUuid));
    yield put(createFetchCaseWatchers(caseUuid));

    yield call(invalidateCaseActivity, caseUuid);

    yield put(addNotification({ message: 'Un-watch case is successful' }));
  } catch (error) {
    yield put(addNotification({ message: 'Un-watch case has failed' }));
  }
}

export function* performFetchWatchingCases({
  payload: userUuid
}: {
  payload: string;
}) {
  if (!userUuid) {
    return;
  }
  try {
    const { json: cases } = yield apiFetchWatchingCases(userUuid);
    yield put(addCaseToWatchingList(cases.map(({ uuid }) => uuid)));
  } catch (error) {
    yield put(addNotification({ message: 'Error fetching watched cases' }));
  }
}

/* Watchers */
function* watchWatchCase() {
  yield takeLatest(createWatchCase, performWatchCase);
}

function* watchUnWatchCase() {
  yield takeLatest(createUnWatchCase, performUnWatchCase);
}

function* watchFetchWatchingCases() {
  yield takeLeading(createFetchWatchingCases, performFetchWatchingCases);
}

export default [
  watchWatchCase(),
  watchUnWatchCase(),
  watchFetchWatchingCases()
];
