/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GenericPlaybookStatusUpdate } from 'components/_playbooks/CacaoPlaybookRunningInstance';
import { ICreateCaseDataRequest } from 'interface/CaseData.interface';
import {
  CasePlaybookActionInstanceStatus,
  IPlaybookActionType,
  PlaybookActionInstance,
  CydApi_CasePlaybookInstance
} from 'interface/Playbook.interface';
import { CaseTag } from 'interface/Tags.interface';
import { useServiceProvider } from 'providers/ServiceProvider';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  createAddActionToCase,
  createRemoveActionFromCase,
  createUpdateCaseAction
} from 'states/caseActions/actions';
import {
  addCasePlaybookActionTag,
  removeCasePlaybookActionTag,
  removePlaybookFromCase,
  updateCasePlaybook,
  updateCasePlaybookAction
} from 'states/casePlaybooks/actions';
import {
  createPlaybookAction,
  createFetchPlaybookActions
} from 'states/playbookActions/actions';
import { AtcPlaybookActionsListSelector } from 'states/playbookActions/selectors';
import { useAutoEffect } from './ReduxHooks';
import { useCasePlaybooks } from './CasePlaybooksHooks';

export const useAtcPlaybookActions = () => {
  return useAutoEffect({
    selector: AtcPlaybookActionsListSelector,
    ac: createFetchPlaybookActions,
    name: 'atc playbook actions',
    statusSelector: (state) => {
      return state.playbookActions.isLoading || state.playbookActions.hasLoaded;
    }
  });
};

export function determineTagsChanged(
  oldTags: Array<string>,
  newTags: Array<CaseTag>,
  availableTags: Array<CaseTag>
): {
  added: Array<CaseTag>;
  deleted: Array<CaseTag>;
} {
  // Add tags are the ones that are in the new tag list, and not in the old.
  const added = newTags.filter((v) => {
    return !oldTags.includes(v.value);
  });

  // Old tags are the ones that are in the old tag list, and not the new tag list
  const deleted = oldTags
    .filter((v) => {
      return !newTags.find((w) => w.value === v);
    })
    .map((v) => {
      // We need to look up the available tags to find the uuids,
      const fullTag = availableTags.find((w) => w.value === v);

      if (!fullTag) {
        throw new Error(`Tag with value: ${v} was not found in availble tags`);
      }

      return fullTag;
    });

  return {
    added,
    deleted
  };
}

export const useAddStandaloneActionToCase = (
  caseUuid: string
): ((
  actionUuid: string,
  onResolve?: (data: PlaybookActionInstance) => void
) => void) => {
  const dispatch = useDispatch();

  return (actionUuid, onResolve) => {
    dispatch(createAddActionToCase({ caseUuid, actionUuid, onResolve }));
  };
};

export const useCreatePlaybookAction = (): ((
  playbookAction: IPlaybookActionType,
  onResolve?: (data: { uuid: string }) => void
) => void) => {
  const dispatch = useDispatch();
  return (data, resolve) => {
    dispatch(
      createPlaybookAction({
        playbookAction: data,
        resolve
      })
    );
  };
};

export const useRemovePlaybookFromCase = (
  caseUuid: string
): ((playbook: CydApi_CasePlaybookInstance) => void) => {
  const dispatch = useDispatch();

  return (playbook) => {
    dispatch(
      removePlaybookFromCase({
        caseUuid,
        playbookUuid: playbook.casePlaybookUuid
      })
    );
  };
};

export const useRemoveStandaloneActionFromCase = (caseUuid: string) => {
  const dispatch = useDispatch();

  return (actionInstance: PlaybookActionInstance) => {
    dispatch(
      createRemoveActionFromCase({
        caseUuid,
        actionInstance: actionInstance
      })
    );
  };
};

export const useUpdateStandaloneAction = (
  caseUuid: string
): ((action: {
  actionUuid: string;
  position: number;
  assigneeUuid: string;
  status: CasePlaybookActionInstanceStatus;
  oldData: any;
}) => void) => {
  const dispatch = useDispatch();

  return (data) => {
    const { assigneeUuid, status, oldData } = data;

    dispatch(
      createUpdateCaseAction({
        caseUuid,

        actionInstance: {
          ...oldData,
          assigneeUuid,
          status
        }
      })
    );
  };
};

export function usePlaybookInstance(
  caseUuid: string,
  casePlaybookInstanceUuid: string
) {
  const casePlaybooks = useCasePlaybooks(caseUuid);

  const casePlaybookInstancesMap = useMemo(() => {
    return casePlaybooks.data.reduce(
      (acc, cur) => {
        acc[cur.casePlaybookUuid] = cur;
        return acc;
      },
      {} as Record<string, CydApi_CasePlaybookInstance>
    );
  }, [casePlaybooks]);

  if (casePlaybookInstanceUuid === 'standalone') {
    return null;
  }

  const playbookInstance = casePlaybookInstancesMap[casePlaybookInstanceUuid];
  if (!playbookInstance) {
    return null;
    // throw new Error(
    //   `Did not found a playbook instance for uuid: '${casePlaybookInstanceUuid}'`
    // );
  }

  return playbookInstance;
}

export function useUpdateCacaoPlaybookInstance(
  caseUuid: string,
  playbookInstanceUuid: string
) {
  const dispatch = useDispatch();

  const playbookInstance = usePlaybookInstance(caseUuid, playbookInstanceUuid);

  return (payload: GenericPlaybookStatusUpdate, resolveFn?: () => void) => {
    if (!playbookInstance) {
      throw new Error('Update playbook instance function not ready');
    }
    dispatch(
      updateCasePlaybook({
        caseUuid,
        casePlaybookUuid: playbookInstanceUuid,
        data: {
          variable_bindings: {
            ...playbookInstance.variable_bindings,
            ...payload.variable_bindings
          }
        },
        resolveFn
      })
    );
  };
}

export function useUpdateCacaoPlaybookAction(caseUuid: string) {
  const dispatch = useDispatch();

  return (payload: {
    playbookUuid: string;
    playbookInstanceUuid: string;
    playbookInstanceActionInstanceUuid: string;
    position: number;
    assignee: string | null;
    status: string;
    oldData: any;
  }) => {
    const { assigneeUuid, status, ...oldData } = payload.oldData;

    dispatch(
      updateCasePlaybookAction({
        caseUuid,
        playbookUuid: payload.playbookInstanceUuid,
        actionInstance: {
          ...oldData,
          assigneeUuid: payload.assignee,
          status: payload.status
        }
      })
    );
  };
}

export const useUpdatePlaybookActions = (
  caseUuid: string
): ((
  oldPlaybook: CydApi_CasePlaybookInstance,
  payload: {
    playbookInstanceUuid: string;
    playbookInstanceActionInstance: string;
    position: number;
    assignee: string | null;
    status: 'success' | 'ready';
    tags?: Array<CaseTag>;
    oldData: any;
  },
  availableTags: Array<CaseTag> // It's annoying that we need to pass this in, but what's we gotta do.
) => void) => {
  const dispatch = useDispatch();

  return (playbookInstance, data, availableTags) => {
    if (!caseUuid) {
      throw new Error('no case uuid provided');
    }

    const { tags } = data;

    const oldPlaybookAction = playbookInstance.action_statuses.find((v) => {
      return v.actionUuid === data.playbookInstanceActionInstance;
    });

    if (!oldPlaybookAction) {
      throw new Error(
        `Could not find the playbook action instance in the playbook action, for action instance uuid: ${data.playbookInstanceActionInstance}`
      );
    }

    const tagsResult = tags
      ? determineTagsChanged(oldPlaybookAction.tags || [], tags, availableTags)
      : null;
    if (tagsResult) {
      tagsResult.added.forEach((v) => {
        dispatch(
          addCasePlaybookActionTag({
            caseUuid: caseUuid,
            casePlaybookUuid: playbookInstance.casePlaybookUuid,

            actionInstance: data.oldData,
            tagUuid: v.uuid
          })
        );
      });

      tagsResult.deleted.forEach((v) => {
        dispatch(
          removeCasePlaybookActionTag({
            caseUuid: caseUuid,
            casePlaybookUuid: playbookInstance.casePlaybookUuid,

            actionInstance: data.oldData,
            tagUuid: v.uuid
          })
        );
      });
    }

    if (data.assignee || data.status) {
      dispatch(
        updateCasePlaybookAction({
          caseUuid,
          playbookUuid: playbookInstance.casePlaybookUuid,
          actionInstance: {
            ...data.oldData,
            assigneeUuid: data.assignee,
            status: data.status
          }
        })
      );
    }
  };
};

export function useActionInstance(actionInstanceUuid: string) {}

export function useActionInstanceComments(actionInstanceUuid: string | null) {
  const { apiGetCommentsOnActionInstance } = useServiceProvider();

  const queryFn = useCallback(async () => {
    if (!actionInstanceUuid) {
      throw new Error('No actionInstanceUuid to run query with');
    }

    const result = await apiGetCommentsOnActionInstance(actionInstanceUuid);

    return result;
  }, [actionInstanceUuid, apiGetCommentsOnActionInstance]);

  const query = useQuery({
    queryKey: ['actionInstanceComments', actionInstanceUuid],
    queryFn: queryFn,

    enabled: !!actionInstanceUuid
  });

  return query;
}

export function useAddCommentToActionInstance(
  actionInstanceUuid: string,
  caseUuid: string
) {
  const { apiCreateCommentOnActionInstance } = useServiceProvider();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (variables: ICreateCaseDataRequest) => {
      return apiCreateCommentOnActionInstance(actionInstanceUuid, variables);
    },

    onMutate: () => {},
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['actionInstanceComments', actionInstanceUuid]
      });

      queryClient.invalidateQueries({
        queryKey: ['caseActivity', caseUuid]
      });
    },
    onError: () => {}
  });

  return mutation;
}
