/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import breaks from 'remark-breaks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import MarkdownSpoilerPlugin from 'utils/MarkdownSpoilerPlugin';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface IMarkdownProps {
  children: string;
  className?: string;
}

export const Markdown: React.FC<IMarkdownProps> = ({ className, children }) => (
  <ReactMarkdown
    css={css`
      & img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }
      // this "> pre" css selector is for the code blocks
      && > pre {
        padding: 0;
        margin: 0;
      }
    `}
    remarkPlugins={[remarkGfm, MarkdownSpoilerPlugin, breaks]}
    rehypePlugins={[
      [
        rehypeExternalLinks,
        { target: '_blank', rel: ['noopener', 'noreferrer'] }
      ]
    ]}
    components={{
      code({ node, inline, className, children, ...props }: any) {
        const language = className?.replace(/^language-/, '');
        // inline: `test`
        // !inline: ```js
        //              console.log('Hello World')
        //          ```
        return !inline ? (
          <SyntaxHighlighter
            style={{ ...coldarkDark, ...{ margin: 0 } }}
            PreTag="div"
            language={language}
            {...props}
          >
            {children}
          </SyntaxHighlighter>
        ) : (
          <code
            css={(theme) => `
            color: ${theme.palette.text}
          `}
            className={className}
            {...props}
          >
            {children}
          </code>
        );
      }
    }}
    className={className}
  >
    {children}
  </ReactMarkdown>
);
