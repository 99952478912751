/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Link } from '@mui/material';
import { useDownload } from 'hooks/DownloadHooks';
import { useMemo } from 'react';
import { STIX_MIMETYPE2_0, STIX_MIMETYPE2_1 } from 'utils/CaseDataUtils';
import { useCaseActivityStyles } from '../styles';
import { humanFileSize } from 'utils/StringUtils';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
export const CaseActivityContentDownload = ({ caseActivity }) => {
  const { mimetype, filename } = caseActivity;

  const { downloadCaseActivity } = useDownload();
  const { linkText } = useCaseActivityStyles({});

  const showDownload = useMemo(
    () =>
      filename &&
      filename.length > 0 &&
      mimetype !== STIX_MIMETYPE2_0 &&
      mimetype !== STIX_MIMETYPE2_1,
    [filename, mimetype]
  );

  return showDownload ? (
    <p
      css={css`
        padding-bottom: 0.5em;
      `}
    >
      <Link
        href="#"
        className={linkText}
        onClick={() => downloadCaseActivity(caseActivity)}
      >
        {filename}
      </Link>

      <span
        css={(theme) => `
      margin-left: 2em;
      color: ${theme.palette.text.secondary};
      `}
      >
        {humanFileSize(caseActivity.datasize)}
      </span>
    </p>
  ) : null;
};
