/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventTriggerRequestResource } from './EventTriggerRequestResource';
import {
    EventTriggerRequestResourceFromJSON,
    EventTriggerRequestResourceFromJSONTyped,
    EventTriggerRequestResourceToJSON,
} from './EventTriggerRequestResource';

/**
 * 
 * @export
 * @interface EventTriggerRequestTopLevel
 */
export interface EventTriggerRequestTopLevel {
    /**
     * 
     * @type {EventTriggerRequestResource}
     * @memberof EventTriggerRequestTopLevel
     */
    data?: EventTriggerRequestResource | null;
}

/**
 * Check if a given object implements the EventTriggerRequestTopLevel interface.
 */
export function instanceOfEventTriggerRequestTopLevel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventTriggerRequestTopLevelFromJSON(json: any): EventTriggerRequestTopLevel {
    return EventTriggerRequestTopLevelFromJSONTyped(json, false);
}

export function EventTriggerRequestTopLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTriggerRequestTopLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : EventTriggerRequestResourceFromJSON(json['data']),
    };
}

export function EventTriggerRequestTopLevelToJSON(value?: EventTriggerRequestTopLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': EventTriggerRequestResourceToJSON(value.data),
    };
}

