/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, FormControl, Typography } from '@mui/material';
import { determineManualOrAutomatic } from '../utils/generalNodeStatusUtils';
import {
  GenericNodeUpdatePayload,
  GenericPlaybookStatusUpdate
} from 'components/_playbooks/CacaoPlaybookRunningInstance';
import { ManualOrAutomaticIcon } from '../ManualOrAutomaticIcon/ManualOrAutomaticIcon';
import {
  AvailableStepStatuses,
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from '../type';
import { User } from 'interface/User.interface';
import { CydUserSelector } from 'components/_objectSelectors/CydUserSelector/CydUserSelector';
import { useMemo } from 'react';
import { CaseTag } from 'interface/Tags.interface';
import { CydTabPanels } from 'components/_formContainers/CydTabPanels/CydTabPanels';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { Markdown } from 'components/Markdown';
import { CydConfirmModal } from 'components/_dialogs/CydConfirmModal/CydConfirmModal';
import { CydTagArrayDisplayByTagValues } from 'components/_objectDisplay/CydTagDisplay/CydTagArrayDisplayByTagValues';
import { ActivityItem } from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';
import { ServicedCommentsPanel } from '../CommentsPanel/ServicedCommentsPanel';
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import { useActionInstanceComments } from 'hooks/PlaybookActionsHook';
import { GenericNodeDetailsPanel } from '../GenericNodeDetailsPanel/GenericNodeDetailsPanel';
import { InputArgsDisplay } from './InputArgsDisplay/InputArgsDisplay';
import { CydPanelHr } from 'components/_panels/CydPanelHr/CydPanelHr';
import {
  getResolvedArgs,
  OutputArgsDisplay
} from './OutputArgsDisplay/OutputArgsDisplay';
import { parseNodeName } from '../CustomNodes/customNodeUtils';
import { useCydBaseModal } from 'components/_dialogs/CydBaseModal/useCydBaseModal';
import { OutputVariablesSettingForm } from './OutputArgsDisplay/OutputVariablesSettingForm';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import { StatusSelector } from './StatusSelector/StatusSelector';
import { CacaoConditionDisplay } from '../CacaoConditionDisplay/CacaoConditionDisplay';
import { PlaybooksArgsDisplay } from './PlaybooksArgsDisplay/PlaybooksArgsDisplay';
import { isPlaybookActionCompletedStatus } from 'utils/PlaybookUtils';
import React from 'react';
import { useRightPanelHook } from 'hooks/RightPanelHook';
// import { CommentsPanel } from '../CommentsPanel/CommentsPanel';

export type NodeDetailsPanelProps = {
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;
  node: GenericNode | null;

  playbookComment?: Array<ActivityItem>;
  onPlaybookStatusUpdate: (data: GenericPlaybookStatusUpdate) => void;

  // By a status update, we mean any change to the running instance of a playbook. eg. adding assignees, filling variables, etc.
  onNodeStatusUpdate: (data: GenericNodeUpdatePayload) => void;
  onRemoveStandaloneAction?: (data: GenericNode) => void;
  onCloseClick: () => void;
  availableUsers: Array<User>;
  availableTags: Array<CaseTag>;
};

const TITLE_MAP: Record<GenericPlaybook['playbookType'], string> = {
  atc: 'ATC Playbook',
  cacao: 'CACAO Playbook',
  standalone: 'Attached Actions'
};

export const NodeDetailsPanel = (props: NodeDetailsPanelProps) => {
  const {
    node,
    onNodeStatusUpdate,
    onRemoveStandaloneAction,
    onPlaybookStatusUpdate,
    playbook,
    playbookResolutionInfo,
    availableTags,
    availableUsers,
    onCloseClick
  } = props;

  const isPlaybookNode = node?.nodeType.type.match('playbook-action');
  const nodeStatus = node && playbookResolutionInfo?.nodeStatuses[node.nodeId];

  if (node && !nodeStatus) {
    throw new Error('Node selected, but no node status found');
  }

  // THIS IS AN ANNOYING ANTI-PATTERN
  // AND RESULT OF NOT ALLOWING THE 'SELECTED' VALUE TO BE A STRING.
  //https://github.com/cydarm/cydarm-frontend/issues/1231
  const selectedUser = useMemo(() => {
    if (!nodeStatus || !nodeStatus.assignee || !availableUsers) {
      return null;
    }
    return availableUsers.find((v) => v.uuid === nodeStatus.assignee) || null;
  }, [availableUsers, nodeStatus]);

  const selectedTags = useMemo(() => {
    if (!nodeStatus || !nodeStatus.tags || !availableTags) {
      return [];
    }

    return availableTags.filter((v) => {
      return nodeStatus.tags.includes(v.value);
    });
  }, [availableTags, nodeStatus]);

  // Purpose of this function is to also include the current values, as well as the new values.
  // Otherwise the backend errors.
  const handleNodeStatusUpdate = (
    payload: Omit<GenericNodeUpdatePayload, 'node'>
  ) => {
    if (onNodeStatusUpdate) {
      if (!node) {
        throw new Error(
          'Attempting to fire a onNodeStatusUpdate when no node is selected'
        );
      }

      onNodeStatusUpdate({
        stepId: payload.stepId,
        payload: {
          assignee: nodeStatus?.assignee ?? null,
          tags: selectedTags,
          status: nodeStatus?.status,
          ...payload.payload
        },
        node,
        variablesPayload: payload.variablesPayload
      });
    }
  };

  const nodeMode = determineManualOrAutomatic(node);
  const commentsQuery = useActionInstanceComments(
    (node?.oldData?.actionInstanceUuid || null) as string | null
  );

  const [variablesModal, open] = useCydBaseModal({
    content: (nodeStatus: AvailableStepStatuses, closeModal) => {
      if (!node) {
        return null;
      }

      return (
        <div>
          <Typography variant="h5">Set Variables</Typography>
          <OutputVariablesSettingForm
            closeModalFn={closeModal}
            onSubmitVariables={(payload) => {
              if (!node) {
                throw new Error(
                  'Attempting node update with no node available'
                );
              }

              handleNodeStatusUpdate({
                stepId: node.nodeId,
                payload: {
                  status: nodeStatus
                },
                variablesPayload: payload.variable_bindings
              });
            }}
            resolvedOutArgs={getResolvedArgs(
              ((node?.nodeType.type === 'action' ||
                node?.nodeType.type === 'start') &&
                node.nodeType.data?.outArgIds) ||
                [],
              playbookResolutionInfo,
              playbook
            )}
          />
        </div>
      );
    }
  });

  useRightPanelHook();

  return (
    <>
      {variablesModal}

      {/* nb. Although this component looks like the right hand panel
          It behaves differently (is actually absolutely positioned).
        */}
      <GenericNodeDetailsPanel
        headerContent={
          <div
            css={(theme) => `
          display: flex; 
          flex-flow: row nowrap; 
          justify-content: space-between; 
          align-items: center; 
          gap: ${theme.spacing(2)}; 
        `}
          >
            <Typography variant="overline" component="h5">
              {node ? 'Action' : TITLE_MAP[playbook.playbookType]}
            </Typography>

            {node &&
              !isPlaybookActionCompletedStatus(
                nodeStatus?.status as AvailableStepStatuses
              ) &&
              playbook.playbookType === 'standalone' &&
              onRemoveStandaloneAction && (
                <CydConfirmModal
                  message="Are you sure want to remove this action from the case?"
                  confirmText="Remove"
                  onConfirm={() => onRemoveStandaloneAction(node)}
                  renderTrigger={(onClick) => (
                    <CydIconButton
                      css={css`
                        margin-left: auto;
                      `}
                      icon="delete"
                      onClick={onClick}
                      label="Remove Action"
                    />
                  )}
                />
              )}

            {node && (
              <CydIconButton
                icon="close"
                onClick={onCloseClick}
                label="Unselect current node"
              />
            )}
          </div>
        }
      >
        {!node && (
          <>
            <Typography variant="h6">{playbook.title}</Typography>
            <Typography variant="subtitle2">Description</Typography>
            <Markdown>{playbook.description}</Markdown>
            <CydTagArrayDisplayByTagValues values={playbook.tags} />
          </>
        )}

        {!node && (
          <Box>
            <CydTabPanels
              variant="side-panel"
              label="selected step"
              initialSelectedTab="Variables"
              tabs={[
                ...(playbook.playbookType === 'cacao'
                  ? [
                      {
                        label: 'Variables',
                        content: (
                          <>
                            <PlaybooksArgsDisplay
                              playbook={playbook}
                              playbookResolutionInfo={playbookResolutionInfo}
                            />
                          </>
                        )
                      }
                    ]
                  : [])
              ]}
            />
          </Box>
        )}

        {node && (
          <>
            <div
              css={css`
                display: flex;
                flex-flow: row nowrap;
              `}
            >
              <Typography
                variant="h6"
                gutterBottom
                css={css`
                  flex: 1 1 auto;
                `}
              >
                {parseNodeName(node, playbook.playbookType)}
              </Typography>
              {isPlaybookNode ? (
                <CydIcon icon="menubook" label="playbook" />
              ) : (
                <ManualOrAutomaticIcon type={nodeMode} />
              )}
            </div>

            {nodeMode === 'manual' && (
              <>
                <div
                  css={(theme) => `
            display: grid; 
            grid-template-columns: 1fr 1fr; 
            align-items: center; 
            grid-gap: ${theme.spacing(1)}; 
          `}
                >
                  <StatusSelector
                    onChange={(newStatus) => {
                      if (
                        newStatus === 'success' &&
                        playbook.playbookType === 'cacao' &&
                        (node.nodeType.type === 'action' ||
                          node.nodeType.type === 'start') &&
                        node.nodeType.data.outArgIds &&
                        node.nodeType.data.outArgIds.length > 0
                      ) {
                        open(newStatus);
                      } else {
                        handleNodeStatusUpdate({
                          stepId: node.nodeId,
                          payload: {
                            status: newStatus
                          }
                        });
                      }
                    }}
                    mode={playbook.playbookType}
                    currentStatus={
                      nodeStatus?.status as any as AvailableStepStatuses
                    }
                  />

                  <FormControl
                    size="small"
                    css={(theme) => `
                    // This is to stop the form from not aligning with the status selector
                    padding: 0;
                    margin: 0
                  `}
                  >
                    <CydUserSelector
                      variant="color-small"
                      ariaLabel="Select user for this action"
                      availableUsers={availableUsers || []}
                      selectedUser={selectedUser}
                      onChange={(newAssignee) => {
                        handleNodeStatusUpdate({
                          stepId: node.nodeId,
                          payload: {
                            assignee: newAssignee ? newAssignee.uuid : undefined
                          }
                        });
                      }}
                      allowUnselect={true}
                    />
                  </FormControl>
                </div>
              </>
            )}
            <Box>
              <CydTabPanels
                variant="side-panel"
                label="selected step"
                initialSelectedTab="Description"
                tabs={[
                  {
                    label: 'Description',
                    content: (
                      <Box>
                        {node.description && (
                          <>
                            <Typography variant="subtitle2" gutterBottom>
                              Description
                            </Typography>

                            <Markdown>{node.description}</Markdown>
                          </>
                        )}

                        {(node.nodeType.type === 'if-condition' ||
                          node.nodeType.type === 'while-condition') && (
                          <>
                            <CacaoConditionDisplay
                              condition={node.nodeType.data.condition}
                              playbook={playbook}
                            />
                          </>
                        )}
                      </Box>
                    )
                  },

                  {
                    label: `Comments ${
                      commentsQuery.data ? `(${commentsQuery.data.length})` : ''
                    }`,
                    tabKey: 'Comments',
                    content: (
                      <Box>
                        <ServicedCommentsPanel
                          actionInstance={
                            node.oldData as PlaybookActionInstance
                          }
                        />
                      </Box>
                    )
                  },
                  ...(playbook.playbookType === 'cacao'
                    ? [
                        {
                          label: 'Variables',
                          content: (
                            <>
                              <InputArgsDisplay
                                node={node}
                                playbook={playbook}
                                resolutionInfo={playbookResolutionInfo}
                              />
                              <CydPanelHr />
                              <OutputArgsDisplay
                                node={node}
                                onUpdatePlaybookInstance={
                                  onPlaybookStatusUpdate
                                }
                                playbook={playbook}
                                playbookResolutionInfo={playbookResolutionInfo}
                              />
                            </>
                          )
                        }
                      ]
                    : [])
                ]}
              />
            </Box>
          </>
        )}
      </GenericNodeDetailsPanel>
    </>
  );
};
