/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import {
  ActivityItem,
  ActivityItemOpenState
} from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';
import { shouldShowPreview } from 'utils/CaseDataUtils';

const CONTENT_LENGTH_FOR_PARTIAL_COLLAPSE_THRESHOLD = 4;

export class CaseActivityUtils {
  public static determineShouldHavePartialCollapse(activityItem: ActivityItem) {
    // Form
    if (activityItem.mimetype === 'application/vnd.cydarm.form+json') {
      const form = JSON.parse(activityItem.content);
      return (
        form.humanData.length > CONTENT_LENGTH_FOR_PARTIAL_COLLAPSE_THRESHOLD
      );
    }

    // File
    if (activityItem.filename) {
      if (this.isMalformedJSON(activityItem)) {
        return false;
      }

      return shouldShowPreview({
        mimetype: activityItem.mimetype,
        datasize: activityItem.datasize ?? ActivityItemOpenState.Closed,
        filename: activityItem.filename
      });
    }

    // Comments
    if (
      activityItem.mimetype === 'text/plain' ||
      activityItem.mimetype === 'text/markdown' ||
      activityItem.mimetype.startsWith('application/stix+json')
    ) {
      const contentSplitByNewLines = activityItem.content?.split('\n') ?? '';

      return (
        contentSplitByNewLines.length >
        CONTENT_LENGTH_FOR_PARTIAL_COLLAPSE_THRESHOLD
      );
    }

    return false;
  }

  // If the activity item is a JSON file and the content is not valid JSON,
  // the content is considered malformed.
  public static isMalformedJSON(activityItem: ActivityItem) {
    if (activityItem.filename && activityItem.mimetype === 'application/json') {
      try {
        JSON.parse(activityItem.content);
      } catch (e) {
        return true;
      }
    }
    return false;
  }

  public static shouldScrollToBottom({
    isFirstLoad,
    isDrawerOpen = false
  }: {
    isFirstLoad: boolean;
    isDrawerOpen?: boolean;
  }) {
    // AC14. If the drawer is open, autoscroll should be disabled
    if (isDrawerOpen) {
      return false;
    }
    // AC1. First Load - https://cydarm.atlassian.net/wiki/spaces/FEAT/pages/1803747329/Case+Thread+Behaviour
    return isFirstLoad;
  }

  public static shouldScrollToElement({
    isDrawerOpen = false,
    activityItem,
    currentUserUuid,
    // the latest activity item in the case thread in the root level
    isRootLevelActivityItem = false,
    isRootLatestActivityItemVisible = false
  }: {
    isDrawerOpen?: boolean;
    activityItem: ActivityItem;
    currentUserUuid: string;
    isRootLevelActivityItem?: boolean;
    isRootLatestActivityItemVisible?: boolean;
  }): boolean {
    if (isDrawerOpen) {
      return false;
    }
    // New changes by current user
    if (activityItem.creatoruuid === currentUserUuid) {
      if (isRootLatestActivityItemVisible) {
        return true;
      }
      // if it is not a comment or attachment, do not scroll
      return !activityItem.audit;
    }
    // New changes by other users
    else {
      if (isRootLevelActivityItem) {
        return isRootLatestActivityItemVisible;
      }
    }
    return false;
  }
}
