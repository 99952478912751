/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  IPlaybookActionType,
  PlaybookAction
} from 'interface/Playbook.interface';
import { createAction } from '@reduxjs/toolkit';

// Playbooks Actions
interface ICreate {
  playbookAction: IPlaybookActionType;
  isShowNotification?: boolean;
  resolve?: (data: { uuid: string }) => void;
}

interface IUpdate {
  uuid: string;
  playbookAction: PlaybookAction;
  resolveAction?: any;
}

interface IAddRemoveTag {
  actionUuid: string;
  tagUuid: string;
  resolve?: () => void;
}

// Playbooks Actions Function
export const createFetchPlaybookActions = createAction('playbookActions/fetch');
export const createFetchPlaybookActionByUuid = createAction<string>(
  'playbookActions/fetchByUuid'
);
export const createPlaybookAction = createAction<ICreate>(
  'playbookActions/create'
);
export const createUpdateAtcPlaybookAction = createAction<IUpdate>(
  'playbookActions/update'
);
export const createDeletePlaybookAction = createAction<string>(
  'playbookActions/delete'
);

export const createAddPlaybookActionTag = createAction<IAddRemoveTag>(
  'playbookActions/tag/add'
);
export const createRemovePlaybookActionTag = createAction<IAddRemoveTag>(
  'playbookActions/tag/remove'
);
