/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { createFetchCaseWatchers } from 'states/watchers/actions';
import {
  caseWatchersSelector,
  watchersHasLoadedSelector,
  watchersIsLoadingSelector
} from 'states/watchers/selectors';
import { useAutoEffect } from './ReduxHooks';

export const useCaseWatchers = (caseUuid: string) => {
  return useAutoEffect({
    selector: caseWatchersSelector,
    ac: createFetchCaseWatchers,
    selectorData: caseUuid,
    actionData: caseUuid,
    isLoadingSelector: watchersIsLoadingSelector(caseUuid),
    hasLoadedSelector: watchersHasLoadedSelector(caseUuid)
  });
};
