import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';

export const caseGroupsSelector = ({
  caseGroupsStore: { caseGroups }
}: IStore) => caseGroups;

export const caseGroupsOfCaseSelector = createSelector(
  caseGroupsSelector,
  (_, caseUuid) => caseUuid,
  (caseGroups, caseUuid) => caseGroups[caseUuid]
);
