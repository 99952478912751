/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import { useCaseContributors } from 'hooks/CaseDataHooks';
import { CydContributorsDisplay } from './CydContributorsDisplay';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { CydObjectNotFound } from 'components/_objectDisplay/CydObjectNotFound/CydObjectNotFound';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

export type CydContributorsDisplayProps = {
  caseUuid: string;
};

export const CydContributorsDisplayByCaseUuid = withErrorBoundary(
  (props: CydContributorsDisplayProps) => {
    const { caseUuid } = props;
    if (!caseUuid) {
      return null;
    }

    //! NOT NEEDED - use CydUserDisplayById?
    const { data: contributors, isLoading } = useCaseContributors(caseUuid);

    if (isLoading) {
      return <LoadingSpinner />;
    }
    return <CydContributorsDisplay contributors={contributors} />;
  },
  (props) => <div data-testid="cyd-contributors-section">{props.children}</div>,
  (props) => <CydObjectNotFound objectId={props.caseUuid} />
);
