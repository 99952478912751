import { PlaybookActionInstance } from 'interface/Playbook.interface';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from '../type';

export function convertStandaloneActionsToFlowchart(
  actionInstances: Array<PlaybookActionInstance>
): GenericPlaybook {
  const nodes: Array<GenericNode> = actionInstances.map((v, i, array) => {
    const nextNode = array[i + 1];

    const nodeId = `${v.actionUuid}-${v.position}`;

    const nextNodeId = nextNode
      ? `${nextNode.actionUuid}-${nextNode.position}`
      : null;

    return {
      name: v.actionName || '',
      description: v.actionDescription,
      nodeType: {
        type: 'action',
        nextNode: nextNodeId,
        data: {}
      },

      oldData: v,
      nodeId: nodeId // !NB!  The old data model does not have actionUuid being unique in the list, so to make unique, we need to combine actionUuid and nodeId
    };
  });

  return {
    id: 'standalone',
    playbookType: 'standalone',
    nodes: nodes,
    title: '',
    description: '',
    acl: '',
    tags: [],
    modified: '',
    created: '',
    variablesMap: {}
  };
}

export function convertStandaloneActionsToFlowchartAndResolution(
  actionInstances: Array<PlaybookActionInstance>
): {
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;
} {
  const playbook = convertStandaloneActionsToFlowchart(actionInstances);

  const playbookResolutionInfo: GenericPlaybookResolutionInfo = {
    topLevelPlaybookStatus: {
      tags: []
    },

    resolvedVariables: {},
    nodeStatuses: actionInstances.reduce((acc, cur) => {
      if (!cur.actionUuid) {
        throw new Error(`Current node did not have a actionUuid`);
      }

      return {
        ...acc,
        [`${cur.actionUuid}-${cur.position}`]: {
          status: cur.status,
          nodeId: cur.actionUuid,
          assignee: cur.assigneeUuid || null,
          tags: cur.tags || []
        }
      };
    }, {})
  };

  return {
    playbook,
    playbookResolutionInfo
  };
}
