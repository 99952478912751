/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Grid, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useNewCaseDataFormStyles } from './styles';
import { DefaultSelect, IOption } from 'components/fields/DefaultSelect';
import { observableTypes, confidenceRating } from 'utils/CaseDataUtils';

import { CydTextField } from 'components/_formElements/CydTextField';
import { useState } from 'react';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { CydDateTimePicker } from 'components/_formElements/CydDateTimePicker/CydDateTimePicker';
import { BulkIocUpload } from './BulkIocUpload/BulkIocUpload';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';

const hashTypeOption = [
  {
    label: 'MD5',
    value: 'MD5'
  },
  {
    label: 'SHA-1',
    value: 'SHA-1'
  },
  {
    label: 'SHA-256',
    value: 'SHA-256'
  },
  {
    label: 'SHA-512',
    value: 'SHA-512'
  },
  {
    label: 'SHA3-256',
    value: 'SHA3-256'
  },
  {
    label: 'SHA3-512',
    value: 'SHA3-512'
  },
  {
    label: 'SSDEEP',
    value: 'SSDEEP'
  },
  {
    label: 'TLSH',
    value: 'TLSH'
  }
] as const;

type HashTypes = (typeof hashTypeOption)[number]['value'];

const ValuePlaceholder = new Proxy( // 😳
  {
    artifact: 'Enter payload bin (base64 encoded)',
    'email-addr': 'Enter email address',
    file: 'Enter file name'
  },
  {
    get: (target, name) =>
      target.hasOwnProperty(name) ? target[name] : 'Enter value'
  }
);

type ICaseDataFormProps = {
  caseUuid: string;
  formData: Record<string, unknown>;
  onChange: (a) => void;
};

export const CaseDataStixForm: React.FC<ICaseDataFormProps> = ({
  caseUuid,
  formData,
  onChange
}) => {
  const { select, radioLabel, hashDiv } = useNewCaseDataFormStyles();

  const handleChange = (event: {
    target: {
      value: unknown;
      name: string;
    };
  }) => {
    const name = event.target.name;
    const value = event.target.value;

    const newData = {
      ...formData,
      [name]: value
    };

    onChange(newData);
  };

  // All of these use states are a bit redundant as that information is encompassed in the formData state anwyay

  const [selectedObservationType, setSelectedObservationType] = useState(
    null as null | (typeof observableTypes)[number]
  );
  const { isToggleEnabled } = useFeatureToggles();
  const isUsingBulkIoc = isToggleEnabled('REACT_APP_USE_BULK_IOC_UPLOAD');

  const [isIocSelected, setIsIocSelected] = useState<boolean>(true);

  const [dateObserved, setDateObserved] = useState<string>();

  const [hash, setHash] = useState({
    hashType: 'MD5' as HashTypes,
    hash: ''
  });

  const handleIOCChange = (event) => {
    setIsIocSelected(event.target.checked);
    handleChange({
      target: {
        name: event.target.name,
        value: event.target.checked
      }
    });
  };

  const handleFileChange = (event) => {
    const newHash =
      event.target.name === 'hash'
        ? {
            ...hash,
            hash: event.target.value
          }
        : {
            ...hash,
            hashType: event.target.value
          };

    handleChange({
      target: {
        name: 'file',
        value: newHash
      }
    });

    setHash(newHash);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={8}>
            <CydSimpleSelect
              autoFocus={true}
              name="dataType"
              required
              availableOptions={observableTypes}
              selectedValueString={selectedObservationType?.value}
              onChange={(value) => {
                setSelectedObservationType(value);
                handleChange({
                  target: {
                    name: 'dataType',
                    value: value.value
                  }
                });
              }}
              label="Select data type"
              generateOptionLabel={(v) => {
                return v.label;
              }}
              generateValueString={(v) => v.value}
            />
          </Grid>
          <Grid item xs={2} alignSelf={'center'}>
            <FormControlLabel
              name="isIoc"
              control={
                <Checkbox
                  checked={isIocSelected}
                  style={{ marginLeft: '14px' }}
                  onChange={handleIOCChange}
                />
              }
              label="IOC"
              classes={{ label: radioLabel }}
            />
          </Grid>

          <Grid item xs={2}>
            {isUsingBulkIoc && <BulkIocUpload caseUuid={caseUuid} />}
          </Grid>
        </Grid>
        <CydTextField
          className={select}
          name="dataValue"
          label={
            //@ts-ignore
            ValuePlaceholder[selectedObservationType?.value] || 'Enter value'
          }
          onChange={(_, e) => handleChange(e)}
          required={true}
        />
        {isIocSelected && (
          <CydTextField
            name="name"
            onChange={(_, e) => handleChange(e)}
            className={select}
            label={'Enter name'}
          />
        )}
        {isIocSelected && (
          <CydTextField
            name="description"
            onChange={(_, e) => handleChange(e)}
            className={select}
            label={'Enter description'}
          />
        )}
        {selectedObservationType?.value === 'artifact' ? (
          <CydTextField
            className={select}
            name="mimeType"
            label="Enter mime type"
            onChange={(_, e) => handleChange(e)}
          />
        ) : selectedObservationType?.value === 'email-addr' ? (
          <CydTextField
            className={select}
            name="displayName"
            label="Enter display name"
            onChange={(_, e) => handleChange(e)}
          />
        ) : selectedObservationType?.value === 'file' ? (
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <div className={hashDiv}>Hash Type:</div>
            </Grid>
            <Grid item xs={4}>
              <DefaultSelect
                options={hashTypeOption as unknown as IOption[]}
                onChange={handleFileChange}
                name="hashType"
                placeholder="Enter hash type"
                placeholderSelection={false}
                className={select}
                defaultValue={'MD5'}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                className={select}
                name="hash"
                placeholder="Enter hash"
                onChange={handleFileChange}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CydDateTimePicker
              makeInitialTimeBeCurrentTime={true}
              label="Enter date of first observation"
              initialValue={dateObserved || null}
              required
              onComplete={(newValue) => {
                setDateObserved(newValue || undefined);
                handleChange({
                  target: {
                    name: 'firstObserved',
                    value: newValue
                  }
                });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <CydSimpleSelect
              availableOptions={confidenceRating}
              onChange={(data) => {
                handleChange({
                  target: {
                    name: 'confidence',
                    value: data.value
                  }
                });
              }}
              generateValueString={(v) => v.value.toString()}
              generateOptionLabel={(v) => v.label as string}
              selectedValueString={`${formData.confidence}`}
              label="Credibility of information"
              name="confidence"
            />
          </Grid>
        </Grid>
        {/* conditional renders depending on type */}
        <Grid container spacing={2} style={{ display: 'none' }}>
          <Grid item xs={6}>
            <DefaultSelect
              options={[{ label: 'From', value: 'From' }]}
              value={''}
              placeholder={'From: (Select relationship type)'}
            />
          </Grid>
          <Grid item xs={6}>
            <DefaultSelect
              options={[{ label: 'To', value: 'To' }]}
              value={''}
              placeholder={'To: (Select relationship type)'}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* sidebar */}
    </Grid>
  );
};
