/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { DialogProps } from '@mui/material/Dialog';
import { DefaultDialog } from 'components/DefaultDialog';
import { D3Svg } from './D3Svg';
import { useStyles } from './styles';
import { memo, FC } from 'react';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

interface ICaseStatusDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  currentCaseState: string | null;
}

export const CaseStatusDialog: FC<ICaseStatusDialogProps> = memo(
  ({ onClose, currentCaseState, ...rest }) => {
    const { dialogBox } = useStyles();
    return (
      <>
        <DefaultDialog
          classes={{ paper: dialogBox }}
          title="Case Workflow"
          maxWidth="lg"
          {...rest}
        >
          <D3Svg currentCaseState={currentCaseState} />
          <CydButton onClick={onClose} variant="text">
            Close
          </CydButton>
        </DefaultDialog>
      </>
    );
  }
);
