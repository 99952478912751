/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as moment from 'moment-timezone';
import React, { useMemo, useEffect, useState } from 'react';
import {
  fetchStoredTimezoneList,
  fetchTotpConfig,
  signOut,
  updateTimezone
} from 'states/auth/actions';
import * as TokenManager from 'utils/TokenManager';
import {
  timezoneSelector,
  timezoneListSelector,
  authIsLoadingSelector,
  totpConfigSelector,
  signedInSelector
} from 'states/auth/selectors';
import { IAutoEffectReturn, useAutoEffect } from './ReduxHooks';
import { useOrgs } from './OrgHooks';
import { LoadingData } from 'types/LoadingData';
import { isAuthenticated as authFn } from '../utils/TokenManager';
import { signInSuccess } from 'states/auth/slice';
import { rtkqUsersApi } from './UserHooks';

export const useCurrentUser = () => {
  const currentUserResult = rtkqUsersApi.endpoints.getCurrentUser.useQuery({});
  return currentUserResult;
};

export const useIsSignedIn = () => {
  const dispatch = useDispatch();

  const signedIn = useSelector(signedInSelector);
  const isAuthenticated = authFn();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(signInSuccess());
    }
  }, [isAuthenticated, dispatch]);
  return signedIn;
};

export function useCurrentUserPrimaryOrg(): LoadingData<string | null> {
  const currentUserResult = useCurrentUser();
  const orgsResult = useOrgs();

  if (
    orgsResult.isLoading ||
    !orgsResult.hasLoaded ||
    currentUserResult.isLoading
  ) {
    return {
      isLoading: true,
      hasLoaded: false,
      data: null
    };
  } else {
    const orgUuid = currentUserResult.data?.org as string | null;

    if (orgUuid) {
      return {
        isLoading: false,
        hasLoaded: true,
        data: orgUuid
      };
    } else {
      // If the user doesn't have an org, return null
      return {
        isLoading: false,
        hasLoaded: true,
        data: null
      };
    }
  }
}

export const useTimezoneLegacy = () => {
  const timezone = useSelector(timezoneSelector);

  return useMemo(() => (timezone ? timezone : moment.tz.guess()), [timezone]);
};

export const useUpdateTimezone = () => {
  const dispatch = useDispatch();
  return (newTimezone) => {
    dispatch(updateTimezone(newTimezone));
  };
};

export const useTimezoneListAvailable = () =>
  useMemo(() => moment.tz.names(), []);

export const useStoredTimezoneList = () => {
  return useAutoEffect({
    selector: timezoneListSelector,
    ac: fetchStoredTimezoneList,
    equality: shallowEqual
  });
};

type TimeZoneHook = {
  timezone: string;
  listOfTimezones: Array<string>;
  updateTimezone: (newTimeZone) => void;
  favoriteTimezones: IAutoEffectReturn<string[]>;
};

const TimeZoneHookContext = React.createContext<TimeZoneHook>({
  timezone: moment.tz.guess(),
  listOfTimezones: moment.tz.names(),
  updateTimezone: () => {
    console.warn('TimeZoneHook not initialised - this a noop!');
  },
  favoriteTimezones: {
    data: [],
    fetchData: () => {
      console.warn('TimeZoneHook not initialised - this a noop!');
    },
    isLoading: false,
    hasLoaded: true
  }
});

export const TimeZoneHookProvider = (
  props: React.PropsWithChildren<TimeZoneHook>
) => {
  const { children, ...rest } = props;
  return (
    <TimeZoneHookContext.Provider value={rest}>
      {children}
    </TimeZoneHookContext.Provider>
  );
};

// I think I will keep this named 'useTimezone2' just so people don't accidentally use it
export const useTimezone2 = () => {
  return React.useContext(TimeZoneHookContext);
};

export const useSignout = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(signOut());
  };
};

export const ProductionTimezoneHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const timezone = useTimezoneLegacy();
  const updateTimezone = useUpdateTimezone();
  const favoriteTimezones = useStoredTimezoneList();
  const timezoneList = moment.tz.names();

  return (
    <TimeZoneHookProvider
      timezone={timezone}
      updateTimezone={updateTimezone}
      favoriteTimezones={favoriteTimezones}
      listOfTimezones={timezoneList}
    >
      {props.children}
    </TimeZoneHookProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */
export const InMemoryTimezoneHookProvider = (
  props: React.PropsWithChildren<{
    initialTz?: string;
  }>
) => {
  const { initialTz } = props;

  const [tz, setTz] = useState(initialTz || moment.tz.guess());

  const timezoneList = moment.tz.names();

  // Nothing
  const favouriteTzs = useMemo(() => {
    return {
      data: [],
      fetchData: () => {
        console.warn('TimeZoneHook not initialised - this a noop!');
      },
      isLoading: false,
      hasLoaded: true
    };
  }, []);

  return (
    <TimeZoneHookProvider
      timezone={tz}
      updateTimezone={setTz}
      favoriteTimezones={favouriteTzs}
      listOfTimezones={timezoneList}
    >
      {props.children}
    </TimeZoneHookProvider>
  );
};

export const useAuthIsLoading = () => useSelector(authIsLoadingSelector);

export const useSessionHook = () => {
  const dispatch = useDispatch();
  const isAuthenticated = TokenManager.isAuthenticated();
  useEffect(() => {
    const tokenRefreshRate = 60000;
    if (!isAuthenticated) {
      //dispatch(signOut());
    }
    const fetchCaseInterval = setInterval(() => {
      TokenManager.refreshTokenIfRequired();
    }, tokenRefreshRate);

    return () => clearInterval(fetchCaseInterval);
  }, [isAuthenticated, dispatch]);
};

export const useFetchTotpConfig = (userUuid: string) => {
  return useAutoEffect({
    selector: totpConfigSelector,
    ac: fetchTotpConfig,
    actionData: userUuid,
    equality: shallowEqual
  });
};
