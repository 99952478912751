/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import {
  useAllNotifications,
  useNotificationDrawerState
} from 'hooks/NotificationHooks';
import { useMemo } from 'react';
import { css, keyframes } from '@emotion/react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { closeNotificationDrawer } from 'states/notifications/slice';

/** @jsxImportSource @emotion/react */

const notificationEnterAnimation = keyframes`
  from {opacity: 0; transform: translateX(100%);}
  to {opacity: 1; transform: translateX(0%);}
`;

export const NotificationDrawer = () => {
  const dispatch = useDispatch();
  const notifications = useAllNotifications();
  const notificationsToShow = useMemo(
    () => notifications.length > 0,
    [notifications.length]
  );
  const showDrawer = useNotificationDrawerState();
  const handleClose = () => {
    dispatch(closeNotificationDrawer());
  };

  if (showDrawer) {
    return (
      <Paper
        css={(theme) => css`
          position: fixed;
          right: 0;
          top: 0;
          width: 400px;
          height: 100%;
          z-index: ${theme.zIndex.drawer};
          padding: 30px 20px 0 20px;
          overflow-y: auto;
          overflow-x: hidden;
          box-shadow: ${theme.shadows[15]};
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: transparent;
            border-radius: ${theme.shape.borderRadius}px;
          }
          &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: ${theme.shape.borderRadius}px;
          }
          &::-webkit-scrollbar {
            width: 5px;
          }
          animation: ${notificationEnterAnimation} 0.3s ease forwards;
        `}
      >
        <Typography
          css={css`
            && {
              margin-bottom: 16px;
            }
          `}
          variant="h4"
          component="h4"
        >
          Notifications
        </Typography>

        <CancelIcon
          css={css`
            position: absolute;
            top: 30px;
            right: 20px;
            width: 35px;
            height: 35px;
            cursor: pointer;
          `}
          onClick={handleClose}
        />

        {notifications.map((notification) => (
          <div
            css={(theme) => css`
              background: ${theme.palette.action.selected};
              padding: 16px;
              margin-bottom: 12px;
              border-radius: 8px;
              animation: ${notificationEnterAnimation} 0.3s ease forwards;
            `}
            key={notification.id}
          >
            <Typography
              css={(theme) => `
                color: ${theme.palette.text.secondary};
              `}
            >
              {notification.message}
            </Typography>
            {notification.timestamp && (
              <Typography
                css={(theme) => `
                  color: ${theme.palette.text.disabled};
                  font-size: 0.8rem;
                  text-align: right;
                `}
              >
                {moment
                  .duration(notification.timestamp - Date.now())
                  .humanize(true)}
              </Typography>
            )}
          </div>
        ))}

        {!notificationsToShow && (
          <Typography
            css={css`
              font-size: 0.9rem;
              margin-top: 25px;
            `}
          >
            No Notifications To Show
          </Typography>
        )}
      </Paper>
    );
  } else {
    return null;
  }
};
