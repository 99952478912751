/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { put, takeLatest } from 'redux-saga/effects';
import {
  createFetchMetas,
  createMeta,
  createUpdateMeta,
  createUpdateMetas,
  createRemoveMeta
} from './actions';
import { addNotification } from 'states/notifications/slice';
import {
  createMetasSuccess,
  fetchMetasSuccess,
  removeMetasSuccess
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { IMeta } from 'interface/Meta.interface';
import {
  apiPerformCreateMeta,
  apiPerformDeleteMeta,
  apiPerformFetchMetas,
  apiPerformUpdateMeta
} from 'services/CaseServices';

export function* performFetchMetas() {
  try {
    const { json } = yield apiPerformFetchMetas();
    const metas = json.map(({ precedence, ...rest }) => ({
      ...rest,
      precedence: parseInt(precedence)
    }));

    yield put(fetchMetasSuccess(metas));
  } catch (error) {
    yield put(addNotification({ message: 'Failed to fetch metadata' }));
  }
}

// TODO: hook this function to BE
export function* performUpdateMetas({
  payload: metas
}: PayloadAction<IMeta[]>) {
  try {
    //yield put(fetchMetasSuccess(metas));

    yield put(addNotification({ message: 'Update metadata successfully' }));
  } catch (error) {
    yield put(addNotification({ message: 'Failed to update metadata' }));
  }
}

export function* performCreateMeta({ payload: meta }) {
  try {
    const { json: recentlyCreatedMeta } = yield apiPerformCreateMeta(meta);

    yield put(createMetasSuccess(recentlyCreatedMeta));
    // TODO: remove it when the create meta endpoint is fixed and an ACL is returned
    yield put(createFetchMetas());
    yield put(addNotification({ message: 'Created metadata successfully' }));
  } catch (error) {
    yield put(addNotification({ message: 'Failed to create metadata' }));
  }
}

export function* performUpdateMeta({ payload: meta }) {
  try {
    const { uuid, name, acl, match } = meta;

    yield apiPerformUpdateMeta(uuid, { uuid, name, acl, match });
    yield put(createFetchMetas());
    yield put(addNotification({ message: 'Update metadata successfully' }));
  } catch (error) {
    yield put(addNotification({ message: 'Failed to update metadata' }));
  }
}

export function* performDeleteMeta({ payload: meta }) {
  try {
    const { uuid, name } = meta;
    yield apiPerformDeleteMeta(uuid);
    yield put(removeMetasSuccess(meta));
    yield put(
      addNotification({ message: `Successfully removed metadata ${name}` })
    );
  } catch (error) {
    yield put(addNotification({ message: 'Failed to remove metadata' }));
  }
}

function* watchFetchMetas() {
  yield takeLatest(createFetchMetas, performFetchMetas);
}

function* watchUpdateMetas() {
  yield takeLatest(createUpdateMetas, performUpdateMetas);
}

function* watchUpdateMeta() {
  yield takeLatest(createUpdateMeta, performUpdateMeta);
}

function* watchCreateMeta() {
  yield takeLatest(createMeta, performCreateMeta);
}

function* watchRemoveMeta() {
  yield takeLatest(createRemoveMeta, performDeleteMeta);
}

export default [
  watchFetchMetas(),
  watchUpdateMetas(),
  watchCreateMeta(),
  watchUpdateMeta(),
  watchRemoveMeta()
];
