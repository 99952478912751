/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import React from 'react';

/** @jsxImportSource @emotion/react */
export type BulkIocLoadingProps = {};

export const BulkIocLoading = (props: BulkIocLoadingProps) => {
  return (
    <>
      <div
        css={(theme) => `
        display: flex;
        flex-flow: column nowrap; 
        justify-content: center; 
        align-items: center; 
        gap: ${theme.spacing(2)};

        margin: ${theme.spacing(8)}; 
      `}
      >
        <LoadingSpinner />
        <Typography variant="body2">Uploading CSV</Typography>
      </div>
    </>
  );
};
