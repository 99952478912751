/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useDefaultCheckboxStyle = makeStyles(
  ({ spacing, palette: { primary, text, common } }) => ({
    label: {
      fontSize: 16,
      color: text.secondary,
      marginBottom: spacing()
    },
    focused: {
      color: common.black
    },
    checkBox: {
      color: text.secondary
    },
    checkBoxChecked: {
      color: primary.main
    }
  })
);
