/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { Org } from 'interface/Org.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchOrgs } from './actions';

export interface IOrgStoreState {
  isLoading: boolean;
  hasLoaded: boolean;
  error?: ErrorPayload | null;
  list: Org[];
}

const initialState: IOrgStoreState = {
  list: [],
  isLoading: false,
  hasLoaded: false,
  error: null
};

const orgSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {
    fetchOrgsSuccess: (state, { payload }: PayloadAction<Org[]>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.list = payload;
    },

    fetchOrgByUuidSuccess: (state, { payload }: PayloadAction<Org>) => {
      state.list = [
        ...state.list.filter(({ uuid }) => uuid !== payload.uuid),
        payload
      ];
    },

    fetchOrgsFailed: (state, { payload }: PayloadAction<ErrorPayload>) => {
      state.error = payload;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchOrgs, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: orgs,
  actions: { fetchOrgsSuccess, fetchOrgByUuidSuccess, fetchOrgsFailed }
} = orgSlice;
