/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICaseStix } from 'interface/Case.interface';
import { IDetailedCaseData } from 'interface/CaseData.interface';
interface IOpenCaptionItem {
  data: ICaseStix | IDetailedCaseData;
  node: any;
  id: string;
}

export interface IOpenCaptionStoreState {
  captions: IOpenCaptionItem[];
}

const initialState: IOpenCaptionStoreState = {
  captions: []
};

const activityTimelineSlice = createSlice({
  name: 'activityTimeline',
  initialState,
  reducers: {
    openTimelineCaption: {
      reducer: (state, { payload }: PayloadAction<IOpenCaptionItem>) => {
        state.captions = [
          ...state.captions.filter(({ id }) => id !== payload.id),
          payload
        ];
      },
      prepare: (payload) => ({
        payload: { ...payload, id: payload.data.uuid || payload.data.id }
      })
    },
    closeTimelineCaption: (state, { payload }: PayloadAction<string>) => {
      state.captions = state.captions.filter(
        (caption) => caption.id !== payload
      );
    }
  }
});

export const {
  reducer: activityTimelineStore,
  actions: { openTimelineCaption, closeTimelineCaption }
} = activityTimelineSlice;
