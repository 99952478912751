/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { takeEveryThrottledPerKey } from 'states/sagas';
import {
  createFetchGroupMembers,
  createAddGroupMember,
  createRemoveGroupMember,
  createFetchGroupChildGroups,
  createAddGroupChild,
  createRemoveGroupChild,
  createFetchRecursiveGroupMembers
} from './actions';
import {
  addGroupChildGroupsSuccess,
  fetchGroupChildGroupsSuccess,
  fetchGroupMembersSuccess,
  fetchRecursiveGroupMembersSuccess,
  removeGroupMembersSuccess,
  removeGroupMemberSuccess
} from './slice';
import { addNotification } from 'states/notifications/slice';
import {
  apiAddGroupChild,
  apiAddGroupMember,
  apiFetchChildGroups,
  apiFetchGroupMembers,
  apiFetchRecursiveGroupMembers,
  apiRemoveGroupChild,
  apiRemoveGroupMember
} from 'services/GroupsServices';

function* performFetchGroupMembers({ payload: uuid }: { payload: string }) {
  try {
    const { json: members } = yield apiFetchGroupMembers(uuid);
    yield put(
      fetchGroupMembersSuccess({
        uuid,
        members
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to fetch user group membership details`
      })
    );
  }
}

function* performFetchRecursiveGroupMembers({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    const { json: deepMembers } = yield apiFetchRecursiveGroupMembers(uuid);
    yield put(
      fetchRecursiveGroupMembersSuccess({
        uuid,
        members: deepMembers
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to fetch user group membership details`
      })
    );
  }
}

function* performAddGroupMember(action) {
  const {
    payload: { groupUuid, memberUuid }
  } = action;

  try {
    yield apiAddGroupMember(groupUuid, memberUuid);
    yield put(createFetchGroupMembers(groupUuid));
    //broken reducer must fix
    //yield put(addGroupMemberSuccess({groupUuid: groupUuid, memberUuid: memberUuid }));
    yield put(addNotification({ message: 'Added user to group successfully' }));
  } catch (ex) {
    yield put(addNotification({ message: `Failed to add user to group` }));
  }
}

function* performRemoveGroupMember(action) {
  const {
    payload: { groupUuid, memberUuid }
  } = action;

  try {
    yield apiRemoveGroupMember(groupUuid, memberUuid);
    yield put(removeGroupMemberSuccess({ groupUuid, memberUuid }));
    yield put(
      addNotification({ message: 'Removed user from group successfully' })
    );
    yield put(createFetchGroupMembers(groupUuid));
  } catch (ex) {
    yield put(addNotification({ message: `Failed to remove user from group` }));
  }
}

function* performFetchGroupChildGroups({ payload: uuid }: { payload: string }) {
  try {
    const { json: childGroups } = yield apiFetchChildGroups(uuid);

    yield put(
      fetchGroupChildGroupsSuccess({
        uuid,
        childGroups
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to fetch group children`
      })
    );
  }
}

function* performAddGroupChild(action) {
  const {
    payload: { groupUuid, childGroupUuid }
  } = action;

  try {
    yield apiAddGroupChild(groupUuid, childGroupUuid);
    yield put(
      addGroupChildGroupsSuccess({
        groupUuid,
        childGroupUuid
      })
    );
    yield put(addNotification({ message: 'Added group child successfully' }));
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to add group child' }));
  }
}

function* performRemoveGroupChild(action) {
  const {
    payload: { groupUuid, childGroupUuid }
  } = action;

  try {
    yield apiRemoveGroupChild(groupUuid, childGroupUuid);
    yield put(removeGroupMembersSuccess({ groupUuid, childGroupUuid }));
    yield put(addNotification({ message: 'Removed group child successfully' }));
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to remove group child' }));
  }
}

/* Watchers */
function* watchFetchGroupMembers() {
  yield takeEveryThrottledPerKey(
    createFetchGroupMembers,
    performFetchGroupMembers,
    ({ payload: uuid }) => uuid,
    100
  );
}

function* watchAddGroupMember() {
  yield takeEvery(createAddGroupMember, performAddGroupMember);
}

function* watchRemoveGroupMember() {
  yield takeEvery(createRemoveGroupMember, performRemoveGroupMember);
}

function* watchFetchGroupChildGroups() {
  yield takeEvery(createFetchGroupChildGroups, performFetchGroupChildGroups);
}

function* watchAddGroupChild() {
  yield takeEvery(createAddGroupChild, performAddGroupChild);
}

function* watchRemoveGroupChild() {
  yield takeEvery(createRemoveGroupChild, performRemoveGroupChild);
}

function* watchFetchRecursiveGroupMembers() {
  yield takeLatest(
    createFetchRecursiveGroupMembers,
    performFetchRecursiveGroupMembers
  );
}

export default [
  watchFetchGroupMembers(),
  watchAddGroupMember(),
  watchRemoveGroupMember(),
  watchFetchGroupChildGroups(),
  watchAddGroupChild(),
  watchRemoveGroupChild(),
  watchFetchRecursiveGroupMembers()
];
