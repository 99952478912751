/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import React, { useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router';
import {
  useCaseTransitionableStates,
  useSingleCase,
  useSingleCasePolling
} from 'hooks/deprecated_CaseHooks';
import { caseViewPageChildPages } from './CaseViewPageTabs';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CydPageSubNav } from 'components/_layout/CydPageSubNav/CydPageSubNav';

import { CydCaseViewHeader } from 'components/_caseView/CydCaseViewHeader/CydCaseViewHeader';
import { useDataSignificancesRedux, useUpdateCase } from 'hooks/CaseDataHooks';
import { useSeverityStates } from 'hooks/CaseSeverityHooks';
import { useCaseAssignees } from 'hooks/UserHooks';
import {
  useIsWatched,
  useUnwatchCaseMutation,
  useWatchCaseMutation
} from 'hooks/WatchingCasesHooks';
import { useCurrentUser } from 'hooks/AuthHooks';
import { useSystemPropValue } from 'hooks/SysPropsHooks';
import { SYSTEM_PROPERTY } from '../../constants';
import { useCheckAccessPermissionsForObjects } from 'hooks/AclHooks';
import { useAddNotification } from 'hooks/NotificationHooks';
import { JustificationModal } from 'components/JustificationModal/JustificationModal';
import {
  useIsMultiOrgStack,
  useOrgs,
  useSingleOrgByName
} from 'hooks/OrgHooks';
import { CaseActivityFilterSetting } from 'components/_caseView/CydCaseViewActivityFilter/CydCaseViewActivityFilter';
import { Case } from 'interface/Case.interface';
import { CydGenericErrorPlaceholder } from 'components/_placeholders/CydGenericErrorPlaceholder/CydGenericErrorPlaceholder';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { useSingleCaseRtkq } from 'hooks/CaseHooksRq';
/** @jsxImportSource @emotion/react */

export type CaseViewPageContext = {
  filter: CaseActivityFilterSetting;
  setFilter: (v: CaseActivityFilterSetting) => void;
  cydCase: Case;
};

export const CaseViewPage: React.FC = () => {
  const { caseUuid } = useParams();
  if (!caseUuid) {
    throw new Error('No caseUuid in URL params');
  }

  const { data: dataSignificances, isLoading: sigsAreLoading } =
    useDataSignificancesRedux();
  // RTKQ can tell us if the case exists or not and give us a reliable loading state
  const caseQuery = useSingleCaseRtkq(caseUuid);
  // polling updates this sagas cache - not the RTKQ one so we need to use this data
  const { data: cydCase } = useSingleCase(caseUuid);

  if (caseQuery.error) {
    return (
      <CydGenericErrorPlaceholder
        title="Page Not Found"
        message="This page could not be found. Either it doesn't exist, or you do not have permission to see it."
        gutterTop
      />
    );
  }

  if (!caseQuery.isLoading && !sigsAreLoading && cydCase && dataSignificances) {
    return (
      <CaseViewPageInner
        cydCase={cydCase}
        availableSignificances={dataSignificances}
      />
    );
  }
  return <LoadingSpinner />;
};

const CaseViewPageInner: React.FC<{
  cydCase: Case;
  availableSignificances: DataSignificance[];
}> = (props) => {
  const { cydCase: securityCase, availableSignificances } = props;
  useSingleCasePolling(securityCase.uuid); // start polling for updates

  const { data: availableSeverities } = useSeverityStates();
  const { data: orgs } = useOrgs();
  const { data: users } = useCaseAssignees(securityCase);
  const assignee = users.find((v) => v.username === securityCase.assignee);
  const { data: availableStatuses } = useCaseTransitionableStates(securityCase);
  const { data: selfUser } = useCurrentUser();
  const { data: org } = useSingleOrgByName(securityCase.org || ''); // remove the || if we tighen that type
  const { data: isMultiOrg } = useIsMultiOrgStack();
  const requireStatusConfirmReason = useSystemPropValue(
    SYSTEM_PROPERTY.STATUS_CHANGE_MODAL
  );
  const [showStatusChangeReasonDialog, setShowStatusChangeReasonDialog] =
    useState(false);
  const [tempStatus, setTempStatus] = useState(null as string | null);

  // The case activity thread filter needs to have its state up here, so that it is retained as the user navigates about the case view pages.
  // However, it should be delete if they leave the page
  const defaultFilter: CaseActivityFilterSetting = {
    postedBy: [],
    fromDate: null,
    toDate: null,
    commentSearch: '',
    significanceLevel: availableSignificances.filter((v) => v.precedence !== 1) // show everything except "Change"
  };
  const [filter, setFilter] = useState(defaultFilter);
  const updateCase = useUpdateCase(securityCase);

  // If the username exists, but no user was found, use the special 'unavailable-user' string.
  const userNameExists =
    typeof securityCase?.assignee === 'string' &&
    securityCase.assignee.length > 0;
  const assigneeToUse =
    userNameExists && !assignee ? 'unavailable-user' : assignee;

  const selectedSeverity = availableSeverities.find(
    (v) => v.precedence === securityCase.severity
  );

  const checkResourcePermssions = useCheckAccessPermissionsForObjects();
  const { canUpdate: canEdit } = checkResourcePermssions(securityCase);

  const addNotification = useAddNotification();

  const caseIsWatched = useIsWatched(securityCase.uuid);
  const watchCaseMutation = useWatchCaseMutation();
  const unwatchCaseMutation = useUnwatchCaseMutation();

  return (
    <>
      {securityCase && selfUser && (
        <>
          {/*renders the CaseViewPageActivities component */}
          <Outlet
            context={
              {
                filter,
                setFilter,
                cydCase: securityCase
              } satisfies CaseViewPageContext
            }
          />

          <CydPageSubNav
            currentNavValueFn={(navLinks, location) => {
              const baseNavLink = navLinks[0];

              const item = navLinks.find((v) => {
                return location.pathname.startsWith(v.to) && v !== baseNavLink;
              });

              if (item) {
                return item.to;
              }

              return baseNavLink.to;
            }}
            navItems={caseViewPageChildPages.map((v) => {
              return {
                to: `/caseview/${securityCase.uuid}${v.url}`,
                label: v.label
              };
            })}
          >
            <CydCaseViewHeader
              dontShowOrg={!isMultiOrg}
              organisation={org}
              canEdit={canEdit}
              locator={securityCase.locator}
              title={securityCase.description}
              onChangeTitle={(newTitle) => {
                if (newTitle.length > 0) {
                  updateCase({
                    description: newTitle
                  });
                } else {
                  addNotification({
                    message: 'Description can not be empty'
                  });
                }
              }}
              availableUsers={users}
              assignee={assigneeToUse ?? null}
              onChangeAssignee={(newAssignee) => {
                updateCase({
                  assignee: newAssignee?.username ?? 'Nobody' // I think ?
                });
              }}
              availableSeverities={availableSeverities}
              selectedSeverity={selectedSeverity ?? null}
              onChangeSeverity={(newSeverity) => {
                updateCase({
                  severity: newSeverity.precedence
                });
              }}
              availableStatuses={availableStatuses.map((v) => v.name)}
              selectedStatus={securityCase.status}
              onChangeStatus={(newStatus) => {
                if (!requireStatusConfirmReason) {
                  updateCase({
                    status: newStatus
                  });
                } else {
                  setTempStatus(newStatus);
                  setShowStatusChangeReasonDialog(true);
                }
              }}
              onAddToWatchList={() => {
                watchCaseMutation(securityCase.uuid, securityCase.acl);
              }}
              onRemoveFromWatchList={() => {
                unwatchCaseMutation(securityCase.uuid);
              }}
              isOnWatchList={caseIsWatched}
              availableOrganisations={orgs}
              onChangeOrg={(newOrg) => {
                updateCase({
                  org: newOrg.name
                });
              }}
              canManage={securityCase.manageable}
            />
          </CydPageSubNav>

          {showStatusChangeReasonDialog && (
            <JustificationModal
              onSubmit={(reason) => {
                updateCase(
                  {
                    status: tempStatus as string
                  },
                  reason
                );

                setTempStatus(null);
                setShowStatusChangeReasonDialog(false);
              }}
              onClose={() => {
                setTempStatus(null);
                setShowStatusChangeReasonDialog(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CaseViewPage;

export function useCaseViewPageContext() {
  return useOutletContext<CaseViewPageContext>();
}
