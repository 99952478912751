/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { fetchAssets } from 'states/assets/slice';
import {
  assetsSelector,
  assetsHasLoadedSelector,
  assetsStatusSelector
} from 'states/assets/selectors';
import { useAutoEffect } from './ReduxHooks';

export const useAssets = () => {
  return useAutoEffect({
    selector: assetsSelector,
    ac: fetchAssets,
    hasLoadedSelector: assetsHasLoadedSelector,
    statusSelector: assetsStatusSelector
  });
};
