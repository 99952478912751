/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { rtkqCaseActivityApi } from 'hooks/CaseActivityHooks';
import { rtkqCasesApi } from 'hooks/CaseHooksRq';
import { rtkqWatchingApi } from 'hooks/WatchingCasesHooks';
import { rtkqUsersApi } from 'hooks/UserHooks';
import { rtkqUserActionsApi } from 'hooks/UserActionsHooks';
import { rtkqConnectorsApi } from 'hooks/ConnectorHooks';
import { rtkqQuickSearchApi } from 'hooks/QuickSearchHooks';
import { rtkqCaseDataApi } from 'hooks/CaseDataHooks';

export const allRtkqApis = [
  rtkqCaseActivityApi,
  rtkqCasesApi,
  rtkqWatchingApi,
  rtkqUsersApi,
  rtkqUserActionsApi,
  rtkqConnectorsApi,
  rtkqQuickSearchApi,
  rtkqCaseDataApi
];
