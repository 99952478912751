/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Tooltip from '@mui/material/Tooltip';
import { CydTagChip } from 'components/_objectDisplay/CydTagDisplay/CydTagChip';

type CydTagDisplayProps = {
  value: string;
};

export const CydTagDisplayByTagValue = (props: CydTagDisplayProps) => {
  const { value } = props;
  if (!value) {
    return null;
  }
  return (
    <Tooltip title={value} key={`tooltip-${value}`}>
      <CydTagChip label={value} />
    </Tooltip>
  );
};
