/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  addNotification,
  INotificationPayload,
  removeNotification
} from 'states/notifications/slice';
import {
  notificationDrawerStateSelector,
  notificationListSelector,
  notificationStoreSelector
} from 'states/notifications/selectors';

export const useLastestNotification = () => {
  const notificationStore = useSelector(notificationStoreSelector);
  const dispatch = useDispatch();

  const [latestNotification] = notificationStore.notifications;

  const closeNotification = useCallback(() => {
    dispatch(removeNotification(latestNotification.id));
  }, [latestNotification, dispatch]);

  return {
    notification: latestNotification,
    closeNotification
  };
};

export const useAllNotifications = () => useSelector(notificationListSelector);

export const useNotificationDrawerState = () =>
  useSelector(notificationDrawerStateSelector);

export const useAddNotification = () => {
  const dispatch = useDispatch();

  return useCallback(
    (notification: INotificationPayload) => {
      dispatch(addNotification({ ...notification }));
    },
    [dispatch]
  );
};

export const useBasicNotification = () => {
  const addNotification = useAddNotification();
  return useCallback(
    (message: string) => {
      addNotification({ message: message });
    },
    [addNotification]
  );
};
