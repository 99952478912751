/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { useCurrentUser } from 'hooks/AuthHooks';
import { css } from '@emotion/react';
import { useCommentHeightStyleValue } from 'hooks/UserPropsHooks';
import { useEffect } from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/** @jsxImportSource @emotion/react */

export const CaseActivityArrow = ({
  caseActivity,
  setShouldShowFullView,
  shouldShowFullView,
  massToggle
}) => {
  const { content, audit } = caseActivity;

  const { data: currentUser } = useCurrentUser();
  const maxCommentHeight = useCommentHeightStyleValue(currentUser?.uuid);

  const nextState = {
    expand: 'collapse',
    collapse: content?.length > maxCommentHeight ? 'partial' : 'expand',
    partial: 'expand'
  };

  const toggleFullView = () => {
    setShouldShowFullView(nextState[shouldShowFullView]);
  };

  useEffect(() => {
    if (massToggle) {
      setShouldShowFullView('expand');
    } else {
      setShouldShowFullView('collapse');
    }
  }, [massToggle, setShouldShowFullView]);

  useEffect(() => {
    // This is a little hacky.
    // The above useEffect will go first,
    // And this this comes along after, to set it for the 'initial' value

    // The content.length thing appears not to work properly.
    // It seems to be comparing string length to vertical height
    // But this is what the initial logic was
    setShouldShowFullView(
      content?.length > maxCommentHeight ? 'partial' : 'expand'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !audit ? (
    <ArrowDownIcon
      css={css`
        transform: rotate(
          ${{
            partial: -90,
            expand: 0,
            collapse: 180
          }[shouldShowFullView]}deg
        );
        transition: transform 0.3s ease;
        margin-left: -7px;
        margin-right: 3px;
      `}
      onClick={toggleFullView}
    />
  ) : null;
};
