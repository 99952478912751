/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydDiv } from 'components/_utils/CydFragment';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { Org } from 'interface/Org.interface';
import React from 'react';
import { CydObjectNotFound } from '../CydObjectNotFound/CydObjectNotFound';

export type CydOrganisationDisplayProps = {
  organisation: Org;
  className?: string;
};

export const CydOrganisationDisplay = withErrorBoundary(
  (props: CydOrganisationDisplayProps) => {
    const { organisation, className = '' } = props;
    return <div className={`${className}`}>{organisation.name}</div>;
  },
  CydDiv,
  (props) => <CydObjectNotFound />
);
