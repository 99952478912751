/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Column } from 'components/_dataDisplay/CydSimpleTable/CydSimpleTable';
import { defaultDisplayAValueAsString } from 'utils/StringUtils';
import {
  GenericNode,
  GenericVariableInfo,
  GenericVariableResolutionInfo
} from '../type';

// From here: https://docs.oasis-open.org/cacao/security-playbooks/v1.0/cs02/security-playbooks-v1.0-cs02.html#_6e9y6r6sgwwd
export const MANUAL_OR_AUTOMATED_MAP = {
  individual: 'manual',
  group: 'manual',
  organization: 'manual',
  location: 'manual',
  sector: 'manual',
  'http-api': 'automatic',
  ssh: 'automatic',
  'security-infrastructure-categiory': 'automatic',
  'net-address': 'automatic',
  kali: 'automatic',
  attacker: 'automatic',
  'attack-agent': 'automatic',
  'attack-group': 'automatic'
} as const;

export function determineManualOrAutomatic(
  data: GenericNode | null
): 'manual' | 'automatic' | 'not-applicable' {
  if (!data) {
    return 'not-applicable';
  }

  return 'manual';

  // !  we still need this logic, when it comes to cacao
  // if (data.cacaoData.type !== 'action') {
  //   return 'not-applicable';
  // }

  // if (data.cacaoData.target_ids.length !== 1) {
  //   throw new Error('We expect target_ids to exist be exactly length 1');
  // }

  // const targetId = data.cacaoData.target_ids[0];
  // const target = data.resolvedCacaoData.targets[targetId];

  // if (!target) {
  //   throw new Error(`No target for targetId '${targetId}' found`);
  // }
  // const nodeType = MANUAL_OR_AUTOMATED_MAP[target.type];

  // if (!nodeType) {
  //   throw new Error(`Did not recognise target type '${nodeType}'`);
  // }

  // return nodeType;
}

type VariableInfoRow = {
  label: string;
  type: string;
  value: string;
};

export function getVariablesAsRowsAndColumns(
  variableInfo: Record<string, GenericVariableInfo>,
  variableResolutionInfo: Record<string, GenericVariableResolutionInfo>,

  inputKeys: Array<string>,
  showValuesColumn: boolean,
  valueDoesNotExistMessage = '(not set)'
): {
  columns: Array<Column<VariableInfoRow>>;
  rows: Array<VariableInfoRow>;
} {
  const columns = [
    {
      label: 'Variable Description',
      key: 'label'
    },
    {
      label: 'Value Type',
      key: 'type'
    },

    ...(showValuesColumn
      ? [
          {
            label: 'Value',
            key: 'value',
            customRender: (v) => {
              if (v === undefined) {
                return valueDoesNotExistMessage;
              }

              return defaultDisplayAValueAsString(v);
            }
          }
        ]
      : [])
  ] as Array<Column<VariableInfoRow>>;

  const rows = inputKeys.map((v) => {
    const info = variableInfo[v];
    const value = variableResolutionInfo[v];

    if (!info) {
      console.warn(`Warning, no info found for key ${v}`);
      return {
        label: v,
        type: '(Error)',
        value: ''
      }; // We can treat these in a special way
    }

    const row = {
      label: info.name,
      type: info.type,
      value: value?.value
    };

    return row;
  }) as Array<VariableInfoRow>;

  return {
    columns,
    rows
  };
}
