/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps
} from '@mui/material';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { useUniqueId } from 'hooks/useUniqueId';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

/** TYPES */

export type CydSignificanceLevelFilterSelectProps = {
  label: string;
  availableOptions: Array<DataSignificance>;
  selectedOptions?: Array<DataSignificance>;
  onChange?: (selection: Array<DataSignificance>) => void;
  className?: string;
};

export const CydSignificanceLevelFilterSelect = (
  props: CydSignificanceLevelFilterSelectProps
) => {
  const {
    label,
    availableOptions,
    selectedOptions,
    onChange,
    className = ''
  } = props;

  const uniqueLabelId = useUniqueId(`multi-select-${label}`);
  const dividerKey = useUniqueId(`divider`);

  /** STATE */

  // don't use setSelected - use updateSelection instead so onChange is always called
  const [selected, setSelected] = useState(selectedOptions ?? []);

  /** HANDLERS */
  const updateSelection = useCallback(
    (selection: Array<DataSignificance>) => {
      setSelected(selection);
      onChange?.(selection);
    },
    [onChange]
  );

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    // On autofill we get a stringified value.
    const eValue = typeof value === 'string' ? value.split(',') : value;
    const currentlySelectedItems = availableOptions.filter((v) =>
      eValue.includes(v.uuid)
    );
    updateSelection(currentlySelectedItems);
  };

  const handleClearSelection = useCallback(() => {
    updateSelection([]);
  }, [updateSelection]);

  const handleSelectAll = useCallback(() => {
    updateSelection(availableOptions);
  }, [availableOptions, updateSelection]);

  /** LAYOUT */

  const menuItemLayout = (uuid, name) => (
    <MenuItem dense value={uuid} key={uuid}>
      <Checkbox checked={selected.find((v) => v.uuid === uuid) !== undefined} />
      <ListItemText primary={name} />
    </MenuItem>
  );

  // create menu items
  const menuItems = [...availableOptions]
    // sort by selectable (true first) and then by precedence (higher first)
    .sort((a, b) => {
      if (a.selectable !== b.selectable) {
        return a.selectable ? -1 : 1;
      } else {
        return b.precedence - a.precedence;
      }
    })
    // insert divider between selectable and non-selectable items
    .reduce((list, curr, index, arr) => {
      const prev = index > 0 ? arr[index - 1] : null;
      const insertDivider = prev ? prev.selectable !== curr.selectable : false;
      if (insertDivider) {
        list.push(<Divider key={dividerKey}></Divider>);
      }
      list.push(menuItemLayout(curr.uuid, curr.name));
      return list;
    }, [] as Array<JSX.Element>);

  const menuProps: SelectProps['MenuProps'] = useMemo(
    () => ({
      TransitionProps: { timeout: 0 },
      MenuListProps: {
        dense: true,
        subheader: (
          <ListSubheader
            disableGutters={true}
            css={css`
              justify-content: space-between;
              display: flex;
            `}
          >
            <Button variant="text" size="small" onClick={handleSelectAll}>
              Select all
            </Button>
            <Button variant="text" size="small" onClick={handleClearSelection}>
              Clear all
            </Button>
          </ListSubheader>
        )
      }
    }),
    [handleClearSelection, handleSelectAll]
  );

  return (
    <FormControl
      css={css`
        min-width: 100px;
      `}
      className={`${className}`}
    >
      <InputLabel id={uniqueLabelId}>{label}</InputLabel>

      <Select
        labelId={uniqueLabelId}
        multiple
        label={label}
        value={selected?.map((v) => v.uuid) ?? ''}
        onChange={handleChange}
        renderValue={() => {
          return selected.map((v) => v.name).join(', ');
        }}
        MenuProps={menuProps}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};
