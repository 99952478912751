/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { css } from '@emotion/react';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { DataSignificanceCheckbox } from 'components/fields/DataSignificanceCheckbox';
import { Typography } from '@mui/material';
import { useActivitiesTimelineFormStyles } from './styles';
import { useDataSignificancesRedux } from 'hooks/CaseDataHooks';
import { useColorForSignificance } from 'components/_objectDisplay/CydSignificanceDisplay/CydSignificanceDisplay';

/** @jsxImportSource @emotion/react */

interface IActivitiesTimelineFormProps {
  onSubmit: (data) => void;
  value: DataSignificance[];
}

export function DataSignificanceLabel(props: { value: DataSignificance }) {
  const { value } = props;

  const { dataSignificanceLabelWrapper, dataSignificanceSvg } =
    useActivitiesTimelineFormStyles();

  const color = useColorForSignificance(value);

  return (
    <Typography className={dataSignificanceLabelWrapper}>
      <svg className={dataSignificanceSvg}>
        <circle cy="5" cx="5" r="5" fill={color}></circle>
      </svg>
      {value.name}
    </Typography>
  );
}

export const ActivitiesTimelineForm: React.FC<IActivitiesTimelineFormProps> = ({
  onSubmit,
  value
}) => {
  const { data: dataSignificances } = useDataSignificancesRedux();

  const handleChange = (e) => {
    onSubmit(e.target.value);
  };

  const renderDataSignificanceLabel = ({
    value
  }: {
    value: DataSignificance;
  }) => value && value.precedence && <DataSignificanceLabel value={value} />;

  return (
    <div
      css={css`
        height: 100%;
        padding: 20px 0 50px 0;
        & .MuiFormControl-root {
          height: 100%;
        }
        & .MuiFormGroup-root {
          padding-top: 5px;
          flex-wrap: nowrap;
        }
        & .MuiFormControlLabel-root {
          margin: 4px 0;
        }
      `}
    >
      <DataSignificanceCheckbox
        value={value}
        name="dataSignificances"
        onChange={handleChange}
        dataSignificances={dataSignificances}
        renderOptionLabel={renderDataSignificanceLabel}
      />
    </div>
  );
};
