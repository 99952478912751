/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { TableCellProps } from '@mui/material/TableCell';
import { memo } from 'react';
import { DefaultTableCell } from 'components/DefaultTable/DefaultTableCell';

interface IDefaultTableDataCell extends TableCellProps {
  noWrap?: boolean;
}

export const DefaultTableDataCell: React.FC<IDefaultTableDataCell> = memo(
  ({ noWrap = false, ...props }) => {
    return (
      <DefaultTableCell
        {...props}
        noWrap={noWrap}
        className={props.className}
      />
    );
  }
);
