/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

/** @jsxImportSource @emotion/react */

export const customScrollbar = (borderRadius: string | number) => ({
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    backgroundColor: 'transparent',
    borderRadius
  },
  '&::-webkit-scrollbar-thumb': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius
  },
  '&::-webkit-scrollbar': {
    width: 5
  }
});

export const customScrollbarCss = `
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  background-color: transparent;
  border-radius: 6px; 
}

&::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  background-color: rgba(255, 255, 255, 0.75); 
  border-radius: 6px; 
}

&::-webkit-scrollbar {
  width: 5px; 
}
`;
