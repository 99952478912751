/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  JsonApiArrayResponse,
  JsonApiIndividualResponse
} from 'interface/JsonApi';
import { cyFetchAuthenticated, CyFetchResponse } from 'utils/CydarmFetch';
import {
  WikiPageObjectResourceAttributes,
  WikiPageTopLevel
} from 'generatedApi';
import { Base64Encode } from 'utils/StringUtils';
import { apiBaseUploadFile } from 'services/FileUploadService';

function generateAttachmentPostBody(
  payload: WikiAttachmentPayload
): WikiPageTopLevel {
  return generateCreateWikiPostBody(payload, {
    file_name: payload.file_name,
    mime_type: payload.mime_type,
    content: payload.content
  });
}

function generateWikiPagePostBody(payload: WikiPagePayload): WikiPageTopLevel {
  return generateCreateWikiPostBody(payload, {
    content: Base64Encode(payload.content),
    title: payload.title
  });
}

function generateCreateWikiPostBody(
  payload: WikiPayload,
  wikiPageRef: WikiPageObjectResourceAttributes
): WikiPageTopLevel {
  return {
    links: null,
    jsonapi: null,
    included: [],
    errors: [],
    data: {
      type: 'wiki',
      attributes: wikiPageRef,
      relationships: {
        acl: {
          links: null,
          data: {
            type: 'acl',
            id: (payload as WikiPagePayload).acl_uuid
          },
          meta: {}
        },
        parent: payload.parent_uuid
          ? { data: { id: payload.parent_uuid } }
          : null,
        children: null
      },
      meta: null
    }
  };
}

export type CydApi_WikiPageStub = {
  title: string;
  mime_type: string;
};

export type CydApi_WikiPageStubRelationships = {
  parent?: {
    data: {
      type: 'wiki';
      id: string;
    };
    links: {
      self: string;
      related: string;
    };
  } | null;

  acl?: {
    data: {
      type: 'acl';
      id: string;
    };
  } | null;
};

export type CydApi_WikiPageObject = {
  title: string;
  content: string;
  mime_type: string;
};

export type CydApi_WikiPageObjectRelationships =
  CydApi_WikiPageStubRelationships;

export async function apiGetWikiPages(): Promise<
  CyFetchResponse<
    JsonApiArrayResponse<CydApi_WikiPageStub, CydApi_WikiPageStubRelationships>
  >
> {
  return cyFetchAuthenticated(`/v0.9/wiki-page?page[number]=0&page[size]=50`);
}

export async function apiGetWikiPage(
  wikiUuid: string
): Promise<
  CyFetchResponse<
    JsonApiIndividualResponse<
      CydApi_WikiPageObject,
      CydApi_WikiPageObjectRelationships
    >
  >
> {
  const result = await cyFetchAuthenticated(
    `/v0.9/wiki-page/${wikiUuid}?include=children,attachments`
  );
  return result;
}

export type WikiPayload = WikiAttachmentPayload | WikiPagePayload;

export type WikiPagePayload = {
  title: string;
  acl_uuid: string;
  content: string;
  parent_uuid?: string;
};

export type WikiAttachmentPayload = {
  content: string;
  parent_uuid: string;
  file_name: string;
  mime_type: string;
};

export async function apiCreateWikiPage(
  payload: WikiPagePayload
): Promise<
  CyFetchResponse<
    JsonApiIndividualResponse<
      CydApi_WikiPageObject,
      CydApi_WikiPageObjectRelationships
    >
  >
> {
  return cyFetchAuthenticated(`/v0.9/wiki-page`, {
    method: 'POST',
    body: JSON.stringify(generateWikiPagePostBody(payload)),
    headers: new Headers({
      'Content-Type': 'application/vnd.api+json'
    })
  });
}

export async function apiUploadFileToWiki(
  payload: WikiAttachmentPayload
): Promise<
  CyFetchResponse<
    JsonApiIndividualResponse<
      CydApi_WikiPageObject,
      CydApi_WikiPageObjectRelationships
    >
  >
> {
  return await apiBaseUploadFile({
    url: `/cydarm-api/v0.9/wiki-page`,
    data: generateAttachmentPostBody(payload),
    fileName: payload.file_name
  });
}

export function apiEditWikiPage(
  wikiUuid: string,
  payload: WikiPayload
): Promise<
  CyFetchResponse<
    JsonApiIndividualResponse<
      CydApi_WikiPageObject,
      CydApi_WikiPageObjectRelationships
    >
  >
> {
  return cyFetchAuthenticated(`/v0.9/wiki-page/${wikiUuid}`, {
    method: 'PUT',
    body: JSON.stringify(generateWikiPagePostBody(payload as WikiPagePayload))
  });
}

export function apiDeleteWikiPage(wikiUuid: string): Promise<
  CyFetchResponse<{
    data: null;
    links: null;
    included: null;
    meta: null;
    jsonapi: {
      version: '1.1';
    };
  }>
> {
  return cyFetchAuthenticated(`/v0.9/wiki-page/${wikiUuid}`, {
    method: 'DELETE'
  });
}
