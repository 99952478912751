/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSyncFilterAndSort } from './createSyncFilterAndSort';
import {
  CydDataTableProps,
  FilterConfig,
  PaginatedLoadMoreFunctionResult,
  RowData,
  SortConfig
} from './CydDataTable';
import { DEFAULT_ROWS_PER_PAGE_SETTING } from '../../../constants';

/**
 * We pull of the pagination logic out to a hook
 */
export function usePaginationAndFiltering<T extends RowData>(
  data: Pick<
    CydDataTableProps<T>,
    | 'paginationInfo'
    | 'rows'
    | 'columnFilter'
    | 'columns'
    | 'refreshRef'
    | 'noRowsPerPage'
  >
): {
  rowsToDisplay: Array<T>;
  pageNumberInfo: {
    pageNum: number;
    numPerPage: number;
    totalNumItems: number;
  };
  handlePageChange: (newPageNum: number) => void;
  handleSortChange: (newSortConfig: SortConfig<T>) => void;
  handleFilterTextChange: (text: string) => void;

  filterConfig: FilterConfig<T>;
  sortConfig: SortConfig<T> | null;
} {
  const {
    rows,
    paginationInfo,
    columnFilter,
    columns,
    refreshRef,
    noRowsPerPage = DEFAULT_ROWS_PER_PAGE_SETTING
  } = data;

  if (!rows && paginationInfo?.paginationType !== 'async') {
    throw new Error("Rows must be provided if pagination type is not 'async!'");
  }

  const loadMoreFunctionToUse = useMemo(() => {
    if (!paginationInfo || paginationInfo.paginationType !== 'async') {
      return createSyncFilterAndSort(rows as Array<T>, columns);
    }

    if (paginationInfo.paginationType === 'async') {
      return paginationInfo.loadingMoreFunction;
    }

    throw new Error(
      //@ts-expect-error
      `Unrecognised pagination type ${paginationInfo.paginationType}`
    );
  }, [paginationInfo, columns, rows]);

  const [currentAsyncData, setCurrentAsyncData] = useState({
    data: [],
    paginationInfo: {
      numPerPage: noRowsPerPage,
      pageNum: 0,
      totalNumItems: 0
    }
  } as PaginatedLoadMoreFunctionResult<T>);

  const [pageNumber, setPageNumber] = useState(0);

  // SORT CONFIG
  const [sortConfig, setSortConfig] = useState<SortConfig<T> | null>(null);

  //FILTER CONFIG
  const [filterConfig, setFilterConfig] = useState<FilterConfig<T>>({
    filterKey: columnFilter?.key ?? null,
    filterText: ''
  });

  const handleFilterChange = useCallback(
    (text: string) => {
      if (!columnFilter) {
        throw new Error(
          'Attempting to change filter text, when no column filter was provided'
        );
      }

      setFilterConfig({
        filterText: text,
        filterKey: columnFilter.key
      });
    },
    [setFilterConfig, columnFilter]
  );

  // When the pages, filters etc change, then refire the async pagination, if we are using it.
  useEffect(() => {
    const fn = () => {
      loadMoreFunctionToUse({
        pageNum: pageNumber,
        numPerPage: noRowsPerPage,
        sortConfig,
        filterConfig
      }).then((data) => {
        setCurrentAsyncData(data);
      });
    };

    fn();

    if (refreshRef) {
      refreshRef.current = fn;
    }
  }, [
    pageNumber,
    noRowsPerPage,
    sortConfig,
    filterConfig,
    setCurrentAsyncData,
    loadMoreFunctionToUse,
    refreshRef
  ]);

  return {
    handlePageChange: setPageNumber,
    handleSortChange: setSortConfig,
    handleFilterTextChange: handleFilterChange,
    rowsToDisplay: currentAsyncData.data,
    filterConfig,
    sortConfig,
    pageNumberInfo: currentAsyncData.paginationInfo
  };
}
