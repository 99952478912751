export function mergeObjectsInUnique<T>(
  array: T[],
  property: any,
  replace: boolean = false
): T[] {
  const newArray = new Map();

  array.forEach((item: T) => {
    const propertyValue = item[property];
    newArray.has(propertyValue)
      ? newArray.set(
          propertyValue,
          replace ? item : { ...newArray.get(propertyValue), ...item }
        )
      : newArray.set(propertyValue, item);
  });

  return Array.from(newArray.values());
}

export const removeEmptyItem = (array, key) => {
  return array.map((item) => {
    let newItem = item;
    if (newItem[key] === null) {
      delete newItem[key];
    }
    return newItem;
  });
};
