/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { CaseActivity } from 'components/CaseActivity';
import { useScrollToBottom } from 'hooks/CaseActivitiesUIHooks';

const CaseActivitiesWrapper = (props) => {
  const { anchorRef, containerRef } = useScrollToBottom(
    props.activities.length
  );

  return (
    <div ref={containerRef} className={props.styles}>
      {props.activities.map((singleDetailedCaseData) => (
        <CaseActivity
          key={singleDetailedCaseData.uuid}
          caseActivity={singleDetailedCaseData}
          containerRef={containerRef}
          massToggle={props.massToggle}
          caseAcl={props.caseAcl}
        />
      ))}
      <div ref={anchorRef} />
    </div>
  );
};

export default CaseActivitiesWrapper;
