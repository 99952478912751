/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  FETCH_CASE_TAGS_ERROR: {
    code: 'FETCH_CASE_TAGS_ERROR',
    message: 'Error occurred while fetching case tags'
  },
  CREATE_CASE_TAG_ERROR: {
    code: 'CREATE_CASE_TAG_ERROR',
    message: 'Error occurred while creating case tag'
  },
  DELETE_CASE_TAG_ERROR: {
    code: 'DELETE_CASE_TAG_ERROR',
    message: 'Error occurred while deleting case tag'
  },
  UPDATE_CASE_TAG_ERROR: {
    code: 'DELETE_CASE_TAG_ERROR',
    message: 'Error occurred while update case tag'
  }
};

export { ERROR_MESSAGE };
