/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';

import { CydCaseViewCaseGroupSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewCaseGroupSection/CydCaseViewCaseGroupSection';

import {
  useAddCaseToGroup,
  useCasesByParentUuid,
  useRemoveCaseFromGroup
} from 'hooks/CaseGroupHooks';

interface CydCaseViewMemberProps {
  caseUuid: string;
  isEditable: boolean;
}

export const CydCaseViewMemberSection = ({
  caseUuid,
  isEditable = false
}: CydCaseViewMemberProps) => {
  const memberResult = useCasesByParentUuid(caseUuid);
  const addMemberMutation = useAddCaseToGroup(caseUuid);
  const removeMemberMutation = useRemoveCaseFromGroup(caseUuid);

  return (
    <>
      <CydCaseViewCaseGroupSection
        caseUuid={caseUuid}
        data-testid="cyd-member-cases-section"
        cases={memberResult.data || []}
        emptyListMessage="There are currently no member cases"
        title="Member Cases"
        isEditable={isEditable}
        onAddCase={addMemberMutation.mutate}
        onRemoveCase={removeMemberMutation.mutate}
      />
    </>
  );
};
