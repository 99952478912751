/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import Typography from '@mui/material/Typography';
import { User } from 'interface/User.interface';

export type CydWatchersDisplayProps = {
  watchers: Array<User>;
};

export const CydWatchersDisplay = (props: CydWatchersDisplayProps) => {
  const { watchers } = props;
  if (!watchers) {
    return null;
  }
  if (watchers?.length === 0) {
    return (
      <Typography color="text.secondary">
        There are no watchers for this case
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="text.primary">
      {watchers
        .map((watcher) => `${watcher.givenName} ${watcher.familyName}`)
        .join(', ')}
    </Typography>
  );
};
