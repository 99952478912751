/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest } from 'redux-saga/effects';
import {
  createAddPlaybookActionTag,
  createPlaybookAction,
  createDeletePlaybookAction,
  createFetchPlaybookActionByUuid,
  createFetchPlaybookActions,
  createRemovePlaybookActionTag,
  createUpdateAtcPlaybookAction
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { PlaybookAction } from 'interface/Playbook.interface';
import { addNotification } from 'states/notifications/slice';
import {
  addSinglePlaybookActionToStore,
  deletePlaybookActionFailed,
  fetchPlaybookActionsFailed,
  fetchPlaybookActionsSuccess
} from './slice';
import {
  apiCreatePlaybookAction,
  apiCreatePlaybookActionTag,
  apiDeletePlaybookAction,
  apiDeletePlaybookActionTag,
  apiFetchPlaybookActionByUuid,
  apiFetchPlaybookActions,
  apiUpdateAtcPlaybookAction
} from 'services/PlaybookServices';

export function* performFetchPlaybookActions() {
  try {
    const { json } = yield apiFetchPlaybookActions();
    yield put(fetchPlaybookActionsSuccess(json));
  } catch (ex) {
    yield put(
      fetchPlaybookActionsFailed(ERROR_MESSAGE.FETCH_PLAYBOOK_ACTIONS_ERROR)
    );
  }
}

export function* performFetchPlaybookActionByUuid({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    const { json }: { json: PlaybookAction } =
      yield apiFetchPlaybookActionByUuid(uuid);
    yield put(addSinglePlaybookActionToStore(json));
  } catch (ex) {
    // TODO: addNotification
    addNotification({
      message: `Successfully fetched Playbook Action`
    });
  }
}

export function* performCreatePlaybookAction(action) {
  const {
    payload: { playbookAction, isShowNotification = true, resolve }
  }: {
    payload: {
      playbookAction: PlaybookAction;
      isShowNotification: boolean;
      resolve: (data: { uuid: string }) => void;
    };
  } = action;

  try {
    const { json } = yield apiCreatePlaybookAction(playbookAction);
    yield put(
      addSinglePlaybookActionToStore(Object.assign(json, playbookAction))
    );
    yield put(createFetchPlaybookActions());
    resolve && resolve(json);
    if (isShowNotification) {
      yield put(
        addNotification({
          message: `Playbook action added successfully`
        })
      );
    }
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CREATE_PLAYBOOK_ACTION_ERROR.message
      })
    );
  }
}

export function* performUpdateAtcPlaybookAction(action) {
  const {
    payload: { playbookAction, uuid, resolveAction }
  }: {
    payload: {
      uuid: string;
      playbookAction: PlaybookAction;
      resolveAction: any;
    };
  } = action;
  try {
    yield apiUpdateAtcPlaybookAction(uuid, {
      uuid: uuid,
      name: playbookAction.name,
      description: playbookAction.description,
      acl: playbookAction.acl
    });
    yield put(createFetchPlaybookActions());
    yield put(
      addNotification({
        message: `Playbook action ${playbookAction.name} has updated successfully`
      })
    );
    if (resolveAction) {
      yield put(resolveAction);
    }
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.UPDATE_PLAYBOOK_ACTION_ERROR.message
      })
    );
  }
}

export function* performDeletePlaybookAction({
  payload: uuid
}: {
  payload: string;
}) {
  try {
    yield apiDeletePlaybookAction(uuid);
    yield put(createFetchPlaybookActions());
    yield put(
      addNotification({
        message: `Playbook action has removed successfully`
      })
    );
  } catch (ex) {
    yield put(
      deletePlaybookActionFailed(ERROR_MESSAGE.DELETE_PLAYBOOK_ACTION_ERROR)
    );
    yield put(
      addNotification({
        message: ERROR_MESSAGE.DELETE_PLAYBOOK_ACTION_ERROR.message
      })
    );
  }
}

export function* performCreatePlaybookActionTag(action) {
  const {
    payload: { actionUuid, tagUuid },
    resolve
  }: {
    payload: { actionUuid: string; tagUuid: string };
    resolve: () => void;
  } = action;
  try {
    yield apiCreatePlaybookActionTag(actionUuid, tagUuid);
    resolve && resolve();
    yield put(createFetchPlaybookActions());
    yield put(
      addNotification({
        message: `Playbook action tag was added successfully`
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CREATE_PLAYBOOK_ACTION_TAG_ERROR.message
      })
    );
  }
}

export function* performDeletePlaybookActionTag(action) {
  const {
    payload: { actionUuid, tagUuid }
  }: { payload: { actionUuid: string; tagUuid: string } } = action;
  try {
    yield apiDeletePlaybookActionTag(actionUuid, tagUuid);
    yield put(createFetchPlaybookActions());
    yield put(
      addNotification({
        message: `Playbook action tag removed successfully`
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.DELETE_PLAYBOOK_ACTION_TAG_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchPlaybookActions() {
  yield takeLatest(createFetchPlaybookActions, performFetchPlaybookActions);
}

function* watchFetchPlaybookActionByUuid() {
  yield takeLatest(
    createFetchPlaybookActionByUuid,
    performFetchPlaybookActionByUuid
  );
}

function* watchCreatePlaybookAction() {
  yield takeLatest(createPlaybookAction, performCreatePlaybookAction);
}

function* watchUpdatePlaybookAction() {
  yield takeLatest(
    createUpdateAtcPlaybookAction,
    performUpdateAtcPlaybookAction
  );
}

function* watchDeletePlaybookAction() {
  yield takeLatest(createDeletePlaybookAction, performDeletePlaybookAction);
}

function* watchCreatePlaybookActionTag() {
  yield takeLatest(createAddPlaybookActionTag, performCreatePlaybookActionTag);
}

function* watchDeletePlaybookActionTag() {
  yield takeLatest(
    createRemovePlaybookActionTag,
    performDeletePlaybookActionTag
  );
}

export default [
  watchFetchPlaybookActions(),
  watchFetchPlaybookActionByUuid(),
  watchCreatePlaybookAction(),
  watchUpdatePlaybookAction(),
  watchDeletePlaybookAction(),
  watchDeletePlaybookActionTag(),
  watchCreatePlaybookActionTag()
];
