/**
 * We have a good reason for ignoring these warnings.
 * When adding items to the list, please add a detailed comment explaining why it should be ignored
 */
const suppressedWarningsMap = new Map();

/**
 * Mui gives a warning about deprecated usage on the DateRangePicker
 * However, the alternative does not work
 * https://github.com/mui/material-ui/issues/39048
 */
suppressedWarningsMap.set(
  /- startText/,
  'Mui deprecation notice on DateRangePicker'
);

const suppressedWarningsAsList = [...suppressedWarningsMap.keys()];

export function setupConsoleOverrides() {
  const originalWarn = console.warn;

  const newWarn: typeof console.warn = (arg1, ...rest) => {
    if (typeof arg1 === 'string') {
      const suppressionKey = suppressedWarningsAsList.find((v) => {
        return arg1.match(v);
      });
      if (suppressionKey) {
        const reason = suppressedWarningsMap.get(suppressionKey);
        originalWarn(
          `(Suppressed warning - see consoleOverrides.ts - Reason: "${reason}")`
        );
      }
    } else {
      originalWarn(arg1, ...rest);
    }
  };
  console.warn = newWarn;
}
