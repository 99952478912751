/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
const NEWLINE = '\n\n';
const BOLDSTART = ' **';
const BOLDEND = '** ';
const TITLE = '### ';
export const caseFormToMarkdown = (formData) => {
  try {
    const { humanData } = JSON.parse(formData);
    return humanData
      .map(([key, value]) =>
        key === '__formName__'
          ? `${TITLE}Form Submission: ${value ? value : ''}`
          : `${BOLDSTART}${key}:${BOLDEND} ${value}`
      )
      .join(NEWLINE);
  } catch (ex) {
    return 'Invalid Form Data';
  }
};
