/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { JsonApiArrayResponse } from 'interface/JsonApi';
import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import {
  CydApi_WikiPageStub,
  CydApi_WikiPageStubRelationships,
  WikiPayload
} from 'services/WikiService';
import { WikiPageCreateAndEdit } from '../WikiPageCreateAndEdit/WikiPageCreateAndEdit';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem
} from '@mui/material';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { useBoolean } from 'hooks/useBoolean';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import { useState } from 'react';
import { Acl } from 'interface/Acl.interface';

export type WikiPageLeftNavProps = {
  wikiStubs: JsonApiArrayResponse<
    CydApi_WikiPageStub,
    CydApi_WikiPageStubRelationships
  >;

  onAddPage: (data: WikiPayload) => void;

  availableAcls: Array<Acl>;
  defaultAcl: Acl | null;
};

export type NavigationTreeNode = {
  id: string;
  title: string;
  children: Array<NavigationTreeNode>;
};

export function createNestingTree(
  wikiStubs: JsonApiArrayResponse<
    CydApi_WikiPageStub,
    CydApi_WikiPageStubRelationships
  >
): Array<NavigationTreeNode> {
  const originalItemsMap = {} as Record<
    string,
    (typeof wikiStubs)['data'][number]
  >;
  const childrenRelationships = {} as Record<string, Array<string>>;

  const wikiStubsData = wikiStubs.data;
  wikiStubsData.forEach((v) => {
    childrenRelationships[v.id] = [];
  });
  const wikiPagesStub = wikiStubsData.filter(
    (v) => v.attributes.mime_type === 'text/markdown'
  );
  wikiPagesStub.forEach((v) => {
    originalItemsMap[v.id] = v;

    const parentId = v.relationships?.parent?.data.id;
    if (parentId) {
      childrenRelationships[parentId].push(v.id);
    }
  });

  function buildChildren(children: Array<string>): Array<NavigationTreeNode> {
    return children.map((v) => {
      const originalItem = originalItemsMap[v];
      if (!originalItem) {
        throw new Error(`Couldn't find child: '${v}' in originalItemsMap`);
      }

      return {
        id: originalItem.id,
        title: originalItem.attributes.title,
        children: buildChildren(childrenRelationships[v])
      };
    });
  }

  return wikiStubsData
    .filter((v) => !v.relationships?.parent)
    .map((v) => {
      return {
        id: v.id,
        title: v.attributes.title,
        children: buildChildren(childrenRelationships[v.id])
      };
    });
}

export const OurLink = React.forwardRef<
  any,
  Omit<LinkProps, 'to'> & { href: string }
>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <Link to={props.href} {...rest} ref={ref}>
      {children}
    </Link>
  );
});

const WikiLinkItem = (props: {
  linkItem: NavigationTreeNode;
  depth: number;
}) => {
  const { linkItem, depth } = props;
  const [isOpen, setIsOpen, toggle] = useBoolean(true);
  const location = useLocation();

  const thisPathName = '/wiki/' + linkItem.id;
  return (
    <>
      <MenuItem
        css={(theme) => `
          padding-left: ${theme.spacing(2 * (depth + 1))}; 
        `}
        selected={location.pathname === thisPathName}
        component={OurLink}
        href={thisPathName}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <ListItemIcon>
          {linkItem.children.length > 0 ? (
            <>
              <IconButton
                css={css`
                  padding: 0;
                `}
                aria-label={isOpen ? 'Collapse Children' : 'Expand Children'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggle();
                }}
              >
                <CydIcon
                  icon="rightArrow"
                  css={css`
                    transform: rotate(${isOpen ? '90deg' : 0});
                  `}
                />
              </IconButton>
            </>
          ) : (
            <>
              <CydIcon
                icon="circle"
                css={(theme) => `
                  width: ${theme.spacing(1.5)}; 
                  height: ${theme.spacing(1.5)};
                  margin-left: ${theme.spacing(2 * (depth + 1))};
                `}
              />
            </>
          )}
        </ListItemIcon>
        <ListItemText
          css={css`
            > span {
              overflow: hidden;
              white-space: nowrap;
              display: block;
              text-overflow: ellipsis;
            }
          `}
        >
          {linkItem.title}
        </ListItemText>
      </MenuItem>

      <div>
        <Collapse in={isOpen}>
          {linkItem.children.map((v) => {
            return <WikiLinkItem linkItem={v} key={v.id} depth={depth + 1} />;
          })}
        </Collapse>
      </div>
    </>
  );
};

export const WikiPageLeftNav = (props: WikiPageLeftNavProps) => {
  const { wikiStubs, onAddPage, availableAcls, defaultAcl } = props;
  const tree = createNestingTree(wikiStubs);

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {tree.map((v) => {
        return <WikiLinkItem linkItem={v} key={v.id} depth={0} />;
      })}

      {modalOpen && (
        <WikiPageCreateAndEdit
          availableAcls={availableAcls}
          defaultAcl={defaultAcl}
          onCloseClick={() => setModalOpen(false)}
          onEditWiki={onAddPage}
        />
      )}

      <CydButtonStack direction={'column'}>
        <CydButton
          variant="outlined"
          startIcon={'add'}
          onClick={() => setModalOpen(true)}
        >
          Add Page
        </CydButton>
      </CydButtonStack>
    </>
  );
};
