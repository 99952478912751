/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import React from 'react';
import clsx from 'clsx';
import { default as CollapseContent } from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCollapseStyles } from 'components/Collapse/styles';

interface ICollapseProps {
  className?: string;
  title?: React.ReactNode;
  isCollapsible?: boolean;
  children: React.ReactNode;
  defaultIsOpen?: boolean;
}

export const Collapse: React.FC<ICollapseProps> = ({
  className,
  isCollapsible = true,
  title,
  children,
  defaultIsOpen = false
}) => {
  const { container, title: titleClass } = useCollapseStyles();
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultIsOpen);
  return (
    <div data-testid="collapse" className={clsx(container, className)}>
      {title && (
        <div
          data-testid="collapse-title"
          className={titleClass}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isCollapsible &&
            (isOpen ? (
              <ArrowUpIcon data-testid="expand-icon" />
            ) : (
              <ArrowDownIcon data-testid="collapse-icon" />
            ))}{' '}
          <Typography>{title}</Typography>
        </div>
      )}
      {isCollapsible ? (
        <CollapseContent data-testid="collapse-content" in={isOpen}>
          <div>{children}</div>{' '}
        </CollapseContent>
      ) : (
        <div data-testid="collapse-content">{children}</div>
      )}
    </div>
  );
};
