/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useDefaultPopoverStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(3),
    backgroundColor: palette.background.paper,
    width: 'fit-content',
    overflow: 'visible',
    marginTop: spacing(2)
  },
  actionsContainer: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
