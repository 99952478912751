import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import { Acl } from 'interface/Acl.interface';

export const aclsSelector = ({ acl: { acls } }: IStore) => acls;

export const sortedAclsSelector = createSelector([aclsSelector], (acls) =>
  [...acls].sort((a, b) => a.description.localeCompare(b.description))
);

export const sortedDataAclsSelector = createSelector(
  [sortedAclsSelector],
  (acls) => acls.filter((acl: any) => acl.forData)
);

export const sortedNonDeprecatedDataAclsSelector = createSelector(
  sortedDataAclsSelector,
  (acls) => acls.filter((acl) => !acl.deprecated)
);

export const sortedNonDeprecatedAclsSelector = createSelector(
  [sortedAclsSelector],
  (acls) => acls.filter((acl) => !acl.deprecated)
);

export const dataDefaultAclSelector = createSelector(
  sortedDataAclsSelector,
  (acls) =>
    acls.filter((acl) => {
      return acl.description.search(/case data defaults$/) !== -1;
    })
);

export const aclByUuidSelector = createSelector(
  aclsSelector,
  (_, uuid) => uuid,
  (acls: Acl[], aclUuid) => {
    const foundAcl = acls.find(({ uuid }) => uuid === aclUuid);
    return foundAcl;
  }
);

export const aclsStatusSelector = (store: IStore) => {
  const {
    acl: { isLoading, hasLoaded }
  } = store;
  return isLoading || hasLoaded;
};

export const aclIsLoadingSelector = (store: IStore) => {
  return store.acl.isLoading;
};
