/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { EndNode } from './EndNode';
import { IfConditionNode } from './IfConditionNode';
import { ParallelNode } from './ParallelNode';
import { PlaybookNode } from './PlaybookNode';
import { SingleNode } from './SingleNode';
import { StartNode } from './StartNode';
import { SwitchConditionNode } from './SwitchConditionNode';
import { WhileConditionNode } from './WhileConditionNode';

export const customNodes = {
  end: EndNode,
  'if-condition': IfConditionNode,
  parallel: ParallelNode,
  'playbook-action': PlaybookNode,
  action: SingleNode,
  start: StartNode,
  'switch-condition': SwitchConditionNode,
  'while-condition': WhileConditionNode
};
