/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface IAlert {
  header: string;
  body: string;
  id: string;
  timestamp: number;
}

export interface IAlertStoreState {
  alerts: IAlert[];
}

const initialState: IAlertStoreState = {
  alerts: []
};

const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: {
      reducer: (state, action: PayloadAction<IAlert>) => {
        state.alerts.push(action.payload);
      },
      prepare: (payload) => ({
        payload: {
          ...payload,
          id: uuidv4(),
          timestamp: Date.now()
        }
      })
    }
  }
});

export const {
  reducer: alertStore,
  actions: { addAlert }
} = alertSlice;
