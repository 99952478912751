import * as CydarmFetch from 'utils/CydarmFetch';
import { CydApi_BaseCase } from './reports/WatchingCasesService';

// IocQuickSearch
export async function apiPerformQuickSearch(searchText: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/case/quick-search', {
    method: 'POST',
    body: JSON.stringify(searchText)
  });
}

export type CydApi_QuickSearchResponseItem = {
  acl: string;
  uuid: string;
  rank: number;
  case: CydApi_BaseCase;
};

// quickSearch
export async function apiQuickSearch(searchText: {
  searchString: string;
}): Promise<
  CydarmFetch.CyFetchResponse<Array<CydApi_QuickSearchResponseItem>>
> {
  return CydarmFetch.cyFetchAuthenticated('/case/quick-search', {
    method: 'POST',
    body: JSON.stringify(searchText)
  });
}

// Metrics
export async function apiFetchMttx(metrics: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/metrics/time-to-state`, {
    method: 'POST',
    body: JSON.stringify(metrics)
  });
}
