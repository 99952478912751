/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonApiErrorSource
 */
export interface JsonApiErrorSource {
    /**
     * A JSON Pointer [RFC6901] to the value in the request document that caused the error
     * @type {string}
     * @memberof JsonApiErrorSource
     */
    pointer?: string;
    /**
     * A string indicating which URI query parameter caused the error
     * @type {string}
     * @memberof JsonApiErrorSource
     */
    parameter?: string;
    /**
     * A string indicating the name of a single request header which caused the erro
     * @type {string}
     * @memberof JsonApiErrorSource
     */
    header?: string;
}

/**
 * Check if a given object implements the JsonApiErrorSource interface.
 */
export function instanceOfJsonApiErrorSource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonApiErrorSourceFromJSON(json: any): JsonApiErrorSource {
    return JsonApiErrorSourceFromJSONTyped(json, false);
}

export function JsonApiErrorSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiErrorSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pointer': !exists(json, 'pointer') ? undefined : json['pointer'],
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'header': !exists(json, 'header') ? undefined : json['header'],
    };
}

export function JsonApiErrorSourceToJSON(value?: JsonApiErrorSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pointer': value.pointer,
        'parameter': value.parameter,
        'header': value.header,
    };
}

