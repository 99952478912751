/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useAclDialogRowStyles } from './styles';

interface IAclDialogRowProps {
  title: string;
  value: any;
}

export const AclDialogRow: React.FC<IAclDialogRowProps> = ({
  title,
  value
}) => {
  const { rowContainer } = useAclDialogRowStyles();

  return (
    <Grid container className={rowContainer}>
      <Grid item xs={3}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={9}>
        {value}
      </Grid>
    </Grid>
  );
};
