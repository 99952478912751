/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydSearchableSingleSelect } from 'components/_formElements/CydSearchableSingleSelect/CydSearchableSingleSelect';
import { CacaoBadge } from 'components/_playbooks/CacaoBadge';
import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';
import { AtcPlaybook } from 'interface/Playbook.interface';
import React, { useMemo, useState } from 'react';

export type CydAddPlaybookToCaseModalProps = {
  availablePlaybooks: (AtcPlaybook | OasisCacaoPlaybook)[]; // nb. we will later have playbooks of other types
  onAddPlaybook: (newPlaybook: AtcPlaybook) => void;
  isOpen: boolean;
  onClose: () => void;
};

export const CydAddPlaybookToCaseModal = (
  props: CydAddPlaybookToCaseModalProps
) => {
  const { onAddPlaybook, availablePlaybooks, isOpen, onClose } = props;

  const [selectedPlaybook, setSelectedPlaybook] = useState(
    null as null | AtcPlaybook | OasisCacaoPlaybook
  );

  const sortedPlaybook = useMemo(() => {
    const sortedPlaybooks = [...availablePlaybooks];
    return sortedPlaybooks.sort((a, b) => a.name.localeCompare(b.name));
  }, [availablePlaybooks]);

  return (
    <CydSimpleFormModal
      isOpen={isOpen}
      onFormSubmit={(e) => {
        e.preventDefault();

        onAddPlaybook(selectedPlaybook as AtcPlaybook);
      }}
      onClose={onClose}
    >
      <>
        <CydSearchableSingleSelect
          autoFocus
          disablePortal={false}
          availableOptions={sortedPlaybook}
          selectedOption={selectedPlaybook}
          onChange={(v) => setSelectedPlaybook(v)}
          label="Select Playbook"
          generateOptionLabel={(v) => v.name}
          renderOption={(props, v) => {
            return (
              <li {...props}>
                <span>{v.name}</span>
                {'workflow' in v && <CacaoBadge />}
              </li>
            );
          }}
        />
        <CydButtonStack justifyContent={'flex-end'}>
          <CydButton variant="text" onClick={onClose}>
            Cancel
          </CydButton>
          <CydButton variant="contained" type="submit">
            Add Playbook
          </CydButton>
        </CydButtonStack>
      </>
    </CydSimpleFormModal>
  );
};
