/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IMeta } from 'interface/Meta.interface';
import { createAction } from '@reduxjs/toolkit';

export const createFetchMetas = createAction('caseMetas/fetch');
export const createUpdateMetas = createAction<IMeta[]>('caseMetas/updateMany');
export const createUpdateMeta = createAction<IMeta>('caseMetas/update');
export const createMeta = createAction<IMeta>('caseMetas/create');
export const createRemoveMeta = createAction<IMeta>('caseMetas/remove');
