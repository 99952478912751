import { IStore } from 'states/store.interface';

export const severityListSelector = ({ severity: { list } }: IStore) => list;

export const maxCaseSeverityLevelSelector = ({
  severity: { maxCaseSeverityLevel }
}: IStore) => maxCaseSeverityLevel;

export const caseSeverityStatusSelector = ({
  severity: { isLoading, hasLoaded }
}: IStore) => {
  return isLoading || hasLoaded;
};
