/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import Table, { TableProps } from '@mui/material/Table';
import { TablePaginationProps } from '@mui/material/TablePagination';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import * as React from 'react';
import { DefaultTableHeaderCell } from './DefaultTableHeaderCell';
import clsx from 'clsx';
import { useDefaultTableStyles } from './style';

interface IDefaultTableProps extends Omit<TableProps, 'classes'> {
  headers?: Array<{
    columnKey: string;
    label: React.ReactNode;
    width?: string | number;
  }>;
  tableHeadElement?: React.ReactNode;
  tableFootElement?: React.ReactNode;
  paginationProps?: TablePaginationProps;
  classes?: {
    headerCell?: string;
    table?: string;
  };
}

export const DefaultTable: React.FC<IDefaultTableProps> = ({
  children,
  headers,
  paginationProps,
  classes = {},
  tableHeadElement,
  className,
  ...rest
}) => {
  const {
    tablePagination,
    tablePaginationSelectRoot,
    tablePaginationSelect,
    tablePaginationSelectIcon,
    tablePaginationFooter,
    tablePaginationActions
  } = useDefaultTableStyles();

  return (
    <Table className={clsx(classes.table, className)} {...rest}>
      {headers && (
        <TableHead>
          <TableRow>
            {headers.map(({ label, width, columnKey }) => (
              <DefaultTableHeaderCell
                style={width ? { width } : {}}
                className={classes.headerCell}
                key={columnKey}
              >
                {label}
              </DefaultTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      {tableHeadElement}
      <TableBody>{children}</TableBody>
      {paginationProps && (
        <TableFooter classes={{ root: tablePaginationFooter }}>
          <TableRow>
            <TablePagination
              classes={{
                root: tablePagination,
                selectRoot: tablePaginationSelectRoot,
                select: tablePaginationSelect,
                selectIcon: tablePaginationSelectIcon,
                actions: tablePaginationActions
              }}
              rowsPerPageOptions={[10, 25, 50]}
              SelectProps={{
                inputProps: { 'aria-label': 'Rows per page' }
              }}
              ActionsComponent={TablePaginationActions}
              colSpan={headers && headers.length}
              {...paginationProps}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};
