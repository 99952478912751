/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { CydApi_CasePlaybookInstance } from 'interface/Playbook.interface';
import { CasePlaybookListItem } from './CasePlaybookListItem';
import { useCasePlaybookListStyles } from './styles';
import { Case } from 'interface/Case.interface';
import { CaseActionsItem } from './CasePlaybookActionsItem';
import { useCaseActions } from 'hooks/deprecated_CaseHooks';

interface ICasePlaybookListProps {
  casePlaybooks: CydApi_CasePlaybookInstance[];
  case: Case;
}

export const CasePlaybookList: React.FC<ICasePlaybookListProps> = ({
  casePlaybooks = [],
  case: securityCase
}) => {
  const { listContainer } = useCasePlaybookListStyles();
  const { data: caseActions } = useCaseActions(securityCase.uuid);
  return (
    <div className={listContainer}>
      {casePlaybooks.map((casePlaybook, index) => (
        <CasePlaybookListItem
          key={`${casePlaybook.casePlaybookUuid}_${index}`}
          case={securityCase}
          casePlaybook={casePlaybook}
          casePlaybookUuid={casePlaybook.casePlaybookUuid}
        />
      ))}
      {caseActions && caseActions.length > 0 && (
        <CaseActionsItem caseActions={caseActions} case={securityCase} />
      )}
    </div>
  );
};
