/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import { CydBaseModal, CydBaseModalProps } from '../CydBaseModal/CydBaseModal';

/** @jsxImportSource @emotion/react */

export type CydSimpleFormModalProps = CydBaseModalProps & {
  onFormSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const CydSimpleFormModal = (props: CydSimpleFormModalProps) => {
  const { onFormSubmit, children, ...rest } = props;

  return (
    <CydBaseModal {...rest}>
      {onFormSubmit ? (
        <form onSubmit={onFormSubmit}>{children}</form>
      ) : (
        children
      )}
    </CydBaseModal>
  );
};
