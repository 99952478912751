/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createDeletePlaybook, createFetchPlaybooks } from './actions';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { AtcPlaybook, PlaybookType } from 'interface/Playbook.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';

export interface IPlaybooksStoreState {
  isLoading?: boolean;
  error?: ErrorPayload | null;
  list: PlaybookType[];
  atcList: AtcPlaybook[];
  cacaoList: OasisCacaoPlaybook[];
  currentSinglePagePlaybook?: PlaybookType;
  deletePlaybook?: PlaybookType;
  deletePlaybookError?: ErrorPayload;
  hasLoaded: boolean;
}

const initialState: IPlaybooksStoreState = {
  list: [],
  atcList: [],
  cacaoList: [],
  currentSinglePagePlaybook: undefined,
  isLoading: false, // TODO: DISCUSS BEFORE MERGE what if we default these to true
  error: null,
  hasLoaded: false
};

const playbooksSlice = createSlice({
  name: 'playbooks',
  initialState,
  reducers: {
    fetchPlaybooksSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = undefined;
      state.list = payload;
      state.atcList =
        payload && payload.map((el) => el.atc).filter((el) => Boolean(el));
      state.cacaoList =
        payload && payload.map((el) => el.cacao).filter((el) => Boolean(el));

      state.hasLoaded = true;
    },

    fetchPlaybooksFailed: (state, { payload }: PayloadAction<ErrorPayload>) => {
      state.error = payload;
      state.isLoading = false;
      state.hasLoaded = true;
    },

    fetchPlaybookByUuidSuccess: (
      state,
      { payload }: PayloadAction<PlaybookType>
    ) => {
      payload.atc?.actions?.forEach((el, index) => (el.position = index + 1));

      state.currentSinglePagePlaybook = payload;
      state.isLoading = false;
    },

    deletePlaybookSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.deletePlaybook = state.list.find(
        (el) => el.atc?.uuid === payload || el.cacao?.uuid === payload
      );
      state.list = state.list.filter((el) => {
        if (el.atc) {
          return el.atc?.uuid !== payload;
        }
        if (el.cacao) {
          return el.cacao?.uuid !== payload;
        }
        return true;
      });

      state.atcList = state.atcList?.filter((el) => el.uuid !== payload);
      state.cacaoList = state.cacaoList?.filter((el) => el.uuid !== payload);
      state.deletePlaybookError = undefined;
    },

    deletePlaybookFailed: (state, { payload }: PayloadAction<ErrorPayload>) => {
      state.isLoading = false;
      state.deletePlaybookError = payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createFetchPlaybooks, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })

      .addCase(createDeletePlaybook, (state) => {
        state.isLoading = false;
        state.deletePlaybookError = undefined;
      })
});

export const {
  reducer: playbooks,
  actions: {
    fetchPlaybooksSuccess,
    fetchPlaybooksFailed,
    fetchPlaybookByUuidSuccess,
    deletePlaybookSuccess,
    deletePlaybookFailed
  }
} = playbooksSlice;
