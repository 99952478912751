/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { combineReducers } from 'redux';
import { acl } from './acl/slice';
import { aclGroups } from './aclGroup/slice';
import { caseCache } from './caseCache/slice';
import { caseData } from './caseData/slice';
import { severity } from './caseSeverity/slice';
import { caseTags } from './caseTags/slice';
import { users } from './users/slice';
import { auth } from './auth/slice';
import { groups } from './groups/slice';
import { data } from './data/slice';
import { quickSearchStore } from './quickSearch/slice';
import { IoCQuickSearch } from './IoCQuickSearch/slice';
import { playbooks } from './playbooks/slice';
import { playbookActions } from './playbookActions/slice';
import { userActionsStore } from './userActions/slice';
import { casePlaybooks } from './casePlaybooks/slice';
import { orgs } from './orgs/slice';
import { reports } from './reports/slice';
import { caseStates } from './caseStates/slice';
import { cases } from './cases/slice';
import { sysprops } from './sysprops/slice';
import { samlLogin } from './saml/slice';
import { caseMetasStore } from './caseMetas/slice';
import { notificationStore } from './notifications/slice';
import { watcherStore } from './watchers/slice';
import { caseListUIStore } from './caseListUI/slice';
import { groupMembers } from './groupMembers/slice';
import { watchingCasesStore } from './watchingCases/slice';
import { caseGroupsStore } from './caseGroups/slice';
import { mttxStore } from './metrics/slice';
import { caseStixStore } from './caseStix/slice';
import { managerDashboardStore } from './managerDashboard/slice';
import { userPropsStore } from './userProps/slice';
import { caseFormsStore } from './caseForms/slice';
import { caseThreadDrawer } from './caseThreadDrawer/slice';
import { contacts } from './contacts/slice';
import { alertStore } from './alerts/slice';
import { activityTimelineStore } from './activityTimeline/slice';
import { caseActionsStore } from './caseActions/slice';
import { assetsStore } from './assets/slice';
import { resources } from './resource/slice';

import { allRtkqApis } from './rtkqApis';
import { queryClient } from 'providers/CydHookProvider/reactQueryQueryClient';

const appReducer = combineReducers({
  alertStore,
  acl,
  aclGroups,
  activityTimelineStore,
  assetsStore,
  cases,
  caseCache,
  caseData,
  casePlaybooks,
  caseActionsStore,
  caseTags,
  severity,
  users,
  groups,
  groupMembers,
  auth,
  data,
  quickSearchStore,
  IoCQuickSearch,
  playbooks,
  playbookActions,
  orgs,
  reports,
  caseStates,
  sysprops,
  samlLogin,
  notificationStore,
  watcherStore,
  caseMetasStore,
  userActionsStore,
  caseListUIStore,
  watchingCasesStore,
  caseGroupsStore,
  mttxStore,
  managerDashboardStore,
  caseStixStore,
  userPropsStore,
  caseFormsStore,
  caseThreadDrawer,
  contacts,
  resources,
  ...allRtkqApis.reduce((acc, cur) => {
    acc[cur.reducerPath] = cur.reducer;
    return acc;
  }, {})
});

export const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT_SUCCESS') {
    queryClient.clear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
