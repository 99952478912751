/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import {
  ConnectorOperationTypeName,
  ConnectorTypeName,
  OperationDefaults
} from 'interface/Connector.interface';
import { cyFetchAuthenticated, CyFetchResponse } from 'utils/CydarmFetch';

export type Identifiable = {
  uuid: string;
};

export type CydApi_Actuator = {
  acl_uuid: string;
  api_poll_rate: number;
  config: {
    data: {};
    encryption_keys: {};
  };
  config_schema: {
    data: {
      $id: string;
      $schema: string;
      additionalProperties: boolean;
      properties: {};
      type: string;
      required?: Array<string>;
    };
    encrypted_fields: Array<string>;
  };
  connectors: Array<string>;
  description: string;
  group: string;
  heartbeat: string;
  heartbeat_rate: number;
  log_level: string;
  name: string;
  principal_uuid: string;
  status: ConnectionStatus;
  supported_connector_types: Array<string>;
  uuid: string;
};

type SecretKey = unknown;
type ConnectorConfig = {
  data: Record<string, any>;
  encryptionKeys: Record<string, SecretKey>;
};

// This is the subset of JSONSchema currently in use.
// Whan adding more fields to this please consider the tradeoffs of replacing it with a thirdparty set of complete json schema types.
export type ConfigSchemaProperty = {
  title?: string;
  type: string;
  description?: string;
  items?: unknown;
};

export type ConfigSchema = {
  encrypted_fields: Array<string>;
  data: {
    $id: string;
    $schema: string;
    properties: { [key: string]: ConfigSchemaProperty };
    additionalProperties: boolean;
    required: Array<string>;
    type: string;
  };
};

export type CydApi_ConnectorType = {
  acl_uuid: string;
  config_schema: ConfigSchema;
  description: string;
  display_name: string;
  name: ConnectorTypeName;
  supported_operations: Array<string> | null;
  uuid: string;
};

export type CydApi_ConnectorOperationType = {
  id: string;
  name: ConnectorOperationTypeName;
  display_name: string;
};

export type ConnectorConfigRequest = Pick<ConnectorConfig, 'data'>;

export type CydApi_UpdateConnectorRequest = Partial<
  Pick<CydApi_Connector, 'description' | 'active'> & {
    config: ConnectorConfigRequest;
  }
>;

export type ConnectionStatus = {
  message: string;
  status: string;
};

export type CydApi_Connector = {
  acl_uuid: string;
  active: boolean;
  actuator_uuid: string;
  config: ConnectorConfig;
  connector_type_uuid: string;
  description: string;
  name: string;
  status: ConnectionStatus | null;
  uuid: string;
};

export type CydApi_EventTriggerRequestFields = {
  name: string;
  description: string;
  active: boolean;
  event_type: string;
  connector_id: string;
  operation_type_id: string;
  condition?: string;
  operation_arguments?: OperationDefaults;
};

export type CydApi_EventTrigger = CydApi_EventTriggerRequestFields & {
  uuid: string;
};

export type CydApi_CreateConnectorRequest = Omit<
  CydApi_Connector,
  'uuid' | 'status' | 'acl_uuid' | 'config'
> & {
  config: ConnectorConfigRequest;
  triggers?: Array<CydApi_CreateConnectorTrigger>;
};

export type CydApi_CreateConnectorTrigger = {
  name: string;
  event_type: string;
  condition?: string;
  connector_operation: {
    operation_type_uuid: string;
    arguments?: {};
  };
};

export function apiGetConnectorTypes(): Promise<
  CyFetchResponse<Array<CydApi_ConnectorType>>
> {
  return cyFetchAuthenticated(`/connector-type`, {
    method: 'GET'
  });
}

export function apiGetActuators(): Promise<
  CyFetchResponse<Array<CydApi_Actuator>>
> {
  return cyFetchAuthenticated(`/actuator`, {
    method: 'GET'
  });
}

export function apiGetConnectors(): Promise<
  CyFetchResponse<Array<CydApi_Connector>>
> {
  return cyFetchAuthenticated(`/connector`, {
    method: 'GET'
  });
}

export function apiCreateConnector(
  config: CydApi_CreateConnectorRequest
): Promise<CyFetchResponse<Identifiable>> {
  return cyFetchAuthenticated(`/connector`, {
    method: 'POST',
    body: JSON.stringify(config)
  });
}

export function apiDeleteConnector(
  connectorUuid: string
): Promise<CyFetchResponse<Identifiable>> {
  return cyFetchAuthenticated(`/connector/${connectorUuid}`, {
    method: 'DELETE'
  });
}

export function apiUpdateConnector(
  config: CydApi_UpdateConnectorRequest,
  connectorUuid: string
): Promise<CyFetchResponse<Identifiable>> {
  return cyFetchAuthenticated(`/connector/${connectorUuid}`, {
    method: 'PUT',
    body: JSON.stringify(config)
  });
}
