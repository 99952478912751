/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonApiLinks } from './JsonApiLinks';
import {
    JsonApiLinksFromJSON,
    JsonApiLinksFromJSONTyped,
    JsonApiLinksToJSON,
} from './JsonApiLinks';
import type { JsonApiResourceId } from './JsonApiResourceId';
import {
    JsonApiResourceIdFromJSON,
    JsonApiResourceIdFromJSONTyped,
    JsonApiResourceIdToJSON,
} from './JsonApiResourceId';

/**
 * 
 * @export
 * @interface JsonApiToOneRelationship
 */
export interface JsonApiToOneRelationship {
    /**
     * 
     * @type {JsonApiLinks}
     * @memberof JsonApiToOneRelationship
     */
    links?: JsonApiLinks | null;
    /**
     * 
     * @type {JsonApiResourceId}
     * @memberof JsonApiToOneRelationship
     */
    data?: JsonApiResourceId | null;
    /**
     * Non-standard meta-information about the relationship
     * @type {object}
     * @memberof JsonApiToOneRelationship
     */
    meta?: object;
}

/**
 * Check if a given object implements the JsonApiToOneRelationship interface.
 */
export function instanceOfJsonApiToOneRelationship(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonApiToOneRelationshipFromJSON(json: any): JsonApiToOneRelationship {
    return JsonApiToOneRelationshipFromJSONTyped(json, false);
}

export function JsonApiToOneRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiToOneRelationship {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'links': !exists(json, 'links') ? undefined : JsonApiLinksFromJSON(json['links']),
        'data': !exists(json, 'data') ? undefined : JsonApiResourceIdFromJSON(json['data']),
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
    };
}

export function JsonApiToOneRelationshipToJSON(value?: JsonApiToOneRelationship | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'links': JsonApiLinksToJSON(value.links),
        'data': JsonApiResourceIdToJSON(value.data),
        'meta': value.meta,
    };
}

