/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Typography } from '@mui/material';
import AnimatedModal from 'components/AnimatedModal/AnimatedModal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { alertStoreSelector } from 'states/alerts/selectors';

export const Alert = () => {
  const [open, setOpen] = useState(false);
  const [latestAlert] = useSelector(alertStoreSelector);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (latestAlert?.id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [latestAlert?.id]);

  return (
    <AnimatedModal isOpen={open} onClose={handleClose}>
      <Typography
        component="h3"
        variant="h5"
        gutterBottom
        data-testid="alert-header"
      >
        {latestAlert?.header}
      </Typography>
      <Typography data-testid="alert-body">{latestAlert?.body}</Typography>
    </AnimatedModal>
  );
};
