/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CydFragment } from 'components/_utils/CydFragment';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { useAclsHook } from 'hooks/AclHooks';
import React from 'react';
import { CydObjectNotFound } from '../CydObjectNotFound/CydObjectNotFound';
import { CydAclDisplay, CydAclDisplayProps } from './CydAclDisplay';
export type CydAclDisplayByIdProps = {
  aclId: string;
} & Omit<CydAclDisplayProps, 'acl'>;

export const CydAclDisplayById = withErrorBoundary(
  (props: CydAclDisplayByIdProps) => {
    const { aclId, ...rest } = props;

    if (!aclId) {
      return null;
    }

    const { getAclByUuid } = useAclsHook();
    const result = getAclByUuid(aclId);

    // @ts-ignore - remove when redux stuff is sorted
    if (result.isLoading || !result.hasLoaded) {
      return <LoadingSpinner />;
    }

    if (!result.data) {
      throw new Error(`No ACL found for id: '${aclId}`);
    }

    return <CydAclDisplay acl={result.data} {...rest} />;
  },
  CydFragment,
  (props) => <CydObjectNotFound objectId={props.aclId} />
);
