import { ActivityItem } from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createDownloadData } from 'states/data/actions';
import { createNoop } from 'utils/Misc';
type DownloadHook = {
  downloadCaseActivity: (caseActivity: ActivityItem) => void;
};

const DownloadHookContext = React.createContext<DownloadHook>({
  downloadCaseActivity: createNoop('DownloadCaseActivity')
});

export const DownloadHookProvider = (
  props: React.PropsWithChildren<DownloadHook>
) => {
  const { children, ...rest } = props;
  return (
    <DownloadHookContext.Provider value={rest}>
      {children}
    </DownloadHookContext.Provider>
  );
};

export const useDownload = () => {
  return React.useContext(DownloadHookContext);
};

export const ProductionDownloadHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const dispatch = useDispatch();
  const handleDownload = React.useCallback(
    (caseActivity: ActivityItem) => {
      dispatch(createDownloadData(caseActivity));
    },
    [dispatch]
  );

  return (
    <DownloadHookProvider downloadCaseActivity={handleDownload}>
      {props.children}
    </DownloadHookProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */
export const InMemoryDownloadHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <DownloadHookProvider
      downloadCaseActivity={(data) => alert(JSON.stringify(data))}
    >
      {props.children}
    </DownloadHookProvider>
  );
};
