/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Autocomplete from '@mui/material/Autocomplete';
import { useAssets } from 'hooks/AssetsHooks';
import { TextField } from '@mui/material';

export const AssetsSelect = ({ value, onChange, ...props }) => {
  const { data: assetsList } = useAssets();
  const handleChangeInterceptor = (selectedOption) => {
    const selectedAssets =
      (selectedOption &&
        assetsList.find(({ name }) => selectedOption.name === name)) ||
      {};
    if (!selectedAssets) {
      return;
    }
    const assetsData = {
      name: selectedAssets.name,
      asset_organisation: selectedAssets.organisation,
      asset_description: selectedAssets.description
        ? selectedAssets.description
        : '',
      asset_location: selectedAssets.location ? selectedAssets.location : '',
      asset_ip_addresses: selectedAssets.ipAddresses?.join(', ') || '',
      asset_infrastructure_types:
        selectedAssets.infrastructureTypes?.join(', ') || ''
    };
    onChange({ target: { value: assetsData } });
  };
  return (
    <Autocomplete
      onChange={(_, optionSelected) => handleChangeInterceptor(optionSelected)}
      options={assetsList}
      getOptionLabel={(option) => option.name || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select asset"
          variant="outlined"
          fullWidth
        />
      )}
      {...props}
    />
  );
};
