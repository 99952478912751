/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAddActionToCase, createFetchCaseActions } from './actions';

interface ICaseActions {
  [index: string]: PlaybookActionInstance[];
}

interface IDeletePayload {
  caseUuid: string;
  playbookActionUuid: string;
}

export interface ICasePlaybookActionsStoreState {
  isLoading: boolean;
  caseActions: ICaseActions;
}

export const initialState: ICasePlaybookActionsStoreState = {
  caseActions: {},
  isLoading: false
};

const caseActionsSlice = createSlice({
  name: 'caseActions',
  initialState,
  reducers: {
    fetchCaseActionsSuccess: (
      state,
      { payload }: PayloadAction<ICaseActions>
    ) => {
      state.isLoading = false;
      state.caseActions = payload;
    },
    addActionToCaseSuccess: (state) => {
      state.isLoading = false;
    },
    removeActionFromCaseSuccess: (
      state,
      { payload }: PayloadAction<IDeletePayload>
    ) => {
      state.caseActions[payload.caseUuid] = state.caseActions[
        payload.caseUuid
      ].filter((action) => action.actionUuid !== payload.playbookActionUuid);
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createFetchCaseActions, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddActionToCase, (state) => {
        state.isLoading = true;
      })
});

export const {
  reducer: caseActionsStore,
  actions: {
    fetchCaseActionsSuccess,
    addActionToCaseSuccess,
    removeActionFromCaseSuccess
  }
} = caseActionsSlice;
