/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';
import { CydBaseModal } from 'components/_dialogs/CydBaseModal/CydBaseModal';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydAclDisplayById } from 'components/_objectDisplay/CydAclDisplay/CydAclDisplayById';
import { CydTimeDisplay } from 'components/_objectDisplay/CydTimeDisplay/CydTimeDisplay';
import { Acl } from 'interface/Acl.interface';
import { JsonApiDataItem } from 'interface/JsonApi';
import React, { useCallback, useState } from 'react';
import {
  CydApi_WikiPageObject,
  CydApi_WikiPageObjectRelationships,
  WikiPagePayload,
  WikiPayload
} from 'services/WikiService';
import { WikiPageCreateAndEdit } from '../WikiPageCreateAndEdit/WikiPageCreateAndEdit';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type WikiPageHeaderProps = {
  wikiItem: JsonApiDataItem<
    CydApi_WikiPageObject,
    CydApi_WikiPageObjectRelationships
  >;
  onAttachClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCreateChildWikiItem: (newData: WikiPagePayload) => void;
  onEditWikiItem: (
    oldItem: JsonApiDataItem<
      CydApi_WikiPageObject,
      CydApi_WikiPageObjectRelationships
    >,
    newData: WikiPayload
  ) => void;
  onDeleteWikiItem: (
    item: JsonApiDataItem<
      CydApi_WikiPageObject,
      CydApi_WikiPageObjectRelationships
    >
  ) => void;
  availableAcls: Array<Acl>;
  defaultAcl: Acl | null;
};

export const WikiPageHeader = (props: WikiPageHeaderProps) => {
  const {
    wikiItem,
    onDeleteWikiItem,
    onCreateChildWikiItem,
    onAttachClick,
    onEditWikiItem,
    availableAcls,
    defaultAcl
  } = props;
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCreateChildPageModalOpen, setIsCreateChildPageModalOpen] =
    useState<boolean>(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const handleEditClick = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  const handleAddChildPage = useCallback(() => {
    setIsCreateChildPageModalOpen(true);
  }, []);

  return (
    <>
      {isEditModalOpen && (
        <WikiPageCreateAndEdit
          wikiItem={wikiItem}
          onCloseClick={() => setIsEditModalOpen(false)}
          availableAcls={availableAcls}
          defaultAcl={defaultAcl}
          onEditWiki={(data) => {
            onEditWikiItem(wikiItem, data);
          }}
        />
      )}
      {isCreateChildPageModalOpen && (
        <WikiPageCreateAndEdit
          onCloseClick={() => setIsCreateChildPageModalOpen(false)}
          availableAcls={availableAcls}
          defaultAcl={defaultAcl}
          onEditWiki={(data) => {
            onCreateChildWikiItem({ ...data, parent_uuid: wikiItem.id });
          }}
        />
      )}
      {isDeleteConfirmOpen && (
        <CydBaseModal
          isOpen
          onClose={() => setIsDeleteConfirmOpen(false)}
          title="Are you sure?"
          dontShowCloseButton
        >
          <>
            <Typography variant="body1">
              Do you want to delete this wiki page?
            </Typography>
            <CydButtonStack justifyContent="flex-end">
              <CydButton
                variant="text"
                onClick={() => setIsDeleteConfirmOpen(false)}
              >
                Cancel
              </CydButton>
              <CydButton
                onClick={() => {
                  onDeleteWikiItem(wikiItem);
                  setIsDeleteConfirmOpen(false);
                }}
              >
                Continue
              </CydButton>
            </CydButtonStack>
          </>
        </CydBaseModal>
      )}

      <div
        css={(theme) => `
     display: flex; 
     flex-flow: row nowrap;
     justify-content: space-between;
     align-items: flex-start;
     margin-left: ${theme.spacing(2)};
     margin-right: ${theme.spacing(2)};
   `}
      >
        <Typography
          variant="h4"
          color="text.primary"
          aria-label={wikiItem.attributes.title}
        >
          {wikiItem.attributes.title}
        </Typography>
        <CydButtonStack
          css={css`
            margin-top: 0;
          `}
        >
          <CydButton
            variant="outlined"
            startIcon={'upload'}
            onClick={onAttachClick}
          >
            Attach
          </CydButton>
          <CydButton
            variant="outlined"
            startIcon={'add'}
            onClick={handleAddChildPage}
          >
            Add child page
          </CydButton>
          <CydButton
            variant="outlined"
            startIcon={'edit'}
            onClick={handleEditClick}
          >
            Edit
          </CydButton>
          <CydButton
            variant="outlined"
            startIcon={'delete'}
            onClick={() => setIsDeleteConfirmOpen(true)}
          >
            Delete
          </CydButton>
        </CydButtonStack>
      </div>
      <div
        css={(theme) => `
     display: flex;
     align-items: flex-start;
     gap: ${theme.spacing(8)};
     margin-left: ${theme.spacing(2)};
   `}
      >
        <Typography variant="caption">
          Created:
          <CydTimeDisplay
            time={wikiItem.meta.created_date}
            css={(theme) => `margin-left: ${theme.spacing(1)}`}
          />
        </Typography>

        <Typography variant="caption">
          Last modified:
          <CydTimeDisplay
            time={wikiItem.meta.last_modified_date}
            css={(theme) => `margin-left: ${theme.spacing(1)}`}
          />
        </Typography>
        {wikiItem.relationships?.acl && (
          <CydAclDisplayById
            aclId={wikiItem.relationships?.acl?.data.id}
            variant="small-clickable"
          />
        )}
      </div>
    </>
  );
};
