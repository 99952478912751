/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';
import {
  IContactCreate,
  IContactBulkCreate,
  IContactUpdate
} from 'interface/Contacts.interface';

export const createFetchContacts = createAction('contacts/fetch');
export const createContact = createAction<IContactCreate>('contacts/create');
export const createUpdateContact =
  createAction<IContactUpdate>('contacts/update');
export const createDeleteContact = createAction<string>('contacts/delete');
export const createBulkContacts = createAction<IContactBulkCreate>(
  'contacts/createBulk'
);
