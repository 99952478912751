/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import * as React from 'react';
import { useSelectStyle } from './style';
import { FormControl, InputLabel } from '@mui/material';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import { useUniqueId } from 'hooks/useUniqueId';

export type IOption = {
  label: React.ReactNode;
  value: any;
};

export interface IDefaultSelectProps extends SelectProps {
  options: IOption[];
  helperText?: string;
  emptyValue?: any;
  placeholderSelection?: boolean;
  disabledPlaceholder?: boolean;
  classes?: {
    wrapper?: string;
    select?: string;
  };
  ariaLabel?: string;
  wrapperStyle?: CSSProperties;
}

export const DefaultSelect: React.FunctionComponent<IDefaultSelectProps> = ({
  options,
  placeholder,
  variant = 'outlined',
  inputProps,
  helperText,
  multiple,
  emptyValue,
  label,
  placeholderSelection = false,
  classes = { wrapper: '', select: '' },
  wrapperStyle,
  ariaLabel,
  disabledPlaceholder,
  ...rest
}) => {
  const { wrapper } = useSelectStyle();
  const generatedId = useUniqueId(`default-select-${label}`);

  return (
    <FormControl
      style={wrapperStyle}
      className={clsx(wrapper, classes.wrapper)}
    >
      <InputLabel id={generatedId} shrink={true}>
        {label}
      </InputLabel>
      <Select
        classes={{ select: clsx(classes.select) }}
        displayEmpty
        label={label}
        labelId={generatedId}
        multiple={multiple}
        fullWidth
        notched={true}
        inputProps={{
          'aria-label': ariaLabel
        }}
        {...rest}
      >
        {placeholderSelection && (
          <MenuItem value={emptyValue || ''} disabled={disabledPlaceholder}>
            {placeholder}
          </MenuItem>
        )}
        {options.map(({ label, value }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText && rest.error && (
        <FormHelperText error={rest.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
