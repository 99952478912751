/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';
// Actions Function

interface IGenerateReportOptions {
  caseUuid: string;
  startTime: number;
  endTime: number;
  timezone: string;
  locator?: string;
  significances?: string[] | undefined;
  aclReadList?: string[];
  format?: string;
}

export const createGenerateReport = createAction<IGenerateReportOptions>(
  'caseData/generateReport'
);
