/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import React from 'react';

import { Markdown } from 'components/Markdown';
import { Base64Decode } from 'utils/StringUtils';
import { JsonApiDataItem } from 'interface/JsonApi';
import {
  CydApi_WikiPageObject,
  CydApi_WikiPageObjectRelationships
} from 'services';
/** @jsxImportSource @emotion/react */

export type WikiPageContentProps = {
  wikiItem: JsonApiDataItem<
    CydApi_WikiPageObject,
    CydApi_WikiPageObjectRelationships
  >;
};

export const WikiPageContent = (props: WikiPageContentProps) => {
  const { wikiItem } = props;
  return (
    <div
      css={(theme) => `
    color: ${theme.palette.text.primary};
    padding-left: ${theme.spacing(2)};
  `}
    >
      <Markdown
        css={(theme) => `
    max-width: 80vw; //stop content from overflowing
    line-height: ${theme.spacing(5)}
  `}
      >
        {Base64Decode(wikiItem.attributes.content)}
      </Markdown>
    </div>
  );
};
