/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { CydTabPanels } from 'components/_formContainers/CydTabPanels/CydTabPanels';
import { CydTextField } from 'components/_formElements/CydTextField';
import { PlaybookAction } from 'interface/Playbook.interface';
import React, { useState } from 'react';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { Markdown } from 'components/Markdown';
import { Box, Typography } from '@mui/material';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { Acl } from 'interface/Acl.interface';
import { CydSearchableSingleSelect } from 'components/_formElements/CydSearchableSingleSelect/CydSearchableSingleSelect';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';
/** @jsxImportSource @emotion/react */

export type CydAddActionToCaseModalProps = {
  availableActions: Array<PlaybookAction>;
  onAddNewAction: (newPlaybook: PlaybookAction) => void;
  onCreateAction: (data: {
    title: string;
    description: string;
    acl: Acl;
  }) => void;
  isOpen: boolean;
  onClose: () => void;
  availableAcls: Array<Acl>;
  defaultAcl: Acl | null;

  buttonTextAddExisting?: string;
  buttonTextCreateAndAdd?: string;
  createOnly?: boolean;
};

export const CydActionAdderModal = (props: CydAddActionToCaseModalProps) => {
  const {
    availableActions,
    onAddNewAction,
    onCreateAction,
    isOpen,
    onClose,
    availableAcls,
    defaultAcl,
    createOnly,

    buttonTextAddExisting = 'Add to case',
    buttonTextCreateAndAdd = 'Create and add to case'
  } = props;

  const [selectedAction, setSelectedAction] = useState(
    null as null | PlaybookAction
  );
  const [mode, setMode] = useState(
    createOnly ? 'Create new action' : 'Add existing action'
  ); // Little bit hacky here, but we need to know which tab is open, so we can handle the
  // form submit accordingly

  return (
    <CydSimpleFormModal
      size="large"
      isOpen={isOpen}
      onFormSubmit={(e) => {
        e.preventDefault();

        if (mode === 'Add existing action') {
          if (!selectedAction) {
            throw new Error('No selected action on form submission');
          }
          onAddNewAction(selectedAction);
          return;
        }

        if (mode === 'Create new action') {
          const formData = new FormData(e.currentTarget);

          const acl = formData.get('acl')?.toString();
          const name = formData.get('name')?.toString();
          const description = formData.get('action-description')?.toString();

          const foundAcl = availableAcls.find((v) => v.uuid === acl);

          if (!foundAcl) {
            throw new Error('No selected ACL');
          }

          if (!name) {
            throw new Error('No name found');
          }

          onCreateAction({
            title: name,
            description: description ?? '',
            acl: foundAcl
          });
          return;
        }

        throw new Error(
          `Tried form submission in an unrecognised mode: ${mode}`
        );
      }}
      onClose={onClose}
    >
      <>
        <CydTabPanels
          variant="side-panel"
          label="Add action to case"
          initialSelectedTab={mode}
          onTabChange={setMode}
          tabs={[
            ...(createOnly
              ? []
              : [
                  {
                    label: 'Add existing action',
                    content: (
                      <>
                        <CydSearchableSingleSelect
                          autoFocus
                          disablePortal={false}
                          availableOptions={availableActions}
                          selectedOptionString={selectedAction?.uuid}
                          onChange={(v) => setSelectedAction(v)}
                          label="Select Action"
                          generateOptionLabel={(v) => {
                            return v ? v.name : '';
                          }}
                          renderOption={(props, v) => {
                            return (
                              <li key={v.uuid} {...props}>
                                <span>{v.name}</span>
                              </li>
                            );
                          }}
                        />

                        {selectedAction && (
                          <div
                            css={(theme) => `
                    margin-top: ${theme.spacing(6)};
                    margin-bottom: ${theme.spacing(4)};
                    padding: ${theme.spacing(3)};
                    border: 1px solid ${theme.palette.grey[700]};
                    border-radius: ${theme.spacing(2)}`}
                          >
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              css={(theme) => `
                        margin-top: ${theme.spacing(3)};
                        font-weight: 600;
                      `}
                            >
                              Description
                            </Typography>
                            <Markdown>{selectedAction.description}</Markdown>
                          </div>
                        )}

                        <CydButtonStack justifyContent={'flex-end'}>
                          <CydButton type="submit">
                            {buttonTextAddExisting}
                          </CydButton>
                        </CydButtonStack>
                      </>
                    )
                  }
                ]),
            {
              label: 'Create new action',
              content: (
                <>
                  <CydTextField
                    required
                    label="Action Name"
                    name="name"
                    autoFocus
                  />
                  <Box height={'400px'} border={'1px'} position={'relative'}>
                    <CydEnhancedMarkdownEditor
                      initialValue={''}
                      name="action-description"
                      label="Action description"
                    />
                  </Box>
                  <CydAclSelector
                    required
                    availableAcls={availableAcls}
                    defaultSelectedAclUuid={defaultAcl?.uuid}
                    name="acl"
                  />

                  <CydButtonStack justifyContent={'flex-end'}>
                    <CydButton type="submit">
                      {buttonTextCreateAndAdd}
                    </CydButton>
                  </CydButtonStack>
                </>
              )
            }
          ]}
        />
      </>
    </CydSimpleFormModal>
  );
};
