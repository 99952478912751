/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import React, { useState, useMemo, memo } from 'react';
import { CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import { TimeFormattedCase } from './interfaces';
import { useDataAcls } from 'hooks/AclHooks';
import {
  createAddTagToCase,
  createRemoveTagFromCase
} from 'states/cases/actions';
import { useDispatch } from 'react-redux';
import ItemSummary from './ItemSummary';
import ItemContents from './ItemContents';
import { useSystemPropValue } from 'hooks/SysPropsHooks';
import { SYSTEM_PROPERTY } from '../../constants';

/** @jsxImportSource @emotion/react */

interface IReviewCaseItem extends TimeFormattedCase {
  innerRef: any;
}

const ReviewCaseItem: React.FC<IReviewCaseItem> = memo(
  ({ tags, acl, innerRef, ...props }) => {
    const dispatch = useDispatch();
    const [currentTags, setCurrentTags] = useState<string[]>(tags || []);
    const REVIEW_TAG = useSystemPropValue(SYSTEM_PROPERTY.REVIEW_TAG, '');
    const { data: acls } = useDataAcls();

    const expanded = useMemo(
      () => currentTags.indexOf(REVIEW_TAG) === -1,
      [currentTags, REVIEW_TAG]
    );

    const currentAcl = useMemo(
      () => acls.find(({ uuid }) => uuid === acl),
      [acls, acl]
    );

    const handleClick = () => {
      if (expanded) {
        dispatch(
          createAddTagToCase({ caseUuid: props.uuid, tagValue: REVIEW_TAG })
        );
        setCurrentTags([...currentTags, REVIEW_TAG]);
      } else if (!expanded) {
        dispatch(
          createRemoveTagFromCase({
            caseUuid: props.uuid,
            tagValue: REVIEW_TAG
          })
        );
        let newTags = [...currentTags];
        newTags.splice(newTags.indexOf(REVIEW_TAG), 1);
        setCurrentTags(newTags);
      }
    };

    return (
      <Accordion
        css={(theme) => {
          const { spacing, palette } = theme;
          return `
            width: 100%;
            min-width: 800px;
            flex: 0 1 auto;
            margin-bottom: ${spacing(1)};
            padding: ${spacing(2)};
            transition: transform 0.2s ease;
            position: relative;
            background: ${palette.background.default};
            && {
              border-radius: ${spacing(1)};
            }
            &::before {
              display: none;
            }
            &&:focus,
            &&:focus-within,
            &&:active {
              transform: scale(1.05);
              z-index: 2;
              background: ${palette.action.selected};
              outline: none;
            }
            &.Mui-expanded {
              margin: ${spacing(0, 0, 1, 0)};
              background: ${palette.action.hover};
            }
        `;
        }}
        defaultExpanded
        tabIndex={0}
        expanded={expanded}
        ref={innerRef}
      >
        <ItemSummary expanded={expanded} handleClick={handleClick} {...props} />
        {currentAcl ? (
          <ItemContents
            currentTags={currentTags}
            currentAcl={currentAcl}
            {...props}
          />
        ) : (
          <CircularProgress color="primary" />
        )}
      </Accordion>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.tags) === JSON.stringify(nextProps.tags) &&
    prevProps.acl === nextProps.acl &&
    prevProps.status === nextProps.status &&
    prevProps.severity === nextProps.severity &&
    prevProps.assignee === nextProps.assignee &&
    prevProps.created === nextProps.created &&
    prevProps.modified === nextProps.modified
);

export default ReviewCaseItem;
