/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice } from '@reduxjs/toolkit';
import { uniqueArray } from 'utils/ArrayUtils';
export interface ICaseDataStoreState {
  caseData: { [index: string]: string[] };
}

const initialState: ICaseDataStoreState = {
  caseData: {}
};

const caseDataSlice = createSlice({
  name: 'caseData',
  initialState,
  reducers: {
    assignDataToCase(state, { payload }) {
      state.caseData[payload.caseUuid] = uniqueArray([
        ...(state.caseData[payload.caseUuid] || []),
        payload.dataUuid
      ]);
    },

    assignDatasetToCase(state, { payload }) {
      state.caseData[payload.caseUuid] = uniqueArray([
        ...(state.caseData[payload.caseUuid] || []),
        ...payload.dataUuids
      ]);
    },

    removeDataFromCase(state, { payload }) {
      state.caseData[payload.caseUuid] = (
        state.caseData[payload.caseUuid] || []
      ).filter((uuid) => uuid !== payload.dataUuid);
    }
  }
});

export const {
  reducer: caseData,
  actions: { assignDataToCase, assignDatasetToCase, removeDataFromCase }
} = caseDataSlice;
