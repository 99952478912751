/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { useCasePlaybookListItemStyles } from './styles';
import { Case } from 'interface/Case.interface';
import { CasePlaybookActionItem } from './CasePlaybookAction/CasePlaybookActionItem';

export interface ICaseActionItemProps {
  caseActions: PlaybookActionInstance[];
  case: Case;
}

export const CaseActionsItem: React.FC<ICaseActionItemProps> = ({
  caseActions,
  case: securityCase
}) => {
  const { itemContainer, itemTitle, itemHeader, actionsHeading } =
    useCasePlaybookListItemStyles();

  //should be a selector
  const noOfCompletedActions = React.useMemo(
    () => caseActions.filter(({ status }) => status === 'success').length,
    [caseActions]
  );

  //should be a selector
  const sortedCaseActions = [...caseActions].sort((actionA, actionB) => {
    if (actionA.created && actionB.created) {
      return actionA.created >= actionB.created ? 1 : -1;
    }
    return actionA.name > actionB.name ? 1 : -1;
  });

  return (
    <div className={itemContainer}>
      <Grid container className={itemHeader}>
        <Grid item xs={9}>
          <Typography className={itemTitle}>{'Attached Action'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="right">
            {' '}
            {noOfCompletedActions} / {caseActions.length} completed
          </Typography>
          {caseActions.length > 0 && (
            <LinearProgress
              color="primary"
              variant="determinate"
              value={(noOfCompletedActions / caseActions.length) * 100}
            />
          )}
        </Grid>
      </Grid>
      <Grid className={actionsHeading} container>
        <Grid item xs={6}>
          <Typography>Action</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center">Assignee</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="center">Is Completed?</Typography>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <div>
        {sortedCaseActions.map((action) => (
          <CasePlaybookActionItem
            key={`${action.actionUuid}_${action.position}`}
            action={action}
            case={securityCase}
            belongsToPlaybook={false}
          />
        ))}
      </div>
    </div>
  );
};
