/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  createAddNewUserProperty,
  createFetchAllUserProperty,
  createUpdateUserProperty
} from 'states/userProps/actions';
import {
  allUserPropsSelector,
  selectCommentHeightStyleValue,
  selectRowsPerPage,
  transformedUserPropSelector,
  userPropsHasLoadedSelector,
  userPropsStatusSelector
} from 'states/userProps/selectors';
import { useAutoEffect } from './ReduxHooks';
import { IStore } from 'states/store.interface';
import React from 'react';
import { useCallback } from 'react';
import { useCurrentUser } from './AuthHooks';

export const useAllUserProps = (userUuid: string) => {
  return useAutoEffect({
    selector: allUserPropsSelector,
    ac: createFetchAllUserProperty,
    statusSelector: userPropsStatusSelector,
    hasLoadedSelector: userPropsHasLoadedSelector,
    actionData: userUuid,
    equality: shallowEqual
  });
};

export const useUserPropNameExists = (userUuid, propName) => {
  const userPropList = useAllUserProps(userUuid);
  const foundPropName = userPropList.data.find((el) => el.name === propName);
  return foundPropName ? true : false;
};

export const useUserPropValue = (
  userUuid,
  propName,
  defaultVal: any = undefined
) => {
  useAllUserProps(userUuid);
  return useSelector(
    (
      state: IStore // OPERATION TYPES CLEAN UP - I added the assertion here to fix the type error. Really this type should be inferred.
    ) => transformedUserPropSelector(state, propName, defaultVal)
  );
};

type UserPropsHook = {
  getUserProp: typeof useUserPropValue;
};

const UserPropsHookContext = React.createContext<UserPropsHook>({
  getUserProp: () => {
    throw new Error('UserPropsContextNotInitialised!');
  }
});

export const UserPropsHookProvider = (
  props: React.PropsWithChildren<UserPropsHook>
) => {
  const currentUser = useCurrentUser();
  const { hasLoaded } = useAllUserProps(currentUser.data?.uuid || '');

  if (!hasLoaded) {
    return null;
  }

  const { children, ...rest } = props;
  return (
    <UserPropsHookContext.Provider value={rest}>
      {children}
    </UserPropsHookContext.Provider>
  );
};

export const ProductionUserPropsHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <UserPropsHookProvider getUserProp={useUserPropValue}>
      {props.children}
    </UserPropsHookProvider>
  );
};

export const useRowsPerPageUserPropValue = () => useSelector(selectRowsPerPage);

export const useCommentHeightStyleValue = (userUuid = '') => {
  useAllUserProps(userUuid);
  return useSelector(selectCommentHeightStyleValue);
};

export type UserProp = {
  propName: string;
  propValue: any;
};

export const useSaveUserProp = () => {
  const dispatch = useDispatch();
  const { data: user } = useCurrentUser();
  const userProps = useAllUserProps(user?.uuid!);

  return useCallback(
    (userProp: UserProp) => {
      if (!user) {
        throw new Error('No user exists while trying to save user property');
      }
      if (userProps.data.find((el) => el.name === userProp.propName)) {
        dispatch(
          createUpdateUserProperty({
            userUuid: user.uuid,
            propName: userProp.propName,
            propValue: userProp.propValue
          })
        );
      } else {
        dispatch(
          createAddNewUserProperty({
            userUuid: user.uuid,
            propName: userProp.propName,
            propValue: userProp.propValue
          })
        );
      }
    },
    [dispatch, user, userProps]
  );
};
