/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { User } from 'interface/User.interface';
import { createAction } from '@reduxjs/toolkit';
import { INewUserData } from 'components/forms/UserSettingsForm';

interface ICreateUser {
  username: string;
  fields: INewUserData;
  resolve: () => void;
  reject: () => void;
}

interface IUpdateUser extends ICreateUser {
  uuid: string;
}

interface IUpdateUserPassword {
  uuid: string;
  fields: {
    newPassword: string;
  };
}

interface IReactivateUser {
  userUuid: string;
  username: string;
}

// Users Actions Function
export const createFetchUsers = createAction('users/fetch');
export const createFetchAllUsers = createAction('users/fetchAll');
export const createFetchUserInfo = createAction<string>('users/fetchUserInfo');
export const createUser = createAction<ICreateUser>('users/create');
export const createUpdateUser = createAction<IUpdateUser>('users/update');
export const createUpdateUserPassword = createAction<IUpdateUserPassword>(
  'users/updatePassword'
);
export const createDeleteUser = createAction<User>('users/delete');
export const createReactivateUser =
  createAction<IReactivateUser>('users/reactivate');
