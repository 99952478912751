/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState, useCallback, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { useCaseMemberOfMemberData } from 'hooks/CaseDataHooks';
import { Typography, Grid, Collapse, Tooltip } from '@mui/material';
import { ActivitiesTimelineForm } from 'components/forms/ActivitiesTimelineForm';
import { useDataSignificanceState } from 'hooks/CaseTimelineHooks';
import { CaseDataReportForm } from 'components/forms/CaseDataReportForm/CaseDataReportForm';
import { useCaseViewPageReportStyles } from './styles';
import { useDispatch } from 'react-redux';
import { createGenerateReport } from 'states/caseData/actions';
import moment from 'moment';
import { useCaseStix } from 'hooks/CaseStixHooks';
import { CaseIOCsTable } from 'components/tables/CaseIOCsTable';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
  fetchIOCsfromCases,
  getIocRowData,
  iocCsvFileHeaders,
  IStixCsvRow,
  transformIOCtoCsvFileData
} from 'utils/IOCUtils';
import { useTimezoneLegacy } from 'hooks/AuthHooks';
import { createDownloadCaseStixData } from 'states/caseStix/actions';
import { ActivitiesTimeline } from 'components/ActivitiesTimeline';
import { GroupFilter } from './GroupFilter';
import { CSVLink } from 'react-csv';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { useParams } from 'react-router';
import { createFetchCaseAndMembersByUuid } from 'states/cases/actions';
import { useCaseViewPageContext } from '../CaseViewPage';

/** @jsxImportSource @emotion/react */

export function CaseViewPageReport() {
  const caseUuid = useParams()['caseUuid'];

  if (!caseUuid) {
    throw new Error('No caseUuid in URL params');
  }

  const { cydCase } = useCaseViewPageContext();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createFetchCaseAndMembersByUuid(cydCase.uuid));
  }, [dispatch, cydCase.uuid]);

  const { data: allCaseActivity } = useCaseMemberOfMemberData(
    cydCase.deepMembers ?? [cydCase.uuid]
  );

  const timezone = useTimezoneLegacy();

  const [aclList, setAclList] = useState(Array(0));

  const [reportDomain, setReportDomain] = useState<[string, string]>(['', '']);

  const [isOpen, setIsOpen] = useState(() => true);

  const [dataSignificancesToShow, setDataSignificancesToShow] =
    useDataSignificanceState();

  const { data: detailedStixData = [] } = useCaseStix(caseUuid);

  const handleDownloadSTIXData = () => {
    dispatch(createDownloadCaseStixData(cydCase));
  };

  const formRef = useRef<any>();

  const handleRequestReportFormSubmit = useCallback(() => {
    if (!formRef.current || !formRef.current.submitForm) {
      return;
    }

    formRef.current.submitForm();
  }, []);

  const handleReportFormSubmit = useCallback(
    ({ startDate, endDate, reportFormat }) => {
      const reportSig = dataSignificancesToShow.map(({ name }) => name);
      if (moment(endDate) === moment(startDate)) {
        startDate = moment(startDate).subtract(15, 'minutes').toDate();
        endDate = moment(endDate).add(15, 'minutes').toDate();
      }
      moment(endDate).isAfter(startDate) &&
        dispatch(
          createGenerateReport({
            caseUuid,
            startTime: Date.parse(startDate),
            endTime: Date.parse(endDate),
            timezone: timezone,
            significances: reportSig,
            format: reportFormat,
            aclReadList: aclList
          })
        );
    },
    [caseUuid, dispatch, dataSignificancesToShow, aclList, timezone]
  );

  const {
    reportWrapper,
    formWrapper,
    container,
    linkButton,
    csvDownloadButton
  } = useCaseViewPageReportStyles();

  const iocs = fetchIOCsfromCases(detailedStixData);

  const iocRows: IStixCsvRow[] = iocs.map((ioc) =>
    getIocRowData(ioc, timezone)
  );

  const csvFileName = cydCase
    ? `cydarm_case_indicators_${cydCase.locator}.csv`
    : 'cydarm_case_indicators.csv';
  const csvFileData: string[][] = transformIOCtoCsvFileData(iocRows);

  const handleToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const [linkTimelineDate, setLinkTimelineDate] = useState(true);
  const handleToggleLinkTimelineData = () => {
    setLinkTimelineDate((prevProps) => !prevProps);
  };

  return cydCase ? (
    <div className={reportWrapper}>
      <Typography variant="h5" gutterBottom>
        Create new report
      </Typography>
      <Typography gutterBottom>Select data to include</Typography>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <ActivitiesTimeline
            activities={allCaseActivity}
            stixData={detailedStixData}
            dataSignificancesToShow={dataSignificancesToShow}
            domain={reportDomain}
            handleDateChange={setReportDomain}
            caseAcl={cydCase.acl}
          />
        </Grid>
        <Grid item xs={3}>
          <ActivitiesTimelineForm
            onSubmit={setDataSignificancesToShow}
            value={dataSignificancesToShow}
          />
        </Grid>
      </Grid>
      <Grid container></Grid>
      <div className={formWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <CaseDataReportForm
              formRef={formRef}
              onSubmit={handleReportFormSubmit}
              handleDateChange={setReportDomain}
              timelineLink={linkTimelineDate}
              timelineStartDate={reportDomain[0]}
              timelineEndDate={reportDomain[1]}
            />
          </Grid>
          <Grid item xs={3}>
            <Tooltip title="Link graph with date fields">
              <IconButton
                className={linkButton}
                onClick={handleToggleLinkTimelineData}
              >
                {linkTimelineDate ? <LinkIcon /> : <LinkOffIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid container item xs={9} spacing={2}>
            <Grid item xs={9}>
              <GroupFilter
                caseData={cydCase}
                setAclList={setAclList}
                aclList={aclList}
              />
            </Grid>
            <Grid
              item
              xs={3}
              css={(theme) => `
                margin: auto;
              `}
            >
              <CydButton
                onClick={handleRequestReportFormSubmit}
                variant="contained"
              >
                Create Report
              </CydButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={container}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton onClick={handleToggleIsOpen}>
              {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h5">Indicators of Compromise</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <CydButtonStack>
              <CydButton onClick={handleDownloadSTIXData} variant="outlined">
                Download STIX 2.1
              </CydButton>
              <CSVLink
                data={csvFileData}
                filename={csvFileName}
                className={csvDownloadButton}
                headers={iocCsvFileHeaders}
                target="_blank"
              >
                <CydButton variant="outlined">Download CSV</CydButton>
              </CSVLink>
            </CydButtonStack>
          </Grid>
        </Grid>
        <Collapse in={isOpen}>
          <div>{iocs && <CaseIOCsTable iocs={iocRows} />}</div>
        </Collapse>
      </div>
    </div>
  ) : null;
}
