/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { PropsWithChildren } from 'react';
import { CydBasicPanel } from 'components/_panels/CydBasicPanel/CydBasicPanel';

/** @jsxImportSource @emotion/react */

export type GenericNodeDetailsPanelProps = PropsWithChildren<{
  headerContent?: React.ReactNode;
}>;

export const GenericNodeDetailsPanel = (
  props: GenericNodeDetailsPanelProps
) => {
  const { children, headerContent } = props;

  return (
    <CydBasicPanel
      data-testid="node-details-panel"
      showBorder
      css={(theme) => `
      position: absolute; 
      top: ${theme.spacing(3)}; 
      bottom: ${theme.spacing(6)}; 
      right: ${theme.spacing(3)}; 
      width: 348px;
      z-index: 10; 

      overflow-y: auto;
      margin: 0;

      padding-bottom: ${theme.spacing(
        20
      )}; //Just give plenty of room to scroll into 
    `}
    >
      <div
        css={(theme) => `
               margin-bottom: ${theme.spacing(4)};

`}
      >
        {headerContent}
      </div>

      {children}
    </CydBasicPanel>
  );
};
