/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

export const useDefaultLayoutHeaderStyles = makeStyles(({ spacing }) => ({
  headerToolbar: {
    boxSizing: 'border-box',
    padding: spacing(0, 3.5),
    flexWrap: 'wrap',
    minHeight: 54,
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerToolbarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  headerToolbarRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  headerTimezoneWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  headerClock: {
    paddingRight: spacing()
  },
  logoImage: {
    display: 'block',
    width: 175,
    marginRight: spacing(5)
  }
}));

export const useDefaultLayoutUserMenuStyles = makeStyles(
  ({ spacing, palette }) => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: '1 1 auto',
      marginLeft: spacing(2)
    },
    iconWrapper: {
      height: 32,
      width: 32,
      backgroundColor: 'rgba(143, 165, 204, 0.6)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      marginLeft: spacing(2)
    },
    accountWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    menuContainer: {
      backgroundColor: palette.background.paper
    },
    menuItem: {
      color: palette.text.primary,
      textDecoration: 'none',
      'li&': {
        color: palette.text.primary
      }
    }
  })
);

export const useDefaultLayoutNavigationStyles = makeStyles(
  ({ spacing, palette }) => ({
    item: {
      height: 54,
      color: palette.primary.contrastText,
      textDecoration: 'none',
      textTransform: 'uppercase',
      display: 'inline-flex',
      alignItems: 'center',
      paddingLeft: spacing(),
      paddingRight: spacing(),
      borderRadius: '0px',
      transition: 'color 0.2s ease, transform 0.2s ease',
      position: 'relative',
      '&:hover': {
        color: alpha(palette.primary.contrastText, 0.5),
        transform: 'scale(1.02)'
      },
      '&.active:after': {
        content: '""',
        display: 'block',
        backgroundColor: palette.primary.contrastText,
        width: '100%',
        height: 2,
        position: 'absolute',
        bottom: 0,
        left: 0
      }
    }
  })
);

export const useDefaultLayoutSearchStyles = makeStyles(() => ({
  wrapper: {
    display: 'inline-flex'
  },
  input: {
    margin: 'auto'
  },
  icon: {
    margin: 'auto',
    marginLeft: '20px'
  }
}));

export const useDefaultLayoutContentStyles = makeStyles(
  ({ spacing, palette }) => ({
    contentWrapper: {
      overflowY: 'auto',
      flex: 1,
      display: 'flex',
      width: '100%',
      backgroundColor: palette.background.default,
      alignItems: 'flex-start',
      padding: spacing(2, 3.5),
      position: 'relative',
      flexDirection: 'column'
    },
    contentPaper: {
      margin: `${spacing(4)} auto`,
      width: 960,
      maxWidth: '100%'
    }
  })
);

export const useDefaultLayoutBannerStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    padding: '2px 0',
    fontSize: '14px',
    background: (colors: any) => colors.bgColor,
    color: (colors: any) => colors.textColor
  }
}));

export const useDefaultLayoutSidebarStyles = makeStyles(({ spacing }) => ({
  sidebarLayout: {
    width: '100%',
    display: 'flex'
  },
  siderContent: {
    width: '100%'
  }
}));
