/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as React from 'react';
import { DefaultTableDataCell, DefaultTableRow } from 'components/DefaultTable';
import { QuickSearch } from './QuickSearch';
import { useQuickSearchResults } from 'hooks/IoCQuickSearchHooks';
import { IStixCsvRow } from 'utils/IOCUtils';

interface ICaseIOCsTableRowProps {
  ioc: IStixCsvRow;
}

export const CaseIOCsTableRow: React.FC<ICaseIOCsTableRowProps> = React.memo(
  ({ ioc }) => {
    const { data: results } = useQuickSearchResults(ioc.patternValue);

    // name, description, type, value, created, locator
    return (
      <DefaultTableRow>
        <DefaultTableDataCell>{ioc.name ? ioc.name : '-'}</DefaultTableDataCell>
        <DefaultTableDataCell>
          {ioc.description ? ioc.description : '-'}
        </DefaultTableDataCell>
        <DefaultTableDataCell>{ioc.patternField}</DefaultTableDataCell>
        <DefaultTableDataCell>{ioc.patternValue}</DefaultTableDataCell>
        <DefaultTableDataCell>{ioc.createdDateString}</DefaultTableDataCell>
        <DefaultTableDataCell>
          <QuickSearch>{results[ioc.patternValue]}</QuickSearch>
        </DefaultTableDataCell>
      </DefaultTableRow>
    );
  }
);
