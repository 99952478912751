/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useAclAciRowStyles = makeStyles(
  ({ spacing, palette, shadows }) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: spacing(0.5),
      paddingTop: spacing(0.5),
      width: '100%'
    },
    rowTitle: {
      textTransform: 'capitalize'
    },
    field: {
      minHeight: '40px',
      borderRadius: spacing(0.5),
      width: '80%',
      lineHeight: '28px',
      boxShadow: shadows[2],
      whiteSpace: 'nowrap',
      overflow: 'auto'
    },
    chipContainer: {
      margin: spacing(1),
      marginLeft: spacing(1.5)
    }
  })
);
