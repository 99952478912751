/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CaseDataForm } from 'components/forms/CaseDataForm';
import { CaseDataStixForm } from 'components/forms/CaseDataForm/CaseDataStixForm';
import { CaseDataGeneratedForms } from 'components/forms/CaseDataGeneratedForms';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydTabPanels } from 'components/_formContainers/CydTabPanels/CydTabPanels';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { Acl } from 'interface/Acl.interface';
import { DataSignificance } from 'interface/DataSignificance.interface';
import React, { useState } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  AddCaseActivityFormCommentPayload,
  AddCaseActivityFormPayload,
  AddCaseActivityFormStixPayload
} from 'interface/Case.interface';
import { confidenceRating } from 'utils/CaseDataUtils';
export type FormActivityModes = 'stix' | 'comment' | 'reply' | 'forms' | 'edit';

export type CydAddCaseActivityFormProps = {
  availableAcls: Array<Acl>;
  caseUuid: string;
  availableSignificances: Array<DataSignificance>;

  initialFormData?: AddCaseActivityFormPayload; // May not be needed

  onCancel: () => void;
  onTabChange: (newTab: string) => void;
  onSave: (data: AddCaseActivityFormPayload) => void;

  initialSelectedAcl?: Acl;
  initialSelectedSignificance?: DataSignificance;

  // This kind of a legacy throwback.
  commentMode?: FormActivityModes;
  isFullscreen?: boolean;
};

const DEFAULT_COMMENT_DATA = {
  type: 'comment',
  data: {
    mde: '',
    files: []
  }
};

/**
 * This component handles
 * @param props
 * @returns
 */
export const CydAddCaseActivityForm = (props: CydAddCaseActivityFormProps) => {
  const {
    availableAcls,
    caseUuid,
    availableSignificances,
    onSave,
    initialFormData,
    commentMode,
    onCancel,
    onTabChange,
    initialSelectedAcl,
    initialSelectedSignificance,
    isFullscreen = false
  } = props;

  if ((initialFormData && !commentMode) || (commentMode && !initialFormData)) {
    console.warn('initialFormData should be used with commentMode', {
      initialFormData,
      commentMode
    });
  }

  const [selectedAcl, setSelectedAcl] = useState(
    initialSelectedAcl || (null as null | Acl)
  );
  const [selectedSignificance, setSelectedSignificance] = useState(
    initialSelectedSignificance || (null as null | DataSignificance)
  );
  const [files, setFiles] = useState<Array<File>>([]);

  const [formData, setFormData] = useState(
    (initialFormData || DEFAULT_COMMENT_DATA) as Pick<
      AddCaseActivityFormPayload,
      'data' | 'type'
    >
  );

  const selectedTab = (mode: string | undefined) => {
    switch (mode) {
      case 'reply':
      case 'edit':
      case 'comment':
        return 'Note';
      case 'stix':
        return 'Data';
      case 'forms':
        return 'Form';
      default:
        return 'Note';
    }
  };
  const [currentTab, setCurrentTab] = useState(selectedTab(commentMode));

  return (
    <form
      id="form-validation"
      onSubmit={(e) => {
        if (e && e.preventDefault) {
          e.preventDefault();
        }

        if (!selectedAcl || !selectedSignificance) {
          throw new Error('something has gone wrong these need to be selected');
        }

        if (currentTab === 'Note') {
          const eventFormData = new FormData(e.currentTarget);
          files.filter((v: File) => {
            return v.size > 0;
          });
          const comment = eventFormData.get('comment-content');
          onSave({
            type: formData.type || commentMode, // Be careful here. If there is a formData type, that will take precedence.
            data: {
              mde: comment,
              files
            },
            acl: selectedAcl,
            dataSignificance: selectedSignificance
          } as AddCaseActivityFormPayload);
        } else {
          onSave({
            type: formData.type || commentMode, // Be careful here. If there is a formData type, that will take precedence.
            data: formData.data,
            acl: selectedAcl,
            dataSignificance: selectedSignificance
          } as AddCaseActivityFormPayload);
        }
      }}
      css={css`
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        flex: 1 0 auto;
      `}
    >
      <CydTabPanels
        variant="chip"
        hideTabs={
          commentMode === 'reply' || commentMode === 'edit' ? true : false
        } // If it is a reply/edit only the comment form should be shown
        initialSelectedTab={selectedTab(commentMode)}
        label="Select Activity Type"
        onTabChange={(newTab) => {
          // This relates to: https://cydarm.atlassian.net/browse/RM-2455
          // The reason this is needed, is because the Markdown Editor doesn't have a required field.
          // So where 'required' fields would otherwise cause an onchange, and the right data will be set
          // That can possibly not happen with the comment form, and the user can hit save and the previous form (stix/forms) will be saved
          if (newTab === 'Note') {
            setFormData(
              DEFAULT_COMMENT_DATA as Pick<
                AddCaseActivityFormPayload,
                'data' | 'type'
              >
            );
          }
          setCurrentTab(newTab);
          onTabChange(newTab);
        }}
        tabs={[
          {
            label: 'Note',
            content: (
              <CaseDataForm
                formData={formData as AddCaseActivityFormCommentPayload}
                isFullscreen={isFullscreen}
                files={files}
                onFilesAdded={(files) => {
                  setFiles(files);
                }}
              />
            )
          },
          {
            label: 'Data',
            content: (
              <CaseDataStixForm
                caseUuid={caseUuid}
                formData={{
                  confidence: confidenceRating[0].value,
                  // default set Ioc to true
                  isIoc: true,
                  ...(formData as AddCaseActivityFormStixPayload).data
                }}
                onChange={(data) => {
                  setFormData({
                    type: 'stix',
                    data
                  });
                }}
              />
            )
          },
          {
            label: 'Form',
            content: (
              <div
                css={css`
                  width: 600px;
                `}
              >
                <CaseDataGeneratedForms
                  onChange={(dataString: string) => {
                    setFormData({
                      type: 'forms',
                      data: dataString
                    });
                  }}
                />
              </div>
            )
          }
        ]}
      />
      <CydButtonStack
        justifyContent={'space-between'}
        css={css`
          justify-self: flex-end;
        `}
      >
        <CydButtonStack>
          <CydSimpleSelect
            required
            availableOptions={availableAcls}
            generateOptionLabel={(v) => {
              return v.description;
            }}
            onChange={(v) => setSelectedAcl(v)}
            label="Case Activity ACL"
            generateValueString={(v) => v.uuid}
            selectedValueString={selectedAcl ? selectedAcl.uuid : null}
          />
          <CydSimpleSelect
            required
            availableOptions={availableSignificances}
            generateOptionLabel={(v) => {
              return v.name;
            }}
            onChange={(v) => setSelectedSignificance(v)}
            label="Significance"
            selectedOption={selectedSignificance}
          />
        </CydButtonStack>
        <CydButtonStack
          css={css`
            align-items: flex-end;
          `}
        >
          <CydButton variant="text" onClick={onCancel}>
            Cancel
          </CydButton>
          <CydButton type="submit">Save</CydButton>
        </CydButtonStack>
      </CydButtonStack>
    </form>
  );
};
