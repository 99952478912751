/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectorOperationTypeResource } from './ConnectorOperationTypeResource';
import {
    ConnectorOperationTypeResourceFromJSON,
    ConnectorOperationTypeResourceFromJSONTyped,
    ConnectorOperationTypeResourceToJSON,
} from './ConnectorOperationTypeResource';
import type { JsonApiError } from './JsonApiError';
import {
    JsonApiErrorFromJSON,
    JsonApiErrorFromJSONTyped,
    JsonApiErrorToJSON,
} from './JsonApiError';
import type { JsonApiLinks } from './JsonApiLinks';
import {
    JsonApiLinksFromJSON,
    JsonApiLinksFromJSONTyped,
    JsonApiLinksToJSON,
} from './JsonApiLinks';
import type { JsonApiServerImpl } from './JsonApiServerImpl';
import {
    JsonApiServerImplFromJSON,
    JsonApiServerImplFromJSONTyped,
    JsonApiServerImplToJSON,
} from './JsonApiServerImpl';

/**
 * 
 * @export
 * @interface ConnectorOperationTypeListTopLevel
 */
export interface ConnectorOperationTypeListTopLevel {
    /**
     * 
     * @type {JsonApiLinks}
     * @memberof ConnectorOperationTypeListTopLevel
     */
    links?: JsonApiLinks | null;
    /**
     * 
     * @type {JsonApiServerImpl}
     * @memberof ConnectorOperationTypeListTopLevel
     */
    jsonapi?: JsonApiServerImpl | null;
    /**
     * A list of objects related to this resource
     * @type {Array<object>}
     * @memberof ConnectorOperationTypeListTopLevel
     */
    included?: Array<object>;
    /**
     * A list of error objects
     * @type {Array<JsonApiError>}
     * @memberof ConnectorOperationTypeListTopLevel
     */
    errors?: Array<JsonApiError>;
    /**
     * List of objects in this resource
     * @type {Array<ConnectorOperationTypeResource>}
     * @memberof ConnectorOperationTypeListTopLevel
     */
    data?: Array<ConnectorOperationTypeResource>;
}

/**
 * Check if a given object implements the ConnectorOperationTypeListTopLevel interface.
 */
export function instanceOfConnectorOperationTypeListTopLevel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectorOperationTypeListTopLevelFromJSON(json: any): ConnectorOperationTypeListTopLevel {
    return ConnectorOperationTypeListTopLevelFromJSONTyped(json, false);
}

export function ConnectorOperationTypeListTopLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorOperationTypeListTopLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'links': !exists(json, 'links') ? undefined : JsonApiLinksFromJSON(json['links']),
        'jsonapi': !exists(json, 'jsonapi') ? undefined : JsonApiServerImplFromJSON(json['jsonapi']),
        'included': !exists(json, 'included') ? undefined : json['included'],
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(JsonApiErrorFromJSON)),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ConnectorOperationTypeResourceFromJSON)),
    };
}

export function ConnectorOperationTypeListTopLevelToJSON(value?: ConnectorOperationTypeListTopLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'links': JsonApiLinksToJSON(value.links),
        'jsonapi': JsonApiServerImplToJSON(value.jsonapi),
        'included': value.included,
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(JsonApiErrorToJSON)),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ConnectorOperationTypeResourceToJSON)),
    };
}

