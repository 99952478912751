/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeEvery } from 'redux-saga/effects';
import { createFetchIoCQuickSearch } from './actions';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { iocQuickSearchSuccess, iocQuickSearchFailed } from './slice';
import { apiPerformQuickSearch } from 'services/IocQuickSearchAndMetricsService';

function* performQuickSearch({ payload: searchText }: { payload: string }) {
  try {
    const { json: results } = yield apiPerformQuickSearch({
      searchString: searchText
    });
    yield put(
      iocQuickSearchSuccess({ results: results, searchText: searchText })
    );
  } catch (ex) {
    yield put(iocQuickSearchFailed());
    yield put(
      addNotification({ message: ERROR_MESSAGE.IOC_QUICK_SEARCH_ERROR.message })
    );
  }
}

/* Watchers */
function* watchQuickSearch() {
  yield takeEvery(createFetchIoCQuickSearch, performQuickSearch);
}

export default [watchQuickSearch()];
