/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Typography, Link, Checkbox } from '@mui/material';
import { css } from '@emotion/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccordionSummary from '@mui/material/AccordionSummary';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

/** @jsxImportSource @emotion/react */

const ItemSummary = ({ handleClick, description, locator, uuid, expanded }) => (
  <AccordionSummary
    css={(theme) => `
      & .MuiAccordionSummary-content {
        display: flex;
        min-width: 0;
        margin: 0;
        align-items: center;
        cursor: default;
        position: relative;
        & .MuiTypography-subtitle2 {
          color: ${theme.palette.text.secondary};
        }
        & a {
          display: block;
          flex: 0 0 auto;
        }
      }
    `}
  >
    <div
      css={css`
        flex: 1 1 auto;
        width: calc(100% - 185px);
        position: relative;
      `}
    >
      <Typography variant="subtitle2">{locator}</Typography>
      <TruncatedTitle>{description}</TruncatedTitle>
    </div>
    <Link target="_blank" href={`/caseview/${uuid}`}>
      <CydButton
        css={(theme) => `
          background: ${theme.palette.primary.light};
          color: ${theme.palette.primary.contrastText};
          border-radius: 50px;
          padding: 6px 10px;
          flex: 0 0 auto;
          &:hover {
            background: ${theme.palette.primary.main};
          }
      `}
      >
        View Case
      </CydButton>
    </Link>
    <FormControlLabel
      css={css`
        & .MuiTypography-body1 {
          font-size: 12px;
        }
      `}
      value="reviewed"
      control={
        <Checkbox
          size="small"
          checked={!expanded}
          onChange={handleClick}
          color="primary"
        />
      }
      label="Reviewed"
      labelPlacement="start"
    />
  </AccordionSummary>
);

const TruncatedTitle = ({ children }) => (
  <div
    css={(theme) => `
      width: calc(100% - 25px);
      position: relative;
      flex: 1 1 auto;
      & .MuiTypography-subtitle1 {
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      & .MuiTypography-subtitle1:hover + span {
        opacity: 1;
        visibility: visible;
      }
      & span {
        position: absolute;
        left: spacing(-2);
        top: spacing(-1);
        background: ${theme.palette.background.default};
        border-radius: spacing(1);
        padding: spacing(1, 2);
        font-size: 20px;
        font-weight: 300;
        line-height: 25px;
        box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.8);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease 0.5s;
      }
    `}
  >
    <Typography variant="subtitle1">{children}</Typography>
    <span>{children}</span>
  </div>
);

export default ItemSummary;
