/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useMemo, useEffect, useState, FC } from 'react';

import moment from 'moment';
import 'moment-timezone';

import { dateFormatInHour } from 'utils/DateUtils';

export const Clock: FC<React.HTMLProps<HTMLDivElement>> = (props) => {
  const { children } = props;

  const timezone = useMemo(
    () => String(children) || moment.tz.guess(),
    [children]
  );

  const [currentTime, setCurrentTime] = useState(
    moment().tz(timezone).format(dateFormatInHour)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().tz(timezone).format(dateFormatInHour));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timezone]);

  return <div {...props}>{currentTime}</div>;
};
