/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { ICreateStixDataRequest } from 'interface/CaseData.interface';
import { createAction } from '@reduxjs/toolkit';
import { Case } from 'interface/Case.interface';

interface IAdd {
  stixData: ICreateStixDataRequest;
  currentCaseDataUuids?: string[];
}

export const createFetchStix = createAction<string>('caseStix/fetch');

export const createAddStixData = createAction<IAdd>('caseStix/add');

export const createDownloadCaseStixData =
  createAction<Case>('caseStix/download');
