/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
/** @jsxImportSource @emotion/react */

export type CacaoBadgeProps = {};

export const CacaoBadge = (props: CacaoBadgeProps) => {
  return (
    <span
      css={(theme) => `
        color: ${theme.palette.info.main};
        border: solid 1px ${theme.palette.info.dark};
        border-radius: 6px;
        margin: 0 0.8em 0 0.5em;
        padding: 0.3em;
        font-size: 10px;
      `}
    >
      cacao
    </span>
  );
};
