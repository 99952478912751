/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  DefaultPopover,
  IDefaultPopoverProps
} from 'components/DefaultPopover';
import { Typography } from '@mui/material';
import { useAddPlaybookPopoverStyles } from 'pages/CaseViewPage/styles';
import { AtcPlaybook } from 'interface/Playbook.interface';
import { AtcPlaybookSelect } from 'components/fields/AtcPlaybookSelect';
import { useAtcPlaybooks } from 'hooks/PlaybooksHooks';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';

interface ICaseViewPagePlaybooksAddPopupProps extends IDefaultPopoverProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (playbook: AtcPlaybook) => void;
}

export const CaseViewPagePlaybooksAddPopup: React.FC<
  ICaseViewPagePlaybooksAddPopupProps
> = ({ isOpened, onClose, onSubmit, ...rest }) => {
  const { container } = useAddPlaybookPopoverStyles();
  const { data: atcPlaybooks } = useAtcPlaybooks();
  const [selectedPlaybook, setSelectedPlaybook] = React.useState<
    AtcPlaybook | undefined
  >(undefined);

  const handlePlaybookChange = (event: any) => {
    setSelectedPlaybook(event.target.value);
  };

  const handleAddPlaybook = () => {
    if (!selectedPlaybook) {
      return;
    }

    onSubmit(selectedPlaybook);
    onClose();
  };

  const popoverActions = (
    <CydButtonStack>
      <CydButton onClick={onClose} variant="text">
        Cancel
      </CydButton>
      <CydButton variant="contained" onClick={handleAddPlaybook}>
        Add Playbook
      </CydButton>
    </CydButtonStack>
  );

  return (
    <DefaultPopover
      isOpened={isOpened}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      actions={popoverActions}
      className={container}
      onClose={onClose}
      {...rest}
    >
      <Typography gutterBottom>Add a copy of this playbook</Typography>
      <AtcPlaybookSelect
        atcPlaybooks={atcPlaybooks}
        value={selectedPlaybook}
        onChange={handlePlaybookChange}
        placeholder="Select playbook"
      />
    </DefaultPopover>
  );
};
