/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { signIn, fetchCurrentUserInfo } from './actions';
import { User, ITotpConfig } from 'interface/User.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueArray } from 'utils/ArrayUtils';
import { LOCAL_STORAGE } from '../../constants';

export interface IAuthStoreState {
  isLoading: boolean;
  signedIn: boolean;
  hasLoaded: boolean;
  timeZone?: string;
  user?: User;
  totp?: ITotpConfig;
  timezoneList: string[];
}

const initialState: IAuthStoreState = {
  isLoading: false,
  hasLoaded: false,
  signedIn: false,
  timezoneList: []
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInFailed: (state) => ({ ...state, isLoading: false }),

    signInSuccess: (state) => ({ ...state, signedIn: true, isLoading: false }),

    fetchCurrentUserInfoSuccess: (state, { payload }: PayloadAction<User>) => ({
      ...state,
      user: payload,
      isLoading: false,
      hasLoaded: true
    }),

    updateTimezoneSuccess: (state, { payload }: PayloadAction<string>) => {
      state.timeZone = payload;
      localStorage.setItem(LOCAL_STORAGE.TIME_ZONE, state.timeZone);
    },

    fetchStoredTimezoneList: (state, { payload }: PayloadAction<string>) => {
      const storedList = localStorage.getItem(
        LOCAL_STORAGE.STORED_TIME_ZONE_LIST
      );
      if (storedList) {
        state.timezoneList = JSON.parse(storedList);
      }
    },

    addTimezone: (state, { payload }: PayloadAction<string>) => {
      state.timezoneList = state.timezoneList
        ? uniqueArray([...state.timezoneList, payload])
        : [payload];
      localStorage.setItem(
        LOCAL_STORAGE.STORED_TIME_ZONE_LIST,
        JSON.stringify(state.timezoneList)
      );
    },

    removeTimezone: (state, { payload }: PayloadAction<string>) => {
      state.timezoneList = state.timezoneList.filter(
        (timezone) => timezone !== payload
      );
      localStorage.setItem(
        LOCAL_STORAGE.STORED_TIME_ZONE_LIST,
        JSON.stringify(state.timezoneList)
      );
    },

    fetchTotpConfigSuccess: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        totp: payload,
        isLoading: false,
        hasLoaded: true
      };
    },

    fetchTotpConfigFailed: (state) => ({
      ...state,
      totp: undefined,
      isLoading: false,
      hasLoaded: true
    }),

    initialiseTotpConfig: (state, { payload }: PayloadAction<ITotpConfig>) => ({
      ...state,
      totp: {
        ...state.totp,
        totpSecret: payload.totpSecret,
        qrBytedata: payload.qrBytedata
      },
      isLoading: false,
      hasLoaded: true
    }),
    validateTotpConfig: (state) => ({ ...state, isLoading: false })
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn, (state) => ({
        ...state,
        isLoading: true,
        hasLoaded: false
      }))

      .addCase(fetchCurrentUserInfo, (state) => ({
        ...state,
        hasLoaded: true
      }));
  }
});

export const {
  reducer: auth,
  actions: {
    signInFailed,
    signInSuccess,
    fetchCurrentUserInfoSuccess,
    updateTimezoneSuccess,
    addTimezone,
    removeTimezone,
    fetchTotpConfigSuccess,
    initialiseTotpConfig,
    fetchTotpConfigFailed
  }
} = authSlice;
