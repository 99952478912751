/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  DefaultSelect,
  IDefaultSelectProps
} from 'components/fields/DefaultSelect';
import { AtcPlaybook } from 'interface/Playbook.interface';

interface IPlaybookSelectProps extends Omit<IDefaultSelectProps, 'options'> {
  atcPlaybooks: AtcPlaybook[];
}

const mapPlaybookToOptions = (playbooks: AtcPlaybook[]) =>
  playbooks.map((playbook) => ({
    label: playbook.name,
    value: playbook
  }));

export const AtcPlaybookSelect: React.FunctionComponent<
  IPlaybookSelectProps
> = ({ atcPlaybooks, value, multiple, placeholder, onChange, ...props }) => {
  const playbookOptions = React.useMemo(
    () => mapPlaybookToOptions(atcPlaybooks),
    [atcPlaybooks]
  );

  const _value =
    value &&
    (multiple
      ? atcPlaybooks.filter(({ uuid }) =>
          (value as AtcPlaybook[]).some(
            (selectedPlaybook) => selectedPlaybook.uuid === uuid
          )
        )
      : atcPlaybooks.find(({ uuid }) => uuid === (value as AtcPlaybook).uuid));

  const emptyValue = React.useMemo(() => (multiple ? [] : ''), [multiple]);

  const changeHandler = React.useCallback(
    (event, child) => {
      if (!onChange) {
        return;
      }

      if (!multiple) {
        onChange(event, child);
      }

      const value = event.target.value;
      const isEmptySelected = multiple && value.some((val: any) => val === '');

      event.target.value = isEmptySelected ? [''] : value;

      onChange(event, child);
    },
    [multiple, onChange]
  );

  const renderValue = React.useCallback(
    (selected) => {
      if (selected.length === 0) {
        return <span>{placeholder}</span>;
      }

      return selected.map(({ name }: AtcPlaybook) => name).join(', ');
    },
    [placeholder]
  );

  return (
    <DefaultSelect
      multiple={multiple}
      options={playbookOptions}
      value={_value || emptyValue}
      placeholder={placeholder}
      onChange={changeHandler}
      renderValue={multiple ? renderValue : undefined}
      {...props}
    />
  );
};
