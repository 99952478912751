import { GenericPlaybookStatusUpdate } from 'components/_playbooks/CacaoPlaybookRunningInstance';
import {
  CydApi_CasePlaybookInstance,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { cyFetchAuthenticated, CyFetchResponse } from 'utils/CydarmFetch';

export async function apiFetchCaseAction(caseUuid: string) {
  return cyFetchAuthenticated(`/case/${caseUuid}/action`, {
    method: 'GET'
  });
}

export async function apiAddActionToCase(caseUuid: string, actionUuid: string) {
  return cyFetchAuthenticated(`/case/${caseUuid}/action/${actionUuid}`, {
    method: 'POST'
  });
}

export async function apiRemoveActionFromCase(
  caseUuid: string,
  actionInstanceUuid: string
) {
  return cyFetchAuthenticated(
    `/case/${caseUuid}/action-instance/${actionInstanceUuid}`,
    {
      method: 'DELETE'
    }
  );
}

export async function apiUpdateActionToCase(
  caseUuid: string,
  actionInstanceUuid: string,
  action: PlaybookActionInstance
) {
  return cyFetchAuthenticated(
    `/case/${caseUuid}/action-instance/${actionInstanceUuid}`,
    {
      method: 'PUT',
      body: JSON.stringify(action)
    }
  );
}

export async function apiFetchCasePlaybooks(
  caseUuid: string
): Promise<CyFetchResponse<Array<CydApi_CasePlaybookInstance>>> {
  return cyFetchAuthenticated(`/case/${caseUuid}/playbook`);
}

export async function apiAddPlaybookToCase(
  caseUuid: string,
  playbookUuid: string
): Promise<
  CyFetchResponse<{
    uuid: string;
    acl: string;
  }>
> {
  return cyFetchAuthenticated(`/case/${caseUuid}/playbook/${playbookUuid}`, {
    method: 'POST'
  });
}

export async function apiRemovePlaybookFromCase(
  caseUuid: string,
  playbookUuid: string
) {
  return cyFetchAuthenticated(`/case/${caseUuid}/playbook/${playbookUuid}`, {
    method: 'DELETE'
  });
}

export async function apiUpdateCasePlaybook(
  caseUuid: string,
  casePlaybookUuid: string,
  payload: GenericPlaybookStatusUpdate
) {
  return cyFetchAuthenticated(
    `/case/${caseUuid}/playbook/${casePlaybookUuid}`,
    {
      method: 'PUT',
      body: JSON.stringify(payload)
    }
  );
}

export async function apiUpdateCasePlaybookAction(
  caseUuid: string,
  playbookUuid: string,
  actionInstanceUuid: string,
  content: unknown
) {
  return cyFetchAuthenticated(
    `/case/${caseUuid}/playbook/${playbookUuid}/action-instance/${actionInstanceUuid}`,
    {
      method: 'PUT',
      body: JSON.stringify(content)
    }
  );
}

export async function apiRemoveTagFromCasePlaybookAction(
  caseUuid: string,
  casePlaybookUuid: string,
  actionInstanceUuid: string,
  tagUuid: string
) {
  return cyFetchAuthenticated(
    `/case/${caseUuid}/playbook/${casePlaybookUuid}/action-instance/${actionInstanceUuid}/case-tag/${tagUuid}`,
    {
      method: 'DELETE'
    }
  );
}

export async function apiAddTagToCasePlaybookAction(
  caseUuid: string,
  casePlaybookUuid: string,
  actionInstanceUuid: string,
  tagUuid: string
) {
  return await cyFetchAuthenticated(
    `/case/${caseUuid}/playbook/${casePlaybookUuid}/action-instance/${actionInstanceUuid}/case-tag/${tagUuid}`,
    {
      method: 'POST'
    }
  );
}
