/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { fetchSystemProperty } from './actions';
import { SystemPropertyInterface } from 'interface/SystemProperty.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  properties: Array<SystemPropertyInterface>;
  isLoading?: boolean;
  hasLoaded?: boolean;
} = {
  properties: [],
  isLoading: false,
  hasLoaded: false
};

const sysPropsSlice = createSlice({
  name: 'sysprops',
  initialState,
  reducers: {
    fetchAllSystemPropSuccess: (
      state,
      { payload }: PayloadAction<SystemPropertyInterface[]>
    ) => {
      state.isLoading = false;
      state.hasLoaded = true;
      try {
        state.properties = payload;
      } catch (ex) {
        console.error('fetchAllSystemPropSuccess: ' + ex.toString());
      }
    },
    fetchSystemPropertySuccess: (
      state,
      { payload }: PayloadAction<SystemPropertyInterface>
    ) => {
      state.isLoading = false;
      try {
        state.properties = [
          ...state.properties.filter(({ name }) => name !== payload.name),
          payload
        ];
      } catch (ex) {
        console.error('fetchSystemPropertySuccess: ' + ex.toString());
      }
    },
    fetchSystemPropertyFailed: (state) => {
      state.isLoading = false;
    },
    removeSystemPropertySuccess: (
      state,
      { payload }: PayloadAction<string | string[]>
    ) => {
      state.isLoading = false;
      try {
        let array = [] as Array<string>;
        if (Array.isArray(payload)) {
          array = payload;
        } else {
          array = [payload];
        }

        return {
          properties: state.properties.filter(
            ({ uuid }) => !array.includes(uuid)
          )
        };
      } catch (ex) {
        console.error('removeSystemPropertySuccess: ' + ex.toString());
        throw ex;
      }
    }
  },
  extraReducers: (builder) =>
    builder.addCase(fetchSystemProperty, (state) => {
      //@ts-ignore this error appeared when we upgraded to typescript 4.7.4
      state.isLoading = true;
      //@ts-ignore this error appeared when we upgraded to typescript 4.7.4
      state.hasLoaded = false;
    })
});

export const {
  reducer: sysprops,
  actions: {
    fetchAllSystemPropSuccess,
    fetchSystemPropertySuccess,
    fetchSystemPropertyFailed,
    removeSystemPropertySuccess
  }
} = sysPropsSlice;
