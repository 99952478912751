/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

//TODO proper TS support
import { createSlice } from '@reduxjs/toolkit';

const initialState: any[] = [];

const aclGroupsSlice = createSlice({
  name: 'aclGroups',
  initialState,
  reducers: {
    fetchAclGroupsSuccess(state, { payload }) {
      state = payload;
    }
  }
});

export const {
  reducer: aclGroups,
  actions: { fetchAclGroupsSuccess }
} = aclGroupsSlice;
