/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { put, takeEvery } from 'redux-saga/effects';
import { createFetchMttxMetrics, IMTTxRequest } from './actions';
import { addNotification } from 'states/notifications/slice';
import {
  fetchMttxMetricsSuccess,
  fetchMttrMetricsSuccess,
  fetchMttrRequest,
  fetchMttxRequest
} from './slice';
import { apiFetchMttx } from 'services/IocQuickSearchAndMetricsService';

function* performFetchMttx(action) {
  const {
    payload
  }: {
    payload: IMTTxRequest;
  } = action;

  if (payload.store_state_in_mttr) {
    yield put(fetchMttrRequest());
  } else {
    yield put(fetchMttxRequest());
  }

  try {
    const { json: mttx } = yield apiFetchMttx(payload);
    if (payload.store_state_in_mttr) {
      // These type errors are a little annoying
      // The issue is that we appear to have this 'payload label' thing, that actually comes from the backend
      // Although I think it's determined by the request
      // It looks like in our code base, it is _always_ 'data'
      // It's probably unneccesary

      // Have suppressed the errors. There is a good comment here.
      yield put(fetchMttrMetricsSuccess({ [payload.label]: mttx } as any));
    } else {
      yield put(fetchMttxMetricsSuccess({ [payload.label]: mttx } as any));
    }
  } catch (error) {
    yield put(addNotification({ message: 'Error fetching metrics' }));
  }
}

function* watchFetchMttx() {
  yield takeEvery(createFetchMttxMetrics, performFetchMttx);
}

export default [watchFetchMttx()];
