/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Grid } from '@mui/material';
import { Case } from 'interface/Case.interface';
import {
  AtcPlaybook,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { ActionItemAssignee } from './ActionItemAssignee';
import { ActionItemComplete } from './ActionItemComplete';
import { ActionItemDescription } from './ActionItemDescription';
import { ActionItemTags } from './ActionItemTags';
import { useCasePlaybookListItemActionStyles } from '../styles';
import { ActionItemDelete } from './ActionItemDelete';

export interface ICasePlaybookListItemActionProps {
  action: PlaybookActionInstance;
  case: Case;
  playbook?: AtcPlaybook;
  casePlaybookUuid?: string;
  belongsToPlaybook: boolean;
}

export const CasePlaybookActionItem: React.FC<
  ICasePlaybookListItemActionProps
> = ({
  action,
  case: securityCase,
  casePlaybookUuid,
  belongsToPlaybook = false
}) => {
  const { actionContainer } = useCasePlaybookListItemActionStyles();

  return (
    <Grid className={actionContainer} container>
      <ActionItemDescription
        actionName={action.actionName}
        actionDescription={action.actionDescription}
      />

      {belongsToPlaybook && (
        <ActionItemTags
          {...{
            securityCase,
            casePlaybookUuid,
            action,
            belongsToPlaybook
          }}
        />
      )}

      <ActionItemAssignee
        {...{ securityCase, casePlaybookUuid, action, belongsToPlaybook }}
      />

      <ActionItemComplete
        {...{
          securityCase,
          casePlaybookUuid,
          action,
          belongsToPlaybook
        }}
      />

      {!belongsToPlaybook && <ActionItemDelete {...{ securityCase, action }} />}
    </Grid>
  );
};
