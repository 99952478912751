/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material';

import { useUniqueId } from 'hooks/useUniqueId';

type ConditionalNull<
  T,
  TAllowUnselect extends boolean
> = TAllowUnselect extends true ? T | null : T;

export type SimpleSelectProps<T, TAllowUnselect extends boolean = false> = {
  generateOptionLabel: (value: T) => string;
  onChange?: (
    newValue: ConditionalNull<T, TAllowUnselect>,
    newValueString: ConditionalNull<string, TAllowUnselect>
  ) => void;
  availableOptions: Array<T>;
  /** @deprecated - use selectedValueString instead */
  selectedOption?: T | null;
  /** For usage as an uncontrolled component*/
  defaultSelectedString?: string | null;
  /** For usage as a controlled component */
  selectedValueString?: string | null;
  /** If this function is not provided, it will use generateOptionalLabel as a fallback */
  generateValueString?: (value: T) => string;
  label?: string;
  className?: string;
  required?: boolean;
  name?: string;
  allowUnselect?: TAllowUnselect;
  unselectOptionLabel?: string;
  autoFocus?: SelectProps['autoFocus'];
  size?: SelectProps['size'];
  disabled?: boolean;
  ariaLabel?: string;
  determineDisabledOptions?: (v: T) => boolean;
};

export const CydSimpleSelect = <T, TAllowUnselect extends boolean = false>(
  props: SimpleSelectProps<T, TAllowUnselect>
) => {
  const {
    selectedOption,
    ariaLabel,
    onChange,
    availableOptions,
    generateOptionLabel,
    label,
    className = '',
    required = false,
    autoFocus = false,
    name,
    allowUnselect = false,
    unselectOptionLabel = '(Select none)',
    size,
    disabled,
    determineDisabledOptions,
    generateValueString,
    selectedValueString,
    defaultSelectedString
  } = props;

  const generateValueStringToUse = generateValueString || generateOptionLabel;
  const ref = React.createRef();

  const valuesLookup = useMemo(() => {
    const map = new Map<string, T>();
    availableOptions.forEach((v) => {
      map.set(generateValueStringToUse(v), v);
    });
    return map;
  }, [availableOptions, generateValueStringToUse]);

  let valueStringToUse: string | undefined;

  if (defaultSelectedString !== undefined) {
    valueStringToUse = undefined;
  } else if (selectedOption) {
    valueStringToUse = generateValueStringToUse(selectedOption as T);
  } else if (selectedValueString) {
    valueStringToUse = selectedValueString;
  } else {
    valueStringToUse = '';
  }

  // I don't like this
  // But it seems like MUI insists you must attach labels via an ID, rather than wrapping:
  // https://v4.mui.com/components/selects/#accessibility
  // https://github.com/mui/material-ui/issues/16494
  const generatedId = useUniqueId(`simple-select-${label}`);

  return (
    <FormControl fullWidth className={`${className}`}>
      {/* @ts-expect-error - until we can get this merged: https://github.com/cydarm/cydarm-frontend/pull/1167 */}
      <InputLabel id={generatedId} size={size}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        required={required}
        labelId={generatedId}
        label={label}
        value={valueStringToUse}
        defaultValue={defaultSelectedString}
        name={name}
        size={size}
        ref={ref}
        autoFocus={autoFocus}
        inputProps={{
          'aria-label': ariaLabel
        }}
        onChange={(e) => {
          const valueKey = e.target.value as string;
          if (valueKey === 'null') {
            onChange?.(
              null as ConditionalNull<T, TAllowUnselect>,
              null as ConditionalNull<string, TAllowUnselect>
            );
            return;
          }

          const value = valuesLookup.get(valueKey) as T;
          if (!value) {
            console.warn("Selected value doesn't exist in the valuesLookup", {
              valueKey
            });
          }
          onChange?.(value, valueKey);
        }}
      >
        {allowUnselect && (
          <MenuItem value="null">{unselectOptionLabel}</MenuItem>
        )}

        {availableOptions.length === 0 && (
          <MenuItem disabled>(none available)</MenuItem>
        )}
        {availableOptions.map((v) => {
          const optionLabel = generateOptionLabel(v);
          const value = generateValueStringToUse(v);
          const isDisabled = determineDisabledOptions
            ? determineDisabledOptions(v)
            : false;

          return (
            <MenuItem value={value} key={value} disabled={isDisabled}>
              {optionLabel}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
