/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest, takeLeading } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { history } from 'utils/HistoryUtils';
import { ERROR_MESSAGE } from './errors';
import {
  deleteUserSuccess,
  fetchUserInfoSuccess,
  fetchUsersSuccess
} from './slice';
import {
  createUser,
  createDeleteUser,
  createFetchAllUsers,
  createFetchUserInfo,
  createFetchUsers,
  createReactivateUser,
  createUpdateUser,
  createUpdateUserPassword
} from './actions';
import {
  apiCreateUser,
  apiDeleteUser,
  apiFetchAllUsers,
  apiFetchUserInfo,
  apiFetchUsers,
  apiReactivateUser,
  apiUpdateUser,
  apiUpdateUserPassword
} from 'services/UsersServices';

function* fetchUsersSaga() {
  try {
    const { json } = yield apiFetchUsers();
    yield put(fetchUsersSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_USERS_ERROR.message })
    );
  }
}

function* fetchAllUsersSaga() {
  try {
    const { json } = yield apiFetchAllUsers();
    yield put(fetchUsersSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_USERS_ERROR.message })
    );
  }
}

function* fetchUserInfoSaga({ payload: uuid }: { payload: string }) {
  try {
    const { json: user } = yield apiFetchUserInfo(uuid);
    yield put(fetchUserInfoSuccess(user));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_USERS_INFO_ERROR.message })
    );
  }
}

function* createUserSaga(action) {
  const {
    payload: {
      username,
      fields: {
        givenName,
        familyName,
        phone,
        email,
        human,
        password,
        org,
        authSourceId,
        authSourceUserName
      }
    }
  } = action;

  try {
    yield apiCreateUser({
      username,
      givenName,
      familyName,
      phone,
      email,
      human,
      password,
      orgUuid: org,
      authSourceId,
      authSourceUserName
    });
    history.go(-1);
    yield put(addNotification({ message: 'Created user' }));
    yield put(createFetchUsers());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to create user' }));
  }
}

function* updateUserSaga(action) {
  const {
    payload: {
      uuid,
      username,
      fields: {
        givenName,
        familyName,
        phone,
        email,
        userAccount,
        newPassword,
        authSourceUserName
      }
    }
  } = action;

  try {
    yield apiUpdateUser(uuid, {
      username,
      givenName,
      familyName,
      phone,
      email,
      userAccount,
      newPassword,
      authSourceUserName
    });
    yield put(createFetchAllUsers());
    yield put(addNotification({ message: 'Updated user settings' }));
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to update user settings' }));
  }
}

function* updateUserPasswordSaga(action) {
  const {
    payload: {
      uuid,
      fields: { newPassword }
    }
  } = action;

  try {
    yield apiUpdateUserPassword(uuid, { newPassword });
    yield put(addNotification({ message: 'Updated user password' }));
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to update user password' }));
  }
}

function* deleteUserSaga(action) {
  const { payload: user } = action;
  try {
    yield apiDeleteUser(user.uuid);
    yield put(
      addNotification({ message: `${user.username} has been deactivated` })
    );
    yield put(deleteUserSuccess(user.uuid));
    yield put(createFetchAllUsers());
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to deactivate user with username: ${user.username}`
      })
    );
  }
}

function* reactivateUserSaga(action) {
  const {
    payload: { userUuid, username }
  } = action;
  try {
    yield apiReactivateUser(userUuid);
    yield put(
      addNotification({
        message: `${username} user account has been reactivated`
      })
    );
    yield put(createFetchAllUsers());
  } catch (ex) {
    yield put(
      addNotification({
        message: `Failed to reactivate ${username} user account`
      })
    );
  }
}

/* Watchers */
function* watchFetchUsers() {
  yield takeLeading(createFetchUsers, fetchUsersSaga);
}

function* watchFetchAllUsers() {
  yield takeLeading(createFetchAllUsers, fetchAllUsersSaga);
}

function* watchFetchUserInfo() {
  yield takeLatest(createFetchUserInfo, fetchUserInfoSaga);
}

function* watchCreateUser() {
  yield takeLatest(createUser, createUserSaga);
}

function* watchUpdateUser() {
  yield takeLatest(createUpdateUser, updateUserSaga);
}

function* watchUpdateUserPassword() {
  yield takeLatest(createUpdateUserPassword, updateUserPasswordSaga);
}

function* watchDeleteUser() {
  yield takeLatest(createDeleteUser, deleteUserSaga);
}

function* watchReactivateUser() {
  yield takeLatest(createReactivateUser, reactivateUserSaga);
}

export default [
  watchFetchUsers(),
  watchFetchAllUsers(),
  watchFetchUserInfo(),
  watchCreateUser(),
  watchUpdateUser(),
  watchUpdateUserPassword(),
  watchDeleteUser(),
  watchReactivateUser()
];
