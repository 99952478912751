/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchGroups } from './actions';
import { Group } from 'interface/Group.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGroupStoreState {
  list: Group[];
  memberGroupUuids: string[];
  memberUserUuids: string[];
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: IGroupStoreState = {
  list: [],
  memberGroupUuids: [],
  memberUserUuids: [],
  isLoading: false,
  hasLoaded: false
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    fetchGroupsSuccess: (state, { payload }: PayloadAction<Group[]>) => {
      state.list = payload;
      state.isLoading = false;
      state.hasLoaded = true;
    },

    clearGroups: () => initialState
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchGroups, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: groups,
  actions: { fetchGroupsSuccess, clearGroups }
} = groupsSlice;
