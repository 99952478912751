/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { CydButton, CydButtonProps } from '../CydButton/CydButton';

export type CydLinkProps = PropsWithChildren<
  {
    to: string;
    className?: string;
  } & Pick<CydButtonProps, 'startIcon' | 'endIcon' | 'variant'> // We can also bring over color, if we need. We'll cross that bridge when we get there.
>;

const ReffedLink = React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
  const { href, ...rest } = props;
  return (
    <Link ref={ref} to={href} {...rest}>
      {props.children}
    </Link>
  );
});

export const CydLink = (props) => {
  const { to, className, children, ...rest } = props;

  return (
    <CydButton
      variant="outlined"
      color="primary"
      className={className}
      {...rest}
      href={to}
      LinkComponent={ReffedLink}
    >
      {children}
    </CydButton>
  );
};
