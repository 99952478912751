/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { User } from 'interface/User.interface';
import moment from 'moment';

export const fullName = (user: User): string => {
  if (!user.givenName || !user.familyName) {
    return '';
  }
  return `${user.givenName} ${user.familyName}`;
};

const sortUsersFnCreator = (orderBy: string, order: string) => {
  if (orderBy === 'created') {
    return (userA: User, userB: User) => {
      if (order === 'asc') {
        return moment(userA.created).isSameOrAfter(moment(userB.created))
          ? 1
          : -1;
      } else {
        return moment(userA.created).isBefore(moment(userB.created)) ? 1 : -1;
      }
    };
  }

  return;
};

export const sortUsers = (
  users: User[],
  orderBy = 'username',
  order = 'asc'
) => {
  const sortFunction = sortUsersFnCreator(orderBy, order);

  if (!sortFunction) {
    return users;
  }

  return users.sort(sortFunction);
};

//receive an array of usernames (or uuids, etc) and map to actual users
export const mapUsers = (
  users: User[],
  dataToMap: any[],
  mapUsing = 'username'
) => {
  return dataToMap
    .map((data) => users.find((user) => user[mapUsing] === data))
    .filter((user): user is User => user !== undefined);
};

export function getUserInitials(user: User) {
  if (!user.displayName) {
    return '?';
  } else {
    return user.displayName.trim()[0].toUpperCase();
  }
}
