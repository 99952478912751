import { CydApi_CasePlaybookInstance } from 'interface/Playbook.interface';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from '../type';

export function convertAtcToGenericPlaybook(
  playbookInstance: CydApi_CasePlaybookInstance
): GenericPlaybook {
  var sortedPlaybookAction = [...playbookInstance.action_statuses];
  sortedPlaybookAction.sort((a, b) => a.position - b.position);

  const nodes: Array<GenericNode> = sortedPlaybookAction.map((v, i, array) => {
    const nextNode = array[i + 1];

    return {
      name: v.actionName || '',
      description: v.actionDescription,
      nodeType: {
        type: 'action',
        nextNode: nextNode?.actionUuid ? nextNode.actionUuid : null,
        data: {}
      },
      oldData: v,
      nodeId: v.actionUuid as string
    };
  });

  return {
    id: playbookInstance.casePlaybookUuid,
    playbookType: 'atc',
    nodes: nodes,
    title: playbookInstance.playbookName,
    description: playbookInstance.playbookDescription,
    acl: '',
    tags: [],
    modified: '',
    created: '',
    variablesMap: {}
  };
}

export function convertAtcToGenericPlaybookAndPlaybookResolution(
  atcPlaybookInstance: CydApi_CasePlaybookInstance
): {
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;
} {
  const playbook = convertAtcToGenericPlaybook(atcPlaybookInstance);

  const playbookResolutionInfo: GenericPlaybookResolutionInfo = {
    topLevelPlaybookStatus: {
      tags: []
    },

    resolvedVariables: {},
    nodeStatuses: atcPlaybookInstance.action_statuses.reduce((acc, cur) => {
      if (!cur.actionUuid) {
        throw new Error(`Current node did not have a actionUuid`);
      }

      return {
        ...acc,
        [cur.actionUuid]: {
          status: cur.status,
          nodeId: cur.actionUuid,
          assignee: cur.assigneeUuid || null,
          tags: cur.tags || []
        }
      };
    }, {})
  };

  return {
    playbook,
    playbookResolutionInfo
  };
}
