/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICasesListSearchParams,
  ICasesListFilterParams,
  FilterPreset
} from 'interface/CasesListUI';
import { uniqueArray } from 'utils/ArrayUtils';

interface IPaginationParams {
  page: number;
  perPage: number;
}

interface IOrderParams {
  orderBy: string;
  order: 'asc' | 'desc';
}

export type ICaseListUIStoreState = {
  currentFilterName: string | null;
  selectedCaseUuids: string[];
  filterParams?: ICasesListFilterParams;
  searchParams: ICasesListSearchParams;
  paginationParams: IPaginationParams;
  orderParams: IOrderParams;
};

export const DEFAULT_SEARCH_PARAMS: ICasesListSearchParams = {
  searchQuery: '',
  searchOptions: ['description', 'locator']
};

const initialState: ICaseListUIStoreState = {
  currentFilterName: null,
  selectedCaseUuids: [],
  filterParams: undefined,
  searchParams: DEFAULT_SEARCH_PARAMS,
  orderParams: {
    orderBy: 'created',
    order: 'desc'
  },
  paginationParams: {
    page: 1,
    perPage: 50
  }
};

const caseListUISlice = createSlice({
  name: 'caseListUI',
  initialState,
  reducers: {
    updateFilterParams: (
      state,
      { payload }: PayloadAction<ICasesListFilterParams>
    ) => ({ ...state, filterParams: payload, currentFilterName: null }),
    updateSearchParams: (
      state,
      { payload }: PayloadAction<ICasesListSearchParams>
    ) => ({ ...state, searchParams: payload, currentFilterName: null }),
    addSelectedCase: (state, { payload }: PayloadAction<string>) => {
      state.selectedCaseUuids = uniqueArray([
        ...state.selectedCaseUuids,
        payload
      ]);
    },
    removeSelectedCase: (state, { payload }: PayloadAction<string>) => {
      state.selectedCaseUuids = state.selectedCaseUuids.filter(
        (uuid) => uuid !== payload
      );
    },
    updateSelectedCases: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedCaseUuids = payload;
    },
    updateOrderParams: (state, { payload }: PayloadAction<IOrderParams>) => {
      state.orderParams = payload;
    },
    updatePaginationParams: (
      state,
      { payload }: PayloadAction<IPaginationParams>
    ) => {
      state.paginationParams = payload;
    },
    updateCurrentFilter: (state, { payload }: PayloadAction<FilterPreset>) => {
      return {
        ...state,
        filterParams: payload.value.filterParams,
        searchParams: payload.value.searchParams,
        currentFilterName: payload.name
      };
    }
  }
});

export const {
  reducer: caseListUIStore,
  actions: {
    updateFilterParams,
    updateSearchParams,
    addSelectedCase,
    removeSelectedCase,
    updateSelectedCases,
    updateOrderParams,
    updatePaginationParams,
    updateCurrentFilter
  }
} = caseListUISlice;
