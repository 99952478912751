/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Grid from '@mui/material/Grid';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydAutoExpandTextField } from 'components/_formElements/CydAutoExpandTextField/CydAutoExpandTextField';

export type CydMetadataFormItem = {
  metadataType: string;
  value: string;
};

type CydMetadataFormProps = {
  title?: string;
  availableMetadataTypes: Array<string>;
  selectedMetadataTypeAndValues: Array<CydMetadataFormItem>;
  onChange: (metadataItem: Array<CydMetadataFormItem>) => void;
};

const DEFAULT_METADATA_AND_VALUE: CydMetadataFormItem = {
  metadataType: '',
  value: ''
};

export const CydMetadataForm = ({
  title = '',
  availableMetadataTypes,
  selectedMetadataTypeAndValues,
  onChange
}: CydMetadataFormProps) => {
  const selectedRows =
    selectedMetadataTypeAndValues.length === 0 &&
    availableMetadataTypes.length > selectedMetadataTypeAndValues.length
      ? [DEFAULT_METADATA_AND_VALUE]
      : selectedMetadataTypeAndValues;

  const hasMoreMetadataType =
    availableMetadataTypes.length > selectedRows.length;

  const remainingMetadata = availableMetadataTypes.filter(
    (ak) => !selectedRows.find((skv) => skv.metadataType === ak)
  );

  const handleChange = (
    newMetadataItem: CydMetadataFormItem,
    rowIndex: number
  ) => {
    onChange(
      selectedRows.map((currentMetadataFormItem, currentRowIndex) => {
        if (rowIndex === currentRowIndex) {
          return newMetadataItem;
        }
        return currentMetadataFormItem;
      })
    );
  };

  return (
    <div>
      {selectedRows.map((metadataWithValue, rowIndex) => (
        <Grid
          container
          key={`${metadataWithValue.metadataType}-${rowIndex}`}
          spacing={2}
          alignItems="flex-start"
        >
          <Grid item xs={4} justifyContent="flex-start">
            <CydSimpleSelect
              // make metadata type field required if the value field is not empty
              // This is to prevent backend throw error when sending empty metadata type
              required={metadataWithValue.value?.trim().length > 0}
              generateOptionLabel={(v) => v}
              selectedOption={metadataWithValue.metadataType}
              onChange={(newMetadata) =>
                handleChange(
                  { ...metadataWithValue, metadataType: newMetadata },
                  rowIndex
                )
              }
              availableOptions={[
                metadataWithValue.metadataType,
                ...remainingMetadata
              ]}
              label={`${title} Type `}
            />
          </Grid>
          <Grid item xs>
            <CydAutoExpandTextField
              fullWidth
              label="Value"
              value={metadataWithValue.value}
              onChange={(value) =>
                handleChange({ ...metadataWithValue, value }, rowIndex)
              }
            />
          </Grid>
          <Grid item>
            <CydIconButton
              icon="delete"
              label="Delete"
              onClick={() => {
                onChange(
                  selectedRows.filter(
                    (kv) => kv.metadataType !== metadataWithValue.metadataType
                  )
                );
              }}
            />
          </Grid>
        </Grid>
      ))}
      {hasMoreMetadataType && (
        <CydButton
          variant="text"
          startIcon="add"
          onMouseDown={() =>
            onChange([...selectedRows, DEFAULT_METADATA_AND_VALUE])
          }
        >
          Add {title}
        </CydButton>
      )}
    </div>
  );
};
