import moment from 'moment';

/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
export const dataSignificanceVerticalPositions = (
  dataSigMap,
  timelineItemHeight,
  timelineGraphHeight
) =>
  Object.keys(dataSigMap).reduce(
    (acc, key, index, dataSignificances) => ({
      ...acc,
      [dataSigMap[key]]:
        timelineItemHeight +
        index * (timelineGraphHeight / dataSignificances.length)
    }),
    {}
  );

const defaultStixPrecedence = 1;

export const parseActivityTimelineData: (
  activities: any,
  stixData: any,
  domain?: never[]
) => {
  parsedData: any[];
  minX: number;
  maxX: number;
  scaleExtent: [number, number];
} = (activities, stixData, domain = []) => {
  if (!activities.length) {
    return {
      parsedData: [],
      minX: Date.now() - 60 * 60 * 1000,
      maxX: Date.now() + 60 * 60 * 1000,
      scaleExtent: [1, 1]
    };
  }

  const calculateJitter = ({ uuid, sigprecedence, id }) => {
    //if there is no uuid we have stix data
    const hashUuid = uuid
      ? parseInt(`0x${uuid.replace(/-/g, '').substr(0, 8)}`)
      : parseInt(`0x${id.replace(/-/g, '').substr(13, 8)}`);
    return +sigprecedence + hashUuid / 0xffffffff - 0.5;
  };

  const parsedStixData = stixData?.[0]
    ? stixData
        .filter(({ id }) => id.startsWith('observed-data--'))
        .map((data) => ({
          ...data,
          created: data.first_observed,
          sigprecedence: defaultStixPrecedence,
          isStix: true
        }))
    : [];

  const parsedData = [...activities, ...parsedStixData]
    .map((activity) => {
      return {
        ...activity,
        created: moment(activity.created).valueOf(),
        positionY: calculateJitter(activity)
      };
    })
    .sort((a, b) => a.created - b.created);

  const x0 = Date.parse(domain[0]) || parsedData[0].created;
  const x1 = Date.parse(domain[1]) || Date.now();
  const graphPeriod = x1 - x0;
  const minX = x0 - graphPeriod / 15;
  const oneHourZoomScaled = Math.max((x1 - minX) / 60000, minX / x1);
  const allTimeZoomScaled = (x1 - minX) / Date.now();

  return {
    parsedData: parsedData,
    minX,
    maxX: x1,
    scaleExtent: [allTimeZoomScaled, oneHourZoomScaled]
  };
};
