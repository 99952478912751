/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Chip, { ChipProps } from '@mui/material/Chip';
import { css } from '@emotion/react';
import { forwardRef, RefObject } from 'react';

/** @jsxImportSource @emotion/react */

type CydTagDisplayProps = ChipProps & {
  isShowAll?: boolean;
};

export const CydTagChip = forwardRef(
  (
    { isShowAll, ...props }: CydTagDisplayProps,
    ref: RefObject<HTMLDivElement>
  ) => (
    <Chip
      clickable={false}
      ref={ref}
      css={css`
        margin: 5px;
        justify-content: left;
        & > * {
          display: inline-block;
          font-size: 11px;
          white-space: ${isShowAll ? 'normal' : 'nowrap'};
          overflow: ${isShowAll ? 'visible' : 'hidden'};
          text-overflow: ${isShowAll ? 'visible' : 'ellipsis'};
          max-width: ${isShowAll ? 'none' : '130px'};
          z-index: 5;
        }
      `}
      size="small"
      {...props}
    />
  )
);
