/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useAutoEffect } from './ReduxHooks';
import { fetchAllResources } from 'states/resource/actions';
import {
  resourcesListSelector,
  resourcesListSingleSelector
} from 'states/resource/selector';

export const useResourceList = () => {
  return useAutoEffect({
    ac: fetchAllResources,
    selector: resourcesListSelector
  });
};

export const useSingleResource = (resourceName: string) => {
  return useAutoEffect({
    ac: fetchAllResources,
    selector: resourcesListSingleSelector,
    selectorData: resourceName
  });
};
