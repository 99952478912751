/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/ArrowDownward';
import { IDetailedCaseData } from 'interface/CaseData.interface';
import { Tooltip } from '@mui/material';
import { useCaseActivityStyles } from './styles';
import { ConfirmDialog } from 'components/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { createDeleteData, createDownloadData } from 'states/data/actions';
import {
  useCommentDrawerState,
  useCommentModeState,
  useManipulatingCaseItemUuidState
} from 'hooks/CaseActivitiesUIHooks';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { ActivityItem } from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';

export interface ICaseActivityActionsProps {
  caseActivity: IDetailedCaseData;
  caseUuid?: string;
}

function getStubsToDelete(caseActivity: ActivityItem): Array<string> {
  const childrenStubs =
    caseActivity.children?.flatMap((v) => getStubsToDelete(v)) || [];

  return [...childrenStubs, caseActivity.dataStubUuid];
}

export const CaseActivityActions: React.FC<ICaseActivityActionsProps> = ({
  caseActivity,
  caseUuid
}) => {
  const dispatch = useDispatch();

  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [, setMode] = useCommentModeState();
  const [, setCaseUuid] = useManipulatingCaseItemUuidState();
  const [, setIsOpen] = useCommentDrawerState();

  const handleDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleDelete = () => {
    const stubs = getStubsToDelete(caseActivity as any);
    stubs.forEach((v) => {
      dispatch(
        createDeleteData({
          dataStubUuid: v,
          caseUuid: caseActivity.caseuuid
        })
      );
    });
    setDeleteDialog(false);
  };

  const handleDownload = () => {
    dispatch(createDownloadData(caseActivity));
  };

  const handleEdit = React.useCallback(() => {
    setMode('edit');
    setCaseUuid(caseActivity.uuid);
    setIsOpen(true);
  }, [caseActivity, setMode, setCaseUuid, setIsOpen]);

  const handleReply = React.useCallback(() => {
    setMode('reply');
    setCaseUuid(caseActivity.dataStubUuid);
    setIsOpen(true);
  }, [caseActivity, setMode, setCaseUuid, setIsOpen]);

  const { deletable, editable, filename } = caseActivity;

  const { button } = useCaseActivityStyles({});

  return (
    <CydButtonStack>
      {filename && (
        <Tooltip title="Download">
          <IconButton onClick={handleDownload} className={button}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )}

      {(deletable || deletable === undefined) && (
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteDialog}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}

      {editable && (
        <Tooltip title="Edit">
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}

      {!caseActivity.audit && (
        <Tooltip title="Reply">
          <IconButton onClick={handleReply}>
            <ReplyIcon />
          </IconButton>
        </Tooltip>
      )}

      {deleteDialog && (
        <ConfirmDialog
          open={deleteDialog}
          title="Do you want to delete this item and all its replies?"
          onClose={handleDeleteDialog}
          onConfirm={handleDelete}
        />
      )}
    </CydButtonStack>
  );
};
