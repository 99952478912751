// Check all environment variables exist
export const REQUIRED_ENV_VARS = [
  'REACT_APP_DEBUG_MODE',
  'REACT_APP_USE_NEW_ACTIVITY_THREAD',
  'REACT_APP_USE_NEW_CASE_PLAYBOOKS',
  'REACT_APP_USE_NEW_CASE_LIST',
  'REACT_APP_USE_CONNECTOR_SETTINGS_PAGE',
  'REACT_APP_USE_CACAO_PLAYBOOKS',
  'REACT_APP_USE_QUICK_STATUS_TRANSITION',
  'REACT_APP_USE_LAZY_LOADING_CASE_SELECTOR',
  'REACT_APP_USE_BULK_IOC_UPLOAD'
] as const;

export function checkEnvVarsExist() {
  REQUIRED_ENV_VARS.forEach((v) => {
    if (process.env[v] === undefined) {
      console.warn(`Required env var: ${v} did not exist`);
    }
  });
}

// Converts the array in to a union list of strings :)
export type POSSIBLE_FEATURE_FLAGS =
  (typeof REQUIRED_ENV_VARS)[keyof typeof REQUIRED_ENV_VARS & number];

export function isToggleEnabled(flagName: POSSIBLE_FEATURE_FLAGS): boolean {
  const localStorageValue = localStorage.getItem(flagName);
  if (localStorageValue) {
    return localStorageValue === 'true';
  }

  const flagValue = process.env[flagName];
  if (flagValue === undefined) {
    console.warn(`${flagName} does not exist, returning false`);
    return false;
  }

  return JSON.parse(flagValue) as boolean;
}
