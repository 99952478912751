/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { iocCaseTableHeaders } from 'components/tables/CaseIOCsTable/headers';
import { ICaseStix } from 'interface/Case.interface';
import moment from 'moment-timezone';
import { dateFormatRfc3339Local } from './DateUtils';
import { StringUtils } from 'utils/StringUtils';

// fetchIOCsfromCases fetches IOCs from cases
export const fetchIOCsfromCases = (detailedStixData: ICaseStix[]) => {
  return detailedStixData.filter(
    (object: ICaseStix) =>
      object.type === 'indicator' &&
      object.indicator_types?.includes('malicious-activity')
  );
};

// IStixCsvRow
export interface IStixCsvRow {
  id: string;
  name?: string;
  description?: string;
  patternField: string;
  patternValue: string;
  createdDateString: string;
}

// iocCaseTableHeaders
export const iocCsvFileHeaders = iocCaseTableHeaders.map(
  (header) => header.label
);

// getIocRowData converts an IOC into a CSV row for display and download
export const getIocRowData = (
  iocCase: ICaseStix,
  timezone: string
): IStixCsvRow => {
  const { name, description, pattern, created, id } = iocCase;
  const createdDate = moment.tz(created, timezone);
  const createdDateString = createdDate.format(dateFormatRfc3339Local);

  // by default, type of pattern is "Pattern" and value is the whole string
  let patternField = 'Pattern';
  let patternValue = pattern ? pattern : '';
  // if we have a simple `type = value` pattern, parse it out
  const simplePatternRe = /^\[[^\s=]+\s?=\s?'[^']+'\]$/;
  const andPatternRe =
    /^\[[^\s=]+\s?=\s?'[^']+'\s+AND\s+[^\s=]+\s?=\s?'[^']+'\]$/;
  if (typeof pattern == 'string') {
    if (simplePatternRe.test(pattern)) {
      // simple pattern
      patternField = pattern
        ?.split('=')[0]
        .replaceAll('[', '')
        .replaceAll(' ', '');
      patternValue = pattern
        ?.split('=')[1]
        .replaceAll(']', '')
        .replaceAll(' ', '')
        .replaceAll("'", '');
    } else if (andPatternRe.test(pattern)) {
      const patterns = pattern.split('AND');
      if (patterns.length > 0) {
        patternField = patterns[0].split(':')[0].replaceAll('[', '');
        patternValue = pattern;
      }
    }
  }
  return {
    id,
    name,
    description,
    patternField,
    patternValue: StringUtils.defang(patternValue),
    createdDateString
  };
};

export const getIOCDownloadHeaders = (): string[] => {
  return ['Id', 'Name', 'Description', 'Type', 'Value', 'Created'];
};

// transformIOCtoCsvFileData transforms IOC data to CSV file data
export const transformIOCtoCsvFileData = (iocs: IStixCsvRow[]): string[][] => {
  const csvData: string[][] = [];
  iocs &&
    iocs.forEach((element: IStixCsvRow) => {
      csvData.push([
        element.id,
        element.name ? element.name : '',
        element.description ? element.description : '',
        element.patternField,
        element.patternValue,
        element.createdDateString
      ]);
    });
  return csvData;
};
