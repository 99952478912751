/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { AtcPlaybook } from 'interface/Playbook.interface';
import { ListItemButton, Typography } from '@mui/material';

import { usePlaybooksPagePlaybooksSidebarStyles } from './styles';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { CacaoBadge } from 'components/_playbooks/CacaoBadge';

import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';
interface IPlaybooksPagePlaybooksSidebarItemProps {
  playbook: AtcPlaybook | OasisCacaoPlaybook;
  onPlaybookSelect: (playbook?: AtcPlaybook | OasisCacaoPlaybook) => void;
  selected: boolean;
}

export const PlaybooksPagePlaybooksSidebarItem: React.FC<
  IPlaybooksPagePlaybooksSidebarItemProps
> = ({ playbook, onPlaybookSelect, selected }) => {
  const { listItem, listItemHeading } =
    usePlaybooksPagePlaybooksSidebarStyles();

  return (
    <ListItemButton
      selected={selected}
      className={listItem}
      onClick={() => onPlaybookSelect(playbook)}
    >
      <Typography
        className={listItemHeading}
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <span>{playbook.name}</span>
        <span>{'workflow' in playbook && <CacaoBadge />}</span>
      </Typography>
    </ListItemButton>
  );
};
