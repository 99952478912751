/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Paper, PopperProps } from '@mui/material';
import React, { useEffect, useRef } from 'react';

export const CydSearchableSingleSelectPopper = ({
  children,
  anchorEl,
  open
}: PopperProps & {
  anchorEl: HTMLElement;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (open && ref.current) {
      document.body.appendChild(ref.current);
    }
  }, [anchorEl, open]);

  if (!anchorEl) {
    return null;
  }

  const anchorClientRect = anchorEl.getBoundingClientRect();
  const winHeight = window.innerHeight;
  const isSpaceBelow =
    winHeight - anchorClientRect.bottom > anchorClientRect.top;

  return (
    <div>
      <div
        ref={ref}
        data-testid="single-select-popper"
        style={{
          position: 'absolute',
          zIndex: 1500,
          top: isSpaceBelow ? anchorClientRect.bottom : 'auto',
          bottom: isSpaceBelow ? 'auto' : winHeight - anchorClientRect.top,
          left: anchorClientRect.left,
          width: anchorClientRect.width,
          display: open ? 'block' : 'none'
        }}
      >
        <Paper>{children}</Paper>
      </div>
    </div>
  );
};
