/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import { Base64Decode } from 'utils/StringUtils';
import { CASE_FILTER, USER_PROPERTY } from '../../constants';
import { FilterPreset } from 'interface/CasesListUI';

export const allUserPropsSelector = ({
  userPropsStore: { userProps }
}: IStore) => {
  return userProps;
};

export const userPropsStatusSelector = ({
  userPropsStore: { hasLoaded, isLoading }
}: IStore) => hasLoaded || isLoading;

export const userPropsHasLoadedSelector = ({
  userPropsStore: { hasLoaded }
}: IStore) => hasLoaded;

const userPropSelector = createSelector(
  allUserPropsSelector,
  (_, propName) => propName,
  (userProps, propName) =>
    userProps.find(({ name }) => name === propName)?.value
);

export const transformedUserPropSelector = createSelector(
  userPropSelector,
  (_, __, defaultVal = '') => defaultVal,
  (userProp, defaultVal) =>
    userProp
      ? JSON.parse(Base64Decode(userProp))?.key || defaultVal
      : defaultVal
);

export const selectUserFilters = (state: IStore): FilterPreset[] =>
  transformedUserPropSelector(
    state,
    CASE_FILTER.CASE_LIST_VIEW_FILTERS,
    undefined
  );

export const selectDefaultUserFilterName = (state: IStore): string =>
  transformedUserPropSelector(state, CASE_FILTER.CASE_LIST_FILTER_DEFAULT, '');

export const selectRowsPerPage = (state: IStore) => {
  const rowsPerPageValue = transformedUserPropSelector(
    state,
    USER_PROPERTY.ROWS_PER_PAGE,
    '10'
  );
  return !isNaN(rowsPerPageValue) ? parseInt(rowsPerPageValue) : 10;
};

export const selectCommentHeightStyleValue = (state: IStore) => {
  const heightValue = transformedUserPropSelector(
    state,
    USER_PROPERTY.MAX_COMMENT_HEIGHT,
    '300'
  );
  return !isNaN(heightValue) ? parseInt(heightValue) : 300;
};

export const selectUserDefaultFilter = createSelector(
  [selectUserFilters, selectDefaultUserFilterName],
  (filters, defaultFilterName) => {
    if (filters != null && defaultFilterName !== '') {
      const result: FilterPreset | undefined = filters.find(
        (v) => v.name === defaultFilterName
      );
      return result;
    }
    return undefined;
  }
);
