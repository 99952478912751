import { checkIsColorValid } from './StringUtils';

export const generateColor = (
  coloursToGen,
  colourIndex,
  saturation = 100,
  luminosity = 50
) => {
  const colorIncrement = 360 / coloursToGen;
  const hue = colorIncrement * colourIndex;
  return `hsl(${hue},${saturation}%,${luminosity}%)`;
};

export function returnValidColor(
  color: string | undefined,
  nPossibleColors: number,
  thisColorIndex: number
): string {
  if (checkIsColorValid(color)) {
    return color as string;
  } else {
    return generateColor(nPossibleColors, thisColorIndex);
  }
}

// Determine contrast
//https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors

function luminance(r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

type RgbTriple = [number, number, number];
export function determineContrast(rgb1: RgbTriple, rgb2: RgbTriple): number {
  var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function determineContrastForHex(hex1: string, hex2: string): number {
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  return determineContrast(rgb1, rgb2);
}

// Hex to rgb
//https://stackoverflow.com/a/39077686/1068446
function hexToRgb(hex: string): RgbTriple {
  try {
    //@ts-ignore
    const result = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

    if (!result) {
      throw new Error();
    }

    return result as RgbTriple;
  } catch (err) {
    throw new Error(`Invalid hex: ${hex}`);
  }
}
