/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { CYDARM_BASE_API } from '../constants';
import { CydApi_QuickSearchResponseItem, apiQuickSearch } from 'services';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const rtkqQuickSearchApi = createApi({
  reducerPath: 'rtk-quick-search',
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),

  tagTypes: ['quick-search'],
  endpoints: (builder) => {
    return {
      quickSearch: builder.query<
        Array<CydApi_QuickSearchResponseItem>,
        { searchString: string }
      >({
        queryFn: async (args) => {
          const result = await apiQuickSearch(args);
          return {
            data: result.json
          };
        }
      })
    };
  }
});

export const useQuickSearchResults = (query: string) => {
  return rtkqQuickSearchApi.endpoints.quickSearch.useQuery(
    { searchString: query },
    { refetchOnMountOrArgChange: true }
  );
};
