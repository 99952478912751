/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  Configuration,
  EventTriggerApi,
  type EventTriggerResource
} from 'generatedApi';
import {
  CydApi_EventTrigger,
  CydApi_EventTriggerRequestFields
} from 'services/ConnectorsService';
import { AbstractOpenApiService } from './AbstractOpenApiService';
import { ObjectUtils } from 'utils/ObjectUtils';

class EventTriggerService extends AbstractOpenApiService<
  CydApi_EventTriggerRequestFields,
  EventTriggerResource
> {
  constructor() {
    super('eventTrigger');
  }

  protected createApi(config: Configuration): EventTriggerApi {
    return new EventTriggerApi(config);
  }

  public transformFieldsToOpenApiResource(
    data: CydApi_EventTriggerRequestFields
  ): EventTriggerResource {
    return ObjectUtils.encodeTemplateStringsToBase64({
      attributes: {
        name: data.name,
        description: data.description,
        active: data.active,
        // condition: data.condition,
        event_type: data.event_type,
        operation_arguments: data.operation_arguments
      },
      relationships: {
        connector: {
          data: {
            id: data.connector_id
          }
        },
        operation_type: {
          data: {
            id: data.operation_type_id
          }
        }
      }
    });
  }

  public transformOpenApiResourceToFields(
    resource: EventTriggerResource
  ): CydApi_EventTrigger {
    const attributes = resource?.attributes;
    const relationships = resource?.relationships;
    return ObjectUtils.decodeTemplatesBase64ToStrings({
      uuid: resource?.id,
      connector_id: relationships?.connector?.data?.id,
      operation_type_id: relationships?.operation_type?.data?.id,
      event_type: attributes?.event_type,
      operation_arguments: attributes?.operation_arguments,
      name: attributes?.name,
      description: attributes?.description,
      active: attributes?.active,
      condition: attributes?.condition
    } as CydApi_EventTrigger);
  }
}

export const eventTriggerService = new EventTriggerService();
