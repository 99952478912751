/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';
import { CydSimpleTable } from 'components/_dataDisplay/CydSimpleTable/CydSimpleTable';
import { CydButtonModal } from 'components/_dialogs/CydButtonModal/CydButtonModal';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { GenericPlaybookStatusUpdate } from 'components/_playbooks/CacaoPlaybookRunningInstance';
import React from 'react';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo,
  GenericVariableInfo
} from '../../type';
import { getVariablesAsRowsAndColumns } from '../../utils/generalNodeStatusUtils';
import { OutputVariablesSettingForm } from './OutputVariablesSettingForm';

export type OutputArgsDisplayProps = {
  node: GenericNode;
  onUpdatePlaybookInstance: (data: GenericPlaybookStatusUpdate) => void;
  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo | null;
};

export function getResolvedArgs(
  outArgs: Array<string>,
  playbookResolutionInfo: GenericPlaybookResolutionInfo,
  playbook: GenericPlaybook
): Array<{
  value: string | null;
  info: GenericVariableInfo;
}> {
  if (!outArgs) {
    return [];
  }

  const resolvedOutArgs = outArgs.map((v) => {
    const value = playbookResolutionInfo.resolvedVariables[v];
    const info = playbook.variablesMap[v];

    if (!info) {
      return {
        value: '(Error)',
        info: {
          key: v,
          name: 'Error',
          type: 'string'
        }
      };
    }

    return {
      value: (value ? value.value : null) as string | null,
      info: {
        ...info,
        key: v
      }
    };
  });

  return resolvedOutArgs;
}

export const OutputArgsDisplay = (props: OutputArgsDisplayProps) => {
  const {
    node,
    onUpdatePlaybookInstance: onUpdateNode,

    playbook,
    playbookResolutionInfo
  } = props;

  if (node.nodeType.type !== 'action' && node.nodeType.type !== 'start') {
    // Not handling this right now.
    return null;
  }

  if (!playbookResolutionInfo) {
    return null;
  }

  if (!onUpdateNode) {
    return null;
  }

  const outArgs = node.nodeType.data.outArgIds || [];

  const resolvedOutArgs = getResolvedArgs(
    outArgs,
    playbookResolutionInfo,
    playbook
  );

  const rowsAndColumns = getVariablesAsRowsAndColumns(
    playbook.variablesMap,
    playbookResolutionInfo.resolvedVariables,
    outArgs,
    true,
    '(not yet set)'
  );

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Output Variables
      </Typography>
      <CydSimpleTable
        rows={rowsAndColumns.rows}
        columns={rowsAndColumns.columns}
        uniquenessId={'label'}
      />

      <>
        {outArgs.length > 0 ? (
          <CydButtonStack justifyContent="flex-end">
            <CydButtonModal
              label="Fill variables"
              ButtonProps={{
                variant: 'outlined'
              }}
              renderContent={(closeModalFn) => {
                return (
                  <OutputVariablesSettingForm
                    onSubmitVariables={onUpdateNode}
                    resolvedOutArgs={resolvedOutArgs}
                    closeModalFn={closeModalFn}
                  />
                );
              }}
            />
          </CydButtonStack>
        ) : (
          <>(No output variables for this step)</>
        )}
      </>
    </div>
  );
};
