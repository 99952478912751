/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  InputBaseProps,
  TextField
} from '@mui/material';
import React, { useMemo } from 'react';
import { CydSearchableSingleSelectPopper } from 'components/_formElements/CydSearchableSingleSelect/CydSearchableSingleSelectPopper';

export type CydSearchableSingleSelectProps<T> = {
  onChange?: (newValue: T | null, newValueString: string | null) => void;
  availableOptions: Array<T>;

  /**
   * @deprecated - use selectedOptionString or defaultSelectedOptionString
   * */
  selectedOption?: T | null;

  /**
   * For controlled usage
   */
  selectedOptionString?: string | null;

  /**
   * For uncontrolled usage
   */
  defaultSelectedOptionString?: string | null;

  generateOptionLabel: (value: T) => string;
  generateOptionValue?: (value: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  label: string;
  className?: string;
  size?: InputBaseProps['size'];
  disabled?: boolean;
  disablePortal?: boolean;
  autoFocus?: boolean;
  name?: string;
};

/**
 * !Important note!
 *
 * Unlike CydSimpleSelect and CydMultiSelect this component does not support seperate generateOptionLabel and generateOptionValue
 * This is due to a constraint on the base Autocomplete component, see: https://github.com/mui/material-ui/issues/23708
 * So sucks for you if you want to do uncontrolled components within a form - you will need to map the labels to  and from uuids yourself
 *
 * This component will not support async searchable selection, we will cross that bridge when we need it.
 * @param props
 * @returns
 */

export const CydSearchableSingleSelect = <T,>(
  props: CydSearchableSingleSelectProps<T>
) => {
  const {
    onChange,
    availableOptions,
    generateOptionLabel,
    renderOption,
    label,
    className,
    size,
    disabled,
    disablePortal = true,
    autoFocus,
    selectedOptionString,
    defaultSelectedOptionString,
    selectedOption,
    name,
    generateOptionValue
  } = props;

  const valueFnToUse = generateOptionValue ?? generateOptionLabel;

  const [labelValueMap, valueLabelMap, optionsMap] = useMemo(() => {
    const map1 = new Map<string, string>();
    const map2 = new Map<string, string>();
    const map3 = new Map<string, T>();

    availableOptions.forEach((v) => {
      const label = generateOptionLabel(v);
      const value = valueFnToUse(v);

      // There's got to be a more performant way to do this surely.
      map1.set(label, value);
      map2.set(value, label);
      map3.set(label, v);
    });

    return [map1, map2, map3];
  }, [availableOptions, generateOptionLabel, valueFnToUse]);

  const [selectedValue, defaultValue] = useMemo(() => {
    let selectedValue: undefined | null | T;
    if (selectedOptionString === undefined) {
      // This means the component will be uncontrolled
      selectedValue = undefined;
    } else {
      selectedValue = selectedOptionString
        ? optionsMap.get(selectedOptionString)
        : null;
    }

    if (!defaultSelectedOptionString) {
      return [selectedValue, null];
    }

    const defaultValueAsLabel = valueLabelMap.get(defaultSelectedOptionString);

    if (!defaultValueAsLabel) {
      return [selectedValue, null];
    }

    const defaultValueAsValue = optionsMap.get(defaultValueAsLabel);

    return [selectedValue, defaultValueAsValue];
  }, [
    optionsMap,
    defaultSelectedOptionString,
    selectedOptionString,
    valueLabelMap
  ]);

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      disablePortal={disablePortal}
      PopperComponent={CydSearchableSingleSelectPopper}
      options={availableOptions}
      value={selectedValue ?? selectedOption}
      defaultValue={defaultValue}
      getOptionLabel={generateOptionLabel}
      renderOption={renderOption}
      size={size}
      onChange={(e, v) => {
        if (onChange) {
          if (v === null) {
            onChange(null, null);
          } else {
            const typedV = v as T;
            onChange(typedV, valueFnToUse(typedV));
          }
        }
      }}
      renderInput={(params) => (
        <>
          <TextField {...params} label={label} autoFocus={autoFocus} />
          {name && (
            <input
              type="hidden"
              value={labelValueMap.get(params.inputProps.value as string)}
              name={name}
            />
          )}
        </>
      )}
    />
  );
};
