/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  AtcPlaybook,
  PlaybookAction,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { Typography, Chip, IconButton } from '@mui/material';
import { usePlaybooksPageSinglePlaybookActionStyle } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { usePlaybookActionsTableStyles } from 'components/tables/PlaybookActionsTable/styles';
import { Markdown } from 'components/Markdown';
import { PlayBookActionsPopover } from 'components/PlaybookActionsPopover';

interface PlaybooksPageActionPanelsProps {
  playbook?: AtcPlaybook;
  playbookAction?: PlaybookAction;
  onRemoveAction: (uuid: string, position: number) => void;
  onUpdatePosition: (
    uuid: string,
    position: number,
    newPosition: number
  ) => void;
  searchParams?: string;
}

export const PlaybooksPageActionPanels: React.FC<
  PlaybooksPageActionPanelsProps
> = ({
  playbook,
  playbookAction,
  onRemoveAction,
  onUpdatePosition,
  searchParams
}) => {
  const { tagChipLabel, tagChip } = usePlaybookActionsTableStyles();
  const [shouldShowActionForm, setShouldShowActionForm] = React.useState(false);
  const [editAction, setEditAction] = React.useState<PlaybookAction>();

  const handleShowEditActionForm = (value: PlaybookAction) => {
    setEditAction(value);
    setShouldShowActionForm(true);
  };

  const handleRequestDeleteAction = (uuid: string, position: number) => {
    onRemoveAction(uuid, position);
  };

  const handleMoveUpPosition = (
    uuid: string,
    position: number,
    validPosition: boolean
  ) => {
    validPosition && onUpdatePosition(uuid, position, position - 1);
  };

  const handleMoveDownPosition = (
    uuid: string,
    position: number,
    validPosition: boolean
  ) => {
    validPosition && onUpdatePosition(uuid, position, position + 1);
  };

  const {
    container,
    subTitle,
    heading,
    descriptionLabel,
    headingIcon,
    actionContainer,
    playbookTitle,
    actionDescription
  } = usePlaybooksPageSinglePlaybookActionStyle();

  const searchAction =
    playbook &&
    searchParams &&
    playbook.actions &&
    playbook.actions.filter(
      (el) =>
        el.name?.includes(searchParams) ||
        el.description?.includes(searchParams)
    );
  const filteredAction =
    (playbook && searchParams && searchAction) || playbook?.actions;

  return (
    <div className={container}>
      {playbook &&
        filteredAction &&
        filteredAction.map((action) => (
          <React.Fragment key={action.uuid}>
            <div className={actionContainer} data-testid="action-container">
              <div className={heading}>
                <Typography variant="h2" className={subTitle}>
                  {action.name}
                </Typography>
                <div className={headingIcon}>
                  <IconButton onClick={() => handleShowEditActionForm(action)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleRequestDeleteAction(
                        action.uuid ?? '',
                        action.position
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleMoveUpPosition(
                        action.uuid ?? '',
                        action.position,
                        action.position !== 1
                      )
                    }
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Position Down"
                    onClick={() =>
                      handleMoveDownPosition(
                        action.uuid ?? '',
                        action.position,
                        action.position !== playbook.actions.length
                      )
                    }
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </div>
              </div>

              <div>
                {action.tags?.map((tagName) => {
                  return (
                    <Chip
                      key={tagName}
                      label={tagName}
                      color="primary"
                      className={tagChip}
                      classes={{ label: tagChipLabel }}
                    ></Chip>
                  );
                })}
              </div>

              <div className={actionDescription}>
                <Typography className={descriptionLabel}>
                  {'Description:'}
                </Typography>
                <div className={actionDescription}>
                  <Markdown>{action.description || 'NA'}</Markdown>
                </div>
              </div>
            </div>
            <div className={playbookTitle}></div>
          </React.Fragment>
        ))}

      {shouldShowActionForm && (
        <PlayBookActionsPopover
          onClose={() => {
            setShouldShowActionForm(false);
          }}
          playbookAction={editAction as PlaybookActionInstance}
        />
      )}
    </div>
  );
};
