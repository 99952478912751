/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IStore } from 'states/store.interface';
import { createStructuredSelector, createSelector } from 'reselect';

const isAddedPlaybookToCaseSuccessSelector = ({
  casePlaybooks: { isAddedPlaybookToCaseSuccess }
}: IStore) => isAddedPlaybookToCaseSuccess;

const isAddingPlaybookToCaseErrorSelector = ({
  casePlaybooks: { isAddingPlaybookToCaseError }
}: IStore) => isAddingPlaybookToCaseError;

const isRemovedPlaybookFromCaseSuccessSelector = ({
  casePlaybooks: { isRemovedPlaybookFromCaseSuccess }
}: IStore) => isRemovedPlaybookFromCaseSuccess;

const isRemovingPlaybookFromCaseErrorSelector = ({
  casePlaybooks: { isRemovingPlaybookFromCaseError }
}: IStore) => isRemovingPlaybookFromCaseError;

export const playbookStatusSelector = createStructuredSelector({
  isAddedPlaybookToCaseSuccess: isAddedPlaybookToCaseSuccessSelector,
  isAddingPlaybookToCaseError: isAddingPlaybookToCaseErrorSelector,
  isRemovedPlaybookFromCaseSuccess: isRemovedPlaybookFromCaseSuccessSelector,
  isRemovingPlaybookFromCaseError: isRemovingPlaybookFromCaseErrorSelector
});

const playbookSelector = ({ casePlaybooks: { casePlaybooks } }: IStore) =>
  casePlaybooks;

export const casePlaybooksSelector = createSelector(
  playbookSelector,
  (_, caseUuid) => caseUuid,
  (playbooks, caseUuid) => playbooks[caseUuid]
);
