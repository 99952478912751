/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useRef, useState, useEffect, FC, memo } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useCaseActivityStyles } from './styles';
import { IDetailedCaseData } from 'interface/CaseData.interface';
import { CaseActivityActions } from './CaseActivityActions';
import { shallowCompareCaseData } from 'utils/CaseDataUtils';
import AclDialog from 'components/AclDialog';
import { useDataAcls } from 'hooks/AclHooks';
import { useHSLColour } from 'hooks/HSLColourHooks';
import { useIsCommentHighlighted } from 'hooks/CaseActivitiesUIHooks';
import { useFilteredDataSignificances } from 'hooks/CaseDataHooks';
import { CaseActivityContentWrapper } from './CaseActivityContentWrapper';
import { CaseActivityInfo } from './CaseActivityInfo';
import { CaseActivityArrow } from './CaseActivityArrow';

interface ICaseActivityProps {
  caseActivity: IDetailedCaseData;
  containerRef: any;
  previewImage?: string;
  massToggle: boolean;
  caseAcl: string;
}

export const CaseActivity: FC<ICaseActivityProps> = memo(
  ({ caseActivity, containerRef, massToggle, caseAcl }) => {
    const [shouldShowFullView, setShouldShowFullView] = useState('partial');

    const {
      significance,
      children,
      audit,
      encryptionkey,
      filename,
      sigprecedence,
      dataStubUuid,
      caseuuid
    } = caseActivity;

    // TODO: Add logic for permissions to edit
    const disabled: boolean = true;
    const { data: acls } = useDataAcls();
    const { data: dataSignificances } = useFilteredDataSignificances();
    const [isAclDialogOpened, setIsAclDialogOpened] = useState(false);
    const highlighted = useIsCommentHighlighted(dataStubUuid);

    const currentAcl =
      caseActivity && acls.find(({ uuid }) => uuid === caseActivity.acl);

    const activityRef = useRef(null);

    const handleOpenAclDialog = () => setIsAclDialogOpened(true);
    const handleCloseAclDialog = () => setIsAclDialogOpened(false);

    const color = useHSLColour(sigprecedence);

    useEffect(() => {
      if (highlighted) {
        // I've commented this out because we've got the auto scrolling behavour up at
        // CaseViewPageActivities.
        // But I'm leaving this here, just to be aware of some apparent highlighting behaviour
        // scrollToElement(activityRef.current, containerRef.current);
      }
    }, [highlighted, containerRef]);

    const {
      container,
      containerMinimal,
      containerHeading,
      containerHeadingRight,
      childrenContainer,
      infoIcon,
      containerHeadingLeft
    } = useCaseActivityStyles({
      color,
      highlighted: highlighted,
      partial: shouldShowFullView === 'partial'
    });

    return (
      <>
        <div
          data-testid="case-activity"
          className={!audit ? container : containerMinimal}
          ref={activityRef}
        >
          <div className={containerHeading}>
            <div className={containerHeadingLeft}>
              <CaseActivityArrow
                {...{
                  caseActivity,
                  setShouldShowFullView,
                  shouldShowFullView,
                  massToggle,
                  caseAcl
                }}
              />
              <CaseActivityInfo
                {...{
                  caseActivity,
                  caseAcl
                }}
              />
            </div>
            <div className={containerHeadingRight}>
              <CaseActivityActions
                caseActivity={caseActivity}
                caseUuid={caseuuid}
              />
              <AclDialog
                open={isAclDialogOpened}
                onClose={handleCloseAclDialog}
                acl={currentAcl}
                significance={significance}
                encryptionkey={encryptionkey}
                dataSignificances={dataSignificances}
                disabled={disabled}
                filename={filename}
              />
            </div>
            <Tooltip title="Access Control">
              <InfoIcon onClick={handleOpenAclDialog} className={infoIcon} />
            </Tooltip>
          </div>
          <CaseActivityContentWrapper
            {...{ caseActivity, shouldShowFullView, setShouldShowFullView }}
          />
        </div>
        {children && children.length > 0 && (
          <div className={childrenContainer}>
            {children.map((child) => (
              <CaseActivity
                key={child.uuid}
                caseActivity={child}
                {...{ containerRef, massToggle, caseAcl }}
              />
            ))}
          </div>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.massToggle === nextProps.massToggle &&
    shallowCompareCaseData(prevProps.caseActivity, nextProps.caseActivity)
);
