/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useAclDialogStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    backgroundColor: palette.background.paper,
    minWidth: '32vw',
    padding: spacing(3)
  },
  title: {
    alignItems: 'center',
    paddingTop: spacing(3),
    paddingBottom: spacing(2)
  },
  aciRowContainer: {
    marginTop: spacing(2),
    padding: spacing(1),
    borderRadius: spacing(0.5)
  },
  notVisible: {
    display: 'none'
  }
}));

export const useAclDialogRowStyles = makeStyles(({ spacing }) => ({
  rowContainer: {
    padding: spacing(0.5, 3),
    alignItems: 'center',
    width: '100%'
  }
}));
