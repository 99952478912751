/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';
import { DatePicker } from '.';

export const DateTimePicker = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ value, ...props }, ref) => (
    <DatePicker type="datetime-local" value={value} ref={ref} {...props} />
  )
);
