/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { GenericNode, GenericPlaybook } from '../type';

export function parseNodeName(
  node: GenericNode,
  playbookType: GenericPlaybook['playbookType']
): string {
  if (playbookType === 'cacao') {
    const split = node.name.split('---');
    if (split.length >= 2) {
      return split[1];
    }

    return node.name;
  }

  return node.name;
}
