/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { CaseActivityContent } from 'components/CaseActivity/CaseActivityContent';
import { CaseActivityInfo } from 'components/CaseActivity/CaseActivityInfo';
import { StixDataPanel } from 'components/_graphVisualisation/StixDataPanel';
import { useDispatch } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { closeTimelineCaption } from 'states/activityTimeline/slice';
import { css, keyframes } from '@emotion/react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

/** @jsxImportSource @emotion/react */

const CaptionCaretCommon = (theme) => css`
  fill: ${theme.palette.background.paper};
  position: absolute;
  top: 50%;
  font-size: 45px;
`;

const growAnimation = keyframes`
  0%    {opacity: 0}
  100%  {opacity: 1}
`;

export const TimelineCaption = ({ caption, svgWidth, caseAcl }) => {
  const { node, data, id } = caption;
  const cy = node.getAttribute('cy');
  const cx = node.getAttribute('cx');
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeTimelineCaption(id));
  };
  const flippedView = +cx > svgWidth * 0.7;
  return (
    <div
      css={css`
        position: absolute;
        ${flippedView ? 'right: 100%;' : 'left: 0;'}
        top: ${+cy + 10}px;
        z-index: 10000;
        width: auto;
        max-width: 70%;
        max-height: 400px;
        transition: transform 0.2s ease;
        animation: ${growAnimation} 0.2s linear forwards;
        transform: translateY(-50%)
          translateX(${+cx + (flippedView ? -14 : 14)}px);
        & [class*='headingText'] {
          flex-wrap: wrap;
        }
      `}
    >
      <div
        css={(theme) => `
          padding: 15px 20px;
          border-radius: 10px;
          overflow-y: auto;
          background: ${theme.palette.background.paper};
          box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 20%);
          & [class*='headingText'] {
            flex-wrap: wrap;
          }
      `}
      >
        {!data.isStix ? (
          <>
            {' '}
            <CaseActivityInfo caseActivity={data} caseAcl={caseAcl} />
            <CaseActivityContent caseActivity={data} />
          </>
        ) : (
          <StixDataPanel data={data} />
        )}
      </div>
      <ArrowLeftIcon
        css={(theme) => css`
          ${CaptionCaretCommon(theme)};
          transform: translateY(-50%) translateX(-58%);
          left: 0;
          display: ${flippedView ? 'none' : 'block'};
        `}
      />
      <ArrowRightIcon
        css={(theme) => css`
          ${CaptionCaretCommon(theme)};
          transform: translateY(-50%) translateX(58%);
          right: 0;
          display: ${flippedView ? 'block' : 'none'};
        `}
      />
      <CancelIcon
        css={css`
          position: absolute;
          top: 0;
          left: ${flippedView ? 'auto' : 0};
          right ${flippedView ? 0 : 'auto'};
          transform: translate(${flippedView ? '-40%' : '40%'}, -40%);
          cursor: pointer;
      `}
        onClick={handleClose}
      />
    </div>
  );
};
