/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState } from 'react';
import {
  CydTextField,
  CydTextFieldProps
} from 'components/_formElements/CydTextField';

export type CydAutoExpandTextFieldProps = CydTextFieldProps;

export const CydAutoExpandTextField = (props: CydAutoExpandTextFieldProps) => {
  const [isMultiline, setIsMultiline] = useState(false);

  const handleFocus = () => {
    setIsMultiline(true);
  };

  const handleBlur = () => {
    // This delay ensures the "Submit" or "Save" button click event triggers before this field collapses.
    // Without the delay, onBlur resizing may prevent the button click event from being triggered.
    setTimeout(() => setIsMultiline(false), 50);
  };

  const value = props.value;
  return (
    <CydTextField
      css={(theme) => `
        .MuiInputBase-multiline {
          padding-top: ${theme.spacing(2)};
        }
        textarea {
          padding-left: 0;
          padding-top: 0;
        }
      `}
      multiline={isMultiline}
      rows={isMultiline ? 3 : 1}
      autoFocus={isMultiline}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
      value={!isMultiline && value ? (value as string).split('\n')[0] : value}
    />
  );
};
