/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import React from 'react';
import { defaultDisplayAValueAsString } from 'utils/StringUtils';

export type Row = Record<string, unknown>;

export type Column<T extends Row> = {
  [K in keyof T]: {
    key: K & string;
    label: React.ReactNode;
    customRender?: (value: T[K], rowData: T) => React.ReactNode;
  };
}[keyof T];

export type CydSimpleTableProps<T extends Row> = {
  uniquenessId: keyof T;
  className?: string;
  rows: Array<T>;
  columns: Array<Column<T>>;
};

/**
 * The CydSimpleTable is a simple and smaller version fo the CydDataTable - it doesn't provide any sorting functionality, pagination etc.
 *
 * @param props
 * @returns
 */
export const CydSimpleTable = <T extends Record<string, unknown>>(
  props: CydSimpleTableProps<T>
) => {
  const { className, rows, columns, uniquenessId } = props;

  return (
    <Table size="small" className={className}>
      <TableHead>
        <TableRow>
          {columns.map((v, vi) => {
            return <TableCell key={`${vi}-${v.key}`}>{v.label}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((v, vi) => {
          const uniqueId = v[uniquenessId] as string;

          return (
            <TableRow key={`${vi}-${uniqueId}`}>
              {columns.map((w, wi) => {
                const renderFn = w.customRender || defaultDisplayAValueAsString;

                return (
                  <TableCell key={`${wi}-${uniqueId}-${w.key}`}>
                    {renderFn(v[w.key], v)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
