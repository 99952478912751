/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  Menu,
  MenuItem,
  MenuProps,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  CydButton,
  CydButtonProps
} from 'components/_formElements/CydButton/CydButton';

import {
  CydIconButton,
  CydIconButtonProps
} from 'components/_formElements/CydIconButton/CydIconButton';
import { CydIcon, CydIconTypes } from 'components/_foundation/CydIcon/CydIcon';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

export type CydMenuProps = {
  label: string;

  variant?: 'button' | 'icon-button';
  icon?: CydIconTypes;
  buttonSize?: CydIconButtonProps['size'];

  ButtonProps?: CydButtonProps;

  menuItems: Array<{
    label: string;
    icon?: CydIconTypes;
    onClick?: () => void;
    link?: string;
  }>;

  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
};

const DEFAULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center'
} as const;

const DEFAULT_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'center'
} as const;

// If you're thinking this looks copy pasted from CydPopover, that's because it is.
// I'm ok with it though.

const ReffedLink = React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
  const { href, role, ...rest } = props;
  return <Link ref={ref} to={href} {...rest} />;
});

export const CydMenu = (props: CydMenuProps) => {
  const {
    label,
    variant = 'button',
    icon,
    menuItems,
    ButtonProps = {},
    buttonSize,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_TRANSFORM_ORIGIN
  } = props;

  const ref = useRef<HTMLButtonElement>(null);

  if (variant === 'icon-button' && !icon) {
    console.warn(
      "You are using an icon-button CydPopover, but you haven't specified an icon to use"
    );
  }
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {variant === 'button' ? (
        <CydButton ref={ref} onClick={handleClick} {...ButtonProps}>
          {label}
        </CydButton>
      ) : (
        <CydIconButton
          size={buttonSize}
          label={label}
          ref={ref}
          onClick={handleClick}
          icon={icon as CydIconTypes}
        />
      )}

      {isOpen && (
        <Menu
          anchorEl={ref.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={isOpen}
          onClose={handleClose}
          PaperProps={{
            sx: {
              minWidth: ref.current?.offsetWidth
            }
          }}
        >
          {menuItems.map((v) => {
            if (v.link) {
              // What we're doing here is rendering a Link, but allow MUI to pass in it's styling to the element
              // Is it actually necessary to do this though, rather than just putting the `href` attribute one?
              // What's the difference?
              // One advantage is that Link/NavLink will error if not surrounded by a router - which is probably a good thing
              // Pretty good explanation here: https://stackoverflow.com/questions/43087007/react-link-vs-a-tag-and-arrow-function
              return (
                <MenuItem
                  key={v.label}
                  component={ReffedLink}
                  href={v.link}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <ListItemIcon>
                    {v.icon && <CydIcon icon={v.icon} />}
                  </ListItemIcon>
                  <ListItemText>{v.label}</ListItemText>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  key={v.label}
                  onClick={(e) => {
                    if (v.onClick) {
                      v.onClick();
                    }
                    setIsOpen(false);
                  }}
                >
                  <ListItemIcon>
                    {v.icon && <CydIcon icon={v.icon} />}
                  </ListItemIcon>
                  <ListItemText>{v.label}</ListItemText>
                </MenuItem>
              );
            }
          })}
        </Menu>
      )}
    </>
  );
};
