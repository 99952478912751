/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

export type CydPageSubNavProps = {};

/** This component is the right hand panel.
 *
 * Uses:
 *  - Information about the currently selected item
 *  - Summary information about the current page
 */
export const CydLeftSideBar = (
  props: React.PropsWithChildren<CydPageSubNavProps>
) => {
  const { children } = props;

  const [ready, setIsReady] = useState(false);
  const portalRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const subNavDiv = document.getElementById(
      'left-side-bar'
    ) as HTMLDivElement;
    portalRef.current = subNavDiv;
    setIsReady(true);
  }, []);

  if (!ready) {
    return null;
  } else {
    if (!portalRef.current) {
      throw new Error(
        'Can not create a left side bar, no container found for it'
      );
    }

    /*   nb. It is important _not_ to do any styling inside this component. 
        That styling should be done up at CydPageLayoutFrame
        The idea is you can have multiple components use of these, and the items are just put into the portal target. You (probably) only want one styled container, not many. 
    */
    return ReactDOM.createPortal(children, portalRef.current);
  }
};
