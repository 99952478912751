import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';

const watchersSelector = ({ watcherStore }: IStore) => watcherStore.watchers;

export const caseWatchersSelector = createSelector(
  watchersSelector,
  (_, caseUuid) => caseUuid,
  (watchers, caseUuid) => {
    return watchers && watchers[caseUuid];
  }
);

export const watchersIsLoadingSelector =
  (caseUuid: string) =>
  ({ watcherStore }: IStore) =>
    watcherStore.isFetching && !watcherStore.watchers?.[caseUuid];

export const watchersHasLoadedSelector =
  (caseUuid: string) =>
  ({ watcherStore }: IStore) =>
    watcherStore.hasLoaded && !!watcherStore.watchers?.[caseUuid];
