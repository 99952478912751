/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as React from 'react';
import { DefaultTable } from 'components/DefaultTable';
import { iocCaseTableHeaders } from './headers';
import { CaseIOCsTableRow } from './CaseIOCsTableRow';
import { useIOCCaseTableStyles } from './styles';
import { IStixCsvRow } from 'utils/IOCUtils';

interface ICaseIOCsTableProps {
  iocs: IStixCsvRow[];
}

export const CaseIOCsTable: React.FC<ICaseIOCsTableProps> = ({ iocs }) => {
  const { table } = useIOCCaseTableStyles();

  return iocs.length > 0 ? (
    <DefaultTable headers={iocCaseTableHeaders} className={table}>
      {iocs.map((ioc) => (
        <CaseIOCsTableRow key={ioc.id} ioc={ioc} />
      ))}
    </DefaultTable>
  ) : (
    <i>No IOCs are attached to this case.</i>
  );
};
