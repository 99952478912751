/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Tooltip from '@mui/material/Tooltip';
import { ClassNames } from '@emotion/react';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import { CydTagDisplayByTagValue } from 'components/_objectDisplay/CydTagDisplay/CydTagDisplayByTagValue';
import { CydTagChip } from 'components/_objectDisplay/CydTagDisplay/CydTagChip';

/** @jsxImportSource @emotion/react */

type CydTagDisplayProps = {
  values?: Array<string>;
};

export const CydTagArrayDisplayByTagValues = (props: CydTagDisplayProps) => {
  const { values } = props;
  if (!values || values.length === 0) {
    return null;
  }
  if (values.length === 1) {
    return <CydTagDisplayByTagValue value={values[0]} />;
  }
  return (
    <ClassNames>
      {({ theme, cx, css }) => (
        <Tooltip
          classes={{
            tooltip: cx(css`
              background-color: ${theme.palette.background.default};
              border: none;
            `)
          }}
          title={values.map((value) => (
            <CydTagChip label={value} key={value} isShowAll />
          ))}
          disableInteractive={false}
        >
          <CydTagChip
            label={`${values.length} tags`}
            icon={<CydIcon icon="more" />}
          />
        </Tooltip>
      )}
    </ClassNames>
  );
};
