/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Chip, ChipProps } from '@mui/material';
import React from 'react';

/** @jsxImportSource @emotion/react */

export type CydTabChipProps = {
  label: string;
  onClick: ChipProps['onClick'];
  isSelected: boolean;
};

export const CydTabChip = React.forwardRef<HTMLDivElement, CydTabChipProps>(
  (props, ref) => {
    const { label, onClick, isSelected, children, ...rest } = props;

    return (
      <Chip
        css={(theme) => `
          margin-right: ${theme.spacing(3)}; 
          border-radius: 16px; 

          ${
            isSelected
              ? `
          border: solid 1px ${theme.palette.primary.main};
          background-color: ${theme.other.primaryTransparent};  
          color: ${theme.palette.primary.main}; 
          `
              : `
            border: solid 1px ${theme.other.primaryTransparent}; 
            color: ${theme.palette.text.primary}; 
          `
          }
        `}
        label={label}
        onClick={onClick}
        variant="outlined"
        color="primary"
        {...rest}
        ref={ref}
      />
    );
  }
);
