/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';

export type FriendlyBase64StringProps = {
  string: string;
  mode?: 'base64' | 'char';
  maxLength?: number;
};

export function truncateLongString(
  string: string,
  mode: 'base64' | 'char' = 'base64',
  maxLength = 24
): string {
  if (string.length > maxLength) {
    const subStr = string.substring(0, maxLength);

    let length: string;
    if (mode === 'base64') {
      // https://stackoverflow.com/questions/17409496/what-is-a-base64-length-in-bytes
      const byteLength = Math.ceil(string.replace(/=/g, '').length * 0.75);
      length = `(${byteLength.toLocaleString()} bytes)`;
    } else if (mode === 'char') {
      length = `(${string.length.toLocaleString()} characters)`;
    } else {
      throw new Error(`Invalid mode: ${mode}, must be 'base64' or 'char'`);
    }

    return `${subStr}... ${length}`;
  } else {
    return string;
  }
}

export const TruncatedLongString = (props: FriendlyBase64StringProps) => {
  const { string, mode, maxLength } = props;

  return <span>{truncateLongString(string, mode, maxLength)}</span>;
};
