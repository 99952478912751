/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { AxiosError } from 'axios';
import * as ApiService from '../services';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

export function errIsResponseType(err: unknown): err is Response {
  return err instanceof Response;
}

export function errIsAxiosErr(err: unknown): err is AxiosError {
  return err instanceof AxiosError;
}

export type RtkqError = {
  status: number;
  statusText: string;
  data: unknown;
  message: string;
};

export async function handleRtkqError(
  err: unknown
): Promise<{ error: RtkqError }> {
  if (errIsResponseType(err)) {
    let message: string;
    try {
      const body = await err.text();
      const json = JSON.parse(body);
      message = json['error-message'] ?? err.statusText;
    } catch {
      message = err.statusText;
    }

    return {
      error: {
        status: err.status,
        statusText: err.statusText,
        data: err,
        message: message
      }
    };
  }

  if (errIsAxiosErr(err)) {
    return {
      error: {
        status: err.status ?? 1,
        statusText: err.message,
        data: err,
        message: err.message
      }
    };
  }

  if (typeof err === 'string') {
    return {
      error: {
        status: 1,
        statusText: err,
        data: err,
        message: err
      }
    };
  }

  return {
    error: {
      status: 1,
      statusText: 'Unknown error',
      data: err,
      message: 'Unknown error'
    }
  };
}

/**
 * Our error messages probably not done right,
 * but it does look like we need a pattern like this
 * https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 * @param error
 * @returns
 */
export function getRtkqErrorMessage(
  error: FetchBaseQueryError | SerializedError | undefined
): string | null {
  if (!error) {
    return null;
  }

  if ('statusText' in error) {
    return error.statusText as string;
  }

  return 'An error occured';
}

// Because this check could be done a lot, and it's just for type safety,
// We'll cache in so the calcuation is just done the one time.
const alreadySeenMap = new Map<unknown, boolean>();

/**
 * The purpose of this function is just to add typings to the `api.extra` object that redux will provide
 *
 * Redux-Toolkit doesn't have a way of a typing it
 * So as a sanity test - we check it here and return the object asserted as the ApiServices type
 * @param value
 * @returns
 */
export function extraIsApiServices(value: unknown): typeof ApiService {
  const existingValue = alreadySeenMap.get(value);
  if (existingValue !== undefined) {
    if (existingValue) {
      return value as typeof ApiService;
    } else {
      throw new Error('Something has gone wrong, value is not ApiServices');
    }
  }
  if (typeof value !== 'object' || value === null) {
    alreadySeenMap.set(value, false);
    throw new Error(
      `Something has gone wrong, value is not ApiServices - type was ${typeof value} - expected an object or null (have you passed the right value into this function?)`
    );
  }

  Object.keys(ApiService).forEach((key) => {
    const receivedValue = value[key];
    if (typeof receivedValue !== 'function') {
      alreadySeenMap.set(value, false);
      throw new Error('Something has gone wrong, value is not ApiServices');
    }
  });

  alreadySeenMap.set(value, true);
  return value as typeof ApiService;
}
