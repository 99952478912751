/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export type TGroupMemberMap = Record<string, string[]>;

export interface ICaseGroupsStoreState {
  caseGroups: TGroupMemberMap;
}
interface IReplacePayload {
  caseUuid: string;
  groupUuids: string[];
}

interface IAddPayload {
  caseUuid: string;
  groupUuid: string;
}

interface IRemovePayload {
  caseUuid: string;
  groupUuid: string;
}

const initialState: ICaseGroupsStoreState = {
  caseGroups: {}
};

const caseGroupsSlice = createSlice({
  name: 'caseGroups',
  initialState,
  reducers: {
    replaceCaseGroups: (state, { payload }: PayloadAction<IReplacePayload>) => {
      state.caseGroups[payload.caseUuid] = payload.groupUuids;
    },
    addGroupToCase: (state, { payload }: PayloadAction<IAddPayload>) => {
      state.caseGroups[payload.caseUuid] = [
        ...(state.caseGroups[payload.caseUuid] || []),
        payload.groupUuid
      ];
    },
    removeGroupFromCase: (
      state,
      { payload }: PayloadAction<IRemovePayload>
    ) => {
      const cases: Array<string> = state.caseGroups[payload.caseUuid];
      state.caseGroups[payload.caseUuid] = cases.filter(
        (groupUuid) => payload.groupUuid !== groupUuid
      );
    }
  }
});

export const {
  reducer: caseGroupsStore,
  actions: { replaceCaseGroups, addGroupToCase, removeGroupFromCase }
} = caseGroupsSlice;
