/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectorOperationTypeUpdateRequestResource } from './ConnectorOperationTypeUpdateRequestResource';
import {
    ConnectorOperationTypeUpdateRequestResourceFromJSON,
    ConnectorOperationTypeUpdateRequestResourceFromJSONTyped,
    ConnectorOperationTypeUpdateRequestResourceToJSON,
} from './ConnectorOperationTypeUpdateRequestResource';

/**
 * 
 * @export
 * @interface ConnectorOperationTypeUpdateRequestTopLevel
 */
export interface ConnectorOperationTypeUpdateRequestTopLevel {
    /**
     * 
     * @type {ConnectorOperationTypeUpdateRequestResource}
     * @memberof ConnectorOperationTypeUpdateRequestTopLevel
     */
    data?: ConnectorOperationTypeUpdateRequestResource | null;
}

/**
 * Check if a given object implements the ConnectorOperationTypeUpdateRequestTopLevel interface.
 */
export function instanceOfConnectorOperationTypeUpdateRequestTopLevel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectorOperationTypeUpdateRequestTopLevelFromJSON(json: any): ConnectorOperationTypeUpdateRequestTopLevel {
    return ConnectorOperationTypeUpdateRequestTopLevelFromJSONTyped(json, false);
}

export function ConnectorOperationTypeUpdateRequestTopLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorOperationTypeUpdateRequestTopLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ConnectorOperationTypeUpdateRequestResourceFromJSON(json['data']),
    };
}

export function ConnectorOperationTypeUpdateRequestTopLevelToJSON(value?: ConnectorOperationTypeUpdateRequestTopLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ConnectorOperationTypeUpdateRequestResourceToJSON(value.data),
    };
}

