/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState, useCallback, useEffect, useMemo } from 'react';
import ReactFlow, { Controls, ReactFlowProps, Node, Edge } from 'reactflow';
import 'reactflow/dist/style.css';

import { customNodes } from './CustomNodes';
import { attachPositions } from './utils/cacaoToFlowchart';

import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from './type';
import { ReactFlowProvider } from 'reactflow';
/** @jsxImportSource @emotion/react */

type CydarmGenericFlowChartProps = Omit<
  ReactFlowProps,
  'nodeTypes' | 'elements' | 'nodes'
> & {
  playbook: GenericPlaybook;
  playbookResolutionInfo?: GenericPlaybookResolutionInfo;
  showControls?: boolean;
  onBackgroundClick?: () => void;
  selectedNodeId: string | null;
};

/**
 *
 *
 * All custom nodes etc, will be defined here.
 * @returns
 */
export const CydarmGenericFlowchart = (props: CydarmGenericFlowChartProps) => {
  const {
    showControls,
    playbook,
    onBackgroundClick,
    playbookResolutionInfo,
    selectedNodeId,
    ...rest
  } = props;

  const { nodes } = playbook;

  const [elements, setElements] = useState<{
    nodes: Array<Node<GenericNode>>; // What we're saying here is that nodes will always contain a data object in this shape
    edges: Array<Edge>;
  }>({ nodes: [], edges: [] });

  const processedNodes = useMemo(() => {
    return nodes.map((v) => {
      return {
        ...v,
        isSelected: v.nodeId === selectedNodeId,

        nodeStatus: playbookResolutionInfo?.nodeStatuses[v.nodeId],
        playbookType: playbook.playbookType
      };
    });
  }, [nodes, playbookResolutionInfo, selectedNodeId, playbook]);

  const refreshLayout = useCallback(async () => {
    const initialElements = await attachPositions(
      processedNodes,
      playbook.playbookType
    );

    setElements(initialElements);
  }, [processedNodes, playbook.playbookType]);

  useEffect(() => {
    refreshLayout();
  }, [refreshLayout]);

  //https://stackoverflow.com/questions/55364127/making-a-dotted-grid-with-css
  return (
    <div
      css={(theme) => `
      height: 100%;
      // make sure all the element containing this elements under scroll-container has 100% height
      #scroll-container *:has(&) {
        height: 100%;
      }

      background-size: 24px 24px;
      background-position: -19px -19px;
      background-image: radial-gradient(${
        theme.other.primaryTransparent
      } 1px, transparent 0);


      .react-flow__handle {
        // Don't show the knobs if it is standalone/ATC
        ${playbook.playbookType !== 'cacao' && 'display: none;'}
      }
      .react-flow__attribution {
        display: none; // We should give them some money though 
      }

      .react-flow__edge-textwrapper {
        .react-flow__edge-textbg {
          fill: ${theme.palette.background.default}; 
        }

        .react-flow__edge-text {
          fill: ${theme.palette.text.primary};
        }
      }

    `}
    >
      <ReactFlowProvider>
        <ReactFlow
          nodesDraggable
          nodes={elements.nodes}
          edges={playbook.playbookType === 'cacao' ? elements.edges : []}
          nodeTypes={customNodes}
          {...rest}
        >
          {showControls && (
            <>
              <Controls />
            </>
          )}
        </ReactFlow>
      </ReactFlowProvider>

      {showControls && (
        <div>
          <button onClick={refreshLayout}>Auto layout</button>
        </div>
      )}
    </div>
  );
};
