/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { takeLatest, put } from 'redux-saga/effects';
import {
  createAddActionToCase,
  createFetchCaseActions,
  createRemoveActionFromCase,
  createUpdateCaseAction
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { addActionToCaseSuccess, fetchCaseActionsSuccess } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import {
  apiAddActionToCase,
  apiFetchCaseAction,
  apiRemoveActionFromCase,
  apiUpdateActionToCase
} from 'services/CasePlaybooksService';

function* performFetchCaseActions({
  payload: caseUuid
}: PayloadAction<string>) {
  try {
    const { json: actionList } = yield apiFetchCaseAction(caseUuid);
    yield put(fetchCaseActionsSuccess({ [caseUuid]: actionList }));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_CASE_ACTIONS_ERROR.message
      })
    );
  }
}

export type AddActionToCasePayload = {
  payload: {
    actionUuid: string;
    onResolve?: (actionUuid: PlaybookActionInstance) => void;
    caseUuid: string;
  };
};
function* performAddActionToCase(action: AddActionToCasePayload) {
  const {
    payload: { caseUuid, actionUuid, onResolve }
  } = action;
  try {
    const result = yield apiAddActionToCase(caseUuid, actionUuid);

    if (onResolve) {
      onResolve(result);
    }

    yield put(addActionToCaseSuccess());
    yield put(createFetchCaseActions(caseUuid));
    yield put(
      addNotification({
        message: 'Successfully add action to case'
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.ADD_CASE_ACTIONS_ERROR.message
      })
    );
  }
}

export type RemoveStandaloneActionFromCasePayload = {
  payload: {
    caseUuid: string;
    actionInstance: PlaybookActionInstance;
  };
};
function* performRemoveActionToCase(
  action: RemoveStandaloneActionFromCasePayload
) {
  const {
    payload: { caseUuid, actionInstance }
  } = action;

  const { actionInstanceUuid } = actionInstance;

  try {
    yield apiRemoveActionFromCase(caseUuid, actionInstanceUuid);
    yield put(createFetchCaseActions(caseUuid));
    yield put(
      addNotification({
        message: 'Successfully removed action from case'
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.REMOVE_CASE_ACTIONS_ERROR.message
      })
    );
  }
}

export type UpdateStandaloneActionOnCasePayload = {
  payload: {
    caseUuid: string;
    actionInstance: PlaybookActionInstance;
  };
};
function* performUpdateActionToCase(
  action: UpdateStandaloneActionOnCasePayload
) {
  const {
    payload: { caseUuid, actionInstance }
  } = action;
  try {
    yield apiUpdateActionToCase(caseUuid, actionInstance.actionInstanceUuid, {
      ...actionInstance,
      // @ts-expect-error https://cydarm.slack.com/archives/C033ZKMMAJ1/p1668466541367079
      // Backend: probably need to refactor this.
      // When fetching (GET request) data, we receive `assigneeUuid` in the response payload
      // When updating (PUT request), we are sending `assignee` in the request payload
      assignee: actionInstance.assigneeUuid
    });
    yield put(createFetchCaseActions(caseUuid));
    yield put(
      addNotification({
        message: 'Successfully update action for case'
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.UPDATE_CASE_ACTIONS_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchCaseActions() {
  yield takeLatest(createFetchCaseActions, performFetchCaseActions);
}

function* watchAddCaseActions() {
  yield takeLatest(createAddActionToCase, performAddActionToCase);
}

function* watchRemoveCaseActions() {
  yield takeLatest(createRemoveActionFromCase, performRemoveActionToCase);
}

function* watchUpdateCaseActions() {
  yield takeLatest(createUpdateCaseAction, performUpdateActionToCase);
}

export default [
  watchFetchCaseActions(),
  watchAddCaseActions(),
  watchRemoveCaseActions(),
  watchUpdateCaseActions()
];
