/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { quickSearch } from './actions';

export interface IQuickSearchResult {
  uuid: string;
  rank: number;
}

export interface IQuickSearchResultList {
  results: IQuickSearchResult[];
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: IQuickSearchResultList = {
  results: [],
  isLoading: false,
  hasLoaded: false
};

const quickSearchSlice = createSlice({
  name: 'quickSearch',
  initialState,
  reducers: {
    quickSearchSuccess: (
      state,
      { payload }: PayloadAction<IQuickSearchResult[]>
    ) => ({ ...state, isLoading: false, results: payload, hasLoaded: true }),
    quickSearchFailed: (state) => ({ ...state, isLoading: false, results: [] })
  },
  extraReducers: (builder) =>
    builder.addCase(quickSearch, (state) => ({
      ...state,
      isLoading: true,
      results: []
    }))
});

export const {
  reducer: quickSearchStore,
  actions: { quickSearchSuccess, quickSearchFailed }
} = quickSearchSlice;
