/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as React from 'react';
import { Acl } from 'interface/Acl.interface';
import { EncryptionKeySelect } from 'components/fields/EncryptionKeySelect';
import { DataSignificanceSelect } from 'components/fields/DataSignificanceSelect/DataSignificanceSelect';
import {
  aclPermissions,
  sortAclAcis,
  transformAclAciGroup
} from 'utils/AclUtils';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { CydAclModalRow } from 'components/_dialogs/CydAclModal/CydAclModalRow';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { Chip, Typography } from '@mui/material';
import { CydDiv, CydNull } from 'components/_utils/CydFragment';
import { useDataSignificancesRedux } from 'hooks/CaseDataHooks';

/** @jsxImportSource @emotion/react */

type CydAclModalProps = {
  acl: Acl;
  onClose: () => void;
  isOpen: boolean;

  // Legacy functionality, and should be removed ASAP
  filename?: string;
  significance?: string;
};

// This thing most probably doesn't belong here and can probably be deleted.
// But we're just keeping in here anyway, to maintain existing functionality.

// Note that we use a state hook, and this thing will just error and be null if the right context does not exist
const ExtraStuff = withErrorBoundary(
  (props: { fileName; significance }) => {
    const { fileName, significance } = props;

    const disabled = true;

    const { data: dataSignificances } = useDataSignificancesRedux();

    // TODO: we might not need this, copy and paste from the prevous ACL Modal
    // Hardcoded for now
    const keyOptions = [
      { label: 'No key selected', value: 'No key selected' },
      { label: 'key1', value: 'key1' },
      { label: 'key2', value: 'key2' },
      { label: 'key3', value: 'key3' }
    ];

    const currentSig = dataSignificances.find(
      ({ name }) => name === significance
    );
    const defaultValue = dataSignificances.find(
      ({ name }) => name === 'Comment'
    );

    return (
      <>
        {fileName && (
          <CydAclModalRow title="Encryption">
            <EncryptionKeySelect
              options={keyOptions}
              value={'No key selected'}
              disabled={disabled}
            />
          </CydAclModalRow>
        )}
        {significance && (
          <CydAclModalRow title="Type">
            <DataSignificanceSelect
              dataSignificances={dataSignificances}
              value={currentSig || defaultValue}
              disabled={disabled}
            />
          </CydAclModalRow>
        )}
      </>
    );
  },
  CydDiv,
  CydNull
);

export const CydAclModal = ({
  acl,
  onClose,
  isOpen,
  filename,
  significance
}: CydAclModalProps) => {
  const aciGroup = transformAclAciGroup(acl);

  return (
    <CydSimpleFormModal
      size="xlarge"
      isOpen={isOpen}
      onClose={onClose}
      title="Access Control List Details"
    >
      <>
        <Typography variant="h6">{acl.description}</Typography>
        <ExtraStuff fileName={filename} significance={significance} />

        {aclPermissions.map((permission) => {
          const acis = aciGroup[permission] || [];
          const sortAcis = sortAclAcis(acis);

          return (
            <CydAclModalRow key={permission} title={permission}>
              {sortAcis.map((aci) => (
                <Chip
                  css={(theme) => `
                  margin: ${theme.spacing(1)};
                `}
                  label={aci.group.name}
                  key={aci.group.uuid}
                />
              ))}
            </CydAclModalRow>
          );
        })}
        <CydButtonStack justifyContent="flex-end">
          <CydButton variant="text" onClick={onClose}>
            Close
          </CydButton>
        </CydButtonStack>
      </>
    </CydSimpleFormModal>
  );
};
