/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CaseDataRequestTopLevel,
  CaseDataTopLevel,
} from '../models/index';
import {
    CaseDataRequestTopLevelFromJSON,
    CaseDataRequestTopLevelToJSON,
    CaseDataTopLevelFromJSON,
    CaseDataTopLevelToJSON,
} from '../models/index';

export interface CaseDataPostRequest {
    caseDataRequestTopLevel?: CaseDataRequestTopLevel;
}

export interface CaseDataUuidGetRequest {
    uuid: string;
    caseDataRequestTopLevel?: CaseDataRequestTopLevel;
}

/**
 * 
 */
export class CaseDataApi extends runtime.BaseAPI {

    /**
     * Add new data to a case
     */
    async caseDataPostRaw(requestParameters: CaseDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseDataTopLevel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        const response = await this.request({
            path: `/case-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CaseDataRequestTopLevelToJSON(requestParameters.caseDataRequestTopLevel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseDataTopLevelFromJSON(jsonValue));
    }

    /**
     * Add new data to a case
     */
    async caseDataPost(requestParameters: CaseDataPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseDataTopLevel> {
        const response = await this.caseDataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * gets a data item
     */
    async caseDataUuidGetRaw(requestParameters: CaseDataUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CaseDataTopLevel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling caseDataUuidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        const response = await this.request({
            path: `/case-data/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: CaseDataRequestTopLevelToJSON(requestParameters.caseDataRequestTopLevel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseDataTopLevelFromJSON(jsonValue));
    }

    /**
     * gets a data item
     */
    async caseDataUuidGet(requestParameters: CaseDataUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CaseDataTopLevel> {
        const response = await this.caseDataUuidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
