/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydLink } from 'components/_formElements/CydLink/CydLink';
import { CydarmRoute } from 'interface/CydarmRoute';
import React from 'react';
import { CydGenericErrorPlaceholder } from '../CydGenericErrorPlaceholder/CydGenericErrorPlaceholder';

/** @jsxImportSource @emotion/react */

export type CydSomethingWentWrongProps = {
  clearErrorBoundary: () => void;
};

export const CydSomethingWentWrong = (props: CydSomethingWentWrongProps) => {
  return (
    <CydGenericErrorPlaceholder
      title="Something went wrong"
      message="Please try again. If the issue perists please get in touch at support@cydarm.com"
    >
      <CydLink
        onClick={() => {
          props.clearErrorBoundary();
        }}
        to={CydarmRoute.HOME}
        css={(theme) => `
            margin-top: ${theme.spacing(6)};
          `}
      >
        Return to home
      </CydLink>
    </CydGenericErrorPlaceholder>
  );
};
