/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { takeEvery, put, takeLeading } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { ERROR_MESSAGE } from './errors';
import {
  createOrRetrieveAclSuccess,
  deleteAclSuccess,
  fetchAclByUuidSuccess,
  fetchAclSuccess
} from './slice';
import {
  createCreateOrRetrieveAclAction,
  createDeleteAclAction,
  createFetchAclAction,
  createFetchAclByUuidAction,
  ICreateOrRetrieveAcl
} from './actions';

import { PayloadAction } from '@reduxjs/toolkit';
import {
  createAcl,
  deleteAcl,
  fetchAclByUuid,
  fetchAllAcls
} from 'services/AclsService';

export function* performFetchAcl() {
  try {
    const { json: acls } = yield fetchAllAcls();
    yield put(fetchAclSuccess(acls));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_ACL_ERROR.message })
    );
  }
}

export function* performFetchAclByUuid({
  payload: uuid
}: PayloadAction<string>) {
  if (!uuid) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_ACL_BY_UUID_ERROR.message
      })
    );
    return;
  }
  try {
    const result = yield fetchAclByUuid(uuid);
    const { json: acl } = result;
    yield put(fetchAclByUuidSuccess(acl));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_ACL_BY_UUID_ERROR.message
      })
    );
  }
}

export function* performCreateOrRetrieveAcl({
  payload
}: {
  payload: ICreateOrRetrieveAcl;
}) {
  try {
    const { json: acl } = yield createAcl(payload.acl);
    payload.resolve(acl);
    yield put(createOrRetrieveAclSuccess(acl));
    yield put(addNotification({ message: 'Created new ACL successfully' }));
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to create a new ACL' }));
  }
}

export function* performDeleteAcl({ payload }: PayloadAction<string>) {
  try {
    yield deleteAcl(payload);
    yield put(deleteAclSuccess(payload));
    yield put(addNotification({ message: 'Deleted ACL successfully' }));
  } catch (ex) {
    yield put(addNotification({ message: `Failed to delete ACL` }));
  }
}

/* Watchers */
function* watchFetchAclByUuid() {
  yield takeEvery(createFetchAclByUuidAction, performFetchAclByUuid);
}

function* watchCreateOrRetrieveAcl() {
  yield takeEvery(createCreateOrRetrieveAclAction, performCreateOrRetrieveAcl);
}

function* watchFetchAcls() {
  yield takeLeading(createFetchAclAction, performFetchAcl);
}

function* watchDeleteAcl() {
  yield takeEvery(createDeleteAclAction, performDeleteAcl);
}

export default [
  watchFetchAcls(),
  watchFetchAclByUuid(),
  watchCreateOrRetrieveAcl(),
  watchDeleteAcl()
];
