/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  IPlaybookActionType,
  PlaybookAction,
  PlaybookActionInstance
} from 'interface/Playbook.interface';

import { PlayBookActionsPopover } from 'components/PlaybookActionsPopover/PlaybookActionsPopover';
import { CydDataTable } from '../../_dataDisplay/CydDataTable/CydDataTable';
import { CydTimeDisplay } from 'components/_objectDisplay/CydTimeDisplay/CydTimeDisplay';
import { joinArray } from 'utils/ArrayUtils';
import { Markdown } from 'components/Markdown';

interface IPlaybookActionsTableProps {
  playbookActions: IPlaybookActionType[];
  onDeleteAction: (playbookAction: PlaybookActionInstance) => void;
}

export const PlaybookActionsTable: React.FC<IPlaybookActionsTableProps> = (
  props
) => {
  const { playbookActions, onDeleteAction } = props;

  const playbookActionsToUse = React.useMemo(() => {
    return playbookActions.map((v) => {
      return v.atc;
    });
  }, [playbookActions]);

  const table = (
    <CydDataTable
      paginationInfo={{
        paginationType: 'sync'
      }}
      rowUniquenessKey="uuid"
      rows={playbookActionsToUse as Array<PlaybookAction>}
      columns={[
        {
          key: 'name',
          label: 'Action Name',
          sortFn: true
        },
        {
          key: 'description',
          label: 'Action Description',
          sortFn: true,
          customRender: (v) => <Markdown>{v}</Markdown>
        },
        {
          key: 'tags',
          label: 'Tags',
          customRender: (v) => {
            if (!v) {
              return null;
            }

            return <>{joinArray(v.map((w) => w))}</>;
          }
        },

        {
          key: 'created',
          label: 'Created At',
          sortFn: true,
          customRender: (v) => <CydTimeDisplay time={v as string} />
        },
        {
          key: 'modified',
          label: 'Last Modified',
          sortFn: true,
          customRender: (v) => <CydTimeDisplay time={v as string} />
        }
      ]}
      rowActions={{
        actions: [
          {
            label: 'Edit',
            icon: 'edit',
            renderModalContent: (data, closeModalFn) => {
              // Fairly sure the that PlaybookActionInstance is the wrong type
              return (
                <PlayBookActionsPopover
                  onClose={closeModalFn}
                  playbookAction={data as PlaybookActionInstance}
                />
              );
            }
          },
          {
            label: 'Delete',
            icon: 'delete',
            onClick: onDeleteAction,
            onClickConfirmMessage: {
              message: 'Delete playbook action?'
            }
          }
        ]
      }}
    />
  );

  return table;
};
