/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  CREATE_CASE_ERROR: {
    code: 'CREATE_CASE_ERROR',
    message: 'Error occurred while creating case'
  },
  CREATE_CASE_CONFLICT_ERROR: {
    code: 'CREATE_CASE_CONFLICT_ERROR',
    message:
      'Error occurred while creating case: a case with same description was created and in duplicate suppression window'
  },
  FETCH_CASE_ERROR: {
    code: 'FETCH_CASE_ERROR',
    message: 'Error occurred while fetching case'
  },
  FETCH_CASE_SLA_ERROR: {
    code: 'FETCH_CASE_SLA_ERROR',
    message: 'Error occurred while fetching case SLA'
  }
};

export { ERROR_MESSAGE };
