/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';

import {
  createAddTagToCase,
  createRemoveTagFromCase
} from 'states/cases/actions';
import { useDispatch } from 'react-redux';
import { createCaseTag } from 'states/caseTags/actions';
import { useCaseTags, useCaseTagsFiltered } from 'hooks/CaseTagsHooks';
import { CydTagMultiSelector } from 'components/_objectSelectors/CydTagMultiSelector/CydTagMultiSelector';
import { CaseTag } from 'interface/Tags.interface';
import { CydCaseDetailsPanelSection } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanelSection';

type CydCaseViewTagsSectionProps = {
  caseUuid: string;
  tags?: Array<string>;
};

const DEFAULT_TAGS = [];

export const CydCaseViewTagsSection = ({
  caseUuid,
  tags = DEFAULT_TAGS
}: CydCaseViewTagsSectionProps) => {
  const { data: caseTags } = useCaseTags();
  const { data: filteredCaseTags } = useCaseTagsFiltered();

  const dispatch = useDispatch();

  const checkStoreDuplicateTag = React.useCallback(
    (checkVal: string) => {
      return (
        caseTags && caseTags.map((el) => el.value).indexOf(checkVal) !== -1
      );
    },
    [caseTags]
  );

  const checkCaseDuplicateTag = React.useCallback(
    (checkVal: string) => {
      return tags?.map((el) => el).indexOf(checkVal) !== -1;
    },
    [tags]
  );

  const isEmptyOrSpaces = (checkVal: string) =>
    checkVal === null || checkVal.match(/^ *$/) !== null;

  const handleSaveTag = React.useCallback(
    (checkVal: string) => {
      if (
        !isEmptyOrSpaces(checkVal) &&
        checkStoreDuplicateTag(checkVal) &&
        !checkCaseDuplicateTag(checkVal)
      ) {
        dispatch(createAddTagToCase({ caseUuid, tagValue: checkVal }));
      } else {
        dispatch(
          createCaseTag({
            value: checkVal,
            description: checkVal,
            acl: 'case data defaults',
            resolve: (caseTag) => {
              dispatch(
                createAddTagToCase({
                  caseUuid,
                  tagValue: caseTag.value || ''
                })
              );
            }
          })
        );
      }
    },
    [checkCaseDuplicateTag, checkStoreDuplicateTag, dispatch, caseUuid]
  );

  const handleRemoveTagFromCase = React.useCallback(
    (tag: string) => {
      dispatch(createRemoveTagFromCase({ caseUuid, tagValue: tag }));
    },
    [dispatch, caseUuid]
  );

  const availableTagsMap = React.useMemo(() => {
    return filteredCaseTags.reduce(
      (acc, cur) => {
        return {
          ...acc,
          [cur.value as string]: cur
        };
      },
      {} as Record<string, CaseTag>
    );
  }, [filteredCaseTags]);

  const selectedTags = React.useMemo(() => {
    return (
      tags?.reduce((acc, cur) => {
        const foundTag = availableTagsMap[cur];
        if (!foundTag) {
          console.warn(`Didn't find a tag for tag id ${cur}`);
          return acc;
        }

        return [...acc, foundTag];
      }, []) || []
    );
  }, [availableTagsMap, tags]);

  const handleTagChange = React.useCallback(
    (newTags: Array<CaseTag>) => {
      // Little bit of a short cut - the new tag will always be the last one.
      const lastTag = newTags[newTags.length - 1];

      let addedTag: CaseTag | null = null;
      let removedTag: CaseTag | null = null;

      if (
        lastTag &&
        !selectedTags.find((v) => {
          return v.uuid === lastTag.uuid;
        })
      ) {
        // If the last tag is not in the selected tags, then it is a new tag.

        addedTag = lastTag;
      } else {
        //Otherwise, we need to find which of the selected tags we just removed.
        removedTag =
          selectedTags.find((v) => {
            return !newTags.find((w) => {
              return w.uuid === v.uuid;
            });
          }) || null;
      }

      if (addedTag) {
        handleSaveTag(addedTag.value);
      } else if (removedTag) {
        handleRemoveTagFromCase(removedTag.value);
      } else {
        throw new Error(
          "Couldn't find either the added tag, or the removed tag"
        );
      }
    },
    [selectedTags, handleSaveTag, handleRemoveTagFromCase]
  );

  return (
    <CydCaseDetailsPanelSection title="Tags">
      <CydTagMultiSelector
        unfocusOnEscape
        dontShowClearButton
        availableTags={filteredCaseTags}
        selectedValues={selectedTags.map((v) => v.value as string)}
        onChange={handleTagChange}
        hotKey="t"
      />
    </CydCaseDetailsPanelSection>
  );
};
