/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createFetchCaseTags } from 'states/caseTags/actions';
import {
  caseTagsSelector,
  caseTagsFilteredSelector,
  caseTagsHasLoadedSelector,
  caseTagStatusSelector
} from 'states/caseTags/selectors';
import { useAutoEffect } from './ReduxHooks';

export const useCaseTags = () => {
  return useAutoEffect({
    name: 'case tags',
    selector: caseTagsSelector,
    ac: createFetchCaseTags,
    statusSelector: caseTagStatusSelector,
    hasLoadedSelector: caseTagsHasLoadedSelector
  });
};

export const useCaseTagsFiltered = () => {
  return useAutoEffect({
    name: 'case tags filtered',

    selector: caseTagsFilteredSelector,
    ac: createFetchCaseTags,
    statusSelector: caseTagStatusSelector
  });
};
