/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type LoadingSpinnerProps = {
  className?: string;
  size?: 'tiny' | 'small' | 'large';
};

const SIZE_MAP: Record<Required<LoadingSpinnerProps>['size'], number> = {
  tiny: 16,
  small: 40,
  large: 120
};

const SPINNER_DEBOUNCE_MS = 250;

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size = 'small', className } = props;

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, SPINNER_DEBOUNCE_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return ready ? (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
      className={className}
    >
      <CircularProgress size={SIZE_MAP[size]} />
    </div>
  ) : null;
};
