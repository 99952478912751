/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectorOperationTypeResourceAttributes
 */
export interface ConnectorOperationTypeResourceAttributes {
    /**
     * The name of the operation type
     * @type {string}
     * @memberof ConnectorOperationTypeResourceAttributes
     */
    name?: string;
    /**
     * The display name of the operation type
     * @type {string}
     * @memberof ConnectorOperationTypeResourceAttributes
     */
    display_name?: string;
    /**
     * A description of the operation type
     * @type {string}
     * @memberof ConnectorOperationTypeResourceAttributes
     */
    description?: string;
    /**
     * The argument schema
     * @type {object}
     * @memberof ConnectorOperationTypeResourceAttributes
     */
    arguments_schema?: object;
}

/**
 * Check if a given object implements the ConnectorOperationTypeResourceAttributes interface.
 */
export function instanceOfConnectorOperationTypeResourceAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectorOperationTypeResourceAttributesFromJSON(json: any): ConnectorOperationTypeResourceAttributes {
    return ConnectorOperationTypeResourceAttributesFromJSONTyped(json, false);
}

export function ConnectorOperationTypeResourceAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorOperationTypeResourceAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'arguments_schema': !exists(json, 'arguments_schema') ? undefined : json['arguments_schema'],
    };
}

export function ConnectorOperationTypeResourceAttributesToJSON(value?: ConnectorOperationTypeResourceAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'display_name': value.display_name,
        'description': value.description,
        'arguments_schema': value.arguments_schema,
    };
}

