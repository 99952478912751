/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import MoreIcon from '@mui/icons-material/More';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import AndroidIcon from '@mui/icons-material/Android';
import DeleteIcon from '@mui/icons-material/Delete';
import FeedIcon from '@mui/icons-material/Feed';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupIcon from '@mui/icons-material/Group';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyIcon from '@mui/icons-material/Key';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LanguageIcon from '@mui/icons-material/Language';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BoyIcon from '@mui/icons-material/Boy';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import SellIcon from '@mui/icons-material/Sell';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import BugReportIcon from '@mui/icons-material/BugReport';
import ReplyIcon from '@mui/icons-material/Reply';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ForwardIcon from '@mui/icons-material/Forward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { IconProps } from '@mui/material';
export type CydIconProps = {
  icon: CydIconTypes;
  label?: string;
  className?: string;
  role?: IconProps['role'];
};

export const ICONS_MAP = {
  back: ArrowBackIcon,
  close: CloseIcon,
  download: DownloadIcon,
  upload: CloudUploadIcon,
  exit: ExitToAppIcon,
  edit: EditIcon,
  person: PersonIcon,
  personGroup: GroupIcon,
  robot: AndroidIcon,
  human: BoyIcon,
  delete: DeleteIcon,
  document: FeedIcon,
  more: MoreIcon,
  switchAccess: SwitchAccessShortcutIcon,
  filter: FilterListIcon,
  leftArrow: ArrowLeftIcon,
  add: AddIcon,
  rightArrow: ArrowRightIcon,
  getApp: GetAppIcon,
  publish: PublishIcon,
  cancel: CancelIcon,
  done: DoneIcon,
  key: KeyIcon,
  lightbulb: LightbulbIcon,
  error: ErrorOutlineIcon,
  save: SaveIcon,
  world: LanguageIcon,
  folder: FolderIcon,
  document2: InsertDriveFileIcon, // Shrug,
  menubook: MenuBookIcon,
  eye: VisibilityIcon,
  dashboard: DashboardIcon,
  search: SearchIcon,
  filter2: FilterAltIcon,
  star: StarRoundedIcon,
  starBorder: StarOutlineRoundedIcon,
  bookmarkOutlined: BookmarkBorderOutlinedIcon,
  bookmarkFilled: BookmarkIcon,
  info: InfoOutlinedIcon,
  copy: ContentCopyIcon,
  debug: BugReportIcon,
  reply: ReplyIcon,
  tag: SellIcon,
  threeDotsHorizontal: MoreHorizIcon,
  downArrow: KeyboardArrowDownIcon,
  badgeExclaim: GppMaybeIcon,
  openInFull: OpenInFullIcon,
  closeFull: CloseFullscreenIcon,
  horizontalRule: HorizontalRuleRoundedIcon,
  timer: TimerRoundedIcon,
  snowFlake: AcUnitIcon,
  forward: ForwardIcon,
  fileUpload: FileUploadIcon,
  doneCircle: CheckCircleIcon,
  circle: CircleIcon
};

export type CydIconTypes = keyof typeof ICONS_MAP;

export const CydIcon = (props: CydIconProps) => {
  const { icon, className, label, role } = props;
  const Icon = ICONS_MAP[icon];

  if (!Icon) {
    throw new Error(`No icon "${icon}" found`);
  }

  if (label) {
    return (
      <Tooltip title={label}>
        <Icon className={className} role={role} />
      </Tooltip>
    );
  }

  return <Icon className={className} role={role} />;
};
