/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { getStore } from 'states/store';
import * as ApiServices from './services';
// import { DownForMaintainence } from 'DownForMaintainence';
// import { SandboxApp } from 'sandbox/RoutingSandbox/App';

// Here we provide the store with the real services
const store = getStore(ApiServices);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

/**
 * For the 'down for maintainence' page just uncomment this and run 'yarn build'
 */

// root.render(
//   <DownForMaintainence/>
// );
