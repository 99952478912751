/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { CaseState } from 'interface/CaseState.interface';
import { createSelector } from 'reselect';
import { IStore } from 'states/store.interface';

export const caseStatesSelector = ({ caseStates: { list } }: IStore) => list;

export const caseStatesOpenStatusSelector = createSelector(
  caseStatesSelector,
  (list) => list.filter((el) => !el.end)
);

export const caseStatesStatusSelector = ({
  caseStates: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;

export const caseStatesIsLoadingSelector = ({
  caseStates: { isLoading }
}: IStore) => isLoading;

export const caseStatesHasLoadedSelector = ({
  caseStates: { hasLoaded }
}: IStore) => hasLoaded;

export const caseTransitionableStatesSelector = createSelector(
  caseStatesSelector,
  (_, currentState) => currentState,
  (caseStates: CaseState[] = [], currentState) => {
    const currentStatus = caseStates.find(
      (caseState) => caseState.name === currentState
    );
    if (!currentStatus) {
      return [];
    }

    const nextStatuses = caseStates.filter((caseState) =>
      currentStatus?.next.includes(caseState.name)
    );
    return [...nextStatuses, currentStatus];
  }
);
