/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonApiServerImpl
 */
export interface JsonApiServerImpl {
    /**
     * The json:api spec version, eg. 1.1
     * @type {string}
     * @memberof JsonApiServerImpl
     */
    version?: string;
}

/**
 * Check if a given object implements the JsonApiServerImpl interface.
 */
export function instanceOfJsonApiServerImpl(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JsonApiServerImplFromJSON(json: any): JsonApiServerImpl {
    return JsonApiServerImplFromJSONTyped(json, false);
}

export function JsonApiServerImplFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonApiServerImpl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function JsonApiServerImplToJSON(value?: JsonApiServerImpl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
    };
}

