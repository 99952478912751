/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as CydarmFetch from 'utils/CydarmFetch';
import {
  put,
  call,
  takeLatest,
  takeEvery,
  all,
  throttle,
  select
} from 'redux-saga/effects';
import { Case, ICaseMeta } from 'interface/Case.interface';
import {
  addNotification,
  INotificationPayload
} from 'states/notifications/slice';
import { addGroupToCase, removeGroupFromCase } from 'states/caseGroups/slice';
import { history } from 'utils/HistoryUtils';
import { ERROR_MESSAGE } from './errors';
import { takeEveryThrottledPerKey } from 'states/sagas';
import { Base64Encode } from 'utils/StringUtils';
import { currentUserSelector } from 'states/auth/selectors';
import { defaultDataSignificanceSelector } from 'states/data/selectors';
import { performFetchDataSignificances } from 'states/data/sagas';
import {
  addCaseSlas,
  addCaseToStore,
  addMemberUuidToCase,
  fetchCasesSuccess
} from './slice';
import {
  createAddMemberToCase,
  createAddTagToCase,
  createAddTagToCases,
  createAssignUserToCases,
  BatchAssignStatusRequest,
  createBatchUpdateCaseStatus,
  createCase,
  createFetchCaseAndMembersByUuid,
  createFetchCaseByLocator,
  createFetchCaseByUuid,
  createFetchCases,
  createFetchCaseSlas,
  IAddTagToCases,
  IAssignUser,
  createRemoveMemberFromCase,
  createRemoveTagFromCase,
  createUpdateCase,
  createUpdateCaseMeta
} from './actions';
import { createAddCaseData } from 'states/data/actions';
import { PayloadAction, Store } from '@reduxjs/toolkit';
import {
  apiPerformAddMemberToCase,
  apiPerformAddTagToCase,
  apiPerformCreateCase,
  apiPerformFetchCaseByLocator,
  apiPerformFetchCaseByUuid,
  apiPerformFetchCases,
  apiPerformFetchCaseSlas,
  apiPerformRemoveMemberFromCase,
  apiPerformRemoveTagFromCase,
  apiPerformUpdateCase,
  apiPerformUpdateCaseMeta
} from 'services/CaseServices';
import { queryClient } from 'providers/CydHookProvider/reactQueryQueryClient';
import { aclByUuidSelector } from 'states/acl/selectors';
import { singleCaseSelector } from './selectors';
import { rtkqCaseActivityApi } from 'hooks/CaseActivityHooks';
import { performFetchCasePlaybooks } from 'states/casePlaybooks/sagas';

/**
 * This is an important pattern to avoid circular dependencies anywhere we interact with store directly
 * See:
 *
 * https://github.com/reduxjs/redux-toolkit/issues/1540
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 *
 *
 * @param store
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let store: Store;
export function provideStore(_store: Store) {
  store = _store;
}

/**
 * This is a spaghetti function to do the invalidation from the various redux sagas.
 * Please use sparinging! We want to remove this!
 * @param caseUuid
 */
export function invalidateCaseActivity(caseUuid: string) {
  store.dispatch(
    rtkqCaseActivityApi.util.invalidateTags([
      {
        type: 'caseActivity',
        id: caseUuid
      }
    ])
  );

  queryClient.invalidateQueries({
    queryKey: ['caseActivity', caseUuid]
  });
  queryClient.refetchQueries({
    queryKey: ['caseActivity', caseUuid]
  });
}

function* performFetchCases() {
  try {
    const { json: list } = yield apiPerformFetchCases();
    yield put(fetchCasesSuccess(list));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_CASE_ERROR.message })
    );
  }
}

function* performCreateCase(action) {
  const {
    payload: { caseData, resolve, navigate }
  }: {
    payload: {
      caseData: Case;
      resolve: (uuid?: string) => string;
      navigate: boolean;
    };
  } = action;

  try {
    const {
      json: { uuid: caseUuid }
    } = yield apiPerformCreateCase(caseData);

    yield put(createFetchCaseByUuid(caseUuid));

    // TODO: look into this to replace callback with a better solution
    resolve && resolve(caseUuid);

    if (navigate) {
      history.push(`/caseview/${caseUuid}`);
      history.go(0);
    }
  } catch (ex) {
    const responseCode: Number = ex.status;
    switch (responseCode) {
      case 409:
        yield put(
          addNotification({
            message: ERROR_MESSAGE.CREATE_CASE_CONFLICT_ERROR.message
          })
        );
        break;
      default:
        yield put(
          addNotification({ message: ERROR_MESSAGE.CREATE_CASE_ERROR.message })
        );
    }
  }
}

export function* performFetchCaseByUuid({
  payload: caseUuid
}: {
  payload: string;
}) {
  try {
    const { json: caseData } = yield apiPerformFetchCaseByUuid(caseUuid);
    yield put(addCaseToStore(caseData));
  } catch (ex) {
    console.error(ex);
    yield put(
      addNotification({ message: `Error fetching case with uuid ${caseUuid}` })
    );
  }
}

export function* fetchCaseAndMembers(caseUuid: string) {
  let allUuids = new Array<string>();
  try {
    const { json: caseData } = yield apiPerformFetchCaseByUuid(caseUuid);

    for (let i = 0; i < caseData.members.length; i = i + 1) {
      const memberUuid = caseData.members[i];
      const memberCaseData: never[] = yield* fetchCaseAndMembers(memberUuid);
      if (memberCaseData && memberCaseData.length > 0) {
        allUuids.push(...memberCaseData);
      }
    }
    allUuids.push(caseData.uuid);
  } catch (ex) {
    console.error(ex);
    yield put(
      addNotification({ message: `Error fetching case with uuid ${caseUuid}` })
    );
  }
  return allUuids;
}

export function* performFetchCaseAndMembersByUuid({
  payload: caseUuid
}: PayloadAction<string>) {
  const allUuids = yield fetchCaseAndMembers(caseUuid);
  try {
    const { json: caseData } = yield CydarmFetch.cyFetchAuthenticated(
      `/case/${caseUuid}`
    );
    const { json: slaData } = yield CydarmFetch.cyFetchAuthenticated(
      `/case/${caseUuid}/sla`,
      {
        method: 'GET'
      }
    );

    const caseData2 = {
      ...caseData,
      slas: slaData,
      deepMembers: allUuids
    };
    yield put(addCaseToStore(caseData2));
  } catch (ex) {
    console.error(ex);

    yield put(
      addNotification({ message: `Error fetching case with uuid ${caseUuid}` })
    );
  }
}

export function* performFetchCaseByLocator(action) {
  const {
    payload: { locator, resolve }
  }: {
    payload: { locator: string; resolve?: (caseData: Case) => void };
  } = action;
  try {
    const { json: caseData } = yield apiPerformFetchCaseByLocator(locator);

    if (resolve) {
      resolve(caseData);
    }

    yield put(addCaseToStore(caseData));
  } catch (ex) {
    yield put(
      addNotification({
        message: `Error fetching case with locator ${locator}`
      })
    );
  }
}

export function* performUpdateCase(action) {
  const {
    payload: { caseUuid, caseData, successNotification, justificationText }
  }: {
    payload: {
      caseUuid: string;
      caseData: Case;
      successNotification: INotificationPayload;
      justificationText: string;
    };
  } = action;

  try {
    yield apiPerformUpdateCase(caseUuid, caseData);
    yield put(
      addNotification(
        successNotification || {
          message: `Updated case ${caseData.locator}`
        }
      )
    );
    if (justificationText) {
      yield call(performAddJustificationComment, {
        payload: { justificationText, caseUuid }
      });
    }
    yield call(performFetchCaseByUuid, { payload: caseUuid });
    yield call(invalidateCaseActivity, caseUuid);
  } catch (ex) {
    yield put(addNotification({ message: `Updating case ${caseUuid} failed` }));
  }
}

function* performAddJustificationComment(action) {
  const {
    payload: { caseUuid, justificationText }
  }: { payload: { caseUuid: string; justificationText: string } } = action;
  try {
    yield call(performFetchDataSignificances);

    const currentCase = yield select(singleCaseSelector, caseUuid);
    const caseAcl = yield select(aclByUuidSelector, currentCase.acl);
    const currentUser = yield select(currentUserSelector);
    const significance = yield select(defaultDataSignificanceSelector);

    const addDataPayload = {
      data: Base64Encode(
        `**Status Change Justification:** ${justificationText}`
      ),
      significance: significance.name,
      userUuid: currentUser.uuid,
      caseUuid,
      mimeType: 'text/plain',
      acl: caseAcl
    };
    yield put(createAddCaseData({ caseData: addDataPayload }));
  } catch (ex) {
    yield put(
      addNotification({
        message: 'Failed to post a status change justification'
      })
    );
  }
}

function* performUpdateCaseMeta(action) {
  const {
    payload: { caseUuid, caseMeta }
  }: {
    payload: { caseUuid: string; caseMeta: { [index: string]: ICaseMeta } };
  } = action;
  try {
    yield apiPerformUpdateCaseMeta(caseUuid, caseMeta);
    yield put(createFetchCaseByUuid(caseUuid));
    yield put(addNotification({ message: 'Updated case metadata' }));
    yield call(invalidateCaseActivity, caseUuid);
  } catch (ex) {
    yield put(
      addNotification({ message: 'Error occurred updating case metadata' })
    );
  }
}

function* performAddMemberToCase(action) {
  const {
    payload: { caseUuid, memberUuid, resolve }
  }: {
    payload: {
      caseUuid: string;
      memberUuid: string;
      resolve?: (memberUuid: string) => void;
    };
  } = action;
  try {
    yield apiPerformAddMemberToCase(caseUuid, memberUuid);
    resolve && resolve(memberUuid);
    yield put(addGroupToCase({ caseUuid: memberUuid, groupUuid: caseUuid }));
    yield put(addMemberUuidToCase({ caseUuid, memberUuid }));
    yield put(createFetchCaseByUuid(caseUuid));
    yield put(
      addNotification({ message: 'Added members to case successfully' })
    );

    yield call(invalidateCaseActivity, memberUuid);
    yield call(invalidateCaseActivity, caseUuid);
  } catch (ex) {
    yield put(
      addNotification({ message: 'Error occurred adding members to case' })
    );
  }
}

function* performRemoveMemberFromCase(action) {
  const {
    payload: { caseUuid, memberUuid, resolve }
  }: {
    payload: {
      caseUuid: string;
      memberUuid: string;
      resolve?: (memberUuid: string) => void;
    };
  } = action;

  try {
    yield apiPerformRemoveMemberFromCase(caseUuid, memberUuid);
    resolve && resolve(memberUuid);
    yield put(createFetchCaseByUuid(caseUuid));
    yield put(
      removeGroupFromCase({ caseUuid: memberUuid, groupUuid: caseUuid })
    );

    yield call(invalidateCaseActivity, memberUuid);
    yield call(invalidateCaseActivity, caseUuid);
  } catch (ex) {
    // TODO: addNotification
  }
}

export function* performAddTagToCase(action) {
  const {
    payload: { caseUuid, tagValue, resolve }
  }: {
    payload: { caseUuid: string; tagValue: string; resolve: () => void };
  } = action;
  try {
    yield apiPerformAddTagToCase(caseUuid, tagValue); //Keep watch

    if (resolve) {
      resolve();
    }

    yield call(performFetchCaseByUuid, { payload: caseUuid });
    // Retrieve playbooks added to a case via the Add Tag To Case endpoint.
    yield call(performFetchCasePlaybooks, { payload: caseUuid });

    yield call(invalidateCaseActivity, caseUuid);
    yield put(addNotification({ message: 'Added tag to case successfully' }));
  } catch (ex) {
    yield put(addNotification({ message: 'Add tag to case has failed' }));
  }
}

export function* performAddTagToCases(action: PayloadAction<IAddTagToCases>) {
  const { payload } = action;
  const { caseUuids, tagValue, onResolve } = payload;
  try {
    yield all(
      caseUuids.map(function* (caseUuid) {
        return yield call(
          CydarmFetch.cyFetchAuthenticated,
          `/case/${caseUuid}/tag`,
          {
            method: 'POST',
            body: JSON.stringify({
              caseUuid,
              tagValue
            })
          }
        );
      })
    );

    if (onResolve) {
      onResolve();
    }

    yield put(
      addNotification({
        message: `Added tag ${tagValue} to cases successfully`
      })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: `Add tag ${tagValue} to cases has failed` })
    );
  }
}

export function* performAssignUserToCases(action: PayloadAction<IAssignUser>) {
  const { payload } = action;
  const { caseUuids, username, onResolve } = payload;

  yield all(
    caseUuids.map(function* (caseUuid) {
      return yield call(CydarmFetch.cyFetchAuthenticated, `/case/${caseUuid}`, {
        method: 'PUT',
        body: JSON.stringify({ assignee: username })
      });
    })
  );

  if (onResolve) {
    onResolve();
  }

  yield put(
    addNotification({
      message: `Assigned ${username} to cases successfully`
    })
  );
}

export function* performRemoveTagFromCase(action) {
  const {
    payload: { caseUuid, tagValue }
  }: { payload: { caseUuid: string; tagValue: string } } = action;
  try {
    yield apiPerformRemoveTagFromCase(caseUuid, tagValue);
    yield put(addNotification({ message: 'Removed tag successfully' }));
    yield put(createFetchCaseByUuid(caseUuid));
    yield call(invalidateCaseActivity, caseUuid);
  } catch (ex) {
    yield put(addNotification({ message: 'Remove tag has failed' }));
  }
}

export function* performFetchCaseSlas({
  payload: caseUuid
}: PayloadAction<string>) {
  try {
    const { json: slaData } = yield apiPerformFetchCaseSlas(caseUuid);
    yield put(addCaseSlas({ slas: slaData, caseUuid }));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_CASE_SLA_ERROR.message })
    );
  }
}

export function* performBatchUpdateCaseStatus(
  action: PayloadAction<BatchAssignStatusRequest>
) {
  const payload = action.payload;
  try {
    const promises = Promise.all(
      payload.caseUuid.map((v) => {
        return CydarmFetch.cyFetchAuthenticated(`/case/${v.uuid}`, {
          method: 'PUT',
          body: JSON.stringify({
            status: payload.caseStatus,
            locator: v.locator
          })
        });
      })
    );
    yield promises;

    if (payload.onResolve) {
      payload.onResolve();
    }
  } catch (err) {
    yield put(addNotification({ message: 'Error updating case statuses' }));
  }
}

/* Watchers */
function* watchFetchCases() {
  yield throttle(100, createFetchCases, performFetchCases);
}

function* watchFetchCaseByUuid() {
  yield takeEveryThrottledPerKey(
    createFetchCaseByUuid,
    performFetchCaseByUuid,
    ({ payload: caseUuid }) => caseUuid,
    5000
  );
}

function* watchFetchCaseAndMembersByUuid() {
  yield takeEvery(
    createFetchCaseAndMembersByUuid,
    performFetchCaseAndMembersByUuid
  );
}

function* watchFetchCaseByLocator() {
  yield takeEvery(createFetchCaseByLocator, performFetchCaseByLocator);
}

function* watchCreateCase() {
  yield takeLatest(createCase, performCreateCase);
}

function* watchUpdateCase() {
  yield takeEvery(createUpdateCase, performUpdateCase);
}

function* watchAddCaseMeta() {
  yield takeLatest(createUpdateCaseMeta, performUpdateCaseMeta);
}

function* watchAddMemberToCase() {
  yield takeEvery(createAddMemberToCase, performAddMemberToCase);
}

function* watchRemoveMemberFromCase() {
  yield takeEvery(createRemoveMemberFromCase, performRemoveMemberFromCase);
}

function* watchAddTagToCase() {
  yield takeEvery(createAddTagToCase, performAddTagToCase);
}

function* watchAddTagToCases() {
  yield takeEvery(createAddTagToCases, performAddTagToCases);
}

function* watchAssignUserToCases() {
  yield takeEvery(createAssignUserToCases, performAssignUserToCases);
}

function* watchRemoveTagFromCase() {
  yield takeEvery(createRemoveTagFromCase, performRemoveTagFromCase);
}

function* watchFetchCaseSlas() {
  yield takeEvery(createFetchCaseSlas, performFetchCaseSlas);
}

function* watchBatchUpdateCaseStatus() {
  yield takeEvery(createBatchUpdateCaseStatus, performBatchUpdateCaseStatus);
}

export default [
  watchFetchCases(),
  watchFetchCaseByUuid(),
  watchFetchCaseAndMembersByUuid(),
  watchFetchCaseByLocator(),
  watchCreateCase(),
  watchUpdateCase(),
  watchAddCaseMeta(),
  watchAddMemberToCase(),
  watchRemoveMemberFromCase(),
  watchAddTagToCases(),
  watchAssignUserToCases(),
  watchAddTagToCase(),
  watchRemoveTagFromCase(),
  watchFetchCaseSlas(),
  watchBatchUpdateCaseStatus()
];
