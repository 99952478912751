/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { css } from '@emotion/react';
import { notificationPlacement } from 'components/SnackbarsContainer/styles';
import { PropsWithChildren } from 'react';

/** @jsxImportSource @emotion/react */

type SnackbarsSeeAllButtonProps = {
  amountOfNotifications: number;
  onClick: () => void;
};

export const SnackbarsSeeAllButton = ({
  children,
  onClick,
  amountOfNotifications
}: PropsWithChildren<SnackbarsSeeAllButtonProps>) => (
  <div
    css={css`
      position: fixed;
      bottom: 0;
      left: 50%;
      z-index: 5;
      border: 1px solid #d1d1d1;
      border-radius: 50px;
      padding: 5px 12px;
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(20px) saturate(180%);
      cursor: pointer;
      display: flex;
      align-items: center;
      transform: translate(
        -50%,
        ${notificationPlacement(amountOfNotifications)}
      );
      transition:
        transform 0.3s linear,
        opacity 0.3s ease;
      opacity: ${amountOfNotifications === 0 ? 0 : 1};
      pointer-events: ${amountOfNotifications === 0 ? 'none' : 'all'};
    `}
    onClick={onClick}
  >
    {children}
  </div>
);
