/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { shallowEqual } from 'react-redux';
import { createFetchOrgs } from 'states/orgs/actions';
import {
  multiOrgSelector,
  orgHasLoadedSelector,
  orgListSelector,
  orgStatusSelector,
  singleOrgByNameSelector,
  singleOrgSelector
} from 'states/orgs/selectors';

import React from 'react';

import { useAutoEffect } from './ReduxHooks';
import { createNoop } from 'utils/Misc';
import { orgs } from 'testData/justData/data';

/**
 * @deprecated - use useOrgsHook
 */
export const useOrgs = () => {
  return useAutoEffect({
    selector: orgListSelector,
    ac: createFetchOrgs,
    equality: shallowEqual,
    statusSelector: orgStatusSelector,
    hasLoadedSelector: orgHasLoadedSelector
  });
};

/**
 * @deprecated - use useOrgsHook
 */
export const useSingleOrg = (orgUuid) => {
  return useAutoEffect({
    selector: singleOrgSelector,
    ac: createFetchOrgs,
    equality: shallowEqual,
    statusSelector: orgStatusSelector,
    hasLoadedSelector: orgHasLoadedSelector,
    selectorData: orgUuid
  });
};

/**
 * @deprecated - use useOrgsHook
 */
export const useSingleOrgByName = (orgName: string) => {
  return useAutoEffect({
    selector: singleOrgByNameSelector,
    ac: createFetchOrgs,
    equality: shallowEqual,
    statusSelector: orgStatusSelector,
    hasLoadedSelector: orgHasLoadedSelector,
    selectorData: orgName
  });
};

/**
 * @deprecated - use useOrgsHook
 */
export const useIsMultiOrgStack = () => {
  return useAutoEffect({
    selector: multiOrgSelector,
    ac: createFetchOrgs,
    equality: shallowEqual,
    statusSelector: orgStatusSelector
  });
};

type OrgHook = {
  getOrgs: typeof useOrgs;
  getOrg: typeof useSingleOrg;
  getOrgByName: typeof useSingleOrg;
  isMultiOrg: typeof useIsMultiOrgStack;
};

const OrgHookContext = React.createContext<OrgHook>({
  getOrgs: createNoop('OrgHook'),
  getOrg: createNoop('OrgHook'),
  getOrgByName: createNoop('OrgHook'),
  isMultiOrg: createNoop('OrgHook')
});

export const OrgHookProvider = (props: React.PropsWithChildren<OrgHook>) => {
  const { children, ...rest } = props;
  return (
    <OrgHookContext.Provider value={rest}>{children}</OrgHookContext.Provider>
  );
};

export const useOrgsHook = () => {
  return React.useContext(OrgHookContext);
};

export const ProductionOrgHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <OrgHookProvider
      getOrg={useSingleOrg}
      getOrgByName={useSingleOrgByName}
      getOrgs={useOrgs}
      isMultiOrg={useIsMultiOrgStack}
    >
      {props.children}
    </OrgHookProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */
export const InMemoryOrgHookProvider = (
  props: React.PropsWithChildren<Partial<OrgHook>>
) => {
  return (
    <OrgHookProvider
      getOrg={
        props.getOrg ||
        (() => ({
          data: orgs[0],
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      getOrgs={
        props.getOrgs ||
        (() => ({
          data: orgs,
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      getOrgByName={
        props.getOrgByName ||
        (() => ({
          data: orgs[0],
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      isMultiOrg={
        props.isMultiOrg ||
        (() => ({
          data: false,
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
    >
      {props.children}
    </OrgHookProvider>
  );
};
