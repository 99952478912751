/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { StixDataPanel } from 'components/_graphVisualisation/StixDataPanel';
import Viz from '../../../libs/stix2vis';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
const useCaseViewPageStixStyles = makeStyles(
  ({ spacing, shape: { borderRadius }, palette }) => ({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 50px',
      height: '100%'
    },

    svgContainer: {},
    selectedNodeData: ({ nodeDataVisible = false }: any) => ({
      minHeight: 0,
      background: alpha(palette.background.default, 0.3),
      borderRadius: 10,
      padding: 25,
      backdropFilter: 'saturate(180%) blur(20px)',
      position: 'relative',
      color: palette.text.primary,
      ...(nodeDataVisible
        ? { opacity: 1, visibility: 'visible', transition: 'opacity 0.4s ease' }
        : {
            display: 'none',
            transition: 'opacity 0.4s ease, visibility 0s 0.4s'
          }),
      '& > div': { marginBottom: 5 },
      '& strong': { textTransform: 'capitalize' }
    }),
    cancelIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
      cursor: 'pointer'
    }
  })
);

export type StixVisProps = {
  caseStixData: unknown;
};

export const StixVis = (props: StixVisProps) => {
  const { caseStixData } = props;

  const svgRef = useRef<any>();
  const [nodeData, setNodeData] = useState<any>();
  const [nodeDataVisible, setNodeDataVisble] = useState<boolean>();

  const handleClose = () => {
    setNodeDataVisble(false);
  };

  const {
    palette: {
      text: { primary }
    }
  } = useTheme();

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }

    const handleSelected = (data) => {
      setNodeData(data);
      setNodeDataVisble(true);
    };

    const vis = new Viz(
      svgRef.current,
      primary,
      undefined,
      undefined,
      //@ts-ignore this error appeared when we upgraded to typescript 4.7.4
      handleSelected
    );

    if (!caseStixData) {
      return;
    }
    const unFrozenDeepCloneOfStixData = JSON.parse(
      JSON.stringify(caseStixData)
    );
    vis.vizStix(unFrozenDeepCloneOfStixData);
    return () => {
      vis.vizReset();
    };
  }, [svgRef, primary, caseStixData]);

  const { selectedNodeData, cancelIcon } = useCaseViewPageStixStyles({
    nodeDataVisible
  });

  return (
    <div
      className="layout-frame-main-content-100"
      css={(theme) => `
        display: flex;
        border: 1px solid ${theme.palette.primary.light};
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;
      `}
    >
      <div
        css={css`
          flex: 1 1 auto;
        `}
      >
        <svg ref={svgRef} id="stix-canvas" data-testid="stix-canvas"></svg>
      </div>
      <div className={selectedNodeData} data-testid="cyd-stix-vis-panel">
        <CancelIcon onClick={handleClose} className={cancelIcon} />
        <StixDataPanel data={nodeData} />
      </div>
    </div>
  );
};
