import * as CydarmFetch from 'utils/CydarmFetch';

export async function apiPerformFetchContacts() {
  return CydarmFetch.cyFetchAuthenticated('/stix/contact', { method: 'GET' });
}

export async function apiPerformCreateContact(contactData) {
  return CydarmFetch.cyFetchAuthenticated('/stix/contact', {
    method: 'POST',
    body: JSON.stringify(contactData)
  });
}

export async function apiPerformUpdateContact(
  contactDataUuid: unknown,
  contactData: unknown
) {
  return CydarmFetch.cyFetchAuthenticated(`/stix/contact/${contactDataUuid}`, {
    method: 'PUT',
    body: JSON.stringify(contactData)
  });
}

export async function apiPerformDeleteContact(uuid) {
  return CydarmFetch.cyFetchAuthenticated(`/stix/contact/${uuid}`, {
    method: 'DELETE'
  });
}

export async function apiCreateBulkContacts(formData: FormData) {
  return CydarmFetch.cyFetchAuthenticated('/stix/contact/bulk', {
    method: 'POST',
    body: formData
  });
}
