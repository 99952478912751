/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Typography from '@mui/material/Typography';
import { Case } from 'interface/Case.interface';
import { CydCaseDetailsPanelSection } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanelSection';
import { CydContributorsDisplayByCaseUuid } from '../CydContributorsDisplay/CydContributorsDisplayByCaseUuid';
import { CydWatchersDisplayByCaseUuid } from 'components/_caseView/CydWatchersDisplay/CydWatchersDisplayByCaseUuid';
import { useCheckAccessPermissionsForObjects } from 'hooks/AclHooks';
import { CydCaseViewTagsSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewTagsSection/CydCaseViewTagsSection';
import { CydCaseViewPlaybookSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewPlaybookSection/CydCaseViewPlaybookSection';
import { CydCaseViewMetadataSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewMetadataSection/CydCaseViewMetadataSection';
import { CydTimeDisplay } from 'components/_objectDisplay/CydTimeDisplay/CydTimeDisplay';
import { CydCaseViewAclSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewAclSection/CydCaseViewAclSection';
import { CydCaseViewMemberSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewMemberSection/CydCaseViewMemberSection';
import { CydCaseViewParentSection } from 'components/_caseView/CydCaseDetailsPanel/_sections/CydCaseViewParentSection/CydCaseViewParentSection';
import { CydCaseViewSlaPanel } from './_sections/CydCaseViewSlaPanel/CydCaseViewSlaPanel';
import { useSlas } from 'hooks/SlaHooks';

type CydCaseDetailsPanelProps = {
  cydarmCase: Case;
};

// See note about this component, and it's sub components here:
// Below PR was not merged, but reference to it, if we want it.
// https://github.com/cydarm/cydarm-frontend/pull/1220/files#diff-cdf034ff79d9c0140e6829e6cef9debe2d1efa4d688e0bafa404c956947afcd8R29

export const CydCaseDetailsPanel = ({
  cydarmCase
}: CydCaseDetailsPanelProps) => {
  const { uuid: caseUuid } = cydarmCase;
  const checkResourcePermissions = useCheckAccessPermissionsForObjects();

  const { canUpdate: canEdit, canManage } =
    checkResourcePermissions(cydarmCase);

  const {
    locator,
    modified,
    created,
    acl: aclUuid,
    metadata,
    tags
  } = cydarmCase;

  const slasResult = useSlas(cydarmCase.uuid);
  const slas = slasResult.data;
  return (
    <>
      <CydCaseDetailsPanelSection title="Contributors">
        <CydContributorsDisplayByCaseUuid caseUuid={caseUuid} />
      </CydCaseDetailsPanelSection>

      <CydCaseDetailsPanelSection title="Watchers">
        <CydWatchersDisplayByCaseUuid caseUuid={caseUuid} />
      </CydCaseDetailsPanelSection>
      <CydCaseViewAclSection
        aclUuid={aclUuid}
        caseUuid={caseUuid}
        locator={locator}
        disabled={!canManage}
      />
      <CydCaseViewTagsSection caseUuid={caseUuid} tags={tags} />
      <CydCaseViewPlaybookSection caseUuid={caseUuid} />
      <CydCaseViewMetadataSection
        caseUuid={caseUuid}
        metadata={metadata}
        isEditable={canEdit}
      />

      <CydCaseViewSlaPanel slas={slas} />

      <CydCaseViewParentSection caseUuid={caseUuid} isEditable={canEdit} />
      <CydCaseViewMemberSection caseUuid={caseUuid} isEditable={canEdit} />
      <Typography variant="caption" color="text.secondary">
        Created:&nbsp; <CydTimeDisplay time={created} />
        <br />
        Last Modified:&nbsp; <CydTimeDisplay time={modified} />
      </Typography>
    </>
  );
};
