/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import DialogContent from '@mui/material/DialogContent';
import { css } from '@emotion/react';
import { ReactNode, FC } from 'react';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

/** @jsxImportSource @emotion/react */

interface ITransitionsModal {
  isOpen: boolean;
  onClose?: () => void;
  hideClose?: boolean;
  children: ReactNode;
  scrollingModal?: boolean;
}

const TransitionsModal: FC<ITransitionsModal> = ({
  isOpen,
  onClose,
  hideClose = false,
  scrollingModal = false,
  children
}) => {
  const handleClose = () => {
    !!onClose && onClose();
  };

  return (
    <Modal
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <DialogContent
          css={(theme) => `
            background-color: ${theme.palette.background.paper};
            border: 2px solid ${theme.palette.background.default};
            box-shadow: ${theme.shadows[5]};
            && {
              padding: ${theme.spacing(4)};
            }
            flex: 0 1 auto;
            border-radius: 8px;
            max-width: 400px;
            outline: none;
            ${
              scrollingModal &&
              `
              overflow-y: scroll;
              max-height: 90vh;
            `
            }
          `}
        >
          <>{children}</>
          {!hideClose && (
            <CydButton
              css={css`
                display: block;
                margin: 8px 0 0 auto;
              `}
              variant="outlined"
              onClick={handleClose}
            >
              Close
            </CydButton>
          )}
        </DialogContent>
      </Fade>
    </Modal>
  );
};

export default TransitionsModal;
