/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

// Connector.interface import has to be relative path, otherwise, it will fail in Cypress test
import { EventTriggerType } from './interface/Connector.interface';

export const DRAG_AND_DROP_HANDLE = {
  CASE_GROUP_MANAGER: 'CASE_GROUP_MANAGER',
  SIDE_BAR_USER_LIST: 'SIDE_BAR_USER_LIST',
  CASE_MEMBER_LIST: 'CASE_MEMBER_LIST'
};

export const SYSTEM_PROPERTY = {
  VERSION: 'CYDARM_VERSION',
  REVIEW_TAG: 'CASE_REVIEW_TAG',
  CLASSIFICATION_TEXT_RGB: 'CLASSIFICATION_TEXT_RGB',
  CLASSIFICATION_BG_RGB: 'CLASSIFICATION_BG_RGB',
  CLASSIFICATION_TEXT: 'CLASSIFICATION_TEXT',
  NAME_FORMAT: 'DISPLAY_NAME_FORMAT',
  USER_ROLE_CATEGORY: 'USER_ROLE_CATEGORY',
  DATA_POLL_TIME: 'DATA_POLL_TIME',
  CASE_ASSIGNEE_GROUPS: 'CASE_ASSIGNEE_GROUPS',
  ACTION_ASSIGNEE_GROUPS: 'ACTION_ASSIGNEE_GROUPS',
  CASE_CREATION_FIELDS: 'CASE_CREATE_EXTRA_FIELDS',
  PASSWORD_COMPLEXITY: 'PASSWORD_COMPLEXITY',
  JSON_SYNTAX_STYLE: 'JSON_SYNTAX_STYLE',
  STATUS_CHANGE_MODAL: 'STATUS_CHANGE_MODAL',
  CUSTOM_THEME_DEFINITION: 'CUSTOM_THEME_DEFINITION',
  COMMENT_CHAR_LIMIT: 'COMMENT_CHAR_LIMIT',
  DASHBOARD_MEAN_TIME_TO_CLOSE_STATE: 'DASHBOARD_MEAN_TIME_TO_CLOSE_STATE',
  DASHBOARD_MEAN_TIME_TO_CONTAINMENT_STATE:
    'DASHBOARD_MEAN_TIME_TO_CONTAINMENT_STATE'
};

export const LOCAL_STORAGE = {
  TIME_ZONE: 'TIMEZONE',
  STORED_TIME_ZONE_LIST: 'STORED_TIME_ZONE_LIST'
};

export const DISPLAY_NAME = {
  FALLBACK_FORMAT: '%F %L',
  MACROS: [
    ['%F', 'givenName'],
    ['%L', 'familyName'],
    ['%U', 'username'],
    ['%E', 'email'],
    ['%R', 'userRole'],
    ['%O', 'userOrg']
  ]
};

export const CASE_FILTER = {
  CASE_LIST_VIEW_FILTERS: 'CASE_LIST_VIEW_FILTERS',
  CASE_LIST_FILTER_DEFAULT: 'CASE_LIST_FILTER_DEFAULT'
};

export const MIMETYPES = {
  JSON_FORM: 'application/vnd.cydarm.form+json'
};

export const ACLS = {
  SYSPROP_PRIVATE: 'sysprop private',
  BASE_USER_READ: 'base user read',
  CASE_DATA_DEFAULT: 'case data defaults'
};

export const USER_PROPERTY = {
  MAX_COMMENT_HEIGHT: 'MAX_COMMENT_HEIGHT',
  THEME_MODE: 'THEME_MODE',
  FAVOURITE_TIMEZONE_LIST: 'FAVOURITE_TIMEZONE_LIST',
  ROWS_PER_PAGE: 'ROWS_PER_PAGE'
};

export const CASE_CREATED_LABEL = 'Case created';
export const CASE_ASSIGNEE_UPDATED_LABEL = 'Case assignee updated';
export const CASE_STATUS_UPDATED_LABEL = 'Case status updated';
export const CASE_UPDATED_LABEL = 'Case updated';

// commented out the events that are currently not supported
export const TRIGGERED_AUTOMATION_EVENT_TYPE_LABELS: Array<{
  value: EventTriggerType;
  label: string;
}> = [
  // { value: EventTriggerType.ConfigUpdated, label: 'Config updated' },
  { value: EventTriggerType.CaseCreated, label: CASE_CREATED_LABEL },
  // { value: EventTriggerType.CaseUpdated, label: 'Case updated' },
  {
    value: EventTriggerType.CaseAssigneeUpdated,
    label: CASE_ASSIGNEE_UPDATED_LABEL
  },
  {
    value: EventTriggerType.CaseStatusUpdated,
    label: CASE_STATUS_UPDATED_LABEL
  },
  {
    value: EventTriggerType.CaseUpdated,
    label: CASE_UPDATED_LABEL
  }
  /*
    { value: EventTriggerType.CaseTagAdded, label: 'Case tag added' },
    { value: EventTriggerType.CaseDataCreated, label: 'Case data created' },
    {
      value: EventTriggerType.CaseDataCreatedStixCyberObs,
      label: 'Case STIX data created'
    },
    {
      value: EventTriggerType.CaseDataCreatedForm,
      label: 'Case form submission created'
    }
  */
];

export const CYDARM_BASE_API = '/cydarm-api';
export const PREVIEW_MAX_SIZE_BYTES = 5_000_000;
export const DEFAULT_ROWS_PER_PAGE_SETTING = 10;
