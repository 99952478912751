/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydTextField } from 'components/_formElements/CydTextField';
import { InputAdornment, Typography, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { ICaseSeverity } from 'interface/CaseSeverity.interface';
import { User } from 'interface/User.interface';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { CydSeveritySelector } from 'components/_objectSelectors/CydSeveritySelector/CydSeveritySelector';
import { CydUserSelector } from 'components/_objectSelectors/CydUserSelector/CydUserSelector';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { CydOrganisationDisplay } from 'components/_objectDisplay/CydOrganisationDisplay/CydOrganisationDisplay';
import { Org } from 'interface/Org.interface';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { CydCaseStatusDirectionGraph } from 'components/_misc/CydCaseStatusDirectionGraph/CydCaseStatusDirectionGraph';
import { CydCopyClicker } from 'components/_misc/CydCopyClicker/CydCopyClicker';
import { LocatorCell } from 'components/tables/DetailCasesTable/TableRow/LocatorCell';

export type CydCaseViewHeaderProps = {
  title: string;
  onChangeTitle: (newTitle: string) => void;
  locator: string;

  availableOrganisations?: Array<Org>;
  onChangeOrg?: (newOrg: Org) => void;

  availableSeverities: Array<ICaseSeverity>;
  selectedSeverity: ICaseSeverity | null;
  onChangeSeverity: (newSeverity: ICaseSeverity) => void;
  availableStatuses: Array<string>; // This is what useCaseTransitionableStates return
  selectedStatus: string | null;
  onChangeStatus: (newStatus: string) => void;
  availableUsers: Array<User>;
  assignee: User | null | 'unavailable-user';
  onChangeAssignee: (newAssignee: User | null) => void;
  onAddToWatchList: () => void;
  onRemoveFromWatchList: () => void;
  isOnWatchList: boolean;
  onDownloadReport?: () => void;

  canEdit: boolean;
  canManage?: boolean;
  organisation: Org;

  dontShowOrg?: boolean;
};

const EMPTY_ARRAY = []; //for use in defaulting.
const EMPTY_FUNCTION = () => {};

export const CydCaseViewHeader = (props: CydCaseViewHeaderProps) => {
  const {
    organisation,
    title,
    onChangeTitle,
    locator,
    selectedSeverity,
    onChangeSeverity,
    selectedStatus,
    onChangeStatus,
    assignee,
    onChangeAssignee,
    onDownloadReport,
    isOnWatchList,
    onAddToWatchList,
    onRemoveFromWatchList,
    canEdit,
    canManage = false,
    availableSeverities,
    availableStatuses,
    availableUsers,
    dontShowOrg = false,

    availableOrganisations = EMPTY_ARRAY as Array<Org>,
    onChangeOrg = EMPTY_FUNCTION
  } = props;

  const disabled = !canEdit;
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  return (
    <>
      <div
        css={(theme) => `
          display: flex; 
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-start;
        `}
      >
        <div
          css={css`
            margin-right: 1rem;
            height: 60px; // prevent this from jumping around
            width: 100%;
          `}
        >
          {/* Show header title and edit button ✏️  - Not defaultPanel, wrap it in a div (Will change on Monday) */}
          {!isEditingTitle && (
            <div
              css={(theme) => `
          
            display: flex; 
            flex-flow: row nowrap; 
            align-items: center; 
          `}
            >
              <CydCopyClicker stringToCopy={`${locator} ${title}`}>
                <Typography
                  variant="h5"
                  color="text.primary"
                  aria-label={title}
                >
                  {title}
                </Typography>
              </CydCopyClicker>

              <CydIconButton
                label="Edit Title"
                icon="edit"
                css={(theme) => `
        `}
                disabled={disabled}
                onClick={() => setIsEditingTitle(true)}
              />
            </div>
          )}

          {/* Show input text field */}

          {isEditingTitle && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setIsEditingTitle(false);

                const newValue = e.target['title'].value;
                if (newValue !== title) {
                  onChangeTitle(newValue);
                }
              }}
            >
              <CydTextField
                css={(theme) => `
              max-width: 710px; 
              `}
                label="Edit case name"
                defaultValue={title}
                name="title"
                onKeyDown={(e) => {
                  if (e.code === 'Escape') {
                    setIsEditingTitle(false);
                  }
                }}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      css={(theme) => `
                    color: ${theme.palette.text.secondary};
                  `}
                    >
                      <IconButton
                        aria-label="Cancel editing"
                        onClick={() => setIsEditingTitle(false)}
                      >
                        <CloseIcon />
                      </IconButton>

                      <IconButton aria-label="Submit" type="submit">
                        <DoneIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </form>
          )}
        </div>

        <CydButtonStack
          justifyContent="flex-end"
          css={css`
            padding: 0;
            margin: 0;
          `}
        >
          <CydButton
            variant="outlined"
            startIcon={isOnWatchList ? 'bookmarkFilled' : 'bookmarkOutlined'}
            onClick={() =>
              !isOnWatchList ? onAddToWatchList() : onRemoveFromWatchList()
            }
          >
            {isOnWatchList ? 'Watchlisted' : 'Add to watchlist'}
          </CydButton>
          {onDownloadReport && (
            <CydButton
              variant="outlined"
              startIcon="download"
              onClick={onDownloadReport}
            >
              Download report
            </CydButton>
          )}
        </CydButtonStack>
      </div>

      <div
        css={(theme) => `

            display: flex; 
            flex-flow: row nowrap; 
            gap: ${theme.spacing(4)};
            align-items: center; 

            &>div {
              width: 150px; 

              &.locator {
                width: auto; 
              }

              &.organisation {
                width: auto; 
              }
            }
        `}
      >
        <div className="locator">
          <LocatorCell locator={locator} />
        </div>

        {/* https://cydarm.atlassian.net/browse/RM-2605 */}
        {!dontShowOrg &&
          (canManage ? (
            <CydSimpleSelect
              size="small"
              label="Organization"
              availableOptions={availableOrganisations}
              onChange={onChangeOrg}
              selectedOption={organisation}
              generateOptionLabel={(v) => v.name}
            />
          ) : (
            <div className="organisation">
              <CydOrganisationDisplay
                css={(theme) => `
          color: ${theme.palette.text.primary};
          font-weight: 600;
          `}
                organisation={organisation}
              />
            </div>
          ))}
        <div>
          <CydSeveritySelector
            disabled={disabled}
            variant="color-small"
            availableSeverities={availableSeverities}
            selectedSeverity={selectedSeverity}
            onChange={onChangeSeverity}
            label="Severity"
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-flow: row nowrap;
          `}
        >
          <CydSimpleSelect
            disabled={disabled}
            size="small"
            label="Status"
            availableOptions={availableStatuses}
            selectedOption={selectedStatus}
            onChange={onChangeStatus}
            generateOptionLabel={(v) => v}
          />

          <CydCaseStatusDirectionGraph currentStatus={selectedStatus} />
        </div>
        <div
          css={css`
            // Deal with the chip/button not having the margins that inputs do.
            padding-top: 2px;
          `}
        >
          <CydUserSelector
            label="Assign user"
            disabled={disabled}
            variant="color-small"
            allowUnselect={true}
            availableUsers={availableUsers}
            selectedUser={assignee}
            onChange={onChangeAssignee}
          />
        </div>
      </div>
    </>
  );
};
