/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import {
  IPlaybookActionType,
  PlaybookAction
} from 'interface/Playbook.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchPlaybookActions } from './actions';

export interface IPlaybookActionsStoreState {
  isLoading: boolean;
  hasLoaded: boolean;
  error: ErrorPayload | undefined;
  list: IPlaybookActionType[];
}

const initialState: IPlaybookActionsStoreState = {
  list: [],
  isLoading: false,
  hasLoaded: false,
  error: undefined
};

const playbookActionSlice = createSlice({
  name: 'playbookActions',
  initialState,
  reducers: {
    fetchPlaybookActionsSuccess: (
      state,
      { payload }: PayloadAction<IPlaybookActionType[]>
    ) => {
      state.list = payload;
      state.isLoading = false;
      state.hasLoaded = true;
    },

    fetchPlaybookActionsFailed: (
      state,
      { payload }: PayloadAction<ErrorPayload>
    ) => {
      state.error = payload;
      state.isLoading = false;
    },

    addSinglePlaybookActionToStore: (
      state,
      { payload }: PayloadAction<PlaybookAction>
    ) => {
      state.list = [
        ...state.list.filter((el) => el.atc?.uuid !== payload.uuid)
      ];
    },

    deletePlaybookActionFailed: (
      state,
      { payload }: PayloadAction<ErrorPayload>
    ) => {
      state.isLoading = false;
      state.error = payload;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchPlaybookActions, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: playbookActions,
  actions: {
    fetchPlaybookActionsSuccess,
    fetchPlaybookActionsFailed,
    addSinglePlaybookActionToStore,
    deletePlaybookActionFailed
  }
} = playbookActionSlice;
