import { ICaseMeta } from 'interface/Case.interface';

export type TransformMetadataToUpdatePayloadReturnValue = {
  create: Array<{
    name: string;
    value: string;
  }>;
  update: Array<ICaseMeta>;
  delete: Array<string>;
};

export const transformMetadataToUpdatePayload = (
  existingMetadata: { [key: string]: ICaseMeta },
  newData: { [key: string]: string }
): TransformMetadataToUpdatePayloadReturnValue => {
  const create: Array<{
    name: string;
    value: string;
  }> = [];
  const update: Array<ICaseMeta> = [];
  const deleteItems: Array<string> = [];

  const newDataKeys = Object.keys(newData);
  const existingMetadataKeys = Object.keys(existingMetadata);

  newDataKeys.forEach((key) => {
    const currExistingMetadata = existingMetadata[key];
    const newValue = newData[key];

    // not exist in the existingMetadata, create it
    if (!currExistingMetadata) {
      create.push({
        name: key,
        value: newValue
      });
    }
    // exist in the existingMetadata, check if need to update
    else if (currExistingMetadata) {
      // if the new value is different from the existing value, update it
      if (currExistingMetadata.value !== newValue) {
        update.push({
          ...currExistingMetadata,
          value: newValue
        });
      }
    }
  });

  // look for deleted items
  existingMetadataKeys.forEach((key) => {
    const currExistingData = existingMetadata[key];
    if (!newData[key]) {
      deleteItems.push(currExistingData.uuid!);
    }
  });
  return {
    create,
    update,
    delete: deleteItems
  };
};
