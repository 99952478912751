/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydMultiSelect } from 'components/_formElements/CydMultiSelect/CydMultiSelect';
import { CydTextField } from 'components/_formElements/CydTextField';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { User } from 'interface/User.interface';
import { useDebounce } from 'hooks/DebounceHooks';
import { useEffect, useState } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydDateRangePicker } from 'components/_formElements/CydDateRangePicker/CydDateRangePicker';
import { CydSignificanceLevelFilterSelect } from '../CydSignificanceLevelFilterSelect/CydSignificanceLevelFilterSelect';

export type CaseActivityFilterSetting = {
  postedBy: Array<User>;
  fromDate: string | null;
  toDate: string | null;
  commentSearch: string | null;
  significanceLevel: Array<DataSignificance>;
};

export type CydCaseViewActivityFilterProps = {
  filterSettings: CaseActivityFilterSetting;
  availableUsers: Array<User>;
  availableSignificanceLevels: Array<DataSignificance>;
  onChange: (settings: CaseActivityFilterSetting) => void;
  debounceRate?: number;
};

export const CydCaseViewActivityFilter = (
  props: CydCaseViewActivityFilterProps
) => {
  const {
    filterSettings,
    availableUsers,
    availableSignificanceLevels,
    onChange,
    debounceRate = 300
  } = props;

  const handleCommentSearchChange = useDebounce(
    (data: string) => {
      onChange({ ...filterSettings, commentSearch: data });
    },
    [onChange, filterSettings],
    debounceRate
  );

  const [searchString, setSearchString] = useState(
    filterSettings.commentSearch
  );

  useEffect(() => {
    handleCommentSearchChange(searchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return (
    <div
      css={(theme) => `
          display: flex; 
          flex-flow: row wrap; 
          flex: 1 1 auto; 
          gap: ${theme.spacing(3)}
        `}
    >
      <CydMultiSelect
        availableOptions={availableUsers}
        selectedOptions={filterSettings.postedBy}
        generateOptionLabel={(v) => v.displayName || v.uuid}
        label="Posted by"
        fullWidth={false}
        css={css`
          width: 200px;
        `}
        onChange={(v) => {
          onChange({
            ...filterSettings,
            postedBy: v
          });
        }}
      />
      <CydDateRangePicker
        initialValue={[filterSettings.fromDate, filterSettings.toDate]}
        onComplete={(fromDate, toDate) => {
          onChange({
            ...filterSettings,
            fromDate,
            toDate
          });
        }}
      />

      <CydSignificanceLevelFilterSelect
        availableOptions={availableSignificanceLevels}
        selectedOptions={filterSettings.significanceLevel}
        css={css`
          width: 200px;
        `}
        label="Significance level"
        onChange={(v) => {
          onChange({
            ...filterSettings,
            significanceLevel: v
          });
        }}
      />

      <CydTextField
        name="content"
        fullWidth={false}
        value={searchString}
        onChange={setSearchString}
        label="Search comments"
      />
    </div>
  );
};
