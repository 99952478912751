/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Additional metadata fields
 * @export
 * @interface ObjectMetadataSet
 */
export interface ObjectMetadataSet {
    /**
     * Whether the object can be deleted by the session
     * @type {boolean}
     * @memberof ObjectMetadataSet
     */
    deletable: boolean;
    /**
     * Whether the object can be updated by the session
     * @type {boolean}
     * @memberof ObjectMetadataSet
     */
    updatable: boolean;
    /**
     * Whether the object can be edited by the session
     * @type {boolean}
     * @memberof ObjectMetadataSet
     */
    editable: boolean;
    /**
     * Whether the object can be managed by the session
     * @type {boolean}
     * @memberof ObjectMetadataSet
     */
    manageable: boolean;
    /**
     * The UUID of the creator of the object
     * @type {string}
     * @memberof ObjectMetadataSet
     */
    creator_uuid?: string;
    /**
     * The datetime when the object was created
     * @type {Date}
     * @memberof ObjectMetadataSet
     */
    created_date?: Date;
    /**
     * The UUID of the last modifier of the object
     * @type {string}
     * @memberof ObjectMetadataSet
     */
    last_modifier_uuid?: string;
    /**
     * The datetime when the object was last modified
     * @type {Date}
     * @memberof ObjectMetadataSet
     */
    last_modified_date?: Date;
}

/**
 * Check if a given object implements the ObjectMetadataSet interface.
 */
export function instanceOfObjectMetadataSet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deletable" in value;
    isInstance = isInstance && "updatable" in value;
    isInstance = isInstance && "editable" in value;
    isInstance = isInstance && "manageable" in value;

    return isInstance;
}

export function ObjectMetadataSetFromJSON(json: any): ObjectMetadataSet {
    return ObjectMetadataSetFromJSONTyped(json, false);
}

export function ObjectMetadataSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectMetadataSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deletable': json['deletable'],
        'updatable': json['updatable'],
        'editable': json['editable'],
        'manageable': json['manageable'],
        'creator_uuid': !exists(json, 'creator_uuid') ? undefined : json['creator_uuid'],
        'created_date': !exists(json, 'created_date') ? undefined : json['created_date'],
        'last_modifier_uuid': !exists(json, 'last_modifier_uuid') ? undefined : json['last_modifier_uuid'],
        'last_modified_date': !exists(json, 'last_modified_date') ? undefined : json['last_modified_date'],
    };
}

export function ObjectMetadataSetToJSON(value?: ObjectMetadataSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deletable': value.deletable,
        'updatable': value.updatable,
        'editable': value.editable,
        'manageable': value.manageable,
        'creator_uuid': value.creator_uuid,
        'created_date': value.created_date,
        'last_modifier_uuid': value.last_modifier_uuid,
        'last_modified_date': value.last_modified_date,
    };
}

