import { IAssetForm } from 'interface/Assets.interface';
import * as CydarmFetch from 'utils/CydarmFetch';

export async function apiFetchAssets() {
  return CydarmFetch.cyFetchAuthenticated('/stix/asset', { method: 'GET' });
}

export async function apiCreateAsset(asset: unknown) {
  return CydarmFetch.cyFetchAuthenticated('/stix/asset', {
    method: 'POST',
    body: JSON.stringify(asset)
  });
}

export async function apiUpdateAsset(asset: IAssetForm) {
  return CydarmFetch.cyFetchAuthenticated(`/stix/asset/${asset.uuid}`, {
    method: 'PUT',
    body: JSON.stringify(asset)
  });
}

export async function apiDeleteAsset(uuid) {
  return CydarmFetch.cyFetchAuthenticated(`/stix/contact/${uuid}`, {
    method: 'DELETE'
  });
}

export async function apiCreateBulkAssets(formData: FormData) {
  const result = CydarmFetch.cyFetchAuthenticated('/stix/asset/bulk', {
    method: 'POST',
    body: formData
  });
  return result;
}
