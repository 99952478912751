import {
  DraftSystemPropertyInterface,
  SystemPropertyInterface
} from 'interface/SystemProperty.interface';
import { cyFetchAuthenticated } from 'utils/CydarmFetch';
import { Base64Decode, Base64Encode } from 'utils/StringUtils';

export async function fetchAllSysProp(): Promise<
  Array<SystemPropertyInterface>
> {
  const result = await cyFetchAuthenticated(`/sys-prop`, {
    method: 'GET'
  });

  const parsedResult = result.json.map(({ value, ...rest }) => {
    return {
      value: Base64Decode(value),
      ...rest
    };
  });

  //

  return parsedResult as Array<SystemPropertyInterface>;
}

export async function fetchSysProp(
  sysPropName: string
): Promise<SystemPropertyInterface> {
  const result = await cyFetchAuthenticated(`/sys-prop/${sysPropName}`, {
    method: 'GET'
  });

  return result.json as SystemPropertyInterface;
}
export async function deleteSysProp(uuid: string) {
  const result = await cyFetchAuthenticated(`/sys-prop/${uuid}`, {
    method: 'DELETE'
  });

  return result.json;
}

export async function createSysProp(sysProp: DraftSystemPropertyInterface) {
  //TODO, what does this thing return?

  const encodedString = Base64Encode(sysProp.value);

  const result = await cyFetchAuthenticated(`/sys-prop`, {
    method: 'POST',
    body: JSON.stringify({
      name: sysProp.name,
      value: encodedString,
      acl: sysProp.acl,
      encrypt: sysProp.encrypt || false
    })
  });

  return result.json;
}

export async function updateSysProp(sysProp: SystemPropertyInterface) {
  const encodedString = Base64Encode(sysProp.value);
  const result = await cyFetchAuthenticated(`/sys-prop/${sysProp.uuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      name: sysProp.name,
      value: encodedString,
      acl: sysProp.acl,
      encrypt: sysProp.encrypt || false
    })
  });

  return result.json;
}
