/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  FETCH_PLAYBOOKS_ERROR: {
    code: 'FETCH_PLAYBOOKS_ERROR',
    message: 'Error occurred while fetching playbooks'
  },
  FETCH_PLAYBOOK_BY_UUID_ERROR: {
    code: 'FETCH_PLAYBOOK_BY_UUID_ERROR',
    message: 'Error occurred while fetching playbook with uuid'
  },
  CREATE_PLAYBOOK_ERROR: {
    code: 'CREATE_PLAYBOOK_ERROR',
    message: 'Error occurred while creating playbook'
  },
  UPLOAD_PLAYBOOK_CONFLICT_ERROR: {
    code: 'UPLOAD_PLAYBOOK_CONFLICT_ERROR',
    message: 'Error occurred while importing file: playbook or action exists'
  },
  UPLOAD_PLAYBOOK_NOCONTENT_ERROR: {
    code: 'UPLOAD_PLAYBOOK_CONFLICT_ERROR',
    message: 'Error occurred while importing file: tags or action not found'
  },
  UPLOAD_PLAYBOOK_FILE_ERROR: {
    code: 'UPLOAD_PLAYBOOK_FILE_ERROR',
    message: 'Error occurred while uploading file: '
  },
  UPDATE_PLAYBOOK_ERROR: {
    code: 'UPDATE_PLAYBOOK_ERROR',
    message: 'Error occurred while updating playbook'
  },
  EXPORT_PLAYBOOK_ERROR: {
    code: 'EXPORT_PLAYBOOK_ERROR',
    message: 'Error occurred while exporting playbook'
  },
  DELETE_PLAYBOOK_ERROR: {
    code: 'DELETE_PLAYBOOK_ERROR',
    message: 'Error occurred while deleting playbook'
  },
  ADD_ACTION_TO_PLAYBOOK_ERROR: {
    code: 'ADD_ACTION_TO_PLAYBOOK_ERROR',
    message: 'Error occurred while adding action to playbook'
  },
  REMOVE_ACTION_FROM_PLAYBOOK_ERROR: {
    code: 'REMOVE_ACTION_FROM_PLAYBOOK_ERROR',
    message: 'Error occurred while removing action from playbook'
  },
  ADD_TAG_TO_PLAYBOOK_ERROR: {
    code: 'ADD_TAG_TO_PLAYBOOK_ERROR',
    message: 'Error occurred while adding tag to playbook'
  },
  REMOVE_TAG_FROM_PLAYBOOK_ERROR: {
    code: 'REMOVE_TAG_FROM_PLAYBOOK_ERROR',
    message: 'Error occurred while removing tag from playbook'
  },
  DELETE_PLAYBOOK_ACTION_ERROR: {
    code: 'DELETE_ACTION_FROM_PLAYBOOK_ERROR',
    message: 'Error occurred while deleting playbook action'
  },
  DUPLICATE_PLAYBOOK_ERROR: {
    code: 'DUPLICATE_PLAYBOOK_ERROR',
    message: 'Error occurred while duplicating playbook'
  },
  UPDATE_POSIITION_PLAYBOOK_ERROR: {
    code: 'UPDATE_POSIITION_PLAYBOOK_ERROR',
    message:
      'Error occurred while updating playbook action position in playbook'
  }
};

export { ERROR_MESSAGE };
