/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import * as React from 'react';
import { useConfirmDialogStyles, dialogBackgroundProps } from './style';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

export type IConfirmDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  onConfirm: () => void;
  heading?: React.ReactNode;
  confirmButtonLabel?: React.ReactNode;
  dismissButtonLabel?: React.ReactNode;
  content?: React.ReactNode;
  open: boolean;
};

export const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  onClose,
  onConfirm,
  title,
  confirmButtonLabel = 'Ok',
  dismissButtonLabel = 'Cancel',
  content,
  open,
  ...rest
}) => {
  const {
    header,
    content: contentClass,
    dialogWrapper
  } = useConfirmDialogStyles();

  return (
    <Dialog
      classes={{ paper: dialogWrapper }}
      onClose={onClose}
      maxWidth="xs"
      BackdropProps={dialogBackgroundProps}
      open={open}
      {...rest}
    >
      {title && <DialogTitle className={header}>{title}</DialogTitle>}
      <DialogContent className={contentClass}>
        <Typography>{content}</Typography>
        <CydButtonStack justifyContent="flex-end">
          {dismissButtonLabel && (
            <CydButton onClick={onClose} variant="text">
              {dismissButtonLabel}
            </CydButton>
          )}
          {confirmButtonLabel && (
            <CydButton
              data-testid="btn-confirm"
              variant="contained"
              onClick={onConfirm}
            >
              {confirmButtonLabel}
            </CydButton>
          )}
        </CydButtonStack>
      </DialogContent>
    </Dialog>
  );
};
