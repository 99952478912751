/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CydLeftSideBar } from 'components/_layout/CydLeftSideBar/CydLeftSideBar';
import { useCurrentDataAcls, useDefaultAclForOrg } from 'hooks/AclHooks';

import { useCreateWikiPage, useWikiStubs } from 'hooks/WikiHooks';
import { Outlet } from 'react-router';

import { WikiPageLeftNav } from './WikiPageLeftNav/WikiPageLeftNav';
import { CydPageSubNav } from 'components/_layout/CydPageSubNav/CydPageSubNav';
import { CydarmRoute } from 'interface/CydarmRoute';

export type WikiPageProps = {};

export const WikiPage = (props: WikiPageProps) => {
  const wikiPagesResult = useWikiStubs();

  const createWikiPageMutation = useCreateWikiPage();

  const availableAclsResult = useCurrentDataAcls();
  const defaultAcl = useDefaultAclForOrg();

  const isLoading = wikiPagesResult.isLoading;

  if (wikiPagesResult.isError) {
    throw new Error('Error fetching wikiPages');
  }

  if (!wikiPagesResult.isLoading && !wikiPagesResult.data) {
    throw new Error("It's not loading or error, but there was no data");
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <CydPageSubNav
            currentNavValueFn={(navLinks, location) => {
              const baseNavLink = navLinks[0];

              const item = navLinks.find((v) => {
                return location.pathname.startsWith(v.to) && v !== baseNavLink;
              });

              if (item) {
                return item.to;
              }

              return baseNavLink.to;
            }}
            navItems={[
              {
                to: CydarmRoute.WIKI,
                label: 'Wiki'
              }
            ]}
          />
          <CydLeftSideBar>
            <WikiPageLeftNav
              wikiStubs={wikiPagesResult.data}
              availableAcls={availableAclsResult.data}
              defaultAcl={defaultAcl.data}
              onAddPage={createWikiPageMutation.mutate}
            />
          </CydLeftSideBar>

          <Outlet />
        </div>
      )}
    </>
  );
};
