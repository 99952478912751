/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { ButtonGroup } from '@mui/material';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';

import { AvailableStepStatuses, GenericPlaybook } from '../../type';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { lightGreen, yellow } from '@mui/material/colors';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
export type StatusSelectorProps = {
  onChange: (newStatus: AvailableStepStatuses) => void;
  currentStatus: AvailableStepStatuses;
  mode: GenericPlaybook['playbookType'];
};

function handleNever(arg: never, message: string): never {
  throw new Error(message);
}

//Based on this conversation https://cydarm.slack.com/archives/C03KV3JSMAS/p1678921098922909
export function getValidStepTransitions(
  currentStatus: AvailableStepStatuses,
  mode: GenericPlaybook['playbookType']
): Array<AvailableStepStatuses> {
  if (mode !== 'cacao') {
    if (currentStatus === 'ready') {
      return ['ready', 'success']; // We use the second item to denote the suggested action
    }
    return ['success', 'ready'];
  }

  switch (currentStatus) {
    case 'blocked':
    case 'awaiting input':
    case 'success':
    case 'failed':
    case 'exception': {
      return [currentStatus];
    }

    case 'ready': {
      return ['ready', 'in progress', 'success', 'failed', 'exception'];
    }

    case 'in progress': {
      return ['in progress', 'success', 'failed', 'exception'];
    }
    default: {
      handleNever(currentStatus, `Step status '${currentStatus}' not handled.`);
    }
  }
}

const StatusColorMap = {
  success: lightGreen['500'],
  'in progress': yellow[500]
};

export const StatusSelector = (props: StatusSelectorProps) => {
  const { onChange, currentStatus, mode } = props;

  const statesToUse = getValidStepTransitions(currentStatus, mode);

  const { isToggleEnabled } = useFeatureToggles();
  const isUsingQuickTransition = isToggleEnabled(
    'REACT_APP_USE_QUICK_STATUS_TRANSITION'
  );

  // This is not fully developed, it's a POC with the feature flag off.
  if (isUsingQuickTransition) {
    return (
      <>
        <ButtonGroup>
          <CydSimpleSelect
            css={css`
              .MuiOutlinedInput-root {
                border-radius: 4px 0px 0px 4px;
                position: relative;
                top: -1px;
              }
            `}
            disabled={statesToUse.length === 1}
            availableOptions={statesToUse}
            selectedOption={currentStatus}
            onChange={onChange}
            label="Step Status"
            size="small"
            generateOptionLabel={(v) => v}
          />
          <CydButton
            onClick={() => onChange(statesToUse[1])}
            startIcon="forward"
            size="small"
            css={css`
              border-radius: 0 4px 4px 0px;
              width: 180px;
              background-color: ${StatusColorMap[statesToUse[1]]};
            `}
          >
            {statesToUse[1]}
          </CydButton>
        </ButtonGroup>
      </>
    );
  } else {
    return (
      <>
        <CydSimpleSelect
          disabled={statesToUse.length === 1}
          availableOptions={statesToUse}
          selectedOption={currentStatus}
          onChange={onChange}
          label="Step Status"
          size="small"
          generateOptionLabel={(v) => v}
        />
      </>
    );
  }
};
