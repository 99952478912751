/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import { Typography } from '@mui/material';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';

/** @jsxImportSource @emotion/react */

export type CydGenericErrorPlaceholderProps = React.PropsWithChildren<{
  title?: string;
  message?: string;
  className?: string;
  gutterTop?: boolean;
}>;

export const CydGenericErrorPlaceholder = (
  props: CydGenericErrorPlaceholderProps
) => {
  const { title, message, className, children, gutterTop } = props;

  return (
    <div
      css={(theme) => `
        display: flex; 
        flex-flow: column nowrap; 
        align-items: center; 
        width: ${theme.spacing(72)};
        margin: 0 auto; 
        ${gutterTop && 'margin-top: 200px;'}
        
      `}
      className={className}
    >
      <CydIcon
        icon="error"
        css={(theme) => `
            width: ${theme.spacing(10)}; 
            height: ${theme.spacing(10)}; 
          `}
      />

      {title && (
        <Typography variant="h6" color="text.primary" gutterBottom>
          {title}
        </Typography>
      )}

      {message && (
        <Typography variant="body2" textAlign={'center'}>
          {message}
        </Typography>
      )}

      {children}
    </div>
  );
};
