/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { IDefaultSelectProps } from 'components/fields/DefaultSelect';
import * as moment from 'moment-timezone';
import { Select } from '@mui/material';

interface ITimezoneSelectProps extends Omit<IDefaultSelectProps, 'options'> {
  timezones: string[];
  inNavBar?: boolean;
}

const mapTimezonesToOptions = (timezones: string[]) =>
  timezones.map((tz) => ({
    label: tz,
    value: tz
  }));

export const TimezoneSelect: React.FunctionComponent<ITimezoneSelectProps> = ({
  timezones,
  value,
  placeholder,
  onChange,
  inNavBar = false,
  ...props
}) => {
  const timezoneOptions = React.useMemo(
    () => mapTimezonesToOptions(timezones),
    [timezones]
  );

  let _value = value && timezones.find((timezone) => timezone === value);
  if (!_value) {
    _value = 'UTC';
  }

  const changeHandler = React.useCallback(
    (event, child) => {
      if (!onChange) {
        return;
      }

      onChange(event, child);
    },
    [onChange]
  );

  const renderValue = React.useCallback(
    (selected) => {
      if (selected && selected.length === 0) {
        return <span>{placeholder}</span>;
      }

      return selected;
    },
    [placeholder]
  );

  return (
    <Select
      native
      value={_value}
      placeholder={moment.tz.guess()}
      onChange={changeHandler}
      renderValue={renderValue}
      {...props}
      inputProps={{
        'aria-label': 'Select Timezone'
      }}
    >
      {timezoneOptions.map(({ label, value }, index) => (
        <option key={index} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
};
