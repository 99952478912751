/* eslint-disable no-unreachable */
/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { FlowStateViewer } from 'components/_playbooks/CacaoFlowchart/FlowStateViewer/FlowStateViewer';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from 'components/_playbooks/CacaoFlowchart/type';
import { convertAtcToGenericPlaybookAndPlaybookResolution } from 'components/_playbooks/CacaoFlowchart/utils/atcToFlowchart';
import { convertCacaoToGenericPlaybookAndPlaybookResolution } from 'components/_playbooks/CacaoFlowchart/utils/cacaoToFlowchart';
import { withFlowchartErrorBoundary } from 'components/_playbooks/CacaoFlowchart/withFlowchartErrorBoundary';
import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';
import {
  GenericNodeUpdatePayload,
  GenericPlaybookStatusUpdate
} from 'components/_playbooks/CacaoPlaybookRunningInstance';
import { useCasePlaybooks } from 'hooks/CasePlaybooksHooks';
import { useCaseTags } from 'hooks/CaseTagsHooks';
import {
  usePlaybookInstance,
  useRemoveStandaloneActionFromCase,
  useUpdateCacaoPlaybookAction,
  useUpdateCacaoPlaybookInstance,
  useUpdatePlaybookActions,
  useUpdateStandaloneAction
} from 'hooks/PlaybookActionsHook';
import { useCacaoPlaybooks } from 'hooks/PlaybooksHooks';
import { useAvailableUsersForCaseUuid } from 'hooks/UserHooks';
import {
  PlaybookActionInstance,
  CydApi_CasePlaybookInstance
} from 'interface/Playbook.interface';
import React, { useEffect, useMemo, useRef } from 'react';

export type CydCasePlaybookDisplayProps = {
  selectedCasePlaybookUuid: string;
  caseUuid: string;
  selectedNode: GenericNode | null;
  onSelectNode: (data: GenericNode | null) => void;
  standalonePlaybook: {
    playbook: GenericPlaybook;
    playbookResolutionInfo: GenericPlaybookResolutionInfo;
  } | null;
};

function useGenericPlaybook(
  selectedCasePlaybookUuid: string,
  caseUuid: string,
  standalonePlaybook: {
    playbook: GenericPlaybook;
    playbookResolutionInfo: GenericPlaybookResolutionInfo;
  } | null
) {
  const { data: cacaoPlaybooks } = useCacaoPlaybooks();

  const casePlaybooks = useCasePlaybooks(caseUuid);

  const casePlaybookInstancesMap = useMemo(() => {
    return casePlaybooks.data.reduce(
      (acc, cur) => {
        acc[cur.casePlaybookUuid] = cur;
        return acc;
      },
      {} as Record<string, CydApi_CasePlaybookInstance>
    );
  }, [casePlaybooks]);

  const cacaoPlaybookTemplatesMap = useMemo(() => {
    return cacaoPlaybooks.reduce(
      (acc, cur) => {
        if (cur.uuid === undefined) {
          // not sure what else to do here
          throw new Error('Cacao playbook template has no uuid');
        }
        acc[cur.uuid] = cur;
        return acc;
      },
      {} as Record<string, OasisCacaoPlaybook>
    );
  }, [cacaoPlaybooks]);

  const result = useMemo(() => {
    if (selectedCasePlaybookUuid === 'standalone') {
      return standalonePlaybook;

      throw new Error('not implemented');
    }

    if (selectedCasePlaybookUuid) {
      const playbookInstance =
        casePlaybookInstancesMap[selectedCasePlaybookUuid];

      if (!playbookInstance) {
        return null;
        throw new Error(
          `Did not found a playbook instance for uuid: '${selectedCasePlaybookUuid}'`
        );
      }

      const isCacaoPlaybook =
        !!cacaoPlaybookTemplatesMap[playbookInstance.playbookUuid];

      if (isCacaoPlaybook) {
        const cacaoPlaybookTemplate =
          cacaoPlaybookTemplatesMap[playbookInstance.playbookUuid];

        if (!cacaoPlaybookTemplate) {
          return null;

          throw new Error(
            `We have a playbook instance, but can't find playbook template ${playbookInstance.playbookUuid}`
          );
        }

        const result = convertCacaoToGenericPlaybookAndPlaybookResolution(
          playbookInstance,
          cacaoPlaybookTemplate
        );
        return result;
      } else {
        return convertAtcToGenericPlaybookAndPlaybookResolution(
          playbookInstance
        );
      }
    }

    return null;
  }, [
    cacaoPlaybookTemplatesMap,
    casePlaybookInstancesMap,
    selectedCasePlaybookUuid,
    standalonePlaybook
  ]);

  return result;
}

function useUpdateNode(
  selectedCasePlaybookUuid: string,
  caseUuid: string,
  selectedNode: GenericNode | null,
  genericPlaybook: GenericPlaybook | null
) {
  const updateStandaloneAction = useUpdateStandaloneAction(caseUuid);
  const updateAtcPlaybook = useUpdatePlaybookActions(caseUuid);
  const updateCacaoPlaybookNode = useUpdateCacaoPlaybookAction(caseUuid);
  const updatePlaybookInstance = useUpdateCacaoPlaybookInstance(
    caseUuid,
    selectedCasePlaybookUuid
  );

  const playbookInstance = usePlaybookInstance(
    caseUuid,
    selectedCasePlaybookUuid
  );
  const availableTags = useCaseTags();

  return (data: GenericNodeUpdatePayload, position: number) => {
    if (selectedCasePlaybookUuid === 'standalone') {
      if (!selectedNode) {
        throw new Error('Somehow updating a node while no node is selected');
      }

      updateStandaloneAction({
        // We need to get the actionUuid from old data, because of that concat we do
        // of actionUuid + position
        actionUuid: data.node.oldData.actionUuid as string,
        assigneeUuid: data.payload.assignee as string,
        position: data.node.oldData.position as number,
        //@ts-ignore
        status: data.payload.status as string,

        oldData: data.node.oldData
      });
    } else {
      if (!playbookInstance) {
        throw new Error('No playbookInstance');
      }
      if (!genericPlaybook) {
        throw new Error('No genericPlaybook');
      }

      if (genericPlaybook.playbookType === 'atc') {
        updateAtcPlaybook(
          playbookInstance,
          {
            playbookInstanceUuid: playbookInstance.playbookUuid,
            playbookInstanceActionInstance: data.stepId,
            position: position,

            assignee: data.payload.assignee as string | null,
            status: data.payload.status as 'ready' | 'success',
            tags: data.payload.tags,
            oldData: data.node.oldData
          },
          availableTags.data
        );
      } else if (genericPlaybook.playbookType === 'cacao') {
        const updateNodeFn = () =>
          updateCacaoPlaybookNode({
            playbookUuid: playbookInstance.playbookUuid,
            playbookInstanceUuid: playbookInstance.casePlaybookUuid,
            playbookInstanceActionInstanceUuid: data.stepId,
            position: position,
            assignee: data.payload.assignee as string | null,
            status: data.payload.status as string,
            oldData: data.node.oldData
          });
        if (data.variablesPayload) {
          updatePlaybookInstance(
            {
              variable_bindings: data.variablesPayload
            },
            updateNodeFn
          );
        } else {
          updateNodeFn();
        }
      } else {
        throw new Error(
          `Playbook type '${genericPlaybook.playbookType}' not recognised`
        );
      }
    }
  };
}

function useUpdatePlaybook(casePlaybookUuid: string, caseUuid: string) {
  const updateFn = useUpdateCacaoPlaybookInstance(caseUuid, casePlaybookUuid);

  return (data: GenericPlaybookStatusUpdate) => {
    updateFn(data);
  };
}

function findFirstEligibleNodeToSelect(
  genericPlaybook: GenericPlaybook,
  resolutionInfo: GenericPlaybookResolutionInfo
): GenericNode | null {
  const inProgressNode = Object.values(genericPlaybook.nodes).find((v) => {
    const status = resolutionInfo.nodeStatuses[v.nodeId].status;
    return status === 'in progress';
  });

  if (inProgressNode && inProgressNode.nodeType.type === 'action') {
    return inProgressNode;
  }

  const readyNode = Object.values(genericPlaybook.nodes).find((v) => {
    const status = resolutionInfo.nodeStatuses[v.nodeId].status;
    return status === 'ready';
  });

  if (readyNode && readyNode.nodeType.type === 'action') {
    return readyNode;
  }

  return null;
}

export const CydCasePlaybookDisplay = withFlowchartErrorBoundary(
  (props: CydCasePlaybookDisplayProps) => {
    const {
      caseUuid,
      selectedCasePlaybookUuid,
      selectedNode,
      onSelectNode,
      standalonePlaybook
    } = props;

    const availableUsers = useAvailableUsersForCaseUuid(caseUuid);
    const availableTags = useCaseTags();

    const result = useGenericPlaybook(
      selectedCasePlaybookUuid,
      caseUuid,
      standalonePlaybook
    );
    const handleUpdateNode = useUpdateNode(
      selectedCasePlaybookUuid,
      caseUuid,
      selectedNode,
      result?.playbook || null
    );

    const handlePlaybookUpdate = useUpdatePlaybook(
      selectedCasePlaybookUuid,
      caseUuid
    );
    const removeActionFromCase = useRemoveStandaloneActionFromCase(caseUuid);

    // Point of the ref is that we don't want to do the 'find default node' every render
    // Just the first time it changes
    const playbookIdRef = useRef<string>();
    useEffect(() => {
      if (result && playbookIdRef.current !== selectedCasePlaybookUuid) {
        playbookIdRef.current = selectedCasePlaybookUuid;
        const node = findFirstEligibleNodeToSelect(
          result.playbook,
          result.playbookResolutionInfo
        );
        onSelectNode(node);
      }
    }, [result, onSelectNode, selectedCasePlaybookUuid]);

    // This is kind of a hack
    // There's a synchronization problem where the selected node will be one behind what the node statuses are
    // So we just return null in that scenario.
    const nodeStatusExists = selectedNode
      ? !!result?.playbookResolutionInfo?.nodeStatuses[selectedNode.nodeId]
      : true;

    if (!nodeStatusExists) {
      return null;
    }

    return (
      <div style={{ height: '100%' }}>
        {result && (
          <FlowStateViewer
            onRemoveAction={(node) => {
              removeActionFromCase(node.oldData as PlaybookActionInstance);
            }}
            selectedNode={selectedNode}
            onChangeSelectedNode={onSelectNode}
            availableStepStates={
              result.playbook.playbookType === 'atc'
                ? ['ready', 'success']
                : [
                    'blocked',
                    'awaiting input',
                    'in progress',
                    'ready',
                    'success',
                    'exception',
                    'failed'
                  ]
            }
            availableTags={availableTags.data}
            availableUsers={availableUsers.data}
            playbook={result.playbook}
            playbookResolutionInfo={result.playbookResolutionInfo}
            onUpdateNode={handleUpdateNode}
            onPlaybookStatusUpdate={handlePlaybookUpdate}
          />
        )}
      </div>
    );
  }
);
