import {
  DefaultContainer,
  withErrorBoundary
} from '../../../hocs/withErrorBoundary';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydGenericErrorPlaceholder } from 'components/_placeholders/CydGenericErrorPlaceholder/CydGenericErrorPlaceholder';
export const FlowChartFallback = () => {
  return (
    <CydGenericErrorPlaceholder
      css={css`
        margin-top: 100px;
      `}
      title="This playbook is unavailable"
      message="This playbook may be invalid."
    />
  );
};

export function withFlowchartErrorBoundary<T extends {}>(
  Component: React.ComponentType<T>
) {
  return withErrorBoundary<T>(Component, DefaultContainer, FlowChartFallback);
}
