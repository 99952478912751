/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import { useDefaultPanelStyles } from './styles';
import { PaperProps } from '@mui/material/Paper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';

/** @jsxImportSource @emotion/react */

interface IDefaultPanelProps
  extends Omit<PaperProps, 'classes' | 'title' | 'variant'> {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  onArrowClick?: () => void;
  classes?: {
    paper?: string;
    title?: string;
    button?: boolean;
    collapseButton?: boolean;
  };
  className?: string;
  variant?: 'page' | 'panel';
}

export const DefaultPanel: React.FC<IDefaultPanelProps> = ({
  title,
  actions,
  children,
  onArrowClick,
  variant = 'page',
  classes: {
    title: titleClass = '',
    paper: paperClass = '',
    button,
    collapseButton
  } = {},
  className = ''
}) => {
  const { panelHeading, panelTitle } = useDefaultPanelStyles();
  return (
    <Paper
      css={(theme) => `
      background-color: ${
        variant === 'page'
          ? theme.palette.background.default
          : theme.palette.background.paper
      }; 
      padding: ${variant === 'page' ? theme.spacing(2) : theme.spacing(6)}; 
      border-radius: ${theme.other.panelBorderRadius};
      margin: ${theme.spacing(4)}; 
    `}
      className={`${paperClass} ${className}`}
    >
      {title && (
        <div className={panelHeading}>
          <Typography className={`${panelTitle} ${titleClass} `} variant="h1">
            {button && (
              <IconButton onClick={onArrowClick}>
                {collapseButton ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            {title}
          </Typography>
          <CydButtonStack justifyContent={'flex-end'}>{actions}</CydButtonStack>
        </div>
      )}
      {children}
    </Paper>
  );
};
