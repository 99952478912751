/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of  the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { FC } from 'react';

import { CydCopyClicker } from 'components/_misc/CydCopyClicker/CydCopyClicker';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
interface ILocatorCell {
  locator: string;
}

export const LocatorCell: FC<ILocatorCell> = ({ locator }) => {
  return (
    <CydCopyClicker
      buttonLabel="Copy case locator"
      stringToCopy={`[cydarm:${locator}]`}
      css={css`
        > button {
          display: flex;
          flex-flow: row-reverse nowrap;
          justify-content: flex-end;
          align-items: center;
        }
      `}
    >
      <Typography
        color="text.primary"
        variant="caption"
        css={css`
          margin-left: 0.5em;
        `}
      >
        {locator}
      </Typography>
    </CydCopyClicker>
  );
};
