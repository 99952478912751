/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;

  return {
    root: {
      //<input type ="date"> has a browser native date icon, here we are adjusting it for dark mode
      '& input[type*="date" i]::-webkit-calendar-picker-indicator': {
        filter: palette.mode === 'dark' ? 'invert(1)' : 'invert(0)'
      }
    }
  };
});

/**
 * Dates should be an ISO (RFC3339) string.
 *
 * See: https://docs.google.com/document/d/1fkNAhRneMZ9jgaRVL4kEcdxVEad9ZneM9_MDmmxtXiM/edit?usp=sharing
 */
export const DatePicker = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ value = '', label, ...props }, ref) => {
    const { root } = useStyles();

    return (
      <TextField
        fullWidth
        InputLabelProps={{ shrink: true }}
        className={root}
        label={label}
        type="date"
        ref={ref}
        value={value}
        color="primary"
        {...props}
      />
    );
  }
);
