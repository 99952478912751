/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';
import { customScrollbar } from 'utils/StyleUtils';

export const usePlaybooksPagePlaybooksSidebarStyles = makeStyles(
  ({ shadows, spacing, shape: { borderRadius }, palette }) => ({
    sidebarPlaybooksButtonGroup: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'inherit',
      zIndex: 1
    },
    sidebarWrapper: {
      maxHeight: 'calc(100vh - 118px)',
      width: 360,
      backgroundColor: palette.background.paper,
      boxShadow: shadows[1],
      paddingBottom: spacing(3),
      flex: '0 0 auto',
      overflowY: 'auto',
      ...customScrollbar(borderRadius)
    },
    sidebarPlaybooksButtonWrapper: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      marginBottom: spacing(1)
    },
    listItem: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      borderBottom: `1px solid ${palette.divider}`,
      flexWrap: 'wrap'
    },
    listItemHeading: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px'
    },
    listItemBody: {
      fontSize: '14px'
    },
    actionsContainer: {
      width: '100%'
    },
    actionItem: {
      paddingLeft: spacing(3),
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      '&:not(:last-child)': {
        borderBottom: `1px solid ${palette.divider}`
      }
    },
    fileInput: {
      visibility: 'hidden',
      display: 'none'
    }
  })
);

export const usePlaybooksPageSinglePlaybookStyle = makeStyles(
  ({ spacing, shape: { borderRadius } }) => ({
    contentContainer: {
      justifyContent: 'flex-end'
    },
    actionContentContainer: {
      width: '100%',
      overflowY: 'auto',
      padding: spacing(3),
      ...customScrollbar(borderRadius)
    },
    searchAction: {
      paddingRight: spacing(2),
      maxWidth: '187px'
    },
    iconTooltipStyle: {
      paddingTop: '11px',
      paddingRight: '11px',
      fontSize: '28px'
    }
  })
);

export const usePlaybooksPageSinglePlaybookInfoStyle = makeStyles(
  ({ spacing, shadows, palette }) => ({
    container: {
      backgroundColor: palette.background.paper,
      boxShadow: shadows[4],
      width: 360,
      flex: '0 0 auto',
      padding: spacing(3),
      boxSizing: 'border-box'
    },
    timeStampSection: {
      marginBottom: spacing(5)
    },
    tagsControl: {
      display: 'flex',
      marginTop: spacing(2)
    },
    tagChip: {
      marginRight: spacing(2),
      marginBottom: spacing(),
      justifyContent: 'space-between',
      fontSize: 11,
      maxWidth: '100%'
    },
    tagChipLabel: {
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 11,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    tagChipContainer: {
      marginTop: spacing(2),
      overflow: 'hidden'
    },
    playbookActionContainer: {
      display: 'inline-flex',
      paddingTop: spacing(3)
    },
    tagChipDiv: {
      marginRight: spacing(2)
    },
    playbookActionDuplicateContainer: {
      display: 'inline-flex',
      paddingLeft: '80px',
      paddingTop: spacing(2)
    }
  })
);

export const usePlaybooksPageSinglePlaybookActionStyle = makeStyles(
  ({ spacing, palette }) => ({
    container: {
      flex: '1 1 100%'
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: spacing(2)
    },
    headingIcon: {
      fontSize: '1rem',
      display: 'flex',
      flexFlow: 'row nowrap'
    },
    iconStyle: {
      paddingRight: spacing(2),
      fontSize: '40px'
    },
    title: {
      fontSize: 24,
      color: palette.text.primary
    },
    subTitle: {
      fontSize: 22,
      color: palette.text.primary
    },
    descriptionLabel: {
      color: palette.text.primary
    },
    actionContainer: {
      backgroundColor: palette.background.paper,
      padding: spacing(2),
      paddingBottom: spacing(4),
      marginBottom: spacing(1)
    },
    playbookTitle: {
      paddingBottom: spacing(2)
    },
    playbookDescription: {
      paddingBottom: spacing(5),
      fontSize: '16px',
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    },
    actionDescription: {
      wordBreak: 'break-word',
      textAlign: 'justify'
    },
    playbookFormStyle: {
      padding: spacing(1)
    },
    popoverStyle: {
      width: '500px !important' // I'm importanting this one - because it seems impossible to set CSS order with the make styles stuff, so
      // I can't override the width property of the DefautPopover container style. I don't want to remove that
      // Style because it's used in a lot of places
      // This will be be solved when we move towards the new design system.
    }
  })
);
