/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';
import { GenericPlaybookStatusUpdate } from 'components/_playbooks/CacaoPlaybookRunningInstance';
import {
  AddTagToCasePlaybookPayload,
  RemoveTagFromCasePlaybookPayload,
  UpdatePlaybookActionPayload
} from './sagas';

type CasePlaybookInstanceRequestBody = {
  caseUuid: string;
  playbookUuid: string;
  callback?: (newPlaybookInstanceUuid: string) => void;
};

export const fetchCasePlaybooks = createAction<string>('casePlaybooks/fetch');

export const addPlaybookToCase =
  createAction<CasePlaybookInstanceRequestBody>('casePlaybooks/add');

export const removePlaybookFromCase =
  createAction<CasePlaybookInstanceRequestBody>('casePlaybooks/remove');

export const updateCasePlaybookAction = createAction<
  UpdatePlaybookActionPayload['payload']
>('casePlaybooks/updatePlaybookAction');

export const removeCasePlaybookActionTag = createAction<
  RemoveTagFromCasePlaybookPayload['payload']
>('casePlaybooks/removeActionTag');

export const addCasePlaybookActionTag = createAction<
  AddTagToCasePlaybookPayload['payload']
>('casePlaybooks/addActionTag');

export type UpdateCasePlaybookPayload = {
  caseUuid: string;
  casePlaybookUuid: string;
  data: GenericPlaybookStatusUpdate;
  resolveFn?: () => void;
};
export const updateCasePlaybook = createAction<UpdateCasePlaybookPayload>(
  'casePlaybooks/updatePlaybook'
);
