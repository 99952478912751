import { FormObject } from 'states/caseForms/slice';
import { cyFetchAuthenticated } from 'utils/CydarmFetch';
import * as CydarmFetch from 'utils/CydarmFetch';
import { CASE_FORM_ENDPOINT } from 'states/caseForms/sagas';
import { CydarmFetchMethod } from 'interface/CydarmFetch.interface';

export async function updateForm(
  params: Omit<FormObject, 'precedence'>
): Promise<void> {
  await cyFetchAuthenticated(`/form/${params.uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params)
  });
}

export async function deleteForm(params: FormObject): Promise<void> {
  await cyFetchAuthenticated(`/form/${params.uuid}`, {
    method: 'DELETE'
  });
}

export async function fetchAllForms(): Promise<FormObject[]> {
  const { json: caseForms } = await CydarmFetch.cyFetchAuthenticated(
    CASE_FORM_ENDPOINT,
    {
      method: CydarmFetchMethod.GET
    }
  );
  //could validate the result as the type is any
  return caseForms;
}

export async function createForm(
  submitCaseFormData: Omit<FormObject, 'uuid'>
): Promise<{ uuid: string; acl: string }> {
  //this is what the api returned
  const { json: caseForm } = await CydarmFetch.cyFetchAuthenticated(
    CASE_FORM_ENDPOINT,
    {
      method: CydarmFetchMethod.POST,
      body: JSON.stringify(submitCaseFormData)
    }
  );
  return caseForm;
}
