/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { fromByteArray, toByteArray } from 'base64-js';
import { CydApi_CasePlaybookInstance } from 'interface/Playbook.interface';
import { TextEncoder, TextDecoder } from 'web-encoding';

export function Base64Encode(string: string) {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(string);
  return fromByteArray(bytes);
}

export function Base64Decode(str: string) {
  let data: string = '';
  try {
    if (str.length <= 0) {
      return data;
    }

    if (str.length % 4 !== 0 || !/^[A-Za-z0-9+/]+={0,2}$/.test(str)) {
      return '*\\[Error displaying comment\\]*';
    }
    var bytes = toByteArray(str);
    const decoder = new TextDecoder();
    data = decoder.decode(bytes);
  } catch (ex) {
    console.error('Base64Decode: ' + ex.toString());
  }
  return data;
}

export function escapeBracketsForDefanged(str: any) {
  return str.replace(/\[.]/g, '\\[.]');
}

export const isAlphabetAndAtCharacterOnly = (str: string): boolean => {
  if (str.length === 0) {
    return true;
  }
  return /^[A-Za-z@ \n\t\r]+$/.test(str);
};

export function zeroPad(num, places) {
  let zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export const capitaliseString = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitaliseEachWord = (string) =>
  string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export function isNumeric(str: any) {
  if (typeof str != 'string') {
    return false;
  }
  return !isNaN(parseFloat(str));
}

export function checkIsColorValid(strColor) {
  if (!strColor) {
    return false;
  }
  var s = new Option().style;
  s.color = strColor;
  var test2 = /^#[0-9A-F]{6}$/i.test(strColor);
  if (s.color === strColor || test2 === true) {
    return true;
  } else {
    return false;
  }
}

export function logObjectPropertiesIfNull(obj, objectName: string = '') {
  Object &&
    Object.entries(obj).forEach(([key, value]) => {
      if (value == null) {
        console.warn(`${objectName} object key: ${key} has a null value`);
      }
    });
}

export function checkObjectHasAllRequiredCasePlaybookField(
  input: CydApi_CasePlaybookInstance
) {
  const schema: Record<keyof CydApi_CasePlaybookInstance, string> = {
    action_statuses: 'array',
    casePlaybookUuid: 'string',
    playbookUuid: 'string',
    caseUuid: 'string',
    playbookName: 'string',
    playbookDescription: 'string',
    precedence: 'number',
    variable_bindings: 'object'
  };

  Object.keys(schema)
    .filter((key) => input[key] === undefined)
    .map((key) => key as keyof CydApi_CasePlaybookInstance)
    .map((key) =>
      console.warn(
        `Case Playbook ${input.casePlaybookUuid} object is missing "${key}" key`
      )
    );
}

export function splitStringToArray(
  str: string | Array<string> = [],
  separator = ','
): Array<string> {
  if (Array.isArray(str)) {
    return str;
  }
  if (!str || !str.trim()) {
    return [];
  }
  return str.split(separator).map((chunk: string) => chunk.trim());
}

export function convertToTestId(str: string): string {
  if (!str) {
    return str;
  }
  return str.replace(/\s|,|\./g, '-').toLowerCase();
}

// Return day, hour, minute, second as number
export function convertSecondsToDHMS(seconds: number) {
  seconds = Math.floor(seconds);

  if (seconds < 60) {
    return [0, 0, 0, seconds];
  }

  let totalSeconds = seconds;

  /*/
  // This bit of functionality is not needed until we display days
  let days = Math.floor(totalSeconds / 86400);
  totalSeconds = totalSeconds - days * 86400;
  if (totalSeconds <= 0) {
    return [days, 0, 0, 0];
  }
  /*/
  let days = 0;
  //*/

  let hrs = Math.floor(totalSeconds / 3600);
  totalSeconds = totalSeconds - hrs * 3600;

  if (totalSeconds <= 0) {
    return [days, hrs, 0, 0];
  }

  let mins = Math.floor(totalSeconds / 60);
  totalSeconds = totalSeconds - mins * 60;
  return [days, hrs, mins, totalSeconds];
}

// Display day, hrs, min, sec to the right format
export function displayableTimeString([day, hrs, min, sec]: number[]) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  let displayTime = '';

  if (day === 0 && hrs === 0 && min === 0 && sec === 0) {
    return '0 second';
  }

  if (day > 0) {
    const dayUnit = day <= 1 ? 'day' : 'days';
    displayTime += `${formatter.format(day)} ${dayUnit} `;
  }

  if (hrs > 0) {
    const hoursUnit = hrs <= 1 ? 'hr' : 'hrs';
    displayTime += `${formatter.format(hrs)} ${hoursUnit} `;
  }

  if (min > 0) {
    const minutesUnit = min <= 1 ? 'min' : 'mins';
    displayTime += `${formatter.format(min)} ${minutesUnit} `;
  }

  // not showing any seconds value if there's hours value
  if (hrs === 0 && sec > 0) {
    let secondUnit = sec <= 1 ? 'second' : 'seconds';
    // display as "sec" is there's minutes value
    if (min > 0) {
      secondUnit = sec <= 1 ? 'sec' : 'secs';
    }
    displayTime += `${formatter.format(sec)} ${secondUnit} `;
  }
  return displayTime.trim();
}

export const convertSecondsToHM = (meanSeconds: number): string => {
  return displayableTimeString(convertSecondsToDHMS(meanSeconds));
};

export function convertSecondToDayString(seconds: number) {
  const days = Math.floor(seconds / 86400);
  const dayUnit = days <= 1 ? 'day' : 'days';
  return `${days} ${dayUnit}`;
}

/**
 * This function is intended to be a simple string matching function for use on things like search and filtering
 * We can extend to allow fuzzier matching on typos, ignoring spaces, etc.
 *
 * @param stringToTest
 * @param stringToMatch
 * @returns
 */
export function usefulStringMatch(
  stringToTest: string,
  stringToMatch: string
): boolean {
  return Boolean(stringToTest.match(stringToMatch));
}

/**
 * JSON.parse a string, but if the value is just a string, then return it
 * @param string
 * @returns
 */
export function jsonParseStringFallback(string: string): unknown {
  let result;
  try {
    result = JSON.parse(string);
    return result;
  } catch (err) {
    return string;
  }
}

/**
 * Convert a value into a useful displayable string
 * @param value
 */
export function defaultDisplayAValueAsString(value: unknown): string {
  if (typeof value == 'string' || typeof value === 'number') {
    return `${value}`;
  }
  return JSON.stringify(value);
}

export function removeTrailingSlash(str: string): string {
  return str.replace(/\/$/, '');
}

/**
 * Compare two ISO date strings, for the purpose of sorting them
 * @param dateA
 * @param dateB
 * @returns
 */
export function compareDateStrings(dateA: string, dateB: string): 0 | 1 | -1 {
  if (dateA > dateB) {
    return 1;
  }
  if (dateA < dateB) {
    return -1;
  }
  return 0;
}

const MAX_URL_LENGTH = 2000;
const UUID_LENGTH = 36;
const BUFFER = 500; // To allow for additional query params, the base url, etc.
const MAX_UUIDS_PER_QUERY = Math.floor((MAX_URL_LENGTH - BUFFER) / UUID_LENGTH);
export function batchUuidStrings(
  uuids: Array<string>,
  maxUuidsPerString = MAX_UUIDS_PER_QUERY
): Array<string> {
  const newUuids = [...uuids];
  const uuidStrings = [] as Array<string>;

  while (newUuids.length) {
    const subArray = newUuids.splice(0, maxUuidsPerString);
    uuidStrings.push(subArray.join(','));
  }
  return uuidStrings;
}

//https://stackoverflow.com/a/14919494/1068446
export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

export class StringUtils {
  public static defang(value: string) {
    return value
      .replace('http', 'hXXp')
      .replace(/\./g, '[.]')
      .replace('@', '(at)');
  }
}
