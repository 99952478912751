/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Typography, Chip } from '@mui/material';
import { IAclAci } from 'interface/Acl.interface';
import { useAclAciRowStyles } from './styles';

/** @jsxImportSource @emotion/react */
interface IAclAciRowProps {
  title: string;
  acis?: IAclAci[];
}

// TODO: consider merging this one with AclDialogAciRow by creating a AciRow component
export const AclAciRow: React.FC<IAclAciRowProps> = ({ title, acis }) => {
  const { container, rowTitle } = useAclAciRowStyles();

  return (
    <div className={container}>
      <div className={rowTitle}>
        <Typography>{title}</Typography>
      </div>
      <div
        css={(theme) => `
        display: flex; 
        flex-flow: row wrap; 
        gap: ${theme.spacing(2)}; 
      `}
      >
        {acis &&
          acis.map((aci) => (
            <Chip label={aci.group.name} key={aci.group.uuid} />
          ))}
      </div>
    </div>
  );
};
