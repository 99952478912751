/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { createRemoveActionFromCase } from 'states/caseActions/actions';

export const ActionItemDelete = ({ action, securityCase }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(
      createRemoveActionFromCase({
        caseUuid: securityCase.uuid,
        actionInstance: action
      })
    );
  };

  return (
    <Grid item xs={1}>
      <DeleteIcon onClick={handleClick} />
    </Grid>
  );
};
