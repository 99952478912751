/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { createFetchGroupsForCase } from 'states/caseGroups/actions';
import { shallowEqual } from 'react-redux';
import { caseGroupsOfCaseSelector } from 'states/caseGroups/selectors';
import { useAutoEffect } from './ReduxHooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useServiceProvider } from 'providers/ServiceProvider';
import { Case } from 'interface/Case.interface';
import { apiPerformFetchCaseByLocator } from 'services';

export const useCaseGroupsMemberOf = (caseUuid: string) => {
  return useAutoEffect({
    selector: caseGroupsOfCaseSelector,
    ac: createFetchGroupsForCase,
    actionData: caseUuid,
    equality: shallowEqual
  });
};

export function useCasesByParentUuid(parentCaseUuid: string) {
  const { apiFetchCasesByParentUuid } = useServiceProvider();

  return useQuery({
    queryKey: ['case-group', parentCaseUuid],
    queryFn: async () => {
      const finalResult = [] as Array<Case>;
      const pageSize = 50;
      let pageNum = 0;
      let shouldContinue = true;

      // There may be more than 50 cases, make sure we get them all
      while (shouldContinue) {
        const result = await apiFetchCasesByParentUuid(
          parentCaseUuid,
          pageSize,
          pageNum
        );

        shouldContinue =
          (result.json.meta?.totalResults ?? 0) > pageSize * (pageNum + 1);
        pageNum++;

        finalResult.push(...result.json.data);
      }
      return finalResult;
    }
  });
}

export function useAddCaseToGroup(parentCaseUuid: string) {
  const { apiPerformAddMemberToCase } = useServiceProvider();

  const qc = useQueryClient();
  return useMutation({
    mutationKey: ['case-group', parentCaseUuid, 'add'],
    mutationFn: async (locator: string) => {
      const member = await apiPerformFetchCaseByLocator(locator);
      return apiPerformAddMemberToCase(parentCaseUuid, member.json.uuid);
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ['case-group', parentCaseUuid]
      });
    }
  });
}

export function useRemoveCaseFromGroup(parentCaseUuid: string) {
  const qc = useQueryClient();
  const { apiPerformRemoveMemberFromCase } = useServiceProvider();

  return useMutation({
    mutationKey: ['case-group', parentCaseUuid, 'remove'],
    mutationFn: (memberUuid: string) => {
      return apiPerformRemoveMemberFromCase(parentCaseUuid, memberUuid);
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ['case-group', parentCaseUuid]
      });
    }
  });
}
