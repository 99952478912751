/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useState } from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid, Collapse, Typography } from '@mui/material';
import { useCasePlaybookListItemActionStyles } from '../styles';
import { Markdown } from 'components/Markdown';

export const ActionItemDescription = ({ actionName, actionDescription }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const {
    actionIcon,
    actionTitle,
    actionDescription: actionDescriptionClass
  } = useCasePlaybookListItemActionStyles();

  return (
    <>
      {!isExpanded ? (
        <ArrowRightIcon onClick={handleToggle} className={actionIcon} />
      ) : (
        <ArrowDownIcon onClick={handleToggle} className={actionIcon} />
      )}
      <Grid item xs={6}>
        <Typography className={actionTitle} onClick={handleToggle}>
          {actionName}
        </Typography>
        <Collapse in={isExpanded}>
          <div className={actionDescriptionClass}>
            <Markdown>{actionDescription}</Markdown>
          </div>
        </Collapse>
      </Grid>
    </>
  );
};
