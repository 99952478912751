/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { put, takeLatest } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { createFetchCaseWatchers } from './actions';
import { fetchCaseWatchersFailed, fetchCaseWatchersSuccess } from './slice';
import { apiFetchCaseWatchers } from 'services/reports/WatchingCasesService';

export function* performFetchCaseWatchers({
  payload: caseUuid
}: {
  payload: string;
}) {
  try {
    const { json: watchers } = yield apiFetchCaseWatchers(caseUuid);
    yield put(fetchCaseWatchersSuccess({ [caseUuid]: watchers }));
  } catch (error) {
    yield put(fetchCaseWatchersFailed(error));
    yield put(addNotification({ message: `Failed to fetch case watcher` }));
  }
}

function* watchFetchCaseWatchers() {
  yield takeLatest(createFetchCaseWatchers, performFetchCaseWatchers);
}

export default [watchFetchCaseWatchers()];
