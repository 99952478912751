/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';
import {
  AddActionToCasePayload,
  RemoveStandaloneActionFromCasePayload,
  UpdateStandaloneActionOnCasePayload
} from './sagas';

export const createFetchCaseActions = createAction<string>('caseActions/fetch');

export const createAddActionToCase =
  createAction<AddActionToCasePayload['payload']>('caseActions/add');

export const createRemoveActionFromCase =
  createAction<RemoveStandaloneActionFromCasePayload['payload']>(
    'caseActions/remove'
  );

export const createUpdateCaseAction =
  createAction<UpdateStandaloneActionOnCasePayload['payload']>(
    'caseActions/update'
  );
