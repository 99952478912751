import { IStore } from 'states/store.interface';
import { createSelector, createStructuredSelector } from 'reselect';

export const playbookListSelector = ({ playbooks: { list } }: IStore) => list;

export const playbookAtcListSelector = ({ playbooks: { atcList } }: IStore) =>
  atcList;

export const playbookCacaoListSelector = ({
  playbooks: { cacaoList }
}: IStore) => cacaoList;

export const singleAtcPlaybookSelector = createSelector(
  ({ playbooks: { currentSinglePagePlaybook } }: IStore) =>
    currentSinglePagePlaybook,
  (_, playbookUuid) => playbookUuid,
  (currentPlaybook, playbookUuid) =>
    currentPlaybook && currentPlaybook.atc?.uuid === playbookUuid
      ? currentPlaybook.atc
      : undefined
);

export const singleAtcPlaybookSelector2 = createSelector(
  (store: IStore) => {
    return store.playbooks.atcList;
  },
  (_, playbookUuid) => playbookUuid,

  (playbooks: IStore['playbooks']['atcList'], playbookUuid: string) => {
    return playbooks.find((v) => v.uuid === playbookUuid);
  }
);

export const singleCacaoPlaybookSelector = createSelector(
  ({ playbooks: { currentSinglePagePlaybook } }: IStore) =>
    currentSinglePagePlaybook,
  (_, playbookUuid) => playbookUuid,
  (currentPlaybook, playbookUuid) =>
    currentPlaybook && currentPlaybook.cacao?.uuid === playbookUuid
      ? currentPlaybook.cacao
      : undefined
);

const deletePlaybookSelector = ({ playbooks: { deletePlaybook } }: IStore) =>
  deletePlaybook?.atc;

const deletePlaybookErrorSelector = ({
  playbooks: { deletePlaybookError }
}: IStore) => deletePlaybookError;

export const deletePlaybookWithErrorSelector = createStructuredSelector({
  deletePlaybook: deletePlaybookSelector,
  deletePlaybookError: deletePlaybookErrorSelector
});

const deleteCacaoPlaybookSelector = ({
  playbooks: { deletePlaybook }
}: IStore) => deletePlaybook?.cacao;

export const deleteCacaoPlaybookWithErrorSelector = createStructuredSelector({
  deletePlaybook: deleteCacaoPlaybookSelector,
  deletePlaybookError: deletePlaybookErrorSelector
});
