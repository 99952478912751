import { useMemo, useCallback, memo, FC } from 'react';
import * as moment from 'moment-timezone';
import { dateFormatLocale } from 'utils/DateUtils';
import { useTimezoneLegacy } from 'hooks/AuthHooks';
import { useStyles } from './styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

interface ITimeStampProps {
  created?: string;
  lastmodified?: string;
  edited?: boolean;
}

export const Timestamps: FC<ITimeStampProps> = memo(
  ({ created, lastmodified, edited }) => {
    const { datetime, timeItem } = useStyles();
    const timeZone = useTimezoneLegacy();

    const formatTime = useCallback(
      (time) => moment.tz(time, timeZone).format(dateFormatLocale),
      [timeZone]
    );

    const createdFormatted = useMemo(
      () => formatTime(created),
      [created, formatTime]
    );

    const modifiedFormatted = useMemo(
      () => formatTime(lastmodified),
      [lastmodified, formatTime]
    );

    return (
      <span className={datetime}>
        <span className={timeItem} data-testid="cyd-time-display">
          <AddCircleIcon />
          {createdFormatted}
        </span>
        {edited && (
          <span className={timeItem} data-testid="cyd-time-display">
            <EditIcon />
            {modifiedFormatted}
          </span>
        )}
      </span>
    );
  }
);
