/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonApiToOneRelationship } from './JsonApiToOneRelationship';
import {
    JsonApiToOneRelationshipFromJSON,
    JsonApiToOneRelationshipFromJSONTyped,
    JsonApiToOneRelationshipToJSON,
} from './JsonApiToOneRelationship';

/**
 * 
 * @export
 * @interface ConnectorOperationTypeResourceRelationships
 */
export interface ConnectorOperationTypeResourceRelationships {
    /**
     * 
     * @type {JsonApiToOneRelationship}
     * @memberof ConnectorOperationTypeResourceRelationships
     */
    acl?: JsonApiToOneRelationship | null;
}

/**
 * Check if a given object implements the ConnectorOperationTypeResourceRelationships interface.
 */
export function instanceOfConnectorOperationTypeResourceRelationships(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectorOperationTypeResourceRelationshipsFromJSON(json: any): ConnectorOperationTypeResourceRelationships {
    return ConnectorOperationTypeResourceRelationshipsFromJSONTyped(json, false);
}

export function ConnectorOperationTypeResourceRelationshipsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorOperationTypeResourceRelationships {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acl': !exists(json, 'acl') ? undefined : JsonApiToOneRelationshipFromJSON(json['acl']),
    };
}

export function ConnectorOperationTypeResourceRelationshipsToJSON(value?: ConnectorOperationTypeResourceRelationships | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acl': JsonApiToOneRelationshipToJSON(value.acl),
    };
}

