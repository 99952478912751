import { User } from 'interface/User.interface';
import * as CydarmFetch from 'utils/CydarmFetch';

export async function apiFetchCurrentUserInfo(
  sessionUuid: string
): Promise<CydarmFetch.CyFetchResponse<User>> {
  return CydarmFetch.cyFetchAuthenticated(`/auth/session/${sessionUuid}`, {
    method: 'GET'
  });
}

export async function apiSignout(sessionUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(
    `/auth/session/${sessionUuid}`,
    {
      method: 'DELETE'
    },
    { autoSignOut: false }
  );
}

export async function apiFetchTotpConfig(userUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/auth/totp/${userUuid}`, {
    method: 'GET'
  });
}

export async function apiInitialiseTotp(userUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/auth/totp/${userUuid}`, {
    method: 'POST'
  });
}

export async function apiValidateTotp(userUuid: string, totpCode: string) {
  return CydarmFetch.cyFetchAuthenticated(`/auth/totp/${userUuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      totpCode: totpCode
    })
  });
}

export async function apiDeleteTotp(userUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/auth/totp/${userUuid}`, {
    method: 'DELETE'
  });
}
