/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';
import { ITotpConfig } from 'interface/User.interface';

// Users Actions
export const FETCH_CURRENT_USER_INFO = 'FETCH_CURRENT_USER_INFO';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';

interface IUserAuth {
  username: string;
  password: string;
  authSource: string;
}

interface IInitialiseTotpConfig {
  userUuid: string;
  resolve: any;
}

export const fetchCurrentUserInfo = createAction('auth/fetchCurrentUserInfo');
export const fetchStoredTimezoneList = createAction(
  'auth/fetchStoredTimezoneList'
);
export const signOut = createAction('auth/signOut');
export const signIn = createAction<IUserAuth>('auth/signIn');
export const updateTimezone = createAction<string>('auth/updateTimezone');
export const fetchTotpConfig = createAction<string>('auth/fetchTotpConfig');
export const initialiseTotpConfig = createAction<IInitialiseTotpConfig>(
  'auth/initialiseTotpConfig'
);
export const validateTotpConfig = createAction<ITotpConfig>(
  'auth/validateTotpConfig'
);
export const removeTotpConfig = createAction<ITotpConfig>(
  'auth/removeTotpConfig'
);
