/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useCallback, useMemo } from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import { createFetchCaseStates } from 'states/caseStates/actions';
import {
  createFetchCases,
  createFetchCaseByUuid,
  createFetchCaseAndMembersByUuid,
  createAddMemberToCase,
  createFetchCaseByLocator,
  createCase
} from 'states/cases/actions';
import { useCurrentUser } from './AuthHooks';
import moment from 'moment';
import { Case } from 'interface/Case.interface';
import deepEqual from 'react-fast-compare';
import {
  caseSelector,
  singleCaseSelector,
  memberCasesSelector,
  parentCasesSelector,
  caseListLoadedSelector
} from 'states/cases/selectors';
import {
  caseStatesHasLoadedSelector,
  caseStatesIsLoadingSelector,
  caseStatesOpenStatusSelector,
  caseStatesSelector,
  caseStatesStatusSelector,
  caseTransitionableStatesSelector
} from 'states/caseStates/selectors';
import { IAutoEffectReturn, useAutoEffect } from './ReduxHooks';
import { useAllUsers } from './UserHooks';
import { useWatchingCases } from './WatchingCasesHooks';
import { caseActionsSelector } from 'states/caseActions/selectors';
import { createFetchCaseActions } from 'states/caseActions/actions';
import { CaseState } from 'interface/CaseState.interface';
import { useCasesFiltered } from './CaseHooksRq';
import { useSystemPropValue } from './SysPropsHooks';
import { SYSTEM_PROPERTY } from '../constants';
import { useInterval } from './useInterval';

export const useCases = () => {
  useAllUsers();
  useWatchingCases();
  return useAutoEffect({
    selector: caseSelector,
    ac: createFetchCases,
    hasLoadedSelector: caseListLoadedSelector
  });
};

export const useCaseAndMembersByUuid = (caseUuid: string) => {
  useWatchingCases();
  return useAutoEffect({
    selector: singleCaseSelector,
    ac: createFetchCaseAndMembersByUuid,
    actionData: caseUuid,
    equality: deepEqual
  });
};

export const useCaseStates = () => {
  return useAutoEffect({
    selector: caseStatesSelector,
    ac: createFetchCaseStates,
    equality: shallowEqual,
    statusSelector: caseStatesStatusSelector,
    isLoadingSelector: caseStatesIsLoadingSelector,
    hasLoadedSelector: caseStatesHasLoadedSelector
  });
};

export const useCaseOpenStatesList = () => {
  return useAutoEffect({
    selector: caseStatesOpenStatusSelector,
    ac: createFetchCaseStates,
    equality: shallowEqual,
    statusSelector: caseStatesStatusSelector
  });
};

// export function useCaseTransitionableStates(cydCase: Case | null);
// export function useCaseTransitionableStates(currentState: string);
export function useCaseTransitionableStates(
  param1: Case | null | string
): IAutoEffectReturn<Array<CaseState>> {
  return useAutoEffect({
    selector: caseTransitionableStatesSelector,
    ac: createFetchCaseStates,
    equality: shallowEqual,
    statusSelector: caseStatesStatusSelector,
    selectorData: typeof param1 === 'string' ? param1 : param1?.status
  });
}

export const useSingleCase = (
  caseUuid: string
): IAutoEffectReturn<Case | undefined> => {
  useWatchingCases();
  return useAutoEffect({
    selector: singleCaseSelector,
    actionData: caseUuid,
    ac: createFetchCaseByUuid,
    hasLoadedSelector: caseListLoadedSelector,
    equality: deepEqual
  });
};

export function useSingleCasePolling(caseUuid: string) {
  const dispatch = useDispatch();

  const pollTime = useSystemPropValue(SYSTEM_PROPERTY.DATA_POLL_TIME);

  const cb = useCallback(() => {
    dispatch(createFetchCaseByUuid(caseUuid));
  }, [dispatch, caseUuid]);

  useInterval(cb, parseInt(pollTime) || 5000, {
    jitterMs: 1000,
    onlyPollIfWindowActive: true
  });

  return useSingleCase(caseUuid);
}

/**@deprecated */
export const useGroupCases = (
  selectedCases: string[],
  onSuccess: () => void
) => {
  const dispatch = useDispatch();

  return useCallback(
    (groupCaseData) => {
      // do not proceed if no cases selected
      if (selectedCases.length <= 0) {
        return;
      }
      if (groupCaseData.groupOption === 'EXISTING') {
        // when resolved for case, add the selected cases to this one
        const resolveFunc = (cydCase: Case) => {
          selectedCases.forEach((selectedCase) => {
            dispatch(
              createAddMemberToCase({
                caseUuid: cydCase.uuid,
                memberUuid: selectedCase,
                resolve: onSuccess
              })
            );
          });
        };
        // look up the locator
        dispatch(
          createFetchCaseByLocator({
            locator: groupCaseData.caseLocator,
            resolve: resolveFunc
          })
        );
      }

      if (groupCaseData.groupOption === 'NEW') {
        dispatch(
          createCase({
            caseData: {
              description: groupCaseData.description,
              members: selectedCases,
              org: groupCaseData.org
            },
            resolve: onSuccess
          })
        );
      }
    },
    [selectedCases, onSuccess, dispatch]
  );
};

//TODO: This is derived data and should be part of a selector
export const useAvailableCaseStates = (selectedCaseUuids: string[]) => {
  const { data: cases } = useCases();
  const { data: caseStates } = useCaseStates();

  const selectedCases = cases.filter(({ uuid }) =>
    selectedCaseUuids.includes(uuid)
  );

  const selectedCaseStates = useMemo(
    () =>
      caseStates.filter(({ name }) =>
        selectedCases.some(({ status }) => status === name)
      ),
    [caseStates, selectedCases]
  );

  return useMemo(
    () =>
      caseStates
        .filter(({ name }) =>
          selectedCaseStates.every(({ next }) => next.includes(name))
        )
        .map(({ name }) => name),
    [selectedCaseStates, caseStates]
  );
};

export const useAvailableCaseStates2 = (caseStates: Array<CaseState>) => {
  return (selectedCases: Array<Case>) => {
    const selectedCaseStates = caseStates.filter(({ name }) =>
      selectedCases.some(({ status }) => status === name)
    );

    const availableStates = caseStates.filter(({ name }) =>
      selectedCaseStates.every(({ next }) => next.includes(name))
    );

    return availableStates;
  };
};

export const useAssignedCases = () => {
  const { data: currentUser } = useCurrentUser();

  const casesResult = useCasesFiltered(
    {
      filter: {
        assignee: currentUser ? [currentUser.username] : []
      },
      page: {
        number: 0,
        size: 1000
      }
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !currentUser
    }
  );

  const cases = casesResult.data;
  const { data: openCaseStates } = useCaseOpenStatesList();
  const openCaseStatesLabels = openCaseStates.map((v) => v.name);

  return useMemo(() => {
    return (
      cases?.data
        ?.filter((v) => {
          return openCaseStatesLabels.includes(v.status);
        })
        .sort((caseA, caseB) =>
          moment(caseA.modified).isBefore(moment(caseB.modified)) ? 1 : -1
        ) ?? []
    );
  }, [cases?.data, openCaseStatesLabels]);
};

/**
 * @deprecated - use useCasesByParentUuid
 * @param memberUuids
 * @returns
 */
export const useMemberCases = (memberUuids: string[] = []) => {
  return useAutoEffect({
    selector: memberCasesSelector,
    ac: createFetchCaseByUuid,
    actionData: memberUuids,
    spreadDispatch: true
  });
};

export const useParentCases = (parentUuids: string[] = []) => {
  return useAutoEffect({
    selector: parentCasesSelector,
    ac: createFetchCaseByUuid,
    actionData: parentUuids,
    spreadDispatch: true
  });
};

export const useCaseActions = (caseUuid: string) => {
  return useAutoEffect({
    selector: caseActionsSelector,
    ac: createFetchCaseActions,
    equality: shallowEqual,
    actionData: caseUuid
  });
};
