import { rtkqUsersApi } from 'hooks/UserHooks';
import { IStore } from 'states/store.interface';

export const signedInSelector = ({ auth: { signedIn } }: IStore) => signedIn;

export const currentUserSelector = (store) => {
  return rtkqUsersApi.endpoints.getCurrentUser.select({})(store).data;
};

export const timezoneSelector = ({ auth: { timeZone } }: IStore) => timeZone;

export const timezoneListSelector = ({ auth: { timezoneList } }: IStore) =>
  timezoneList;

export const authIsLoadingSelector = ({ auth: { isLoading } }: IStore) =>
  isLoading;

export const authStatusSelector = ({
  auth: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;

export const totpConfigSelector = ({ auth: { totp } }: IStore) => totp;
