/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps
} from '@mui/x-date-pickers-pro';
import { Box, TextField } from '@mui/material';
import { useTimezone2 } from 'hooks/AuthHooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  convertTimezonedDateToLocaleDate,
  getEndOfDayInTimeZone,
  getStartOfDayInTimeZone
} from 'utils/DateUtils';
import { allAreNull } from 'utils/ArrayUtils';

/** @jsxImportSource @emotion/react */

export type CydDateRangePickerProps = Pick<
  DateRangePickerProps<any, any>,
  'startText' | 'endText' | 'className'
> & {
  onComplete: (fromDate: string | null, toDate: string | null) => void;
  required?: boolean;
  initialValue: DateRange<any>;
  makeInitialValueOneMonthAgoToToday?: boolean;

  // Just incase the timezone triggering is causing problems, we'll allow the user to opt out.
  preventFireOnCompleteOnTimezoneChange?: boolean;
  // Using UTC timezone display
  preventTimezoneChange?: boolean;
};

function determineInitialValues(
  makeInitialValueOneMonthAgoToToday: boolean,
  initialValue: DateRange<any>,
  timezone: string,
  preventTimezoneChange = false
): DateRange<any> {
  if (makeInitialValueOneMonthAgoToToday && allAreNull(initialValue)) {
    const today = new Date(Date.now());
    //https://stackoverflow.com/a/7937257/1068446
    const monthAgo = new Date(Date.now());
    monthAgo.setMonth(monthAgo.getMonth() - 1);

    return [monthAgo, today];
  } else {
    return initialValue.map((v) => {
      if (v && !preventTimezoneChange) {
        return convertTimezonedDateToLocaleDate(v, timezone);
      }

      return v;
    }) as typeof initialValue;
  }
}

/**
 * For simplicity the 'onChange' for this component is really the 'onComplete', we won't fire anything until user has selected both dates
 * @param props
 * @returns
 */
export const CydDateRangePicker = (props: CydDateRangePickerProps) => {
  const {
    initialValue,
    makeInitialValueOneMonthAgoToToday = false,
    onComplete,
    startText = 'From',
    endText = 'To',
    className,
    required,
    preventFireOnCompleteOnTimezoneChange = false,
    preventTimezoneChange = false
  } = props;

  const { timezone } = useTimezone2();

  const [values, setValues] = useState(
    determineInitialValues(
      makeInitialValueOneMonthAgoToToday,
      initialValue,
      timezone,
      preventTimezoneChange
    )
  );

  const handleComplete = useCallback(
    (newValue: DateRange<any>) => {
      const [dateFrom, dateTo] = newValue;
      let newDateFrom = dateFrom;
      let newDateTo = dateTo;
      if (dateFrom) {
        const jsDate = new Date(dateFrom);

        newDateFrom = getStartOfDayInTimeZone(
          {
            date: jsDate.getDate(),
            monthIndex: jsDate.getMonth(),
            fullYear: jsDate.getFullYear()
          },
          timezone
        );
      }

      if (dateTo) {
        const jsDate = new Date(dateTo);
        newDateTo = getEndOfDayInTimeZone(
          {
            day: jsDate.getDate(),
            monthIndex: jsDate.getMonth(),
            fullYear: jsDate.getFullYear()
          },
          timezone
        );
      }

      onComplete(newDateFrom, newDateTo);
    },
    [onComplete, timezone]
  );

  // Is there a better technique than this?
  // I don't want handleComplete to fire everytime values changes.
  const valuesRef = useRef(values);
  valuesRef.current = values;

  const handleCompleteRef = useRef(handleComplete);
  handleCompleteRef.current = handleComplete;
  useEffect(() => {
    if (!preventFireOnCompleteOnTimezoneChange) {
      handleCompleteRef.current(valuesRef.current);
    }
  }, [
    timezone,
    valuesRef,
    handleCompleteRef,
    preventFireOnCompleteOnTimezoneChange
  ]);

  useEffect(() => {
    if (makeInitialValueOneMonthAgoToToday && initialValue[0] === null) {
      handleCompleteRef.current(valuesRef.current);
    }
  }, [
    makeInitialValueOneMonthAgoToToday,
    handleCompleteRef,
    valuesRef,
    initialValue
  ]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* https://github.com/mui/mui-x/issues/4554
      Can't style the DateRangePicker directly
    */}
      <div
        className={className}
        css={(theme) => `

        >div{
          display: flex; 
          flex-flow: row nowrap; 
        }
              
        .MuiFormControl-root {
          flex: 1 0 auto; 
        }
      `}
      >
        <DateRangePicker
          ref={ref}
          PopperProps={{
            anchorEl: ref.current,
            placement: 'bottom-start'
          }}
          // Re: deprecation warnings
          // The localeText solution does not work.
          // See issue here: https://github.com/mui/material-ui/issues/39048
          startText={startText}
          endText={endText}
          value={values}
          inputFormat="dd/MM/yyyy"
          onChange={(newValue) => setValues(newValue)}
          onAccept={handleComplete}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                {...startProps}
                autoComplete="off" // In future we may want to customise via props
                required={required}
                inputProps={{
                  ...startProps.inputProps,
                  'data-testid': 'date-range-picker-from'
                }}
              />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField
                {...endProps}
                autoComplete="off"
                required={required}
                inputProps={{
                  ...endProps.inputProps,
                  'data-testid': 'date-range-picker-to'
                }}
              />
            </React.Fragment>
          )}
        />
      </div>
    </>
  );
};
