/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Link } from 'react-router-dom';
import { CydarmRoute } from 'interface/CydarmRoute';
import { convertToTestId } from 'utils/StringUtils';
import { Tabs, Tab } from '@mui/material';

import { useLocation } from 'react-router';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { POSSIBLE_FEATURE_FLAGS } from 'featureFlags';
/** @jsxImportSource @emotion/react */

const navigationItems = [
  {
    label: 'Case List',
    url: CydarmRoute.CASE_LIST
  },
  {
    label: 'Playbooks',
    url: CydarmRoute.PLAYBOOKS
  },
  {
    label: 'Metrics',
    url: CydarmRoute.MANAGE_DASHBOARD
  },
  {
    label: 'Review',
    url: CydarmRoute.REVIEW
  },
  {
    label: 'Knowledge',
    url: CydarmRoute.WIKI
  }
] as Array<{
  label: string;
  url: string;
  featureFlag?: POSSIBLE_FEATURE_FLAGS;
}>;

type CydAppBarNavLinksProps = {
  className?: string;
};

const NavLink = React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
  // aria-selected is probably the wrong hint to provide
  // https://github.com/mui/material-ui/issues/32924
  return (
    <Link
      ref={ref}
      aria-selected={props['aria-selected']}
      to={props.href}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  );
});

/**  So basically we need to do some annoying conversion logic to treat 
/caseview/123/foo as /caselist
/playbooks/123 as /playbooks etc
 

Because Mui Tabs want to do exact matching. 

See: https://github.com/mui/material-ui/issues/33055 for my suggestion for making this much nicer
*/
function determineTabsValue(path: string): string | false {
  const strippedValue = (() => {
    if (path.startsWith('/caseview')) {
      return '/caselist';
    }

    if (path.startsWith(CydarmRoute.CHARTS)) {
      return CydarmRoute.MANAGE_DASHBOARD;
    }

    return `/${path.split('/')[1]}`;
  })();

  if (navigationItems.find((v) => v.url === strippedValue)) {
    return strippedValue;
  } else {
    return false;
  }
}

export const CydAppBarNavLinks = (props: CydAppBarNavLinksProps) => {
  const { className } = props;

  const location = useLocation();

  const tabsValue = determineTabsValue(location.pathname);
  const { isToggleEnabled } = useFeatureToggles();

  return (
    <Tabs value={tabsValue} className={className}>
      {navigationItems.map(({ url, label, featureFlag }) => {
        if (!featureFlag || isToggleEnabled(featureFlag)) {
          return (
            <Tab
              data-testid={convertToTestId(`nav-${label}`)}
              key={url}
              href={url}
              label={label}
              value={url}
              component={NavLink}
            />
          );
        }

        return null;
      })}
    </Tabs>
  );
};
