/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ spacing }) => ({
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  fieldWrapper: {
    marginBottom: 16,
    '& label + .MuiFormControl-root': {
      marginTop: 16
    }
  },
  field: (props: any) => ({
    minWidth: props?.charWidth ? `${props.charWidth}ch` : '200px'
  }),
  formSelect: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 28
  },
  formPadding: {
    paddingBottom: spacing(3)
  }
}));
