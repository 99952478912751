/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createTheme, Theme as MuiTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import '@emotion/react';
import { GenericRunningInstanceStepStatus } from 'components/_playbooks/CacaoPlaybookRunningInstance';

// It's ok to commit this license key, it's exposed in the bundle anyway
// https://mui.com/x/introduction/licensing/#license-key-installation
LicenseInfo.setLicenseKey(
  '65e37af50c2888c6584652a26147dd6dTz03NDcyMSxFPTE3MjYzNTA2OTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// Note that this 'other' color doesn't actually sit in the palette as we would like
// Can't see a nice way to do it.

//https://mui.com/material-ui/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface MuiTheme {
    other: {
      border: string;
      primaryTransparent: string;
      panelBorderRadius: string;
      appBarHeight: string;
      playbookNodeOutlineOther: string;
      playbookNodeOutlineSelected: string;

      playbookStatusPalette: {
        [key in GenericRunningInstanceStepStatus['status']]: {
          main: string;
          _400: string;
        };
      };
      colorRatingActive: string;
      colorAvatar: string;
      chartHeight: number;
    };
  }

  interface ThemeOptions {
    other?: {
      border: string;
      primaryTransparent: string;
      panelBorderRadius: string;
      appBarHeight: string;
      playbookNodeOutlineOther: string;
      playbookNodeOutlineSelected: string;

      playbookStatusPalette: {
        [key in GenericRunningInstanceStepStatus['status']]: {
          main: string;
          _400: string;
        };
      };
      colorRatingActive: string;
      colorAvatar: string;
      chartHeight: number;
    };
  }
}

// Make typescript/emotion aware of the Mui theme
//https://emotion.sh/docs/typescript#define-a-theme
declare module '@emotion/react' {
  export interface Theme extends MuiTheme {
    other: {
      border: string;
      panelBorderRadius: string;
      primaryTransparent: string;
      appBarHeight: string;
      playbookNodeOutlineOther: string;
      playbookNodeOutlineSelected: string;
      playbookStatusPalette: {
        [key in GenericRunningInstanceStepStatus['status']]: {
          main: string;
          _400: string;
        };
      };
      colorRatingActive: string;
      colorAvatar: string;
      chartHeight: number;
    };
  }
}

export const darkTheme = createTheme({
  // We have some custom theme variables we want to add
  other: {
    chartHeight: 370,
    border: 'rgba(255, 255, 255, 0.23)',
    panelBorderRadius: '24px',
    primaryTransparent: 'rgba(144, 202, 249, 0.08)',
    appBarHeight: '56px',

    playbookNodeOutlineOther: 'rgba(255, 255, 255, 0.23)',
    playbookNodeOutlineSelected: '#90CAF9',

    playbookStatusPalette: {
      blocked: {
        main: '#F44336',
        _400: '#EF5350'
      },
      'awaiting input': {
        main: '#F44336',
        _400: '#EF5350'
      },
      exception: {
        main: '#F44336',
        _400: '#EF5350'
      },
      failed: {
        main: '#F44336',
        _400: '#EF5350'
      },
      'in progress': {
        main: '#FFEB3B',
        _400: '#FFEE58'
      },
      success: {
        main: '#4CAF50',
        _400: '#8BC34A'
      },

      ready: {
        main: '#90CAF9', // nb. this is the palette value palette.primary.main
        _400: '#29B6F6'
      }
    },
    colorRatingActive: '#FFB400',
    colorAvatar: '#009688'
  },

  // Set the side of the grid
  spacing: 4,

  // border radius for buttons, inputs etc
  shape: {
    borderRadius: 8
  },

  //Typography
  typography: {
    h1: {
      fontSize: 96,
      fontWeight: 300
    },
    h2: {
      fontSize: 60,
      fontWeight: 300
    },
    h3: {
      fontSize: 48,
      fontWeight: 400
    },
    h4: {
      fontSize: 34,
      fontWeight: 400
    },
    h5: {
      fontSize: 24,
      fontWeight: 500
    },
    h6: {
      fontSize: 20,
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    },
    button: {
      fontSize: 14
    },
    caption: {
      fontSize: 12,
      fontWeight: 400
    },
    overline: {
      fontSize: 12,
      fontWeight: 400
    }
  },

  //Standard mui palette
  palette: {
    mode: 'dark',
    primary: {
      main: '#90CAF9',
      light: '#E3F2FD',
      dark: '#42A5F5'
    },
    secondary: {
      main: '#CE93D8',
      dark: '#AB47BC',
      light: '#F3E5F5'
    },
    background: {
      default: '#051425',
      paper: '#0C1D30'
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255,255,255,0.7)'
    },
    error: {
      main: '#F44336',
      dark: '#D32F2F',
      light: '#E57373'
    },
    warning: {
      main: '#FFA726',
      dark: '#F57C00',
      light: '#FFB74D'
    },
    info: {
      main: '#29B6F6',
      light: '#4FC3F7',
      dark: '#0288D1'
    },
    success: {
      main: '#5ED364',
      dark: '#38A33D',
      light: '#8DD690'
    }
  },

  // Customisations on individual components
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          // We are manually setting the height of button to match the inputs (nb. the inputs have their height set by padding
          // we are just setting the height explicitly, we can change it if it doesn't work out)
          // we also set margin top and bottom to match the dense margin of the input
          height: 40,
          marginBottom: 4,
          marginTop: 8
        },
        sizeSmall: {
          height: 24,
          marginBottom: 4,
          marginTop: 8,
          fontSize: 11
        },

        outlined: ({ theme }) => ({
          //@ts-ignore ugh
          backgroundColor: theme.other.primaryTransparent,
          borderColor: 'transparent'
        })
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary
        })
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('xs')]: {
            minHeight: 56
          }
        })
      }
    },

    MuiButtonBase: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: '6px 16px',
          textTransform: 'none'
        }
      }
    },

    MuiFormControl: {
      defaultProps: {
        // nb. I want to do this, but until we decide what to do with this issue
        // https://github.com/mui/material-ui/issues/33155
        // Then we'll keep it at none
        margin: 'dense'
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 300
        }
      },
      defaultProps: {
        // This fixes an issue of a slight reposition after the tooltip opens.
        // See: https://github.com/mui/material-ui/issues/39064
        TransitionProps: {
          timeout: 0
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8.5px 14px',

          '&:-webkit-autofill': {
            // overriding the chrome autofill style: https://github.com/mui/material-ui/issues/283
            // For this ticket: https://cydarm.atlassian.net/browse/RM-2381
            WebkitBoxShadow: '0 0 0 100px #051425 inset'
          },

          '&.MuiInputBase-inputSizeSmall': {
            borderRadius: 4,
            fontSize: 13,
            lineHeight: '18px',
            padding: '3px 14px'
          }
        },

        root: {
          '&.MuiInputBase-sizeSmall': {
            borderRadius: 4,

            '& legend>span': {
              // Fix annoying space on small variants when the label floats
              padding: 0
            }
          }
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,

          transform: 'translate(14px, 9px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)'
          }
        },

        sizeSmall: {
          fontSize: 13,
          transform: 'translate(14px, 4px) scale(1)'
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          gap: 3
        },
        // This was the only way I could find to get
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: 0
          },

          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: 1
          },

          '& .MuiAutocomplete-tagSizeMedium': {
            height: 23,
            margin: 0
          },

          '& .MuiAutocomplete-tagSizeSmall': {}
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16
        },

        // nb. the heights of the inputs are actually determined by
        // their padding value, not a height value!
        input: {
          padding: '8.5px 14px'
        },

        sizeSmall: {
          '& .MuiInputBase-input': {},
          fontSize: 13,
          '& .MuiSelect-iconOutlined': {
            width: 16
          }
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off'
      }
    },

    MuiPaper: {
      defaultProps: {
        elevation: 1
      }
    }
  }
});

export const useCustomTheme = () => {
  return darkTheme;
};
