/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  usePlaybooksPageSinglePlaybookStyle,
  usePlaybooksPageSinglePlaybookActionStyle
} from './styles';
import { PlaybooksPageSinglePlaybookInfo } from './PlaybooksPageSinglePlaybookInfo';
import { useDispatch } from 'react-redux';
import {
  createAddTagToPlaybook,
  createDeletePlaybook,
  createRemoveTagFromPlaybook,
  createRemoveActionFromPlaybook,
  createFetchPlaybookByUuid,
  createAddActionToPlaybook,
  createMovePlaybookAction,
  createUpdatePlaybook
} from 'states/playbooks/actions';
import { createPlaybookAction } from 'states/playbookActions/actions';
import { CaseTag } from 'interface/CaseTag.interface';
import { AtcPlaybook, PlaybookAction } from 'interface/Playbook.interface';
import { PlaybooksPageActionPanels } from './PlaybooksPageSinglePlaybookAction';
import { useAtcPlaybookActions } from 'hooks/PlaybookActionsHook';
import {
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { PlaybookForm } from 'components/forms/PlaybookForm';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { useCaseTags } from 'hooks/CaseTagsHooks';
import AclDialog from 'components/AclDialog';
import { useAclByUuid, useCurrentDataAcls } from 'hooks/AclHooks';
import { AclSelect } from 'components/fields/AclSelect';
import { CydarmRoute } from 'interface/CydarmRoute';
import { Markdown } from 'components/Markdown';
import { PageToolbar, PageContent } from 'components/Page';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydRightInfoPanel } from 'components/_layout/CydRightInfoPanel/CydRightInfoPanel';
import { useNavigate } from 'react-router';
import { Acl } from 'interface/Acl.interface';
import { CydActionAdderModal } from 'components/_playbooks/CydActionAdderModal/CydActionAdderModal';
/** @jsxImportSource @emotion/react */

type PlaybooksPageSingleAtcPlaybookProps = {
  atcPlaybook: AtcPlaybook;
  selectedAction: any;
};

export const PlaybooksPageSingleAtcPlaybook = (
  props: PlaybooksPageSingleAtcPlaybookProps
) => {
  const { selectedAction, atcPlaybook } = props;

  const { data: caseTags } = useCaseTags();
  const dispatch = useDispatch();
  const formRef = React.useRef<any>();
  const [shouldShowPlaybookForm, setShouldShowPlaybookForm] =
    React.useState(false);

  // The purpose of this bool is to show the modal for adding an action
  const [shouldShowAddActionForm, setShouldShowAddActionForm] =
    React.useState(false);
  const [searchParams, setSearchParams] = React.useState();
  const actionsResults = useAtcPlaybookActions();

  const handleSearchParams = (event: any) => {
    setSearchParams(event.target.value);
  };

  const handleSavePlaybook = (values: AtcPlaybook) => {
    const playbookUuid = atcPlaybook?.uuid ?? '';
    dispatch(createUpdatePlaybook({ uuid: playbookUuid, playbook: values }));
    setShouldShowPlaybookForm(false);
  };

  const handleRequestUpdateAction = () => {
    if (!formRef.current?.submitForm) {
      return;
    }
    formRef.current.submitForm();
  };

  const handleAddTagToPlaybook = (caseTag: CaseTag) => {
    if (!atcPlaybook?.uuid) {
      return;
    }

    dispatch(
      createAddTagToPlaybook({
        playbookUuid: atcPlaybook.uuid,
        caseTagUuid: caseTag.uuid
      })
    );
  };

  const handleRemoveTagFromPlaybook = (caseTag: CaseTag) => {
    if (!atcPlaybook?.uuid) {
      return;
    }
    dispatch(
      createRemoveTagFromPlaybook({
        playbookUuid: atcPlaybook.uuid,
        caseTagUuid: caseTag.uuid
      })
    );
  };

  const navigate = useNavigate();
  const handleDeletePlaybook = () => {
    if (!atcPlaybook?.uuid) {
      return;
    }
    dispatch(
      createDeletePlaybook({
        uuid: atcPlaybook.uuid,
        onSuccess: () => navigate(CydarmRoute.PLAYBOOKS)
      })
    );
  };

  const currentAction = React.useMemo(() => {
    let action: PlaybookAction | undefined;

    if (selectedAction) {
      action = atcPlaybook?.actions?.find(
        (el) => el.actionUuid === selectedAction.actionUuid
      );
    }
    return action ?? atcPlaybook?.actions?.[0];
  }, [selectedAction, atcPlaybook]);

  const handleUpdatePosition = (
    playbookActionUuid: string,
    currentPosition: number,
    newPosition: number
  ) => {
    const playbookUuid = atcPlaybook?.uuid ?? '';
    dispatch(
      createMovePlaybookAction({
        playbookUuid,
        playbookActionUuid,
        currentPosition,
        newPosition
      })
    );
  };

  const handleCreateAndAddAction = (data: {
    title: string;
    description: string;
    acl: Acl;
  }) => {
    dispatch(
      createPlaybookAction({
        playbookAction: {
          atc: {
            position: -1,
            name: data.title,
            description: data.description,
            acl: data.acl.description
          }
        },
        isShowNotification: false,
        resolve: ({ uuid }) => {
          if (!atcPlaybook?.uuid) {
            return;
          }
          dispatch(
            createAddActionToPlaybook({
              playbookUuid: atcPlaybook.uuid,
              playbookActionUuid: uuid,
              PlaybookActionType: {
                atc: {
                  name: data.title,
                  position: -1,
                  description: data.description
                }
              }
            })
          );
        }
      })
    );
    setShouldShowAddActionForm(false);
  };

  const handleAddAction = (data: PlaybookAction) => {
    if (!data.uuid) {
      throw new Error('No uuid on playbookAction');
    }

    dispatch(
      createAddActionToPlaybook({
        playbookUuid: atcPlaybook.uuid,
        playbookActionUuid: data.uuid,
        PlaybookActionType: { atc: data }
      })
    );

    setShouldShowAddActionForm(false);
  };

  const handleRemoveAction = (uuid: string, position: number) => {
    const playbookUuid = atcPlaybook?.uuid ?? '';
    dispatch(
      createRemoveActionFromPlaybook({
        playbookUuid,
        playbookActionUuid: uuid,
        position
      })
    );
    dispatch(createFetchPlaybookByUuid(atcPlaybook?.uuid ?? ''));
  };

  const { contentContainer, searchAction, iconTooltipStyle } =
    usePlaybooksPageSinglePlaybookStyle();

  const {
    title,
    subTitle,
    playbookDescription,
    container,
    playbookFormStyle,
    playbookTitle
  } = usePlaybooksPageSinglePlaybookActionStyle();

  const [openAclDialog, setOpenAclDialog] = React.useState(false);
  const { data: aclList } = useCurrentDataAcls();
  const { data: playbookAcl } = useAclByUuid(atcPlaybook?.acl);

  const handleChangeAcl = (e) => {
    atcPlaybook?.uuid &&
      e.target.value &&
      dispatch(
        createUpdatePlaybook({
          uuid: atcPlaybook?.uuid,
          playbook: { ...atcPlaybook, acl: e.target.value }
        })
      );
  };

  const aclDialogFunc = () => {
    return (
      <div
        css={(theme) => `
      margin-bottom: ${theme.spacing(5)}`}
      >
        <Tooltip
          title="Access Control"
          onClick={() => setOpenAclDialog(true)}
          className={iconTooltipStyle}
        >
          <InfoIcon />
        </Tooltip>
        <AclSelect
          acls={aclList}
          value={playbookAcl}
          onChange={handleChangeAcl}
          placeholder="Select access control"
        />
      </div>
    );
  };

  return (
    <div className={contentContainer}>
      <PageContent>
        <>
          <PageToolbar>
            <TextField
              className={searchAction}
              placeholder={'Search actions'}
              onChange={handleSearchParams}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <CydButton
              variant="contained"
              onClick={() => setShouldShowAddActionForm(true)}
              startIcon="add"
            >
              Add action
            </CydButton>
          </PageToolbar>

          {shouldShowAddActionForm && (
            <CydActionAdderModal
              availableAcls={aclList}
              defaultAcl={playbookAcl}
              availableActions={
                actionsResults.data.map((v) => v.atc) as Array<PlaybookAction>
              }
              onAddNewAction={handleAddAction}
              onCreateAction={handleCreateAndAddAction}
              isOpen={shouldShowAddActionForm}
              onClose={() => setShouldShowAddActionForm(false)}
              buttonTextCreateAndAdd="Create and add to playbook"
              buttonTextAddExisting="Add to playbook"
            />
          )}
        </>

        <div className={container} data-testid="playbook-container">
          {!shouldShowPlaybookForm && atcPlaybook && (
            <>
              <div className={playbookTitle}>
                <Typography variant="h1" className={title}>
                  {atcPlaybook?.name}
                  <IconButton onClick={() => setShouldShowPlaybookForm(true)}>
                    <EditIcon />
                  </IconButton>
                </Typography>
              </div>
              <div>
                <Typography className={subTitle}>Description</Typography>
                {atcPlaybook && (
                  <Markdown className={playbookDescription}>
                    {atcPlaybook.description}
                  </Markdown>
                )}
                {aclDialogFunc()}
              </div>
            </>
          )}

          {shouldShowPlaybookForm && (
            <div className={playbookFormStyle}>
              <PlaybookForm
                formRef={formRef}
                onSubmit={handleSavePlaybook}
                playbook={atcPlaybook}
              />
              <CydButtonStack justifyContent="flex-end">
                <CydButton
                  variant="text"
                  onClick={() => setShouldShowPlaybookForm(false)}
                >
                  Cancel
                </CydButton>
                <CydButton
                  variant="contained"
                  onClick={handleRequestUpdateAction}
                >
                  Save
                </CydButton>
              </CydButtonStack>
            </div>
          )}
        </div>

        {currentAction && (
          <PlaybooksPageActionPanels
            playbook={atcPlaybook}
            onRemoveAction={handleRemoveAction}
            playbookAction={currentAction}
            onUpdatePosition={handleUpdatePosition}
            searchParams={searchParams}
          />
        )}
      </PageContent>
      <CydRightInfoPanel>
        {atcPlaybook && (
          <PlaybooksPageSinglePlaybookInfo
            caseTags={caseTags}
            playbook={atcPlaybook}
            onAddTagToPlaybook={handleAddTagToPlaybook}
            onDeletePlaybook={handleDeletePlaybook}
            onRemoveTagOnPlaybook={handleRemoveTagFromPlaybook}
          />
        )}
        {playbookAcl && (
          <AclDialog
            open={openAclDialog}
            onClose={() => setOpenAclDialog(false)}
            acl={playbookAcl}
            disabled={true}
          />
        )}
      </CydRightInfoPanel>
    </div>
  );
};
