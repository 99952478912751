/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
import { useDefaultDialogStyles } from './styles';

interface IDefaultDialogProps extends DialogProps {
  actions?: React.ReactNode;
}

/**
 * @deprecated
 * @param param0
 * @returns
 */
export const DefaultDialog: React.FC<IDefaultDialogProps> = ({
  title,
  children,
  actions,
  classes,
  ...props
}) => {
  const { content, header, container, actionsWrapper } =
    useDefaultDialogStyles();

  return (
    <Dialog classes={{ paper: container, ...classes }} {...props}>
      <DialogTitle className={header}>{title}</DialogTitle>
      <div className={content}>
        {children}
        {actions && <div className={actionsWrapper}>{actions}</div>}
      </div>
    </Dialog>
  );
};
