/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAsset,
  IAssetResponse,
  UpdateAssetSuccessPayload
} from 'interface/Assets.interface';
import { splitStringToArray } from 'utils/StringUtils';

export interface IAssetState {
  results: IAsset[];
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: IAssetState = {
  results: [],
  isLoading: false,
  hasLoaded: false
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    updateAssetSuccess: (
      state,
      { payload: updatedAssetPayload }: PayloadAction<UpdateAssetSuccessPayload>
    ) => {
      state.results = state.results.map((asset: IAsset) => {
        if (asset.uuid === updatedAssetPayload.uuid) {
          return {
            ...asset,
            ...updatedAssetPayload,
            infrastructureTypes: splitStringToArray(
              updatedAssetPayload.infrastructureTypes
            ),
            ipAddresses: splitStringToArray(updatedAssetPayload.ipAddresses)
          };
        }
        return asset;
      });
    },

    fetchAssets: (state) => {
      state.isLoading = true;
    },

    fetchAssetsSuccess: (
      state,
      { payload }: PayloadAction<IAssetResponse[]>
    ) => {
      state.isLoading = false;
      state.results = payload.map((assetResponse: IAssetResponse) => {
        const {
          data: {
            name,
            infrastructure_types: infrastructureTypes,
            description,
            location,
            ip_addresses: ipAddresses,
            organisation,
            deprecated
          },
          acl,
          uuid
        } = assetResponse;

        return {
          uuid,
          acl,
          description,
          infrastructureTypes: splitStringToArray(infrastructureTypes),
          ipAddresses: splitStringToArray(ipAddresses),
          location,
          name,
          organisation,
          deprecated
        };
      });
      state.hasLoaded = true;
    },

    fetchAssetsFailed: (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
    }
  }
});

export const {
  reducer: assetsStore,
  actions: {
    updateAssetSuccess,
    fetchAssets,
    fetchAssetsSuccess,
    fetchAssetsFailed
  }
} = assetsSlice;
