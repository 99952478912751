/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { DataSignificance } from 'interface/DataSignificance.interface';
import {
  DefaultSelect,
  IDefaultSelectProps
} from 'components/fields/DefaultSelect';

interface IDataSignificanceSelectProps
  extends Omit<IDefaultSelectProps, 'options'> {
  dataSignificances: DataSignificance[];
  disabled?: boolean;
}

export const DataSignificanceSelect: React.FC<IDataSignificanceSelectProps> = ({
  dataSignificances,
  value,
  ...rest
}) => {
  const dataSignificanceOptions = React.useMemo(
    () =>
      dataSignificances.map((dataSignificance) => ({
        value: dataSignificance,
        label: dataSignificance.name
      })),
    [dataSignificances]
  );

  const emptyValue = '';

  const _value =
    value &&
    dataSignificances.find(
      ({ uuid }) => uuid === (value as DataSignificance).uuid
    );

  return (
    <DefaultSelect
      {...rest}
      value={_value || emptyValue}
      options={dataSignificanceOptions}
    />
  );
};
