/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { IconButton, Tooltip, Typography } from '@mui/material';
import { CydIcon, CydIconTypes } from 'components/_foundation/CydIcon/CydIcon';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
export type CydInfoIconProps = {
  className?: string;
  text: string;
  icon?: CydIconTypes;

  variant?: 'flat' | 'tooltip';
};

export function exhaustiveFailure(string: never): never {
  throw new Error(`No handler exists for string type: '${string}'`);
}

export const CydInfoIcon = (props: CydInfoIconProps) => {
  const { className, text, icon = 'info', variant = 'flat' } = props;

  if (variant === 'flat') {
    return (
      <div
        className={className}
        css={(theme) => ` 
  
      color: ${theme.palette.text.primary}; 
      display: flex; 
      flex-flow: row nowrap; 
      gap: ${theme.spacing(1)}; 
      align-items: center; 
      `}
      >
        <>
          <CydIcon icon={icon} />
          <Typography variant="body1">{text}</Typography>
        </>
      </div>
    );
  }

  if (variant === 'tooltip') {
    return (
      <>
        <Tooltip title={text}>
          <IconButton
            css={css`
              padding: 0;
            `}
          >
            <CydIcon icon={icon} />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return exhaustiveFailure(variant);
};
