/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Chip, Grid } from '@mui/material';
import { DefaultSelect } from 'components/fields/DefaultSelect';
import { useCaseTags, useCaseTagsFiltered } from 'hooks/CaseTagsHooks';
import { CaseTag } from 'interface/Tags.interface';
import { usePlaybooksPageSinglePlaybookInfoStyle } from 'pages/PlaybooksPage/PlaybooksPagePlaybooks/styles';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  addCasePlaybookActionTag,
  removeCasePlaybookActionTag
} from 'states/casePlaybooks/actions';
import { useCasePlaybookListItemActionStyles } from '../styles';

const mapCaseTagsToOptions = (caseTags: CaseTag[]) =>
  caseTags.map((caseTag) => ({
    label: caseTag.value,
    value: caseTag.value
  }));

export const ActionItemTags = ({ securityCase, casePlaybookUuid, action }) => {
  const { data: tagList } = useCaseTags();
  const { data: tagListFiltered } = useCaseTagsFiltered();
  const tagOption = tagListFiltered && mapCaseTagsToOptions(tagListFiltered);
  const dispatch = useDispatch();
  const { tags, actionUuid } = action;
  const { tagChip, tagChipLabel, tagChipDiv } =
    usePlaybooksPageSinglePlaybookInfoStyle();

  const { assigneeSelect } = useCasePlaybookListItemActionStyles();
  const [selectedOption, setSelectedOption] = React.useState('');

  const handleRemoveTag = (tagName: string) => {
    const selectedTag = tagList && tagList.find((el) => el.value === tagName);
    actionUuid &&
      selectedTag &&
      dispatch(
        removeCasePlaybookActionTag({
          caseUuid: securityCase.uuid,
          casePlaybookUuid,
          tagUuid: selectedTag.uuid,
          actionInstance: action
        })
      );
  };
  const handleTagsChange = (selectedTagName) => {
    const selectedTag =
      selectedTagName && tagList.find((el) => el.value === selectedTagName);
    actionUuid &&
      selectedTag &&
      dispatch(
        addCasePlaybookActionTag({
          caseUuid: securityCase.uuid,
          casePlaybookUuid,
          tagUuid: selectedTag.uuid,
          actionInstance: action
        })
      );
    setSelectedOption('');
  };

  const renderValue = useCallback(
    (selected) => {
      if (!selectedOption || selectedOption.length === 0) {
        return <span style={{ paddingLeft: '10px' }}>{'Add new tag'}</span>;
      }
      setSelectedOption(selected);
      return;
    },
    [selectedOption]
  );

  return (
    <Grid item xs={2}>
      <div className={tagChipDiv}>
        {tags &&
          tags.map((tag, index) => {
            return (
              <Chip
                label={tag}
                key={`${tag}_${index}`}
                color="primary"
                className={tagChip}
                classes={{ label: tagChipLabel }}
                onDelete={() => {
                  handleRemoveTag(tag);
                }}
              ></Chip>
            );
          })}
        <DefaultSelect
          options={tagOption}
          placeholderSelection={false}
          onChange={(e) => handleTagsChange(e.target.value)}
          renderValue={renderValue}
          style={{ maxWidth: 180 }}
          defaultValue={''}
          className={assigneeSelect}
        />
      </div>
    </Grid>
  );
};
