/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  DefaultSelect,
  IDefaultSelectProps
} from 'components/fields/DefaultSelect';

interface IEncryptionSelectProps extends Omit<IDefaultSelectProps, 'options'> {
  options: any[];
  value?: any;
  disabled: boolean;
}
// This has just been created with mock data and will need fetch correct data from API
export const EncryptionKeySelect: React.FC<IEncryptionSelectProps> = ({
  options,
  value,
  ...props
}) => {
  return (
    <DefaultSelect options={options} value={value} {...props}></DefaultSelect>
  );
};
