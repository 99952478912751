/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Mttx = { data: IMTTx[] };
export interface IMttxMetricsStoreState {
  mttx: {
    isLoading: boolean;
    data: Mttx;
  };
  mttr: {
    isLoading: boolean;
    data: Mttx;
  };
}

export interface IMTTx {
  period: string;
  number_cases: number;
  mean_seconds: any;
  median_seconds: number;
  std_dev_seconds: number;
  max_seconds: number;
  min_seconds: number;
  q1_seconds: number;
  q3_seconds: number;
}

const initialState: IMttxMetricsStoreState = {
  mttx: {
    isLoading: false,
    data: {
      data: []
    }
  },
  mttr: {
    isLoading: false,
    data: {
      data: []
    }
  }
};

const mttxSlice = createSlice({
  name: 'mttxStore',
  initialState,
  reducers: {
    fetchMttxRequest: (state) => {
      state.mttx.isLoading = true;
    },

    fetchMttrRequest: (state) => {
      state.mttr.isLoading = true;
    },

    fetchMttxMetricsSuccess: (state, { payload }: PayloadAction<Mttx>) => {
      state.mttx.data = { ...state.mttx.data, ...payload };
      state.mttx.isLoading = false;
    },
    fetchMttrMetricsSuccess: (state, { payload }: PayloadAction<Mttx>) => {
      state.mttr.data = { ...state.mttx.data, ...payload };
      state.mttr.isLoading = false;
    }
  }
});

export const {
  reducer: mttxStore,
  actions: {
    fetchMttxMetricsSuccess,
    fetchMttrMetricsSuccess,
    fetchMttxRequest,
    fetchMttrRequest
  }
} = mttxSlice;
