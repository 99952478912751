import { Report } from 'interface/Report.interface';
import { mdReport } from '../../testData/testScenario_basicReportPreviewa/markdownPreview';

type ReportPreview = {
  report: string;
};

export async function getReportPreview(
  reportOptions: Report
): Promise<ReportPreview> {
  return {
    report: mdReport
  };
}
