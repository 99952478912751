import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ palette }) => ({
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
    color: palette.text.disabled
  }
}));
