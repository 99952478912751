/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const AUTH_SOURCE = {
  CERTIFICATE: { value: 'Certificate', text: 'Certificate' },
  INTERNAL: { value: 'internal', text: 'Internal' },
  SSO: { value: 'sso', text: 'Single Sign On' }
};

const AUTH_SOURCE_LIST = [AUTH_SOURCE.INTERNAL];

export { AUTH_SOURCE, AUTH_SOURCE_LIST };
