import { Theme } from '@emotion/react';
import { GenericNodeWithPossibleStatus } from './BaseCustomNode';
import { AvailableStepStatuses } from '../type';
import { green, red, yellow } from '@mui/material/colors';

const STATUS_BORDER_STYLES: Record<
  AvailableStepStatuses | 'no-status',
  string | null
> = {
  blocked: `solid 2px ${red[500]};`,
  'awaiting input': `solid 2px ${red[500]};`,
  'in progress': `solid 2px ${yellow[500]};`,
  success: `solid 1px ${green[500]};`,
  exception: `solid 2px ${red[500]};`,
  failed: `solid 2px ${red[500]};`,
  ready: null,
  'no-status': null
};

export function outerBorder(theme: Theme, node: GenericNodeWithPossibleStatus) {
  return `
  border: ${
    STATUS_BORDER_STYLES[node.nodeStatus?.status || 'no-status'] ||
    `solid 1px ${theme.other.playbookNodeOutlineOther};`
  }

  background-color: ${theme.palette.background.paper};
   
  `;
}

export function innerBorder(theme: Theme, node: GenericNodeWithPossibleStatus) {
  return `
  border: solid 3px ${
    node.isSelected ? theme.other.playbookNodeOutlineSelected : 'transparent'
  };

  background-color: ${
    node.isSelected ? theme.other.primaryTransparent : 'transparent'
  };

  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.action.hover};
  }
  `;
}
