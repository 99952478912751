/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydTextField } from 'components/_formElements/CydTextField';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { JsonApiDataItem } from 'interface/JsonApi';
import { Acl } from 'interface/Acl.interface';
import React, { useRef, useState } from 'react';
import {
  CydApi_WikiPageObject,
  CydApi_WikiPageObjectRelationships,
  WikiPagePayload
} from 'services/WikiService';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydBaseModal } from 'components/_dialogs/CydBaseModal/CydBaseModal';
import { useUnloadWarning } from 'hooks/usePreventUnloadWarning';
import { Typography } from '@mui/material';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Base64Decode } from 'utils/StringUtils';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

export type WikiPageCreateAndEditProps = {
  wikiItem?: JsonApiDataItem<
    CydApi_WikiPageObject,
    CydApi_WikiPageObjectRelationships
  >;
  onEditWiki: (data: WikiPagePayload) => void;
  availableAcls: Array<Acl>;
  defaultAcl: Acl | null;
  onCloseClick: () => void;
};

export const WikiPageCreateAndEdit = (props: WikiPageCreateAndEditProps) => {
  const { wikiItem, onEditWiki, availableAcls, defaultAcl, onCloseClick } =
    props;

  useUnloadWarning();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  function isUnchangedFn(formData: FormData) {
    const isUnchanged =
      formData.get('acl')?.toString() ===
        wikiItem?.relationships?.acl?.data.id &&
      formData.get('title')?.toString() === wikiItem?.attributes.title &&
      formData.get('wiki-content')?.toString() ===
        Base64Decode(wikiItem?.attributes.content ?? '');

    return isUnchanged;
  }

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <CydBaseModal
        isOpen
        size="xxlarge"
        dontShowCloseButton
        onClose={() => {
          formRef.current?.reset();
        }}
        css={css`
          display: flex;
          flex-flow: column nowrap;
        `}
      >
        <form
          ref={formRef}
          onReset={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const isUnchanged = isUnchangedFn(formData);

            if (isUnchanged) {
              onCloseClick();
            } else {
              setIsConfirmModalOpen(true);
            }
          }}
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const isUnchanged = isUnchangedFn(formData);

            if (isUnchanged) {
              onCloseClick();
            } else {
              const aclUuid = formData.get('acl')?.toString();
              const title = formData.get('title')?.toString();
              const content = formData.get('wiki-content')?.toString() || '';

              if (!aclUuid || !title) {
                // this should never happen
                throw new Error('ACL or title was not present');
              }

              onEditWiki({
                acl_uuid: aclUuid,
                title: title,
                content: content,
                parent_uuid: wikiItem?.relationships?.parent?.data.id
              });
              onCloseClick();
            }
          }}
          css={css`
            display: flex;
            flex-flow: column nowrap;
            flex: 1 1 auto;
          `}
        >
          <CydButtonStack justifyContent={'flex-end'}>
            <CydButton type="reset" variant="outlined">
              Close
            </CydButton>
            <CydButton type="submit">Submit</CydButton>
          </CydButtonStack>
          <CydButtonStack
            direction="row"
            css={(theme) => `
               ${theme.spacing(4)};
            `}
          >
            <CydTextField
              required
              label="Title"
              defaultValue={wikiItem?.attributes.title}
              name="title"
            />
            <CydAclSelector
              required
              availableAcls={availableAcls}
              defaultSelectedAclUuid={
                wikiItem?.relationships?.acl?.data.id ??
                defaultAcl?.uuid ??
                null
              }
              name="acl"
            />
          </CydButtonStack>
          <div
            css={css`
              height: calc(100vh - 350px);
            `}
          >
            <CydEnhancedMarkdownEditor
              initialValue={Base64Decode(wikiItem?.attributes.content || '')}
              name="wiki-content"
              label="Content"
            />
          </div>
          {isConfirmModalOpen && (
            <CydBaseModal
              isOpen={isConfirmModalOpen}
              onClose={() => setIsConfirmModalOpen(false)}
              title="Are you sure?"
              dontShowCloseButton
            >
              <>
                <Typography variant="body1">
                  If you close this page, your data will be lost
                </Typography>
                <CydButtonStack justifyContent="flex-end">
                  <CydButton
                    variant="text"
                    onClick={() => setIsConfirmModalOpen(false)}
                  >
                    Cancel
                  </CydButton>
                  <CydButton onClick={onCloseClick}>Continue</CydButton>
                </CydButtonStack>
              </>
            </CydBaseModal>
          )}
        </form>
      </CydBaseModal>
    </>
  );
};
