import {
  createSelectorCreator,
  defaultMemoize,
  createSelector
} from 'reselect';
import deepEqual from 'react-fast-compare';
import { usersWithDisplayNameSelector } from 'states/users/selectors';
import { watchingCasesSelector } from 'states/watchingCases/selectors';
import { IStore } from 'states/store.interface';
import { transformSingleCase } from 'utils/CaseUtils';

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  deepEqual
);

export const caseListSelector = ({ cases: { list } }: IStore) => list;

export const caseListLoadingSelector = ({ cases: { isLoading } }: IStore) =>
  isLoading;

export const caseListLoadedSelector = ({ cases: { hasLoaded } }: IStore) =>
  hasLoaded;

export const caseSelector = createDeepEqualSelector(
  caseListSelector,
  watchingCasesSelector,
  usersWithDisplayNameSelector,
  (cases, watchingCases, users) =>
    cases.map((securityCase) => {
      const assignedUser = users.find(
        ({ username }) => username === securityCase.assignee
      );

      return {
        ...securityCase,
        isWatched: watchingCases.some(
          (caseUuid) => caseUuid === securityCase.uuid
        ),
        isGroupCase: securityCase.members && securityCase.members.length > 0,
        assignedUserDisplayName: assignedUser && assignedUser.displayName,
        tagsDescription: securityCase.tags && securityCase.tags.join(', '),
        playbookProgressPercent:
          securityCase.totalActionsInAllPlaybooks &&
          (securityCase.totalCompletedActionsInAllPlaybooks /
            securityCase.totalActionsInAllPlaybooks) *
            100
      };
    })
);

export const singleCaseSelector = createSelector(
  watchingCasesSelector,
  caseListSelector,
  (_, caseUuid) => caseUuid,
  (watchingCases, caseList, caseUuid) => {
    const singleCase = caseList.find(({ uuid }) => uuid === caseUuid);
    return singleCase
      ? transformSingleCase(singleCase, watchingCases)
      : undefined;
  }
);

export const memberCasesSelector = createSelector(
  caseListSelector,
  (_, memberUuids) => memberUuids,
  (caseList, memberUuids) =>
    (memberUuids &&
      caseList.filter(({ uuid }) => memberUuids.includes(uuid))) ||
    []
);

export const parentCasesSelector = createSelector(
  caseListSelector,
  (_, parentUuids) => parentUuids,
  (caseList, parentUuids) =>
    (parentUuids &&
      caseList.filter(({ uuid }) => parentUuids.indexOf(uuid) !== -1)) ||
    []
);
