import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import { IQuickSearchResult } from 'states/IoCQuickSearch/slice';

const iocQuickSearchSelector = ({ IoCQuickSearch }: IStore) =>
  IoCQuickSearch.results;

export const iocQuickSearchResultsSelector = createSelector(
  iocQuickSearchSelector,
  (quickSearchResults: { [key: string]: Array<IQuickSearchResult> }) => {
    let retVal = {};
    const entries = Object.entries(quickSearchResults) as Array<
      [string, Array<IQuickSearchResult>]
    >;
    if (entries.length) {
      for (const [key, data] of entries) {
        retVal[key] = data.map((d: IQuickSearchResult) => d.case);
      }
    }
    return retVal;
  }
);
