/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { CydApi_CasePlaybookInstance } from 'interface/Playbook.interface';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { useCasePlaybookListItemStyles } from './styles';
import { Case } from 'interface/Case.interface';
import { CasePlaybookActionItem } from './CasePlaybookAction/CasePlaybookActionItem';
import { removePlaybookFromCase } from 'states/casePlaybooks/actions';
import { useDispatch } from 'react-redux';
import { Collapse } from 'components/Collapse/Collapse';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { Markdown } from 'components/Markdown';

export interface ICasePlaybookListItemProps {
  casePlaybook: CydApi_CasePlaybookInstance;
  casePlaybookUuid: string;
  case: Case;
}

export const CasePlaybookListItem: React.FC<ICasePlaybookListItemProps> = ({
  casePlaybook,
  casePlaybookUuid,
  case: securityCase
}) => {
  const {
    playbookName,
    playbookDescription,
    action_statuses = []
  } = casePlaybook;
  const dispatch = useDispatch();
  const {
    itemContainer,
    itemTitle,
    itemHeader,
    actionsHeading,
    itemActionContainer
  } = useCasePlaybookListItemStyles();

  const noOfCompletedActions = React.useMemo(
    () => action_statuses.filter(({ status }) => status === 'success').length,
    [action_statuses]
  );

  const handleItemDelete = () => {
    if (!casePlaybook || !casePlaybookUuid) {
      return;
    }

    dispatch(
      removePlaybookFromCase({
        caseUuid: securityCase.uuid,
        playbookUuid: casePlaybookUuid
      })
    );
  };

  return (
    <div className={itemContainer}>
      <Grid container className={itemHeader}>
        <Grid item xs={9}>
          <Typography className={itemTitle}>{playbookName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="right">
            {' '}
            {noOfCompletedActions} / {action_statuses.length} completed
          </Typography>
          {action_statuses.length > 0 && (
            <LinearProgress
              color="primary"
              variant="determinate"
              value={(noOfCompletedActions / action_statuses.length) * 100}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Collapse title="Description">
            <Markdown>{playbookDescription}</Markdown>
          </Collapse>
        </Grid>
      </Grid>
      <Grid className={actionsHeading} container>
        <Grid item xs={6}>
          <Typography>Action</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>Tags</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center">Assignee</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center">Is Completed?</Typography>
        </Grid>
      </Grid>
      <div>
        {[...action_statuses]
          .sort((actionA, actionB) => actionA.position - actionB.position)
          .map((action) => (
            <CasePlaybookActionItem
              key={`${casePlaybookUuid}_${action.actionUuid}}`}
              action={action}
              case={securityCase}
              casePlaybookUuid={casePlaybookUuid}
              belongsToPlaybook={true}
            />
          ))}
      </div>
      <div className={itemActionContainer}>
        <CydButton onClick={handleItemDelete}>Delete Playbook</CydButton>
      </div>
    </div>
  );
};
