/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import React from 'react';

/** @jsxImportSource @emotion/react */

export type CydObjectNotFoundProps = {
  objectId?: string | number | null;
  fallback?: React.ReactNode;
};

export const CydObjectNotFound = (props: CydObjectNotFoundProps) => {
  const { fallback = null } = props;

  const { isToggleEnabled } = useFeatureToggles();
  const isDebugMode = isToggleEnabled('REACT_APP_DEBUG_MODE');

  if (isDebugMode) {
    return (
      <div
        css={(theme) => `
    color: ${theme.palette.error.main}; 
    border: ${theme.palette.error.main}
  `}
      >
        Object not found
      </div>
    );
  }

  return <>{fallback}</>;
};
