/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventTriggerPatchResource } from './EventTriggerPatchResource';
import {
    EventTriggerPatchResourceFromJSON,
    EventTriggerPatchResourceFromJSONTyped,
    EventTriggerPatchResourceToJSON,
} from './EventTriggerPatchResource';

/**
 * 
 * @export
 * @interface EventTriggerPatchTopLevel
 */
export interface EventTriggerPatchTopLevel {
    /**
     * 
     * @type {EventTriggerPatchResource}
     * @memberof EventTriggerPatchTopLevel
     */
    data?: EventTriggerPatchResource | null;
}

/**
 * Check if a given object implements the EventTriggerPatchTopLevel interface.
 */
export function instanceOfEventTriggerPatchTopLevel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventTriggerPatchTopLevelFromJSON(json: any): EventTriggerPatchTopLevel {
    return EventTriggerPatchTopLevelFromJSONTyped(json, false);
}

export function EventTriggerPatchTopLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTriggerPatchTopLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : EventTriggerPatchResourceFromJSON(json['data']),
    };
}

export function EventTriggerPatchTopLevelToJSON(value?: EventTriggerPatchTopLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': EventTriggerPatchResourceToJSON(value.data),
    };
}

