/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useDefaultPanelStyles = makeStyles(({ spacing, palette }) => ({
  panelHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(3),
    maxWidth: '100%'
  },
  panelActions: {
    display: 'flex',
    gap: spacing(2)
  },
  panelActionsItem: {
    color: palette.primary.main,
    textTransform: 'uppercase'
  },
  panelTitle: {
    fontSize: 24,
    color: palette.text.primary
  }
}));
