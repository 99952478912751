/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { v4 as uuid } from 'uuid';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { CydFileProgress } from 'components/_dialogs/CydFileProgress/CydFileProgress';
import axios, { Method } from 'axios';
import { getAccessToken } from 'utils/TokenManager';

export const apiBaseUploadFile = async ({
  url,
  data,
  fileName,
  method = 'POST'
}: {
  url: string;
  data: any;
  fileName: string;
  method?: Method;
}): Promise<any> => {
  const authToken = getAccessToken();
  const uniqueId = uuid();
  const progressRef = {
    current: 0 as number | 'success' | 'error'
  };
  enqueueSnackbar(
    <CydFileProgress fileName={fileName} progressRef={progressRef} />,
    {
      persist: true,
      key: uniqueId,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    }
  );
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        'X-Cydarm-Authz': authToken,
        'Content-Type': 'application/vnd.api+json'
      },
      onUploadProgress: (e) => {
        progressRef.current = Math.floor((e.progress ?? 0) * 100);
      }
    });
    progressRef.current = 'success';

    setTimeout(() => {
      closeSnackbar(uniqueId);
    }, 2000);
    return response;
  } catch (error) {
    progressRef.current = 'error';

    setTimeout(() => {
      closeSnackbar(uniqueId);
    }, 2000);
    throw new Error(error);
  }
};
