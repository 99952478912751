/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
export type TemplatedField = {
  template: string;
};

export type HeaderTemplateFields = Record<string, TemplatedField>;

export type OperationTypeSmtpSendEmailFields = {
  bcc?: Array<TemplatedField>;
  body: TemplatedField;
  cc?: Array<TemplatedField>;
  from?: TemplatedField;
  headers?: HeaderTemplateFields;
  reply_to?: TemplatedField;
  subject?: TemplatedField;
  to?: Array<TemplatedField>;
};

export type OperationTypeSendSlackWebhookFields = {
  template?: TemplatedField;
};

// All the generic webhook operation type fields defined in https://cydarm.atlassian.net/browse/RM-4493
export type OperationTypeGenericWebhookFields = {
  url_path: TemplatedField;
  content_type: string;
  headers: HeaderTemplateFields;
  request_body: TemplatedField;
};

export type OperationDefaults =
  | OperationTypeSmtpSendEmailFields
  | OperationTypeSendSlackWebhookFields
  | OperationTypeGenericWebhookFields;

export function isOperationTypeGenericWebhookFields(
  fields: OperationDefaults
): fields is OperationTypeGenericWebhookFields {
  return (fields as OperationTypeGenericWebhookFields).url_path !== undefined;
}

export function isOperationTypeSmtpSendEmailFields(
  fields: OperationDefaults
): fields is OperationTypeSmtpSendEmailFields {
  return (fields as OperationTypeSmtpSendEmailFields).body !== undefined;
}

export function isOperationTypeSendSlackWebhookFields(
  fields: OperationDefaults
): fields is OperationTypeSendSlackWebhookFields {
  const typedFields = fields as OperationTypeSendSlackWebhookFields;
  return (
    typedFields.template !== undefined && Object.keys(typedFields).length === 1
  );
}

export enum ConnectorOperationTypeName {
  SendEmailTemplate = 'smtp-send-email-template',
  SendSlackWebhook = 'operation-type-send-slack-webhook',
  SendGenericWebhook = 'operation-type-send-generic-webhook'
}

export enum EventTriggerType {
  CaseCreated = 'event-case-created',
  CaseAssigneeUpdated = 'event-case-assignee-updated',
  CaseStatusUpdated = 'event-case-status-updated',
  ConfigUpdated = 'event-config-updated',
  CaseUpdated = 'event-case-updated',
  CaseTagAdded = 'event-case-tag-added',
  CaseDataCreated = 'event-case-data-created',
  CaseDataCreatedStixCyberObs = 'event-case-data-created-stix-cyber-obs',
  CaseDataCreatedForm = 'event-case-data-created-form'
}

export enum ConnectorName {
  EmailPollerAccount = 'Email Poller Account',
  EmailSenderAccount = 'Email Sender Account',
  GenericWebhookSender = 'Generic Webhook Sender',
  SlackWebhookSender = 'Slack Webhook Sender',
  TenableConnector = 'Tenable.io Connector'
}

export enum ConnectorTypeName {
  EmailPollerAccount = 'email-poller-account',
  EmailSenderAccount = 'smtp-email-send',
  GenericWebhookSender = 'connector-type-generic-webhook-sender',
  SlackWebhookSender = 'connector-type-slack-webhook-sender',
  TenableConnector = 'tenable'
}
