/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';
import { LOCAL_STORAGE } from '../constants';
import { configureStore, Store } from '@reduxjs/toolkit';
import { IStore } from './store.interface';
import { rootReducer } from './rootReducer';
import { mergeDeep } from 'utils/ObjectUtils';
import { allRtkqApis } from './rtkqApis';
import { provideStore } from './provideStore';

// eslint-disable-next-line no-restricted-imports
import { createApiServices } from 'testUtils/createServices';
import * as ApiServices from '../services/index';

const sagaMiddleware = createSagaMiddleware();

const initialStoreState = {
  auth: {
    isLoading: false,
    signedIn: false,
    hasLoaded: false,
    timeZone: localStorage.getItem(LOCAL_STORAGE.TIME_ZONE) || undefined
  }
};

// Default services are all error throwing services
const DEFAULT_API_SERVICES = createApiServices({});

export function getStore(client: typeof ApiServices = DEFAULT_API_SERVICES) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // disabling serializableCheck is temporary and we should aim to disable as soon as possible
      getDefaultMiddleware({
        thunk: {
          extraArgument: client
        },
        serializableCheck: false
      }).concat(sagaMiddleware, ...allRtkqApis.map((v) => v.middleware)),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialStoreState
  });
  sagaMiddleware.run(sagas);

  provideStore(store);

  return store;
}

/**
 * THIS APPROACH WAS A MISTAKE AND WE SHOULD GET RID OF IT AS SOON AS POSSIBLE
 */
// NB!
// While this thing can provide initial state.
// None of the reducers actually update it.
export const createStoreWithInitialData = (
  data: Partial<IStore>,
  client: typeof ApiServices = DEFAULT_API_SERVICES
) => {
  const store: Store<IStore> = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // disabling serializableCheck is temporary and we should aim to disable as soon as possible
      getDefaultMiddleware({
        thunk: {
          extraArgument: client
        },
        serializableCheck: false
      }).concat(sagaMiddleware, ...allRtkqApis.map((v) => v.middleware)),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: mergeDeep(initialStoreState, data)
  });

  sagaMiddleware.run(sagas);

  provideStore(store);

  return store;
};
