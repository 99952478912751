/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useMemo } from 'react';

import {
  CYDARM_FORM_MIMETYPE,
  MARKDOWN_TEXT_MIME_TYPE,
  STIX_MIMETYPE2_0,
  STIX_MIMETYPE2_1
} from 'utils/CaseDataUtils';
import { caseFormToMarkdown } from 'utils/CaseFormsUtils';
import { codeBlockStyle } from '../styles';
import { Markdown } from 'components/Markdown';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const CaseActivityContentMarkdown = ({ caseActivity }) => {
  const { htmlString, content, mimetype, filename, audit } = caseActivity;
  const parsedContent =
    mimetype === CYDARM_FORM_MIMETYPE ? caseFormToMarkdown(content) : content;

  const { codeBlock } = codeBlockStyle();

  const showMarkdown = useMemo(
    () =>
      !audit &&
      !htmlString &&
      (filename === '' ||
        mimetype === MARKDOWN_TEXT_MIME_TYPE ||
        mimetype === STIX_MIMETYPE2_0 ||
        mimetype === STIX_MIMETYPE2_1),
    [audit, mimetype, filename, htmlString]
  );

  return showMarkdown ? (
    <Markdown
      css={css`
        > *:first-child {
          margin-top: 0;
        }
      `}
      className={codeBlock}
    >
      {parsedContent}
    </Markdown>
  ) : null;
};
