/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { REQUIRED_ENV_VARS } from 'featureFlags';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import React from 'react';

export type CydFeatureTogglesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CydFeatureTogglesModal = (props: CydFeatureTogglesModalProps) => {
  const { isOpen, onClose } = props;

  const { isToggleEnabled, setToggle } = useFeatureToggles();
  return (
    <CydSimpleFormModal isOpen={isOpen} onClose={onClose} size="xlarge">
      <>
        <FormGroup>
          {REQUIRED_ENV_VARS.map((v) => {
            const isOn = isToggleEnabled(v);
            return (
              <FormControlLabel
                key={v}
                control={
                  <Switch
                    checked={isOn}
                    disabled={v === 'REACT_APP_DEBUG_MODE'}
                    onClick={() => {
                      setToggle(v, !isOn);
                    }}
                  />
                }
                label={v}
              />
            );
          })}
        </FormGroup>
      </>
    </CydSimpleFormModal>
  );
};
