/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState } from 'react';
import { CydCaseDetailsPanelSection } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanelSection';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydCaseGroupList } from 'components/_caseView/CydCaseGroupList/CydCaseGroupList';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { Case } from 'interface/Case.interface';

import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { CydTextField } from 'components/_formElements/CydTextField';
import { CydCaseSelector } from 'components/_objectSelectors/CydCaseSelector/CydCaseSelector';

type CydCaseViewCaseGroupSectionProps = {
  title?: string;
  cases: Array<Case>;
  onAddCase: (locator: string) => void;
  onRemoveCase: (caseUuid: string) => void;
  emptyListMessage?: string;
  isEditable?: boolean;
  'data-testid'?: string;
  caseUuid: string;
};

const CydCaseViewCaseGroupSelectionWithSelector = (
  props: CydCaseViewCaseGroupSectionProps
) => {
  const {
    title = 'Case Groups',
    emptyListMessage = 'This case is not part of any group',
    cases,
    isEditable,
    onAddCase,
    onRemoveCase,
    caseUuid
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <CydCaseDetailsPanelSection
      data-testid={props['data-testid']}
      title={`${title} (${cases.length})`}
      actions={
        <CydButton
          variant="text"
          startIcon="add"
          disabled={!isEditable}
          onClick={() => setIsEditing(true)}
        >
          Add
        </CydButton>
      }
    >
      {isEditing && (
        <>
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                const locatorString = formData.get('locator')?.toString()!;
                onAddCase(locatorString);
                if (!locatorString) {
                  setIsEditing(false);
                } else {
                  setIsEditing(false);
                }
              }}
            >
              <CydCaseSelector
                name="locator"
                label="Case"
                excludeList={[...cases.map((v) => v.uuid), caseUuid]}
              />
              <CydButtonStack direction={'row-reverse'}>
                <CydButton type="submit">Save</CydButton>
                <CydButton variant={'text'} onClick={() => setIsEditing(false)}>
                  Cancel
                </CydButton>
              </CydButtonStack>
            </form>
          </>
        </>
      )}

      <CydCaseGroupList
        cases={cases}
        emptyMessage={isEditing ? '' : emptyListMessage}
        isDeletable={isEditable}
        onDelete={(uuid) => onRemoveCase(uuid)}
      />
    </CydCaseDetailsPanelSection>
  );
};

const CydCaseViewCaseGroupSelectionWithTextField = (
  props: CydCaseViewCaseGroupSectionProps
) => {
  const {
    title = 'Case Groups',
    emptyListMessage = 'This case is not part of any group',
    cases,
    isEditable,
    onAddCase,
    onRemoveCase
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <CydCaseDetailsPanelSection
      data-testid={props['data-testid']}
      title={`${title} (${cases.length})`}
      actions={
        <CydButton
          variant="text"
          startIcon="add"
          disabled={!isEditable}
          onClick={() => setIsEditing(true)}
        >
          Add
        </CydButton>
      }
    >
      {isEditing && (
        <>
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                //@ts-ignore
                const locatorString = e.target.elements.locator.value;
                onAddCase(locatorString);
                setIsEditing(false);
              }}
            >
              <CydTextField name="locator" label="Case Locator" />
              <CydButtonStack direction={'row-reverse'}>
                <CydButton type="submit">Save</CydButton>
                <CydButton variant={'text'} onClick={() => setIsEditing(false)}>
                  Cancel
                </CydButton>
              </CydButtonStack>
            </form>
          </>
        </>
      )}

      <CydCaseGroupList
        cases={cases}
        emptyMessage={isEditing ? '' : emptyListMessage}
        isDeletable={isEditable}
        onDelete={(uuid) => onRemoveCase(uuid)}
      />
    </CydCaseDetailsPanelSection>
  );
};

export const CydCaseViewCaseGroupSection = (
  props: CydCaseViewCaseGroupSectionProps
) => {
  const { isToggleEnabled } = useFeatureToggles();

  const isUsingLazyLoadingSelector = isToggleEnabled(
    'REACT_APP_USE_LAZY_LOADING_CASE_SELECTOR'
  );

  if (isUsingLazyLoadingSelector) {
    return <CydCaseViewCaseGroupSelectionWithSelector {...props} />;
  }
  return <CydCaseViewCaseGroupSelectionWithTextField {...props} />;
};
