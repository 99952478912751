/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  CydApi_CasePlaybookInstance,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { Typography } from '@mui/material';
import { useCasePlaybooks } from 'hooks/CasePlaybooksHooks';
import { useCaseActions } from 'hooks/deprecated_CaseHooks';
import { CydCaseDetailsPanelSection } from 'components/_caseView/CydCaseDetailsPanel/CydCaseDetailsPanelSection';
import { CydarmRoute } from 'interface/CydarmRoute';
import { CydLink } from 'components/_formElements/CydLink/CydLink';
import { CydPlaybookInstanceDisplay } from 'components/_objectDisplay/CydPlaybookInstanceDisplay/CydPlaybookInstanceDisplay';

/** @jsxImportSource @emotion/react */

type CydCaseViewPlaybookProps = {
  caseUuid: string;
};

export const CydCaseViewPlaybookSection = ({
  caseUuid
}: CydCaseViewPlaybookProps) => {
  const { data: casePlaybooks }: { data: CydApi_CasePlaybookInstance[] } =
    useCasePlaybooks(caseUuid);
  const { data: caseActions }: { data: PlaybookActionInstance[] } =
    useCaseActions(caseUuid);

  return (
    <CydCaseDetailsPanelSection
      title="Playbooks"
      actions={
        <CydLink
          variant="text"
          to={`/caseview/${caseUuid}${CydarmRoute.PLAYBOOKS}`}
        >
          View
        </CydLink>
      }
    >
      {!casePlaybooks || casePlaybooks.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          Add playbooks on the playbooks tab
        </Typography>
      ) : (
        <>
          {casePlaybooks.map(
            ({ casePlaybookUuid, playbookName, action_statuses = [] }) => (
              <div
                data-testid="playbooksection-playbooks"
                key={casePlaybookUuid}
              >
                <CydPlaybookInstanceDisplay
                  title={playbookName}
                  actionStatuses={action_statuses}
                />
              </div>
            )
          )}
          {caseActions && caseActions.length > 0 && (
            <div
              data-testid="playbooksection-attachedactions"
              key={`caseActions`}
            >
              <CydPlaybookInstanceDisplay
                title={'Attached Action'}
                actionStatuses={caseActions}
              />
            </div>
          )}
        </>
      )}
    </CydCaseDetailsPanelSection>
  );
};
