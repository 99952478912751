/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { createAction } from '@reduxjs/toolkit';

export interface IMTTxRequest {
  label: string;
  period_start: string | null;
  period_end: string | null;
  time_increment: string;
  start_state?: string;
  end_state: string;
  include_end_state: boolean;
  include_start_state?: boolean;
  store_state_in_mttr?: boolean;
}

export const createFetchMttxMetrics =
  createAction<IMTTxRequest>('metrics/fetch');
