/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useState, memo, FC, useEffect, useLayoutEffect } from 'react';
import {
  useAddCommentRtkq,
  useAddStixRtkq,
  useCaseDataFormHandler,
  useFilteredDataSignificances,
  useUploadFile
} from 'hooks/CaseDataHooks';
import {
  useCommentDrawerState,
  useCommentModeState,
  useManipulatingCaseItemUuid
} from 'hooks/CaseActivitiesUIHooks';
import { Case } from 'interface/Case.interface';
import { CommentDialog } from 'components/CommentDialog';
import { useCurrentUser } from 'hooks/AuthHooks';
import { SYSTEM_PROPERTY } from '../../../constants';
import { useSystemPropValue } from 'hooks/SysPropsHooks';
import { TEXT_MIMETYPE, readCaseDataFile } from 'utils/CaseDataUtils';
import {
  CydAddCaseActivityForm,
  FormActivityModes
} from 'components/_caseView/CydAddCaseActivtyForm/CydAddCaseActivityForm';
import { useCurrentDataAcls } from 'hooks/AclHooks';
import { CydBasicPanel } from 'components/_panels/CydBasicPanel/CydBasicPanel';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { Base64Encode } from 'utils/StringUtils';

/** @jsxImportSource @emotion/react */
interface ICaseViewPageCaseDataDrawerProps {
  caseDetails: Case;
  formDataState: any[];

  onTabChange: (newTab: string) => void;
}

export const CaseViewPageCaseDataDrawer: FC<ICaseViewPageCaseDataDrawerProps> =
  memo(({ caseDetails, formDataState, onTabChange }) => {
    const [isOpened, setIsOpened] = useCommentDrawerState();

    const { data: dataSignificances } = useFilteredDataSignificances();
    const { data: acls } = useCurrentDataAcls();

    const manipulatingCommentUuid = useManipulatingCaseItemUuid();

    const [mode, setMode] = useCommentModeState();
    const [formData] = formDataState;
    const [commentDialogState, setCommentDialogState] = useState(false);
    const { data: currentUser } = useCurrentUser();

    const [isFullscreen, setIsFullScreen] = useState(false);

    const [margin, setMargin] = useState(0);

    useLayoutEffect(() => {
      const el = document.getElementById('scroll-container');
      if (!el) {
        console.warn('Element #scroll-container does not exist');
      } else {
        setMargin(el.clientHeight);
      }
    }, []);

    const softCharLimit = useSystemPropValue(
      SYSTEM_PROPERTY.COMMENT_CHAR_LIMIT,
      65536
    );

    const handleClose = () => {
      setIsOpened(false);
      setMode('comment');
      setIsFullScreen(false);
    };

    // Because the drawer state is managed externally, need to make sure we close it when this component unmounts
    useEffect(() => {
      return () => {
        handleClose();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [addComment] = useAddCommentRtkq();
    const [uploadFile] = useUploadFile();
    const [addStix] = useAddStixRtkq();

    const handleSubmit = async (payload: any) => {
      const { data, dataSignificance, ...rest } = payload;

      const dataToSubmit = {
        ...rest,
        significance: dataSignificance,
        ...(typeof data === 'string' ? { data } : data),
        type: payload.type || mode
      };

      // nb. The manipulating comment does not reset after submitting the reply
      // So make sure we check that comment mode is reply
      let parentId =
        payload.type === 'reply' ? manipulatingCommentUuid ?? null : null;

      if (dataToSubmit.mde?.length > softCharLimit) {
        setCommentDialogState(true);
      } else {
        if (dataToSubmit.files?.length > 0) {
          const fdata = await readCaseDataFile(dataToSubmit.files);

          if (payload.data.mde.length > 0) {
            addComment({
              caseUuid: caseDetails.uuid,
              parentUuid: parentId ?? undefined,
              data: {
                acl_uuid: dataToSubmit.acl.uuid,
                data: Base64Encode(payload.data.mde),
                significance: dataSignificance.name,
                mimeType: 'text/plain'
              }
            }).then((result) => {
              if ('error' in result) {
                throw new Error(
                  'An error occured while submitting the parent comment'
                );
              }

              fdata.stixData.forEach((v) => {
                addStix({
                  caseUuid: caseDetails.uuid,
                  parentUuid: result.data.uuid,
                  data: {
                    data: v.data,
                    fileLastMod: v.fileLastMod,
                    fileName: v.fileName,
                    mimeType: v.mimeType,
                    bundleAcl: dataToSubmit.acl,
                    significance: dataSignificance.name
                  }
                });
              });

              fdata.fileData.forEach((file) => {
                uploadFile({
                  caseUuid: caseDetails.uuid,
                  parentUuid: result.data.uuid,
                  data: {
                    acl_uuid: dataToSubmit.acl.uuid,
                    data: file.data,
                    fileLastMod: file.fileLastMod,
                    mimeType: file.mimeType,
                    significance: dataToSubmit.significance.name,
                    fileName: file.fileName
                  }
                });
              });
            });
          } else {
            fdata.stixData.forEach((v) => {
              addStix({
                caseUuid: caseDetails.uuid,
                parentUuid: parentId ?? undefined,
                data: {
                  data: v.data,
                  fileLastMod: v.fileLastMod,
                  fileName: v.fileName,
                  mimeType: v.mimeType,
                  bundleAcl: dataToSubmit.acl,
                  significance: dataSignificance.name
                }
              });
            });

            fdata.fileData.forEach((file) => {
              uploadFile({
                caseUuid: caseDetails.uuid,
                parentUuid: parentId ?? undefined,

                data: {
                  acl_uuid: dataToSubmit.acl.uuid,
                  data: file.data,
                  fileLastMod: file.fileLastMod,
                  mimeType: file.mimeType,
                  significance: dataToSubmit.significance.name,
                  fileName: file.fileName
                }
              });
            });
          }

          handleClose();
        } else {
          submitCaseData(dataToSubmit);
        }
      }
    };

    const submitCaseData = useCaseDataFormHandler(
      caseDetails.uuid,
      mode,
      manipulatingCommentUuid,
      () => {
        handleClose();
      }
    );

    const handleCommentDialogClose = () => {
      setCommentDialogState(false);
    };

    const handleFileUploadDialogClose = (fileName?: string) => {
      const currentTime = new Date().toUTCString();
      const textFile = new File(
        [formData.mde],
        fileName
          ? `${fileName}.txt`
          : `${caseDetails.locator}_${currentUser?.username}_${currentTime}.txt`,
        {
          type: TEXT_MIMETYPE,
          lastModified: Date.now()
        }
      );
      formData.files = [textFile];
      formData.mde = '';
      submitCaseData(formData);
    };

    return (
      <>
        {isOpened && (
          <CydBasicPanel
            showBorder
            id="add-activity-item-panel"
            data-testid="add-activity-item-panel"
            css={(theme) => `
                 overflow: auto; 
                 margin:0;
                 display: flex; 
                 flex-flow: column nowrap; 
                 justify-content: stretch; 
                 ${
                   isFullscreen
                     ? `
                  position: fixed; 
                  inset: ${theme.spacing(4)};
                  top: 30px;
                  z-index:200;
                 `
                     : `                 
                 max-height: 550px; 
                 position: fixed; 
                 bottom:10px;
                 left: 10px; 
                 right: 345px;
                 margin-top: ${margin - 550}px;
                 z-index: 3;
              `
                 }
            `}
          >
            <CydIconButton
              css={(theme) => `
                z-index: 10;
                position: absolute; 
                right: ${theme.spacing(1)}; 
                top:${theme.spacing(1)};
              `}
              icon={isFullscreen ? 'closeFull' : 'openInFull'}
              onClick={() => {
                setIsFullScreen(!isFullscreen);
              }}
              label="fullscreen"
            />

            <CydAddCaseActivityForm
              /** 👉 ⚠️ Be careful here
               *  There's an initial render where isOpened is true, and no form data exists
               *  This causes the activity adder to think that there's no data, when editing a comment.
               *
               *  To solve, we remount for every instance of isOpened-mde-mode
               *
               *  The data doesn't update until you've pressed saved, so should be ok.
               */
              key={`${isOpened}-${formData?.mde}-${formData.mode}`}
              availableAcls={acls}
              caseUuid={caseDetails.uuid}
              availableSignificances={dataSignificances}
              commentMode={mode as FormActivityModes}
              initialFormData={
                formData
                  ? ({
                      type: formData.type,
                      data: {
                        mde: formData.mde
                      }
                    } as any)
                  : undefined
              }
              initialSelectedAcl={formData?.acl}
              initialSelectedSignificance={formData?.significance}
              onSave={handleSubmit}
              onCancel={handleClose}
              onTabChange={onTabChange}
              isFullscreen={isFullscreen}
            />
            <CommentDialog
              isOpened={commentDialogState}
              onClose={handleCommentDialogClose}
              onFileUpload={handleFileUploadDialogClose}
              onSubmit={() => submitCaseData(formData)}
              charLimit={softCharLimit}
            />
          </CydBasicPanel>
        )}
      </>
    );
  });
