import React from 'react';
import { NodeDetailsPanel } from '../NodeDetailsPanel/NodeDetailsPanel';
import { CydarmGenericFlowchart } from '../CydarmGenericFlowChart';
import {
  GenericNodeUpdatePayload,
  GenericPlaybookStatusUpdate
} from 'components/_playbooks/CacaoPlaybookRunningInstance';
import {
  GenericNode,
  GenericPlaybook,
  GenericPlaybookResolutionInfo
} from '../type';
import { User } from 'interface/User.interface';
import { CaseTag } from 'interface/Tags.interface';
export type FlowStateViewerProps = {
  onUpdateNode: (data: GenericNodeUpdatePayload, position: number) => void;
  onRemoveAction?: (data: GenericNode) => void;

  playbook: GenericPlaybook;
  playbookResolutionInfo: GenericPlaybookResolutionInfo;

  availableUsers: Array<User>;
  // If null - don't allow status selection
  /**
   * @deprecated no longer used
   */
  availableStepStates?: null | Array<
    GenericPlaybookResolutionInfo['nodeStatuses'][string]['status']
  >;
  availableTags: Array<CaseTag>;

  selectedNode: GenericNode | null;
  onChangeSelectedNode: (node: GenericNode | null) => void;
  onPlaybookStatusUpdate: (data: GenericPlaybookStatusUpdate) => void;
};

/**
 * FlowStateViewer allows the user to see how the Cacao playbook has executed.
 * ie. Gives information about what time a step was completed, details about it etc.
 * @param props
 * @returns
 */
export const FlowStateViewer = (props: FlowStateViewerProps) => {
  const {
    playbook,
    playbookResolutionInfo,
    selectedNode,
    onChangeSelectedNode,
    onRemoveAction,
    onUpdateNode,
    availableUsers,
    availableTags,
    onPlaybookStatusUpdate
  } = props;

  return (
    <>
      <CydarmGenericFlowchart
        onPaneClick={() => onChangeSelectedNode(null)}
        playbook={playbook}
        playbookResolutionInfo={playbookResolutionInfo}
        nodesDraggable={false}
        nodesConnectable={false}
        selectedNodeId={selectedNode?.nodeId || null}
        elementsSelectable={true}
        onNodeClick={(event, element) => {
          onChangeSelectedNode(element.data);
        }}
      />

      <NodeDetailsPanel
        onRemoveStandaloneAction={
          onRemoveAction &&
          ((node) => {
            onRemoveAction(node);
            onChangeSelectedNode(null);
          })
        }
        playbook={playbook}
        playbookResolutionInfo={playbookResolutionInfo}
        onCloseClick={() => onChangeSelectedNode(null)}
        node={selectedNode}
        onPlaybookStatusUpdate={onPlaybookStatusUpdate}
        onNodeStatusUpdate={(data) =>
          onUpdateNode(data, selectedNode?.oldData.position as number)
        }
        availableTags={availableTags}
        availableUsers={availableUsers}
      />
    </>
  );
};
