/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { CaseActivityPreviewImage } from 'components/CaseActivity/CaseActivityPreviewImage';
import { inlineImageWhitelist, shouldShowPreview } from 'utils/CaseDataUtils';

export const CaseActivityContentPreviewImage = ({ caseActivity }) => {
  // can be displayed as an inline image
  if (
    !inlineImageWhitelist.has(caseActivity.mimetype) ||
    !shouldShowPreview(caseActivity)
  ) {
    return null;
  }
  return <CaseActivityPreviewImage caseDataUuid={caseActivity.uuid} />;
};
