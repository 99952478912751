/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import { SYSTEM_PROPERTY } from '../../constants';

export const allSystemPropertiesSelector = ({
  sysprops: { properties }
}: IStore) =>
  properties.map((property) => ({
    ...property,
    value: property.value
  }));

export const systemPropValueSelector = createSelector(
  allSystemPropertiesSelector,
  (_, propName) => propName,
  (_, __, defaultVal) => defaultVal,
  (properties, propName, defaultValue) => {
    const { value } = properties.find(({ name }) => name === propName) || {
      value: defaultValue
    };
    return value;
  }
);

export const caseAssigneeGroupsSelector = createSelector(
  (state) =>
    systemPropValueSelector(state, SYSTEM_PROPERTY.CASE_ASSIGNEE_GROUPS, ''),
  (propString) => propString && propString.split(',').map((val) => val.trim())
);

export const actionAssigneeGroupsSelector = createSelector(
  (state) =>
    systemPropValueSelector(state, SYSTEM_PROPERTY.ACTION_ASSIGNEE_GROUPS, ''),
  (propString) => propString && propString.split(',').map((val) => val.trim())
);

export const caseCreationFieldsSelector = createSelector(
  (state) =>
    systemPropValueSelector(state, SYSTEM_PROPERTY.CASE_CREATION_FIELDS, ''),
  (propString) => {
    return propString
      .replace(/\s/g, '')
      .toLowerCase()
      .split(',')
      .reduce(
        (acc, field) => {
          let [key, value] = field.split(':');
          if (!key || !value) {
            return acc;
          }
          return {
            ...acc,
            [key]: value
          };
        },
        { description: 'mandatory' }
      );
  }
);

export const passwordComplexitySelector = createSelector(
  (state) =>
    systemPropValueSelector(
      state,
      SYSTEM_PROPERTY.PASSWORD_COMPLEXITY,
      'LENGTH=12,UPPER=YES,LOWER=YES,DIGIT=YES,SPECIAL=NO'
    ),
  (propString) => {
    const passwordRules = propString
      .split(',')
      .map((val) => val.trim())
      .reduce((acc, curr) => {
        let [key, val] = curr.split('=');
        if (!key || !val) {
          return acc;
        }
        return { ...acc, [key]: val };
      }, {});
    return { ...passwordRules, LENGTH: +passwordRules.LENGTH || 12 };
  }
);

export const sysPropsHasLoadedSelector = ({
  sysprops: { hasLoaded }
}: IStore) => hasLoaded;

export const sysPropsIsLoadingSelector = ({
  sysprops: { isLoading }
}: IStore) => isLoading;

export const sysPropsStatusSelector = ({
  orgs: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;
