/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import React, { useState, useCallback } from 'react';
import { CydSimpleFormModal } from '../CydSimpleFormModal/CydSimpleFormModal';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type CydConfirmModalProps = {
  onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  message: string;
  confirmText?: string;
  renderTrigger: (
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => React.ReactElement;

  noConfirmationRequired?: boolean;
};

/**
 * A simple modal that shows an continue/cancel modal.
 * @param props
 * @returns
 */
export const CydConfirmModal = (props: CydConfirmModalProps) => {
  const {
    onConfirm,
    title,
    message,
    renderTrigger,
    confirmText = 'Continue',
    noConfirmationRequired
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = useCallback(
    (e) => {
      if (noConfirmationRequired) {
        onConfirm(e);
      } else {
        setIsOpen(true);
      }
    },
    [setIsOpen, noConfirmationRequired, onConfirm]
  );

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleConfirm = useCallback(
    (e) => {
      onConfirm(e);
      setIsOpen(false);
    },
    [onConfirm, setIsOpen]
  );

  return (
    <>
      {renderTrigger(handleOpen)}
      <CydSimpleFormModal
        title={title}
        onClose={handleCancel}
        isOpen={isOpen}
        css={css`
          display: flex;
          flex-flow: column nowrap;
        `}
      >
        <>
          <div
            css={css`
              flex: 1 1 auto;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
            `}
          >
            <Typography variant="body1">{message}</Typography>
          </div>
          <CydButtonStack justifyContent={'flex-end'}>
            <CydButton variant="text" onClick={handleCancel}>
              Cancel
            </CydButton>
            <CydButton variant="contained" onClick={handleConfirm}>
              {confirmText}
            </CydButton>
          </CydButtonStack>
        </>
      </CydSimpleFormModal>
    </>
  );
};
