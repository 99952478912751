/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { useAtcPlaybookActions } from 'hooks/PlaybookActionsHook';
import { PlaybookActionsTable } from 'components/tables/PlaybookActionsTable';
import { DefaultPanel } from 'components/DefaultPanel';
import { PlaybookAction } from 'interface/Playbook.interface';
import {
  createPlaybookAction,
  createDeletePlaybookAction
} from 'states/playbookActions/actions';
import { useDispatch } from 'react-redux';
import { CydarmPage } from 'interface/CydarmRoute';
import { PageContent, PageToolbar } from 'components/Page';
import DefaultLayoutSidebar from 'components/layouts/DefaultLayout/DefaultLayoutSidebar';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydActionAdderModal } from 'components/_playbooks/CydActionAdderModal/CydActionAdderModal';
import { Acl } from 'interface/Acl.interface';
import { useCurrentDataAcls, useDefaultAclForOrg } from 'hooks/AclHooks';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
/** @jsxImportSource @emotion/react */

export const PlaybooksPageActions: React.FC = () => {
  const { data: playbookActions } = useAtcPlaybookActions();
  const dispatch = useDispatch();
  const [shouldShowAddActionForm, setShouldShowAddActionForm] =
    React.useState(false);

  const availableAclsResult = useCurrentDataAcls();
  const defaultAclResult = useDefaultAclForOrg();

  const handleRequestDelete = (playbook: PlaybookAction) => {
    !!playbook.uuid && dispatch(createDeletePlaybookAction(playbook.uuid));
  };

  const handleAddAction = (data: PlaybookAction) => {
    if (!data) {
      throw new Error('No data was found');
    }
    dispatch(
      createPlaybookAction({
        playbookAction: { atc: data }
      })
    );
    setShouldShowAddActionForm(false);
  };

  const handleCreateAndAddAction = (data: {
    title: string;
    description: string;
    acl: Acl;
  }) => {
    if (!data) {
      throw new Error('No data was found');
    }
    dispatch(
      createPlaybookAction({
        playbookAction: {
          atc: {
            position: -1,
            name: data.title,
            description: data.description,
            acl: data.acl.description
          }
        }
      })
    );
    setShouldShowAddActionForm(false);
  };

  if (defaultAclResult.isLoading || availableAclsResult.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <DefaultLayoutSidebar page={CydarmPage.PLAYBOOKS_ACTIONS} sidebar={<div />}>
      <PageContent>
        <PageToolbar>
          <CydButton
            data-testid="btn-add-action"
            variant="contained"
            onClick={() => setShouldShowAddActionForm(true)}
            startIcon="add"
          >
            Add action
          </CydButton>
        </PageToolbar>
        <DefaultPanel>
          <PlaybookActionsTable
            onDeleteAction={handleRequestDelete}
            playbookActions={playbookActions}
          />
        </DefaultPanel>
      </PageContent>

      {shouldShowAddActionForm && (
        <CydActionAdderModal
          createOnly
          availableAcls={availableAclsResult.data}
          defaultAcl={defaultAclResult.data}
          availableActions={
            playbookActions.map((v) => v.atc) as Array<PlaybookAction>
          }
          onAddNewAction={handleAddAction}
          onCreateAction={handleCreateAndAddAction}
          isOpen={shouldShowAddActionForm}
          onClose={() => setShouldShowAddActionForm(false)}
          buttonTextCreateAndAdd="Create Action"
        />
      )}
    </DefaultLayoutSidebar>
  );
};
