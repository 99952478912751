/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IDetailedCaseData } from 'interface/CaseData.interface';
import { isValidJSON, defaultSTIX2_1 } from './Stix2_1ParserUtils';
import { defaultSTIX2_0 } from './Stix2_0ParserUtils';
import moment from 'moment';
import { CaseTag } from 'interface/Tags.interface';
import {
  CydApi_CasePlaybookInstance,
  IPlaybookActionType,
  PlaybookAction,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { Base64Decode, compareDateStrings } from './StringUtils';
import { IMeta } from 'interface/Meta.interface';
import { CaseActivityFilterSetting } from 'components/_caseView/CydCaseViewActivityFilter/CydCaseViewActivityFilter';
import { ActivityItem } from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';
import { clone } from './ObjectUtils';
import { PREVIEW_MAX_SIZE_BYTES } from '../constants';

export const TEXT_MIMETYPE = 'text/plain';
export const JSON_MIMETYPE = 'application/json';
export const YAML_MIMETYPE = 'application/yaml';
export const XML_MIMETYPE = 'application/xml';
export const XML_MIMETYPE2 = 'text/xml';
export const PYTHON_MIMETYPE = 'text/x-python-script';
export const SHELL_MIMETYPE = 'text/x-sh';
export const JAVASCRIPT_MIMETYPE = 'application/javascript';
export const JAVASCRIPT_TEXT_MIMETYPE = 'text/javascript';
export const SQL_MIMETYPE = 'application/sql';
export const MARKDOWN_TEXT_MIME_TYPE = 'text/markdown';
export const STIX_MIMETYPE2_1 = 'application/stix+json;version=2.1';
export const STIX_MIMETYPE2_0 = 'application/stix+json;version=2.0';
export const CYDARM_FORM_MIMETYPE = 'application/vnd.cydarm.form+json';
export const HTML_MIMETYPE = 'text/html';
export const POWERSHELL_EXTENSION = '.ps1';
export const SQL_EXTENSION = '.sql';
export const YAML_EXTENSION = '.yaml';
export const inlineImageWhitelist = new Set([
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/bmp',
  'image/vnd.microsoft.icon'
]);
export const displayableMimeTypes = new Set([
  TEXT_MIMETYPE,
  MARKDOWN_TEXT_MIME_TYPE,
  STIX_MIMETYPE2_0,
  STIX_MIMETYPE2_1,
  CYDARM_FORM_MIMETYPE,
  HTML_MIMETYPE,
  JSON_MIMETYPE,
  YAML_MIMETYPE,
  XML_MIMETYPE,
  XML_MIMETYPE2,
  PYTHON_MIMETYPE,
  SHELL_MIMETYPE,
  JAVASCRIPT_MIMETYPE,
  JAVASCRIPT_TEXT_MIMETYPE,
  SQL_MIMETYPE,
  ...Array.from(inlineImageWhitelist)
]);

export const displayableSyntaxHighlighting = new Set([
  HTML_MIMETYPE,
  JSON_MIMETYPE,
  YAML_MIMETYPE,
  XML_MIMETYPE,
  XML_MIMETYPE2,
  PYTHON_MIMETYPE,
  SHELL_MIMETYPE,
  JAVASCRIPT_MIMETYPE,
  JAVASCRIPT_TEXT_MIMETYPE,
  SQL_MIMETYPE
]);

export const displayableSyntaxHighlightingExtension = new Set([
  POWERSHELL_EXTENSION,
  SQL_EXTENSION,
  YAML_EXTENSION
]);

export const syntaxHighlightingMap = {
  [JSON_MIMETYPE]: 'json',
  [YAML_MIMETYPE]: 'yaml',
  [XML_MIMETYPE]: 'xml',
  [XML_MIMETYPE2]: 'xml',
  [HTML_MIMETYPE]: 'vbscriptHtml',
  [PYTHON_MIMETYPE]: 'python',
  [SHELL_MIMETYPE]: 'shell',
  [JAVASCRIPT_MIMETYPE]: 'javascript',
  [JAVASCRIPT_TEXT_MIMETYPE]: 'javascript',
  [SQL_MIMETYPE]: 'sql',
  [SQL_EXTENSION]: 'sql',
  [YAML_EXTENSION]: 'yaml',
  [POWERSHELL_EXTENSION]: 'powershell'
} as const;

export const COMMENTS_ON_PLAYBOOK_ACTION = 'Comments on action';

// check if case data can be displayed in the case view
export const isDisplayableMimeType = (mimeType: string): boolean => {
  if (displayableMimeTypes.has(mimeType)) {
    return true;
  }
  return false;
};

// check if case data can be displayed inline in the case view with syntax highlighting
export const isDisplayableWithSyntaxHighlight = (
  mimeType: string,
  filename: string
): boolean => {
  if (displayableSyntaxHighlighting.has(mimeType)) {
    return true;
  }
  const extension: string | undefined = '.' + filename.split('.').pop();
  if (extension && displayableSyntaxHighlightingExtension.has(extension)) {
    return true;
  }
  return false;
};

export const observableTypes = [
  { label: 'IPV4 Address', value: 'ipv4-addr' },
  { label: 'Domain Name', value: 'domain-name' },
  { label: 'URL', value: 'url' },
  { label: 'Email Address', value: 'email-addr' },
  { label: 'File', value: 'file' },
  { label: 'Artifact', value: 'artifact' }
];

export const confidenceRating = [
  { label: '1 - Confirmed by other sources', value: 90 },
  { label: '2 - Probably True', value: 70 },
  { label: '3 - Possibly True', value: 50 },
  { label: '4 - Doubtful', value: 30 },
  { label: '5 - Improbable', value: 10 },
  { label: '6 - Truth cannot be judged', value: 0 }
];

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

//TODO WRITE TESTS FOR THESE

const filterCaseDataFnCreator = (filterData: CaseActivityFilterSetting) => {
  if (!filterData || Object.keys(filterData).length <= 0) {
    return;
  }

  return ({
    created,
    creatoruuid,
    content,
    significance
  }: IDetailedCaseData) => {
    let shouldInclude: boolean = true;

    if (Array.isArray(filterData.postedBy) && filterData.postedBy.length > 0) {
      shouldInclude =
        !!creatoruuid &&
        shouldInclude &&
        !!filterData.postedBy?.find((el) => el.uuid === creatoruuid);
    }

    if (filterData.fromDate) {
      shouldInclude =
        shouldInclude &&
        moment(filterData.fromDate).isSameOrBefore(moment(created), 'days');
    }

    if (filterData.toDate) {
      shouldInclude =
        shouldInclude &&
        moment(filterData.toDate).isSameOrAfter(moment(created), 'days');
    }

    if (filterData.commentSearch) {
      shouldInclude =
        shouldInclude &&
        content !== undefined &&
        content.includes(filterData.commentSearch);
    }

    if (
      Array.isArray(filterData.significanceLevel) &&
      filterData.significanceLevel.length > 0
    ) {
      shouldInclude =
        shouldInclude &&
        !!filterData.significanceLevel.find(
          (signficanceLevel) => signficanceLevel.name === significance
        );
    }

    return shouldInclude;
  };
};

export const filterCaseData = (
  caseData: IDetailedCaseData[],
  filterData: CaseActivityFilterSetting
) => {
  const filterFunction = filterCaseDataFnCreator(filterData);

  if (!filterFunction) {
    return caseData;
  }

  return caseData.filter(filterFunction);
};

// make a label string for collating action comments
function getActionCommentLabel(
  actionInstanceUuid: string,
  caseActions: Array<PlaybookActionInstance>
) {
  // use for loop to break out of loop early
  for (const caseAction of caseActions) {
    if (caseAction.actionInstanceUuid === actionInstanceUuid) {
      return `${COMMENTS_ON_PLAYBOOK_ACTION} "${caseAction.actionName}"`;
    }
  }
  return COMMENTS_ON_PLAYBOOK_ACTION;
}

// make a label string for collating playbook action comments
function getPlaybookActionCommentLabel(
  actionUuid: string,
  casePlaybooks: Array<CydApi_CasePlaybookInstance>
) {
  // use for loop to break out of loop early
  for (const casePlaybook of casePlaybooks) {
    for (const actionStatus of casePlaybook.action_statuses) {
      if (actionStatus.actionInstanceUuid === actionUuid) {
        return `${COMMENTS_ON_PLAYBOOK_ACTION} "${actionStatus.actionName}" of playbook "${casePlaybook.playbookName}"`;
      }
    }
  }
  return COMMENTS_ON_PLAYBOOK_ACTION;
}
// Be careful in this function. There are object mutations that did cause bugs.
// But like, constantly creating new objects _is_ less performant.
export const mapViewableData2 = (
  data:
    | {
        case_actions_data: Array<ActivityItem>;
        case_data: Array<ActivityItem>;
        playbook_actions_data: Array<ActivityItem>;
      }
    | undefined,
  filterSettings: CaseActivityFilterSetting,
  casePlaybooks: CydApi_CasePlaybookInstance[],
  caseActions: PlaybookActionInstance[]
): Array<ActivityItem> | null => {
  if (!data) {
    return null;
  }

  const filteredCaseData = filterCaseData(
    clone(data.case_data) as any as IDetailedCaseData[],
    clone(filterSettings)
  ) as any as Array<ActivityItem>;

  // nb. We are using mutative logic here
  // This seems like the easiest way to add the children to the tree

  // Put _ALL_ of the data into the lookup.
  const caseDataLookup = new Map<string, ActivityItem>();
  clone(data.case_data).forEach((v) => {
    caseDataLookup.set(v.uuid, v);
  });

  // Keep track of the items we have already seen
  const alreadySeen = new Set<ActivityItem>();

  // The top items will be populated in here, and these are what are ultimately returned
  const topItems = new Set<ActivityItem>();

  function findTopParent(item: ActivityItem): ActivityItem | null {
    const actualItem = caseDataLookup.get(item.uuid);
    if (!actualItem) {
      throw new Error('Item not found in lookup');
    }

    // If we've already seen the item, then we've already built the parent and can exit early
    // This also prevents us from adding the same item multiple times
    if (alreadySeen.has(actualItem)) {
      return null;
    }

    alreadySeen.add(actualItem);

    if (actualItem.parentuuid) {
      let parent = caseDataLookup.get(actualItem.parentuuid);
      if (!parent) {
        // It's an orphan (eg. parent can't be seen because of ACLs), add a tombstone
        parent = {
          uuid: actualItem.parentuuid,
          created: actualItem.created,
          edited: false,
          lastmodified: '',
          caseuuid: actualItem.caseuuid,
          creatoruuid: '',
          creatorusername: actualItem.creatorusername,
          acl: actualItem.acl,
          mimetype: 'tombstone',
          creatorUuid: '',
          content: '(This content is not available)',
          bytedata: '',
          parentCaseUuid: actualItem.parentCaseUuid,
          significance: actualItem.significance,
          sigprecedence: actualItem.sigprecedence,
          dataStubUuid: actualItem.dataStubUuid,
          parentuuid: null,
          audit: true,
          deletable: false,
          editable: false
        };

        // Gotta add it into the lookup because it may have many children
        caseDataLookup.set(actualItem.parentuuid, parent);
      }

      if (!parent.children) {
        parent.children = [actualItem];
      } else {
        parent.children.push(actualItem);
      }

      return findTopParent(parent);
    } else {
      return actualItem;
    }
  }

  filteredCaseData.forEach((v) => {
    const topParentWithChildren = findTopParent(v);
    if (topParentWithChildren) {
      topItems.add(topParentWithChildren);
    }
  });
  const actionsMap = {} as Record<string, Array<ActivityItem>>;

  const filteredPlaybookComments = filterCaseData(
    clone(data.playbook_actions_data) as any as IDetailedCaseData[],
    filterSettings
  ) as any as Array<ActivityItem>;

  const filteredStandaloneComments = filterCaseData(
    clone(data.case_actions_data) as any as IDetailedCaseData[],
    filterSettings
  ) as any as Array<ActivityItem>;

  [...filteredPlaybookComments, ...filteredStandaloneComments].forEach((v) => {
    if (!v.action_instance_uuid) {
      throw new Error(
        'action_instance_uuid did not exist on a playbook action'
      );
    }

    // find the v.action_instance_uuid in the actions
    let actionCommentLabel = getActionCommentLabel(
      v.action_instance_uuid,
      caseActions
    );
    // if it's not set, look through the playbook actions
    if (actionCommentLabel === COMMENTS_ON_PLAYBOOK_ACTION) {
      actionCommentLabel = getPlaybookActionCommentLabel(
        v.action_instance_uuid,
        casePlaybooks
      );
    }
    const existingComments = actionsMap[v.action_instance_uuid];
    if (!existingComments) {
      actionsMap[v.action_instance_uuid] = [
        {
          uuid: v.action_instance_uuid,
          created: v.created,
          lastmodified: v.lastmodified,
          caseuuid: v.caseuuid,
          creatorUuid: v.creatorUuid,
          creatorusername: v.creatorusername,
          acl: v.acl,
          content: actionCommentLabel,
          significance: v.significance,
          sigprecedence: v.sigprecedence,
          audit: true,
          mimetype: 'text/plain',
          action_instance_uuid: v.action_instance_uuid
        } as any as ActivityItem,
        v
      ];
    } else {
      existingComments.push(v);
    }
  });

  const existingArray = new Array(...topItems.values());

  Object.values(actionsMap).forEach((v) =>
    v.sort((a, b) => {
      if (a.content.startsWith(COMMENTS_ON_PLAYBOOK_ACTION)) {
        return -1;
      }
      if (b.content.startsWith(COMMENTS_ON_PLAYBOOK_ACTION)) {
        return 1;
      }
      return compareDateStrings(a.created, b.created);
    })
  );
  Object.values(actionsMap).forEach((v) => {
    const [parent, ...rest] = v;

    parent.children = rest;

    existingArray.push(parent);
  });

  return existingArray.sort((a, b) => compareDateStrings(a.created, b.created));
};

export const convertToMarkdown = (dataIn, mimetype) => {
  try {
    if (isValidJSON(dataIn)) {
      var markdown =
        mimetype === STIX_MIMETYPE2_0
          ? defaultSTIX2_0(dataIn)
          : defaultSTIX2_1(dataIn);
      return markdown;
    }
    return 'Invalid JSON';
  } catch (ex) {
    //bug with markdown processor, loses first line
    return '```\n' + dataIn + '```';
  }
};

export const getMimetypeFromSTIX = (payload) => {
  const data = Base64Decode(payload);
  if (isValidJSON(data)) {
    const json = JSON.parse(data);
    var getVersion = String();
    Object.keys(json).forEach(function (key) {
      if (key === 'spec_version') {
        getVersion = json[key];
      }
    });
    const mimeType = getVersion === '2.1' ? STIX_MIMETYPE2_1 : STIX_MIMETYPE2_0;
    return mimeType;
  }
  return;
};

type OurFileData = {
  data: string;
  fileLastMod: number;
  fileName: string;
  mimeType: string;
};

export const readCaseDataFile = async (
  files: FileList | File[],
  fileName?: string
): Promise<{
  stixData: Array<OurFileData>;
  fileData: Array<OurFileData>;
}> => {
  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (!fileReader.result || typeof fileReader.result !== 'string') {
          reject({ message: 'Cannot process file' });
          return;
        }

        const commaPos = fileReader.result.indexOf(',');
        const payload = fileReader.result.substr(commaPos + 1);
        const STIXVersion = getMimetypeFromSTIX(payload);
        const resolveData = file.name.endsWith('.stix.json')
          ? {
              data: payload,
              mimeType: STIXVersion,
              fileName: fileName || file.name,
              fileLastMod: Math.round(file.lastModified / 1000)
            }
          : {
              data: payload,
              mimeType: file.type,
              fileName: fileName || file.name,
              fileLastMod: Math.round(file.lastModified / 1000)
            };
        resolve(resolveData);
      };
      fileReader.readAsDataURL(file);
    });
  let stixFiles: any = [],
    otherFiles: any = [];
  let processedFiles = await Promise.all(
    Array.from(files).map((file) => readFile(file))
  );
  processedFiles.forEach((fileData: any) => {
    if (
      fileData.mimeType === STIX_MIMETYPE2_1 ||
      fileData.mimeType === STIX_MIMETYPE2_0
    ) {
      stixFiles.push(fileData);
    } else {
      otherFiles.push(fileData);
    }
    Array.from(files).forEach((file) => readFile(file));
  });

  return {
    stixData: stixFiles,
    fileData: otherFiles
  };
};

export const shallowCompareCaseData = (
  caseDataA: IDetailedCaseData,
  caseDataB: IDetailedCaseData
) =>
  caseDataA.uuid === caseDataB.uuid &&
  caseDataA.audit === caseDataB.audit &&
  caseDataA.content === caseDataB.content &&
  caseDataA.created === caseDataB.created &&
  caseDataA.previewImage === caseDataB.previewImage &&
  caseDataA.significance === caseDataB.significance &&
  JSON.stringify(caseDataA.creator) === JSON.stringify(caseDataB.creator) &&
  JSON.stringify(caseDataA.children) === JSON.stringify(caseDataB.children);

export const scrollToElement = (
  element,
  container,
  scrollOnlyOnProximity = false,
  buffer = 300
) => {
  let scrollTo = element.offsetTop - buffer;

  if (scrollOnlyOnProximity) {
    let absBufferRange = Math.abs(buffer * 2);
    let currentScroll = container.scrollTop + container.offsetHeight;
    let range = [
      currentScroll - absBufferRange,
      currentScroll + absBufferRange
    ];
    if (scrollTo <= range[0] || scrollTo >= range[1]) {
      return;
    }
  }

  container.scroll({
    top: scrollTo,
    behavior: 'smooth'
  });
};

export const sortTagsFnCreator =
  ({ orderBy, order }: { orderBy: keyof CaseTag; order: string }) =>
  (groupA: CaseTag, groupB: CaseTag) =>
    order === SortOrder.ASC
      ? (groupA[orderBy] as string).localeCompare(groupB[orderBy] as string)
      : (groupB[orderBy] as string).localeCompare(groupA[orderBy] as string);

export const sortTags = (
  groups: CaseTag[],
  orderBy: keyof CaseTag,
  order: string
) => {
  const sortFunction = sortTagsFnCreator({ orderBy, order });

  if (!sortFunction) {
    return groups;
  }

  return [...groups].sort(sortFunction);
};

export const sortActionFnCreator =
  ({ orderBy, order }: { orderBy: keyof PlaybookAction; order: string }) =>
  (groupA: PlaybookAction, groupB: PlaybookAction) =>
    order === SortOrder.ASC
      ? (groupA[orderBy] as string).localeCompare(groupB[orderBy] as string)
      : (groupB[orderBy] as string).localeCompare(groupA[orderBy] as string);

export const sortActions = (
  groups: IPlaybookActionType[],
  orderBy: keyof PlaybookAction,
  order: string
) => {
  const sortFunction = sortActionFnCreator({ orderBy, order });

  if (!sortFunction) {
    return groups;
  }

  return [...groups]
    .map((el) => el.atc)
    .filter((el) => Boolean(el))
    .sort(sortFunction);
};

export const sortMetaFnCreator =
  ({ orderBy, order }: { orderBy: keyof IMeta; order: string }) =>
  (groupA: IMeta, groupB: IMeta) =>
    order === SortOrder.ASC
      ? (groupA[orderBy] as string).localeCompare(groupB[orderBy] as string)
      : (groupB[orderBy] as string).localeCompare(groupA[orderBy] as string);
export const sortMetas = (
  groups: IMeta[],
  orderBy: keyof IMeta,
  order: string
) => {
  const sortFunction = sortMetaFnCreator({ orderBy, order });

  if (!sortFunction) {
    return groups;
  }

  return [...groups].sort(sortFunction);
};

export const convertStringtoMarkdownTable = (data: string) => {
  function columnWidth(rows, columnIndex) {
    return Math.max.apply(
      null,
      rows.map(function (row) {
        return row[columnIndex] ? row[columnIndex].length : 0;
      })
    );
  }

  var rows = data.split(/[\n\u0085\u2028\u2029]|\r\n?/g).map(function (row) {
    return row.split('\t');
  });
  var columnWidths = rows[0].map(function (column, columnIndex) {
    return columnWidth(rows, columnIndex);
  });
  var markdownRows = rows.map(function (row, rowIndex) {
    return (
      '| ' +
      row
        .map(function (column, index) {
          const columnLength = column ? column.length : 0;
          const columnW =
            columnWidths[index] && Array(columnWidths[index] - columnLength + 1)
              ? Array(columnWidths[index] - columnLength + 1)
              : [''];
          return column + columnW.join(' ');
        })
        .join(' | ') +
      ' |'
    );
  });
  markdownRows.splice(
    1,
    0,
    '|' +
      columnWidths
        .map(function (width, index) {
          return Array(columnWidths[index] + 3).join('-');
        })
        .join('|') +
      '|'
  );
  const itemString = markdownRows.join('\n');
  return itemString;
};

export function shouldShowPreview(dataStub: {
  mimetype: string;
  datasize: number;
  filename: string;
}) {
  // atttachment is to large, don't show preview
  if (dataStub.datasize > PREVIEW_MAX_SIZE_BYTES) {
    return false;
  }

  // if mime type is in the list of displayable mime types, show preview
  if (displayableMimeTypes.has(dataStub.mimetype)) {
    return true;
  }

  // if file extension is in the list of displayable types show preview
  const fileExtension = '.' + dataStub.filename?.split('.').pop();
  if (displayableSyntaxHighlightingExtension.has(fileExtension)) {
    return true;
  }

  return false;
}
