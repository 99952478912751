/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useEffect, useState } from 'react';

export const useRightPanelHook = () => {
  const [ready, setIsReady] = useState(false);
  useEffect(() => {
    const rightInfoPanel = document.getElementById(
      'right-info-panel'
    ) as HTMLDivElement;
    if (rightInfoPanel?.children?.length > 0) {
      const children = Array.from(rightInfoPanel.children);
      if (children[0].children.length > 0) {
        rightInfoPanel.style.display = 'block';
      } else {
        rightInfoPanel.style.display = 'none';
      }
    }
    setIsReady(true);
    return () => {
      if (rightInfoPanel?.style?.display) {
        rightInfoPanel.style.display = 'none';
      }
    };
  }, [ready]);
  return ready;
};
