/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { ReactNode, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

type CydCaseDetailsPanelSectionProps = {
  title: ReactNode;
  actions?: ReactNode;
  'data-testid'?: string;
};

export const CydCaseDetailsPanelSection = (
  props: PropsWithChildren<CydCaseDetailsPanelSectionProps>
) => {
  const { title, children, actions } = props;
  return (
    <div
      data-testid={props['data-testid']}
      css={(theme) => css`
        margin-bottom: ${theme.spacing(4)};
      `}
    >
      <div
        css={(theme) => `
          display: flex;
          align-items: center;
          margin-bottom: ${theme.spacing(1)}; 



          // I don't like this, but styling the buttons that are passed in. 
          // Would rather that this component directly determined the buttons here. 
          .MuiButton-root {
            margin: 0; 
            padding: 0; 
            height: auto;
          }
        `}
      >
        <Typography
          css={css`
            flex: 1;
          `}
          variant="subtitle2"
          color="text.primary"
        >
          {title}
        </Typography>
        {actions && <div>{actions}</div>}
      </div>
      <div
        css={css`
          .MuiFormControl-root {
            margin-top: 0;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
};
