/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { INewSystemPropertiesData } from 'components/forms/SystemPropertiesForm';
import { createAction } from '@reduxjs/toolkit';

// System Properties Actions
// export const ADD_SYSTEM_PROPERTY = 'ADD_SYSTEM_PROPERTY';
// export const ADD_SYSTEM_PROPERTY_SUCCESS = 'ADD_SYSTEM_PROPERTY_SUCCESS';
// export const REMOVE_SYSTEM_PROPERTY = 'REMOVE_SYSTEM_PROPERTY';
// export const REMOVE_SYSTEM_PROPERTY_SUCCESS = 'REMOVE_SYSTEM_PROPERTY_SUCCESS';
// export const UPDATE_SYSTEM_PROPERTY = 'UPDATE_SYSTEM_PROPERTY';
// export const FETCH_SYSTEM_PROPERTY = 'FETCH_SYSTEM_PROPERTY';
// export const FETCH_ALL_SYSTEM_PROPERTY = 'FETCH_ALL_SYSTEM_PROPERTY';
// export const FETCH_ALL_SYSTEM_SUCCESS = 'FETCH_ALL_SYSTEM_PROPERTY_SUCCESS';
// export const FETCH_SYSTEM_PROPERTY_SUCCESS = 'FETCH_SYSTEM_PROPERTY_SUCCESS';
// export const FETCH_SYSTEM_PROPERTY_FAILED = 'FETCH_SYSTEM_PROPERTY_FAILED';

// System Properties Action Functions

export const addSystemProperty = createAction<
  INewSystemPropertiesData | INewSystemPropertiesData[]
>('sysProp/add');
export const updateSystemProperty = createAction<
  INewSystemPropertiesData | INewSystemPropertiesData[]
>('sysProp/update');
export const removeSystemProperty = createAction<string>('sysProp/remove');
export const fetchSystemProperty = createAction<string>('sysProp/fetchSingle');
export const fetchAllSystemProperty = createAction('sysProp/fetchAll');
