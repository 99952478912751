/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  CASE_CACHE_FETCH_ERROR: {
    code: 'CASE_CACHE_FETCH_ERROR',
    message: 'Error occurred while fetching case cache'
  },
  CASE_CACHE_ADD_ERROR: {
    code: 'CASE_CACHE_ADD_ERROR',
    message: 'Error occurred while adding case cache'
  },
  CASE_CACHE_DELETE_ERROR: {
    code: 'CASE_CACHE_DELETE_ERROR',
    message: 'Error occurred while deleting case cache'
  }
};

export { ERROR_MESSAGE };
