import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload
} from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { Selector, useDispatch, useSelector } from 'react-redux';
import { IStore } from 'states/store.interface';

interface IAutoEffect {
  <TSpread extends boolean, TSelector = unknown, TAction = undefined>(data: {
    name?: string; // Just for debugging
    selector: (state: any, data?: any) => TSelector;
    ac?: ActionCreatorWithPayload<TAction, string>;
    equality?: (a, b) => boolean;
    actionData:
      | (false extends TSpread | undefined ? TAction : TAction[])
      | null;
    selectorData?: any;
    statusSelector?: Selector<IStore, boolean>;
    isLoadingSelector?: Selector<IStore, boolean>;
    hasLoadedSelector?: Selector<IStore, boolean>;
    spreadDispatch?: TSpread | undefined;
  }): IAutoEffectReturn<TSelector>;

  <TSpread extends boolean, TSelector = unknown>(data: {
    name?: string; // Just for debugging
    selector: (state: any, data?: any) => TSelector;
    ac?: ActionCreatorWithoutPayload;
    equality?: (a, b) => boolean;
    selectorData?: any;
    statusSelector?: Selector<IStore, boolean>;
    isLoadingSelector?: Selector<IStore, boolean>;
    hasLoadedSelector?: Selector<IStore, boolean>;
    spreadDispatch?: TSpread | undefined;
  }): IAutoEffectReturn<TSelector>;
}

export interface IAutoEffectReturn<TSelector> {
  data: TSelector;
  fetchData: () => void;
  isLoading: boolean;
  hasLoaded: boolean;
}

export const useAutoEffect: IAutoEffect = ({
  name,
  selector,
  ac,
  statusSelector = () => false,
  isLoadingSelector = () => false,
  hasLoadedSelector = () => false,
  selectorData,
  actionData,
  spreadDispatch = false,
  equality
}) => {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) =>
      selector(state, selectorData ? selectorData : actionData || undefined),
    equality
  );
  const fetchedAndNotLoading = useSelector(statusSelector);

  const isLoading = useSelector(isLoadingSelector);
  const hasLoaded = useSelector(hasLoadedSelector);

  const actionDataString =
    typeof actionData === 'object' ? JSON.stringify(actionData) : actionData;

  const boundAction = useCallback(() => {
    let _actionData;
    try {
      _actionData = JSON.parse(actionDataString);
    } catch {
      _actionData = actionDataString;
    }

    if (!ac) {
      return;
    }
    if (_actionData && spreadDispatch) {
      _actionData.forEach((el) => dispatch(ac(el)));
    } else if (_actionData) {
      dispatch(ac(_actionData));
    } else if (_actionData !== null && _actionData === undefined) {
      dispatch(ac(_actionData));
    }
  }, [ac, actionDataString, spreadDispatch, dispatch]);

  useEffect(() => {
    if (!fetchedAndNotLoading) {
      boundAction();
    }
  }, [boundAction, fetchedAndNotLoading, name]);

  return {
    data,
    fetchData: boundAction,
    isLoading,
    hasLoaded
  };
};
