/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Grid } from '@mui/material';
import { DefaultCheckbox } from 'components/fields/DefaultCheckbox';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUpdateCaseAction } from 'states/caseActions/actions';
import { updateCasePlaybookAction } from 'states/casePlaybooks/actions';
import { useCasePlaybookListItemActionStyles } from '../styles';

export const ActionItemComplete = ({
  action,
  securityCase,
  casePlaybookUuid,
  belongsToPlaybook
}) => {
  const { status, assigneeUuid } = action;
  const [actionStatus, setActionStatus] = useState(status);
  const dispatch = useDispatch();

  const { isCompletedWrapper } = useCasePlaybookListItemActionStyles();

  const handleCompletedChange = (event) => {
    const completed = event.target.value;
    const caseUuid = securityCase.uuid;

    setActionStatus(completed ? 'success' : 'ready');

    if (belongsToPlaybook) {
      dispatch(
        updateCasePlaybookAction({
          caseUuid,
          playbookUuid: casePlaybookUuid,
          actionInstance: {
            ...action,
            assigneeUuid,
            status: completed ? 'success' : 'ready'
          }
        })
      );
    } else {
      dispatch(
        createUpdateCaseAction({
          caseUuid,
          actionInstance: {
            ...action,
            assigneeUuid,
            status: completed ? 'success' : 'ready'
          }
        })
      );
    }
  };

  return (
    <Grid item xs={belongsToPlaybook ? 2 : 3} className={isCompletedWrapper}>
      <DefaultCheckbox
        onChange={handleCompletedChange}
        options={[{ value: true, label: '' }]}
        value={actionStatus === 'success'}
      />
    </Grid>
  );
};
