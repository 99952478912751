/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DrawerModes = 'comment' | 'reply' | 'edit' | 'stix' | 'forms';
export interface ICaseThreadDrawer {
  mode: DrawerModes;
  drawerIsOpen: boolean;
  caseItemUuid?: string;
}

const initialState: ICaseThreadDrawer = {
  mode: 'comment',
  drawerIsOpen: false
};

const caseThreadDrawerSlice = createSlice({
  name: 'caseThreadDrawer',
  initialState,
  reducers: {
    updateCommentMode: (state, { payload }: PayloadAction<DrawerModes>) => {
      state.mode = payload;
    },
    setCommentDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.drawerIsOpen = payload;
    },
    setManiplulatingCaseItem: (state, { payload }: PayloadAction<string>) => {
      state.caseItemUuid = payload;
    }
  }
});

export const {
  reducer: caseThreadDrawer,
  actions: { updateCommentMode, setCommentDrawer, setManiplulatingCaseItem }
} = caseThreadDrawerSlice;
