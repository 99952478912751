/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CaseStatusDialog } from 'components/CaseStatusDialog';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import React, { useState } from 'react';
export type CydCaseStatusDirectionGraphProps = {
  currentStatus: string | null;
};

export const CydCaseStatusDirectionGraph = (
  props: CydCaseStatusDirectionGraphProps
) => {
  const { currentStatus } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CydIconButton
        icon="info"
        label="See status transition graph"
        onClick={() => setIsOpen(true)}
        size="small"
      />
      <CaseStatusDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        currentCaseState={currentStatus}
      />
    </>
  );
};
