/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Collapse, Fade, Typography } from '@mui/material';
import moment from 'moment';
import TouchRipple, {
  TouchRippleActions
} from '@mui/material/ButtonBase/TouchRipple';
import { CydSignificanceDisplay } from 'components/_objectDisplay/CydSignificanceDisplay/CydSignificanceDisplay';
import { CydTimeDisplay } from 'components/_objectDisplay/CydTimeDisplay/CydTimeDisplay';
import { User } from 'interface/User.interface';
import React, { useEffect, useMemo, useState, useRef } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { CydAclDisplayById } from 'components/_objectDisplay/CydAclDisplay/CydAclDisplayById';
import { CydCaseViewActivityCollapser } from './CydCaseViewActivityCollapser/CydCaseViewActivityCollapser';
import { CaseActivityContent } from 'components/CaseActivity/CaseActivityContent';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydConfirmModal } from 'components/_dialogs/CydConfirmModal/CydConfirmModal';
import { CydUserDisplayById } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplayById';
import { CydAclDiffersShield } from './CydAclDiffersShield/CydAclDiffersShield';
import { CaseActivityUtils } from 'utils/CaseActivityUtils';

export type DataStub = {
  uuid: string;
  version: number;
  created: Date;
  parentuuid: null;
  creatoruuid: string;
  creatorusername: string;
  mimetype: string;
  filename: string;
  datasize: number;
  datasource: string;
  location: string;
  datalocation: string;
  datalocationtype: string;
  significance: string;
  sigprecedence: string;
  audit: boolean;
  deleted: boolean;
  acl: string;
  edited: boolean;
  editoruuid: null;
  editorusername: string;
  lastmodified: Date;
  hidden: boolean;
  caseuuid: string;
};

export type ActivityItem = {
  uuid: string;
  created: string;

  edited: boolean;
  lastmodified: string;
  caseuuid: string;
  creatoruuid: string;
  creatorusername: string;
  acl: string;
  creator?: User;
  creatorUuid: string;

  mimetype: string;

  children?: Array<ActivityItem>;

  content: string;
  filename?: string;
  bytedata: string;
  datasize?: number;
  parentCaseUuid: string | null;

  significance: string;
  sigprecedence: number;

  dataStubUuid: string;
  parentuuid: string | null;

  audit: boolean;

  // I suspect that 'deletable' is a different thing to the 'canDelete' permission
  // eg. can delete comments, but not status changes.
  deletable: boolean;
  editable: boolean;

  action_instance_uuid?: string;
};

export type CydCaseViewActivityItemProps = {
  activityItem: ActivityItem;
  caseAcl: string;

  /**
   * If this value exists we obey it what child open states should be.
   */
  openStateFromParent?: ActivityItemOpenState;

  onDownloadClick: (item: ActivityItem) => void;
  onEditClick: (
    item: ActivityItem,
    activityItemEl: HTMLDivElement | null
  ) => void;
  onReplyClick: (
    item: ActivityItem,
    activityItemContentEl: HTMLDivElement | null
  ) => void;
  onDeleteClick: (item: ActivityItem) => void;
  onVisible?: (isVisible: boolean) => void;
  onNewItemMounted?: (activityItemEl: HTMLDivElement) => void;
};

// the open state of the activity item
export enum ActivityItemOpenState {
  Closed = 0,
  Partial = 1,
  Open = 2
}

export const CydCaseViewActivityItem = (
  props: CydCaseViewActivityItemProps
) => {
  const {
    activityItem,
    onDownloadClick,
    onEditClick,
    onReplyClick,
    onDeleteClick,
    onVisible,
    onNewItemMounted,
    caseAcl,

    openStateFromParent
  } = props;
  const rippleRef = useRef<TouchRippleActions>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const {
    content,
    audit,
    significance,
    created,
    creatoruuid,
    edited,
    lastmodified,
    children,
    filename,
    editable,
    deletable = true,
    mimetype,
    acl,
    action_instance_uuid
  } = activityItem;
  const showDifferentAclSignifier = !audit && acl !== caseAcl;

  const showReplyButton = !action_instance_uuid;

  // See note in the PR: https://github.com/cydarm/cydarm-frontend/pull/1360
  // These could possibly be removed
  const showDeleteButton = mimetype !== 'application/vnd.cydarm.form+json';
  const showEditButton =
    mimetype !== 'application/vnd.cydarm.form+json' &&
    !mimetype.startsWith('application/stix+json');

  const isTombstone = mimetype === 'tombstone';

  const hasPartialCollapse = useMemo(
    () => CaseActivityUtils.determineShouldHavePartialCollapse(activityItem),
    [activityItem]
  );

  const [openState, setOpenState] = useState(
    hasPartialCollapse
      ? ActivityItemOpenState.Partial
      : (ActivityItemOpenState.Open as ActivityItemOpenState)
  );

  useEffect(() => {
    // see https://cydarm.atlassian.net/wiki/x/JgDdbw for desired behaviour
    if (typeof openStateFromParent === 'number') {
      setOpenState(openStateFromParent);
    }
  }, [openStateFromParent]);

  useEffect(() => {
    if (!onVisible) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        // entries are array of observed elements
        entries.forEach((entry) => onVisible(entry.isIntersecting));
      },
      {
        threshold: 0.5 // 50% of the element's visibility
      }
    );

    const currentElement = contentRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    // Cleanup observer on component unmount
    return () => {
      if (currentElement) {
        observer.disconnect();
      }
    };
  }, [onVisible]);

  useEffect(() => {
    // only show ripple effect if the activity item is less than a minute old
    const createdTime = moment(activityItem.created);
    const differenceInMs = Math.abs(createdTime.diff(moment()));
    const createdWithIn10secs = differenceInMs <= 10000; // 10 seconds

    if (createdWithIn10secs) {
      if (onNewItemMounted && contentRef.current) {
        onNewItemMounted(contentRef.current);
      }
      showRippleEffect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showRippleEffect = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const rippleElement = rippleRef.current;
    if (!rippleElement) {
      return;
    }
    rippleElement.start(event);
    setTimeout(() => rippleElement.stop(event), 300);
  };

  const childredSorted = children?.slice().sort((a, b) => {
    return Number(new Date(a.created)) - Number(new Date(b.created));
  });

  return (
    <div
      data-testid="case-activity"
      css={css`
        display: flex;
        flex-flow: row nowrap;
      `}
    >
      <CydCaseViewActivityCollapser
        significance={significance}
        openState={openState}
        onClick={
          audit
            ? undefined
            : () => {
                if (hasPartialCollapse) {
                  // cycle through states
                  switch (openState) {
                    case ActivityItemOpenState.Closed:
                      setOpenState(ActivityItemOpenState.Partial);
                      break;
                    case ActivityItemOpenState.Partial:
                      setOpenState(ActivityItemOpenState.Open);
                      break;
                    case ActivityItemOpenState.Open:
                      setOpenState(ActivityItemOpenState.Closed);
                      break;
                  }
                } else {
                  setOpenState(
                    openState === ActivityItemOpenState.Open
                      ? ActivityItemOpenState.Closed
                      : ActivityItemOpenState.Open
                  );
                }
              }
        }
      />

      {/* Everything except the collapser */}
      <div
        css={css`
          flex: 1 1 auto;
          max-width: 1350px;
        `}
      >
        <div
          ref={contentRef}
          data-testid="activity-item-content"
          css={css`
            position: relative;
          `}
        >
          {/* Top Bar */}
          <div
            css={css`
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
            `}
          >
            {/* Info */}
            <div
              css={(theme) => `
                  display: flex;
                  flex-flow: row wrap;
                  gap: ${theme.spacing(2)};
                  align-items: center;
                  height: 24px;
                  font-size: ${theme.typography.body2.fontSize}px;
              `}
            >
              <>
                {!isTombstone && <CydTimeDisplay time={created} />}

                <CydSignificanceDisplay significance={significance} />
                <div>
                  {creatoruuid && (
                    <CydUserDisplayById
                      userId={creatoruuid}
                      variant="bold"
                      css={(theme) => `
                        display: inline; 
                        margin-right: 0.5em;
                        color: ${theme.palette.text.primary};
                    `}
                    />
                  )}
                  <Typography
                    variant="body2"
                    color={'text.primary'}
                    css={css`
                      display: inline;
                      margin-right: 0.5em;
                      line-height: initial;
                    `}
                  >
                    {audit ? content : `Posted a ${significance}`}
                  </Typography>

                  <span
                    css={(theme) => `
                        font-size: ${theme.typography.caption.fontSize}px;

                    `}
                  >
                    {edited && (
                      <>
                        <span
                          css={(theme) => `
                              font-style: italic;
                              margin-right: 0.5em;

                          `}
                        >
                          Last modified
                        </span>
                        <CydTimeDisplay
                          time={lastmodified}
                          css={(theme) => `
                              font-style: italic; `}
                        />
                      </>
                    )}
                  </span>

                  <CydAclDiffersShield aclDiffers={showDifferentAclSignifier} />
                </div>
              </>
            </div>

            {/* Actions*/}

            <div>
              {filename && (
                <CydIconButton
                  icon="download"
                  onClick={() => onDownloadClick(activityItem)}
                  label="Download file"
                />
              )}

              {!audit && (
                <>
                  {showReplyButton && (
                    <CydIconButton
                      icon="reply"
                      onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        showRippleEffect(e);
                        onReplyClick(activityItem, contentRef.current);
                      }}
                      label="Reply to this activity item"
                    />
                  )}
                  {showEditButton && (
                    <CydIconButton
                      disabled={!editable}
                      icon="edit"
                      onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        showRippleEffect(e);
                        onEditClick(activityItem, contentRef.current);
                      }}
                      label="Edit Activity"
                    />
                  )}

                  {showDeleteButton && (
                    <CydConfirmModal
                      renderTrigger={(onClick) => (
                        <CydIconButton
                          disabled={!deletable}
                          icon="delete"
                          onClick={onClick}
                          label="Delete Activity"
                        />
                      )}
                      message="Do you want to delete this item and all its replies?"
                      onConfirm={() => onDeleteClick(activityItem)}
                    />
                  )}

                  <CydAclDisplayById
                    variant="small-clickable"
                    aclId={acl}
                    filename={filename}
                    significance={significance}
                  />
                </>
              )}
            </div>
          </div>

          {/* Body*/}
          {/* The double collapse is icky and we should do something better
            Basically needs to support:
                  - For no hasPartial, needs to animated toggle between show nothing and show everything
                  - For hasPartial, needs to animated toggle beween show nothing, show partial, show everything
                  */}
          <Collapse orientation="vertical" in={openState > 0}>
            {hasPartialCollapse && (
              <>
                <Collapse
                  orientation="vertical"
                  collapsedSize={100}
                  in={openState === ActivityItemOpenState.Open}
                >
                  <CaseActivityContent caseActivity={activityItem} />
                </Collapse>

                <Fade in={openState === ActivityItemOpenState.Partial}>
                  <CydButton
                    size="small"
                    variant="outlined"
                    css={(theme) => `
                        display: block;
                        margin: ${theme.spacing(2)} auto;
                    `}
                    onClick={() => {
                      setOpenState(ActivityItemOpenState.Open);
                    }}
                  >
                    Show More
                  </CydButton>
                </Fade>
              </>
            )}

            {!hasPartialCollapse && (
              <>
                <CaseActivityContent caseActivity={activityItem} />
              </>
            )}
          </Collapse>
          <TouchRipple ref={rippleRef} />
        </div>

        {/* Recursively render replies */}
        {childredSorted &&
          childredSorted.map((v) => {
            return (
              <CydCaseViewActivityItem
                key={v.uuid}
                openStateFromParent={openState}
                activityItem={v}
                caseAcl={caseAcl}
                {...{
                  onDownloadClick,
                  onEditClick,
                  onReplyClick,
                  onDeleteClick,
                  onVisible,
                  onNewItemMounted
                }}
              />
            );
          })}
      </div>
    </div>
  );
};
