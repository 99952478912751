/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { useState, useEffect } from 'react';

interface ISkeletonListWrapper {
  skeleton: any;
  hasLoaded: boolean;
  children?: any;
  amount?: number;
}

export const SkeletonListWrapper = ({
  skeleton: Skeleton,
  hasLoaded,
  children,
  amount = 15
}: ISkeletonListWrapper) => {
  return (
    <>
      {hasLoaded
        ? children.map((child, i) => (
            <Child Skeleton={Skeleton} ChildElement={child} key={i} />
          ))
        : [...new Array(amount)].map((_, i) => <Skeleton key={i} />)}
    </>
  );
};

const Child = ({ Skeleton, ChildElement }) => {
  const [initLoad, setInitLoad] = useState(false);
  // little hack to display the skeleton load before attempting to render the element
  useEffect(() => {
    if (initLoad) {
      return;
    }
    const timeout = setTimeout(() => setInitLoad(true), 0);
    return () => clearTimeout(timeout);
  });
  return initLoad ? (
    ChildElement
  ) : (
    <Skeleton ref={ChildElement.props.innerRef} />
  );
};
