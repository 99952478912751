/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { usePlaybooks } from 'hooks/PlaybooksHooks';
import { PlaybooksPagePlaybooksSidebar } from './PlaybooksPagePlaybooksSidebar';
import { AtcPlaybook } from 'interface/Playbook.interface';
import { useDispatch } from 'react-redux';
import { createPlaybook } from 'states/playbooks/actions';
import { CydarmRoute } from 'interface/CydarmRoute';

import { CydLeftSideBar } from 'components/_layout/CydLeftSideBar/CydLeftSideBar';
import { useNavigate, useParams } from 'react-router';
import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';

export const PlaybooksPagePlaybooks: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { data: playbooks } = usePlaybooks();
  const dispatch = useDispatch();

  const params = useParams<{ playbookUuid: string }>();

  const handleSelectPlaybook = (
    playbook?: AtcPlaybook | OasisCacaoPlaybook
  ) => {
    if (!playbook?.uuid) {
      navigate(CydarmRoute.PLAYBOOKS);
      return;
    }
    navigate(`${CydarmRoute.PLAYBOOKS}/${playbook.uuid}`);
  };

  const handleCreatePlaybook = (playbookData) => {
    dispatch(
      createPlaybook({
        ...playbookData,
        resolve: (data) => {
          navigate(`${CydarmRoute.PLAYBOOKS}/${data.uuid}`);
        }
      })
    );
  };

  const displayingPlaybooks = React.useMemo(() => {
    return [...playbooks].sort((playbookA, playbookB) => {
      if (!playbookA.atc || !playbookB.atc) {
        return -1;
      }
      if (playbookA.atc.name === playbookB.atc.name) {
        return (
          playbookA.atc.modified?.localeCompare(playbookB.atc.modified || '') ||
          -1
        );
      } else {
        return playbookA.atc.name.localeCompare(playbookB.atc.name);
      }
    });
  }, [playbooks]);

  return (
    <CydLeftSideBar>
      <PlaybooksPagePlaybooksSidebar
        onPlaybookSelect={handleSelectPlaybook}
        playbooks={displayingPlaybooks}
        onCreatePlaybook={handleCreatePlaybook}
        selectedPlaybookUuid={params.playbookUuid}
      />
    </CydLeftSideBar>
  );
};
