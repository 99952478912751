import { CaseActivityFilterSetting } from 'components/_caseView/CydCaseViewActivityFilter/CydCaseViewActivityFilter';
import { ActivityItem } from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';

import { mapViewableData2 } from 'utils/CaseDataUtils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CYDARM_BASE_API } from '../constants';
import {
  apiFetchCaseActivity,
  apiFetchSingleActivityItem
} from 'services/DataService';
import { useCasePlaybooks } from './CasePlaybooksHooks';
import { useCaseActions } from './deprecated_CaseHooks';

export const rtkqCaseActivityApi = createApi({
  reducerPath: 'rtk-caseActivity',

  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),
  tagTypes: ['caseActivity'],
  endpoints: (builder) => {
    return {
      getAllCaseActivity: builder.query<
        {
          case_actions_data: Array<ActivityItem>;
          case_data: Array<ActivityItem>;
          playbook_actions_data: Array<ActivityItem>;
        },
        {
          caseUuid: string;
          lastUpdated?: string;
        }
      >({
        queryFn: async (arg, api, extraOptions) => {
          return {
            data: await apiFetchCaseActivity(arg.caseUuid)
          };
        },
        providesTags: (result, err, args) => {
          return [
            {
              type: 'caseActivity',
              id: args.caseUuid
            }
          ];
        }
      }),

      // As far as I can see, this is this only/best way to imperatively
      // Invalidate caches from other slices.
      // Maybe we should put them all in the same slice.

      //See: https://redux-toolkit.js.org/rtk-query/api/created-api/endpoints#example
      // https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#invalidating-tags
      invalidateCaseActivity: builder.mutation<
        null,
        {
          caseUuid: string;
        }
      >({
        invalidatesTags: (result, error, arg) => [
          { type: 'caseActivity', id: arg.caseUuid }
        ],
        queryFn: (args) => {
          return {
            data: null
          };
        }
      }),

      getSingleCaseActivityItem: builder.query<
        ActivityItem,
        { caseUuid: string; activityUuid: string | null }
      >({
        providesTags: (result, err, args) => {
          return [
            {
              type: 'caseActivity',
              id: args.caseUuid
            }
          ];
        },
        queryFn: async (arg, api, extraOptions) => {
          if (!arg.activityUuid) {
            throw new Error(
              'No activity uuid to fetch with. (This error should never be thrown - query should be disabled.) '
            );
          }

          const result = await apiFetchSingleActivityItem(
            arg.caseUuid,
            arg.activityUuid
          );
          return {
            data: result
          };
        }
      })
    };
  }
});

export function useSingleCaseActivityItem(
  caseUuid: string,
  activityUuid: string | null
) {
  return rtkqCaseActivityApi.endpoints.getSingleCaseActivityItem.useQuery(
    { caseUuid, activityUuid },
    {
      skip: !activityUuid
    }
  );
}

/**Get all case activity */
export function useGetAllCaseActivity(params: {
  caseUuid: string;
  lastUpdated?: string;
}) {
  const { useGetAllCaseActivityQuery } = rtkqCaseActivityApi;
  return useGetAllCaseActivityQuery(params);
}

/**Get all case activity, filtered and put into a tree like structure  */
export function useCaseActivityWithFilter(params: {
  caseUuid: string;
  filterSettings: CaseActivityFilterSetting;
  lastUpdated?: string;
}) {
  const { caseUuid, filterSettings, lastUpdated } = params;
  const result = useGetAllCaseActivity({
    caseUuid,
    lastUpdated
  });
  const casePlaybooks = useCasePlaybooks(caseUuid).data;
  const caseActions = useCaseActions(caseUuid).data;

  const mappedResult = mapViewableData2(
    result.data,
    filterSettings,
    casePlaybooks,
    caseActions
  );

  return {
    ...result,
    data: mappedResult
  };
}
