/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useState } from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';
import { useDownloadFileFromWikiPage } from 'hooks/WikiHooks';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { WikiPageStubResource } from 'generatedApi';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

type WikiAttachmentListItemProps = {
  attachment: WikiPageStubResource;
  onDeleteClick: () => void;
};

export const WikiAttachmentListItem = ({
  attachment,
  onDeleteClick
}: WikiAttachmentListItemProps) => {
  const downloadFileFromWikiPage = useDownloadFileFromWikiPage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileDownload = (attachmentId: string) => {
    setIsLoading(true);
    downloadFileFromWikiPage.mutate(
      { attachmentId },
      {
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <ListItem key={attachment.id}>
      <ListItemAvatar
        css={css`
          cursor: pointer;
        `}
        onClick={handleFileDownload.bind(null, attachment.id as string)}
      >
        <Avatar>
          <InsertDriveFileIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        css={css`
          cursor: pointer;
          text-decoration: underline;
        `}
        onClick={handleFileDownload.bind(null, attachment.id as string)}
        primary={attachment.attributes?.file_name}
      />
      <ListItemSecondaryAction>
        <CydIconButton
          icon="download"
          onClick={() => handleFileDownload(attachment.id as string)}
          label="Download file"
          loading={isLoading}
        />

        <CydIconButton
          icon="delete"
          onClick={() => {
            if (!attachment.id) {
              return;
            }
            onDeleteClick();
          }}
          label="Delete file"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
