import { useEffect, useCallback } from 'react';

//dont hotkey while we're trying to type in these elements
const ignoredHTMLElements = ['INPUT', 'TEXTAREA'];

//TODO accept an object of keys, so we can register multiple keymaps without calling the function multiple times
export const useRegisterHotkeyListener = (
  key: string | undefined,
  callbackFn: () => void,
  ignoreElements = true
) => {
  const hotkeyFunction = useCallback(
    (e) => {
      if (key === e.key) {
        const activeElement = document.activeElement;
        const activeElementNodeName = activeElement?.nodeName || '';
        const activeElementIsCodeMirror =
          activeElement?.classList.contains('CodeMirror-code');
        const activeElementIsInput = ignoredHTMLElements.includes(
          activeElementNodeName
        );

        if (
          !ignoreElements ||
          (!activeElementIsInput && !activeElementIsCodeMirror)
        ) {
          callbackFn();
        }
      }
    },
    [key, callbackFn, ignoreElements]
  );
  useEffect(() => {
    if (key) {
      document.addEventListener('keyup', hotkeyFunction);
      return () => document.removeEventListener('keyup', hotkeyFunction);
    }

    return () => {};
  }, [key, hotkeyFunction]);
};
