/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

export type BulkIocCompleteProps = {
  fileName: string;
  onDoneClick: () => void;
};

export const BulkIocComplete = (props: BulkIocCompleteProps) => {
  const { fileName, onDoneClick } = props;

  return (
    <div>
      <Typography variant="body1" color="text.primary">
        <CydIcon
          icon="doneCircle"
          css={(theme) => `
          fill: ${theme.palette.success.main};
          vertical-align: middle;
          margin-right: 10px;
        `}
        />
        Indicators extracted from {fileName} were added to the Case.
      </Typography>

      <CydButtonStack justifyContent={'flex-end'}>
        <CydButton variant="outlined" onClick={onDoneClick}>
          Done
        </CydButton>
      </CydButtonStack>
    </div>
  );
};
