/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import TextField, { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';

// Note that the TextField is a bit more of a complicated component than others
// As it is actually used by other atomic components like File Selector, Auto Complete etc
// And being able to pass refs in, etc is required.
export type CydTextFieldProps = Pick<
  TextFieldProps,
  | 'defaultValue'
  | 'value'
  | 'label'
  | 'helperText'
  | 'name'
  | 'type'
  | 'id'
  | 'disabled'
  | 'inputProps'
  | 'InputProps'
  | 'className'
  | 'inputRef'
  | 'autoFocus'
  | 'required'
  | 'variant'
  | 'placeholder'
  | 'fullWidth'
  | 'size'
  | 'onKeyDown'
  | 'autoComplete'
  | 'multiline'
  | 'onFocus'
  | 'onBlur'
  | 'rows'
  | 'InputLabelProps'
> & {
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorText?: string;
};

export const CydTextField = (props: CydTextFieldProps) => {
  const { onChange, helperText, errorText, ...rest } = props;

  return (
    <TextField
      fullWidth={true}
      // Not sure why needs to shrink.
      // since we are using mui TextField and other fields (e.g. dropdown) are not shrink,
      // commenting this out for consistency
      // InputLabelProps={{ shrink: true }}
      onChange={(e) => onChange && onChange(e.target.value, e)}
      helperText={helperText || errorText}
      error={!!errorText}
      {...rest}
    />
  );
};
