/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  createCaseTag,
  createDeleteCaseTag,
  createFetchCaseTags,
  createUpdateCaseTag
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { fetchCaseTagsFailed, fetchCaseTagsSuccess } from './slice';
import {
  apiPerformCreateCaseTags,
  apiPerformDeleteCaseTags,
  apiPerformFetchCaseTags,
  apiPerformUpdateCaseTags
} from 'services/CaseServices';

export function* performFetchCaseTags() {
  try {
    const { json } = yield apiPerformFetchCaseTags();
    yield put(fetchCaseTagsSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_CASE_TAGS_ERROR.message
      })
    );
    yield put(fetchCaseTagsFailed(ERROR_MESSAGE.FETCH_CASE_TAGS_ERROR));
  }
}

export function* performCreateCaseTag(action) {
  const {
    payload: { value, description, acl },
    resolve
  } = action;
  try {
    const {
      json: { uuid }
    } = yield apiPerformCreateCaseTags({ value, description, acl });
    const caseTag = { uuid, value, description, acl };
    yield put(createFetchCaseTags());
    resolve && resolve(caseTag);
    yield put(
      addNotification({
        message: 'Successfully creating new case tag'
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.CREATE_CASE_TAG_ERROR.message
      })
    );
  }
}

export function* performUpdateCaseTag(action) {
  const {
    payload: { uuid, name, description, aclDescription }
  } = action;
  try {
    yield apiPerformUpdateCaseTags(uuid, {
      value: name,
      description,
      acl: aclDescription
    });
    yield put(createFetchCaseTags());
    yield put(
      addNotification({
        message: 'Successfully updating case tag'
      })
    );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.UPDATE_CASE_TAG_ERROR.message
      })
    );
  }
}

export function* performDeleteCaseTag({ payload: uuid }: { payload: string }) {
  try {
    yield apiPerformDeleteCaseTags(uuid);
    const { json } = yield apiPerformFetchCaseTags();
    const foundUpdatedTag = json.find((el) => el.uuid === uuid);
    yield put(fetchCaseTagsSuccess(json));
    foundUpdatedTag
      ? yield put(
          addNotification({
            message: 'Successfully marking case tag as deprecated'
          })
        )
      : yield put(
          addNotification({
            message: 'Successfully removing case tag'
          })
        );
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.DELETE_CASE_TAG_ERROR.message
      })
    );
  }
}

/* Watchers */
function* watchFetchCaseTags() {
  yield takeLeading(createFetchCaseTags, performFetchCaseTags);
}

function* watchCreateCaseTag() {
  yield takeLatest(createCaseTag, performCreateCaseTag);
}

function* watchDeleteCaseTag() {
  yield takeLatest(createDeleteCaseTag, performDeleteCaseTag);
}

function* watchUpdateCaseTag() {
  yield takeLatest(createUpdateCaseTag, performUpdateCaseTag);
}

export default [
  watchFetchCaseTags(),
  watchCreateCaseTag(),
  watchDeleteCaseTag(),
  watchUpdateCaseTag()
];
