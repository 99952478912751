/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  Autocomplete,
  Avatar,
  Chip,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField
} from '@mui/material';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { User } from 'interface/User.interface';
import React, { useMemo, useRef, useState } from 'react';

import { getUserInitials } from 'utils/UserUtils';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import { ClassNames } from '@emotion/react';
import { CydUserDisplay } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplay';

/** @jsxImportSource @emotion/react */

export type CydUserSelectorProps<TAllowUnselect extends boolean> = {
  availableUsers: Array<User>;

  //All of this drama to support this issue.
  // https://cydarm.atlassian.net/browse/RM-2597
  // Basically a scenario where you have the username, but not the the User
  /**
   * @deprecated use selectedUserUuid or defaultSelectedUserUuid instead
   */
  selectedUser?: null | User | 'unavailable-user';

  label?: string;

  /**
   * Mostly used to differentiate between two different assignees
   */
  ariaLabel?: string;
  variant?: 'default' | 'color-small';
  onChange?: (
    newUser: TAllowUnselect extends true ? User | null : User
  ) => void;
  allowUnselect: TAllowUnselect;
  unselectOptionLabel?: string;
  disabled?: boolean;
  className?: string;
  selectedUserUuid?: string;
  defaultSelectedUserUuid?: string | null;
  name?: string;
};

export const PopoverVariant = <TAllowUnselect extends boolean>(
  props: CydUserSelectorProps<TAllowUnselect>
) => {
  const {
    availableUsers,
    selectedUser,
    onChange,
    allowUnselect,
    disabled,
    className,
    label,
    ariaLabel,
    defaultSelectedUserUuid,
    selectedUserUuid
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const hasUnavailableUser = selectedUser === 'unavailable-user';

  const options = useMemo(() => {
    return availableUsers.map((v) => ({
      label: v.displayName,
      value: v
    }));
  }, [availableUsers]);

  /**
   * nb. Far too much work to get these to work for now, but at some point we should.
   *
   * Basically for uncontrolled it would involve maintaining an internal state of the selected uuid.
   * And also adding an hidden input so that form events work
   */
  if (defaultSelectedUserUuid) {
    throw new Error(
      'Uncontrolled component usage not supported for color-small variant!'
    );
  }

  if (selectedUserUuid) {
    throw new Error('selectedUserUuid not supported for color-small variant!');
  }

  return (
    <>
      <Chip
        className={className}
        disabled={disabled}
        aria-label={ariaLabel || label}
        css={(theme) => `

        &.Mui-disabled {
          opacity:1; 
        }
        height: 24px; 
        border-radius: 16px; 

        margin-top: 8px; 
        margin-bottom: 4px;

        .MuiChip-deleteIcon {
          height: 16px;
        }
        
        border: ${theme.palette.grey[700]} solid 1px; 
        background-color: ${
          selectedUser ? theme.palette.action.selected : 'transparent'
        }
      `}
        icon={
          selectedUser && !hasUnavailableUser ? (
            <CydUserDisplay
              user={selectedUser}
              variant="avatar"
              css={(theme) => `
              width: 18px; 
              height: 18px; 
            `}
            />
          ) : (
            <CydIcon
              icon="add"
              css={(theme) => `
          fill: ${theme.palette.grey[700]}; 
          background-color: ${theme.palette.grey[400]};
          border-radius: 100%; 
          width: 18px; 
          height: 18px; 
          padding: 2px; 
        `}
            />
          )
        }
        ref={ref}
        onDelete={
          allowUnselect && selectedUser
            ? () =>
                onChange?.(
                  null as TAllowUnselect extends true ? User | null : User
                )
            : undefined
        }
        onClick={() => {
          setIsOpen(true);
        }}
        label={
          selectedUser
            ? hasUnavailableUser
              ? UNAVAILABLE_USER_PLACEHOLDER.displayName
              : selectedUser.displayName
            : label
        }
      />

      <ClassNames>
        {({ css, theme }) => (
          <Popover
            transitionDuration={0}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom'
            }}
            anchorEl={ref.current}
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            PaperProps={{
              className: css`
                margin-top: ${theme.spacing(1)};
                padding: ${theme.spacing(2)};
                width: 300px;
                min-height: 270px;
              `
            }}
          >
            <Autocomplete
              disablePortal
              openOnFocus
              options={options}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  {...params}
                  autoFocus
                  label="Search users"
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      // Need to override the padding
                      // Various open issues about this : https://github.com/mui/material-ui/issues/22544
                      '&.MuiInputBase-adornedStart': {
                        paddingTop: '0'
                      }
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CydIcon
                          icon="search"
                          css={(theme) => `
        height: 20px; 
        width: 22px; 
        font-size: 14px; 
        margin-bottom: ${theme.spacing(3)}`}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              onChange={(e, v) => {
                // Only call the onchange is there is a value, or unselect is allowed
                if (v || allowUnselect) {
                  onChange?.(
                    (v?.value || null) as TAllowUnselect extends true
                      ? User | null
                      : User
                  );
                }
                //Always close the popover
                setIsOpen(false);
              }}
              ListboxProps={{}}
              componentsProps={{
                paper: {
                  elevation: 0
                },
                popper: {
                  disablePortal: true,
                  sx: {
                    '& .MuiAutocomplete-listbox': {
                      maxHeight: 'none'
                    }
                  }
                }
              }}
              renderOption={(props, v, state) => {
                return (
                  <ListItemButton
                    component="li"
                    dense
                    {...props}
                    aria-label={v.label}
                  >
                    <ListItemIcon>
                      <Avatar
                        css={(theme) => `
        height: 24px; 
        width: 24px; 
        font-size: 14px; 
      `}
                      >
                        {getUserInitials(v.value)}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText>{v.label}</ListItemText>
                  </ListItemButton>
                );
              }}
              classes={{
                paper: css`
                  background-color: transparent;
                `
              }}
            />
          </Popover>
        )}
      </ClassNames>
    </>
  );
};

const UNAVAILABLE_USER_PLACEHOLDER = {
  displayName: 'Undisclosed user',
  uuid: 'unavailable-user'
} as unknown as User;

export const CydUserSelector = <TAllowUnselect extends boolean>(
  props: CydUserSelectorProps<TAllowUnselect>
) => {
  const {
    availableUsers,
    selectedUser,
    onChange,
    selectedUserUuid,
    defaultSelectedUserUuid,
    name,
    allowUnselect,
    variant = 'default',
    unselectOptionLabel,
    disabled = false,
    className,
    ariaLabel,
    label = 'Select user'
  } = props;

  if (variant === 'color-small') {
    return <PopoverVariant label={props.label || 'Select user'} {...props} />;
  }

  const hasUnavailableUser = selectedUser === 'unavailable-user';

  let selectedOptionIfExists: User | undefined = undefined;
  if (selectedUser) {
    if (hasUnavailableUser) {
      selectedOptionIfExists = UNAVAILABLE_USER_PLACEHOLDER;
    } else {
      selectedOptionIfExists = selectedUser as User;
    }
  }

  return (
    <>
      <CydSimpleSelect
        ariaLabel={ariaLabel}
        availableOptions={
          (hasUnavailableUser
            ? [UNAVAILABLE_USER_PLACEHOLDER, ...availableUsers]
            : availableUsers) as Array<User>
        }
        className={className}
        disabled={disabled}
        size={variant === 'default' ? 'medium' : 'small'}
        label={label}
        selectedValueString={selectedUserUuid ?? selectedOptionIfExists?.uuid}
        defaultSelectedString={defaultSelectedUserUuid}
        onChange={onChange}
        allowUnselect={allowUnselect}
        unselectOptionLabel={unselectOptionLabel}
        determineDisabledOptions={(v) => v === UNAVAILABLE_USER_PLACEHOLDER}
        generateValueString={(v) => {
          return v.uuid;
        }}
        generateOptionLabel={(v) => {
          return v.displayName;
        }}
        name={name}
      />
    </>
  );
};
