import { Store } from '@reduxjs/toolkit';
import { addNotification } from './notifications/slice';

/**
 * This is an important pattern to avoid circular dependencies anywhere we interact with store directly
 * See:
 *
 * https://github.com/reduxjs/redux-toolkit/issues/1540
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 *
 *
 * @param store
 */
let store: Store;
export function provideStore(_store: Store) {
  store = _store;
}

/**
 * Allow us to imperatively dispatch notifications from anywhere.
 * @param notif
 */
export function imperativeAddNotification(notif: { message: string }) {
  if (store) {
    store.dispatch(addNotification(notif));
  }
}
