/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IDetailedCaseData } from 'interface/CaseData.interface';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mergeObjectsInUnique } from 'utils/MergeUtils';
import { createFetchDataSignificances } from './actions';

export interface IDataStoreState {
  isLoading: boolean;
  hasLoaded: boolean;
  dataSignificances: DataSignificance[];
  cases: {
    [key: string]: IDetailedCaseData[];
  };
}

interface IAddToStore {
  dataset: IDetailedCaseData[];
  caseUuid: string;
}

interface IRemove {
  caseUuid: string;
  dataStubUuid: string;
}

const initialState: IDataStoreState = {
  dataSignificances: [],
  isLoading: false,
  hasLoaded: false,
  cases: {}
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    addDatasetToStore: (state, { payload }: PayloadAction<IAddToStore>) => {
      if (state.cases[payload.caseUuid]) {
        state.cases[payload.caseUuid] = mergeObjectsInUnique(
          [...state.cases[payload.caseUuid], ...payload.dataset],
          'uuid',
          true
        );
      } else {
        state.cases[payload.caseUuid] = payload.dataset;
      }
    },

    removeDataFromStore: (state, { payload }: PayloadAction<IRemove>) => {
      if (state.cases[payload.caseUuid]) {
        state.cases[payload.caseUuid] = state.cases[payload.caseUuid].filter(
          ({ dataStubUuid }) => dataStubUuid !== payload.dataStubUuid
        );
      }
    },

    fetchDataSignificancesSuccess: (
      state,
      { payload }: PayloadAction<DataSignificance[]>
    ) => {
      state.dataSignificances = payload;
      state.isLoading = false;
      state.hasLoaded = true;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchDataSignificances, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: data,
  actions: {
    addDatasetToStore,
    removeDataFromStore,
    fetchDataSignificancesSuccess
  }
} = dataSlice;
