/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { TruncatedLongString } from 'components/_dataDisplay/TruncatedLongString/TruncatedLongString';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  paddedLine: {
    paddingLeft: spacing(2)
  },
  table: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'stretch',

    // Control the display order with CSS! :)
    '& .type': {
      order: -100
    },
    '& .spec_version': {
      order: -99
    },
    '& .id': {
      order: -98
    }
  },
  root: {}
}));

const TRUNCATE_MAP = {
  payload_bin: 'base64',
  body: 'char'
};

export const StixDataPanel = ({ data }) => {
  return (
    <div>
      <RecursiveDataPanel data={data} />
    </div>
  );
};

export const RecursiveDataPanel = ({ data }) => {
  const { table } = useStyles();

  if (!data) {
    return null;
  }

  const filteredData = Object.entries(data).filter(
    ([type]) => !['positionY', 'isStix'].includes(type)
  );
  return (
    <div className={table}>
      {filteredData.map(([key, value]: [string, string]) => {
        // Be aware!
        // Stix vis is doing an annoying thing where it is 'helpfully'
        // Removing the underscores for us
        // Here we're putting them back in
        const newKey = key.replace(' ', '_');

        return (
          <div key={newKey} className={newKey}>
            <strong>{key}: </strong>
            <StixDataLine dataKey={newKey} value={value} />
          </div>
        );
      })}
    </div>
  );
};

const StixDataLine = ({ dataKey, value }) => {
  const { paddedLine } = useStyles();

  if (typeof value === 'object') {
    return (
      <div className={paddedLine}>
        <RecursiveDataPanel data={value} />
      </div>
    );
  } else {
    let stringToUse;
    const truncateMode = TRUNCATE_MAP[dataKey];
    if (truncateMode) {
      stringToUse = <TruncatedLongString string={value} mode={truncateMode} />;
    } else {
      stringToUse = <>{String(value)}</>;
    }

    return (
      <>
        <>{stringToUse}</>
      </>
    );
  }
};
