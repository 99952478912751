/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useState } from 'react';
import { CydarmGenericFlowchart } from '../CydarmGenericFlowChart';

import { GenericPlaybook } from '../type';
import { BuilderDetailsPanel } from '../BuilderDetailsPanel/BuilderDetailsPanel';

type UpdatePlaybookPayload = Pick<
  GenericPlaybook,
  'acl' | 'description' | 'title' | 'tags' | 'nodes'
>;

export type FlowBuilderProps = {
  playbook: GenericPlaybook;
  onSavePlaybook: (playbook: UpdatePlaybookPayload) => void;
  onDeletePlaybook: () => void;
};

/**
 * Flow builder is the component a user uses to _create_ Cacao playbooks.
 *
 * It also has a 'read only' mode for allowing users to view their playbooks, without editing them.
 * @param props
 * @returns
 */

export const FlowBuilder = (props: FlowBuilderProps) => {
  const { playbook } = props;
  const [selectedNode, setSelectedNode] = useState<any | null>(null);

  return (
    <>
      <CydarmGenericFlowchart
        selectedNodeId={selectedNode ? selectedNode.nodeId : null}
        playbook={playbook}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={true}
        onNodeClick={(event, element) => {
          setSelectedNode(element.data);
        }}
      />
      <BuilderDetailsPanel
        playbook={playbook}
        node={selectedNode}
        playbookType={playbook.playbookType}
        onDeletePlaybook={props.onDeletePlaybook}
      />
    </>
  );
};
