/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICaseCacheStoreState {
  list: string[];
}

const initialState: ICaseCacheStoreState = {
  list: []
};

export const caseCacheSlice = createSlice({
  name: 'caseCache',
  initialState,
  reducers: {
    caseCacheFetchResolved: (state, { payload }: PayloadAction<string[]>) => {
      state.list = payload;
    }
  }
});

export const {
  reducer: caseCache,
  actions: { caseCacheFetchResolved }
} = caseCacheSlice;
