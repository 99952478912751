/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Box, Stack, Typography } from '@mui/material';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydTextField } from 'components/_formElements/CydTextField';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { Acl } from 'interface/Acl.interface';
import { useState } from 'react';

import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

export type CreatePlaybookData = {
  name: string;
  description: string;
  acl: Acl;

  workflowJson?: Record<string, unknown>;
};

export type CreatePlaybookFormProps = {
  availableAcls: Array<Acl>;
  defaultAcl: Acl | null;
  onSubmit: (data: CreatePlaybookData) => void;
};

export const CreatePlaybookForm = (props: CreatePlaybookFormProps) => {
  const { availableAcls, onSubmit, defaultAcl } = props;
  const [freeTextErr] = useState(null as null | string);
  const [acl, setAcl] = useState(defaultAcl);

  return (
    <form
      data-testid="cacao-playbook-modal"
      onSubmit={(e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const acl = formData.get('acl')?.toString();
        const name = formData.get('name')?.toString();
        const description = formData.get('atc-description')?.toString();

        if (!acl) {
          throw new Error('No ACL selected');
        }

        const foundAcl = availableAcls.find((v) => v.uuid === acl);
        if (!foundAcl) {
          throw new Error('no found ACL');
        }
        if (!name) {
          throw new Error('name was not found');
        }

        if (!description) {
          throw new Error('description was not found');
        }

        onSubmit({
          name,
          description,
          acl: foundAcl
        });
      }}
    >
      <Stack direction={'column'} height="100%">
        <Stack direction={'column'} height="100%">
          <CydTextField autoFocus name="name" required label="Playbook Name" />
          <Box height={'400px'} border={'1px'} position={'relative'}>
            <CydEnhancedMarkdownEditor
              initialValue={''}
              name="atc-description"
              label="Playbook description"
            />
          </Box>
        </Stack>

        {freeTextErr && <Typography color="error">{freeTextErr}</Typography>}
        <CydAclSelector
          required
          availableAcls={availableAcls}
          selectedAclUuid={acl?.uuid}
          onChange={setAcl}
          name="acl"
        />
        <CydButtonStack justifyContent={'flex-end'}>
          <CydButton type="submit">Submit</CydButton>
        </CydButtonStack>
      </Stack>
    </form>
  );
};
