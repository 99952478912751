/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest } from 'redux-saga/effects';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { quickSearch } from './actions';
import { quickSearchFailed, quickSearchSuccess } from './slice';
import { apiQuickSearch } from 'services/IocQuickSearchAndMetricsService';

function* performQuickSearch({ payload: searchText }: { payload: string }) {
  try {
    const { json: results } = yield apiQuickSearch({
      searchString: decodeURIComponent(searchText)
    });

    yield put(quickSearchSuccess(results));
  } catch (ex) {
    yield put(quickSearchFailed());
    yield put(
      addNotification({ message: ERROR_MESSAGE.QUICK_SEARCH_ERROR.message })
    );
  }
}

/* Watchers */
function* watchQuickSearch() {
  yield takeLatest(quickSearch, performQuickSearch);
}

export default [watchQuickSearch()];
