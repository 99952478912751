/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';
import { Clock } from 'components/Clock/Clock';
import { TimezoneSelect } from 'components/fields/TimezoneSelect';
import moment from 'moment';
import { CydPopover } from 'components/_dialogs/CydPopover/CydPopover';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ManyTimezonesSelector } from 'components/Clock/ManyTimeZonesSelector';
export type CydAppBarTimeZoneSelectProps = {
  allTimezones: Array<string>;
  selectedTimezone: string;
  updateTimezone: (tz: string) => void;
  className?: string;
};

export const CydAppBarTimezoneSelect = (
  props: CydAppBarTimeZoneSelectProps
) => {
  const { allTimezones, className, selectedTimezone, updateTimezone } = props;
  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        gap: 1em;
      `}
    >
      <Clock children={selectedTimezone} />
      <TimezoneSelect
        timezones={allTimezones}
        value={selectedTimezone}
        onChange={(event) => {
          const newTimeZone =
            (event.target.value as string) || moment.tz.guess();
          updateTimezone(newTimeZone);
        }}
        inNavBar={true}
      />

      <CydPopover
        label="Favorite timezones"
        variant="icon-button"
        icon="world"
        onFormSubmit={(e) => {
          // This is kind of hacky behaviour for use with the CydPopover
          // I haven't provided a way to programatically close the popover, other than form submission.
          // However, I'm kind of ok with this.
          // Could possibly do something add a 'onFormClear' to provide two different closing mechanisms
          e.preventDefault();
        }}
      >
        <ManyTimezonesSelector />
      </CydPopover>
    </div>
  );
};
