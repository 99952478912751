/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Case } from 'interface/Case.interface';
import { User } from 'interface/User.interface';
import { createFetchRecursiveGroupMembers } from 'states/groupMembers/actions';
import {
  deepActionAssigneesSelector,
  deepCaseAssigneesSelector,
  deepGroupMembersStatusSelector,
  singleDeepGroupMembersWithDisplayNameSelector
} from 'states/groupMembers/selectors';
import {
  createFetchAllUsers,
  createFetchUserInfo,
  createFetchUsers
} from 'states/users/actions';
import {
  usersWithDisplayNameSelector,
  singleUserSelector,
  usersStatusSelector,
  disabledUsersSelector,
  singleUserSelectorByUsername,
  usersHasLoadedSelector,
  usersIsLoadingSelector
} from 'states/users/selectors';
import { useGroups, useUuidFromGroupName } from './GroupHooks';
import { IAutoEffectReturn, useAutoEffect } from './ReduxHooks';
import { useAllSystemProperties } from './SysPropsHooks';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CYDARM_BASE_API } from '../constants';
import { apiFetchCurrentUserInfo } from 'services/AuthService';
import { getSessionUuid } from 'utils/TokenManager';
import { useSingleCase } from './deprecated_CaseHooks';

export const useAllUsers = (
  {
    filterNonHuman = false,
    filterDeleted = false
  }: { filterNonHuman?: boolean; filterDeleted?: boolean } = {
    filterNonHuman: false,
    filterDeleted: false
  }
) => {
  useDisplayNameData();
  return useAutoEffect({
    selector: usersWithDisplayNameSelector,
    ac: createFetchAllUsers,
    statusSelector: usersStatusSelector,
    selectorData: { filterNonHuman, filterDeleted }
  });
};

export const useAllDisabledUsers = () => {
  useAllUsers();
  return useAutoEffect({
    selector: disabledUsersSelector,
    ac: createFetchAllUsers,
    statusSelector: usersStatusSelector
  });
};

export const useRecursiveGroupUsers = (uuid) => {
  useAllUsers();
  return useAutoEffect({
    selector: singleDeepGroupMembersWithDisplayNameSelector,
    ac: createFetchRecursiveGroupMembers,
    actionData: uuid
  });
};

// function overloading ftw
// https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
export function useCaseAssignees(
  org: string,
  assignee: string
): IAutoEffectReturn<Array<User>>;
export function useCaseAssignees(
  cydCase: Case | null
): IAutoEffectReturn<Array<User>>;
export function useCaseAssignees(
  param1: string | Case | null,
  assignee?: string
): IAutoEffectReturn<Array<User>> {
  useAllUsers();
  useGroups();

  let orgId: string;

  if (typeof param1 === 'string') {
    orgId = param1;
  } else if (typeof param1 === 'object') {
    orgId = param1?.org || '';
  } else {
    orgId = '';
  }

  if (orgId === '') {
    // warn
  }

  const uuid = useUuidFromGroupName(orgId);
  //pass no data until we have our uuid
  const selectorData = uuid && { uuid, assignee };
  const result = useAutoEffect({
    selector: deepCaseAssigneesSelector,
    ac: createFetchRecursiveGroupMembers,
    selectorData: selectorData,
    actionData: uuid || null
  });

  return result;
}

export function useAvailableUsersForCaseUuid(caseUuid: string) {
  const caseResult = useSingleCase(caseUuid);
  return useCaseAssignees(caseResult.data ?? null);
}

export const useActionAssignees = (org, assignee = '') => {
  useAllUsers();
  useGroups();
  const uuid = useUuidFromGroupName(org);
  //pass no data until we have our uuid
  const selectorData = uuid && { uuid, assignee };
  return useAutoEffect({
    selector: deepActionAssigneesSelector,
    ac: createFetchRecursiveGroupMembers,
    statusSelector: deepGroupMembersStatusSelector,
    selectorData: selectorData,
    actionData: uuid || null
  });
};

export const useSingleUser = (userUuid?: string) => {
  useDisplayNameData();
  return useAutoEffect({
    selector: singleUserSelector,
    ac: createFetchUserInfo,
    actionData: userUuid || null,
    isLoadingSelector: usersIsLoadingSelector,
    hasLoadedSelector: usersHasLoadedSelector
  });
};

export const useSingleUserByUserName = (username?: string) => {
  return useAutoEffect({
    selector: singleUserSelectorByUsername,
    statusSelector: usersStatusSelector,
    ac: createFetchUsers,
    selectorData: username,
    isLoadingSelector: usersIsLoadingSelector,
    hasLoadedSelector: usersHasLoadedSelector
  });
};

export const useDisplayNameData = () => {
  useGroups();
  useAllSystemProperties();
};

export const rtkqUsersApi = createApi({
  reducerPath: 'rtk-users',
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),
  tagTypes: ['user'],
  endpoints: (builder) => {
    return {
      getCurrentUser: builder.query<User, {}>({
        queryFn: async () => {
          try {
            const sessionUuid = getSessionUuid();
            const result = await apiFetchCurrentUserInfo(sessionUuid);
            return {
              data: result.json
            };
          } catch (err) {
            console.error(err);
            return {
              error: {
                data: err,
                status: 1
              }
            };
          }
        }
      })
    };
  }
});
