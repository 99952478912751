/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { FC, useMemo, memo } from 'react';
import { useAllUsers } from 'hooks/UserHooks';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { User } from 'interface/User.interface';
import { CydUserDisplay } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplay';

interface IUserSelect2 extends SelectProps {
  dataValue?: keyof User;
}

export const UserSelect2: FC<IUserSelect2> = memo(
  ({ value, dataValue = 'uuid', ...props }) => {
    const { data: users } = useAllUsers({ filterDeleted: true });

    const _value = useMemo(() => value || '', [value]);

    return (
      <Select value={_value} {...props} data-testid="user-select">
        <MenuItem value="" disabled>
          Select user
        </MenuItem>
        {users.map((user) => (
          <MenuItem value={user[dataValue] as string} key={user.uuid}>
            <CydUserDisplay user={user} />
          </MenuItem>
        ))}
      </Select>
    );
  }
);
