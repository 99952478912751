/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import {
  ActivityItem,
  DataStub
} from 'components/_caseView/CydCaseViewActivityItem/CydCaseViewActivityItem';

import { Acl } from 'interface/Acl.interface';
import {
  HTML_MIMETYPE,
  inlineImageWhitelist,
  shouldShowPreview
} from 'utils/CaseDataUtils';
import * as CydarmFetch from 'utils/CydarmFetch';
import { clone } from 'utils/ObjectUtils';
import {
  Base64Decode,
  Base64Encode,
  batchUuidStrings
} from 'utils/StringUtils';
import {
  ICaseData,
  ICreateCaseDataRequest
} from 'interface/CaseData.interface';
import type {
  CaseDataRequestTopLevel,
  CaseDataTopLevel
} from '../generatedApi/models/index';
import { cyFetchAuthenticated, CyFetchResponse } from 'utils/CydarmFetch';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { apiBaseUploadFile } from 'services/FileUploadService';

export async function apiUpdateData(dataStubUuid: string, data) {
  const { acl, ...rest } = data;
  const newData = {
    ...rest,
    acl: acl
  };

  return CydarmFetch.cyFetchAuthenticated(`/data/${dataStubUuid}`, {
    method: 'PUT',
    body: JSON.stringify(newData)
  });
}

export async function apiDeleteData(dataStubUuid) {
  return CydarmFetch.cyFetchAuthenticated(`/data/${dataStubUuid}`, {
    method: 'DELETE'
  });
}

export async function apiFetchDataSignificances(): Promise<
  CydarmFetch.CyFetchResponse<Array<DataSignificance>>
> {
  return CydarmFetch.cyFetchAuthenticated('/data-sig', {
    method: 'GET'
  });
}

export async function apiFetchDetailedDataset(caseDataUuid: string) {
  return CydarmFetch.cyFetchFileAuthenticated(`/data/${caseDataUuid}/file`);
}

export function mergeDataStubAndData(_dataStub, data): ActivityItem {
  const dataStub = clone(_dataStub);
  dataStub.content = Base64Decode(data.bytedata);
  dataStub.editable = data.editable;
  dataStub.deletable = data.deletable;
  dataStub.manageable = data.manageable;
  dataStub.readable = data.readable;

  if (data.mimetype === HTML_MIMETYPE) {
    dataStub.htmlString = Base64Decode(data.bytedata);
  }

  return dataStub;
}

export async function apiFetchSingleActivityItem(
  caseUuid: string,
  activityItemUuid: string
): Promise<ActivityItem> {
  /**
   * nb. what we're doing here is that as well as fetching the data directly, we retreive the data stub that is returned from `/case/{uuid}/data`,
   * And then do the merge logic
   *
   * __this may not be necessary__ but it's just to keep this consistent with what is returned from apiFetchCaseActivity
   */
  const [caseDataStubsResult, dataResult] = await Promise.all([
    apiFetchCaseActivityDataStubs(caseUuid),
    CydarmFetch.cyFetchAuthenticated(`/data/${activityItemUuid}`)
  ]);

  const allStubsMap = [
    ...caseDataStubsResult.json.case_actions_data,
    ...caseDataStubsResult.json.case_data,
    ...caseDataStubsResult.json.playbook_actions_data
  ].reduce((acc, cur) => {
    acc.set(cur.uuid, cur);
    return acc;
  }, new Map<string, any>());

  const originalStub = allStubsMap.get(activityItemUuid);

  if (!originalStub) {
    throw new Error(
      `No dataStub found for uuid '${activityItemUuid}' on caseUuid '${caseUuid}`
    );
  }

  return mergeDataStubAndData(originalStub, dataResult.json);
}

export async function apiFetchCaseActivityDataStubs(caseUuid: string): Promise<
  CydarmFetch.CyFetchResponse<{
    case_actions_data: Array<DataStub>;
    case_data: Array<DataStub>;
    playbook_actions_data: Array<DataStub>;
  }>
> {
  return CydarmFetch.cyFetchAuthenticated(`/case/${caseUuid}/data`);
}

export async function apiFetchCaseActivity(
  caseUuid: string,
  includeHidden = false
): Promise<{
  case_actions_data: Array<ActivityItem>;
  case_data: Array<ActivityItem>;
  playbook_actions_data: Array<ActivityItem>;
}> {
  const result = await apiFetchCaseActivityDataStubs(caseUuid);

  const dataStubsMap = {};

  const flatResults = Object.values(result.json)
    .flatMap((v) => v)
    .filter((v) => {
      if (includeHidden) {
        return true;
      }
      return !v.hidden;
    });

  flatResults.forEach((v) => {
    dataStubsMap[v.uuid] = v;
    dataStubsMap[v.uuid].dataStubUuid = v.uuid;
  });

  // Warning! There is an API requirement that you need to provide the uuid multiple times for editing data items.
  // https://cydarm.slack.com/archives/C033ZKMMAJ1/p1673235873987779
  // There will be a bug right now, where in certain circumstances if the multiple uuids straddle the batching cut off, it'll error
  const nonFileDataStubUuids = flatResults
    // this needs to match the condition in CaseActivityContentPreviewImage.tsx
    .filter(
      (d) => !inlineImageWhitelist.has(d.mimetype) && shouldShowPreview(d)
    )
    .map((v) => v.uuid);

  const uuidBatches = batchUuidStrings(nonFileDataStubUuids);

  const proms = uuidBatches.map((v) => apiFetchCaseDatasetTz(v, ''));

  const promsResult = await Promise.all(proms);
  const promsResults2 = promsResult.flatMap((v) => v.json);
  promsResults2.forEach((v) => {
    const dataStub = dataStubsMap[v.dataStubUuid];
    const mergedDataStub = mergeDataStubAndData(dataStub, v);
    dataStubsMap[v.dataStubUuid] = mergedDataStub;
  });

  const resultMap = Object.entries(result.json).reduce((acc, cur, index) => {
    const [key, dataStubValues] = cur;

    acc[key] = dataStubValues
      .map((v, i) => {
        if (!v.hidden || includeHidden) {
          const value = dataStubsMap[v.uuid];
          return value;
        }
        return null;
      })
      .filter((v) => !!v);
    return acc;
  }, {});

  return resultMap as {
    case_actions_data: Array<ActivityItem>;
    case_data: Array<ActivityItem>;
    playbook_actions_data: Array<ActivityItem>;
  };
}

export async function apiFetchCaseDatasetTz(
  uuidList: string,
  encodedSelectedTz: string
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/data?uuids=${uuidList}&timezone=${encodedSelectedTz}`
  );
}

export type CydApi_AddCaseActivityResponse = ICaseData;

export async function apiAddCaseData(
  caseUuid: string,
  dataFile: ({ acl: Acl } | { acl_uuid: string }) & Record<string, unknown>,
  parentUuid?: string
): Promise<CydarmFetch.CyFetchResponse<CydApi_AddCaseActivityResponse>> {
  const newData = {
    ...dataFile,
    //@ts-expect-error
    acl_uuid: 'acl' in dataFile ? dataFile.acl.uuid : dataFile.acl_uuid
  };

  const caseDataPath = `/case/${caseUuid}/data`;
  return CydarmFetch.cyFetchAuthenticated(
    caseDataPath + (parentUuid ? `/${parentUuid}` : ''),
    {
      method: 'POST',
      body: JSON.stringify(newData)
    }
  );
}

export type CydApi_StixDataUploadBody = {
  data: string;
  fileLastMod: number;
  fileName: string;
  mimeType: string;
  bundleAcl: Acl;
  significance: string;
};

export async function apiAddCaseStixPath(
  caseDataUuid: string,
  dataFile: CydApi_StixDataUploadBody,
  parentUuid?: string
): Promise<CydarmFetch.CyFetchResponse<CydApi_AddCaseActivityResponse>> {
  const { bundleAcl, ...rest } = dataFile;
  const newData = {
    ...rest,
    acl_uuid: bundleAcl.uuid
  };
  const caseStixPath = `/case/${caseDataUuid}/stix${
    parentUuid ? `/${parentUuid}` : ''
  }`;
  return CydarmFetch.cyFetchAuthenticated(caseStixPath, {
    method: 'POST',
    body: JSON.stringify(newData)
  });
}

// converts the payload to a JSON:API compliant object
export function generateCreateCaseDataPostBody(
  payload: ICreateCaseDataRequest
) {
  const jsonApiPostBody: CaseDataRequestTopLevel = {
    data: {
      type: 'case-data',
      attributes: {
        mime_type: payload.mimeType,
        payload: Base64Encode(payload.data),
        significance: payload.significance,
        file_name: payload.fileName,
        data_source: 'internal',
        encryption_key: '',
        hidden: false
      },
      relationships: {
        acl: {
          data: {
            type: 'acl',
            id: payload.acl_uuid
          },
          meta: {}
        },
        parent_case: {
          data: {
            type: 'case',
            id: payload.caseUuid
          }
        }
      }
    }
  };
  const relationships = jsonApiPostBody.data?.relationships;
  if (payload.parentUuid && relationships) {
    relationships.parent_data = {
      data: {
        type: 'case-data',
        id: payload.parentUuid
      }
    };
  }
  return jsonApiPostBody;
}

export async function apiBulkIocUpload(
  payload: ICreateCaseDataRequest
): Promise<CyFetchResponse<CaseDataTopLevel>> {
  const jsonApiPayload = generateCreateCaseDataPostBody(payload);
  return cyFetchAuthenticated('/v0.9/case-data', {
    method: 'POST',
    body: JSON.stringify(jsonApiPayload),
    headers: new Headers({
      'Content-Type': 'application/vnd.api+json'
    })
  });
}

export async function apiUploadFileToCase(payload: {
  caseUuid: string;
  parentUuid: string | null;
  data: unknown;
  fileName: string;
}) {
  return await apiBaseUploadFile({
    url: `/cydarm-api/case/${payload.caseUuid}/data${
      payload.parentUuid ? `/${payload.parentUuid}` : ''
    }`,
    data: payload.data,
    fileName: payload.fileName
  });
}
