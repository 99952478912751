/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import * as ApiServices from '../services/index';

export function createApiServices(
  services: Partial<typeof ApiServices>
): typeof ApiServices {
  return Object.keys(ApiServices).reduce(
    (acc, cur) => {
      if (services[cur]) {
        acc[cur] = services[cur];
      } else {
        acc[cur] = async () => {
          throw new Error(`${cur} not initalised!"`);
        };
      }
      return acc;
    },
    {} as typeof ApiServices
  );
}
