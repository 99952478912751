import { IStore } from 'states/store.interface';

export const notificationStoreSelector = ({ notificationStore }: IStore) =>
  notificationStore;

export const notificationListSelector = ({
  notificationStore: { notifications }
}: IStore) => [...notifications].reverse();

export const notificationDrawerStateSelector = ({
  notificationStore: { notificationDrawerOpen }
}: IStore) => notificationDrawerOpen;
