/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Grid } from '@mui/material';
import 'easymde/dist/easymde.min.css';

import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydFileUploader } from 'components/_formElements/CydFileUploader/CydFileUploader';
import { useEffect, useRef, useState } from 'react';
import { AddCaseActivityFormCommentPayload } from 'interface/Case.interface';

interface ICaseDataFormProps {
  formData: AddCaseActivityFormCommentPayload;
  files?: Array<File>;
  onFilesAdded?: (files: Array<File>) => void;
  isFullscreen?: boolean;
}

export const CaseDataForm: React.FC<ICaseDataFormProps> = ({
  formData,
  files,
  onFilesAdded,
  isFullscreen
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // working out the height (exluding the markdown editor) due to the markdown editor is the only element that will have dynamic height
  const [
    totalHeightExcludeMarkdownEditor,
    setTotalHeightExcludeMarkdownEditor
  ] = useState<number>(0);

  useEffect(() => {
    if (!ref.current?.parentElement) {
      return;
    }
    const PADDING = 16; // 8px padding on top and bottom of the markdown editor
    setTotalHeightExcludeMarkdownEditor(
      window.innerHeight - ref.current?.parentElement.clientHeight + PADDING
    );
  }, [isFullscreen]);

  if (!formData) {
    // This is a hacky fix for a synchronisation issue for RTL tests. This should never fire in production.
    // Basically in RTL the form data is briefly null before receiving the set value.
    // However, it doesn't seem to happen in the real browser.
    console.warn(
      'Warning, no form data found for the comment, returning null...'
    );
    return null;
  }

  return (
    <Grid
      ref={ref}
      container
      spacing={2}
      alignItems="stretch"
      css={css`
        height: ${isFullscreen
          ? `calc(100vh - ${totalHeightExcludeMarkdownEditor}px)`
          : '250px'};
        > div {
          padding-top: 0;
        }
      `}
    >
      <Grid item xs={8} height={'100%'}>
        <CydEnhancedMarkdownEditor
          name="comment-content"
          label="Comment Content"
          initialValue={formData.data.mde}
        />
      </Grid>
      <Grid
        item
        xs={4}
        css={(theme) => `
          height: 100%;
          &&& {
            padding-bottom: ${theme.spacing(2)};
          }
        `}
      >
        <CydFileUploader
          css={css`
            height: 100%;
            margin-top: 0;
            overflow-y: auto;
          `}
          name="files"
          multiple
          files={files}
          onFilesAdded={onFilesAdded}
        />
      </Grid>
    </Grid>
  );
};
