/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { PropsWithChildren, ReactElement, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { DashboardPage } from 'pages/DashboardPage';
import { LoginPage } from 'pages/LoginPage';
import { CaseListPage } from 'pages/CaseListPage';
import { PlaybooksPage } from 'pages/PlaybooksPage';
import { LoadingScreen } from 'components/LoadingScreen';
import { SettingsPage } from 'pages/SettingsPage';
import { QuickSearchPage } from 'pages/QuickSearchPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { SOCManagerDashboardPage } from 'pages/SOCManagerDashboardPage';
import ReviewPage from 'pages/ReviewPage/ReviewPage';
import { ChartsPage } from 'pages/ChartsPage';
import { CydarmRoute } from 'interface/CydarmRoute';
import { CydPageLayoutFrame } from 'components/_layout/CydPageLayoutFrame/CydPageLayoutFrame';
import { WikiPage } from 'pages/WikiPage/WikiPage';
import { PlaybooksPageSinglePlaybook } from 'pages/PlaybooksPage/PlaybooksPagePlaybooks/PlaybooksPageSinglePlaybook';
import { CaseViewPagePlaybooks } from 'pages/CaseViewPage/CaseViewPagePlaybooks';
import { CaseViewPageReport } from 'pages/CaseViewPage/CaseViewPageReport';
import { CaseViewPageStix } from 'pages/CaseViewPage/CaseViewStix';
import { CydCaseViewPlaybooks } from 'pages/CaseViewPage/CydCaseViewPlaybooks/CydCaseViewPlaybooks';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { CaseViewPageActivities } from 'pages/CaseViewPage/CaseViewPageActivities/CaseViewPageActivities';
import { CaseViewPage } from 'pages/CaseViewPage';
import { PlaybooksPageActions } from 'pages/PlaybooksPage/PlaybooksPageActions';
import { PlaybooksPagePlaybooks } from 'pages/PlaybooksPage/PlaybooksPagePlaybooks';
import { SettingsPageRoutes } from 'pages/SettingsPage/SettingsPageRoutes';
import { WikiPageData } from 'pages/WikiPage/WikiPageData';
import { isAuthenticated } from 'utils/TokenManager';
import { useDefaultAclForOrg } from 'hooks/AclHooks';

/**
 * This page is an old path that is no longer being used
 * see ticket: https://cydarm.atlassian.net/browse/RM-3121
 * import { HomePage } from 'pages/HomePage';
 */

type RequireAuthProps = PropsWithChildren<{
  location: any;
  isAuthed: boolean;
}>;
export function RequireAuth(props: RequireAuthProps) {
  return props.isAuthed ? (
    ((props.children || null) as ReactElement)
  ) : (
    <Navigate
      to={`${CydarmRoute.AUTH}?redirect=${props.location.pathname}${props.location.search}`}
    />
  );
}

const UNAUTHED_ROUTES = [CydarmRoute.AUTH, CydarmRoute.HOME] as Array<string>;

/**
 * This is an empty component that will exist on any authed routes.
 *
 * Its purpose is to call any data loading function before they are needed.
 * @returns
 */
function AuthedDataPreloader() {
  useDefaultAclForOrg();
  return null;
}

function OurRouter() {
  const isAuthed = isAuthenticated();
  const { isToggleEnabled } = useFeatureToggles();

  const useNewPlaybooks = isToggleEnabled('REACT_APP_USE_NEW_CASE_PLAYBOOKS');

  const location = useLocation();

  if (!UNAUTHED_ROUTES.includes(location.pathname) && !isAuthed) {
    return (
      <Navigate
        to={`${CydarmRoute.AUTH}?redirect=${location.pathname}${location.search}`}
      />
    );
  }

  return (
    <CydPageLayoutFrame isAuthenticated={isAuthed}>
      {isAuthed && <AuthedDataPreloader />}
      <Routes>
        <Route path={CydarmRoute.AUTH} element={<LoginPage />} />
        <Route
          path={CydarmRoute.HOME}
          element={<Navigate to={CydarmRoute.AUTH} replace={true} />}
        />

        <Route path={CydarmRoute.DASHBOARD} element={<DashboardPage />} />

        <Route path={CydarmRoute.CASE_LIST} element={<CaseListPage />} />

        <Route
          path={CydarmRoute.CASE_VIEW}
          element={
            <>
              <CaseViewPage />
            </>
          }
        >
          <Route
            path={`playbooks`}
            element={
              <>
                {useNewPlaybooks ? (
                  <CydCaseViewPlaybooks />
                ) : (
                  <CaseViewPagePlaybooks />
                )}
              </>
            }
          />

          <Route path={`report`} element={<CaseViewPageReport />} />

          <Route path={`graph`} element={<CaseViewPageStix />} />

          <Route index element={<CaseViewPageActivities />} />
        </Route>

        <Route
          path={CydarmRoute.QUICK_SEARCH}
          element={<QuickSearchPage key={location.key} />}
        />

        <Route path={CydarmRoute.SETTINGS} element={<SettingsPage />}>
          <>
            {SettingsPageRoutes.map((page) => {
              const Component = page.component;

              const path = page.url.split('/settings/')[1];

              return (
                <Route path={path} key={page.url} element={<Component />} />
              );
            })}

            <Route
              path="*"
              element={<NotFoundPage isAuthenticated={isAuthed} />}
            />
          </>
        </Route>

        <Route path={CydarmRoute.REVIEW} element={<ReviewPage />} />

        <Route
          path={CydarmRoute.MANAGE_DASHBOARD}
          element={<SOCManagerDashboardPage />}
        />

        <Route
          path={CydarmRoute.PLAYBOOKS}
          element={
            <>
              <PlaybooksPage />

              {/* This seems sketchy, but  */}
              {!location.pathname.endsWith('/actions') && (
                <PlaybooksPagePlaybooks />
              )}
            </>
          }
        >
          <Route
            path={`:playbookUuid`}
            element={<PlaybooksPageSinglePlaybook />}
          />

          <Route path={`actions`} element={<PlaybooksPageActions />} />
        </Route>

        <Route path={CydarmRoute.REPORT} element={<>Report</>} />
        <Route path={CydarmRoute.CHARTS} element={<ChartsPage />} />
        <Route path={CydarmRoute.WIKI} element={<WikiPage />}>
          <Route path=":wikiUuid" element={<WikiPageData />} />
        </Route>

        <Route
          path={'/*'}
          element={<NotFoundPage isAuthenticated={isAuthed} />}
        />
      </Routes>
    </CydPageLayoutFrame>
  );
}

export default () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <OurRouter />
    </Suspense>
  );
};
