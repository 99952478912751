import * as CydarmFetch from 'utils/CydarmFetch';

export type CydApi_UserAction = {
  Case: string;
  Playbook: string;
  PlaybookPrecedence: number;
  actionUuid: string;
  position: number;
  actionName: string;
  actionDescription: string;
  assigneeUuid: string;
  assignedAt: string;
  status: string;
  statusAt: string;
  statusMsg: string;
  created: string;
  variable_bindings: {};
  tags: null;
  case_playbook_uuid: string;
  actionInstanceUuid: string;
};

// userActions
export async function apiFetchUserActions(
  uuid: string
): Promise<CydarmFetch.CyFetchResponse<Array<CydApi_UserAction>>> {
  return CydarmFetch.cyFetchAuthenticated(`/user/${uuid}/action`, {
    method: 'GET'
  });
}

// userProps
export async function apiFetchAllUserProps(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/user-prop/${uuid}`);
}

export async function apiCreateUserProps(
  userUuid: string,
  propName: string,
  propValue: unknown
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/user-prop/${userUuid}/${propName}`,
    {
      method: 'POST',
      body: JSON.stringify(propValue)
    }
  );
}

export async function apiUpdateUserProps(
  userUuid: string,
  propName: string,
  propValue: unknown
) {
  return CydarmFetch.cyFetchAuthenticated(
    `/user-prop/${userUuid}/${propName}`,
    {
      method: 'PUT',
      body: JSON.stringify(propValue)
    }
  );
}

export async function apiRemoveUserProps(userUuid: string, propName: string) {
  return CydarmFetch.cyFetchAuthenticated(
    `/user-prop/${userUuid}/${propName}`,
    {
      method: 'DELETE'
    }
  );
}

// users
export async function apiFetchUsers() {
  return CydarmFetch.cyFetchAuthenticated('/user');
}

export async function apiFetchAllUsers() {
  return CydarmFetch.cyFetchAuthenticated('/user?include_deleted=1');
}

export async function apiFetchUserInfo(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/user/${uuid}`);
}

export async function apiCreateUser(userData: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/user`, {
    method: 'POST',
    body: JSON.stringify(userData)
  });
}

export async function apiUpdateUser(uuid: string, userData: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/user/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(userData)
  });
}

export async function apiUpdateUserPassword(uuid: string, userData: unknown) {
  return CydarmFetch.cyFetchAuthenticated(`/user/${uuid}/password`, {
    method: 'PUT',
    body: JSON.stringify(userData)
  });
}

export async function apiDeleteUser(uuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/user/${uuid}`, {
    method: 'DELETE'
  });
}

export async function apiReactivateUser(userUuid: string) {
  return CydarmFetch.cyFetchAuthenticated(`/user/${userUuid}/reactivate`, {
    method: 'PUT'
  });
}
