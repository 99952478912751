/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydButtonModal } from 'components/_dialogs/CydButtonModal/CydButtonModal';
import { BulkIocFormContent } from './BulkIocFormContent.tsx/BulkIocFormContent';
import { BulkIocLoading } from './BulkIocLoading/BulkIocLoading';
import { BulkIocComplete } from './BulkIocComplete/BulkIocComplete';
import {
  useBulkIocUpload,
  useDefaultDataSignificance,
  useFilteredDataSignificances
} from 'hooks/CaseDataHooks';
import { useCaseAcl, useCurrentDataAcls } from 'hooks/AclHooks';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useCurrentUser } from 'hooks/AuthHooks';
import { User } from 'interface/User.interface';
import { useState } from 'react';
import { useCommentDrawerState } from 'hooks/CaseActivitiesUIHooks';

const PHASE_TO_TITLE_MAP: Record<QueryStatus, string> = {
  uninitialized: 'Upload CSV',
  rejected: 'Upload CSV',
  pending: 'Uploading',
  fulfilled: 'Complete'
};

export type BulkIocUploadProps = {
  caseUuid: string;
};

export const BulkIocUpload = (props: BulkIocUploadProps) => {
  const { caseUuid } = props;
  const signficanceResult = useFilteredDataSignificances();
  const aclsResult = useCurrentDataAcls();
  const currentUser: User | undefined = useCurrentUser().data;
  if (!currentUser) {
    throw new Error('No current user');
  }
  const userUuid = currentUser.uuid;

  //Our options for determining the default
  // Either pass it in from the form data, so we're using the same logic that is used up there
  // Or reproduce the logic here
  // I'm opting for reproducing the logic, in a hook.
  // https://github.com/cydarm/cydarm-frontend/blob/36c696631c236b7714c8bba7c46406b90f5355fb/src/hooks/CaseActivitiesUIHooks.ts#L117-L120

  const defaultSignificanceResult = useDefaultDataSignificance();
  const defaultAclResult = useCaseAcl(caseUuid);

  const [bulkIocUpload, bulkIocUploadResult] = useBulkIocUpload();

  return (
    <BulkIocUploadInner
      aclsResult={aclsResult}
      signficanceResult={signficanceResult}
      defaultAclResult={defaultAclResult}
      defaultSignficanceResult={defaultSignificanceResult}
      caseUuid={caseUuid}
      userUuid={userUuid}
      bulkIocUpload={bulkIocUpload}
      bulkIocUploadResult={bulkIocUploadResult}
      phase={bulkIocUploadResult.status}
      onClose={() => {
        bulkIocUploadResult.reset();
      }}
    />
  );
};

export type BulkIocUploadInnerProps = {
  phase: QueryStatus;
  signficanceResult: ReturnType<typeof useFilteredDataSignificances>;
  aclsResult: ReturnType<typeof useCurrentDataAcls>;
  defaultAclResult: ReturnType<typeof useCaseAcl>;
  defaultSignficanceResult: ReturnType<typeof useDefaultDataSignificance>;
  caseUuid: string;
  userUuid: string;
  bulkIocUpload: ReturnType<typeof useBulkIocUpload>[0];
  bulkIocUploadResult: ReturnType<typeof useBulkIocUpload>[1];
  onClose?: () => void;
};

export const BulkIocUploadInner = (props: BulkIocUploadInnerProps) => {
  const {
    phase,
    signficanceResult,
    aclsResult,
    defaultAclResult,
    defaultSignficanceResult,
    caseUuid,
    userUuid,
    bulkIocUpload,
    bulkIocUploadResult,
    onClose
  } = props;

  const [errorText, setErrorText] = useState('');
  const [, setIsOpened] = useCommentDrawerState();

  return (
    <CydButtonModal
      label="Bulk upload Indicators"
      ButtonProps={{
        variant: 'text'
      }}
      title={PHASE_TO_TITLE_MAP[phase]}
      size="large"
      onClose={() => {
        setErrorText('');
        onClose && onClose();
        setIsOpened(false);
      }}
      renderContent={(closeModalFn) => {
        if (
          signficanceResult.isLoading ||
          aclsResult.isLoading ||
          defaultAclResult.isLoading ||
          defaultSignficanceResult.isLoading
        ) {
          return <LoadingSpinner />;
        }

        if (
          bulkIocUploadResult.isUninitialized ||
          bulkIocUploadResult.isError
        ) {
          return (
            <BulkIocFormContent
              availableSignificances={signficanceResult.data}
              availableAcls={aclsResult.data}
              defaultSelectedAcl={defaultAclResult.data}
              defaultSelectedSignificance={
                defaultSignficanceResult.data ?? signficanceResult.data[0]
              }
              closeModalFn={closeModalFn}
              onSubmit={(payload) => {
                payload.file.text().then((fileContent) => {
                  bulkIocUpload({
                    payload: {
                      caseUuid: caseUuid,
                      acl_uuid: payload.aclUuid,
                      significance: payload.significanceName,
                      data: fileContent,
                      userUuid: userUuid,
                      mimeType: 'application/vnd.cydarm.ioc-bulk+csv',
                      fileName: payload.file.name,
                      setErrorText: setErrorText
                    }
                  });
                });
              }}
              errorMessage={errorText}
            />
          );
        }

        if (bulkIocUploadResult.isLoading) {
          return <BulkIocLoading />;
        }

        if (bulkIocUploadResult.isSuccess) {
          return (
            <BulkIocComplete
              fileName={
                bulkIocUploadResult.data.data?.attributes?.file_name || ''
              }
              onDoneClick={closeModalFn}
            />
          );
        }

        throw new Error('Unhandled result path');
      }}
    />
  );
};
