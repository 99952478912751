/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

export const QuickSearch = ({ children }) => {
  const data = children;
  return (
    <>
      {data
        ? data.map((items) => {
            return items ? (
              <Tooltip title={items.description} key={items.uuid}>
                <li key={items.uuid}>
                  <Link target="_blank" to={`/caseview/${items.uuid}/`}>
                    {items.locator}
                  </Link>
                </li>
              </Tooltip>
            ) : (
              'Loading Data ...'
            );
          })
        : 'Loading Data ...'}
    </>
  );
};

export default QuickSearch;
