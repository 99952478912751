/* eslint-disable no-console */
/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Typography from '@mui/material/Typography';

/** @jsxImportSource @emotion/react */

const StackedInfo = ({ header, info }) => (
  <Typography
    css={(theme) => `
      display: flex;
      flex-direction: column;
      & small {
        color: ${theme.palette.text.secondary};
        font-weight: bold;
      }
      & span {
        font-weight: 300;
      }
    `}
  >
    <small>{header}</small>
    <span>{info ? info : '-'}</span>
  </Typography>
);

export default StackedInfo;
