/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydTimeDisplay } from 'components/_objectDisplay/CydTimeDisplay/CydTimeDisplay';
import { CydUserDisplayById } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplayById';
import React, { useMemo, useRef } from 'react';
import { Acl } from 'interface/Acl.interface';
import { ActionInstanceComment } from 'services/ActionComments';
import { compareDateStrings } from 'utils/StringUtils';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { Markdown } from 'components/Markdown';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';
import { Box } from '@mui/material';
/** @jsxImportSource @emotion/react */

export type CommentsPanelProps = {
  comments: Array<ActionInstanceComment>;
  onAddComment: (newComment: string) => void;

  availableAcls: Array<Acl>;
  onChangeAcl: (acl: Acl) => void;
  selectedAcl: Acl | null;

  //nb. For now, we will say that they can't delete/edit comments
  // In the Playbook Actions Comments area.
};

export const CommentsPanel = (props: CommentsPanelProps) => {
  const { comments, onAddComment, availableAcls, onChangeAcl, selectedAcl } =
    props;

  const sortedComments = useMemo(() => {
    return comments.sort((a, b) => compareDateStrings(a.created, b.created));
  }, [comments]);

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <div>
        {sortedComments.map((v) => {
          return (
            <div key={v.dataStubUuid} data-testid="cyd-comment">
              <div
                css={(theme) => `
                    display: flex;
                    flex-flow: row; 
                    justify-content: flex-start;
                    gap: ${theme.spacing(2)};
                    margin-bottom: ${theme.spacing(3)};
                    align-items: flex-start;
                    `}
              >
                <CydUserDisplayById
                  userId={v.creatoruuid}
                  variant="avatar"
                  css={(theme) => `
                    width: 18px; 
                    height: 18px; 
                  `}
                />
                <CydUserDisplayById userId={v.creatoruuid} />
                <CydTimeDisplay
                  time={v.created}
                  css={(theme) => `
                  font-family: ${theme.typography.caption.fontFamily}; 
                  font-size: ${theme.typography.caption.fontSize}; 
                  color: ${theme.palette.text.secondary};

                  flex: 1 1 auto; 
                `}
                />
              </div>

              <Markdown
                css={(theme) => `
                 margin-left: ${theme.spacing(6)};
                 margin-bottom: ${theme.spacing(6)};

               `}
              >
                {v.content}
              </Markdown>
            </div>
          );
        })}

        <form
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            const commentContent = formData.get('comment')?.toString();
            if (commentContent === undefined) {
              throw new Error('No comment content exists');
            }

            if (commentContent.trim().length > 0) {
              onAddComment(commentContent);
              formRef.current?.reset();
            }
          }}
        >
          <Box height={'300px'} border={'1px'} position={'relative'}>
            <CydEnhancedMarkdownEditor
              autoFocus
              initialValue={''}
              name="comment"
              label="Comment Content"
            />
          </Box>

          {/* Note that this isn't part of the form.
          I'm just not wanting to make that change right now.
        */}
          <CydAclSelector
            required
            availableAcls={availableAcls}
            selectedAclUuid={selectedAcl?.uuid}
            onChange={onChangeAcl}
          />

          <CydButtonStack justifyContent="flex-end">
            <CydButton type="reset" variant="text">
              Cancel
            </CydButton>
            <CydButton type="submit" variant="contained">
              Comment
            </CydButton>
          </CydButtonStack>
        </form>
      </div>
    </>
  );
};
