/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { FC, memo } from 'react';
import { useIframeStyles } from './styles';

interface ICaseActivityHtmlView {
  htmlString: string;
  caseuuid: string | undefined;
  filename?: string;
}

export const CaseActivityHtmlView: FC<ICaseActivityHtmlView> = memo(
  ({ htmlString, caseuuid: caseUuid, filename }) => {
    const { iframeStyles } = useIframeStyles();

    return (
      <div>
        <h3>Displaying HTML {filename}</h3>
        <iframe
          title={`email view ${caseUuid}`}
          sandbox=""
          className={iframeStyles}
          srcDoc={htmlString}
        ></iframe>
      </div>
    );
  }
);
