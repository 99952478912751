/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  fetchCasePlaybooks,
  addPlaybookToCase,
  updateCasePlaybookAction
} from './actions';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import {
  AtcPlaybook,
  CydApi_CasePlaybookInstance,
  PlaybookActionInstance
} from 'interface/Playbook.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICasePlaybooks {
  [index: string]: CydApi_CasePlaybookInstance[];
}

interface ICaseActions {
  [index: string]: PlaybookActionInstance[];
}

interface IRemovePlaybook {
  casePlaybookUuid: string;
  caseUuid: string;
}

export interface ICasePlaybooksStoreState {
  isLoading: boolean;
  isAddedPlaybookToCaseSuccess: boolean;
  isAddingPlaybookToCaseError: boolean;
  isRemovedPlaybookFromCaseSuccess: boolean;
  isRemovingPlaybookFromCaseError: boolean;
  error: ErrorPayload | null;
  casePlaybooks: ICasePlaybooks;
  caseActions: ICaseActions;
  atcPlaybook: AtcPlaybook | null;
}

export const initialState: ICasePlaybooksStoreState = {
  casePlaybooks: {},
  caseActions: {},
  isLoading: false,
  error: null,
  atcPlaybook: null,
  isAddedPlaybookToCaseSuccess: false,
  isAddingPlaybookToCaseError: false,
  isRemovedPlaybookFromCaseSuccess: false,
  isRemovingPlaybookFromCaseError: false
};

//to do, dear god it must be split into different files and refactored
const casePlaybooksSlice = createSlice({
  name: 'casePlaybooks',
  initialState,
  reducers: {
    addPlaybookToCaseSuccess: (state) => ({
      ...state,
      isLoading: false,
      isAddingPlaybookToCaseError: false,
      isAddedPlaybookToCaseSuccess: true
    }),

    addPlaybookToCaseFailed: (state) => ({
      ...state,
      isLoading: false,
      isAddingPlaybookToCaseError: true,
      isAddedPlaybookToCaseSuccess: false
    }),

    fetchCasePlaybooksSuccess: (
      state,
      { payload }: PayloadAction<ICasePlaybooks>
    ) => {
      state.casePlaybooks = payload;
    },

    removePlaybookFromCaseSuccess: (
      state,
      { payload }: PayloadAction<IRemovePlaybook>
    ) => {
      state.isRemovedPlaybookFromCaseSuccess = true;
      state.isRemovingPlaybookFromCaseError = false;
      state.casePlaybooks[payload.caseUuid] = (
        state.casePlaybooks[payload.caseUuid] || []
      ).filter(
        (playbook) => playbook.casePlaybookUuid !== payload.casePlaybookUuid
      );
    },

    removePlaybookFromCaseFailed: (state) => ({
      ...state,
      isRemovedPlaybookFromCaseSuccess: false,
      isRemovingPlaybookFromCaseError: true
    })
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchCasePlaybooks, (state) => {
        state.isLoading = true;
      })

      .addCase(addPlaybookToCase, (state) => {
        state.isLoading = true;
      })

      .addCase(updateCasePlaybookAction, (state, { payload }) => {
        const { actionInstance } = payload;
        const updateCaseUuid = actionInstance.Case;
        if (!state.casePlaybooks[updateCaseUuid!]) {
          throw new Error(`casePlaybook ${updateCaseUuid} not found`);
        }
        const newCasePlaybooks = state.casePlaybooks[updateCaseUuid!].map(
          (playbook) => {
            if (playbook.playbookUuid === actionInstance.Playbook) {
              return {
                ...playbook,
                action_statuses: playbook.action_statuses.map(
                  (actionStatus) => {
                    if (actionStatus.actionUuid === actionInstance.actionUuid) {
                      return actionInstance;
                    }
                    return actionStatus;
                  }
                )
              };
            }
            return playbook;
          }
        );
        state.casePlaybooks[updateCaseUuid] = newCasePlaybooks;
      })
});

export const {
  reducer: casePlaybooks,
  actions: {
    addPlaybookToCaseSuccess,
    addPlaybookToCaseFailed,
    fetchCasePlaybooksSuccess,
    removePlaybookFromCaseSuccess,
    removePlaybookFromCaseFailed
  }
} = casePlaybooksSlice;
