/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { useMemo } from 'react';
import {
  displayableSyntaxHighlighting,
  displayableSyntaxHighlightingExtension
} from 'utils/CaseDataUtils';
import { useJsonSyntaxStyle } from './SysPropsHooks';

export function determineLanguageFromMimeTypeOrFileName(
  mimetype: string,
  filename: string
): string | null {
  if (displayableSyntaxHighlighting.has(mimetype)) {
    return mimetype;
  }
  const fileExtension = filename
    ? filename.slice(Math.max(0, filename.lastIndexOf('.')) || Infinity)
    : '';
  if (displayableSyntaxHighlightingExtension.has(fileExtension)) {
    return fileExtension;
  }
  return null;
}

export const useDisplayAsJsonYaml = (content) => {
  const jsonSyntaxStyle = useJsonSyntaxStyle();
  const displayAsYaml = useMemo(() => {
    if (jsonSyntaxStyle.toLowerCase() !== 'yaml') {
      return false;
    }
    try {
      JSON.parse(content);
    } catch (e) {
      return false;
    }
    return true;
  }, [content, jsonSyntaxStyle]);
  return displayAsYaml;
};

export const useDisplayAsJsonTable = (content) => {
  const jsonSyntaxStyle = useJsonSyntaxStyle();
  const displayAsTable = useMemo(() => {
    if (jsonSyntaxStyle.toLowerCase() !== 'table') {
      return false;
    }
    try {
      JSON.parse(content);
    } catch (e) {
      return false;
    }
    return true;
  }, [content, jsonSyntaxStyle]);
  return displayAsTable;
};
