/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';
import { customScrollbar } from 'utils/StyleUtils';

export const useCasePlaybookListStyles = makeStyles(
  ({ spacing, shape: { borderRadius } }) => ({
    listContainer: {
      padding: spacing(2),
      height: '100%',
      maxHeight: 'calc(100% - 60px)',
      position: 'relative',
      overflow: 'auto',
      ...customScrollbar(borderRadius)
    }
  })
);

export const useCasePlaybookListItemStyles = makeStyles(({ spacing }) => ({
  itemContainer: {
    marginBottom: spacing(9)
  },
  itemTitle: {
    fontSize: 24
  },
  itemHeader: {
    marginBottom: spacing(3),
    alignItems: 'center'
  },
  actionsHeading: {
    marginBottom: spacing(2),
    paddingLeft: spacing(4)
  },
  itemActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(2)
  }
}));

export const useCasePlaybookListItemActionStyles = makeStyles(
  ({ spacing, palette: { text, background } }) => ({
    actionContainer: {
      borderBottom: `1px solid ${text.primary}`,
      paddingTop: spacing(1.25),
      paddingBottom: spacing(1.25),
      paddingLeft: spacing(8),
      position: 'relative',
      '&:first-child': {
        borderTop: `1px solid ${text.primary}`
      }
    },
    actionIcon: {
      position: 'absolute',
      left: 0
    },
    actionTitle: {
      fontSize: 16
    },
    actionDescription: {
      marginTop: spacing(2),
      fontSize: 14,
      marginRight: spacing(3),
      wordBreak: 'break-word'
    },
    assigneeSelect: {
      backgroundColor: background.paper,
      color: text.primary
    },
    isCompletedWrapper: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);
