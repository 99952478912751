/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydButtonModal } from 'components/_dialogs/CydButtonModal/CydButtonModal';
import React from 'react';

import { Typography } from '@mui/material';

import { Acl } from 'interface/Acl.interface';
import { CreatePlaybookData, CreatePlaybookForm } from './CreatePlaybookForm';

export type CreatePlaybookModalProps = {
  onAtcCreate: (data: CreatePlaybookData) => void;
  acls: Array<Acl>;
  defaultAcl: Acl | null;
};

export const CreatePlaybookModal = (props: CreatePlaybookModalProps) => {
  const { onAtcCreate, acls, defaultAcl } = props;

  return (
    <CydButtonModal
      label="Create a new playbook"
      ButtonProps={{
        startIcon: 'add'
      }}
      size="xlarge"
      renderContent={(closeModalFn) => {
        return (
          <div>
            <Typography variant="h4" gutterBottom>
              Create New ATC Playbook
            </Typography>
            <CreatePlaybookForm
              defaultAcl={defaultAcl}
              onSubmit={(data) => {
                onAtcCreate(data);
                closeModalFn();
              }}
              availableAcls={acls}
            />
          </div>
        );
      }}
    />
  );
};
