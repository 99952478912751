/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import config from 'config';
import { useCaseViewPageActivitiesStyles } from 'pages/CaseViewPage/CaseViewPageActivities/styles';
import {
  useRef,
  useLayoutEffect,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCommentDrawer,
  setManiplulatingCaseItem,
  updateCommentMode
} from 'states/caseThreadDrawer/slice';
import {
  commentDrawerSelector,
  commentModeSelector,
  manipulatingCaseItemSelector
} from 'states/caseThreadDrawer/selectors';
import { scrollToElement } from 'utils/CaseDataUtils';
import { useDataAcls } from './AclHooks';
import { useFilteredDataSignificances } from './CaseDataHooks';

import { useSingleCaseActivityItem } from './CaseActivityHooks';
import { Case } from 'interface/Case.interface';

export const useCommentMode = () => useSelector(commentModeSelector);
export const useCommentDrawerOpenState = () =>
  useSelector(commentDrawerSelector);
export const useManipulatingCaseItemUuid = () =>
  useSelector(manipulatingCaseItemSelector);

export const useIsCommentHighlighted = (caseUuid) => {
  const caseBeingManipulated = useManipulatingCaseItemUuid();
  const mode = useCommentMode();
  return useMemo(
    () => mode === 'reply' && caseUuid === caseBeingManipulated,
    [mode, caseBeingManipulated, caseUuid]
  );
};

export const useCommentDrawerState = (): [boolean, (a) => void] => {
  const dispatch = useDispatch();
  const currentState = useCommentDrawerOpenState();
  return [
    currentState,
    useCallback(
      (state = !currentState) => {
        dispatch(setCommentDrawer(state));
      },
      [currentState, dispatch]
    )
  ];
};

export const useCommentModeState = (): [string, (a) => void] => {
  const dispatch = useDispatch();
  const currentState = useCommentMode();
  return [
    currentState,
    useCallback(
      (state) => {
        dispatch(updateCommentMode(state));
      },
      [dispatch]
    )
  ];
};

export const useManipulatingCaseItemUuidState = (): [
  string | undefined,
  (a) => void
] => {
  const dispatch = useDispatch();
  const currentState = useManipulatingCaseItemUuid();
  return [
    currentState,
    useCallback(
      (state) => {
        dispatch(setManiplulatingCaseItem(state));
      },
      [dispatch]
    )
  ];
};

export const useFormDataState = (
  caseDetails: Case
): [any, React.Dispatch<React.SetStateAction<{}>>] => {
  const [formData, setFormData]: any = useState({});
  const { data: acls } = useDataAcls();
  const { data: dataSignificances } = useFilteredDataSignificances();
  const isOpened = useCommentDrawerOpenState();
  const mode = useCommentMode();
  const manipulatingCase = useManipulatingCaseItemUuid();

  const editingActivityQuery = useSingleCaseActivityItem(
    caseDetails.uuid,
    manipulatingCase || null
  );

  const editingActivityAcl = editingActivityQuery.data?.acl;
  const editingActivityDataSigs = editingActivityQuery.data?.significance;
  const editingActivityMde = editingActivityQuery.data?.content;

  useEffect(() => {
    var newFormData: any = {
      acl: acls.find(({ uuid }) => uuid === caseDetails.acl),
      significance: dataSignificances.find(
        ({ precedence }) => precedence === config.DEFAULT_DATA_SIGNIFICANCE
      )
    };

    if (mode === 'edit') {
      if (!editingActivityMde) {
        return;
      }
      newFormData = {
        acl: acls.find(({ uuid }) => uuid === editingActivityAcl),
        significance: dataSignificances.find(
          ({ name }) => name === editingActivityDataSigs
        ),
        mde: editingActivityMde
      };
    }

    if (mode === 'comment' || mode === 'reply') {
      newFormData = { ...newFormData, mde: '' };
    }

    setFormData((prevState) =>
      isOpened ? { ...prevState, ...newFormData, mode } : newFormData
    );
  }, [
    mode,
    isOpened,
    manipulatingCase,
    caseDetails.acl,
    dataSignificances,
    acls,
    editingActivityAcl,
    editingActivityDataSigs,
    editingActivityMde
  ]);
  return [formData, setFormData];
};

export const useScrollOnOpenDrawer = (anchorRef, containerRef) => {
  const [isOpen] = useCommentDrawerState();
  const mode = useCommentMode();
  const modeRef = useRef(mode);
  modeRef.current = mode;
  useEffect(() => {
    if (
      !isOpen ||
      !anchorRef.current ||
      !containerRef.current ||
      modeRef.current === 'reply'
    ) {
      return;
    }
    setTimeout(
      () =>
        scrollToElement(anchorRef.current, containerRef.current, true, -300),
      3
    );
  }, [isOpen, anchorRef, containerRef, modeRef]);
};

const MINIMUM_BOTTOM_PROXIMITY = 400;
export const useScrollToBottom = (caseLength) => {
  const containerRef = useRef<any>();
  const anchorRef = useRef<any>();
  const touched = useRef<boolean>(false);
  const mode = useCommentMode();

  useScrollOnOpenDrawer(anchorRef, containerRef);
  useLayoutEffect(() => {
    let prevScroll = 0;

    const scrollBottom = (animate = false) => {
      if (touched.current || mode === 'reply') {
        return;
      }

      if (!containerRef.current) {
        return;
      }

      containerRef.current.scroll({
        top: anchorRef.current.offsetTop,
        behavior: animate ? 'smooth' : 'auto'
      });
    };

    const spamScroller = setInterval(scrollBottom, 10);
    const handleOnScroll = () => {
      if (!containerRef.current) {
        return;
      }
      let currentScroll = containerRef.current.scrollTop;
      if (prevScroll > currentScroll) {
        touched.current = true;
        cleanUp();
      }
      prevScroll = currentScroll;
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleOnScroll);
    }

    const cleanUp = () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleOnScroll);
      }
      clearInterval(spamScroller);
    };
    return () => cleanUp();
  }, [mode]);

  useLayoutEffect(() => {
    if (caseLength === 0 || !touched.current) {
      return;
    }
    const element = containerRef.current;

    const isNearBottom =
      element.scrollHeight - element.scrollTop - element.clientHeight <=
      MINIMUM_BOTTOM_PROXIMITY;

    if (!isNearBottom && touched.current) {
      return;
    }

    //delay the scrolling down, leaving time for images and such to properly render.
    //this is a hack, hopefully in the future we can implement some form of listening to when the image has loaded before scrolling.
    setTimeout(() => {
      containerRef.current.scroll({
        top: anchorRef.current.offsetTop,
        behavior: 'smooth'
      });
    }, 500);
  }, [caseLength]);

  return { containerRef, anchorRef };
};

export const useDrawerHeightStyles = (formData) => {
  const drawerRef = useRef<any>(null);
  const [shouldShowActivityDrawer] = useCommentDrawerState();
  const [mode] = useCommentModeState();
  const [height, setHeight] = useState();

  const { activitiesWrapper } = useCaseViewPageActivitiesStyles({
    shouldShowActivityDrawer,
    height
  });

  useLayoutEffect(() => {
    if (drawerRef.current) {
      setHeight(drawerRef.current?.offsetHeight + 50);
    }
  }, [mode, shouldShowActivityDrawer, drawerRef, formData.data]);

  return [drawerRef, activitiesWrapper];
};
