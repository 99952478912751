/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydMenu } from 'components/_dialogs/CydMenu/CydMenu';
import { CydarmRoute } from 'interface/CydarmRoute';
import React, { useState } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { CydFeatureTogglesModal } from './CydFeatureTogglesModal';
export type CydUserAvatarProps = {
  onSignoutClick: () => void;
  version: string;
  className?: string;
};

export const CydUserAvatar = (props: CydUserAvatarProps) => {
  const { onSignoutClick, version, className } = props;

  const [featureTogglesOpen, setFeatureTogglesOpen] = useState(false);

  const { isToggleEnabled } = useFeatureToggles();

  const [secretDebugModeClickCount, setSecretDebugModeClickCount] = useState(0);
  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <CydFeatureTogglesModal
        isOpen={featureTogglesOpen}
        onClose={() => setFeatureTogglesOpen(false)}
      />

      <CydMenu
        variant="icon-button"
        label="profile"
        icon="person"
        menuItems={[
          {
            label: `Version: ${version}`,

            ...(isToggleEnabled('REACT_APP_DEBUG_MODE') ||
            secretDebugModeClickCount > 2
              ? {
                  onClick: () => setFeatureTogglesOpen(true),
                  icon: 'debug'
                }
              : {
                  onClick: () => {
                    setSecretDebugModeClickCount(secretDebugModeClickCount + 1);
                  }
                })
          },
          {
            label: 'Help',
            onClick: () => {
              window.open('https://support.cydarm.com/');
            }
          },
          {
            label: 'Send Feedback',
            onClick: () => {
              window.open('mailto:support@cydarm.com?subject=Feedback');
            }
          },
          {
            label: 'Settings',
            link: `${CydarmRoute.SETTINGS}/profile`
          },
          {
            label: 'Logout',
            onClick: onSignoutClick
          }
        ]}
      />
    </div>
  );
};
