/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchUserActions } from './actions';
import { PlaybookActionInstance } from 'interface/Playbook.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserAction {
  [index: string]: PlaybookActionInstance[];
}
export interface IUserActionsStoreState {
  userActions: IUserAction;
  isLoading?: boolean;
}

export const initialState: IUserActionsStoreState = {
  userActions: {},
  isLoading: false
};

const userActionSlice = createSlice({
  name: 'userActions',
  initialState,
  reducers: {
    fetchUserActionsSuccess: (
      state,
      { payload }: PayloadAction<IUserAction>
    ) => {
      state.isLoading = false;
      state.userActions = { ...state.userActions, ...payload };
    },
    fetchUserActionsFailed: (state) => {
      state.isLoading = false;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchUserActions, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: userActionsStore,
  actions: { fetchUserActionsSuccess, fetchUserActionsFailed }
} = userActionSlice;
