/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Case } from 'interface/Case.interface';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { sortCases } from 'utils/CaseUtils';
import { Link } from '@mui/material';

type CydCaseGroupListProps = {
  cases: Array<Case>;
  emptyMessage?: string;
  onDelete: (caseUuid: string) => void;
  isDeletable?: boolean;
};

const DEFAULT_EMPTY_MESSAGE = 'No cases';

export const CydCaseGroupList = withErrorBoundary(
  ({
    cases,
    emptyMessage = DEFAULT_EMPTY_MESSAGE,
    onDelete,
    isDeletable
  }: CydCaseGroupListProps) => {
    if (!cases) {
      throw new Error('cases is not defined');
    }
    if (cases.length === 0) {
      return (
        <Typography variant="body2" color="text.secondary">
          {emptyMessage}
        </Typography>
      );
    }
    const sortedCases = sortCases(cases, 'locator' as keyof Case, 'asc');
    //todo: possibly make a proper CydCaseDisplay comeponent for linked locator
    return (
      <div>
        {sortedCases.map((c) => (
          <Grid container key={c.uuid} alignItems="center">
            <Grid item xs={3}>
              <Link underline="none" href={c.uuid}>
                {c.locator}
              </Link>
            </Grid>
            <Grid item xs={7}>
              {c.description}
            </Grid>
            <Grid item xs={2} justifyContent="flex-end">
              <CydIconButton
                label="Remove"
                icon="close"
                disabled={!isDeletable}
                onClick={() => onDelete(c.uuid)}
              />
            </Grid>
          </Grid>
        ))}
      </div>
    );
  },
  (props) => <div>{props.children}</div>,
  ({ emptyMessage = DEFAULT_EMPTY_MESSAGE }) => (
    <Typography variant="body2" color="text.secondary">
      {emptyMessage}
    </Typography>
  )
);
