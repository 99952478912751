/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Divider, MenuItem, MenuList } from '@mui/material';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CydIconButton } from 'components/_formElements/CydIconButton/CydIconButton';
import { CydLeftSideBar } from 'components/_layout/CydLeftSideBar/CydLeftSideBar';
import { CydPlaybookInstanceDisplay } from 'components/_objectDisplay/CydPlaybookInstanceDisplay/CydPlaybookInstanceDisplay';
import { convertStandaloneActionsToFlowchartAndResolution } from 'components/_playbooks/CacaoFlowchart/utils/standaloneToFlowchart';
import { useCaseActions } from 'hooks/deprecated_CaseHooks';
import {
  useAddPlaybookToCase,
  useCasePlaybooks
} from 'hooks/CasePlaybooksHooks';
import {
  useAtcPlaybookActions,
  useRemovePlaybookFromCase,
  useAddStandaloneActionToCase,
  useCreatePlaybookAction
} from 'hooks/PlaybookActionsHook';
import { useAtcPlaybooks, useCacaoPlaybooks } from 'hooks/PlaybooksHooks';
import { PlaybookAction } from 'interface/Playbook.interface';
import React, { useEffect, useMemo, useState } from 'react';
import { CydAddPlaybookToCaseModal } from './CydAddPlaybookToCaseModal/CydAddPlaybookToCaseModal';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useParams } from 'react-router';
import { CydActionAdderModal } from 'components/_playbooks/CydActionAdderModal/CydActionAdderModal';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { GenericNode } from 'components/_playbooks/CacaoFlowchart/type';
import { CydNoDataToDisplay } from 'components/_placeholders/CydNoDataToDisplay/CydNoDataToDisplay';
import { CydMenu } from 'components/_dialogs/CydMenu/CydMenu';
import { useCaseAcl, useCurrentDataAcls } from 'hooks/AclHooks';
import { Acl } from 'interface/Acl.interface';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { CydCasePlaybookDisplay } from './CydCasePlaybookDisplay/CydCasePlaybookDisplay';

export const CydCaseViewPlaybooks = () => {
  const params = useParams();
  const caseUuid = params['caseUuid'];

  if (!caseUuid) {
    throw new Error('No caseUuid in URL params');
  }

  const atcPlaybooksResult = useAtcPlaybooks();

  const cacaoPlaybooksResult = useCacaoPlaybooks();

  const { data: atcActions } = useAtcPlaybookActions();

  const { isToggleEnabled } = useFeatureToggles();

  const isUsingCacao = isToggleEnabled('REACT_APP_USE_CACAO_PLAYBOOKS');

  const casePlaybooks = useCasePlaybooks(caseUuid);

  const cacaoIdSet = useMemo(() => {
    return new Set(cacaoPlaybooksResult.data.map((v) => v.uuid));
  }, [cacaoPlaybooksResult.data]);

  const casePlaybooksToUse = useMemo(() => {
    const sorted = [...(casePlaybooks.data || [])].sort((a, b) => {
      const aIsComplete = a.action_statuses.every(
        (v) => v.status === 'success'
      );
      const bIsComplete = b.action_statuses.every(
        (v) => v.status === 'success'
      );

      // Put the complete ones on the bottom
      if (aIsComplete && !bIsComplete) {
        return 1;
      }

      if (!aIsComplete && bIsComplete) {
        return -1;
      }

      // If both are either both complete, or both incomplete, sort by precedence
      return a.precedence - b.precedence;
    });

    if (!isUsingCacao) {
      return sorted.filter((v) => {
        return !cacaoIdSet.has(v.playbookUuid);
      });
    }
    return sorted;
  }, [isUsingCacao, casePlaybooks, cacaoIdSet]);
  const { data: caseActions } = useCaseActions(caseUuid);

  const { data: aclList } = useCurrentDataAcls();
  const defaultAcl = useCaseAcl(caseUuid);

  const standalonePlaybook = useMemo(() => {
    if (!caseActions) {
      return null;
    }

    return convertStandaloneActionsToFlowchartAndResolution(caseActions);
  }, [caseActions]);

  const [selectedPlaybookId, setSelectedPlaybookId] = useState(
    null as string | null
  );

  const [selectedNode, setSelectedNode] = useState(null as GenericNode | null);

  const [isAddPlaybookOpen, setIsAddPlaybookOpen] = useState(false);
  const [isAddActionOpen, setIsAddActionOpen] = useState(false);

  const handleAddPlaybookToCase = useAddPlaybookToCase(caseUuid);

  const removePlaybookFromCase = useRemovePlaybookFromCase(caseUuid);

  const addActionToCase = useAddStandaloneActionToCase(caseUuid);
  const createAction = useCreatePlaybookAction();

  const handleAddActionToCase = (action: PlaybookAction) => {
    setSelectedNode(null);
    setSelectedPlaybookId(null);
    addActionToCase(action.uuid as string, (actionUuid) => {
      setSelectedPlaybookId('standalone');
    });
    setIsAddActionOpen(false);
  };

  const handleCreateAndAddAction = (data: {
    title: string;
    description: string;
    acl: Acl;
  }) => {
    setSelectedNode(null);
    setSelectedPlaybookId(null);
    createAction(
      {
        atc: {
          name: data.title,
          description: data.description,
          position: caseActions.length + 1,
          acl: data.acl.uuid
        }
      },
      (newAction) => {
        addActionToCase(newAction.uuid, () => {
          setSelectedPlaybookId('standalone');
        });
      }
    );

    setIsAddActionOpen(false);
  };

  const allPlaybooks = useMemo(() => {
    if (isUsingCacao) {
      return [...atcPlaybooksResult.data, ...cacaoPlaybooksResult.data];
    }

    return atcPlaybooksResult.data;
  }, [atcPlaybooksResult, cacaoPlaybooksResult, isUsingCacao]);

  /**
   * On component mount, automatically select a case playbook
   *
   * This solution is not intended to be permenant.
   *
   * Real solution involves reading query params.
   * This thread if you are interested: https://www.reddit.com/r/reactjs/comments/11ftoqq/keeping_track_of_query_param_state_as_you_click/
   *
   * So if you're reading this in several years time, be amused I guess.
   */
  useEffect(() => {
    if (!selectedPlaybookId && casePlaybooksToUse && caseActions) {
      if (casePlaybooksToUse.length > 0) {
        setSelectedPlaybookId(casePlaybooksToUse[0].casePlaybookUuid);
      } else if (caseActions.length > 0) {
        setSelectedPlaybookId('standalone');
      }
    }
  }, [
    caseActions,
    casePlaybooks.data,
    casePlaybooksToUse,
    selectedPlaybookId,
    setSelectedPlaybookId
  ]);

  /**
   * ------------CONDITIONALS START HERE------------
   */

  if (
    !casePlaybooks.data ||
    casePlaybooks.isLoading ||
    !caseActions ||
    defaultAcl.isLoading ||
    atcPlaybooksResult.isLoading ||
    cacaoPlaybooksResult.isLoading
  ) {
    return <LoadingSpinner />;
  }

  if (casePlaybooks.hasLoaded && !casePlaybooks.data) {
    throw new Error('Playbooks have loaded, but no data');
  }

  return (
    <div data-testid="cyd-page-case-view-playbooks" style={{ height: '100%' }}>
      {isAddActionOpen && (
        <CydActionAdderModal
          availableAcls={aclList}
          defaultAcl={defaultAcl.data}
          availableActions={atcActions.map((v) => v.atc as PlaybookAction)}
          isOpen={isAddActionOpen}
          onClose={() => setIsAddActionOpen(false)}
          onAddNewAction={handleAddActionToCase}
          onCreateAction={handleCreateAndAddAction}
        />
      )}
      {isAddPlaybookOpen && (
        <CydAddPlaybookToCaseModal
          availablePlaybooks={allPlaybooks}
          isOpen={isAddPlaybookOpen}
          onClose={() => setIsAddPlaybookOpen(false)}
          onAddPlaybook={(playbook) => {
            setSelectedPlaybookId(null);
            setSelectedNode(null);
            handleAddPlaybookToCase(playbook, (newPlaybooksInstanceUuid) =>
              setSelectedPlaybookId(newPlaybooksInstanceUuid)
            );
            setIsAddPlaybookOpen(false);
          }}
        />
      )}

      <CydLeftSideBar>
        <MenuList>
          {casePlaybooksToUse.map((v) => {
            const isCacao = cacaoIdSet.has(v.playbookUuid);
            if (!isUsingCacao && isCacao) {
              return null;
            }

            return (
              <MenuItem
                aria-label={v.playbookName}
                selected={v.casePlaybookUuid === selectedPlaybookId}
                key={`${v.casePlaybookUuid}`}
                onClick={() => {
                  setSelectedPlaybookId(v.casePlaybookUuid);
                  setSelectedNode(null);
                }}
                css={(theme) => `
                  align-items: flex-start;
                `}
              >
                <CydPlaybookInstanceDisplay
                  title={v.playbookName}
                  isCacao={isCacao}
                  actionStatuses={v.action_statuses}
                  css={(theme) => `
                    flex: 1 1 auto; 
                  `}
                />

                <CydIconButton
                  css={css`
                    flex: 0 0 40px;
                  `}
                  onClick={(e) => {
                    e.stopPropagation();
                    removePlaybookFromCase(v);
                  }}
                  icon="delete"
                  label="Remove playbook from case"
                />
              </MenuItem>
            );
          })}

          {/* MenuList doesn't like fragments, so gotta do these conditionals twice */}
          {standalonePlaybook &&
            standalonePlaybook.playbook.nodes.length > 0 && <Divider />}
          {standalonePlaybook &&
            standalonePlaybook.playbook.nodes.length > 0 && (
              <MenuItem
                selected={'standalone' === selectedPlaybookId}
                onClick={() => {
                  setSelectedPlaybookId('standalone');
                  setSelectedNode(null);
                }}
              >
                <CydPlaybookInstanceDisplay
                  title={'Standalone Actions'}
                  actionStatuses={caseActions}
                  css={(theme) => `
                    flex: 1 1 auto; 
                  `}
                />

                <div
                  css={css`
                    flex: 0 0 40px;
                  `}
                >
                  {/* spacer where the delete icon would be */}
                </div>
              </MenuItem>
            )}
        </MenuList>

        <CydButtonStack direction={'column'}>
          <CydMenu
            label="Add"
            ButtonProps={{
              startIcon: 'add',
              variant: 'outlined'
            }}
            menuItems={[
              {
                label: 'Playbook',
                onClick: () => setIsAddPlaybookOpen(true)
              },
              {
                label: 'Action',
                onClick: () => setIsAddActionOpen(true)
              }
            ]}
          />
        </CydButtonStack>
      </CydLeftSideBar>

      {casePlaybooks.data.length === 0 && caseActions.length === 0 && (
        <CydNoDataToDisplay
          css={css`
            margin-top: 150px;
          `}
          title="No actions to display"
          body="Try adding a playbook or action to the case"
          additionalContent={
            <CydMenu
              label="Add"
              ButtonProps={{
                startIcon: 'add',
                variant: 'outlined'
              }}
              menuItems={[
                {
                  label: 'Playbook',
                  onClick: () => setIsAddPlaybookOpen(true)
                },
                {
                  label: 'Action',
                  onClick: () => setIsAddActionOpen(true)
                }
              ]}
            />
          }
        />
      )}
      {selectedPlaybookId && (
        <CydCasePlaybookDisplay
          caseUuid={caseUuid}
          selectedCasePlaybookUuid={selectedPlaybookId}
          selectedNode={selectedNode}
          onSelectNode={setSelectedNode}
          standalonePlaybook={standalonePlaybook}
        />
      )}
    </div>
  );
};
