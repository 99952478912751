/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Paper, Popover, PopoverProps } from '@mui/material';
import {
  CydButton,
  CydButtonProps
} from 'components/_formElements/CydButton/CydButton';
import {
  CydIconButton,
  CydIconButtonProps
} from 'components/_formElements/CydIconButton/CydIconButton';
import { CydIconTypes } from 'components/_foundation/CydIcon/CydIcon';
import React, { useState } from 'react';
import { useRef } from 'react';

/** @jsxImportSource @emotion/react */

export type CydPopoverProps = {
  label: string;
  icon?: CydIconTypes;

  variant?: 'button' | 'icon-button';
  buttonVariant?: CydButtonProps['variant'];

  buttonSize?: CydIconButtonProps['size'];

  // For now, I'm hoping that these two optional properties are plenty enough configuration
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];

  /**
   * If you include this property, children will be put inside a plain `form` element
   * You can access the submission event via this callback
   *
   * When the form submits, the popover will close
   */
  onFormSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;

  children?: React.ReactNode;

  /**if true: contents of the popover will remount, causing their state to refreshed
   * if false: state is preserved
   */
  remount?: boolean;

  /**
   * Use this prop instead of ordinary children, if you want to programatically close the popover
   */
  renderChildren?: (props: { closePopoverFn: () => void }) => React.ReactNode;
};
const DEFAULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center'
} as const;

const DEFAULT_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'center'
} as const;

/**
 *

 * @param props
 * @returns
 */
export const CydPopover = (props: CydPopoverProps) => {
  const {
    label,
    icon,
    variant = 'button',
    buttonVariant,
    buttonSize,
    children = null,
    renderChildren,
    onFormSubmit,
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin = DEFAULT_TRANSFORM_ORIGIN,
    remount
  } = props;

  const ref = useRef<HTMLButtonElement>(null);

  if (variant === 'icon-button' && !icon) {
    console.warn(
      "You are using an icon-button CydPopover, but you haven't specified an icon to use"
    );
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {variant === 'button' ? (
        <CydButton ref={ref} onClick={handleClick} variant={buttonVariant}>
          {label}
        </CydButton>
      ) : (
        <CydIconButton
          size={buttonSize}
          label={label}
          ref={ref}
          onClick={handleClick}
          icon={icon as CydIconTypes}
        />
      )}

      {(!remount || isOpen) && (
        <Popover
          open={isOpen}
          anchorEl={ref.current}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <Paper
            css={(theme) => `
          padding: ${theme.spacing(2)};
      `}
          >
            {onFormSubmit ? (
              <form
                onSubmit={(e) => {
                  onFormSubmit(e);
                  handleClose();
                }}
              >
                {renderChildren
                  ? renderChildren({ closePopoverFn: handleClose })
                  : children}
              </form>
            ) : (
              <>
                {renderChildren
                  ? renderChildren({ closePopoverFn: handleClose })
                  : children}
              </>
            )}
          </Paper>
        </Popover>
      )}
    </>
  );
};
