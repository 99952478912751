/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useCallback } from 'react';

export function debounce(
  this: any,
  fn: Function,
  wait: number,
  immediate = false
) {
  let timeout: any;

  return (...args: any[]) => {
    if (immediate && !timeout) {
      fn.apply(this, args);
    }

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;

      if (!immediate) {
        fn.apply(this, args);
      }
    }, wait);
  };
}

export const useDebounce = (
  fnToDebounce: Function,
  inputs: any[],
  wait: number,
  immediate = false
) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(debounce(fnToDebounce, wait, immediate), inputs);
