import { useEffect } from 'react';

/**
 * Components that use this component will display a warning before the user closes the tab
 * On component unmount the window unload event listener will be removed.
 */
export function useUnloadWarning() {
  useEffect(() => {
    const listener = function (e) {
      var confirmationMessage =
        'It looks like you have been editing something. ' +
        'If you leave before saving, your changes will be lost.';

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    };

    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, []);
}
