/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Typography } from '@mui/material';
import { parseNodeName } from '../CustomNodes/customNodeUtils';
import { GenericNode, GenericPlaybook } from '../type';
import { GenericNodeDetailsPanel } from '../GenericNodeDetailsPanel/GenericNodeDetailsPanel';
import { determineManualOrAutomatic } from '../utils/generalNodeStatusUtils';
import { Markdown } from 'components/Markdown';
import { ManualOrAutomaticIcon } from '../ManualOrAutomaticIcon/ManualOrAutomaticIcon';
import { CydTabPanels } from 'components/_formContainers/CydTabPanels/CydTabPanels';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import { CydSimpleTable } from 'components/_dataDisplay/CydSimpleTable/CydSimpleTable';
import { CacaoConditionDisplay } from '../CacaoConditionDisplay/CacaoConditionDisplay';
import React from 'react';
import { useRightPanelHook } from 'hooks/RightPanelHook';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

/** @jsxImportSource @emotion/react */

export type BuilderDetailsPanelProps = {
  node: GenericNode;
  playbookType: GenericPlaybook['playbookType'];

  playbook: GenericPlaybook;
  onDeletePlaybook: (string) => void;
};

type VariableInfo = {
  id: string;
  description: string;
  type: string;
};

export function getNodeVariables(
  node: GenericNode,
  playbook: GenericPlaybook
): {
  inputVariables: Array<VariableInfo>;
  outputVariables: Array<VariableInfo>;
} {
  const inputVariables: VariableInfo[] = [];
  const outputVariables: VariableInfo[] = [];

  // no node selected - this is a playbook
  if (node === null) {
    for (const variable of Object.values(playbook.variablesMap)) {
      inputVariables.push({
        id: variable.key,
        description: variable.name,
        type: variable.type
      });
    }
    return {
      inputVariables,
      outputVariables
    };
  }

  if (node.nodeType.type !== 'action') {
    return {
      inputVariables,
      outputVariables
    };
  }

  node.nodeType.data.inArgIds?.forEach((id) => {
    const variable = playbook.variablesMap[id];
    if (!variable) {
      throw new Error(`No variable with id '${id} found in variablesMap`);
    }

    inputVariables.push({
      id,
      description: variable.name,
      type: variable.type
    });
  });

  node.nodeType.data.outArgIds?.forEach((id) => {
    const variable = playbook.variablesMap[id];
    if (!variable) {
      throw new Error(`No variable with id '${id} found in variablesMap`);
    }

    outputVariables.push({
      id,
      description: variable.name,
      type: variable.type
    });
  });

  return { inputVariables, outputVariables };
}

export const BuilderDetailsPanel = (props: BuilderDetailsPanelProps) => {
  const { node, playbookType, playbook, onDeletePlaybook } = props;
  const nodeMode = determineManualOrAutomatic(node);
  const isPlaybookNode = node?.nodeType?.type.match('playbook') || false;
  const nodeVariables = getNodeVariables(node, playbook);

  useRightPanelHook();

  return (
    <GenericNodeDetailsPanel
      headerContent={
        <>
          <div
            css={(theme) => `
          display: flex; 
          flex-flow: row nowrap; 
          justify-content: space-between; 
          align-items: center; 
          gap: ${theme.spacing(2)}; 
        `}
          >
            <Typography variant="overline" component="h5">
              {playbookType} Playbook {node ? 'Action' : ''}
            </Typography>
          </div>

          <div
            css={(theme) => `
                  display: flex;
                  align-item: center;
                  gap: ${theme.spacing(3)};
                `}
          >
            {/* <Typography variant="caption" color="text.secondary" gutterBottom>
              Created:&nbsp;
              <CydTimeDisplay time={node.oldData.created as string} />
            </Typography>
            <Typography variant="caption" color="text.secondary" gutterBottom>
              Modified:&nbsp;
              <CydTimeDisplay time={node.oldData.modified as string} />
            </Typography> */}
          </div>
        </>
      }
    >
      <>
        <div
          css={(theme) => `
          display: flex;
          flex-flow: row nowrap;
        `}
        >
          <Typography
            variant="h6"
            gutterBottom
            css={(theme) => `
            flex: 1 1 auto;
          `}
          >
            {node ? parseNodeName(node, playbookType) : playbook.title}
          </Typography>
          {isPlaybookNode ? (
            <CydIcon icon="menubook" label="playbook" />
          ) : (
            <ManualOrAutomaticIcon type={nodeMode} />
          )}
        </div>

        <CydTabPanels
          variant="side-panel"
          label="selected step"
          initialSelectedTab="Description"
          tabs={[
            {
              label: 'Description',
              content: (
                <>
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      Description
                    </Typography>

                    <Typography variant="body1" component="div">
                      <Markdown>
                        {(node ? node.description : playbook.description) || ''}
                      </Markdown>
                    </Typography>

                    {(node?.nodeType.type === 'if-condition' ||
                      node?.nodeType.type === 'while-condition') && (
                      <>
                        <CacaoConditionDisplay
                          playbook={playbook}
                          condition={node.nodeType.data.condition}
                        />
                      </>
                    )}
                    {!node && (
                      <CydButton
                        variant="outlined"
                        onClick={() => onDeletePlaybook(playbook.id)}
                      >
                        Delete Playbook
                      </CydButton>
                    )}
                  </>
                </>
              )
            },
            ...(playbookType === 'cacao'
              ? [
                  {
                    tabKey: 'variables',
                    label: `Variables (${
                      nodeVariables.inputVariables.length +
                      nodeVariables.outputVariables.length
                    })`,
                    content: (
                      <>
                        <div>
                          <div
                            css={(theme) => `
                          margin-bottom: ${theme.spacing(10)}
                        `}
                          >
                            <Typography variant="subtitle2" gutterBottom>
                              {node ? 'Input Variables' : 'Playbook Variables'}
                            </Typography>

                            <CydSimpleTable
                              css={(theme) => `
                                box-sizing: content-box;
                                `}
                              columns={[
                                {
                                  key: 'id',
                                  label: 'ID'
                                },
                                {
                                  key: 'type',
                                  label: 'Type'
                                },
                                {
                                  key: 'description',
                                  label: 'Description'
                                }
                              ]}
                              rows={nodeVariables.inputVariables}
                              uniquenessId="id"
                            />
                          </div>
                          {node && (
                            <div>
                              <Typography variant="subtitle2" gutterBottom>
                                Output Variables
                              </Typography>

                              <CydSimpleTable
                                css={(theme) => `
                                  box-sizing: content-box;
                                  `}
                                columns={[
                                  {
                                    key: 'id',
                                    label: 'ID'
                                  },
                                  {
                                    key: 'type',
                                    label: 'Type'
                                  },
                                  {
                                    key: 'description',
                                    label: 'Description'
                                  }
                                ]}
                                rows={nodeVariables.outputVariables}
                                uniquenessId="id"
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )
                  }
                ]
              : [])
          ]}
        />
      </>
    </GenericNodeDetailsPanel>
  );
};
