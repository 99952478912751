/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
/*
(c) 2019 Copyright Cydarm Technologies Pty Ltd. All rights reserved.
*/
export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  THREAD_ITEMS_REFRESH_INTERVAL: 15000, // in millisecond, reload thread items every 10 seconds in Case View
  CASE_LIST_REFRESH_INTERVAL: 10000,
  DASHBOARD_CHART_REFRESH_INTERVAL: 30000,
  // in milli-seconds - when the jwt token is about to expire (in REFRESH_TOKEN_THRESHOLD),
  // the jwt token will be refresh (default: 5 minutes)
  REFRESH_TOKEN_THRESHOLD: 5 * 60 * 1000,
  DEFAULT_DATA_SIGNIFICANCE: 3,
  DEFAULT_ACL: 'case data defaults'
};
