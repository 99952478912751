/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Divider } from '@mui/material';
import React from 'react';

/** @jsxImportSource @emotion/react */
export type CydPanelHrProps = {
  className?: string;
  disableMargins?: boolean;
};

export const CydPanelHr = (props: CydPanelHrProps) => {
  const { className, disableMargins } = props;
  return (
    <Divider
      className={className}
      css={(theme) => `
      margin: ${theme.spacing(disableMargins ? 0 : 6)} -${theme.spacing(6)}};
    `}
    />
  );
};
