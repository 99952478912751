/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Report } from 'interface/Report.interface';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  isLoading: boolean;
  error?: ErrorPayload | null;
  report?: Report | null;
} = {
  report: null,
  isLoading: false,
  error: null
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    createReportSuccess: (state, { payload }: PayloadAction<Report>) => {
      state.report = payload;
      state.isLoading = false;
    },
    createReportFailed: (state, { payload }: PayloadAction<ErrorPayload>) => {
      state.error = payload;
      state.isLoading = false;
    },
    loadingReportSuccess: (state) => {
      state.isLoading = false;
    }
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      (action) => action.type.startsWith('reports/create'),
      (state) => {
        state.isLoading = true;
      }
    )
});

export const {
  reducer: reports,
  actions: { createReportSuccess, createReportFailed, loadingReportSuccess }
} = reportsSlice;
