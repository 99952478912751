/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useState, ClipboardEventHandler } from 'react';
import ReactDropzone from 'react-dropzone';
import { useStyles } from 'components/Dropzone/styles';

interface IDropzoneProps {
  onDrop: (file: any) => void;
  onPasteCapture?: ClipboardEventHandler;
  active: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Dropzone = React.forwardRef(
  (
    { onDrop, active, className, children, onPasteCapture }: IDropzoneProps,
    ref: React.RefObject<any>
  ) => {
    const [isHovering, setIsHovering] = useState(false);
    const { fileDropHelper } = useStyles();
    const handleFileEnter = () => {
      if (active) {
        setIsHovering(true);
      }
    };
    const handleFileLeave = () => {
      if (active) {
        setIsHovering(false);
      }
    };

    return (
      <ReactDropzone
        ref={ref}
        noClick
        onDrop={(file) => {
          onDrop(file);
          setIsHovering(false);
        }}
        onDragEnter={handleFileEnter}
        onDragLeave={handleFileLeave}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            data-testid="cyd-dropzone"
            {...getRootProps({ className: className })}
            onPasteCapture={(e) => {
              if (onPasteCapture) {
                onPasteCapture(e);
              }
              setIsHovering(false);
            }}
          >
            <input {...getInputProps()} />
            {children}
            {isHovering && (
              <div className={fileDropHelper}>Drop File(s) To Upload</div>
            )}
          </div>
        )}
      </ReactDropzone>
    );
  }
);

export default Dropzone;
