/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  createOrg,
  createDeleteOrg,
  createFetchOrgByUuid,
  createFetchOrgs,
  createUpdateOrg
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { Org } from 'interface/Org.interface';
import { addNotification } from 'states/notifications/slice';
import { addAlert } from 'states/alerts/slice';
import {
  fetchOrgByUuidSuccess,
  fetchOrgsFailed,
  fetchOrgsSuccess
} from './slice';
import {
  apiCreateOrg,
  apiDeleteOrg,
  apiFetchOrgByUuid,
  apiFetchOrgs,
  apiUpdateOrg
} from 'services/OrgService';

export function* performFetchOrgs() {
  try {
    const { json } = yield apiFetchOrgs();
    yield put(fetchOrgsSuccess(json));
  } catch (ex) {
    yield put(fetchOrgsFailed(ERROR_MESSAGE.FETCH_ORGS_ERROR));
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_ORGS_ERROR.message })
    );
  }
}

export function* performFetchOrgByUuid({ payload: uuid }: { payload: string }) {
  try {
    const { json }: { json: Org } = yield apiFetchOrgByUuid(uuid);
    yield put(fetchOrgByUuidSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_ORG_BY_UUID_ERROR.message
      })
    );
  }
}

export function* performCreateOrg({ payload: name }: { payload: string }) {
  try {
    yield apiCreateOrg({ name: name });
    yield put(
      addNotification({ message: `Organization ${name} has been created` })
    );
    yield put(
      addAlert({
        header: 'Logout Required',
        body: 'To see the new organization, you must log out and back in again.'
      })
    );
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.CREATE_ORG_ERROR.message })
    );
  }
}

export function* performUpdateOrg(action) {
  const {
    payload: { org, uuid }
  }: { payload: { uuid: string; org: Org } } = action;
  try {
    yield apiUpdateOrg(uuid, {
      uuid,
      name: org.name,
      groupUuid: org.groupUuid,
      acl: org.acl
    });
    yield put(
      addNotification({ message: `Organization ${org.name} has been updated` })
    );
    yield put(createFetchOrgByUuid(uuid));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.UPDATE_ORG_ERROR.message })
    );
  }
}

export function* performDeleteOrg(action) {
  const {
    payload: { uuid }
  }: { payload: { uuid: string } } = action;
  try {
    yield apiDeleteOrg(uuid);
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.DELETE_ORG_ERROR.message })
    );
  }
}

/* Watchers */
function* watchFetchOrgs() {
  yield takeLeading(createFetchOrgs, performFetchOrgs);
}

function* watchFetchOrgByUuid() {
  yield takeLatest(createFetchOrgByUuid, performFetchOrgByUuid);
}

function* watchCreateOrg() {
  yield takeLatest(createOrg, performCreateOrg);
}

function* watchUpdateOrg() {
  yield takeLatest(createUpdateOrg, performUpdateOrg);
}

function* watchDeleteOrg() {
  yield takeLatest(createDeleteOrg, performDeleteOrg);
}

export default [
  watchFetchOrgs(),
  watchFetchOrgByUuid(),
  watchCreateOrg(),
  watchUpdateOrg(),
  watchDeleteOrg()
];
