/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Report } from 'interface/Report.interface';
import { createAction } from '@reduxjs/toolkit';

export interface ICreateReportWithResolve extends Report {
  displayPreview?: (mdValue: string, mimetype: string) => void;
  previewType?: string;
}

// Reports Actions Function
export const createUserReport = createAction('reports/create/users');
export const createGroupReport = createAction('reports/create/group');
export const createAnalystReport = createAction<Report>(
  'reports/create/analyst'
);
export const createMTTXReport = createAction<ICreateReportWithResolve>(
  'reports/create/mttx'
);
export const createHeatMapReport = createAction<ICreateReportWithResolve>(
  'reports/create/heatmap'
);
export const createSummaryReport = createAction<ICreateReportWithResolve>(
  'reports/create/summary'
);
