/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { css } from '@emotion/react';
import { CydUserDisplayById } from 'components/_objectDisplay/CydUserDisplay/CydUserDisplayById';
import { ACLSignifier } from './ACLSignifier';
import { useCaseActivityStyles } from './styles';
import { Timestamps } from './Timestamps/Timestamps';

/** @jsxImportSource @emotion/react */

export const CaseActivityInfo = ({ caseActivity, caseAcl }) => {
  const {
    content,
    significance,
    creatoruuid,
    created,
    audit,
    edited,
    lastmodified
  } = caseActivity;

  const showDifferentAclSignifier = !audit && caseActivity.acl !== caseAcl;

  const { headingText } = useCaseActivityStyles({});

  return (
    <div className={headingText}>
      <span
        css={css`
          margin-right: 8px;
        `}
      >
        {creatoruuid && <CydUserDisplayById userId={creatoruuid} />}
        {audit ? content : `posted a ${significance}`}
      </span>
      {showDifferentAclSignifier && <ACLSignifier />}
      <Timestamps {...{ created, lastmodified, edited }} />
    </div>
  );
};
