/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

export const toastPlacement = (number) => `-${number * 70 + 20}px`;
export const hiddenToastPlacement = (number) => `-${(number - 1) * 70 + 30}px`;
export const notificationPlacement = (number) =>
  number === 4 ? hiddenToastPlacement(number) : toastPlacement(number);
