/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { IconButton, IconButtonProps } from '@mui/material';
import { CircularProgress } from '@mui/material';
import React from 'react';

import { CydIconTypes, CydIcon } from '../../_foundation/CydIcon/CydIcon';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type CydIconButtonProps = {
  /**
   * The label is for accessibility
   * And shows when you hover the button
   * Also, is how you select the button in RTL tests
   */
  label: string;
  icon: CydIconTypes;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: IconButtonProps['size'];
  type?: IconButtonProps['type'];
  className?: string;
  disabled?: boolean;
  loading?: boolean;
};

export const CydIconButton = React.forwardRef<
  HTMLButtonElement,
  CydIconButtonProps
>((props: CydIconButtonProps, ref) => {
  const { loading, label, icon, onClick, size, className, disabled } = props;

  return (
    <IconButton
      aria-label={label}
      onClick={onClick}
      ref={ref}
      size={size as IconButtonProps['size']}
      className={className}
      disabled={disabled}
    >
      {loading && (
        <CircularProgress
          size={40}
          css={css`
            position: absolute;
            transform: translate(-50%, -50%);
          `}
        />
      )}
      <CydIcon icon={icon} />
    </IconButton>
  );
});
