/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { CYDARM_BASE_API } from '../constants';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CydApi_BaseCase,
  apiFetchCasesFiltered,
  apiPerformFetchCaseByLocator
} from 'services';
import { PaginatedLoadMoreFunctionResult } from 'components/_dataDisplay/CydDataTable/CydDataTable';
import { imperativeAddNotification } from 'states/imperitiveAddNotif';
import { extraIsApiServices, handleRtkqError } from 'utils/rtkqUtils';
import { rtkqUsersApi } from './UserHooks';
import { DataSignificance } from 'interface/DataSignificance.interface';
import { Base64Encode } from 'utils/StringUtils';
import { CaseTag } from 'interface/Tags.interface';

export type CaseFilterParams = {
  page: {
    number: number;
    size: number;
  };

  filter: Partial<{
    create_start: string;
    create_end: string;
    mod_start: string;
    mod_end: string;

    state: Array<string>;
    org: Array<string>;
    assignee: Array<string>;
    severity: Array<string>;
    inc_tag: Array<string>;
    exc_tag: Array<string>;
    text: string;
  }>;

  sort?: string;
  sort_dir?: string;
};

const LOCATOR_DOES_NOT_EXIST =
  'Error adding case - that locator does not exist.';

const ERROR_FETCHING_SINGLE_CASE = 'Error fetching single case.';

const ERROR_FETCHING_FILTERED_CASES = 'Error fetching filtered cases.';

const ALL_TAGS = ['case-uuid', 'case-locator', 'cases-filtered'];
export const rtkqCasesApi = createApi({
  reducerPath: 'rtk-cases',
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),

  tagTypes: ALL_TAGS,
  endpoints: (builder) => {
    return {
      getSingleCaseByLocator: builder.query<
        CydApi_BaseCase,
        { locator: string | null }
      >({
        queryFn: async (arg, api, extraOptions) => {
          if (!arg.locator) {
            throw new Error(
              'No locator to fetch with. (This error should never be thrown, the query should be disabled.)'
            );
          }

          try {
            const result = await apiPerformFetchCaseByLocator(arg.locator);

            return {
              data: result.json
            };
          } catch (err) {
            imperativeAddNotification({ message: LOCATOR_DOES_NOT_EXIST });

            return {
              error: {
                status: 1,
                statusText: '',
                data: LOCATOR_DOES_NOT_EXIST
              }
            };
          }
        },

        providesTags: (result, err, args) => {
          if (result) {
            return [
              {
                type: 'case-locator',
                id: result.locator
              },
              {
                type: 'case-uuid',
                id: result.uuid
              }
            ];
          }

          return [];
        }
      }),

      getSingleCaseByUuid: builder.query<CydApi_BaseCase, { caseUuid: string }>(
        {
          queryFn: async (arg, api, extraOptions) => {
            try {
              const apiService = extraIsApiServices(api.extra);
              const result = await apiService.apiPerformFetchCaseByUuid(
                arg.caseUuid
              );

              return {
                data: result.json
              };
            } catch (err) {
              imperativeAddNotification({
                message: ERROR_FETCHING_SINGLE_CASE
              });

              return {
                error: {
                  status: 1,
                  statusText: '',
                  data: ERROR_FETCHING_SINGLE_CASE
                }
              };
            }
          },

          providesTags: (result, err, args) => {
            if (result) {
              return [
                {
                  type: 'case-locator',
                  id: result.locator
                },
                {
                  type: 'case-uuid',
                  id: result.uuid
                }
              ];
            }

            return [];
          }
        }
      ),

      batchAssignTagToCases: builder.mutation<
        null,
        { caseUuids: Array<string>; tag: CaseTag }
      >({
        invalidatesTags: ALL_TAGS,
        queryFn: async (args, api) => {
          try {
            const apiService = extraIsApiServices(api.extra);

            const proms = args.caseUuids.map((caseUuid: string) => {
              return apiService.apiPerformAddTagToCase(
                caseUuid,
                args.tag.value
              );
            });

            await Promise.all(proms);

            imperativeAddNotification({ message: 'Add tag to cases' });

            return {
              data: null
            };
          } catch (err) {
            // This isn't great error handling.
            // Really we'd want a per-request kind of error handling - but then how do we display that?
            return await handleRtkqError('Error assigning tag to cases');
          }
        }
      }),

      batchAssignUserToCases: builder.mutation<
        null,
        {
          caseUuids: Array<string>;
          assigneeUserName: string;
        }
      >({
        invalidatesTags: ALL_TAGS,
        queryFn: async (args, api) => {
          try {
            const apiService = extraIsApiServices(api.extra);

            const proms = args.caseUuids.map((v) => {
              return apiService.apiPerformUpdateCase(v, {
                assignee: args.assigneeUserName
              });
            });

            await Promise.all(proms);
            imperativeAddNotification({ message: 'Assigned user to cases' });

            return {
              data: null
            };
          } catch (err) {
            // This isn't great error handling.
            // Really we'd want a per-request kind of error handling - but then how do we display that?
            return await handleRtkqError('Error assigning user to cases');
          }
        }
      }),

      batchAssignStatusToCases: builder.mutation<
        null,
        {
          caseUuids: Array<string>;
          status: string; // The status name
          changeReason?: string;

          /**
           * The reason we need to provide this
           * is because the change reason status update needs it
           * it would be preferable to just fetch that inside the handler here
           * but that's going to involving converting the DataSignificances and SysProps over to RTKQ... so lets not
           * once we have converted those over
           * we can remove this and fetch them directly
           */
          defaultDataSignificance: DataSignificance;
        }
      >({
        invalidatesTags: ALL_TAGS,
        queryFn: async (args, api) => {
          try {
            const apiService = extraIsApiServices(api.extra);

            const proms = args.caseUuids.map(async (v) => {
              await apiService.apiPerformUpdateCase(v, {
                status: args.status
              });

              // From here: https://github.com/cydarm/cydarm-frontend/blob/14d3bd58b8f2bf7bf37b0cb9659e0475ae9f0f0f/src/states/cases/sagas.ts#L307-L316
              if (args.changeReason) {
                const cydarmCaseResult = await api
                  .dispatch(
                    rtkqCasesApi.endpoints.getSingleCaseByUuid.initiate({
                      caseUuid: v
                    })
                  )
                  .unwrap();
                const userResult = await api
                  .dispatch(rtkqUsersApi.endpoints.getCurrentUser.initiate({}))
                  .unwrap();

                await apiService.apiAddCaseData(v, {
                  data: Base64Encode(
                    `**Status Change Justification:** ${args.changeReason}`
                  ),
                  significance: args.defaultDataSignificance.name,
                  userUuid: userResult.uuid,
                  caseUuid: v,
                  mimeType: 'text/plain',
                  acl_uuid: cydarmCaseResult.acl
                });
              }
              return;
            });

            await Promise.all(proms);

            imperativeAddNotification({ message: "Changed cases' status" });

            return {
              data: null
            };
          } catch (err) {
            // This isn't great error handling.
            // Really we'd want a per-request kind of error handling - but then how do we display that?
            return await handleRtkqError('Error assigning user to cases');
          }
        }
      }),

      createCase: builder.mutation<
        {
          uuid: string;
        },
        {
          description: string;
          org?: string;
          members?: Array<string>;
        }
      >({
        queryFn: async (args, api) => {
          try {
            const apiService = extraIsApiServices(api.extra);
            const result = await apiService.apiPerformCreateCase(args);
            return {
              data: result.json
            };
          } catch (err) {
            return await handleRtkqError(err);
          }
        }
      }),

      batchAddCasesToGroup: builder.mutation<
        {
          uuid: string;
        },
        {
          caseUuids: Array<string>;
          groupInfo:
            | {
                groupOption: 'NEW';
                description: string;
                orgUuid?: string | undefined;
              }
            | {
                groupOption: 'EXISTING';
                locator: string;
              };
        }
      >({
        invalidatesTags: ALL_TAGS,
        queryFn: async (args, api) => {
          try {
            const apiService = extraIsApiServices(api.extra);

            if (args.groupInfo.groupOption === 'EXISTING') {
              const dispatchResult = api.dispatch(
                rtkqCasesApi.endpoints.getSingleCaseByLocator.initiate({
                  locator: args.groupInfo.locator
                })
              );
              const unwrappedResult = await dispatchResult.unwrap();
              // Need to be very careful here!
              // Circular type references means that this assertion is necessary, otherwise the type of the rtkqCasesApi is `any`
              const uuid = unwrappedResult.uuid as string;

              const proms = args.caseUuids.map((v) => {
                return apiService.apiPerformAddMemberToCase(uuid, v);
              });

              imperativeAddNotification({ message: 'Added cases to group' });

              await Promise.all(proms);

              return {
                data: {
                  uuid: uuid
                }
              };
            }
            if (args.groupInfo.groupOption === 'NEW') {
              const dispatchResult = api.dispatch(
                rtkqCasesApi.endpoints.createCase.initiate({
                  description: args.groupInfo.description,
                  org: args.groupInfo.orgUuid,
                  members: args.caseUuids
                })
              );

              const unwrappedResult = await dispatchResult.unwrap();
              const uuid = unwrappedResult.uuid as string;
              return {
                data: {
                  uuid: uuid
                }
              };
            }

            throw new Error(
              `No handler exists for string type: '${args.groupInfo}'`
            );
          } catch (err) {
            // This isn't great error handling.
            // Really we'd want a per-request kind of error handling - but then how do we display that?
            return await handleRtkqError('Error adding case to group');
          }
        }
      }),

      getCasesFiltered: builder.query<
        PaginatedLoadMoreFunctionResult<CydApi_BaseCase>,
        { filterOptions: CaseFilterParams }
      >({
        queryFn: async (arg, api, extraOptions) => {
          try {
            const result = await apiFetchCasesFiltered(arg.filterOptions);

            return {
              data: result
            };
          } catch (err) {
            imperativeAddNotification({
              message: ERROR_FETCHING_FILTERED_CASES
            });

            return {
              error: {
                status: 1,
                statusText: '',
                data: ERROR_FETCHING_FILTERED_CASES
              }
            };
          }
        },

        providesTags: (result, err, args) => {
          if (result) {
            return [
              {
                type: 'cases-filtered',
                id: JSON.stringify(args.filterOptions)
              },
              ...result.data.flatMap((v) => {
                return [
                  {
                    type: 'case-locator',
                    id: v.locator
                  } as const,
                  {
                    type: 'case-uuid',
                    id: v.uuid
                  } as const
                ];
              })
            ];
          }

          return [];
        }
      })
    };
  }
});

export function useSingleCaseByLocator(caseLocator: string | null) {
  const rtkqResult = rtkqCasesApi.endpoints.getSingleCaseByLocator.useQuery(
    { locator: caseLocator },
    {
      skip: !caseLocator
    }
  );

  return rtkqResult;
}

export function useSingleCaseRtkq(caseUuid: string) {
  const rtkqResult = rtkqCasesApi.endpoints.getSingleCaseByUuid.useQuery({
    caseUuid: caseUuid
  });

  return rtkqResult;
}

export function useCasesFiltered(
  filterOptions: CaseFilterParams,
  queryOptions: Parameters<
    typeof rtkqCasesApi.endpoints.getCasesFiltered.useQuery
  >[1]
) {
  const rtkqResult = rtkqCasesApi.endpoints.getCasesFiltered.useQuery(
    { filterOptions: filterOptions },
    queryOptions
  );

  return rtkqResult;
}

export function useCasesFilteredLazy() {
  return rtkqCasesApi.endpoints.getCasesFiltered.useLazyQuery();
}

export function useBatchAssignTagToCases() {
  return rtkqCasesApi.endpoints.batchAssignTagToCases.useMutation();
}

export function useBatchAssignUserToCases() {
  return rtkqCasesApi.endpoints.batchAssignUserToCases.useMutation();
}

export function useBatchAssignStatusToCases() {
  return rtkqCasesApi.endpoints.batchAssignStatusToCases.useMutation();
}

export function useBatchAddCasesToGroup() {
  return rtkqCasesApi.endpoints.batchAddCasesToGroup.useMutation();
}
