/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

export const uniqueArray = (array: any[]) => [...new Set(array)];
export const joinArray = (
  value: Array<string> | string,
  separator: string = ', '
) => (Array.isArray(value) ? value.join(separator) : value);

export function partitionArray<T>(
  list: T[],
  nPartions: number,
  partionFn: (value: T) => number | null
): Array<Array<T>> {
  const returnArray = new Array(nPartions).fill(true).map((v) => [] as T[]); // Note that the map is neccessary in order to create new array objects

  list.forEach((v) => {
    const index = partionFn(v);
    if (index === null) {
      //do nothing
    } else {
      if (index >= nPartions) {
        throw new Error(
          `partitionFn returned an out of range error, return value was ${index}, nPartions was ${nPartions}`
        );
      }
      returnArray[index].push(v);
    }
  });

  return returnArray;
}

const toString = (obj: unknown): string => {
  //ECMA specification: http://www.ecma-international.org/ecma-262/6.0/#sec-tostring

  if (obj === null) {
    return 'null';
  }

  if (typeof obj === 'boolean' || typeof obj === 'number') {
    return obj.toString();
  }

  if (typeof obj === 'string') {
    return obj;
  }

  if (typeof obj === 'symbol') {
    throw new TypeError();
  }

  if (typeof obj === 'object') {
    //we know we have an object. perhaps return JSON.stringify?
    //@ts-ignore - for some reason I'm getting a CLI type error here
    return obj.toString();
  }

  throw new TypeError();
};

// Copied from here:
// https://stackoverflow.com/questions/47334234/how-to-implement-array-prototype-sort-default-compare-function
export function defaultSortFunction(x: unknown, y: unknown): number {
  //INFO: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  //ECMA specification: http://www.ecma-international.org/ecma-262/6.0/#sec-sortcompare

  if (x === undefined && y === undefined) {
    return 0;
  }

  if (x === undefined) {
    return 1;
  }

  if (y === undefined) {
    return -1;
  }

  if (typeof x === 'number' && typeof y === 'number') {
    return x - y;
  }

  const xString = toString(x).toUpperCase();
  const yString = toString(y).toUpperCase();

  if (xString < yString) {
    return -1;
  }

  if (xString > yString) {
    return 1;
  }

  return 0;
}

export function allAreNull(arr) {
  let result = true;

  for (const value of arr) {
    if (value !== null) {
      result = false;
      break;
    }
  }

  return result;
}
