/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Acl } from 'interface/Acl.interface';
import { IDefaultSelectProps } from 'components/fields/DefaultSelect';
import { Select, MenuItem, FormHelperText } from '@mui/material';

interface IAclSelectProps extends Omit<IDefaultSelectProps, 'options'> {
  acls: Acl[];
  value?: Acl | string | null;
  disabled?: boolean;
  disabledPlaceholder?: boolean;
}

const mapAclToOptions = (acls: Acl[]) =>
  acls
    .filter(({ deprecated }) => !deprecated)
    .map((acl) => ({
      label: acl.description,
      value: acl
    }));

/**
 * @deprecated - Use CydAclSelectorInstead
 * @param param0
 * @returns
 */
export const AclSelect: React.FC<IAclSelectProps> = ({
  acls,
  value,
  helperText,
  disabledPlaceholder,
  placeholder = 'Select ACL',
  ...props
}) => {
  const aclOptions = React.useMemo(() => mapAclToOptions(acls), [acls]);
  const emptyValue = '';

  const _value =
    value && typeof value === 'object'
      ? acls.find(({ uuid }) => uuid === value.uuid)
      : emptyValue;

  return (
    <>
      <Select
        displayEmpty
        value={_value}
        {...props}
        placeholder={placeholder}
        SelectDisplayProps={{
          'aria-label': placeholder
        }}
      >
        <MenuItem value={emptyValue} disabled={disabledPlaceholder}>
          Select ACL
        </MenuItem>

        {aclOptions.map(
          ({ label, value }: { label: any; value: any }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          )
        )}
      </Select>
      {!!helperText && (
        <FormHelperText error={true}>{helperText}</FormHelperText>
      )}
    </>
  );
};
