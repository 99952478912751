/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { AtcPlaybook, PlaybookType } from 'interface/Playbook.interface';
import { List, Input } from '@mui/material';
import { usePlaybooksPagePlaybooksSidebarStyles } from './styles';
import { PlaybooksPagePlaybooksSidebarItem } from './PlaybooksPagePlaybooksSidebarItem';
import { useDispatch } from 'react-redux';
import { createPlaybookByFile } from 'states/playbooks/actions';
import { CydButton } from 'components/_formElements/CydButton/CydButton';

/** @jsxImportSource @emotion/react */
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { useFeatureToggles } from 'hooks/FeatureFlagHooks';
import { CreateATCPlaybookModal } from '../CreatePlaybookModal/CreateATCPlaybookModal';
import { useCurrentDataAcls, useDefaultAclForOrg } from 'hooks/AclHooks';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CreatePlaybookModal } from '../CreatePlaybookModal/CreatePlaybookModal';

export interface IPlaybookData {
  description: string;
  name: string;
  acl?: string;
}

interface IPlaybooksPagePlaybooksSidebarProps {
  playbooks: PlaybookType[];
  onPlaybookSelect: (playbook: AtcPlaybook) => void;
  onCreatePlaybook: (
    playbookData: { atc: IPlaybookData } | { cacao: Record<string, unknown> }
  ) => void;
  selectedPlaybookUuid?: string;
}

export const PlaybooksPagePlaybooksSidebar: React.FC<
  IPlaybooksPagePlaybooksSidebarProps
> = ({
  playbooks,
  onPlaybookSelect,
  onCreatePlaybook,
  selectedPlaybookUuid
}) => {
  const dispatch = useDispatch();
  const { isToggleEnabled } = useFeatureToggles();
  const { fileInput } = usePlaybooksPagePlaybooksSidebarStyles();
  const aclListResult = useCurrentDataAcls();
  const defaultAclResult = useDefaultAclForOrg();
  const [createPlaybook, setCreatePlaybook] = React.useState(false);

  const handleCreatePlaybook = (
    playbookData: { atc: IPlaybookData } | { cacao: Record<string, unknown> }
  ) => {
    onCreatePlaybook(playbookData);
    setCreatePlaybook(false);
  };

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const fileInputRef = React.useRef<any>();
  const handleRequestUploadFile = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    var successMsg = '';
    const filesArr = Array.prototype.slice.call(event.target.files);
    filesArr &&
      filesArr.forEach((file) => {
        formData.append('filelist', file);
        if (file) {
          successMsg = `${successMsg} File name: ${file.name} successfully imported\n`;
        }
      });

    !!successMsg
      ? dispatch(
          createPlaybookByFile({
            fileList: formData,
            successMessage: successMsg
          })
        )
      : dispatch(createPlaybookByFile({ fileList: formData }));

    event.target.value = null;
    return;
  };

  const isUsingCacaoPlaybooks = isToggleEnabled(
    'REACT_APP_USE_CACAO_PLAYBOOKS'
  );

  const isLoading =
    aclListResult.isLoading ||
    defaultAclResult.isLoading ||
    !aclListResult.hasLoaded;

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <CydButtonStack direction={'column'}>
            {!isUsingCacaoPlaybooks && (
              <>
                <CydButton
                  ref={buttonRef}
                  onClick={() => setCreatePlaybook(true)}
                  startIcon="add"
                  fullWidth
                >
                  Create a new playbook
                </CydButton>

                <CreateATCPlaybookModal
                  isOpen={createPlaybook}
                  onClose={() => setCreatePlaybook(false)}
                  defaultAcl={defaultAclResult.data}
                  availableAcls={aclListResult.data}
                  onCreatePlaybook={(data) => {
                    handleCreatePlaybook({
                      atc: {
                        name: data.name,
                        description: data.description,
                        acl: data.acl.uuid
                      }
                    });
                  }}
                />
              </>
            )}

            {isUsingCacaoPlaybooks && (
              <CreatePlaybookModal
                defaultAcl={defaultAclResult.data}
                onAtcCreate={(data) => {
                  handleCreatePlaybook({
                    atc: {
                      name: data.name,
                      description: data.description,
                      acl: data.acl.uuid
                    }
                  });
                }}
                acls={aclListResult.data}
              />
            )}
            <CydButton
              startIcon="publish"
              onClick={handleRequestUploadFile}
              fullWidth
            >
              Upload Playbook or Action
            </CydButton>
            <Input
              name="file"
              type="file"
              className={fileInput}
              onChange={handleFileChange}
              inputRef={fileInputRef}
              inputProps={{
                multiple: true
              }}
            />
          </CydButtonStack>
          <div>
            <List>
              {playbooks?.map((playbook) => {
                if (playbook.atc) {
                  return (
                    <PlaybooksPagePlaybooksSidebarItem
                      key={playbook.atc.uuid}
                      onPlaybookSelect={onPlaybookSelect}
                      playbook={playbook.atc}
                      selected={selectedPlaybookUuid === playbook.atc.uuid}
                    />
                  );
                }

                if (playbook.cacao) {
                  if (isUsingCacaoPlaybooks) {
                    return (
                      <PlaybooksPagePlaybooksSidebarItem
                        key={playbook.cacao.uuid}
                        onPlaybookSelect={onPlaybookSelect}
                        playbook={playbook.cacao}
                        selected={selectedPlaybookUuid === playbook.cacao.uuid}
                      />
                    );
                  } else {
                    return null;
                  }
                }

                throw new Error('Encountered a non-CACAO, non-ATC playbook');
              })}
            </List>
          </div>
        </div>
      )}
    </>
  );
};
