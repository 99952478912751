/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
const ERROR_MESSAGE = {
  FETCH_CASE_PLAYBOOKS_ERROR: {
    code: 'FETCH_CASE_PLAYBOOKS_ERROR',
    message: 'Error occurred while fetching case playbooks'
  },
  UPDATE_CASE_PLAYBOOK_ERROR: {
    code: 'UPDATE_CASE_PLAYBOOK_ERROR',
    message: 'Error occurred while updating case playbook'
  },
  FETCH_CASE_PLAYBOOK_BY_UUID_ERROR: {
    code: 'FETCH_CASE_PLAYBOOK_BY_UUID_ERROR',
    message: 'Error occurred while fetching case playbook by uuid'
  },
  ADD_PLAYBOOK_TO_CASE_ERROR: {
    code: 'ADD_PLAYBOOK_TO_CASE',
    message: 'Error occurred while adding playbook to case'
  },
  REMOVE_PLAYBOOK_FROM_CASE_ERROR: {
    code: 'REMOVE_PLAYBOOK_FROM_CASE',
    message: 'Error occurred while removing playbook from case'
  },
  FETCH_CASE_PLAYBOOK_ACTION_STATUSES_ERROR: {
    code: 'FETCH_CASE_PLAYBOOK_ACTION_STATUSES_ERROR',
    message: 'Error occurred while fetching case playbook action statuses'
  },
  UPDATE_CASE_PLAYBOOK_ACTION_STATUS_ERROR: {
    code: 'UPDATE_CASE_PLAYBOOK_ACTION_STATUS_ERROR',
    message: 'Error occurred while updating case playbook action status'
  },
  REMOVE_CASE_PLAYBOOK_ACTION_TAG_ERROR: {
    code: 'REMOVE_CASE_PLAYBOOK_ACTION_TAG_ERROR',
    message: 'Error occurred while removing case playbook action tag'
  },
  ADD_CASE_PLAYBOOK_ACTION_TAG_ERROR: {
    code: 'ADD_CASE_PLAYBOOK_ACTION_TAG_ERROR',
    message: 'Error occurred while adding case playbook action tag'
  }
};

export { ERROR_MESSAGE };
