/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchSamlLoginUrl } from './actions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SamlLoginReducerState {
  url: string | null;
  isLoading?: boolean;
}

const initialState: SamlLoginReducerState = {
  url: null,
  isLoading: false
};

const samlLoginSlice = createSlice({
  name: 'samlLogin',
  initialState,
  reducers: {
    fetchSamlLoginUrlSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.url = payload;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchSamlLoginUrl, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: samlLogin,
  actions: { fetchSamlLoginUrlSuccess }
} = samlLoginSlice;
