/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import * as React from 'react';
import { DefaultDialog } from 'components/DefaultDialog';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';

interface ICommentDialog {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onFileUpload: (string) => void;
  charLimit: number;
}

export const CommentDialog: React.FC<ICommentDialog> = ({
  isOpened,
  onClose,
  onFileUpload,
  onSubmit,
  charLimit
}) => {
  const [dialogOptionValue, setDialogOptionValue] = React.useState('edit');
  const [uploadFileName, setUploadFileName] = React.useState('');

  const handleChange = (event) => {
    setDialogOptionValue(event.target.value);
  };
  const handleFileNameChange = (event) => {
    setUploadFileName(event.target.value);
  };

  const handleDialogSubmit = () => {
    if (dialogOptionValue === 'file') {
      onFileUpload(uploadFileName);
    } else if (dialogOptionValue === 'continue') {
      onSubmit();
    }
    setUploadFileName('');
    setDialogOptionValue('edit');
    onClose();
    return;
  };

  const handleCloseDialog = () => {
    setUploadFileName('');
    setDialogOptionValue('edit');
    onClose();
    return;
  };

  const dialogChildren = (
    <React.Fragment>
      <FormControl component="fieldset">
        <RadioGroup
          defaultValue="edit"
          name="radio-buttons-group"
          onChange={handleChange}
        >
          <FormControlLabel
            value="edit"
            control={<Radio />}
            label="Edit the text"
          />
          <FormControlLabel
            value="file"
            control={<Radio />}
            label="Upload the text as a file"
          />
          {dialogOptionValue === 'file' && (
            <TextField
              label="File name"
              variant="outlined"
              onChange={handleFileNameChange}
            />
          )}
          <FormControlLabel
            value="continue"
            control={<Radio />}
            label="Continue"
          />
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );

  const dialogActions = (
    <CydButtonStack justifyContent="flex-end">
      <CydButton onClick={handleCloseDialog}>Cancel</CydButton>
      <CydButton variant="contained" onClick={handleDialogSubmit}>
        Submit
      </CydButton>
    </CydButtonStack>
  );

  return (
    <DefaultDialog
      open={isOpened}
      onClose={onClose}
      title={`The comment is over the soft limit of ${charLimit} characters. Please select an option:`}
      children={dialogChildren}
      actions={dialogActions}
    />
  );
};
