/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CYDARM_BASE_API } from '../../constants';
import { authzHeader } from 'utils/CydarmFetch';
import * as TokenManager from 'utils/TokenManager';
import {
  BaseAPI,
  Configuration,
  type JsonApiError,
  type JsonApiLinks,
  type JsonApiServerImpl
} from 'generatedApi';

interface BaseTopLevel {
  links?: JsonApiLinks | null;
  jsonapi?: JsonApiServerImpl | null;
  included?: Array<object>;
  errors?: Array<JsonApiError>;
}

interface BaseTopLevelItem<Fields> extends BaseTopLevel {
  data?: Fields | null;
}

interface BaseTopLevelList<Fields> extends BaseTopLevel {
  data?: Array<Fields & { uuid: string }>;
}

type ServiceName = 'eventTrigger' | 'connectorOperationType';

export abstract class AbstractOpenApiService<Fields, OpenApiResource> {
  private serviceName: ServiceName;

  private get configuration() {
    return new Configuration({
      basePath: `${CYDARM_BASE_API}/v0.9`,
      headers: {
        [authzHeader]: TokenManager.getAccessToken()
      }
    });
  }

  constructor(serviceName: ServiceName) {
    this.serviceName = serviceName;
  }

  // Abstract methods
  protected abstract createApi(config: Configuration): BaseAPI;

  public abstract transformFieldsToOpenApiResource(
    data: Partial<Fields>
  ): OpenApiResource;
  public abstract transformOpenApiResourceToFields(
    resource: OpenApiResource
  ): Fields;

  async get(query: {
    pageNumber: number;
    pageSize: number;
  }): Promise<BaseTopLevelList<Fields>> {
    const api = this.createApi(this.configuration);
    const result = await api[`${this.serviceName}Get`](query);
    return {
      ...result,
      data: result.data.map((item: OpenApiResource) =>
        this.transformOpenApiResourceToFields(item)
      )
    };
  }

  async getByUuid(id: string) {
    const api = this.createApi(this.configuration);
    const result = await api[`${this.serviceName}UuidGet`]({ uuid: id });
    return {
      ...result,
      data: this.transformOpenApiResourceToFields(result)
    };
  }

  async create(data: Fields): Promise<BaseTopLevelItem<OpenApiResource>> {
    const api = this.createApi(this.configuration);
    const result = await api[`${this.serviceName}Post`]({
      [`${this.serviceName}RequestTopLevel`]: {
        data: this.transformFieldsToOpenApiResource(data)
      }
    });
    return {
      ...result,
      data: this.transformOpenApiResourceToFields(result)
    };
  }

  async update(uuid: string, data: Fields): Promise<OpenApiResource> {
    const api = this.createApi(this.configuration);
    const result = await api[`${this.serviceName}UuidPatch`]({
      uuid,
      [`${this.serviceName}PatchTopLevel`]: {
        data: this.transformFieldsToOpenApiResource(data)
      }
    });
    return {
      ...result,
      data: this.transformOpenApiResourceToFields(result)
    };
  }

  async delete(uuid: string): Promise<void> {
    const api = this.createApi(this.configuration);
    await api[`${this.serviceName}UuidDelete`]({ uuid });
  }
}
