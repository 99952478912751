/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Button, Typography } from '@mui/material';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydIconButton } from '../CydIconButton/CydIconButton';

export type CydFileUploaderProps = {
  maxFiles?: number;
  multiple?: boolean;
  accept?: Exclude<Parameters<typeof useDropzone>[0], undefined>['accept'];
  name?: string;
  required?: boolean;
  className?: string;
  files?: Array<File>;
  onFilesAdded?: (files: Array<File>) => void;
};

export const CydFileUploader = (props: CydFileUploaderProps) => {
  const {
    maxFiles,
    multiple = false,
    accept,
    className,
    files = [],
    onFilesAdded
  } = props;

  const onDrop = useCallback(
    (incomingFiles: Array<File>) => {
      if (onFilesAdded) {
        onFilesAdded(incomingFiles);
      }
    },
    [onFilesAdded]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept,
    ...(maxFiles ? { maxFiles } : {}),
    multiple,
    useFsAccessApi: false
  });

  if (files.length > 0) {
    return (
      <div
        className={className}
        css={(theme) => `
    background-color: ${
      isDragActive
        ? theme.palette.background.paper
        : theme.palette.background.default
    }; 
    border: solid 1px ${theme.other.border};
    border-radius: 8px;
    padding: ${theme.spacing(2)};
    color: ${theme.palette.text.primary};
    margin: ${theme.spacing(1)} 0;
    `}
      >
        {files.map((v, i) => {
          return (
            <div
              key={v.name}
              css={css`
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <Typography variant="body2">{v.name}</Typography>{' '}
              <CydIconButton
                icon="delete"
                label="Delete"
                onClick={() => {
                  if (onFilesAdded) {
                    const newArray = [...files];
                    newArray.splice(i, 1);
                    onFilesAdded(newArray);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={className}
      data-testid="cyd-file-uploader"
      css={(theme) => `
    display: flex;
    flex-flow: column nowrap; 
    align-items: center; 
    justify-content: center;
    gap: 1em;
    margin: ${theme.spacing(1)} 0;

    height: 180px; 

    background-color: ${
      isDragActive
        ? theme.palette.background.paper
        : theme.palette.background.default
    }; 
    border: solid 1px ${theme.other.border};
    border-radius: 8px; 
    color: ${theme.palette.text.primary};

    

  `}
      {...getRootProps()}
    >
      <CydIcon icon="fileUpload" />
      <Typography>Drag your files here to upload</Typography>
      <input {...getInputProps()} />
      <Button
        variant="outlined"
        onClick={open}
        css={css`
          position: relative;
        `}
      >
        Browse files
      </Button>
    </div>
  );
};
