/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydTextField } from 'components/_formElements/CydTextField';
import { CydAclSelector } from 'components/_objectSelectors/CydAclSelector/CydAclSelector';
import { Acl } from 'interface/Acl.interface';
import React from 'react';

/** @jsxImportSource @emotion/react */
import { AtcPlaybook } from 'interface/Playbook.interface';
import { CydSimpleFormModal } from 'components/_dialogs/CydSimpleFormModal/CydSimpleFormModal';
import { Box, Stack, Typography } from '@mui/material';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

export type CreatePlaybookData = {
  name: string;
  description: string;
  acl: Acl;
};

export type CreatePlaybookFormProps = {
  availableAcls: Array<Acl>;
  defaultAcl: Acl | null;
  playbook?: AtcPlaybook;
  isOpen: boolean;
  onClose: () => void;
  onCreatePlaybook: (data: CreatePlaybookData) => void;
};

export const CreateATCPlaybookModal = (props: CreatePlaybookFormProps) => {
  const { availableAcls, onCreatePlaybook, defaultAcl, isOpen, onClose } =
    props;

  return (
    <CydSimpleFormModal
      size="large"
      isOpen={isOpen}
      onFormSubmit={(e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const acl = formData.get('acl')?.toString();
        const name = formData.get('name')?.toString();
        const description = formData.get('atc-description')?.toString();

        const foundAcl = availableAcls.find((v) => v.uuid === acl);
        if (!foundAcl) {
          throw new Error('No ACL found');
        }

        if (!name) {
          throw new Error('No name found');
        }

        if (!description) {
          throw new Error('No description found');
        }

        onCreatePlaybook({
          name,
          description,
          acl: foundAcl
        });
      }}
      onClose={onClose}
    >
      <Stack direction={'column'} height={'500px'}>
        <Typography variant="h5" gutterBottom>
          Create ATC Playbook
        </Typography>
        <CydTextField autoFocus name="name" required label="Playbook Name" />
        <Box height={'400px'} border={'1px'} position={'relative'}>
          <CydEnhancedMarkdownEditor
            name="atc-description"
            initialValue={''}
            label="Playbook description"
          />
        </Box>
        <CydAclSelector
          required
          availableAcls={availableAcls}
          defaultSelectedAclUuid={defaultAcl?.uuid ?? null}
          name="acl"
        />
        <CydButtonStack justifyContent={'flex-end'}>
          <CydButton type="submit">Submit</CydButton>
        </CydButtonStack>
      </Stack>
    </CydSimpleFormModal>
  );
};
