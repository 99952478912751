/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Base64Decode, Base64Encode } from './StringUtils';

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

//https://stackoverflow.com/a/37164538/1068446
export function mergeDeep(target, source) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export function clone<T extends {}>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 *
 * Just an implementation of this function
 * https://ramdajs.com/docs/#pathOr
 * @param fallBack
 * @param path
 * @param obj
 */
export function pathOr(
  fallBack: unknown,
  path: Array<string | number>,
  obj: any
): any {
  let current = obj;

  for (let x of path) {
    try {
      current = current[x];
    } catch (err) {
      return fallBack;
    }
  }

  return current;
}

export class ObjectUtils {
  public static removeNullAndUndefinedFields(obj: object): object {
    const newObj = {};

    for (let key in obj) {
      if (obj[key] !== null && obj[key] !== undefined) {
        newObj[key] = obj[key];
      }
    }

    return newObj;
  }

  /*
   * Encodes the values of all "template" fields to Base64 strings.
   *
   * Example:
   * Input:
   * {
   *   "url_path": {
   *     "template": "https://www.cydarm.com"
   *   },
   *   "method": "POST"
   * }
   *
   * Output:
   * {
   *   "url_path": {
   *     "template": "aHR0cHM6Ly93d3cuY3lkYXJtLmNvbQ=="
   *   },
   *   "method": "POST"
   * }
   */
  public static encodeTemplateStringsToBase64<T extends object>(obj: T): T {
    const newObj = clone(obj);
    const keys = Object.keys(newObj);

    for (const key of keys) {
      const value = newObj[key];

      if (Array.isArray(value)) {
        newObj[key] = value.map((v) => this.encodeTemplateStringsToBase64(v));
      } else if (isObject(value)) {
        newObj[key] = this.encodeTemplateStringsToBase64(value);
      } else if (typeof value === 'string') {
        if (key === 'template') {
          newObj[key] = Base64Encode(value);
        } else {
          newObj[key] = value;
        }
      }
    }
    return newObj;
  }

  /*
   * Decodes Base64 strings in all "template" fields to their original string values.
   *
   * Example:
   * Input:
   * {
   *   "url_path": {
   *     "template": "aHR0cHM6Ly93d3cuY3lkYXJtLmNvbQ=="
   *   },
   *   "method": "POST"
   * }
   *
   * Output:
   * {
   *   "url_path": {
   *      "template": "https://www.cydarm.com"
   *   },
   *   "method": "POST"
   * }
   */
  public static decodeTemplatesBase64ToStrings<T extends object>(obj: T): T {
    const newObj = clone(obj);
    const keys = Object.keys(newObj);

    for (const key of keys) {
      const value = newObj[key];

      if (Array.isArray(value)) {
        newObj[key] = this.decodeTemplatesBase64ToStrings(value);
      } else if (isObject(value)) {
        newObj[key] = this.decodeTemplatesBase64ToStrings(value);
      } else if (typeof value === 'string') {
        if (key === 'template') {
          newObj[key] = Base64Decode(value);
        } else {
          newObj[key] = value;
        }
      }
    }
    return newObj;
  }
}
