/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { DataSignificance } from 'interface/DataSignificance.interface';
import {
  IDefaultCheckboxProps,
  DefaultCheckbox
} from 'components/fields/DefaultCheckbox';

interface IDataSignificanceCheckboxProps
  extends Omit<IDefaultCheckboxProps, 'options'> {
  dataSignificances: DataSignificance[];
}

export const DataSignificanceCheckbox: React.FC<
  IDataSignificanceCheckboxProps
> = ({ dataSignificances, value, ...rest }) => {
  const dataSignificanceOptions = React.useMemo(() => {
    return dataSignificances.map((dataSignificance) => ({
      value: dataSignificance,
      label: dataSignificance.name
    }));
  }, [dataSignificances]);

  const _value =
    value &&
    dataSignificances.filter(({ uuid }) =>
      (value as DataSignificance[]).some(
        (dataSignificance) => dataSignificance.uuid === uuid
      )
    );

  return (
    <DefaultCheckbox
      {...rest}
      value={_value}
      options={dataSignificanceOptions}
    />
  );
};
