/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { put, takeLatest, takeLeading } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import { ERROR_MESSAGE } from './errors';
import { createFetchGroups, createGroup, createDeleteGroup } from './actions';
import { clearGroups, fetchGroupsSuccess } from './slice';
import {
  apiCreateGroup,
  apiDeleteGroup,
  apiFetchGroups
} from 'services/GroupsServices';

function* performFetchGroups() {
  yield put(clearGroups());
  try {
    const { json } = yield apiFetchGroups();
    yield put(fetchGroupsSuccess(json));
  } catch (ex) {
    yield put(
      addNotification({ message: ERROR_MESSAGE.FETCH_GROUPS_ERROR.message })
    );
  }
}

function* performCreateGroup(action) {
  const {
    payload: { name, description, category, authSource }
  } = action;

  try {
    yield apiCreateGroup({
      name,
      description,
      category,
      authSource
    });
    yield put(addNotification({ message: `Group ${name} has been created` }));
    yield put(createFetchGroups());
  } catch (ex) {
    yield put(addNotification({ message: 'Failed to create new group' }));
  }
}

function* performDeleteGroup(action) {
  const { payload: group } = action;
  try {
    yield apiDeleteGroup(group.uuid);
    yield put(addNotification({ message: `${group.name} has been deleted` }));
  } catch (ex) {
    yield put(
      addNotification({ message: `Failed to delete the ${group.name}` })
    );
  }
}

/* Watchers */
function* watchFetchGroups() {
  yield takeLeading(createFetchGroups, performFetchGroups);
}

function* watchCreateGroup() {
  yield takeLatest(createGroup, performCreateGroup);
}

function* watchDeleteGroup() {
  yield takeLatest(createDeleteGroup, performDeleteGroup);
}

export default [watchFetchGroups(), watchCreateGroup(), watchDeleteGroup()];
