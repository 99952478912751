/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useDefaultTableStyles = makeStyles(
  ({ spacing, palette: { text } }) => ({
    tableHeader: {
      fontSize: 13,
      fontWeight: 600,
      paddingLeft: spacing(),
      paddingRight: spacing()
    },
    headerWithFilter: {
      display: 'flex'
    },
    headerFilterIcon: {
      marginRight: spacing()
    },
    headerSortIcon: {
      fill: text.primary,
      margin: 0
    },
    tablePagination: {
      color: text.secondary
    },
    tablePaginationSelectRoot: {
      color: text.secondary
    },
    tablePaginationSelect: {
      color: text.secondary
    },
    tablePaginationInput: {
      color: text.secondary
    },
    tablePaginationSelectIcon: {
      color: text.secondary
    },

    tablePaginationFooter: {
      color: text.secondary
    },
    tablePaginationActions: {
      color: text.secondary
    }
  })
);

export const tableBorderBottom = (theme: Theme) => ``;
