/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydSomethingWentWrong } from 'components/_placeholders/CydSomethingWentWrong/CydSomethingWentWrong';
import { CydarmRoute } from 'interface/CydarmRoute';
import { isAuthenticated } from 'utils/TokenManager';

type ErrorBoundaryProps = {
  /**
   * Component to show when an error occurs
   */
  FallbackComponent?: React.ComponentType<{}>; // Future state - allow this component to accept the error object

  /**
   * Container component for both the regular content and the error
   */
  ContainerComponent?: React.ComponentType<React.PropsWithChildren<{}>>;
};

const DefaultContainer = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  return <div>{children}</div>;
};

const DefaultError = (props: { clearErrorBoundary: () => void }) => {
  return (
    <div
      css={css`
        margin: 200px auto;
        max-width: 800px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      `}
    >
      <CydSomethingWentWrong clearErrorBoundary={props.clearErrorBoundary} />
    </div>
  );
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  {
    hasError: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    /**
     * If things are erroring because they are not authenticated, redirect them to the login page.
     *
     */
    if (!window.location.pathname.startsWith('/auth') && !isAuthenticated()) {
      window.location.replace(
        `${CydarmRoute.AUTH}?redirect=${window.location.pathname}${window.location.search}`
      );
      return {
        hasError: false
      };
    }

    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }

  componentDidCatch(error, errorInfo) {
    // Any error reporting here
  }

  render() {
    const {
      FallbackComponent = DefaultError,
      ContainerComponent = DefaultContainer
    } = this.props;

    if (this.state?.hasError) {
      // You can render any custom fallback UI

      return (
        <ContainerComponent>
          <FallbackComponent
            clearErrorBoundary={() => {
              this.setState({ hasError: false });
            }}
          />
        </ContainerComponent>
      );
    }

    return <ContainerComponent>{this.props.children}</ContainerComponent>;
  }
}
