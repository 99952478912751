/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { useDefaultPopoverStyles } from './styles';
import clsx from 'clsx';

export interface IDefaultPopoverProps
  extends Omit<PopoverProps, 'open' | 'onSubmit'> {
  isOpened: boolean;
  actions?: React.ReactNode;
  className?: string;
}

export const DefaultPopover: React.FC<IDefaultPopoverProps> = ({
  isOpened,
  actions,
  children,
  className,
  ...rest
}) => {
  const { container, actionsContainer } = useDefaultPopoverStyles();

  let _isOpened = false;
  if (isOpened !== undefined) {
    _isOpened = isOpened;
  }
  return (
    <Popover
      classes={{ paper: clsx(container, className) }}
      open={_isOpened}
      disableRestoreFocus
      {...rest}
    >
      {children}
      {actions && <div className={actionsContainer}>{actions}</div>}
    </Popover>
  );
};
