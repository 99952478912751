/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCaseForms } from './actions';

export type FormObject = {
  uuid: string;
  name: string;
  precedence: string;
  fields: string;
  acl: string;
};

export interface ICaseForms {
  data: FormObject[];
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: ICaseForms = {
  data: [],
  isLoading: false,
  hasLoaded: false
};

const caseFormsSlice = createSlice({
  name: 'caseForms',
  initialState,
  reducers: {
    fetchCaseFormsSuccess: (
      state,
      { payload }: PayloadAction<FormObject[]>
    ) => ({ ...state, isLoading: false, hasLoaded: true, data: payload }),
    createCaseFormSuccess: (state, { payload: createdCaseForm }) => {
      return {
        ...state,
        data: state.data.concat({
          ...createdCaseForm,
          fields: btoa(createdCaseForm.fields)
        })
      };
    }
  },
  extraReducers: (builder) =>
    builder.addCase(fetchCaseForms, (state) => ({ ...state, isLoading: true }))
});

export const {
  reducer: caseFormsStore,
  actions: { fetchCaseFormsSuccess, createCaseFormSuccess }
} = caseFormsSlice;
