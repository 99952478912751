/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JsonApiToOneRelationship } from './JsonApiToOneRelationship';
import {
    JsonApiToOneRelationshipFromJSON,
    JsonApiToOneRelationshipFromJSONTyped,
    JsonApiToOneRelationshipToJSON,
} from './JsonApiToOneRelationship';

/**
 * 
 * @export
 * @interface EventTriggerResourceRelationships
 */
export interface EventTriggerResourceRelationships {
    /**
     * 
     * @type {JsonApiToOneRelationship}
     * @memberof EventTriggerResourceRelationships
     */
    acl?: JsonApiToOneRelationship | null;
    /**
     * 
     * @type {JsonApiToOneRelationship}
     * @memberof EventTriggerResourceRelationships
     */
    operation_type?: JsonApiToOneRelationship | null;
    /**
     * 
     * @type {JsonApiToOneRelationship}
     * @memberof EventTriggerResourceRelationships
     */
    connector?: JsonApiToOneRelationship | null;
}

/**
 * Check if a given object implements the EventTriggerResourceRelationships interface.
 */
export function instanceOfEventTriggerResourceRelationships(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventTriggerResourceRelationshipsFromJSON(json: any): EventTriggerResourceRelationships {
    return EventTriggerResourceRelationshipsFromJSONTyped(json, false);
}

export function EventTriggerResourceRelationshipsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTriggerResourceRelationships {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acl': !exists(json, 'acl') ? undefined : JsonApiToOneRelationshipFromJSON(json['acl']),
        'operation_type': !exists(json, 'operation_type') ? undefined : JsonApiToOneRelationshipFromJSON(json['operation_type']),
        'connector': !exists(json, 'connector') ? undefined : JsonApiToOneRelationshipFromJSON(json['connector']),
    };
}

export function EventTriggerResourceRelationshipsToJSON(value?: EventTriggerResourceRelationships | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acl': JsonApiToOneRelationshipToJSON(value.acl),
        'operation_type': JsonApiToOneRelationshipToJSON(value.operation_type),
        'connector': JsonApiToOneRelationshipToJSON(value.connector),
    };
}

