/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { Checkbox, FormControlLabel } from '@mui/material';
import { useMemo } from 'react';

export const BooleanField = ({
  value,
  onChange,
  className,
  label,
  ...props
}) => {
  const handleChange = (e) => {
    onChange({ target: { value: e.target.checked } });
  };
  const _value = useMemo(() => (value === '' ? false : value), [value]);
  return (
    <FormControlLabel
      className={className}
      label={label}
      control={
        <Checkbox
          checked={_value}
          value={_value}
          onChange={handleChange}
          {...props}
        />
      }
    />
  );
};
