/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { takeLatest, put, call } from 'redux-saga/effects';
import { createGenerateReport } from './actions';
import { addNotification } from 'states/notifications/slice';
import { apiGenerateReport } from 'services/CaseServices';
import { invalidateCaseActivity } from 'states/cases/sagas';

function* generateReportSaga(action) {
  const {
    payload: { caseUuid, locator, ...data }
  } = action;
  try {
    const response: Response = yield apiGenerateReport(caseUuid, { ...data });
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    const blob = yield response.blob();
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = locator;
    // attempt to retrieve filename
    const contentDisp: string | null = response.headers.get(
      'Content-Disposition'
    );
    if (contentDisp != null) {
      const parts: string[] = contentDisp.split('=');
      if (parts.length > 1) {
        anchor.download = parts[1];
      }
    }
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    yield call(invalidateCaseActivity, caseUuid);

    yield put(addNotification({ message: 'Generated report successfully' }));
  } catch (error) {
    yield put(addNotification({ message: 'Failed to generate report' }));
  }
}

/* Watchers */

function* watchGenerateReport() {
  yield takeLatest(createGenerateReport, generateReportSaga);
}

export default [watchGenerateReport()];
