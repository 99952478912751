/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import { put, takeLeading } from 'redux-saga/effects';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import { fetchCaseSeverityFailure, fetchCaseSeveritySuccess } from './slice';
import { createFetchCaseSeverity } from './actions';
import { apiPerformFetchCaseSeverity } from 'services/CaseServices';

function* performFetchCaseSeverity() {
  try {
    const { json } = yield apiPerformFetchCaseSeverity();
    yield put(fetchCaseSeveritySuccess(json));
  } catch (ex) {
    yield put(fetchCaseSeverityFailure());
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_CASE_SEVERITY_ERROR.message
      })
    );
  }
}

function* watchFetchCaseSeverity() {
  yield takeLeading(createFetchCaseSeverity, performFetchCaseSeverity);
}

export default [watchFetchCaseSeverity()];
