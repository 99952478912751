/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React, { useEffect, useRef } from 'react';
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import { convertClipboardDataToGfmTable } from 'utils/convertClipboardDataToGfmTable';
import { mdiSpellcheck } from '@mdi/js';
import { iconToSvg } from './iconToSvg';
import FormHelperText from '@mui/material/FormHelperText';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export type CydEnhancedMarkdownEditorProps = {
  name?: string;
  initialValue?: string;
  autoFocus?: boolean;
  label?: string;
  height?: string;
  onChange?: (value: string) => void;
  error?: string;
};

/**
 * This component is a reimplementation of the EasyMDE/CydMarkdownEditor, as the other one is super confusing.
 * @param props
 * @returns
 */
export const CydEnhancedMarkdownEditor = (
  props: CydEnhancedMarkdownEditorProps
) => {
  const { name, initialValue, label, height = '100%', onChange, error } = props;

  const ref = useRef<HTMLTextAreaElement>(null);

  const handleSpellcheckToggle = (editor: EasyMDE) => {
    // toggle the spellcheck attribute on the textarea
    const toggle = !editor.codemirror.getOption('spellcheck');
    editor.codemirror.setOption('spellcheck', toggle);

    const spellCheckIconClassList =
      document.getElementById('spell-check-icon')?.classList;
    if (toggle) {
      spellCheckIconClassList?.add('active');
    } else {
      spellCheckIconClassList?.remove('active');
    }
  };

  useEffect(() => {
    // This is pretty hacky
    // And basically I've had it with JSDOM

    // The problem:
    // Cypress's .clear method will not work if the inputStyle is textarea
    // JSDOM doesn't support contenteditable at all, so we have to use textarea there
    // So basically for Cypress tests we want this to be inputStyle: 'contenteditable'
    // For RTL tests it should be 'textarea'

    // See: https://github.com/jsdom/jsdom/issues/1670

    // We inspect the userAgent to find out which is which
    // We can remove all of this once we have no RTL tests that are relying on the markdown editor.

    const envIsJsdom = navigator.userAgent.includes('jsdom');
    let easyMDE: EasyMDE | undefined;

    if (ref.current) {
      easyMDE = new EasyMDE({
        autofocus: props.autoFocus ?? true,
        element: ref.current,
        initialValue,
        nativeSpellcheck: true, // using the native spellcheck will add the spellcheck=true attribute to the textarea
        spellChecker: false, // we want to avoid loading the codemirror spellchecker as this downloads dictionary files from a CDN which violates our CSP
        autoDownloadFontAwesome: false,

        // See note above
        inputStyle: envIsJsdom ? 'textarea' : 'contenteditable',

        toolbar: [
          'bold',
          'italic',
          'heading',
          '|',
          'quote',
          'unordered-list',
          'ordered-list',
          '|',
          'link',
          'image',
          '|',
          {
            name: 'spellcheck',
            action: (editor, ...rest) => {
              handleSpellcheckToggle(editor);
            },

            className: 'spell-check-icon active',
            title: 'Toggle Spell Check',
            icon: iconToSvg(mdiSpellcheck),
            attributes: {
              id: 'spell-check-icon'
            }
          }
        ],
        status: [
          'lines',
          'words',
          {
            className: 'cy_character_count',
            defaultValue: (el) => {
              el.innerHTML = initialValue?.length.toString() ?? '0';
            },
            onUpdate: (el) => {
              const chars = easyMDE?.value()?.length ?? 0;
              el.innerHTML = chars.toString();
            }
          },
          'cursor'
        ],
        minHeight: '0px'
      });

      // Traverse the DOM upwards to find the form parent, if any
      function findFormParent(element): HTMLFormElement | null {
        while (element) {
          if (element.tagName === 'FORM') {
            return element;
          }
          element = element.parentNode;
        }
        return null; // No <form> element found in the ancestors
      }
      const formElement = findFormParent(ref.current);

      // Fix for issue where reseting the form doesn't fire with the current text value.
      // See this test: https://github.com/cydarm/cydarm-frontend/blob/0c62139afedfbb4f95529382fab0c1a9768c14f5/src/pages/WikiPage/WikiPageCreateAndEdit/WikiPageCreateAndEdit.test.tsx#L161-L198
      //
      formElement?.addEventListener('reset', () => {
        // First make the value be the current mde value (so reset will fire with the correct value)
        if (ref.current && easyMDE) {
          ref.current.value = easyMDE.value();
        }

        // And then reset it back to initial value
        setTimeout(() => {
          easyMDE?.value(initialValue ?? '');
        }, 0);
      });

      /**
       * Handlers for cmd+enter, ctrl+enter
       */
      const submitHandler = () => {
        if (formElement) {
          // Dispatch a submit event
          formElement.dispatchEvent(
            new Event('submit', { bubbles: true, cancelable: true })
          );
        }
      };

      /**
       * We need to set the name on to this textarea, not the one declared below
       * This is so we can interact with it via RTL tests
       */
      easyMDE.codemirror.on('refresh', (instance) => {
        const innerTextArea = document.querySelector(
          envIsJsdom ? '.CodeMirror textarea' : '.CodeMirror .CodeMirror-code'
        );
        if (innerTextArea) {
          innerTextArea.setAttribute(
            'data-testid',
            'cyd-enhanced-markdown-editor'
          );
          innerTextArea.setAttribute('role', 'textbox');
          if (name) {
            innerTextArea.setAttribute('name', name);
          }
          if (label) {
            innerTextArea.setAttribute('aria-label', label);
          }
        }
      });
      easyMDE.codemirror.on('change', () => {
        if (onChange) {
          onChange(easyMDE?.value() ?? '');
        }
      });

      // We need to get the extraKeys first, otherwise the extra keys we set will clobber it.
      const existingExtraKeys = easyMDE.codemirror.getOption('extraKeys');

      // not documented on EasyMDE
      // But documented here: https://codemirror.net/5/doc/manual.html#:~:text=for%20more%20information.-,extraKeys,-%3A%20object
      easyMDE.codemirror.setOption('extraKeys', {
        'Ctrl-Enter': submitHandler,
        'Cmd-Enter': submitHandler,
        ...(existingExtraKeys as CodeMirror.KeyMap)
      });

      //@ts-ignore there's some weird error happening here that that only shows up in terminal
      easyMDE.codemirror.on('paste', (instance: any, event: any) => {
        const gfm = convertClipboardDataToGfmTable({
          types: event.clipboardData?.types ?? [],
          getData: (str) => event.clipboardData?.getData(str) ?? ''
        });
        if (gfm) {
          event.preventDefault();
          instance.replaceSelection(gfm);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        css={(theme) => {
          const themePalette = theme.palette;
          const themePaletteBackground = themePalette.background;
          const themePaletteText = themePalette.text;
          const themePalettePrimary = themePalette.primary;
          const themePalettePrimaryLight = themePalettePrimary.light;
          const themePaletteTextPrimary = themePaletteText.primary;
          const themeBorder = theme.other.border;

          return `
          height: ${height};
          textarea {
            caret-color: white; 
          }
  
          .EasyMDEContainer {
            ${error ? `border: 1px solid ${themePalette.error.main};` : ''}
            background-color: ${themePaletteBackground.paper}; 
            height: ${height};
            inset: 0; 
            display: flex; 
            flex-flow: column nowrap; 
          }
  
          .CodeMirror-cursor {
            border-color: ${themePaletteTextPrimary};
          }
  
          .CodeMirror {
            background-color: ${themePaletteBackground.default}; 
            color: ${themePaletteTextPrimary};
            border: ${themeBorder} solid 1px;
            border-top: transparent 0px solid;
            flex: 1 1 auto; 
          } 
  
          .editor-statusbar .cy_character_count:before {
            content: 'chars: '
          }
        
          .editor-toolbar {
            border: ${themeBorder} solid 1px;
            button {
              color: ${themePaletteTextPrimary};
  
              &.spellcheck:hover {
                background-color: ${themePalettePrimaryLight}05;
              }
  
              &#spell-check-icon{
                svg {
                  fill: ${themePaletteTextPrimary};
                }
              }
  
              &.active {
                background-color: ${themePalettePrimaryLight}15;
              }
  
              &:hover {
                background-color: ${themePalettePrimaryLight}05;
                border: solid 1px ${themeBorder};
              }
            }
          }
        `;
        }}
      >
        <textarea ref={ref} defaultValue={initialValue} name={name} />
      </div>
      <FormHelperText
        css={css`
          margin-top: 3px;
          margin-right: 14px;
          margin-bottom: 0;
          margin-left: 14px;
        `}
        error
      >
        {error}
      </FormHelperText>
    </>
  );
};
