import { cyFetchAuthenticated } from 'utils/CydarmFetch';

export type OpenCloseMetric = {
  startPeriod: Date;
  endPeriod: Date;
  totalNewCases: number;
  totalClosedCases: number;
};

export async function getOpenCloseMetric(
  startDate: Date,
  endDate: Date,
  orgs?: Array<string>
): Promise<OpenCloseMetric> {
  const startDateAsString = startDate.toISOString();
  const endDateAsString = endDate.toISOString();
  const orgsParam = orgs ? orgs.join(',') : '';
  const result = await cyFetchAuthenticated(
    `/metrics/openclose?startPeriod=${startDateAsString}&endPeriod=${endDateAsString}&orgs=${orgsParam}`,
    {
      method: 'GET'
    }
  );

  try {
    // Can also run time validation here:

    const parsedResult = {
      startPeriod: new Date(startDateAsString),
      endPeriod: new Date(endDateAsString),
      totalNewCases: result.json.totalNewCases,
      totalClosedCases: result.json.totalClosedCases
    };

    return parsedResult;
  } catch (err) {
    throw err;
  }
}
