/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectorOperationTypeRequestResourceAttributes } from './ConnectorOperationTypeRequestResourceAttributes';
import {
    ConnectorOperationTypeRequestResourceAttributesFromJSON,
    ConnectorOperationTypeRequestResourceAttributesFromJSONTyped,
    ConnectorOperationTypeRequestResourceAttributesToJSON,
} from './ConnectorOperationTypeRequestResourceAttributes';
import type { ConnectorOperationTypeResourceRelationships } from './ConnectorOperationTypeResourceRelationships';
import {
    ConnectorOperationTypeResourceRelationshipsFromJSON,
    ConnectorOperationTypeResourceRelationshipsFromJSONTyped,
    ConnectorOperationTypeResourceRelationshipsToJSON,
} from './ConnectorOperationTypeResourceRelationships';

/**
 * 
 * @export
 * @interface ConnectorOperationTypeRequestResource
 */
export interface ConnectorOperationTypeRequestResource {
    /**
     * 
     * @type {string}
     * @memberof ConnectorOperationTypeRequestResource
     */
    type?: string;
    /**
     * 
     * @type {ConnectorOperationTypeRequestResourceAttributes}
     * @memberof ConnectorOperationTypeRequestResource
     */
    attributes?: ConnectorOperationTypeRequestResourceAttributes;
    /**
     * 
     * @type {ConnectorOperationTypeResourceRelationships}
     * @memberof ConnectorOperationTypeRequestResource
     */
    relationships?: ConnectorOperationTypeResourceRelationships | null;
}

/**
 * Check if a given object implements the ConnectorOperationTypeRequestResource interface.
 */
export function instanceOfConnectorOperationTypeRequestResource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectorOperationTypeRequestResourceFromJSON(json: any): ConnectorOperationTypeRequestResource {
    return ConnectorOperationTypeRequestResourceFromJSONTyped(json, false);
}

export function ConnectorOperationTypeRequestResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorOperationTypeRequestResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'attributes': !exists(json, 'attributes') ? undefined : ConnectorOperationTypeRequestResourceAttributesFromJSON(json['attributes']),
        'relationships': !exists(json, 'relationships') ? undefined : ConnectorOperationTypeResourceRelationshipsFromJSON(json['relationships']),
    };
}

export function ConnectorOperationTypeRequestResourceToJSON(value?: ConnectorOperationTypeRequestResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'attributes': ConnectorOperationTypeRequestResourceAttributesToJSON(value.attributes),
        'relationships': ConnectorOperationTypeResourceRelationshipsToJSON(value.relationships),
    };
}

