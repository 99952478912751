/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Autocomplete, Chip, InputBaseProps, TextField } from '@mui/material';
import { useRegisterHotkeyListener } from 'hooks/HotkeyHooks';
import React, { useMemo, useRef } from 'react';
/** @jsxImportSource @emotion/react */
import { useUniqueId } from 'hooks/useUniqueId';
import ClearIcon from '@mui/icons-material/Clear';

export type CydSearchableMultiSelectProps<T> = {
  onChange?: (newValue: Array<T>, newValueStrings: Array<string>) => void;
  availableOptions: Array<T>;

  /**@deprecated Use selectedOptionStrings or defaultSelectedOptionStrings instead */
  selectedOptions?: Array<T>;

  /**
   * For controlled usage
   */
  selectedOptionStrings?: Array<string>;
  /**
   * For uncontrolled usage
   */
  defaultSelectedOptionStrings?: Array<string>;

  generateOptionLabel: (value: T) => string;

  generateValueString?: (value: T) => string;
  label: string;
  className?: string;
  hotKey?: string;
  size?: InputBaseProps['size'];

  // Provide this function to determine

  dontShowClearButton?: boolean;
  unfocusOnEscape?: boolean;
  name?: string;
};

/**
 * This is solving a pretty nasty bug
 *
 * https://cydarm.atlassian.net/browse/RM-3114
 * Repro of the bug is here: https://codesandbox.io/s/eloquent-davinci-exek80?file=/demo.tsx
 *
 * Essentially what the problem is that every time the case view polls, new objects are generated for the cases selected tags, etc.
 * These come through to the autocomplete, which likes remove the inprogress typing when that happens. (Fair enough?)
 *
 * So what we do here is is a check of 'are all the new values in the old value set, and also, are their lengths the same? Yes? Ok, keep using the old one.
 *
 * I hate this, it's obviously pretty dirty.
 *
 * But tbh, this is where I would criticise a React a bit?
 *
 * I mean really the problem is that new objects come through from state management. BUUUUT: It's quite conceivable that even if you unchanging objects coming from state mangement, you're doing a .map on them or something like that.
 *
 * Big oof.
 *
 *
 * @param values
 * @param valueStringFn
 * @returns
 */
function useTrueChangingValues<T>(
  values?: Array<T>,
  valueStringFn?: (value: T) => string
): Array<T> | undefined {
  const valuesRef = useRef(values);

  if (!valueStringFn || !values) {
    return values;
  }

  const prevValuesSet = new Set(
    valuesRef.current?.map((v) => valueStringFn(v))
  );

  if (
    values.every((v) => prevValuesSet.has(valueStringFn(v))) &&
    values.length === prevValuesSet.size
  ) {
    return valuesRef.current;
  }

  valuesRef.current = values;
  return valuesRef.current;
}

/**
 * This component will not support async searchable selection, we will cross that bridge when we need it
 * @param props
 * @returns
 */
export const CydSearchableMultiSelect = <T,>(
  props: CydSearchableMultiSelectProps<T>
) => {
  const {
    onChange,
    availableOptions,
    selectedOptions,
    selectedOptionStrings,
    defaultSelectedOptionStrings,
    generateOptionLabel,
    label,
    className,
    hotKey,
    size,
    generateValueString,
    dontShowClearButton = false,
    unfocusOnEscape = false,
    name
  } = props;

  const inputRef = React.useRef<HTMLInputElement>();

  const valueFn = generateValueString ?? generateOptionLabel;

  useRegisterHotkeyListener(hotKey, () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const trueSelectedValues = useTrueChangingValues(
    selectedOptions,
    generateValueString
  );

  const [, valueLabelMap, optionsMap] = useMemo(() => {
    const map1 = new Map<string, string>();
    const map2 = new Map<string, string>();
    const map3 = new Map<string, T>();

    availableOptions.forEach((v) => {
      const label = generateOptionLabel(v);
      const value = valueFn(v);

      // There's got to be a more performant way to do this surely.
      map1.set(label, value);
      map2.set(value, label);
      map3.set(label, v);
    });

    return [map1, map2, map3];
  }, [availableOptions, generateOptionLabel, valueFn]);

  const [selectedValues, defaultValues]: [T[] | undefined, T[] | undefined] =
    useMemo(() => {
      let selectedValues: undefined | Array<T>;

      if (trueSelectedValues) {
        selectedValues = trueSelectedValues;
      } else {
        if (selectedOptionStrings === undefined) {
          // This means the component will be uncontrolled
          selectedValues = undefined;
        } else {
          selectedValues = [];

          selectedOptionStrings.forEach((v) => {
            const result = optionsMap.get(v);
            if (result) {
              selectedValues?.push(result);
            }
          });
        }
      }

      if (!defaultSelectedOptionStrings) {
        return [selectedValues, []];
      }

      const defaultValuesAsLabel = defaultSelectedOptionStrings.map((v) =>
        valueLabelMap.get(v)
      );
      const defaultValueAsValue = defaultValuesAsLabel.map((v) =>
        optionsMap.get(v as string)
      ) as Array<T>;

      return [selectedValues, defaultValueAsValue];
    }, [
      optionsMap,
      defaultSelectedOptionStrings,
      selectedOptionStrings,
      valueLabelMap,
      trueSelectedValues
    ]);

  const id = useUniqueId('autocomplete');

  return (
    <Autocomplete
      multiple
      css={(theme) => `
      
      .MuiAutocomplete-paper {
        border-radius: 0; 
        border: solid 1px red; 
      }
        .MuiAutocomplete-tagSizeSmall {
          height: 20px;
          .MuiChip-label {
            font-size: 10px; 
          }

          .MuiSvgIcon-root {
            height: 12px; 
            width: 12px; 
          }
        }
      `}
      clearOnBlur={false}
      clearIcon={dontShowClearButton ? null : <ClearIcon fontSize="small" />}
      className={className}
      disablePortal
      id={id} // Required to make the accessible label appear
      options={availableOptions}
      value={selectedValues}
      defaultValue={defaultValues ?? undefined}
      size={size}
      ChipProps={{
        size: 'small'
      }}
      slotProps={{
        paper: {
          style: {
            borderRadius: 0, // Prevents odd, sporadic issue where the text appears blurry if there is an overflow scroll
            fontSize: 14,
            overflowWrap: 'anywhere'
          }
        }
      }}
      getOptionLabel={generateOptionLabel}
      onChange={(e, v) => {
        onChange?.(
          v as Array<T>,
          v.map((w) => valueFn(w as T))
        );
      }}
      onKeyDown={(e) => {
        //@ts-ignore
        if (
          e.key === 'Escape' &&
          unfocusOnEscape &&
          //@ts-ignore
          !(e.target.getAttribute('aria-expanded') === 'true')
        ) {
          //@ts-ignore
          e.target.blur();
        }
      }}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              label={label}
              inputRef={inputRef}
              onKeyDown={(e) => {
                // do not allow backspace to delete tags.
                if (e.key === 'Backspace') {
                  if (!inputRef.current?.value) {
                    e.stopPropagation();
                  }
                }
              }}
            />
          </>
        );
      }}
      renderTags={(values, getTagProps, ownerState) => {
        return values.map((v, index) => {
          const value = valueFn(v);
          return (
            <React.Fragment key={value}>
              <Chip
                label={generateOptionLabel(v as T)}
                size={size}
                {...getTagProps({ index })}
              />
              {name && <input type="hidden" value={value} name={name} />}
            </React.Fragment>
          );
        });
      }}
    />
  );
};
