/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import React from 'react';
import { useDefaultLayoutSidebarStyles } from 'components/layouts/DefaultLayout/style';
import { CydarmPage } from 'interface/CydarmRoute';
import { convertToTestId } from 'utils/StringUtils';

type DefaultLayoutSidebarProps = {
  page?: CydarmPage;
  sidebar: React.ReactNode;
};

const DefaultLayoutSidebar = ({
  page,
  sidebar,
  children
}: React.PropsWithChildren<DefaultLayoutSidebarProps>) => {
  const { sidebarLayout, siderContent } = useDefaultLayoutSidebarStyles();
  return (
    <div
      data-testid={convertToTestId(`sb-content-${page}`)}
      className={sidebarLayout}
    >
      {sidebar}
      <div className={siderContent}>{children}</div>
    </div>
  );
};

export default DefaultLayoutSidebar;
