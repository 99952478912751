/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { StackProps } from '@mui/material/Stack';

/** @jsxImportSource @emotion/react */

type CydStackProps = React.PropsWithChildren<{
  direction?: StackProps['direction'];
  spacing?: number;
  className?: string;
  justifyContent?: StackProps['justifyContent'];
}>;

export const CydButtonStack = (props: CydStackProps) => {
  const {
    direction = 'row',
    spacing = 1,
    children,
    className,
    justifyContent = 'flex-start'
  } = props;
  return (
    // Note: We don't actually use the Material Stack because of its various margin issues
    // https://github.com/mui/material-ui/issues/33155
    <div
      css={(theme) => `
        display: flex; 
        flex-flow: ${direction} nowrap; 
        gap: ${theme.spacing(spacing)}; 
        margin-top: ${theme.spacing(2)};    
        justify-content: ${justifyContent};
    `}
      className={`CydButtonStack ${className}`}
    >
      {children}
    </div>
  );
};
