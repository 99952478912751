/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { createFetchGroupsForCase } from './actions';
import { put, takeLatest } from 'redux-saga/effects';
import { addNotification } from 'states/notifications/slice';
import * as CydarmFetch from 'utils/CydarmFetch';
import { replaceCaseGroups } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

function* performFetchGroupsForCase({
  payload: caseUuid
}: PayloadAction<string>) {
  try {
    const { json: groupList } = yield CydarmFetch.cyFetchAuthenticated(
      `/case/${caseUuid}/member`,
      {
        method: 'GET'
      }
    );
    yield put(replaceCaseGroups({ caseUuid, groupUuids: groupList }));
  } catch (ex) {
    yield put(
      addNotification({
        message: 'Could not fetch case groups containing this case.'
      })
    );
  }
}

function* watchFetchGroupsForCase() {
  yield takeLatest(createFetchGroupsForCase, performFetchGroupsForCase);
}

export default [watchFetchGroupsForCase()];
