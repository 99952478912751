/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  createFetchCaseByLocator,
  createFetchCases,
  createUpdateCase,
  createUpdateCaseMeta,
  createAddTagToCase,
  createRemoveTagFromCase
} from './actions';
import { ErrorPayload } from 'interface/ErrorPayload.interface';
import { Case } from 'interface/Case.interface';
import { mergeObjectsInUnique, removeEmptyItem } from 'utils/MergeUtils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICaseData } from 'interface/CaseData.interface';
import { ISla } from 'interface/Sla.interface';
export interface ICaseStoreState {
  error: ErrorPayload | null;
  list: Case[];
  isLoading: boolean;
  hasLoaded: boolean;
}

interface IAddDataStubs {
  dataStubs: ICaseData[];
  case: Case;
}

interface IAddSlas {
  caseUuid: string;
  slas: ISla[];
}

interface IAddCaseMemberToCase {
  caseUuid: string;
  memberUuid: string;
}

const initialState: ICaseStoreState = {
  list: [],
  error: null,
  isLoading: false,
  hasLoaded: false
};

const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    addCaseToStore: (state, { payload }: PayloadAction<Case>) => {
      state.isLoading = false;
      state.list = [
        ...state.list.filter(({ uuid }) => uuid !== payload.uuid),
        payload
      ];
    },

    addDatastubsToCase: (state, { payload }: PayloadAction<IAddDataStubs>) => {
      let index = state.list.findIndex(
        ({ uuid }) => uuid === payload.case.uuid
      );
      if (index !== -1) {
        state.list[index].data = payload.dataStubs;
      }
    },

    addCaseSlas: (state, { payload }: PayloadAction<IAddSlas>) => {
      let index = state.list.findIndex(({ uuid }) => uuid === payload.caseUuid);
      if (index !== -1) {
        state.list[index].slas = payload.slas;
      }
    },

    fetchCasesSuccess: (state, { payload }: PayloadAction<Case[]>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.list = mergeObjectsInUnique(
        [...state.list, ...removeEmptyItem(payload, 'metadata')],
        'uuid'
      );
    },

    addMemberUuidToCase: (
      state,
      { payload }: PayloadAction<IAddCaseMemberToCase>
    ) => {
      state.list = state.list.map((c) => {
        if (c.uuid === payload.caseUuid) {
          return {
            ...c,
            members: [...(c.members || []), payload.memberUuid]
          };
        }
        return c;
      });
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createFetchCases, (state) => ({ ...state, isLoading: true }))

      .addCase(createFetchCaseByLocator, (state) => ({
        ...state,
        isFetching: true
      }))

      .addCase(createUpdateCase, (state) => ({
        ...state,
        isUpdating: true,
        isUpdatedSuccess: false
      }))

      .addCase(createUpdateCaseMeta, (state, { payload }) => {
        const index = state.list.findIndex(
          ({ uuid }) => uuid === payload.caseUuid
        );
        if (index !== -1) {
          state.list[index].metadata = Object.keys(payload.newData).reduce(
            (acc, key) => {
              const newValue = payload.newData[key];
              acc[key] = {
                name: key,
                value: newValue
              };
              return acc;
            },
            {}
          );
        }
      })

      .addCase(
        createAddTagToCase,
        (
          state,
          { payload }: PayloadAction<{ caseUuid: string; tagValue: string }>
        ) => {
          const index = state.list.findIndex(
            ({ uuid }) => uuid === payload.caseUuid
          );
          if (index !== -1) {
            state.list[index].tags = [
              ...(state.list[index].tags || []),
              payload.tagValue
            ];
          }
        }
      )

      .addCase(
        createRemoveTagFromCase,
        (
          state,
          { payload }: PayloadAction<{ caseUuid: string; tagValue: string }>
        ) => {
          const index = state.list.findIndex(
            ({ uuid }) => uuid === payload.caseUuid
          );
          if (index !== -1) {
            state.list[index].tags = state.list[index].tags?.filter(
              (tag) => tag !== payload.tagValue
            );
          }
        }
      )
});

export const {
  reducer: cases,
  actions: {
    addCaseToStore,
    addDatastubsToCase,
    addCaseSlas,
    fetchCasesSuccess,
    addMemberUuidToCase
  }
} = casesSlice;
