/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { User } from 'interface/User.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchRecursiveGroupMembers } from './actions';

export interface IGroupMemberReducerState {
  members: Record<string, User[]>;
  childGroups: Record<string, string[]>;
  deepMembers: Record<string, User[]>;
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: IGroupMemberReducerState = {
  members: {},
  childGroups: {},
  deepMembers: {},
  isLoading: false,
  hasLoaded: false
};

interface IFetchGroupMembers {
  uuid: string;
  members: User[];
}

interface IAddGroupMember {
  memberUuid: string;
  groupUuid: string;
}

interface IGroupChildGroups {
  uuid: string;
  childGroups: string[];
}

interface IAddGroupChildGroups {
  groupUuid: string;
  childGroupUuid: string;
}

const groupMembersSlice = createSlice({
  name: 'groupMembers',
  initialState,
  reducers: {
    fetchGroupMembersSuccess: (
      state,
      { payload }: PayloadAction<IFetchGroupMembers>
    ) => {
      state.members[payload.uuid] = payload.members;
    },

    fetchRecursiveGroupMembersSuccess: (
      state,
      { payload }: PayloadAction<IFetchGroupMembers>
    ) => {
      state.deepMembers[payload.uuid] = payload.members;
    },

    addGroupMemberSuccess: () =>
      // state,
      // { payload }: PayloadAction<IAddGroupMember>
      {
        //i found these types were wrong while refactoring, so TODO: make this actually do something
        // if (state.members[payload.groupUuid]) {
        //   state.members[payload.groupUuid].push(payload.memberUuid);
        // } else {
        //   state.members[payload.groupUuid] = [payload.memberUuid];
        // }
      },

    removeGroupMemberSuccess: (
      state,
      { payload }: PayloadAction<IAddGroupMember>
    ) => {
      if (state.members[payload.groupUuid]) {
        state.members[payload.groupUuid].filter(
          ({ uuid }) => uuid !== payload.memberUuid
        );
      }
    },

    fetchGroupChildGroupsSuccess: (
      state,
      { payload }: PayloadAction<IGroupChildGroups>
    ) => {
      state.childGroups[payload.uuid] = payload.childGroups;
    },

    addGroupChildGroupsSuccess: (
      state,
      { payload }: PayloadAction<IAddGroupChildGroups>
    ) => {
      if (state.childGroups[payload.groupUuid]) {
        state.childGroups[payload.groupUuid].push(payload.childGroupUuid);
      } else {
        state.childGroups[payload.groupUuid] = [payload.childGroupUuid];
      }
    },

    removeGroupMembersSuccess: (
      state,
      { payload }: PayloadAction<IAddGroupChildGroups>
    ) => {
      if (state.childGroups[payload.groupUuid]) {
        state.childGroups[payload.groupUuid].filter(
          (uuid) => uuid !== payload.childGroupUuid
        );
      }
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchRecursiveGroupMembers, (state) => {
      state.isLoading = true;
    })
});

export const {
  reducer: groupMembers,
  actions: {
    fetchGroupMembersSuccess,
    fetchRecursiveGroupMembersSuccess,
    addGroupMemberSuccess,
    removeGroupMemberSuccess,
    fetchGroupChildGroupsSuccess,
    addGroupChildGroupsSuccess,
    removeGroupMembersSuccess
  }
} = groupMembersSlice;
