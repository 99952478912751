/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCaseFormJSON, useCaseForms } from 'hooks/CaseFormsHooks';
import { PlaybookActionsSelect } from 'components/fields/PlaybookActionsSelect';
import { UserSelect2 } from 'components/fields/UserSelect2';
import { PlaybookSelect2 } from 'components/fields/PlaybookSelect2';
import { useStyles } from './styles';
import { OrgSelect2 } from 'components/fields/OrgSelect2';
import { Base64Decode } from 'utils/StringUtils';
import { DatetimeFields } from './DateTimeFields';
import { BooleanField } from './BooleanField';
import { ContactNameSelect } from 'components/fields/ContactNameSelect';
import { AssetsSelect } from 'components/fields/AssetsSelect';
import { FormObject } from 'states/caseForms/slice';
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import { CydDiv } from 'components/_utils/CydFragment';
import { CydTextField } from 'components/_formElements/CydTextField';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const CaseDataGeneratedForms = ({ onChange }) => {
  const [formSelected, setFormSelected] = useState<FormObject | null>(null);
  const [genFormData, setGenFormData] = useState({});
  const { data: availableForms } = useCaseForms();

  const { formWrapper, formPadding } = useStyles({});

  const handleFormDataChange = (value) => {
    setGenFormData(value);
  };

  const JSONToSubmit = useCaseFormJSON({
    uuid: formSelected?.uuid,
    fields: genFormData
  });

  // A bit hacky but just making it so we don't need to worry about infinite loops from that onChange changing
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // Whenever the JSON to Submit changes, fire the on change
  useEffect(() => {
    onChangeRef.current(JSONToSubmit);
  }, [JSONToSubmit]);

  const currentForm = useMemo(() => {
    let form = availableForms.find(({ uuid }) => uuid === formSelected?.uuid);
    if (!form) {
      return;
    }
    let fieldTransformed = JSON.parse(Base64Decode(form.fields));
    return {
      ...form,
      fields: fieldTransformed
    };
  }, [formSelected, availableForms]);

  return (
    <>
      <CydSimpleSelect
        label="Select form template"
        availableOptions={availableForms}
        selectedOption={formSelected}
        onChange={(v) => {
          setFormSelected(v);
        }}
        generateOptionLabel={(v) => {
          return v.name;
        }}
        className={formPadding}
      />

      {currentForm && (
        <div
          className={formWrapper}
          css={css`
            display: flex;
            flex-flow: column nowrap;
          `}
        >
          {currentForm.fields.map((field) => (
            <FormFieldWrapper
              field={field}
              key={field.name}
              onChange={handleFormDataChange}
              formData={genFormData}
            />
          ))}
        </div>
      )}
    </>
  );
};

const flattenFields: { [key: string]: string } = {
  contact: 'contact_name',
  asset: 'asset_name'
};

const FormFieldWrapper = (props) => {
  const { formData, field, onChange } = props;
  const flattenField = flattenFields[field.type];

  const isFlattenFieldName: boolean = !!flattenField;
  const value =
    (!isFlattenFieldName ? formData[field.name] : formData[flattenField]) || '';
  const handleChange = (e) => {
    const newValue = e.target.value;
    //temp hack to be remediated in RM-1165
    onChange((prevState) =>
      !isFlattenFieldName
        ? { ...prevState, [field.name]: newValue }
        : { ...prevState, ...newValue }
    );
  };

  const handleChangeDate = (dateString: string) => {
    //temp hack to be remediated in RM-1165
    onChange((prevState) => ({ ...prevState, [field.name]: dateString }));
  };
  const { field: fieldStyles, fieldWrapper } = useStyles({
    charWidth: field.charWidth
  });
  const labelId = `field-${field.name}-label`;
  const label = field.displayName;
  return (
    <FormControl className={fieldWrapper}>
      <InputLabel shrink htmlFor={`field-${field.name}`} id={labelId}>
        {field.type === 'boolean' || isFlattenFieldName ? '' : label}
      </InputLabel>
      <FormField
        {...{
          value,
          field,
          handleChange,
          fieldStyles,
          labelId,
          handleChangeDate
        }}
      />
    </FormControl>
  );
};

export const FormField = withErrorBoundary(
  (props: any) => {
    const { field, value, handleChange, fieldStyles, handleChangeDate } = props;
    const fieldProps = {
      value,
      onChange: handleChange,
      name: field.name,
      id: `field-${field.name}`,
      className: fieldStyles,
      onDateChange: handleChangeDate
    };
    switch (field.type) {
      case 'string':
        return (
          <CydTextField
            onChange={(_, e) => handleChange(e)}
            value={fieldProps.value}
            id={fieldProps.id}
            className={fieldProps.className}
            name={fieldProps.name}
          />
        );
      case 'enum': {
        return (
          <Select
            {...fieldProps}
            fullWidth
            displayEmpty
            data-testid="enum-select"
          >
            <MenuItem value="" disabled>
              Select one
            </MenuItem>
            {(field.possibleValues || field.values).map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      }
      case 'number':
        return (
          <CydTextField
            onChange={(_, e) => handleChange(e)}
            value={fieldProps.value}
            id={fieldProps.id}
            className={fieldProps.className}
            name={fieldProps.name}
            type="number"
          />
        );
      case 'date':
      case 'datetime':
      case 'time':
        return <DatetimeFields {...fieldProps} type={field.type} />;
      case 'boolean':
        return <BooleanField {...fieldProps} label={field.displayName} />;
      case 'user':
        return <UserSelect2 {...fieldProps} displayEmpty fullWidth />;
      case 'org':
        return <OrgSelect2 {...fieldProps} displayEmpty fullWidth />;
      case 'playbook':
        return <PlaybookSelect2 {...fieldProps} displayEmpty fullWidth />;
      case 'action':
        return <PlaybookActionsSelect {...fieldProps} displayEmpty fullWidth />;
      case 'contact':
        return <ContactNameSelect {...fieldProps} />;
      case 'asset':
        return <AssetsSelect {...fieldProps} />;

      default:
        return null;
    }
  },
  CydDiv,
  (props) => {
    return (
      <Typography
        color="error"
        css={(theme) => `
    margin: ${theme.spacing(3)} 0 0; 
  `}
      >
        This form is not available. Try creating a new instance of it the
        settings area.
      </Typography>
    );
  }
);
