/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectorOperationTypeResourceAttributes } from './ConnectorOperationTypeResourceAttributes';
import {
    ConnectorOperationTypeResourceAttributesFromJSON,
    ConnectorOperationTypeResourceAttributesFromJSONTyped,
    ConnectorOperationTypeResourceAttributesToJSON,
} from './ConnectorOperationTypeResourceAttributes';
import type { ConnectorOperationTypeResourceRelationships } from './ConnectorOperationTypeResourceRelationships';
import {
    ConnectorOperationTypeResourceRelationshipsFromJSON,
    ConnectorOperationTypeResourceRelationshipsFromJSONTyped,
    ConnectorOperationTypeResourceRelationshipsToJSON,
} from './ConnectorOperationTypeResourceRelationships';
import type { ObjectMetadataSet } from './ObjectMetadataSet';
import {
    ObjectMetadataSetFromJSON,
    ObjectMetadataSetFromJSONTyped,
    ObjectMetadataSetToJSON,
} from './ObjectMetadataSet';

/**
 * 
 * @export
 * @interface ConnectorOperationTypeResource
 */
export interface ConnectorOperationTypeResource {
    /**
     * 
     * @type {string}
     * @memberof ConnectorOperationTypeResource
     */
    type?: string;
    /**
     * The UUID of the operation type
     * @type {string}
     * @memberof ConnectorOperationTypeResource
     */
    id?: string;
    /**
     * 
     * @type {ConnectorOperationTypeResourceAttributes}
     * @memberof ConnectorOperationTypeResource
     */
    attributes?: ConnectorOperationTypeResourceAttributes;
    /**
     * 
     * @type {ConnectorOperationTypeResourceRelationships}
     * @memberof ConnectorOperationTypeResource
     */
    relationships?: ConnectorOperationTypeResourceRelationships | null;
    /**
     * 
     * @type {ObjectMetadataSet}
     * @memberof ConnectorOperationTypeResource
     */
    meta?: ObjectMetadataSet | null;
}

/**
 * Check if a given object implements the ConnectorOperationTypeResource interface.
 */
export function instanceOfConnectorOperationTypeResource(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectorOperationTypeResourceFromJSON(json: any): ConnectorOperationTypeResource {
    return ConnectorOperationTypeResourceFromJSONTyped(json, false);
}

export function ConnectorOperationTypeResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorOperationTypeResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'attributes': !exists(json, 'attributes') ? undefined : ConnectorOperationTypeResourceAttributesFromJSON(json['attributes']),
        'relationships': !exists(json, 'relationships') ? undefined : ConnectorOperationTypeResourceRelationshipsFromJSON(json['relationships']),
        'meta': !exists(json, 'meta') ? undefined : ObjectMetadataSetFromJSON(json['meta']),
    };
}

export function ConnectorOperationTypeResourceToJSON(value?: ConnectorOperationTypeResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'attributes': ConnectorOperationTypeResourceAttributesToJSON(value.attributes),
        'relationships': ConnectorOperationTypeResourceRelationshipsToJSON(value.relationships),
        'meta': ObjectMetadataSetToJSON(value.meta),
    };
}

