/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createFetchUsers, createFetchAllUsers } from './actions';
import { User } from 'interface/User.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mergeObjectsInUnique } from 'utils/MergeUtils';

export interface IUserStoreState {
  list: User[];
  isLoading: boolean;
  hasLoaded: boolean;
}

const initialState: IUserStoreState = {
  list: [],
  isLoading: false,
  hasLoaded: false
};

export const sortByGivenName = (users: User[]) => {
  users.sort((userA, userB) => userA.givenName.localeCompare(userB.givenName));
  return users;
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersSuccess: (state, { payload }: PayloadAction<User[]>) => ({
      ...state,
      list: sortByGivenName(payload),
      isLoading: false,
      hasLoaded: true
    }),

    fetchUserInfoSuccess: (state, { payload }: PayloadAction<User>) => {
      state.list = sortByGivenName(
        mergeObjectsInUnique([...state.list, payload], 'uuid')
      );
    },

    deleteUserSuccess: (state, { payload }: PayloadAction<string>) => {
      state.list = state.list.filter(({ uuid }) => uuid !== payload);
    },

    clearUsers: () => initialState,

    createUser: (state) => {
      state.hasLoaded = false;
    },

    updateUser: (state) => {
      state.hasLoaded = false;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createFetchUsers, (state) => {
        state.isLoading = true;
      })

      .addCase(createFetchAllUsers, (state) => {
        state.isLoading = true;
      })
});

export const {
  reducer: users,
  actions: {
    fetchUsersSuccess,
    fetchUserInfoSuccess,
    deleteUserSuccess,
    clearUsers,
    createUser,
    updateUser
  }
} = usersSlice;
