/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { convertToTestId } from 'utils/StringUtils';
import { useLocation } from 'react-router';

type NavItem = {
  label: string;
  to: string;
  ariaLabel?: string;
};

export type CydPageSubNavProps = {
  /* Use this prop to determine the current nav value, based on location. 
    ie. You probably want to use this to strip trailing IDs and stuff of the path. 
  */
  currentNavValueFn?: (
    items: Array<NavItem>,
    location: ReturnType<typeof useLocation>
  ) => string | false;
  navItems?: Array<NavItem>;

  className?: string;
};

const ReffedLink = React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
  const { href, className, onClick, children, ...rest } = props;
  return (
    <Link ref={ref} to={href} className={className} onClick={onClick} {...rest}>
      {children}
    </Link>
  );
});

const DEFAULT_NAV_VALUE_FUNCTION = (
  navItems: Array<NavItem>,
  location: ReturnType<typeof useLocation>
) => {
  const foundItem = navItems.find((v) => {
    return location.pathname.startsWith(v.to);
  });

  if (foundItem) {
    return foundItem.to;
  }

  return false;
};

//This component provides navigation tabs, also you can put content like page title etc, above it.
export const CydPageSubNav = (
  props: React.PropsWithChildren<CydPageSubNavProps>
) => {
  const {
    children,
    navItems,
    className,
    currentNavValueFn = DEFAULT_NAV_VALUE_FUNCTION
  } = props;

  const [ready, setIsReady] = useState(false);
  const portalRef = useRef<HTMLDivElement | null>(null);

  let location = useLocation();

  useEffect(() => {
    const subNavDiv = document.getElementById('sub-nav') as HTMLDivElement;

    if (className) {
      subNavDiv.classList.add(className);
    }
    portalRef.current = subNavDiv;
    setIsReady(true);

    // Will be a bug if the className ever changes after first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedValue = useMemo(() => {
    if (!navItems) {
      return false;
    }
    return currentNavValueFn(navItems, location);
  }, [currentNavValueFn, location, navItems]);

  if (!ready) {
    return null;
  } else {
    if (!portalRef.current) {
      throw new Error('Can not create a sub nav, no container found for it');
    }

    const navElements = navItems ? (
      <Tabs value={selectedValue}>
        {navItems.map((v) => {
          const { label, to } = v;
          return (
            <Tab
              data-testid={convertToTestId(`nav-${label}`)}
              key={to}
              href={to}
              label={label}
              value={to}
              aria-label={v.ariaLabel || label}
              component={ReffedLink}
            />
          );
        })}
      </Tabs>
    ) : null;

    /*   nb. It is important _not_ to do any styling inside this component. 
         That styling should be done up at CydPageLayoutFrame
         The idea is you can have multiple components use of these, and the items are just put into the portal target. You (probably) only want one styled container, not many. 
     */
    return ReactDOM.createPortal(
      <>
        {children}
        {navElements}
      </>,
      portalRef.current
    );
  }
};
