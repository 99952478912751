/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import Autocomplete from '@mui/material/Autocomplete';
import { useContactListName, useContacts } from 'hooks/ContactsHooks';
import { TextField } from '@mui/material';

export const ContactNameSelect = ({ value, onChange, ...props }) => {
  const { data: contactList } = useContacts();
  const { data: contactListName } = useContactListName();

  const mapContactToOption = (contactNameList: string[]) =>
    contactNameList
      .map((name) => ({
        label: name,
        value: name
      }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  //temp hack to be remediated in RM-1165
  const handleChangeInterceptor = (selectedOption) => {
    const { data: selectedContact } =
      (selectedOption &&
        contactList.find(
          ({ data: { name } }) => selectedOption.value === name
        )) ||
      {};
    const contactData = {
      contact_name: selectedContact?.name,
      contact_email: selectedContact?.email?.join(', ') || '',
      contact_phone: selectedContact?.phone?.join(', ') || ''
    };
    onChange({ target: { value: contactData } });
  };

  const contactLabel = contactListName && mapContactToOption(contactListName);

  return (
    <Autocomplete
      onChange={(_, optionSelected) => handleChangeInterceptor(optionSelected)}
      options={contactLabel}
      getOptionLabel={(option: any) => option.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select contact"
          variant="outlined"
          fullWidth
        />
      )}
      {...props}
    />
  );
};
