/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Grid, Typography } from '@mui/material';

/** @jsxImportSource @emotion/react */

type CydAclModalRowProps = {
  title: string;
  children: ReactNode;
};

export const CydAclModalRow = ({ title, children }: CydAclModalRowProps) => {
  const cellCss = css`
    && {
      padding: 0;
    }
  `;
  return (
    <Grid
      css={(theme) => css`
        margin: ${theme.spacing(4)} 0;
        width: 100%;
      `}
      container
      alignItems="center"
    >
      <Grid css={cellCss} item xs={3}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid css={cellCss} item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};
