/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { takeLatest, put } from 'redux-saga/effects';
import {
  addCasePlaybookActionTag,
  addPlaybookToCase,
  fetchCasePlaybooks,
  removeCasePlaybookActionTag,
  removePlaybookFromCase,
  updateCasePlaybook,
  updateCasePlaybookAction,
  UpdateCasePlaybookPayload
} from './actions';
import { ERROR_MESSAGE } from './errors';
import { addNotification } from 'states/notifications/slice';
import {
  addPlaybookToCaseFailed,
  addPlaybookToCaseSuccess,
  fetchCasePlaybooksSuccess,
  removePlaybookFromCaseFailed,
  removePlaybookFromCaseSuccess
} from './slice';
import {
  checkObjectHasAllRequiredCasePlaybookField,
  logObjectPropertiesIfNull
} from 'utils/StringUtils';
import {
  PlaybookActionInstance,
  CydApi_CasePlaybookInstance
} from 'interface/Playbook.interface';
import {
  apiAddPlaybookToCase,
  apiFetchCasePlaybooks,
  apiRemoveTagFromCasePlaybookAction,
  apiRemovePlaybookFromCase,
  apiUpdateCasePlaybookAction,
  apiAddTagToCasePlaybookAction,
  apiUpdateCasePlaybook
} from 'services/CasePlaybooksService';

export function* performFetchCasePlaybooks({
  payload: caseUuid
}: {
  payload: string;
}) {
  try {
    const result = yield apiFetchCasePlaybooks(caseUuid);
    const playbooks = result.json;

    const playbookList = playbooks.map(
      (playbook: CydApi_CasePlaybookInstance) => {
        checkObjectHasAllRequiredCasePlaybookField(playbook);
        logObjectPropertiesIfNull(playbook, 'CasePlaybook');
        return {
          ...playbook
        };
      }
    );

    yield put(fetchCasePlaybooksSuccess({ [caseUuid]: playbookList }));
  } catch (ex) {
    yield put(
      addNotification({
        message: ERROR_MESSAGE.FETCH_CASE_PLAYBOOKS_ERROR.message
      })
    );
  }
}

export function* performAddPlaybookToCase(action) {
  const {
    payload: { caseUuid, playbookUuid, callback }
  } = action;
  try {
    const result = yield apiAddPlaybookToCase(caseUuid, playbookUuid);

    yield put(addPlaybookToCaseSuccess());
    yield put(fetchCasePlaybooks(caseUuid));

    if (callback) {
      callback(result.json.uuid);
    }
  } catch (ex) {
    yield put(addPlaybookToCaseFailed());
    yield put(
      addNotification({
        message: ERROR_MESSAGE.ADD_PLAYBOOK_TO_CASE_ERROR.message
      })
    );
  }
}

export function* performRemovePlaybookFromCase(action) {
  const {
    payload: { caseUuid, playbookUuid }
  } = action;
  try {
    yield apiRemovePlaybookFromCase(caseUuid, playbookUuid);

    yield put(
      removePlaybookFromCaseSuccess({
        casePlaybookUuid: playbookUuid,
        caseUuid
      })
    );
    yield put(
      addNotification({ message: 'Successfully removed playbook from case' })
    );
  } catch (ex) {
    yield put(removePlaybookFromCaseFailed());
    yield put(
      addNotification({
        message: ERROR_MESSAGE.REMOVE_PLAYBOOK_FROM_CASE_ERROR.message
      })
    );
  }
}

// currently not used
// function* performFetchCasePlaybookActionStatuses(action) {
//   const {
//     payload: { caseUuid, playbookUuid }
//   } = action;
//   try {
//     const { json: playbookActionStatuses } =
//       yield CydarmFetch.cyFetchAuthenticated(
//         `/case/${caseUuid}/playbook/${playbookUuid}/action-status`,
//         {
//           method: 'GET'
//         }
//       );
//     yield put({
//       type: FETCH_CASE_PLAYBOOK_ACTION_STATUSES_SUCCESS,
//       payload: { playbookActionStatuses }
//     });
//   } catch (ex) {
//     yield put(
//       addNotification({
//         message: ERROR_MESSAGE.FETCH_CASE_PLAYBOOK_ACTION_STATUSES_ERROR.message
//       })
//     );
//     yield put({
//       type: FETCH_CASE_PLAYBOOK_ACTION_STATUSES_FAILED,
//       payload: {
//         error: ERROR_MESSAGE.FETCH_CASE_PLAYBOOK_ACTION_STATUSES_ERROR
//       }
//     });
//   }
// }

export type UpdatePlaybookActionPayload = {
  payload: {
    caseUuid: string;
    playbookUuid: string;
    actionInstance: PlaybookActionInstance;
  };
};
export function* performUpdateCasePlaybookAction({
  payload
}: UpdatePlaybookActionPayload) {
  const { caseUuid, playbookUuid, actionInstance } = payload;

  const { actionInstanceUuid, assigneeUuid, status, variable_bindings } =
    actionInstance;

  try {
    const requestBody = {
      assignee: assigneeUuid,
      status,
      variable_bindings
    };

    yield apiUpdateCasePlaybookAction(
      caseUuid,
      playbookUuid,
      actionInstanceUuid,
      requestBody
    );

    yield put(fetchCasePlaybooks(caseUuid));
    yield put(
      addNotification({ message: 'Updated case playbook successfully' })
    );
  } catch (ex) {
    // The store will have the user inputted data
    // refresh the case playbook status back to the original status if the operation failed
    yield put(fetchCasePlaybooks(caseUuid));
    yield put(
      addNotification(ERROR_MESSAGE.UPDATE_CASE_PLAYBOOK_ACTION_STATUS_ERROR)
    );
  }
}

export type RemoveTagFromCasePlaybookPayload = {
  payload: {
    caseUuid: string;
    casePlaybookUuid: string;
    actionInstance: PlaybookActionInstance;
    tagUuid: string;
  };
};

function* performUpdateCasePlaybook(action: {
  payload: UpdateCasePlaybookPayload;
}) {
  try {
    yield apiUpdateCasePlaybook(
      action.payload.caseUuid,
      action.payload.casePlaybookUuid,
      action.payload.data
    );
    yield put(fetchCasePlaybooks(action.payload.caseUuid));

    if (action.payload.resolveFn) {
      action.payload.resolveFn();
    }
  } catch (err) {
    yield put(addNotification(ERROR_MESSAGE.UPDATE_CASE_PLAYBOOK_ERROR));
  }
}

function* performRemoveCasePlaybookActionTag(
  action: RemoveTagFromCasePlaybookPayload
) {
  const {
    payload: { caseUuid, tagUuid, casePlaybookUuid, actionInstance }
  } = action;

  try {
    yield apiRemoveTagFromCasePlaybookAction(
      caseUuid,
      casePlaybookUuid,
      actionInstance.actionInstanceUuid,
      tagUuid
    );

    yield put(fetchCasePlaybooks(caseUuid));
    yield put(
      addNotification({
        message: 'Remove case playbook action tag successfully'
      })
    );
  } catch (ex) {
    yield put(
      addNotification(ERROR_MESSAGE.REMOVE_CASE_PLAYBOOK_ACTION_TAG_ERROR)
    );
  }
}

export type AddTagToCasePlaybookPayload = {
  payload: {
    caseUuid: string;
    casePlaybookUuid: string;
    actionInstance: PlaybookActionInstance;
    tagUuid: string;
  };
};

function* performAddCasePlaybookActionTag(action: AddTagToCasePlaybookPayload) {
  const {
    payload: { caseUuid, tagUuid, casePlaybookUuid, actionInstance }
  } = action;

  try {
    yield apiAddTagToCasePlaybookAction(
      caseUuid,
      casePlaybookUuid,
      actionInstance.actionInstanceUuid,
      tagUuid
    );

    yield put(fetchCasePlaybooks(caseUuid));
    yield put(
      addNotification({
        message: 'Add case playbook action tag successfully'
      })
    );
  } catch (ex) {
    yield put(
      addNotification(ERROR_MESSAGE.ADD_CASE_PLAYBOOK_ACTION_TAG_ERROR)
    );
  }
}

/* Watchers */
function* watchFetchCasePlaybooks() {
  yield takeLatest(fetchCasePlaybooks, performFetchCasePlaybooks);
}

function* watchAddPlaybookToCase() {
  yield takeLatest(addPlaybookToCase, performAddPlaybookToCase);
}

function* watchRemovePlaybookFromCase() {
  yield takeLatest(removePlaybookFromCase, performRemovePlaybookFromCase);
}

function* watchRemoveCasePlaybookActionTag() {
  yield takeLatest(
    removeCasePlaybookActionTag,
    performRemoveCasePlaybookActionTag
  );
}

function* watchAddCasePlaybookActionTag() {
  yield takeLatest(addCasePlaybookActionTag, performAddCasePlaybookActionTag);
}

function* watchUpdateCasePlaybookAction() {
  yield takeLatest(updateCasePlaybookAction, performUpdateCasePlaybookAction);
}

function* watchUpdateCasePlaybook() {
  yield takeLatest(updateCasePlaybook, performUpdateCasePlaybook);
}
export default [
  watchFetchCasePlaybooks(),
  watchAddPlaybookToCase(),
  watchRemovePlaybookFromCase(),
  watchUpdateCasePlaybookAction(),
  watchRemoveCasePlaybookActionTag(),
  watchAddCasePlaybookActionTag(),
  watchUpdateCasePlaybook()
];
