/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { IStore } from 'states/store.interface';

export const assetsSelector = ({ assetsStore: { results } }: IStore) =>
  [...results].sort((a, b) => (a.name > b.name ? 1 : -1));

export const assetsHasLoadedSelector = ({
  assetsStore: { hasLoaded }
}: IStore) => hasLoaded;

export const assetsStatusSelector = ({
  assetsStore: { hasLoaded, isLoading }
}: IStore) => hasLoaded || isLoading;
