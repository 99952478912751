import { shallowEqual } from 'react-redux';
import { createFetchCaseSeverity } from 'states/caseSeverity/actions';
import {
  severityListSelector,
  maxCaseSeverityLevelSelector,
  caseSeverityStatusSelector
} from 'states/caseSeverity/selectors';
import { useAutoEffect } from './ReduxHooks';
import React from 'react';
import { severities } from '../testData/justData/data';

/**
 * @deprecated - use useSeverity
 * @returns
 */
export const useSeverityStates = () => {
  return useAutoEffect({
    selector: severityListSelector,
    ac: createFetchCaseSeverity,
    equality: shallowEqual,
    statusSelector: caseSeverityStatusSelector
  });
};

/**
 * @deprecated - use useSeverity
 * @returns
 */
export const useMaxCaseSeverityLevel = () => {
  return useAutoEffect({
    selector: maxCaseSeverityLevelSelector,
    ac: createFetchCaseSeverity,
    equality: shallowEqual,
    statusSelector: caseSeverityStatusSelector
  });
};

type SeverityHook = {
  getSeverities: typeof useSeverityStates;
  getMaxSeverity: typeof useMaxCaseSeverityLevel;
};

const SeverityHookContext = React.createContext<SeverityHook>({
  getSeverities: () => ({
    data: severities,
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  }),
  getMaxSeverity: () => ({
    data: severities[4].precedence as number,
    isLoading: false,
    hasLoaded: true,
    fetchData: () => {}
  })
});

export const SeverityHookProvider = (
  props: React.PropsWithChildren<SeverityHook>
) => {
  const { children, ...rest } = props;
  return (
    <SeverityHookContext.Provider value={rest}>
      {children}
    </SeverityHookContext.Provider>
  );
};

export const useSeverity = () => {
  return React.useContext(SeverityHookContext);
};

export const ProductionSeverityHookProvider = (
  props: React.PropsWithChildren<{}>
) => {
  return (
    <SeverityHookProvider
      getSeverities={useSeverityStates}
      getMaxSeverity={useMaxCaseSeverityLevel}
    >
      {props.children}
    </SeverityHookProvider>
  );
};

/**
 * For testing purposes only
 * @param props
 * @returns
 */
export const InMemorySeverityHookProvider = (
  props: React.PropsWithChildren<Partial<SeverityHook>>
) => {
  return (
    <SeverityHookProvider
      getSeverities={
        props.getSeverities ||
        (() => ({
          data: severities,
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
      getMaxSeverity={
        props.getMaxSeverity ||
        (() => ({
          data: severities[4].precedence as number,
          isLoading: false,
          hasLoaded: true,
          fetchData: () => {}
        }))
      }
    >
      {props.children}
    </SeverityHookProvider>
  );
};
