/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { ISla } from 'interface/Sla.interface';
import React from 'react';
import { CydCaseDetailsPanelSection } from '../../CydCaseDetailsPanelSection';

/** @jsxImportSource @emotion/react */

export type CydCaseViewSlaPanelProps = {
  slas: Array<ISla>;
};

export const CydCaseViewSlaPanel = (props: CydCaseViewSlaPanelProps) => {
  const { slas } = props;

  return (
    <CydCaseDetailsPanelSection title="Service Levels">
      {slas.length === 0 && (
        <span
          css={(theme) => `
                color: ${theme.palette.text.secondary};
      `}
        >
          No service levels applicable
        </span>
      )}
      {slas.map((v) => {
        return (
          <dl
            key={v.name}
            css={(theme) => `

        display: flex; 
        flex-flow: row wrap; 
        justify-content: space-between; 

        dt {
          color: ${theme.palette.text.primary};

        }

        dd {
          color: ${theme.palette.text.secondary};
        }
      `}
          >
            <dt>{v.name}</dt>

            <dd>{v.timeRemaining}</dd>
          </dl>
        );
      })}
    </CydCaseDetailsPanelSection>
  );
};
