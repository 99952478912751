/* tslint:disable */
/* eslint-disable */
/**
 * Cydarm API
 * An API for Cydarm case management
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EventTriggerListTopLevel,
  EventTriggerPatchTopLevel,
  EventTriggerRequestTopLevel,
  EventTriggerTopLevel,
} from '../models/index';
import {
    EventTriggerListTopLevelFromJSON,
    EventTriggerListTopLevelToJSON,
    EventTriggerPatchTopLevelFromJSON,
    EventTriggerPatchTopLevelToJSON,
    EventTriggerRequestTopLevelFromJSON,
    EventTriggerRequestTopLevelToJSON,
    EventTriggerTopLevelFromJSON,
    EventTriggerTopLevelToJSON,
} from '../models/index';

export interface EventTriggerGetRequest {
    pageNumber: number;
    pageSize: number;
}

export interface EventTriggerPostRequest {
    eventTriggerRequestTopLevel?: EventTriggerRequestTopLevel;
}

export interface EventTriggerUuidDeleteRequest {
    uuid: string;
}

export interface EventTriggerUuidGetRequest {
    uuid: string;
}

export interface EventTriggerUuidPatchRequest {
    uuid: string;
    eventTriggerPatchTopLevel?: EventTriggerPatchTopLevel;
}

/**
 * 
 */
export class EventTriggerApi extends runtime.BaseAPI {

    /**
     * Get all event triggers
     */
    async eventTriggerGetRaw(requestParameters: EventTriggerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventTriggerListTopLevel>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling eventTriggerGet.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling eventTriggerGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['page[number]'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page[size]'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/event-trigger`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventTriggerListTopLevelFromJSON(jsonValue));
    }

    /**
     * Get all event triggers
     */
    async eventTriggerGet(requestParameters: EventTriggerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventTriggerListTopLevel> {
        const response = await this.eventTriggerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new event trigger
     */
    async eventTriggerPostRaw(requestParameters: EventTriggerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventTriggerTopLevel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        const response = await this.request({
            path: `/event-trigger`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventTriggerRequestTopLevelToJSON(requestParameters.eventTriggerRequestTopLevel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventTriggerTopLevelFromJSON(jsonValue));
    }

    /**
     * Create a new event trigger
     */
    async eventTriggerPost(requestParameters: EventTriggerPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventTriggerTopLevel> {
        const response = await this.eventTriggerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an event trigger
     */
    async eventTriggerUuidDeleteRaw(requestParameters: EventTriggerUuidDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling eventTriggerUuidDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/event-trigger/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an event trigger
     */
    async eventTriggerUuidDelete(requestParameters: EventTriggerUuidDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.eventTriggerUuidDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get the specified event trigger
     */
    async eventTriggerUuidGetRaw(requestParameters: EventTriggerUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventTriggerTopLevel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling eventTriggerUuidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/event-trigger/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventTriggerTopLevelFromJSON(jsonValue));
    }

    /**
     * Get the specified event trigger
     */
    async eventTriggerUuidGet(requestParameters: EventTriggerUuidGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventTriggerTopLevel> {
        const response = await this.eventTriggerUuidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit an event trigger
     */
    async eventTriggerUuidPatchRaw(requestParameters: EventTriggerUuidPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventTriggerTopLevel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling eventTriggerUuidPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        const response = await this.request({
            path: `/event-trigger/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EventTriggerPatchTopLevelToJSON(requestParameters.eventTriggerPatchTopLevel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventTriggerTopLevelFromJSON(jsonValue));
    }

    /**
     * Edit an event trigger
     */
    async eventTriggerUuidPatch(requestParameters: EventTriggerUuidPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventTriggerTopLevel> {
        const response = await this.eventTriggerUuidPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
