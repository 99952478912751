const CONVERSION_MAP = {
  '#00ff00': '#8BC34A',
  '#ffff00': '#D4E157',
  '#ffa500': '#FFA726',
  '#ff0000': '#FF7043',
  '#000000': '#EF5350',
  '#c8c8c8': '#BDBDBD'
};

// This is a hacky solution to convert the existing configured severitys to a more
// pleasant color palette, without requiring a DB migration.
export const convertLegacySeverityColor = (
  hex?: string
): string | undefined => {
  if (!hex) {
    return hex;
  }

  const foundColor = CONVERSION_MAP[hex];

  if (foundColor) {
    return foundColor;
  }

  return hex;
};
