/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { Form, Formik } from 'formik';
import { FormikTextField } from 'components/FormikTextField';
import { string, object } from 'yup';
import { usePlaybookFormStyles } from './styles';
import { AtcPlaybook } from 'interface/Playbook.interface';
import { AclSelect } from 'components/fields/AclSelect';
import { useDataAcls } from 'hooks/AclHooks';
import { CydEnhancedMarkdownEditor } from 'components/_formElements/CydEnhancedMarkdownEditor/CydEnhancedMarkdownEditor';

export interface IPlaybookData {
  description: string;
  name: string;
  acl?: string;
}

interface IPlaybookFormProps {
  onSubmit: (playbookActionData: IPlaybookData) => void;
  formRef: React.RefObject<any>;
  playbook?: AtcPlaybook;
}

const validationSchema = object({
  name: string().required('Please enter a name'),
  description: string().required('Please enter a description')
});

export const PlaybookForm: React.FC<IPlaybookFormProps> = ({
  onSubmit,
  formRef,
  playbook
}) => {
  const { data: aclList } = useDataAcls();
  const defaultAcl =
    aclList && aclList.find((el) => el.description === 'case data defaults');
  const existsAcl =
    aclList && aclList.find((el) => playbook && el.uuid === playbook.acl);
  const { fieldWrapper } = usePlaybookFormStyles();

  const renderForm = ({ handleChange, values, errors, setFieldValue }) => {
    values.acl = values.acl ? values.acl : defaultAcl;
    return (
      <Form>
        <div className={fieldWrapper}>
          <FormikTextField placeholder="Playbook name" name="name" />
        </div>
        <div className={fieldWrapper}>
          <CydEnhancedMarkdownEditor
            name="description"
            label="Description"
            onChange={(description) => {
              setFieldValue('description', description);
            }}
            initialValue={values.description}
            height="260px"
            error={errors.description}
          />
        </div>
        <AclSelect
          acls={aclList}
          name={'acl'}
          value={values.acl}
          onChange={handleChange}
        />
      </Form>
    );
  };

  const initialValues = React.useMemo(
    () => ({
      name: (playbook && playbook.name) || '',
      description: (playbook && playbook.description) || '',
      acl: playbook && playbook.acl && existsAcl
    }),
    [playbook, existsAcl]
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {renderForm}
    </Formik>
  );
};
