// This is an alternative use of CydBaseModal which is useful if you need to show the modal imperitively.

import { useState } from 'react';
import { CydBaseModal, CydBaseModalProps } from './CydBaseModal';

type UseBaseModalOptions<T> = {
  modalProps?: Omit<CydBaseModalProps, 'isOpen'>;
  content:
    | React.ReactNode
    | ((data: T | null, closeModal: () => void) => React.ReactNode);
  initiallyOpen?: boolean;
};

const emptyObject = {};

export function useCydBaseModal<T>(
  options: UseBaseModalOptions<T>
): [React.ReactNode, (data: T) => void, () => void] {
  const [isOpen, setIsOpen] = useState(options.initiallyOpen || false);
  const [data, setData] = useState(null as null | T);

  const render = (
    <CydBaseModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      {...(options.modalProps || emptyObject)}
    >
      <>
        {typeof options.content === 'function'
          ? options.content(data, () => setIsOpen(false))
          : options.content}
      </>
    </CydBaseModal>
  );

  return [
    render,
    (data: T) => {
      setData(data);
      setIsOpen(true);
    },
    () => setIsOpen(false)
  ];
}
