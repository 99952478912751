/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';

export type CydFragmentProps = {};

// The purpose of these is just for easy reference when using the withErrorBoundary HOC.

export const CydFragment = (
  props: React.PropsWithChildren<CydFragmentProps>
) => {
  const { children } = props;
  return <>{children}</>;
};

export const CydNull = (props: React.PropsWithChildren<CydFragmentProps>) => {
  return null;
};

export const CydDiv = (props: React.PropsWithChildren<CydFragmentProps>) => {
  const { children } = props;
  return <div>{children}</div>;
};
