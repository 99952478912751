/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import { CydSimpleSelect } from 'components/_formElements/CydSimpleSelect/CydSimpleSelect';
import { DataSignificance } from 'interface/DataSignificance.interface';
import React from 'react';

export type CydSignificanceSelectorProps = {
  availableSignificances: Array<DataSignificance>;
  onChange?: (
    newSignificance: DataSignificance,
    newValueString: string
  ) => void;
  label?: string;

  /**
   * nb. This is the _name_ of the significance, as that is how it is most commonly used.
   * If we later need to use uuid, we can extend this component and add a valueKey property.
   */
  selectedValue?: string;
  defaultSelectedValue?: string | null;

  name?: string;
};

export const CydSignificanceSelector = (
  props: CydSignificanceSelectorProps
) => {
  const {
    availableSignificances,
    onChange,
    label = 'Significance',
    selectedValue,
    defaultSelectedValue,
    name
  } = props;

  return (
    <CydSimpleSelect
      required
      availableOptions={availableSignificances}
      generateOptionLabel={(v) => {
        return v.name;
      }}
      generateValueString={(v) => {
        return v.name;
      }}
      name={name}
      onChange={onChange}
      label={label}
      selectedValueString={selectedValue}
      defaultSelectedString={defaultSelectedValue}
    />
  );
};
