/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import { IconButton } from '@mui/material';
import { useTimezoneLegacy, useTimezoneListAvailable } from 'hooks/AuthHooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { Clock } from './Clock';
import * as moment from 'moment-timezone';
import {
  DefaultTableRow,
  DefaultTableDataCell,
  DefaultTable
} from 'components/DefaultTable';
import { TimezoneSelect } from 'components/fields/TimezoneSelect';
import AddIcon from '@mui/icons-material/Add';
import { updateTimezone } from 'states/auth/actions';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import {} from 'states/userProps/actions';
import { useUserFavouriteTimezones } from 'hooks/TimezoneHooks';
import { useDispatch } from 'react-redux';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';

interface IClockPageNewPopoverProps {}

export const ManyTimezonesSelector: React.FC<
  IClockPageNewPopoverProps
> = () => {
  const allTimezones = useTimezoneListAvailable();
  const [selectedTimeZone, setSelectedTimeZone] = React.useState<string | null>(
    null
  );
  const dispatch = useDispatch();
  const currentTimezone = useTimezoneLegacy();

  const handleUpdateTimezone = (timeZone: string) => {
    dispatch(updateTimezone(timeZone));
  };

  const {
    userFavouriteTimezones,
    addFavouriteTimezone,
    removeFavouriteTimezone
  } = useUserFavouriteTimezones();

  return (
    <>
      <DefaultTable>
        <DefaultTableRow>
          <DefaultTableDataCell>
            <TimezoneSelect
              timezones={allTimezones}
              value={selectedTimeZone}
              onChange={(event) => {
                const newTimeZone =
                  (event.target.value as string) || moment.tz.guess();
                setSelectedTimeZone(newTimeZone);
              }}
            />
          </DefaultTableDataCell>
          <DefaultTableDataCell>
            <IconButton
              color="primary"
              onClick={() =>
                selectedTimeZone && addFavouriteTimezone(selectedTimeZone)
              }
              size="large"
            >
              <AddIcon />
            </IconButton>
          </DefaultTableDataCell>
          <DefaultTableDataCell />
          <DefaultTableDataCell />
        </DefaultTableRow>
        <DefaultTableRow onClick={() => dispatch(updateTimezone('UTC'))}>
          <DefaultTableDataCell>
            {moment.tz('UTC').zoneAbbr()}
          </DefaultTableDataCell>
          <DefaultTableDataCell />
          <DefaultTableDataCell>
            <Clock children="UTC" />
          </DefaultTableDataCell>
          <DefaultTableDataCell />
        </DefaultTableRow>
        {userFavouriteTimezones &&
          userFavouriteTimezones.map((timeZone) => {
            const isCurrentRowSelected = currentTimezone === timeZone;
            return (
              <DefaultTableRow key={timeZone} selected={isCurrentRowSelected}>
                <DefaultTableDataCell
                  onClick={() => handleUpdateTimezone(timeZone)}
                >
                  {timeZone}
                </DefaultTableDataCell>
                <DefaultTableDataCell
                  onClick={() => handleUpdateTimezone(timeZone)}
                />
                <DefaultTableDataCell>
                  <Clock children={timeZone} />
                </DefaultTableDataCell>
                <DefaultTableDataCell>
                  <DeleteIcon
                    onClick={() => removeFavouriteTimezone(timeZone)}
                  />
                </DefaultTableDataCell>
              </DefaultTableRow>
            );
          })}
      </DefaultTable>
      <CydButtonStack justifyContent={'flex-end'}>
        <CydButton variant="text" type="submit">
          Close
        </CydButton>
      </CydButtonStack>
    </>
  );
};
