import React from 'react';

import * as allServices from '../services';

export type InjectedServices = typeof allServices;
const ServiceProviderContext = React.createContext(allServices);

export const ApiServiceProvider = (
  props: React.PropsWithChildren<Partial<InjectedServices>>
) => {
  const { children, ...rest } = props;
  return (
    <ServiceProviderContext.Provider
      value={{
        ...allServices,
        ...rest
      }}
    >
      {children}
    </ServiceProviderContext.Provider>
  );
};

export const useServiceProvider = () => {
  return React.useContext(ServiceProviderContext);
};
