/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  ZOOM_LEVELS,
  generateDateRangeByZoomLevel
} from 'utils/CaseTimelineUtils';
import { IActivitiesTimelineData } from 'interface/ActivitiesTimeLine.interface';
import { useDataSignificancesRedux } from './CaseDataHooks';
import { DataSignificance } from 'interface/DataSignificance.interface';

export const useCaseTimeline = (
  timelineFilterData: IActivitiesTimelineData = {
    zoomLevel: ZOOM_LEVELS.ONE_WEEK.VALUE,
    dataSignificances: [],
    startDate: moment().startOf('isoWeek').startOf('day'),
    endDate: moment().endOf('isoWeek').endOf('day')
  }
) => {
  const { startDate, endDate, zoomLevel, dataSignificances } =
    timelineFilterData;

  return useMemo(
    () => ({
      dataSignificances,
      zoomLevel,
      ...generateDateRangeByZoomLevel(startDate, endDate, zoomLevel)
    }),
    [dataSignificances, endDate, startDate, zoomLevel]
  );
};

export const useDataSignificanceState = (): [
  DataSignificance[],
  React.Dispatch<React.SetStateAction<DataSignificance[]>>
] => {
  const { data: dataSignificances } = useDataSignificancesRedux();
  const [state, setState] = useState(dataSignificances);
  useEffect(() => {
    setState(dataSignificances);
  }, [dataSignificances]);

  return [state, setState];
};
