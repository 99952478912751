/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import * as React from 'react';
import {
  DefaultPopover,
  IDefaultPopoverProps
} from 'components/DefaultPopover';
import { usePlaybooksPageSinglePlaybookActionStyle } from './styles';
import { PlaybookCreateActionForm } from 'components/forms/PlaybookActionForm/PlaybookCreateActionForm';
import { CydButton } from 'components/_formElements/CydButton/CydButton';
import { CydButtonStack } from 'components/_formContainers/CydButtonStack/CydButtonStack';

interface IPlaybooksPageNewActionPopoverProps extends IDefaultPopoverProps {
  onSubmit: () => void;
  onClose: () => void;
}

export const PlaybooksPageNewActionPopover: React.FC<
  IPlaybooksPageNewActionPopoverProps
> = ({ onClose, onSubmit, ...rest }) => {
  const formRef = React.useRef<any>();

  const { popoverStyle } = usePlaybooksPageSinglePlaybookActionStyle();

  const handleRequestSavePlaybookAction = () => {
    if (!formRef.current || !formRef.current.submitForm) {
      return;
    }

    formRef.current.submitForm();
  };

  const popoverActions = (
    <CydButtonStack>
      <CydButton onClick={onClose} variant="text">
        Cancel
      </CydButton>
      <CydButton
        data-testid="btn-save"
        onClick={handleRequestSavePlaybookAction}
        variant="contained"
      >
        Save
      </CydButton>
    </CydButtonStack>
  );

  return (
    <DefaultPopover
      data-testid="po-new-action"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      actions={popoverActions}
      onClose={onClose}
      className={popoverStyle}
      {...rest}
    >
      <PlaybookCreateActionForm formRef={formRef} onSubmit={onSubmit} />
    </DefaultPopover>
  );
};
