import { useEffect, useMemo } from 'react';

import * as d3 from 'd3-selection';
import * as d3Drag from 'd3-drag';
import * as d3Force from 'd3-force';
import { generateColor } from 'utils/ColourUtils';
import { useCaseStates } from 'hooks/deprecated_CaseHooks';
import { useStyles } from './styles';
import { Grid, Typography } from '@mui/material';

const caseStatesToD3 = (states, currentState) => {
  let nodes: any = [],
    links: any = [];

  states.sort((a, b) => (a.ord < b.ord ? -1 : 1));

  states.forEach((state, index) => {
    let fixedParams = {};
    if (index === 0) {
      fixedParams = { fy: -height / 2 + 20, fx: 0 };
    }
    if (index === states.length - 1) {
      fixedParams = { fy: height / 2 - 40, fx: 0 };
    }
    nodes.push({
      id: state.name,
      end: state.end,
      start: state.start,
      current: currentState === state.name,
      ...fixedParams
    });
    state.next.forEach((nextState) => {
      let existingLinkIndex = links.findIndex(
        (link) => link.source === nextState && link.target === state.name
      );

      if (existingLinkIndex === -1) {
        let isBackLink =
          states.find(({ name }) => name === nextState).ord < state.ord;
        links.push({
          source: state.name,
          target: nextState,
          twoWays: false,
          backlink: isBackLink,
          isNext: state.name === currentState
        });
      } else {
        links[existingLinkIndex].twoWays = true;
      }
    });
  });

  return [nodes, links];
};

const width = 800,
  height = 700;

const drag = (simulation) => {
  function dragstarted(event, d) {
    if (!event.active) {
      simulation.alphaTarget(0.3).restart();
    }
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(event, d) {
    d.fx = event.x;
    d.fy = event.y;
  }

  function dragended(event, d) {
    if (!event.active) {
      simulation.alphaTarget(0);
    }
    d.fx = null;
    d.fy = null;
  }

  return d3Drag
    .drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);
};

export const D3Svg = ({ currentCaseState }) => {
  const { data: caseStates } = useCaseStates();
  const [nodes, links] = useMemo(
    () => caseStatesToD3([...caseStates], currentCaseState),
    [caseStates, currentCaseState]
  );

  const { svgStyles, keyText } = useStyles();

  useEffect(() => {
    if (!links || !nodes) {
      return;
    }
    const COLOR_MAP = (index) => generateColor(links.length, index, 90, 70);

    const svg = d3
      .select('#d3-svg')
      .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`);

    const simulation = d3Force
      .forceSimulation(nodes)
      .force(
        'link',
        d3Force
          .forceLink(links)
          .id((d: any) => d.id)
          .distance(100)
      )
      .force(
        'charge',
        d3Force
          .forceManyBody()
          .strength((d: any) => (d.backlink && !d.twoWays ? -8000 : -6000))
          .distanceMin(100)
          .distanceMax(500)
      )
      .force('collision', d3Force.forceCollide().radius(2))
      .force(
        'x',
        d3Force.forceX().x(() => 0)
      )
      .force('y', d3Force.forceY());

    svg
      .append('defs')
      .selectAll('marker')
      .data(links)
      .enter()
      .append('marker')
      .attr('id', (d, i) => `arrowend-${i}`)
      .attr('refX', 14)
      .attr('refY', 3.5)
      .attr('markerWidth', 10)
      .attr('markerHeight', 7)
      .attr('orient', 'auto')
      .append('polygon')
      .attr('fill', (d, i) => COLOR_MAP(i))
      .attr('points', '0 0, 10 3.5, 0 7');

    svg
      .append('defs')
      .selectAll('marker')
      .data(links)
      .enter()
      .append('marker')
      .attr('id', (d, i) => `arrowstart-${i}`)
      .attr('refX', -3)
      .attr('refY', 3.5)
      .attr('markerWidth', 10)
      .attr('markerHeight', 7)
      .attr('orient', 'auto')
      .append('polygon')
      .attr('fill', (d, i) => COLOR_MAP(i))
      .attr('points', '10 0, 10 7, 0 3.5');

    const link = svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke-width', 2.5)
      .selectAll('path')
      .data(links)
      .enter()
      .append('path')
      .attr('stroke', (d, i) => COLOR_MAP(i))
      .attr('stroke-dasharray', ({ isNext }) => (isNext ? 4 : 0))
      .attr('marker-end', (d, i) => `url(#arrowend-${i})`)
      .attr('marker-start', ({ twoWays }, i) =>
        twoWays ? `url(#arrowstart-${i})` : ''
      );

    const node = svg
      .append('g')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .selectAll('g')
      .data(nodes)
      .enter()
      .append('g')
      .call(drag(simulation));

    node
      .append('circle')
      .attr(
        'class',
        ({ current, end, start }) =>
          `${current ? 'state--current' : ''} ${end ? 'state--end' : ''} ${
            start ? 'state--start' : ''
          }`
      );

    node
      .append('text')
      .attr('x', 15)
      .attr('y', 5)
      .text(({ id }) => id);

    simulation.on('tick', () => {
      node.attr('transform', (d: any) => `translate(${d.x}, ${d.y})`);
      link.attr(
        'd',
        (d: any) => `M${d.source.x},${d.source.y} L${d.target.x},${d.target.y}`
      );
    });

    return () => {
      simulation.stop();
    };
  }, [nodes, links]);

  const keyItems = [
    {
      text: 'Current State',
      svg: <circle cx="0" cy="0" className="state--current" />
    },
    {
      text: 'Available Transitions',
      svg: <line x1="-14" y1="0" x2="28" y2="0" className="state--next" />
    },
    {
      text: 'Start State',
      svg: <circle cx="0" cy="0" className="state--start" />
    },
    {
      text: 'End State',
      svg: <circle cx="0" cy="0" className="state--end" />
    }
  ];

  return (
    <div className={svgStyles}>
      <svg id="d3-svg" width="800" height="700"></svg>

      <Grid container alignItems="center" justifyContent="center">
        {keyItems.map((keyItem, index) => (
          <Grid
            key={index}
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
          >
            <svg viewBox="-14 -14 28 28" width="30" height="30">
              <g>{keyItem.svg}</g>
            </svg>
            <Typography className={keyText}>{keyItem.text}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
