/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */

import * as CydarmFetch from 'utils/CydarmFetch';
import jwtDecode from 'jwt-decode';

import config from '../config';

const TOKEN_LOCATOR: string = 'cydarm-session-token';

/**
 * Takes an access token and writes it to session storage
 * @param token
 */
export function setAccessToken(token: string): void {
  localStorage.setItem(TOKEN_LOCATOR, token);
}

/**
 * Returns the current access token from local storage.
 * If there is no access token, returns an empty string
 */
export function getAccessToken(): string {
  return localStorage.getItem(TOKEN_LOCATOR) || '';
}

export function isAuthenticated(): boolean {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  }

  const payload: any = jwtDecode(accessToken);
  return payload.exp * 1000 > new Date().getTime();
}

/**
 * If access token exists, the access token will be refreshed after the refresh token threshold has been met.
 *
 */
export function isTokenAboutToExpire(): boolean {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return false;
  }
  const payload: any = jwtDecode(accessToken);

  // converts to milliseconds
  const expiryTime = payload.exp * 1000;
  const currentTime = new Date().getTime();
  const thresholdTime = expiryTime - config.REFRESH_TOKEN_THRESHOLD;

  return expiryTime > currentTime && currentTime > thresholdTime;
}

/**
 * Returns the session UUID from local storage.
 * If there is no access token, returns an empty string
 */
export function getSessionUuid(): string {
  const accessToken: string = getAccessToken();
  const jwtParts: string[] = accessToken.split('.');
  if (jwtParts.length !== 3) {
    return '';
  }
  const claims: JSON = JSON.parse(atob(jwtParts[1]));
  return claims['jti'];
}

/**
 * Returns the current access token from local storage.
 * If there is no access token, returns an empty string
 */
export function clearAccessToken() {
  return localStorage.clear();
}

const tokenResponseHandler = ({ response }) => {
  if (!(response.status >= 200 && response.status < 300)) {
    return Promise.reject('HTTP Status: ' + response.statusText);
  }
  const accessToken = response.headers.get('Access-Token');
  if (accessToken != null) {
    setAccessToken(accessToken);
    return Promise.resolve();
  }
  // TODO: addNotification
  return Promise.reject('No access token was set');
};

/**
 * 1. Login using provided Username and Password
 * 2. Call arbitrary endpoint and record provided Cydarm Session Access Token
 * Returns boolean representing successful login
 * @param username
 * @param password
 */
export function internalLogin(
  username: string,
  password: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    // do the authentication
    CydarmFetch.cyFetchWithRequestBodyAuth('/auth/password', username, password)
      .then(tokenResponseHandler)
      .then(() => resolve(true))
      .catch((err) => reject(err));
  });
}

export function refreshToken() {
  CydarmFetch.cyFetchWithBearerToken('/auth/refresh', getAccessToken(), {
    method: 'POST'
  })
    .then(tokenResponseHandler)
    .catch((err) => err);
}

export function refreshTokenIfRequired() {
  if (!isTokenAboutToExpire()) {
    return;
  }
  refreshToken();
}
