/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';
import { Acl } from 'interface/Acl.interface';

export interface ICreateOrRetrieveAcl {
  acl: any;
  resolve: (acl: Acl) => void;
}

export const createFetchAclByUuidAction =
  createAction<string>('acl/fetchByUuid');
export const createCreateOrRetrieveAclAction =
  createAction<ICreateOrRetrieveAcl>('acl/createOrRetrieve');
export const createFetchAclAction = createAction('acl/fetch');
export const createDeleteAclAction = createAction<string>('acl/delete');
