/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createAction } from '@reduxjs/toolkit';

interface IAddRemove {
  groupUuid: string;
  memberUuid: string;
}

interface IAddRemoveChildren {
  groupUuid: string;
  childGroupUuid: string;
}

// Groups Actions Function
export const createFetchGroupMembers =
  createAction<string>('groupMembers/fetch');

export const createAddGroupMember = createAction<IAddRemove>(
  'groupMembers/create'
);

export const createRemoveGroupMember = createAction<IAddRemove>(
  'groupMembers/remove'
);

export const createFetchGroupChildGroups = createAction<string>(
  'groupMembers/children/fetch'
);

export const createAddGroupChild = createAction<IAddRemoveChildren>(
  'groupMembers/children/add'
);

export const createRemoveGroupChild = createAction<IAddRemoveChildren>(
  'groupMembers/children/remove'
);

export const createFetchRecursiveGroupMembers = createAction<string>(
  'groupMembers/fetchRecursive'
);
