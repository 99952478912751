/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchIoCQuickSearch } from './actions';
import { Case } from 'interface/Case.interface';

export interface IQuickSearchResult {
  uuid: string;
  case: Case;
  rank: number;
}

export interface IOCQuickSearchResultList {
  isSearching: boolean;
  results: { [key: string]: Array<IQuickSearchResult> };
}

const initialState: IOCQuickSearchResultList = {
  results: {},
  isSearching: false
};

interface ISuccess {
  searchText: string;
  results: any;
}

const IocQuickSearchSlice = createSlice({
  name: 'IoCQuickSearch',
  initialState,
  reducers: {
    iocQuickSearchSuccess: (state, { payload }: PayloadAction<ISuccess>) => {
      state.results[payload.searchText] = payload.results;
    },
    iocQuickSearchFailed: (state) => {
      state.isSearching = false;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(createFetchIoCQuickSearch, (state) => {
      state.isSearching = true;
    })
});

export const {
  reducer: IoCQuickSearch,
  actions: { iocQuickSearchSuccess, iocQuickSearchFailed }
} = IocQuickSearchSlice;
