/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import {
  ICaseData,
  ICreateCaseDataRequest,
  IDetailedCaseData
} from 'interface/CaseData.interface';
import { Case } from 'interface/Case.interface';
import { createAction } from '@reduxjs/toolkit';

interface IUpdate {
  dataStubUuid: string;
  data: Partial<ICaseData>;
}

interface IDelete {
  dataStubUuid: string;
  caseUuid?: string;
}

interface IFetch {
  uuid: string;
  timezone?: string;
  currentCaseDataUuids?: string[];
}

interface IFetchStubs {
  caseData: Case;
  currentCaseDataUuids?: string[];
}

interface IAddData {
  caseData: ICreateCaseDataRequest;
  currentCaseDataUuids?: string[];
  parentCaseDataStubUuid?: string;
}

type IDownload = Pick<IDetailedCaseData, 'dataStubUuid' | 'filename'>;

export const createUpdateData = createAction<IUpdate>('data/update');

export const createDeleteData = createAction<IDelete>('data/remove');

export const createFetchCaseData = createAction<IFetch>('data/fetch');

export const createAddCaseData = createAction<IAddData>('data/add');

export const createDownloadData = createAction<IDownload>('data/download');

export const createFetchDataSignificances = createAction(
  'data/significances/fetch'
);

export const createFetchCaseDataStubs =
  createAction<IFetchStubs>('data/stubs/fetch');
