import { IStore } from 'states/store.interface';
import { createSelector } from 'reselect';
import {
  groupMembersSelector,
  singleChildGroupSelector
} from 'states/groupMembers/selectors';
import { Group } from 'interface/Group.interface';
import { DefaultRootState } from 'react-redux';

export const groupsSelector = ({ groups: { list } }: IStore) => list || [];

export const groupUuidsSelector = createSelector(groupsSelector, (groups) =>
  groups ? groups.map((g) => g.uuid) : []
);

export const singleGroupSelector = createSelector(
  groupsSelector,
  (_, groupUuid) => groupUuid,
  (groups, groupUuid) => groups && groups.find(({ uuid }) => uuid === groupUuid)
);

export const singleGroupChildrenSelector = createSelector(
  groupsSelector,
  singleChildGroupSelector,
  (groups, childGroups) =>
    groups.filter(({ uuid }) => childGroups.includes(uuid))
);

export const singleGroupNonMemberGroupsSelector = createSelector(
  groupsSelector,
  singleChildGroupSelector,
  (_, groupUuid) => groupUuid,
  (groups, childGroups, groupUuid) =>
    groupUuid
      ? groups.filter(
          ({ uuid }) => !childGroups.includes(uuid) && uuid !== groupUuid
        )
      : groups
);

export const currentGroupsSelector = createSelector(
  groupsSelector,
  groupMembersSelector,
  (_, userUuid: string) => userUuid,
  (groups, groupMembers, userUuid) => {
    if (!groupMembers || !groups) {
      return [];
    }

    let currentGroupUuids: string[] = [];
    Object.keys(groupMembers).forEach((groupUuid) => {
      const userMatch = groupMembers[groupUuid].filter(
        (currentMember) => currentMember.uuid === userUuid
      );
      if (userMatch.length === 1) {
        currentGroupUuids.push(groupUuid);
      }
    });
    const currentGroups: Group[] = groups.filter(
      (thisGroup) => currentGroupUuids.indexOf(thisGroup.uuid) !== -1
    );
    return currentGroups;
  }

  // OPERATION TYPES CLEAn up - I'm pretty sure this is right
  // https://github.com/reduxjs/reselect/issues/563#issuecomment-1017391919
  // Reselect doesn't appear to be too well maintained - argument for discontinuing use
) as unknown as (state: DefaultRootState, userUuid: string) => Group[];

export const groupsStatusSelector = ({
  groups: { isLoading, hasLoaded }
}: IStore) => isLoading || hasLoaded;

export const groupsHasLoadedSelector = ({ groups: { hasLoaded } }: IStore) =>
  hasLoaded;

export const groupsIsLoadingSelector = ({ groups: { isLoading } }: IStore) =>
  isLoading;
