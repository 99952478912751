/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import { useCurrentUser } from './AuthHooks';

import {
  createApi,
  fetchBaseQuery,
  skipToken
} from '@reduxjs/toolkit/dist/query/react';
import { CYDARM_BASE_API } from '../constants';
import { CydApi_UserAction, apiFetchUserActions } from 'services';

export const rtkqUserActionsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: CYDARM_BASE_API
  }),
  reducerPath: 'rtk-user-actions',
  tagTypes: ['user-action'],
  endpoints: (builder) => {
    return {
      getUserActions: builder.query<
        Array<CydApi_UserAction>,
        { userUuid: string }
      >({
        queryFn: async (args) => {
          const result = await apiFetchUserActions(args.userUuid);
          return {
            data: result.json
          };
        }
      })
    };
  }
});

export const useUserActionsRtkq = () => {
  const { data: currentUser } = useCurrentUser();

  return rtkqUserActionsApi.endpoints.getUserActions.useQuery(
    currentUser?.uuid
      ? {
          userUuid: currentUser?.uuid
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );
};
