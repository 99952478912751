/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import { IPlaybookActionType, AtcPlaybook } from 'interface/Playbook.interface';
import { createAction } from '@reduxjs/toolkit';
import { OasisCacaoPlaybook } from 'components/_playbooks/CacaoPlaybook';

interface ICreateByFile {
  fileList: FormData;
  successMessage?: any;
}

interface IUpdate {
  uuid: string;
  playbook: AtcPlaybook;
}

interface IAddAction {
  playbookUuid: string;
  playbookActionUuid: string;
  PlaybookActionType?: IPlaybookActionType;
}

interface IRemoveAction extends IAddAction {
  position: number;
}

interface IAddRemoveTag {
  playbookUuid: string;
  caseTagUuid: string;
}

interface IMoveAction extends IAddAction {
  currentPosition: number;
  newPosition: number;
}

export interface ICreatePlaybook {
  atc?: AtcPlaybook;
  resolve?: (value: { uuid: string }) => void;
  cacao?: OasisCacaoPlaybook;
}

// Playbooks Actions Function
export const createFetchPlaybooks = createAction('playbooks/fetch');

export const createFetchPlaybookByUuid = createAction<string>(
  'playbooks/fetchByUuid'
);

export const createPlaybook = createAction<ICreatePlaybook>('playbooks/create');

export const createPlaybookByFile = createAction<ICreateByFile>(
  'playbooks/createByFile'
);

export const createUpdatePlaybook = createAction<IUpdate>('playbooks/update');

export const createDeletePlaybook = createAction<{
  uuid: string;
  onSuccess: () => void;
}>('playbooks/delete');

export const createExportPlaybook = createAction<string>('playbooks/export');

export const createDuplicatePlaybook = createAction<string>(
  'playbooks/duplicate'
);

export const createAddActionToPlaybook = createAction<IAddAction>(
  'playbooks/action/add'
);

export const createRemoveActionFromPlaybook = createAction<IRemoveAction>(
  'playbooks/action/remove'
);

export const createMovePlaybookAction = createAction<IMoveAction>(
  'playbooks/action/move'
);

export const createAddTagToPlaybook =
  createAction<IAddRemoveTag>('playbooks/tag/add');

export const createRemoveTagFromPlaybook = createAction<IAddRemoveTag>(
  'playbooks/tag/remove'
);
