/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */

import makeStyles from '@mui/styles/makeStyles';

export const useDefaultDialogStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    backgroundColor: palette.background.paper
  },
  content: {
    padding: spacing(4)
  },
  header: {
    padding: spacing(4),
    paddingBottom: spacing(0),
    color: palette.text.primary
  },
  actionsWrapper: {
    paddingTop: spacing(4),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
