/*
 *  COPYRIGHT NOTICE
 *  All source code contained within the Cydarm cybersecurity software provided by Cydarm
 *  Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 *  protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 *  without prior written permission of the Company. All rights reserved.
 */
import moment from 'moment';
import { dateFormatLocale } from './DateUtils';
import { wordReplacer, titleCase } from './Stix2_0ParserStructure';
import { isValidJSON, getIndex } from './Stix2_1ParserUtils';

export const NEWLINE = '\n\n';
const BOLDSTART = '**';
const BOLDEND = '**';

export const defaultSTIX2_0 = (dataIn) => {
  if (!isValidJSON(dataIn)) {
    return dataIn;
  }

  try {
    //default to output json
    //const title = '### STIX 2.0 Fragment \n';
    const title = getTitle(dataIn);
    let output = '';

    //load the STIX 2.0 json
    let json = JSON.parse(dataIn);

    let data = jsonToMarkdown(json);

    //remove undefines
    data = data.filter((item) => item);
    data.forEach(function (item) {
      output = output + item;
    });

    return title + NEWLINE + output;
  } catch (err) {
    return '```' + dataIn;
  }
};

// trimmedEntries will remove any entries from the STIX data before flattening the structure for presentation,
// Any data that needs to be trimmed should be added here
const trimmedEntries = (data) => {
  var result = data.split(' : ', 4);

  const set = new Set([
    //  'id',
    'x_cydarm_com_reply',
    'x-cydarm-com-cydarm-case',
    'extensions',
    'modified',
    'x-cydarm-com-dns-query',
    'x_cydarm_com_query',
    'spec_version',
    'observed-data',
    'resolves_to_refs',
    'bundle'
  ]);
  return result.some((value) => set.has(value));
};

// TODO: need to check if the observed data types match the syntax in all cases
// objectTypes checks that the type field is referring to an Observed Data type
export const objectTypes = (data) => {
  const set = new Set([
    'network-traffic',
    'artifact',
    'directory',
    'autonomous-system',
    'domain-name',
    'email-addr',
    'email-message',
    'file',
    'mutex',
    'process',
    'software',
    'url',
    'user-account',
    'windows-registry-key',
    'x509-cert'
  ]);

  return set.has(data);
};

const formatValues = (dataIn) => {
  const value = dataIn.replace(/-/g, '');
  var output = '';
  if (value in wordReplacer) {
    output = value.replace(value, wordReplacer[value]);
  } else if (moment(dataIn, moment.ISO_8601).isValid()) {
    output = moment(dataIn).format(dateFormatLocale);
  } else if (!trimmedEntries(dataIn)) {
    output = titleCase(dataIn);
  }
  return output;
};

const getTitle = (dataIn) => {
  if (isValidJSON(dataIn)) {
    const json = JSON.parse(dataIn);
    const objects = json.objects;
    const searchTermIndex = getIndex(objects, 'type');
    const searchTerm = json.objects[searchTermIndex].type;
    const title = '### STIX 2.0 Fragment: ' + formatValues(searchTerm) + '\n';
    return title;
  }
  return;
};

const jsonToMarkdown = (json: any) => {
  let output: any[] = [];

  //identify all items for processing
  let obj: any = {};
  Object.entries(json.objects).forEach(([key, value]) => {
    let item = processX(key, value);
    if (item != null) {
      obj[key] = item;
    }
  });

  obj = setTypeAsKeyToOjbect(obj);
  obj = updateReferenceItemFromObjects(obj);

  //remove ids now that we have updated references
  obj = removeIdsFromObjects(obj);

  //to markdown
  Object.entries(obj).forEach(([key, value]) => {
    let startDepth = 1;
    let item = processXToMarkdown(key, value, startDepth);
    if (item != null) {
      output.push(item);
    }
  });

  return output;
};

const removeIdsFromObjects = (obj) => {
  Object.entries(obj).forEach(([key, value]: [any, any]) => {
    if (typeof value == 'object') {
      return removeIdsFromObjects(value);
    } else if (key === 'id') {
      delete obj[key];
    }
  });
  return obj;
};

const updateReferenceItemFromObjects = (obj) => {
  let findReplaceItems: any = [];

  Object.entries(obj).forEach(([key, value]: [any, any]) => {
    findReplaceItems[value['id']] = key;
  });

  let objString = JSON.stringify(obj);
  Object.entries(findReplaceItems).forEach(([find, replace]: [any, any]) => {
    objString = objString.replace(new RegExp(find, 'g'), replace);
  });

  return JSON.parse(objString);
};

const setTypeAsKeyToOjbect = (obj) => {
  let objs = Object.entries(obj);

  Object.entries(objs).forEach(([key, value]: [any, any]) => {
    if (value[1].hasOwnProperty('type')) {
      let theKey = getNextObjectKey(obj, titleCase(value[1]['type']));
      obj[theKey] = value[1];
      delete obj[key];
    }
  });

  return obj;
};

const getNextObjectKey = (obj, key) => {
  if (!obj.hasOwnProperty(key)) {
    return key;
  }

  //should not have more that 20 items of one type
  for (var i = 1; i < 20; i++) {
    let myKey = key + i.toString();
    if (!obj.hasOwnProperty(myKey)) {
      return myKey;
    }
  }
};

const processXlist = (item) => {
  let list: any[] = [];

  Object.entries(item).forEach(([, valueField]: [any, any]) => {
    if (valueField in wordReplacer) {
      valueField = valueField.replace(valueField, wordReplacer[valueField]);
    }
    list.push(valueField);
  });

  return list.join(', ');
};

//list of keys that should contain lists
const keysThatAreArrays = (key: string) => {
  let searchKey = key.toLowerCase();
  const list = ['protocols', 'labels', 'sectors', 'where_sighted_refs'];

  return list.includes(searchKey);
};

const processX = (key, value) => {
  let obj: any = {};

  if (trimmedEntries(key)) {
    return;
  } else if (typeof value === 'string' || value instanceof String) {
    if (trimmedEntries(value)) {
      return;
    }
    obj = value;
  } else if (typeof value === 'number' && isFinite(value)) {
    obj = value.toString();
  } else if (typeof value === 'boolean') {
    obj = value.toString();
  } else if (value && typeof value === 'object' && value instanceof Object) {
    if (keysThatAreArrays(key)) {
      obj = processXlist(value);
    } else {
      Object.entries(value).forEach(([keyField, valueField]) => {
        let item = processX(keyField, valueField);
        if (item != null) {
          obj[keyField] = item;
        }
      });
    }
  }

  return obj;
};

const processXToMarkdown = (key, value, depth) => {
  let output = '';
  var blockQuotes = '';
  for (var i = 0; i < depth; i++) {
    blockQuotes += '>';
  }

  blockQuotes += ' ';
  if (value && typeof value === 'object' && value instanceof Object) {
    let addKey = '';
    //We don't want the object keys
    if (key !== 'objects') {
      if (depth > 0) {
        if (Number.isNaN(parseInt(key))) {
          addKey = blockQuotes + BOLDSTART + key + BOLDEND + NEWLINE;
        } else {
          depth -= 2;
        }
      }
    }
    Object.entries(value).forEach(([keyField, valueField]) => {
      let item = processXToMarkdown(keyField, valueField, depth + 1);
      if (item != null) {
        output += addKey + item;
        addKey = '';
      }
    });
  } else {
    if (key != null && value != null) {
      output +=
        NEWLINE +
        blockQuotes +
        BOLDSTART +
        formatValues(key) +
        BOLDEND +
        ' : ' +
        formatValues(value.toString()) +
        NEWLINE;
    }
  }

  return output;
};
