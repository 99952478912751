export const sendToClipboard = (text, thing = 'Item') => {
  navigator.clipboard.writeText(text);
};

export const copyLocatorAndDescription = (locator, description) => {
  let text = `${locator} ${description}`;
  sendToClipboard(text, 'locator and description');
};

export const copyLocator = (locator) => {
  let text = `[cydarm:${locator}]`;
  sendToClipboard(text, 'locator');
};
