/*
 * COPYRIGHT NOTICE
 * All source code contained within the Cydarm cybersecurity software provided by Cydarm
 * Technologies Pty Ltd ABN 17 622 236 113 (Company) is the copyright of the Company and
 * protected by copyright laws. Redistribution or reproduction of this material is strictly prohibited
 * without prior written permission of the Company. All rights reserved.
 */
import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CydSignificanceDot } from 'components/_objectDisplay/CydSignificanceDisplay/CydSignificanceDisplay';
import { CydIcon } from 'components/_foundation/CydIcon/CydIcon';
import { ActivityItemOpenState } from '../CydCaseViewActivityItem';

export type CydCaseViewActivityCollapserProps = {
  significance: string;
  openState: ActivityItemOpenState;
  onClick?: () => void;
};

/**
 * The threading line on the left
 * @param props
 * @returns
 */
export const CydCaseViewActivityCollapser = (
  props: CydCaseViewActivityCollapserProps
) => {
  const { significance, onClick, openState } = props;

  return (
    <div
      role="button"
      onClick={onClick}
      aria-label="Toggle collapsed state"
      css={(theme) => `
      :hover {
        cursor: ${onClick ? 'pointer' : 'auto'}; 
      }

      display: grid; 
      grid-template-columns: 14px 24px; 
      grid-template-rows: 24px 1fr; 

      justify-content: center; 
      align-items: center;
    `}
    >
      {/* Dot */}
      <CydSignificanceDot significance={significance} />

      <div css={css``}>
        {onClick && (
          <CydIcon
            icon="rightArrow"
            css={css`
              position: relative;
              top: 1px;
              transform: rotate(${[270, 0, 90][openState]}deg);
              transition: transform 0.3s ease;
            `}
          />
        )}
      </div>

      {/* Line */}
      <div
        css={(theme) => `
        align-self: stretch;
        width: 2px;
        margin: 6px;
        min-height:  16px; 
        background-color: ${theme.other.border};
      `}
      ></div>
    </div>
  );
};
